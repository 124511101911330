<template>
    <v-card class="mt-3" v-if="hideSection">
      <v-card-title>
        <span>{{ $t('title.imm_1294_education_history') }}</span>
        <v-spacer />
        <v-btn class="mb-3" color="primary" dark small @click.native="saveForm()" :disabled="!full_detail">{{ $t('global.add') }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense class="mb-3 flex">
          <v-col cols="10">
            <div class="body-2">{{ $t('global.imm_1294_education_history_detail') }}</div>
          </v-col>
          <v-col cols="2">
            <el-checkbox class="ml-5 mt-2" v-model="full_detail" @change="checkFullDetail()">{{ $t('global.yes') }}</el-checkbox>
          </v-col>
        </v-row>
        <v-alert type="info" border="left" dense class="mb-2" v-if="$t('infobox.imm_1294_education_history') !== '' && !isAlertHide">
          {{ $t('infobox.imm_1294_education_history') }}
        </v-alert>
        <v-row dense class="customCollapseHeader">
          <v-col cols="6" sm="2">From Date</v-col>
          <v-col cols="6" sm="2">To Date</v-col>
          <v-col class="d-none d-sm-flex" sm="3">Country</v-col>
          <v-col class="d-none d-sm-flex" sm="3">City</v-col>
          <v-col class="d-none d-sm-flex" sm="2">Type of Certificate</v-col>
        </v-row>
        <el-collapse v-model="activeNames" accordion v-show="full_detail">
          <el-collapse-item v-for="(form,i) in items" :name="i+1" :key="i" :id="'row' + form.id">
            <template slot="title">
              <div class="customHead">
                <v-row dense>
                  <v-col cols="6" sm="2">
                    <el-tag type="primary" v-if="form.from_date !== null && form.from_date != undefined">{{ form.from_date | day }}</el-tag>
                  </v-col>
                  <v-col cols="6" sm="2">
                    <el-tag type="success" v-if="form.to_date !== null && form.to_date != undefined">{{ form.to_date | day }}</el-tag>
                  </v-col>
                  <v-col class="d-none d-sm-flex" sm="3"><div class="pl-2 pr-2">{{ form.country_name }}</div></v-col>
                  <v-col class="d-none d-sm-flex" sm="3"><div class="pl-2 pr-2">{{ form.city }}</div></v-col>
                  <v-col class="d-none d-sm-flex" sm="2"><div class="pl-3 pr-2">{{ form.type_of_certificate }}</div></v-col>
                </v-row>
              </div>
            </template>
            <el-form :model="form" ref="form" label-position="top">
              <v-row dense>
                <template v-if="isOINP">
                  <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'ongoing')">
                    <el-form-item prop="ongoing">
                      <el-checkbox v-model="form.ongoing" @change="handleAutoSave(i, form)">Ongoing</el-checkbox>
                    </el-form-item>
                  </v-col>
                </template>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'name_of_school')">
                  <el-form-item :label="$t('imm_1294_education_history.name_of_school')" prop="name_of_school">
                    <el-input v-model="form.name_of_school" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'field_of_study')">
                  <el-form-item :label="$t('imm_1294_education_history.field_of_study')" prop="field_of_study">
                    <el-input v-model="form.field_of_study" @change="handleAutoSave(i, form)" style="width: 100%;" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'country_id')">
                  <el-form-item :label="$t('imm_1294_education_history.country_id')" prop="country_id">
                    <div class="el-select native-select">
                      <div class="el-input">
                        <select v-model="form.country_id" @change="changeCountry(form);handleAutoSave(i, form)" placeholder="Select" class="el-input__inner">
                          <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                        </select>
                      </div>
                    </div>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'provice')">
                  <el-form-item label="Province" prop="provice">
                    <div class="el-select native-select" v-if="selectProvine">
                      <div class="el-input">
                        <select @change="handleAutoSave(i, form)" v-model="form.provice" placeholder="Select" class="el-input__inner">
                          <option v-for="(item, index) in province" v-bind:key="index" :value="item.province_name" :label="item.province_name">{{ item.province_name }}</option>
                        </select>
                      </div>
                    </div>
                    <el-input v-else v-model="form.provice" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'city')">
                  <el-form-item :label="$t('imm_1294_education_history.city')" prop="city">
                    <el-input v-model="form.city" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'from_date')">
                  <el-form-item :label="$t('imm_1294_education_history.from_date')" prop="from_date">
                    <el-date-picker :picker-options="datePickerOptions" value-format="yyyy-MM-dd" @change="handleAutoSave(i, form)" v-model="form.from_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'to_date')">
                  <el-form-item :label="$t('imm_1294_education_history.to_date')" prop="to_date">
                    <el-date-picker :picker-options="datePickerOptions" value-format="yyyy-MM-dd" @change="handleAutoSave(i, form)" v-model="form.to_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'type_of_certificate')">
                  <el-form-item :label="$t('imm_1294_education_history.type_of_certificate')" prop="type_of_certificate">
                    <el-input v-model="form.type_of_certificate" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
              </v-row>
              <v-row dense v-show="sectionSlug === 'IEE-Education'">
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'years')">
                  <el-form-item :label="$t('imm_1294_education_history.years')" prop="years">
                    <el-input v-model="form.years" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'study_time')">
                  <el-form-item :label="$t('imm_1294_education_history.study_time')" prop="study_time">
                    <el-select v-model="form.study_time" @change="handleAutoSave(i, form)" style="width: 100%">
                      <el-option value="Full-time">Full-time</el-option>
                      <el-option value="Part-time">Part-time</el-option>
                    </el-select>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'study_period')">
                  <el-form-item :label="$t('imm_1294_education_history.study_period')" prop="study_period">
                    <el-select v-model="form.study_period" @change="handleAutoSave(i, form)" style="width: 100%">
                      <el-option value="In good standing but no certificate or diploma or degree awarded">In good standing but no certificate or diploma or degree awarded</el-option>
                      <el-option value="Certificate or diploma or degree awarded">Certificate or diploma or degree awarded</el-option>
                      <el-option value="Not completed">Not completed</el-option>
                    </el-select>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'awarded')">
                  <el-form-item :label="$t('imm_1294_education_history.awarded')" prop="awarded">
                    <el-radio-group v-model="form.awarded" @change="handleAutoSave(i, form)">
                      <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                      <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </v-col>
                <template v-if="isOINP">
                  <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'obtained_basis')">
                    <el-form-item label="Was your degree, college diploma or certificate, or high school diploma obtained on a full-time or part-time basis?" prop="obtained_basis">
                      <el-input v-model="form.obtained_basis" @change="handleAutoSave(i, form)" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'recipient')">
                    <el-form-item label="Are you the recipient of a grant, bursary or scholarship that requires you to return to your home country or another jurisdiction (another Canadian province, territory, or another country) after the completion of your studies?" prop="recipient">
                      <el-input v-model="form.recipient" @change="handleAutoSave(i, form)" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'eca')">
                    <el-form-item label="Do you have an Educational Credential Assessment (ECA)?" prop="eca">
                      <el-input v-model="form.eca" @change="handleAutoSave(i, form)" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                </template>
              </v-row>
              <v-row dense class="mt-2">
                <v-col cols="12">
                  <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(i,form)">{{ $t('global.save') }}</v-btn>
                  <v-btn :loading="autoSave" :disabled="autoSave" color="error" small @click.native="deleteItem(form,i)">{{ $t('global.delete') }}</v-btn>
                </v-col>
              </v-row>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionSlug: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    },
    isOINP: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        from_date: null,
        to_date: null,
        name_of_school: '',
        city: '',
        country_id: '',
        field_of_study: '',
        type_of_certificate: '',
        years: null,
        study_time: null,
        study_period: null,
        awarded: null,
        sec_org_id: this.$orgId,
        ongoing: null,
        provice: null,
        recipient: null,
        obtained_basis: null
      },
      secOrgID: this.$orgId,
      activeNames: -1,
      url: 'imms_table/imm_1294_education_history',
      deleteUrl: 'imms/imm_1294_education_history',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      applicableId: 'education_general/id'
    }),
    full_detail: {
      get() {
        return this.$store.state.education_general.educationGeneral.full_detail
      },
      set(val) {
        this.$store.commit('education_general/SET_FULL_DETAIL', val)
      }
    },
    isAlertHide: function() {
      return this.$route.name == 'section_comparison' ? true : false
    }
  },
  methods: {
    changeCountry(item) {
      if (item.country_id != undefined && item.country_id != null) {
        let idx = this.country.map(p => p.id).indexOf(item.country_id)
        item.country_name = this.country[idx].country_name
      }
    },
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('education_general/getAll', this.memberUID)
      var url = `table/imm_1294_edu_history_view?filter=member_uid=${this.memberUID}&order=from_date DESC, to_date DESC`
      this.$store.dispatch('store/get', url).then(response => {
        let vm = this

        if (vm.isDataValidation) {
          if (!vm.full_detail) {
            vm.hideSection = false
            return false
          }
          response.resource.forEach(function(item) {
            var form = {
              from_date: null,
              to_date: null,
              name_of_school: '',
              city: '',
              country_id: '',
              field_of_study: '',
              type_of_certificate: '',
              years: null,
              study_time: null,
              study_period: null,
              awarded: null
            }

            if (vm.sectionSlug !== 'IEE-Education') {
              delete form.years
              delete form.study_time
              delete form.study_period
              delete form.awarded
            }

            if (!vm.isOINP) {
              delete form.study_period
              delete form.ongoing
              delete form.recipient
              delete form.obtained_basis
            }

            var obj = vm.checkFormItems(item, form)

            if (obj != 'empty') {
              vm.items.push(item)
            }
          })
          if (response.resource.length == 0) {
            vm.hideSection = true
          }
          else {
            vm.hideSection = vm.validationItems.length == 0 ? false : true
          }
        }
        else {
          vm.items = response.resource
        }
        this.$Progress.finish()
      })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        country_id: '',
        city: '',
        name_of_school: '',
        type_of_certificate: '',
        from_date: null,
        to_date: null,
        study_period: null,
        study_time: null,
        years: null,
        awarded: null,
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        ongoing: null,
        provice: null,
        recipient: null,
        obtained_basis: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    checkFullDetail() {
      var action = 'education_general/save'
      var data = {}
      data.full_detail = this.full_detail
      data.member_id = parseFloat(this.memberID)
      data.member_uid = this.memberUID
      data.application_id = this.$route.params.id
      if (this.applicableId != undefined) {
        data.id = this.applicableId
        action = 'education_general/edit'
      }
      this.$store.dispatch(action, data)
        .then(response => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        })
    }
  }
}
</script>

<style>
  .small-input .el-input{
    width: 50px;
    float: left;
    margin-right: 15px;
  }
  .small-input .el-form-item__content{
    line-height: inherit;
  }
  .small-input .el-form-item__content .el-form-item__label{
    display: inherit;
    padding-top: 5px;
  }
</style>
