import Vue from "vue";
import axiosNode from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axiosNode);

var instanceNode = axiosNode.create({
  baseURL: process.env.API_ROOT_IRCC_NODEAPP
});

instanceNode.interceptors.request.use(
  config => {
    let token = window.localStorage.getItem("token");
    if (token != undefined && token != null) {
      config.withCredentials = true;
      config.headers.common["ircc_role_alias"] = window.localStorage.getItem("role");
      config.headers.common["ircc_role_id"] = window.localStorage.getItem("role_id");
      config.headers.common["email"] = window.localStorage.getItem("email");
      config.headers.common["user_id"] = window.localStorage.getItem("id");
    }
    return config;
  },
  error => {
    throw error
  }
);

instanceNode.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },
  error => {
    throw error;
  }
);

export default {
  get: function(url, params) {
    return instanceNode.get(url, { params: params });
  },
  post: function(url, data) {
    return instanceNode.post(url, data);
  },
  put: function(url, data, params) {
    return instanceNode.put(url, data, { params: params });
  },
  delete: function(url) {
    return instanceNode.delete(url);
  }
};
