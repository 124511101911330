import api from "./../plugins/axios";
import { orderObjectBy } from "./../utils/sort";

export const state = {
  tabs: [],
  selectedTabs: [],
  tab: {},
  ui_conf_id: undefined,
  confs: [],
  relationship_ids: []
};

export const getters = {
  all(state) {
    return state.tabs;
  },
  relationship_ids(state) {
    return state.relationship_ids;
  },
  uiConfs(state) {
    return state.confs;
  },
  getOne(state) {
    return state.tab;
  },
  getUiConfId(state) {
    return state.ui_conf_id;
  },
  selectedTabs(state) {
    return state.selectedTabs;
  }
};

export const actions = {
  getUiConfs({ commit }, filter) {
    return api.get("/table/ui_configuration" + filter).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  getTabs({ commit }, filter) {
    return api.get("/table/ui_tab" + filter).then(
      (response) => {
        commit("GET_DATAS", response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  getSections({ commit }, filter) {
    return api.get("/table/ui_section" + filter).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  getTabSections({ commit }, params) {
    return api.get("/table/ui_tab_sections" + params.filter1).then(
      (response) => {
        return api.get("/table/ui_configuration" + params.conf).then((responseConfig) => {
          var results = {
            all: response.data.resource,
            selected: responseConfig.data.resource
          };
          commit("GET_GROUPING_TABS", results);
          return Promise.resolve(results);
        });
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  getTabSections2({ commit }, params) {
    return api.get("/table/ui_layout_config_view" + params.conf).then((responseConfig) => {
      return Promise.resolve(responseConfig);
    });
  },
  getTabConfSections({ commit }, params) {
    return api.get("/table/ui_tab_sections" + params.filter1).then(
      (response) => {
        var filter = "?filter=((application_type_id=" + params.application_type_id + ") AND (relationship_id=" + params.relationship_id + "))";
        return api.get("/table/ui_configuration" + filter).then((responseConfig) => {
          var results = {
            all: response.data.resource,
            selected: responseConfig.data.resource,
            application_type_id: params.application_type_id
          };
          commit("GET_GROUPING", results);
          return Promise.resolve(results);
        });
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  saveUiTab({ commit }, data) {
    var item = {};
    item.order = data.order;
    item.label = data.label;
    item.alias = data.alias;
    item.infobox = data.infobox;

    if (data.id == undefined) {
      let requestObject = { resource: [item] };
      return api.post("/table/ui_tab", requestObject).then(
        (response) => {
          var item = {};
          item.order = data.order;
          item.label = data.label;
          item.alias = data.alias;
          item.infobox = data.infobox;
          item.sectionTmps = data.sectionTmps;
          item.id = response.data.resource[0].id || undefined;
          return Promise.resolve(response.data.resource[0] || undefined);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    }
    else {
      item.id = data.id;
      let requestObject = { resource: [item] };
      return api.put("/table/ui_tab", requestObject).then(
        (response) => {
          return Promise.resolve(response.data.resource[0] || undefined);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    }
  },
  saveSection({ commit }, data) {
    if (data.id == undefined) {
      let requestObject = { resource: [data] };
      return api.post("/table/ui_section", requestObject).then(
        (response) => {
          return Promise.resolve(response.data.resource[0] || undefined);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    }
    else {
      let requestObject = { resource: [data] };
      return api.put("/table/ui_section", requestObject).then(
        (response) => {
          return Promise.resolve(response.data.resource[0] || undefined);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    }
  },
  saveUiConf({ commit }, data) {
    var requestObject = { resource: data };
    return api.post("table/ui_configuration", requestObject).then(
      (response) => {
        return Promise.resolve(response.data.resource[0] || undefined);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  saveSectionTable({ commit }, data) {
    var requestObject = { resource: data };
    return api.post("table/ui_sections_table", requestObject).then(
      (response) => {
        return Promise.resolve(response.data.resource);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  editUiConf({ commit }, data) {
    var requestObject = { resource: data };
    return api.put("table/ui_configuration", requestObject).then(
      (response) => {
        return Promise.resolve(response.data.resource[0] || undefined);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  deleteTab({ commit }, data) {
    return api.delete("table/ui_tab" + "?filter=id=" + data.id).then(
      (response) => {
        commit("DELETE_DATA", data);
        return Promise.resolve(response);
      },
      (error) => {
        commit("FAILURE_DATAS", error);
        return Promise.reject(error);
      }
    );
  },
  deleteSection({ commit }, filter) {
    return api.delete("table/ui_section" + filter).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  deleteSectionTable({ commit }, filter) {
    return api.delete("table/ui_sections_table" + filter).then(
      (response) => {
        return Promise.resolve(response.data.resource);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  deleteUiConf({ commit }, filter) {
    return api.delete("table/ui_configuration" + filter).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
};

export const mutations = {
  GET_RELATIONSHIP_IDS(state, datas) {
    if (datas.length > 0) {
      var items = [];
      datas.forEach(function(vsel, key) {
        if (items.indexOf(parseInt(vsel.relationship_id)) == -1) {
          items.push(parseInt(vsel.relationship_id));
        }
      });
      state.relationship_ids = items;
    }
  },
  GET_GROUPING_TABS(state, datas) {
    var items = [];
    var checks = [];
    var results = datas.all;
    var selectedItems = datas.selected || [];

    selectedItems.forEach(function(vTab, key) {
      for (var i = 0; i < results.length; i++) {
        if (parseFloat(vTab.tab_id) === parseFloat(results[i].tab_id) && parseFloat(vTab.section_id) === parseFloat(results[i].section_id)) {
          vTab.infobox = results[i].tab_inbobox;
          vTab.alias = results[i].tab_alias;
          vTab.label = results[i].tab_label;
          vTab.section_label = results[i].section_label;
          vTab.section_info = results[i].section_info;
          vTab.section_alias = results[i].section_alias;
          vTab.section_slug = results[i].section_slug;
        }
      }
    });

    for (var i = 0; i < selectedItems.length; i++) {
      var tab = selectedItems[i];
      var item = {};
      item.tab_id = tab.tab_id;
      item.alias = tab.alias;
      item.label = tab.label;
      item.infobox = tab.infobox;
      item.order = tab.order;
      item.sections = [];
      var index = checks.indexOf(tab.tab_id);
      if (index == -1) {
        items.push(item);
      }
      checks.push(tab.tab_id);
    }

    items.forEach(function(item) {
      selectedItems.forEach(function(selected) {
        if (item.tab_id === selected.tab_id) {
          var select = {};
          select.alias = selected.section_alias;
          select.slug = selected.section_slug;
          select.label = selected.section_label;
          select.info = selected.section_info;
          select.order = selected.order;
          item.sections.push(select);
        }
      });
    });

    state.selectedTabs = items;
  },
  GET_GROUPING_TABS2(state, datas) {
    var items = [];
    var checks = [];
    var selectedItems = datas.selected || [];

    selectedItems.forEach(function(vTab, key) {
      vTab.infobox = vTab.tab_inbobox;
      vTab.alias = vTab.tab_alias;
      vTab.label = vTab.tab_label;
      vTab.section_label = vTab.section_label;
      vTab.section_info = vTab.section_info;
      vTab.section_alias = vTab.section_alias;
      vTab.section_slug = vTab.section_slug;
    });
    for (var i = 0; i < selectedItems.length; i++) {
      var tab = selectedItems[i];
      var item = {};
      item.tab_id = tab.tab_id;
      item.alias = tab.alias;
      item.label = tab.label;
      item.infobox = tab.infobox;
      item.order = tab.tab_order;
      item.sections = [];
      var index = checks.indexOf(tab.tab_id);
      if (index == -1) {
        items.push(item);
      }
      checks.push(tab.tab_id);
    }

    items.forEach(function(item) {
      selectedItems.forEach(function(selected) {
        if (item.tab_id === selected.tab_id) {
          var select = {};
          select.alias = selected.section_alias;
          select.label = selected.section_label;
          select.info = selected.section_info;
          select.order = selected.section_order;
          select.slug = selected.section_slug;
          item.sections.push(select);
        }
      });
    });
    state.selectedTabs = items;
  },
  GET_GROUPING(state, datas) {
    var items = [];
    var checks = [];
    var results = datas.all;
    var selectedItems = datas.selected || [];
    var appTypeId = datas.application_type_id;
    for (var i = 0; i < results.length; i++) {
      var tab = results[i];
      var item = {};
      item.id = tab.tab_id;
      item.infobox = tab.tab_inbobox;
      item.alias = tab.tab_alias;
      item.label = tab.tab_label;
      item.sections = [];
      var index = checks.indexOf(tab.tab_id);
      if (index == -1) {
        items.push(item);
      }
      checks.push(tab.tab_id);
    }
    items.forEach(function(vTab, key) {
      for (var i = 0; i < results.length; i++) {
        var tab = results[i];
        var section = {};
        section.section_id = tab.section_id;
        section.infobox = tab.section_info;
        section.alias = tab.section_alias;
        section.label = tab.section_label;
        section.slug = tab.section_slug;
        section.order = null;
        section.checked = false;
        if (selectedItems.length > 0) {
          for (var n = 0; n < selectedItems.length; n++) {
            if (parseFloat(selectedItems[n]["section_id"]) === parseFloat(tab.section_id) && parseFloat(selectedItems[n]["application_type_id"]) === parseFloat(appTypeId)) {
              section.order = selectedItems[n]["order"];
              section.checked = true;
            }
          }
        }
        if (tab.tab_id === vTab.id) {
          vTab.sections.push(section);
        }
      }
    });
    state.confs = items;
  },
  GET_DATAS(state, datas) {
    datas.map(function(row) {
      row.sectionTmps = [];
      if (row.sections != null) {
        row.sectionTmps = JSON.parse(row.sections);
      }
    });
    state.tabs = datas;
  },
  GET_CONFIFS_WITH_SELECTED(state, datas) {
    datas.all.map(function(row) {
      row.sectionTmps = [];
      if (row.sections != null) {
        row.sectionTmps = JSON.parse(row.sections);
        row.sectionTmps.map(function(section) {
          section.checked = false;
          datas.selected.map(function(vRow) {
            if (vRow.sections != null) {
              var maps = JSON.parse(vRow.sections);
              maps.map(function(vSection) {
                if (vSection.sections != undefined) {
                  vSection.sections.map(function(vNode) {
                    if (vNode.keyVal === section.keyVal) {
                      row.alias = vSection.alias;
                      section.checked = true;
                      section.order = vNode.order;
                    }
                  });
                }
              });
            }
          });
        });
      }
    });
    state.tabs = datas.all;
  },
  GET_CONFIFS_BY_AT(state, datas) {
    var items = [];
    var tmpSections = [];
    datas.all.map(function(row) {
      var alias = row.alias;
      row.sectionTmps = [];
      if (row.sections != null) {
        row.sectionTmps = JSON.parse(row.sections);
        tmpSections = [];
        row.sectionTmps.map(function(section) {
          section.checked = false;
          datas.selected.map(function(vRow) {
            if (vRow.sections != null) {
              var maps = JSON.parse(vRow.sections);
              maps.map(function(vSection) {
                if (vSection.sections && vSection.sections.length > 0) {
                  vSection.sections.map(function(vNode) {
                    if (vNode.keyVal === section.keyVal) {
                      alias = vSection.alias;
                      tmpSections.push(vNode);
                    }
                  });
                }
              });
            }
          });
        });
        var data = orderObjectBy(tmpSections, "order", 1);
        if (tmpSections.length > 0) {
          items.push({
            label: row.label,
            alias: row.alias,
            infobox: row.infobox,
            store: alias,
            sections: data
          });
        }
      }
    });
    state.tabs = items;
  },

  GET_CONF(state, data) {
    state.ui_conf_id = data[0] != undefined ? data[0]["id"] : undefined;
  },
  GET_DATA(state, data) {
    state.tab = data;
  },
  ADD_DATA(state, data) {
    let idx = state.tabs.map((p) => p.id).indexOf(data.id);
    state.tabs.splice(idx, 1, data);
  },
  UPDATE_DATA(state, data) {
    let idx = state.tabs.map((p) => p.id).indexOf(data.id);
    state.tabs.splice(idx, 1, data);
  },
  FAILURE_DATAS(state) {
    state.tabs = [];
  },
  FAILURE_DATA(state, data) {
    state.tab = {};
  },
  DELETE_DATA(state, data) {
    let idx = state.tabs.map((p) => p.id).indexOf(data.id);
    state.tabs.splice(idx, 1);
  },
  EMPTY(state, data) {
    state.tabs = [];
  }
};
