<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ $t('title.imm_0008_details_visit_canada') }}</span>
      <v-spacer />              
    </v-card-title>
    <v-card-text>
      <v-row dense class="medium-space">
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'purpose_visit')">
          <el-form-item :label="$t('imm_0008.purpose_visit')" prop="purpose_visit">
            <el-select @change="saveIMM(form)" v-model="form.purpose_visit" placeholder="Select" style="width: 100%;">
              <el-option :value="item.name" v-for="(item, index) in status" v-bind:key="index">{{ item.name }}</el-option>
            </el-select>
          </el-form-item>
        </v-col>
        <template v-if="form.purpose_visit === 'Other'">
          <v-col cols="12" sm="6" md="8" v-if="dataValidation(form, 'purpose_visit_other')">
            <el-form-item :label="$t('imm_0008.purpose_visit_other')" prop="purpose_visit_other">
              <el-input @change="saveIMM(form)" v-model="form.purpose_visit_other" style="width: 100%;" />
            </el-form-item>
          </v-col>
        </template>
      </v-row>
      <v-row dense class="medium-space">
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'int_from')">
          <el-form-item :label="$t('imm_0008.int_from')" prop="int_from">
            <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.int_from" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'int_to')">
          <el-form-item :label="$t('imm_0008.int_to')" prop="int_to">
            <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.int_to" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </v-col>
      </v-row>
      <v-row dense class="medium-space">
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'funds_available')">
          <el-form-item :label="$t('imm_0008.funds_available')" prop="funds_available">
            <el-input @change="saveIMM(form)" type="number" v-model="form.funds_available">
              <template slot="prepend">$</template>
            </el-input>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'expenses')">
          <el-form-item label="My expenses in Canada will be paid by" prop="expenses">
            <el-select @change="saveIMM(form)" v-model="form.expenses" placeholder="Select" style="width: 100%;">
              <el-option :value="item" v-for="(item, index) in expenses" v-bind:key="index">{{ item }}</el-option>
            </el-select>
          </el-form-item>
        </v-col>
        <template v-if="form.expenses === 'Other'">
          <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'expenses_other')">
            <el-form-item label="Other" prop="expenses_other">
              <el-input @change="saveIMM(form)" v-model="form.expenses_other" style="width: 100%;" />
            </el-form-item>
          </v-col>
        </template>
      </v-row>
      <visit-canada-detail :memberUID="memberUID" :memberID="memberID" :datas="datas"></visit-canada-detail>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import VisitCanadaDetail from './visit_canada_detail'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    },
    memberUID: {
      type: String
    },
    memberID: {
      type: String
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      expenses: ['Myself', 'Parents', 'Other']
    }
  },
  computed: mapGetters({
    status: 'other/purposeMyVisit'
  }),
  components: {
    VisitCanadaDetail
  },
  mounted() {
    if (this.isDataValidation) {
      var item = {
        purpose_visit: null,
        funds_available: null,
        purpose_visit_other: null,
        int_from: null,
        int_to: null
      }

      if (this.form.purpose_visit !== 'Other') {
        delete item.purpose_visit_other
      }

      this.checkItem(this.form, item) 
    }
  }
}
</script>