<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
    </v-card-title>
    <v-card-text>
      <v-alert type="info" border="left" dense class="mb-2" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <el-form :model="form" ref="form">
        <template v-if="sectionSlug === '195'">
          <v-row dense class="mt-4 radio-parent" v-if="dataValidation(form, 'spouce_children')">
            <v-col cols="12" sm="6" md="7">
              <label class="el-form-item__label mt-2">Do you or your spouse/common-law partner have any children?</label>
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <el-form-item prop="spouce_children">
                <el-radio-group v-model="form.spouce_children" @change="checkApplicable(form)">
                  <el-radio label="No">No</el-radio>
                  <el-radio label="Yes">Yes</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <template v-if="form.spouce_children == 'Yes'">
            <v-row dense class="mt-4 radio-parent" v-if="dataValidation(form, 'many_children')">
              <v-col cols="12" sm="6" md="7">
                <label class="el-form-item__label mt-2">How many children do you or your spouse/common-law partner have?</label>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <el-form-item prop="many_children">
                  <el-input type="number" v-model="form.many_children" @change="checkApplicable(form)" style="width: 100%;" />
                </el-form-item>
              </v-col>
            </v-row>
          </template>
        </template>

        <template v-if="sectionSlug === '197'">
          <v-row dense class="mt-4 radio-parent" v-if="dataValidation(form, 'family_members')">
            <v-col cols="12" sm="6" md="7">
              <label class="el-form-item__label mt-2">How many family members do you have (principal applicant, spouse or partner,dependent children, and their dependent children)?</label>
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <el-form-item prop="family_members">
                <el-input v-model="form.family_members" @change="checkApplicable(form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="mt-4 radio-parent" v-if="dataValidation(form, 'funds')">
            <v-col cols="12" sm="6" md="7">
              <label class="el-form-item__label">What funds (Canadian $) do you have available to bring to Canada to support family?</label>
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <el-form-item prop="funds">
                <el-input type="number" @change="checkApplicable(form)" v-model="form.funds">
                  <template slot="prepend">$</template>
                </el-input>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="mt-4 radio-parent" v-if="dataValidation(form, 'check_relative_in_canada')">
            <v-col cols="12" sm="6" md="7">
              <label class="el-form-item__label">Do you have a relative in Canada who is 18 years of age or older and is a Canadian citizen or Permanent Resident living in Canada?</label>
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <el-form-item prop="check_relative_in_canada_check">
                <el-radio-group class="ml-3 mt-2" v-model="form.check_relative_in_canada_check" @change="checkApplicable(form)">
                  <el-radio value="Yes" label="Yes">Yes</el-radio>
                  <el-radio value="No" label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense v-if="dataValidation(form, 'relative_in_canada_text')">
            <v-col cols="12" v-if="form.check_relative_in_canada">
              <el-input class="mt-3" v-model="form.relative_in_canada_text" @change="checkApplicable(form)" style="width: 100%;" />
            </v-col>
          </v-row>
        </template>
      </el-form>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    sectionId: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    },
    isDataValidation: {
      type: Boolean
    },
    isOINP: {
      type: Boolean
    }
  },
  data() {
    return {
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        id: undefined,
        spouce_children: null,
        many_children: null,
        family_members: null,
        funds: null,
        check_relative_in_canada: null,
        relative_in_canada_text: null,
        sec_org_id: parseInt(localStorage.getItem('org_id'))
      },
      url: 'imms_table/application_family_info_summary',
      getUrl: `table/application_family_info_summary?filter=member_uid=${this.memberUID}`
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$store.dispatch('store/get', `${this.getUrl}`)
        .then(response => {
          if (response.resource.length > 0) {
            this.form = response.resource[0]
            this.form.check_relative_in_canada_check = this.form.check_relative_in_canada ? 'Yes' : 'No'

            if (this.isDataValidation) {
              this.checkRow(response.resource[0])
            }
          }
          else {
            if (this.isDataValidation) {
              this.checkRow(this.form)
            }
          }
        })
    },
    checkRow(data) {
      var form = {
        spouce_children: null,
        many_children: null,
        family_members: null,
        funds: null,
        check_relative_in_canada: null,
        relative_in_canada_text: null
      }

      if (this.sectionSlug === '195') {
        delete form.family_members
        delete form.funds
        delete form.check_relative_in_canada
        delete form.relative_in_canada_text
      }

      if (this.sectionSlug === '197') {
        delete form.spouce_children
        delete form.many_children
      }

      if (data.spouce_children !== 'Yes') {
        delete form.many_children
      }

      if (!this.isOINP) {
        delete form.check_relative_in_canada
        delete form.relative_in_canada_text
        delete form.funds
      }
      this.checkItem(data, form)
    },
    checkApplicable(form) {
      if (form.check_relative_in_canada_check == 'Yes') {
        form.check_relative_in_canada = true
      }
      else if (form.check_relative_in_canada_check == 'No') {
        form.check_relative_in_canada = false
      }

      this.autoSaveSubmit(form)
    }
  }
}
</script>

<style>
  .el-form-item__label{
    line-height: 20px;
    text-align: left;
  }
</style>
