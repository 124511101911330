import api from './../plugins/axios';
import axios from 'axios';
import constants from '../constants.js';

export const state = {
  applications: [],
  application: {},
  appType: [],
  appProcesses: [],
  files: [],
  processInputes: [
    { field: 'date_at_submitted', label: 'App Submit Date', type: 'date' },
    { field: 'date_hired', label: 'Date Hired', type: 'date' },
    { field: 'xxx', label: 'Arrival Date', type: 'date' },
    { field: 'expiry_date', label: 'Expiry Date', type: 'date' },
    { field: 'gov_portal_login', label: 'Portal Login', type: 'text' },
    { field: 'gov_portal_password', label: 'Portal Password', type: 'text' },
    { field: 'xxx', label: 'File No Got', type: 'date' },
    { field: 'cic_app_number', label: 'File Number', type: 'text' },
    { field: 'xxx', label: 'File Sent To', type: 'date' },
    { field: 'originals_mailed', label: 'Originals Mailed Date', type: 'date' },
    { field: 'xxx', label: 'Application Mailed', type: 'date' },
    { field: 'track_no', label: 'Tracking No', type: 'text' },
    {
      field: 'app_delivered_date',
      label: 'Application Delivered',
      type: 'date',
    },
    { field: 'approval_date', label: 'Approval Date', type: 'date' },
    {
      field: 'nomination_expiry_date',
      label: 'Nomination Expiried',
      type: 'date',
    },
    { field: 'medical_exam_date', label: 'Medical Examination', type: 'date' },
    {
      field: 'police_certificate_requested',
      label: 'Police Certificate Requested',
      type: 'date',
    },
    {
      field: 'police_certificate_submitted',
      label: 'Police Certificate Submitted',
      type: 'date',
    },
    {
      field: 'other_request_received',
      label: 'Other Requests Date Received',
      type: 'date',
    },
    {
      field: 'other_request_submitted',
      label: 'Other Request Submitted',
      type: 'date',
    },
    {
      field: 'landing_fee_requested',
      label: 'Landing Fee (RPRF) Requested',
      type: 'date',
    },
    {
      field: 'landing_fee_paid',
      label: 'Landing Fee (RPRF) paid',
      type: 'date',
    },
    { field: 'passport_request', label: 'Passport Request', type: 'date' },
    {
      field: 'got_confirmation_docs',
      label: 'Got confirmation docs',
      type: 'date',
    },
    { field: 'sent_type', label: 'Sent Type', type: 'text' },
    { field: 'sent_to', label: 'Sent To', type: 'text' },
  ],
  notificationTypes: [
    { text: 'Note', value: 'note' },
    { text: 'Message', value: 'message' },
    { text: 'Status', value: 'application_status' },
    { text: 'Meeting', value: 'meeting' },
    { text: 'Accounting', value: 'accounting' },
    { text: 'Task', value: 'task' },
    { text: 'Email', value: 'Email' },
    { text: 'Login History', value: 'login_history' },
    { text: 'Staff', value: 'assigned_staff' },
    { text: 'File', value: 'file_history' },
    { text: 'Application Info', value: 'application' },
  ],
  statuses: [
    { text: 'Created', value: constants.APP_STATUS_CREATED },
    { text: 'Purchased', value: constants.APP_STATUS_PURCHASED },
    { text: 'RCIC onboarding', value: constants.APP_STATUS_RCIC_ONBOARDING },
    {
      text: 'Forms/Recruitment',
      value: constants.APP_STATUS_FORMS_RECRUITMENT,
    },
    { text: 'Review', value: constants.APP_STATUS_REVIEW },
    {
      text: 'Waiting for final payment',
      value: constants.APP_STATUS_WAITING_FOR_PAYMENT,
    },
    { text: 'Ready to Submit', value: constants.APP_STATUS_READY_TO_SUBMIT },
    { text: 'Submitted', value: constants.APP_STATUS_SUBMITTED },
    { text: 'Follow up', value: constants.APP_STATUS_FOLLOW_UP },
    { text: 'Completed', value: constants.APP_STATUS_COMPLETE },
    { text: 'Archived', value: constants.APP_STATUS_ARCHIVED },
  ],
  applicationMenus: [
    { text: 'Edit', icon: 'edit', alias: 'edit', condition: 'isAll' },
    {
      text: 'Change organization',
      icon: 'swap_horiz',
      alias: 'organization',
      condition: 'isOfas',
    },
    {
      text: 'Choose a specific employer',
      icon: 'insert_drive_file',
      alias: 'employer',
      condition: 'isOINP',
    },
    {
      text: 'Run Robot',
      icon: 'picture_as_pdf',
      alias: 'pdf_generate',
      condition: '!isApplicant',
    },
    {
      text: 'Processing Details',
      icon: 'verified_user',
      alias: 'processing_details',
      condition: '!isApplicant',
    },
    {
      text: 'Configure Name',
      icon: 'label',
      alias: 'configure_name',
      condition: '!isApplicant',
    },
    {
      text: 'Family member',
      icon: 'people',
      alias: 'family_member',
      condition: 'isAll',
    },
    {
      text: 'Configure Family Member',
      icon: 'settings',
      alias: 'configue_member',
      condition: '!isApplicant',
    },
    {
      text: 'Application Type Change',
      icon: 'info',
      alias: 'app_type',
      condition: 'isConsultantAdmin',
    },
    {
      text: 'Run data validation',
      icon: 'description',
      alias: 'data_validation',
      condition: '!isApplicant',
    },
    {
      text: 'Make payment',
      icon: 'credit_card',
      alias: 'remainder',
      condition: 'Remainder',
    },
    {
      text: 'Assigned user',
      icon: 'assignment_ind',
      alias: 'assigned_user',
      condition: 'isConsultantAdmin',
    },
    {
      text: 'Schedule a video meeting',
      icon: 'today',
      alias: 'schedule_meeting',
      condition: 'isConsultant || isStaff',
    },
    {
      text: 'Send email',
      icon: 'mail_outline',
      alias: 'send_email',
      condition: 'exceptApplicant',
    },
    {
      text: 'Robot Files',
      icon: 'insert_drive_file',
      alias: 'robot',
      condition: '!isApplicant',
    },
    {
      text: 'Documents',
      icon: 'insert_drive_file',
      alias: 'document',
      condition: '!isApplicant',
    },
    { text: 'Delete', icon: 'delete', alias: 'remove', condition: 'isAdmin' },
    {
      text: 'Create Application',
      icon: 'apps',
      alias: 'create_apps_ircc',
      condition: 'isAdmin',
    },
  ],
  decisionTypes: [
    { text: 'Approved', value: 'Approved' },
    { text: 'Refused', value: 'Refused' },
    { text: 'Terminated', value: 'Terminated' },
  ],
  formApplicationStatus: {
    id: null,
    imms_uuid: null,
    status: null,
    date_at_purchased: null,
    days_in_purchased: 0,
    date_at_rcic_onboarding: null,
    days_in_rcic_onboarding: 0,
    date_at_forms_recruitment: null,
    days_in_forms_recruitment: 0,
    date_at_review: null,
    days_in_review: 0,
    date_at_waiting_for_final_payment: null,
    days_in_waiting_for_final_payment: 0,
    date_at_ready_to_submit: null,
    days_in_ready_to_submit: 0,
    date_at_submitted: null,
    days_in_submitted: 0,
    date_at_follow_up: null,
    days_in_follow_up: 0,
    date_at_completed: null,
    days_in_completed: 0,
    date_at_archived: null,
    days_in_archived: 0,
    processing_days: 0,
  },
};

export const getters = {
  getAppMenus(state) {
    return state.applicationMenus;
  },
  all(state) {
    return state.applications;
  },
  getOne(state) {
    return state.application;
  },
  getAppType(state) {
    return state.appType;
  },
  processes(state) {
    return state.appProcesses;
  },
  processInputes(state) {
    return state.processInputes;
  },
  getFiles(state) {
    return state.files;
  },
  getStatUses(state) {
    return state.statuses;
  },
  actions(state) {
    return state.actions;
  },
  getDecisionTypes(state) {
    return state.decisionTypes;
  },
  getApplicationStatusDays(state) {
    return state.formApplicationStatus;
  },
};

export const actions = {
  getStatUses({ commit }, filter) {
    return state.statuses;
  },
  getAppType({ commit }, id) {
    return api
      .get('table/application_types?order=display_order ASC,name ASC')
      .then(
        (response) => {
          commit('GET_APP_TYPE', response.data.resource);
          return Promise.resolve(response);
        },
        (err) => {
          commit('FAILURE_DATAS', err);
          return Promise.reject(err);
        },
      );
  },
  getAll({ commit }, filter) {
    return api.get('table/application_list_org_view' + filter).then(
      (response) => {
        commit('GET_DATAS', response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit('FAILURE_DATAS', err);
        return Promise.reject(err);
      },
    );
  },
  getOne({ commit }, filter) {
    return api.get('table/application_list_view' + filter).then(
      (response) => {
        commit('GET_DATA', response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit('FAILURE_DATAS', err);
        return Promise.reject(err);
      },
    );
  },
  getFiles({ commit }, url) {
    return axios.get(url).then(
      (response) => {
        commit('GET_FILES', response.data);
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  getProcess({ commit }, filter) {
    return api.get('table/process_tables_view' + filter).then(
      (response) => {
        commit('GET_APP_PROCESSS', response.data.resource);
        return Promise.resolve(response.data.resource);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  save({ commit }, data) {
    return api.post('application_management/applications', data).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        commit('FAILURE_DATAS', err);
        return Promise.reject(err);
      },
    );
  },
  edit({ commit }, data) {
    return api.put('table/applications/' + data.id, data).then(
      (response) => {
        commit('UPDATE_DATA', data);
        return Promise.resolve(response);
      },
      (err) => {
        commit('FAILURE_DATAS', err);
        return Promise.reject(err);
      },
    );
  },
  setStatuses({ commit }, data) {
    commit('SET_STATUS', data);
  },
  setApplicationStatusDays({ commit }, payload) {
    commit('SET_APPLICATION_STATUS_DAYS', payload);
  },
};

export const mutations = {
  GET_FILES(state, datas) {
    state.files = datas;
  },
  GET_APP_PROCESSS(state, datas) {
    state.appProcesses = datas;
  },
  GET_APP_TYPE(state, datas) {
    datas.forEach(function(element) {
      element.count = undefined;
    });
    state.appType = datas;
  },
  FAILURE_APP_TYPE(state) {
    state.appType = [];
  },
  GET_DATAS(state, datas) {
    state.applications = datas;
  },
  GET_DATA(state, data) {
    state.application = data[0] || {};
  },
  UPDATE_DATA(state, data) {
    state.application = data;
  },
  FAILURE_DATAS(state) {
    state.applications = [];
  },
  FAILURE_DATA(state, data) {
    state.application = {};
  },
  DELETE_DATA(state, data) {
    let idx = state.applications.map((p) => p.id).indexOf(data.id);
    state.applications.splice(idx, 1);
  },
  SET_STATUS(state, datas) {
    state.statuses = datas;
  },
  SET_APPLICATION_STATUS_DAYS(state, datas) {
    state.formApplicationStatus = {
      ...datas,
    };
  },
};
