<template>
  <div>
    <v-card v-if="hideSection" class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="yukon-checkbox">
          <el-form-item prop="false_info" v-if="dataValidation(form, 'false_info')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.false_info"></el-checkbox>
            <label class="ml-4">I understand that any false statements, or concleament of information may result in Yukon refusing my application or, if applicable my nomination. It may also result in my expulsion from Canada and may be grounds for my prosecution or, cause my nomination to be revoked.</label>
          </el-form-item>
          <el-form-item prop="intent_to_live_yukon" v-if="dataValidation(form, 'intent_to_live_yukon')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.intent_to_live_yukon"></el-checkbox>
            <label class="ml-4">I intend to live in Yukon on a permanent basis.</label>
          </el-form-item>
          <el-form-item prop="no_fee" v-if="dataValidation(form, 'no_fee')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.no_fee"></el-checkbox>
            <label class="ml-4">I declare that I have not, directly or indirectly, paid the employer a fee for submitting an application to the YNP.</label>
          </el-form-item>
          <el-form-item prop="tpa" v-if="dataValidation(form, 'tpa')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.tpa"></el-checkbox>
            <label class="ml-4">I confirm my agreement to the Guaranteed Employment Offer (GEO) or Section 2 of this application.</label>
          </el-form-item>
          <el-form-item prop="collect_personal_info_inside" v-if="dataValidation(form, 'collect_personal_info_inside')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.collect_personal_info_inside"></el-checkbox>
            <label class="ml-4">I agree to sign a Tri-Partite Agreement (TPA) (that outlines my responsibilities under the Yukon Nominee Program). I understand that any breach of the terms of the TPA may result in the revocation of my nomination.</label>
          </el-form-item>
          <el-form-item prop="collect_personal_info_outside" v-if="dataValidation(form, 'collect_personal_info_outside')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.collect_personal_info_outside"></el-checkbox>
            <label class="ml-4">I authorize the YNP to collect personal information from other sources inside or outside Canada ofr the purpose of assessing this application for the YNP, verifying information provided by me in my application, and evaluating the YNP. These sources may include, but are not limited to professional organizations, indusrty associations, educational institutions, financial institutions, government or quasi government agencies, and law enforcement agencies.</label>
          </el-form-item>
          <el-form-item prop="collect_edu_pre_employer_info" v-if="dataValidation(form, 'collect_edu_pre_employer_info')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.collect_edu_pre_employer_info"></el-checkbox>
            <label class="ml-4">I consent to the YNP disclosing my personal information to such other sources inside or outside Canada fro the purpose of the assessing my application for the YNP, verifying information provided by me in my application, and evaluating the YNP.</label>
          </el-form-item>
          <el-form-item prop="info_ircc_approval" v-if="dataValidation(form, 'info_ircc_approval')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.info_ircc_approval"></el-checkbox>
            <label class="ml-4">I have contacted my educational institution(s) and previous employer(s) listed in this application to provide the appropriate consent for them to release information to Yukon government officers for the purposes of assessing this application to the Yukon Nominee Program.</label>
          </el-form-item>
          <el-form-item prop="business_good_standing_yukon" v-if="dataValidation(form, 'business_good_standing_yukon')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.business_good_standing_yukon"></el-checkbox>
            <label class="ml-4">I understand that my employer who was exempt from the Labour Market Impact Assessment (LMIA) process must submit the offer of employment information and pay the compliance fee using IRCC Portal: http://www.cic.gc.ca/english/e-services/employer-portal.asp. This will need to be done before I can apply for an employer-specific work permit, at a Mission overseas or in-Canada office.</label>
          </el-form-item>
          <el-form-item prop="foregoing_statement" v-if="dataValidation(form, 'foregoing_statement')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.foregoing_statement"></el-checkbox>
            <label class="ml-4">I understand all the foregoing statements. I have asked for and obtained an explanation fro every point which was not clear to me.</label>
          </el-form-item>
          <el-form-item prop="info_true" v-if="dataValidation(form, 'info_true')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.info_true"></el-checkbox>
            <label class="ml-4">I declare that the information I have given in this application is truthful, complete and correct.</label>
          </el-form-item>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  mixins: [autosave],
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        false_info: null,
        intent_to_live_yukon: null,
        no_fee: null,
        guaranteed_employer_offer: null,
        tpa: null,
        collect_personal_info_inside: null,
        collect_personal_info_outside: null,
        collect_edu_pre_employer_info: null,
        info_ircc_approval: null,
        foregoing_statement: null,
        info_true: null
      },
      url: 'imms_table/lmia_yukon_immigrant_declaration',
      getUrl: 'imms_table/lmia_yukon_immigrant_declaration?application_imms_uuid=' + this.$route.params.id
    }
  },
  mounted() {
    this.fetch().then(data => {
      if (data.items != undefined && data.items.length > 0) {
        if (this.isDataValidation) {
          this.checkRow(data.items[0])
        }
        this.form = data.items[0]
      }
      else {
        if (this.isDataValidation) {
          this.checkRow(this.form)
        }
      }
    })
  }
}
</script>
