
<template>
  <div class="mt-3">
    <v-card>
      <v-card-text class="p-n">
        <v-col cols="12" v-if="check">
          <el-alert
            title="Please check to date"
            type="error">
          </el-alert>
        </v-col>
        <table class="table" style="width:100%">
          <thead>
            <tr>
              <th width="135">Date left Canada</th>
              <th width="135">Date returned <br> to Canada</th>
              <th>Destination</th>
              <th>Reason for absence</th>
              <th width="80">Total # days <br> absent from <br>Canada</th>
              <th width="30">
                <v-btn color="green" small fab @click.native="add()">
                  <v-icon class="white--text">add</v-icon>
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in items" :key="i" :id="'row' + item.id">
              <td class="pa-1">
                <el-date-picker :id="'date_left' + item.id" value-format="yyyy-MM-dd" v-model="item.date_left" @change="handleAutoSave(i, item);getLength(item, i)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </td>
              <td class="pa-1">
                <el-date-picker :id="'date_returned' + item.id" value-format="yyyy-MM-dd" v-model="item.date_returned" @change="handleAutoSave(i, item);getLength(item, i)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </td>
              <td class="pa-1">
                <el-input :id="'destination' + item.id" v-model="item.destination" style="width: 100%" @change="handleAutoSave(i, item)" />
              </td>
              <td class="pa-1">
                <el-input :id="'reason' + item.id" v-model="item.reason" style="width: 100%" @change="handleAutoSave(i, item)" />
              </td>
              <td class="pa-1 text-right">
                <el-input v-model="item.days" disabled style="width: 100%" @change="handleAutoSave(i, item)" />
              </td>
              <td class="pa-1 text-center">
                <v-btn color="pink" small fab @click.native="deleteOpen(item, i)"><v-icon class="white--text">clear</v-icon></v-btn>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td colspan="4" class="text-right"><b class="mr-3">Box I</b>Total number of days absent from Canada BEFORE becoming a permanent <br> resident, while holding temporary resident or protected person status.</td>
              <td class="pa-1 text-right"><b class="pl-2 pr-2">{{ totalDays }}</b></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="allSaveForm()">{{ $t('global.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { checkLength } from '@/utils/helpers'
import autosave from '@/mixins/autosave.js'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    form: {
      type: Object
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [autosave, datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        days: null,
        destination: null,
        reason: null,
        date_left: null,
        date_returned: null
      },
      check: false,
      url: 'imms_table/application_absent_canada',
      state: 'absentCanada',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    },
    totalDays: function(val) {
      this.item.box_i = val
    }
  },
  computed: {
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    },
    totalDays: function() {
      var count = 0
      if (this.item.absences_canada === 'Yes') {
        this.items.forEach(function(item) {
          if (item.days === null || item.days === undefined) item.days = 0
          count += parseFloat(item.days)
        })
      }
      return count
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    checkDate: function(event) {
      this.$emit('checkDate', event)
    },
    getLength(item, index) {
      if (item.date_left && item.date_returned) {
        let length = checkLength(this.item.box_b, this.item.box_d, item.date_left, item.date_returned, -1)

        if (length > 0) {
          item.days = length
          this.check = false
        }
        else {
          this.check = true
          item.date_returned = null
        }
      }
    },
    fetchData() {
      this.$Progress.start()
      var url = `table/application_absent_canada?filter=member_uid=${this.memberUID}` + '&order=date_left ASC,date_returned ASC'
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) this.checkTableFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        days: null,
        destination: null,
        reason: null,
        date_left: null,
        date_returned: null
      }
      this.edit = false
    },
    add() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
    },
    allSaveForm() {
      if (this.items.length > 0) {
        this.loading = true

        this.items.forEach(function(item) {
          var action = 'all_stores/save'
          if (item.id != undefined) {
            action = 'all_stores/edit'
            item.id = parseFloat(item.id)
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': item, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                item.id = response.data.resource[0].id
                item.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Saved', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }, this)
      }
      else {
        return false
      }
    },
    resetForm() {
      this.$refs.item.resetFields()
      this.nodeClear()
    }
  }
}
</script>
