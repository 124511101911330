export const state = {
  cartTotal: 0,
  cart: [],
  consultant: {},
  total: 0,
  user: {},
  cartView: "ApplicationType"
};

export const getters = {
  all(state) {
    return state.cart;
  },
  getUser(state) {
    return state.user;
  },
  cartView: (state) => {
    return state.cartView;
  }
};

export const actions = {
  setUser(context, payload) {
    context.commit("addUser", payload);
  },
  changeCartView(context, payload) {
    context.commit("changeCartView", payload);
  }
};

export const mutations = {
  changeCartView(state, payload) {
    state.cartView = payload;
  },
  addUser(state, payload) {
    state.user = payload;
  },
  clearCartCount: (state) => {
    state.cartTotal = 0;
  },
  clearCartContents: (state) => {
    state.cart = {};
  },
  addItem: (state, data) => {
    let idx = state.cart.map((p) => p.application_type_id).indexOf(data.application_type_id);
    if (idx == -1) {
      state.cart.push(data);
    }
    state.total = Object.values(state.cart).reduce((acc, el) => acc + el.application_count * el.price, 0).toFixed(2);
  },
  selectCons: (state, item) => {
    state.consultant = item;
  },
  removeItem: (state, item) => {
    state.cartTotal -= item.application_count;
    let idx = state.cart.map((p) => p.application_type_id).indexOf(item.application_type_id);
    state.cart.splice(idx, 1);

    state.total = Object.values(state.cart).reduce((acc, el) => acc + el.application_count * el.price, 0).toFixed(2);
  },
  emptyState: (state) => {
    state.cartTotal = 0;
    state.cart = [];
    state.consultant = {};
    state.total = 0;
  },
  changeCardView: (state, value) => {
    state.cartView = value
  }
};
