<template>
  <v-card v-if="hideSection" class="mt-3">
    <v-card-title>
      <span>{{sectionLabel}}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text class="p-n">
      <v-row dense v-if="!isDataValidation">
        <v-col cols="12" class="mb-3">
          <div class="text13 pull-left">a) Have you held travel documents and or passports during your eligibility period?</div>
          <el-radio-group class="ml-3 mt-1 pull-left" @change="checkCitizenship()" v-model="form.travel_document">
            <el-radio label="Yes">Yes, Complete details below in table</el-radio>
            <el-radio label="No">No, Complete details below in table</el-radio>
          </el-radio-group>
        </v-col>
      </v-row>
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <v-row dense v-if="form.travel_document === 'No'">
        <v-col cols="12" class="mb-3">
          <el-input class="ml-3 mt-1" @change="checkCitizenship()" v-model="form.travel_document_details" type="textarea" />
        </v-col>
      </v-row>
      <table class="table" style="width:100%" v-if="form.travel_document === 'Yes'">
        <thead>
          <tr>
            <th>Document #</th>
            <th>Country of Issue</th>
            <th>Place of Issue</th>
            <th>Date Issued</th>
            <th width="150">Expiry Date</th>
            <th rowspan="2" width="30">
              <v-btn color="green" small fab @click.native="saveForm()">
                <v-icon class="white--text">add</v-icon>
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(form,i) in items" :key="i">
            <td class="pa-1">
              <el-input v-if="dataValidationItems(form, 'document')" v-model="form.document" />
            </td>
            <td class="pa-1">
              <div v-if="dataValidationItems(form, 'country')" class="el-select native-select">
                <div class="el-input">
                  <select v-model="form.country" placeholder="Select" class="el-input__inner" @change="handleAutoSave(i, form)">
                    <option v-for="(item, index) in country" v-bind:key="index" :value="item.country_name" :label="item.country_name">{{ item.country_name }}</option>
                  </select>
                </div>
              </div>
            </td>
            <td class="pa-1">
              <el-input v-if="dataValidationItems(form, 'place')" v-model="form.place" @change="handleAutoSave(i, form)" />
            </td>
            <td class="pa-1">
              <el-date-picker v-if="dataValidationItems(form, 'date_issued')" value="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.date_issued" @change="handleAutoSave(i, form)"></el-date-picker>
            </td>
            <td class="pa-1">
              <el-date-picker v-if="dataValidationItems(form, 'expiry_date')" value="yyyy-MM-dd" v-model="form.expiry_date" @change="handleAutoSave(i, form)"></el-date-picker>
            </td>
            <td class="pa-1 text-center">
              <v-btn color="pink" small fab @click.native="deleteOpen(form, i)">
                <v-icon class="white--text">clear</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="form.travel_document === 'Yes'">If you do not have a travel document or passport that was/is valid during your eligibility period or if there is a gap between
      your passports, please explain why here.</p>
    </v-card-text>
    <v-card-text class="pt-0" v-if="!isDataValidation">
      <v-row dense>
        <v-col cols="12" class="mb-3">
          <div class="text13 pull-left mb-3">b) I hereby give my consent to the Canada Border Services Agency (CBSA) to disclose the details of my history of travel to IRCC
          in order to assist IRCC in determining my citizenship eligibility. I also hereby authorize IRCC to collect the history of
          my travel from the CBSA. </div>
          <el-radio-group class="ml-3 mt-1 pull-left" @change="checkCitizenship()" v-model="form.hereby">
            <el-radio label="Yes">Yes</el-radio>
            <el-radio label="No">No</el-radio>
          </el-radio-group>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  name: 'TravelDocument',
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        country: null,
        document: null,
        place: null,
        date_issued: null,
        expiry_date: null
      },
      url: 'imms_table/application_travel_documents',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      form: 'citizenship/get'
    })
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_travel_documents?filter=member_uid=${this.memberUID}`)
        .then(response => {
          let vm = this
          if (vm.isDataValidation) {
            response.resource.forEach(function(item) {
              var form = {
                country: null,
                document: null,
                place: null,
                date_issued: null,
                expiry_date: null
              }

              var obj = vm.checkFormItems(item, form)

              if (obj != 'empty') {
                vm.items.push(item)
              }
            })

            if (response.resource.length == 0) {
              vm.hideSection = true
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
          else {
            vm.items = response.resource
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    checkCitizenship() {
      this.$store.dispatch('citizenship/edit', this.form)
        .then(response => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Disabled', type: 'success' })
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        country: null,
        document: null,
        place: null,
        date_issued: null,
        expiry_date: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>
