<template>
  <v-card class="mt-3">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{ sectionInfoBox }}
      </v-alert>
      <el-form :model="form" ref="form" label-position="top">
        <v-row dense>
          <v-col cols="12" sm="6">
            <el-form-item label="Which Industry are you in?" prop="industry">
              <el-select id="industry" v-model="form.industry" style="width: 100%">
                <el-option v-for="(type, index) in types" v-bind:key="index" :value="type.name" :label="type.name">{{ type.name }}</el-option>
              </el-select>
            </el-form-item>
          </v-col>
          <v-col cols="12">
            <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm()">
              {{ $t('global.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </el-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      types: [
        { name: 'Foodservices Industry' },
        { name: 'Hotel and Lodging Industry' },
        { name: 'Food and Beverage Processing Industry' },
        { name: 'Manufacturing Industry' },
        { name: 'Truck Driving Industry**' },
        { name: 'Other industry (specify)' }
      ],
      formNode: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        industry: null
      },
      fields: ['industry'],
      url: 'imms_table/prov_semi_worker',
      state: 'semiSkilledWolker',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    item: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    },
    form: function() {
      if (this.item.length > 0) {
        return this.item[0]
      }
      else {
        return this.formNode
      }
    }
  },
  mounted() {
    if (this.datas !== undefined) {
      this.checkFields(this.fields, this.datas, this.sectionId)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/prov_semi_worker?filter=member_uid=${this.memberUID}`
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true

          if (this.form.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': this.form, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                this.form.id = response.data.resource[0].id
                this.form.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    }
  }
}
</script>
