<template>
  <div>
    <v-card v-if="hideSection" class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="yukon-checkbox">
          <v-row dense>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_registered_business_name')">
              <el-form-item prop="company_registered_business_name" label="Registered business name">
                <el-input @change="handleAutoSave(form)" v-model="form.company_registered_business_name" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_operating_as')">
              <el-form-item prop="company_operating_as" label="Operating as (if applicable)">
                <el-input @change="handleAutoSave(form)" v-model="form.company_operating_as" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_date_stablished')">
              <el-form-item prop="company_date_stablished" label="Date established">
                <el-date-picker value-format="yyyy-MM-dd" v-model="form.company_date_stablished" @change="handleAutoSave(form)"></el-date-picker>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_primary_business_language')">
              <el-form-item prop="company_primary_business_language" label="Primary business language">
                <el-select v-model="form.company_primary_business_language" placeholder="Language">
                  <el-option key="lang-english" value="English" label="English">English</el-option>
                  <el-option key="lang-french" value="French" label="French">French</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_webiste')">
              <el-form-item prop="company_webiste" label="Website">
                <el-input @change="handleAutoSave(form)" v-model="form.company_webiste" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'owner_email')">
              <el-form-item prop="company_type_business" label="Type of business">
                <el-input @change="handleAutoSave(form)" v-model="form.company_type_business" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_industry_sector')">
              <el-form-item prop="company_industry_sector" label="Industry/sector">
                <el-input @change="handleAutoSave(form)" v-model="form.company_industry_sector" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_is_business')">
              <el-form-item prop="company_is_business" label="Was this business, or part of this business, acquired through the Yukon Business Nominee Program?">
                <el-select v-model="form.company_is_business" placeholder="">
                  <el-option key="is-business-english" value="Yes" label="Yes">Yes</el-option>
                  <el-option key="is-business-french" value="No" label="No">No</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_is_business_when')">
              <el-form-item prop="company_is_business_when" label="If yes, when? (Provide exact date)">
                <el-date-picker value-format="yyyy-MM-dd" v-model="form.company_is_business_when" @change="handleAutoSave(form)"></el-date-picker>
              </el-form-item>
            </v-col>
          </v-row>
          <div>
            <h3>Mailing Address</h3>
          </div>
          <v-row dense>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_mailing_address')">
              <el-form-item prop="company_mailing_address" label="Address">
                <el-input @change="handleAutoSave(form)" v-model="form.company_mailing_address" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_mailing_city')">
              <el-form-item prop="company_mailing_city" label="City">
                <el-input @change="handleAutoSave(form)" v-model="form.company_mailing_city" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_mailing_province')">
              <el-form-item prop="company_mailing_province" label="Province/Region">
                <el-input @change="handleAutoSave(form)" v-model="form.company_mailing_province" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_mailing_postal_code')">
              <el-form-item prop="company_mailing_postal_code" label="Postal Code">
                <el-input @change="handleAutoSave(form)" v-model="form.company_mailing_postal_code" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_mailing_country')">
              <el-form-item prop="company_mailing_country" label="Country">
                <el-input @change="handleAutoSave(form)" v-model="form.company_mailing_country" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_mailing_phone')">
              <el-form-item prop="company_mailing_phone" label="Phone">
                <el-input @change="handleAutoSave(form)" v-model="form.company_mailing_phone" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_mailing_fax')">
              <el-form-item prop="company_mailing_fax" label="Fax">
                <el-input @change="handleAutoSave(form)" v-model="form.company_mailing_fax" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_mailing_email')">
              <el-form-item prop="company_mailing_email" label="Email Address">
                <el-input @change="handleAutoSave(form)" v-model="form.company_mailing_email" />
              </el-form-item>
            </v-col>
          </v-row>

          <div>
            <h3>Physical address</h3>
          </div>
          <v-row dense>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_physical_address')">
              <el-form-item prop="company_physical_address" label="Address">
                <el-input @change="handleAutoSave(form)" v-model="form.company_physical_address" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_physical_city')">
              <el-form-item prop="company_physical_city" label="City">
                <el-input @change="handleAutoSave(form)" v-model="form.company_physical_city" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_physical_province')">
              <el-form-item prop="company_physical_province" label="Province/Region">
                <el-input @change="handleAutoSave(form)" v-model="form.company_physical_province" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_physical_postal_code')">
              <el-form-item prop="company_physical_postal_code" label="Postal Code">
                <el-input @change="handleAutoSave(form)" v-model="form.company_physical_postal_code" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_physical_country')">
              <el-form-item prop="company_physical_country" label="Country">
                <el-input @change="handleAutoSave(form)" v-model="form.company_physical_country" />
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  mixins: [autosave],
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        company_registered_business_name: null,
        company_operating_as: null,
        company_date_stablished: null,
        company_primary_business_language: null,
        company_webiste: null,
        company_type_business: null,
        company_industry_sector: null,
        company_is_business: null,
        company_is_business_when: null,

        company_mailing_address: null,
        company_mailing_city: null,
        company_mailing_province: null,
        company_mailing_postal_code: null,
        company_mailing_country: null,
        company_mailing_fax: null,
        company_mailing_email: null,
        company_mailing_phone: null,

        company_physical_address: null,
        company_physical_city: null,
        company_physical_province: null,
        company_physical_postal_code: null,
        company_physical_country: null
      },
      url: 'imms_table/lmia_yukon_app',
      getUrl: 'imms_table/lmia_yukon_app?application_imms_uuid=' + this.$route.params.id
    }
  },
  mounted() {
    this.fetch().then(data => {
      if (data.items != undefined && data.items.length > 0) {
        if (this.isDataValidation) {
          this.checkRow(data.items[0])
        }
        this.form = data.items[0]
      }
      else {
        if (this.isDataValidation) {
          this.checkRow(this.form)
        }
      }
    })
  }
}
</script>
