<template>
  <v-card class="mt-3" v-if="!isDataValidation">
    <v-card-title>
      <span>{{sectionLabel}}</span>
    </v-card-title>
    <v-card-text class="p-n">
      <el-form :model="form" ref="form" label-position="top">
        <v-row dense>
          <v-col cols="12" class="mb-3">
            <p>Please read the situations that prevent you from becoming a Canadian citizen and select the applicable statement below.</p>
              <ol>
                <li>Are you now?
                  <ul>
                    <li>On Probation?</li>
                    <li>On parole?</li>
                    <li>Serving a term of imprisonment?</li>
                  </ul>
                </li>
                <li>Are you now serving a sentence outside Canada for an offence?</li>
                <li>Are you now charged with, on trial for, or subject to or a party to an appeal relating to:
                  <ul>
                    <li>An offence under the Citizenship Act or an indictable offence in Canada?</li>
                    <li>An offence outside Canada?</li>
                  </ul>
                </li>
                <li>4. Are you now, or have you ever been, under a removal order (have you been asked by Canadian officials to leave Canada)?</li>
                <li>Are you now under investigation for, charged with, on trial for, subject to or a party to an appeal relating to, or have
                you been convicted of a war crime or a crime against humanity?</li>
                <li>Have you directly or indirectly misrepresented or withheld material circumstances relating to this application, or in the
                5 years immediately before the date of your citizenship application, were you prohibited from being granted citizenship or
                taking the Oath because of misrepresentation or withholding material circumstances?</li>
                <li>In the 10 years immediately before the date of your citizenship application, was your Canadian citizenship taken away (revoked)
                for reasons of false representation, fraud, or knowingly concealing material circumstances?</li>
                <li>In the 4 years immediately before the date of your citizenship application or since you submitted your application, have
                you:
                  <ol>
                    <li>Been convicted of an indictable offence under any Act of Parliament or an offence under the Citizenship Act?</li>
                    <li>Been convicted of an offence outside Canada, regardless of whether you were pardoned or otherwise granted amnesty for the
                    offence?</li>
                    <li>While a permanent resident, have you:</li>
                    <li>Been convicted in Canada of terrorism, high treason, treason, or spying offences? • Been convicted outside Canada of terrorism?</li>
                    <li>Served as a member of an armed force of a country or an organized armed group and that country or group engaged in armed
                    conflict with Canada?</li>
                  </ol>
                </li>
              </ol>
          </v-col>
          <v-col cols="12" class="mb-3">
            <el-form-item prop="none_situations">
              <el-checkbox id="none_situations" @change="checkCitizenship()" v-model="form.none_situations" label="a) I have read the situations that prevent a person from becoming a Canadian citizen and declare that none of these situations apply to me."></el-checkbox>
            </el-form-item>
          </v-col>
          <v-col cols="12" class="mb-3">
            <el-form-item prop="more_situations">
              <el-checkbox id="more_situations" @change="checkCitizenship()" v-model="form.more_situations" label="b) I have read the situations that prevent a person from becoming a Canadian citizen and declare that one or more of the situations apply to me."></el-checkbox>
            </el-form-item>
        </v-col>
      </v-row>
      </el-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      form: 'citizenship/get'
    })
  }, 
  methods: {
    checkCitizenship() {
      this.$store.dispatch('citizenship/edit', this.form)
        .then(response => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Disabled', type: 'success' })
        })
    }
  }
}
</script>