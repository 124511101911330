<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
      <template v-if="dataValidation(form, 'country_where_applying')">
        <div class="body-2 mr-3">{{ $t('global.same_current_country_residence') }}</div>
        <el-radio-group @change="saveIMM(form)" v-model="form.country_where_applying" class="mt-1">
          <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
          <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
        </el-radio-group>
      </template>
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <v-row dense class="medium-space" v-if="form.country_where_applying === 'No'">
        <v-col cols="12" sm="6" md="3" lg="4" v-if="dataValidation(form, 'applying_country')">
          <el-form-item :label="$t('imm_0008.applying_country')" prop="applying_country">
            <div class="el-select native-select">
              <div class="el-input">
                <select @change="saveIMM(form)" v-model="form.applying_country" placeholder="Select" class="el-input__inner">
                  <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                </select>
              </div>
            </div>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="4" v-if="dataValidation(form, 'applying_country_status')">
          <el-form-item :label="$t('imm_0008.applying_country_status')" prop="applying_country_status">
            <el-select @change="saveIMM(form)" v-model="form.applying_country_status" placeholder="Select" style="width: 100%;">
              <el-option :value="item.name" v-for="(item, index) in status" v-bind:key="index">{{ item.name }}</el-option>
            </el-select>
          </el-form-item>
        </v-col>
        <template v-if="form.applying_country_status !== 'Citizen'">
          <v-col cols="12" sm="6" md="3" lg="2" v-if="dataValidation(form, 'applying_country_from')">
            <el-form-item :label="$t('imm_0008.applying_country_from')" prop="applying_country_from">
              <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.applying_country_from" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2" v-if="dataValidation(form, 'applying_country_to')">
            <el-form-item :label="$t('imm_0008.applying_country_to')" prop="applying_country_to">
              <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.applying_country_to" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      status: 'other/status'
    })
  },
  mounted() {
    if (this.isDataValidation) {
      if (this.form.country_where_applying == 'Yes') {
        this.hideSection = false
      }
      else {
        var item = {
          applying_country: null,
          applying_country_status: null,
          applying_country_from: null,
          applying_country_to: null
        }

        if (this.form.applying_country_status == 'Citizen') {
          delete item.applying_country_to
          delete item.applying_country_from
        }

        this.checkItem(this.form, item)
      }
    }
  }
}
</script>
