import _ from "lodash";
import moment from "moment";

export default {
  data() {
    return {
      selectProvine: false,
      autoSave: false,
      validation: true,
      secOrgID: parseInt(localStorage.getItem("org_id")),
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        }
      },
      validationItems: [],
      validateItem: {},
      hideSection: true
    };
  },
  methods: {
    checkLength(from, to) {
      let frDate = moment(from, "YYYY-MM-DD");
      let toDate = moment(to, "YYYY-MM-DD");
      let length = toDate.diff(frDate, "days");

      return length
    },
    checkRow(data) {
      this.checkItem(data, this.form);
    },
    checkItem(data, form) {
      let vm = this;
      let hideField = {};

      for (let key in data) {
        let index = key in form;

        if (index) {
          if (vm.isEmpty(data[key])) {
            hideField[key] = data[key];
          }
        }
      }
      let size = Object.keys(hideField).length;
      if (size == 0) {
        vm.hideSection = false;
        return "empty";
      }
      else {
        hideField.id = data.id;
        vm.validateItem = hideField;
        vm.hideSection = true;

        return hideField;
      }
    },
    checkFormItems(data, form) {
      let vm = this;
      let hideField = {};

      for (let key in data) {
        let index = key in form;

        if (index) {
          if (vm.isEmpty(data[key])) {
            hideField[key] = data[key];
          }
        }
      }

      let size = Object.keys(hideField).length;

      if (size == 0) {
        return "empty";
      }
      else {
        hideField.id = data.id;
        vm.validationItems.push(hideField);
        return hideField;
      }
    },
    dataValidation(data, key) {
      if (!this.isDataValidation || key == undefined) {
        return true;
      }

      let size = Object.keys(this.validateItem).length;
      if (size > 0) {
        return key in this.validateItem;
      }
      else {
        return false;
      }
    },
    dataValidationItems(data, key) {
      if (!this.isDataValidation || key == undefined) {
        return true;
      }

      let index = this.validationItems.map((p) => parseInt(p.id)).indexOf(parseInt(data.id));
      if (index == -1) {
        return true;
      }
      else {
        return key in this.validationItems[index];
      }
    },
    isEmpty(e) {
      switch (e) {
        case "":
        case null:
        case typeof this == "undefined":
          return true;
        default:
          return false;
      }
    },
    checkDate(event) {
      if (event.target) event.target.readOnly = true;
    },
    getProvince(item) {
      if (item.country_id != undefined) {
        if (42 == item.country_id || 239 == item.country_id) {
          this.selectProvine = true;
          this.phoneMasked = true;
          this.$store.dispatch("other/getProvince", item.country_id);
        }
        else {
          this.phoneMasked = false;
          this.selectProvine = false;
        }
      }
      else {
        this.phoneMasked = false;
        this.selectProvine = false;
      }
    },
    resetForm() {
      this.$refs.form.resetFields();
      this.nodeClear();
    },
    saveIMM(formGeneric) {
      if (formGeneric.mailing_address) {
        formGeneric.res_apartment_unit = formGeneric.cur_apartment_unit;
        formGeneric.res_streetno = formGeneric.cur_streetno;
        formGeneric.res_street_name = formGeneric.cur_street_name;
        formGeneric.res_city = formGeneric.cur_city;
        formGeneric.res_country_id = formGeneric.cur_country_id;
        formGeneric.res_province = formGeneric.cur_province;
        formGeneric.res_postal_code = formGeneric.cur_postal_code;
        formGeneric.res_district = formGeneric.cur_district;
      }
      formGeneric.uci_number = formGeneric.uci_number && !isNaN(formGeneric.uci_number) ? +formGeneric.uci_number : null;
      formGeneric.application_id = +this.$route.params.id;

      this.$store.dispatch("generic/edit", formGeneric);
    },
    autoSaveSubmit(form) {
      var vm = this;
      vm.autoSave = true;
      vm.submitSave(vm, form);
    },
    submitSave: _.debounce((vm, form) => {
      let url = `${vm.url}?filter=application_id=${vm.$route.params.id}`;
      if (vm.getUrl != undefined) {
        url = vm.getUrl;
      }

      vm.$store.dispatch("store/get", url).then((res) => {
        if (res.resource.length == 0) {
          vm.$store.dispatch("store/save", { url: vm.url, data: form }).then((response) => {
            if (response.data.resource !== undefined && response.data.resource[0] !== undefined) {
              form.id = response.data.resource[0].id;
            }
            vm.autoSave = false;
          });
        }
        else {
          form.id = parseInt(res.resource[0].id);
          form.imms_uuid = res.resource[0].imms_uuid;
          vm.$store.dispatch("store/edit", { url: vm.url, data: form }).then((response) => {
            if (response.data.resource !== undefined && response.data.resource[0] !== undefined) {
              form.id = response.data.resource[0].id;
            }
            vm.autoSave = false;
          });
        }
      });
    }, 1000),
    handleAutoSave(index, form) {
      var that = this;
      that.autoSave = true;
      that.submitData(index, that, form);
    },
    submitData: _.debounce((index, that, form) => {
      var action = "store/save";
      var edit = false;
      if (form.imms_uuid !== undefined && form.imms_uuid !== null && form.imms_uuid !== '') {
        action = "store/edit";
        edit = true;
      }
      that.autoSave = true;

      if (form.sec_org_id == undefined && form.sec_org_id == null) {
        form.sec_org_id = that.$orgId;
      }

      that.$store
        .dispatch(action, { url: that.url, data: form })
        .then((response) => {
          if (!edit && response.data.resource !== undefined && response.data.resource.length > 0) {
            form.id = response.data.resource[0].id;
            form.imms_uuid = response.data.resource[0].imms_uuid;
          }
          that.autoSave = false;
        })
        .catch((err) => {
          that.autoSave = true;
        });
    }, 2000),
    async deleteOpen(item, index) {
      this.$confirm("Are you sure to delete this item?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(async() => {
        if (item.id) {
          try {
            let url = `${this.url}/${item.id}`
            if (item.imms_uuid !== undefined && item.imms_uuid !== null && this.url.includes('imms_table')) {
              url = `${this.url}/${item.imms_uuid}`
            }
            await this.$api.delete(url)
            this.items.splice(index, 1);
            this.activeNames = -1;
            this.$store.dispatch('toaster/add', { text: 'Deleted', type: 'success' })
          }
          catch (err) {
            console.error(err);
            this.$store.dispatch('toaster/add', { text: `Cannot delete item. Please contact supporting team!`, type: 'error' })
          }
        }
        else {
          this.items.splice(index, 1);
          this.activeNames = -1;
        }
      })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Canceled"
          });
        });
    },
    async deleteItem(item, index) {
      this.$confirm("Are you sure to delete this item?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(async() => {
        if (item.id) {
          try {
            let url = `${this.deleteUrl}/${item.id}`
            if (item.imms_uuid !== undefined && item.imms_uuid !== null && this.url.includes('imms')) {
              url = `${this.deleteUrl}/${item.imms_uuid}`
            }
            await this.$api.delete(url)
            this.items.splice(index, 1);
            this.activeNames = -1;
            this.$store.dispatch('toaster/add', { text: 'Deleted', type: 'success' })
          }
          catch (err) {
            console.error(err);
            this.$store.dispatch('toaster/add', { text: `Cannot delete item. Please contact supporting team!`, type: 'error' })
          }
        }
        else {
          this.items.splice(index, 1);
          this.activeNames = -1;
        }
      })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Canceled"
          });
        });
    }
  }
};
