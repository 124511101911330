<template>
  <div>
    <v-card v-if="hideSection" class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="yukon-checkbox">
          <v-row dense>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'retention_employee_plan')">
              <el-form-item prop="retention_employee_plan" label="What type of employee benefit plan do you offer to employees?">
                <el-input @change="handleAutoSave(form)" v-model="form.retention_employee_plan" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'retention_training_opportunities')">
              <el-form-item prop="retention_training_opportunities" label="What training opportunities are provided to employees? ">
                <el-input @change="handleAutoSave(form)" v-model="form.retention_training_opportunities" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'retention_performance_reviews')">
              <el-form-item prop="retention_performance_reviews" label="How often are performance reviews conducted on all employees?">
                <el-input @change="handleAutoSave(form)" v-model="form.retention_performance_reviews" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'retention_employee_recognition')">
              <el-form-item prop="retention_employee_recognition" label="What employee recognition practices/programs are in place?">
                <el-input @change="handleAutoSave(form)" v-model="form.retention_employee_recognition" />
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  mixins: [autosave],
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        retention_employee_recognition: null,
        retention_performance_reviews: null,
        retention_training_opportunities: null,
        retention_employee_plan: null
      },
      url: 'imms_table/lmia_yukon_employer_declarations',
      getUrl: 'imms_table/lmia_yukon_employer_declarations?application_imms_uuid=' + this.$route.params.id
    }
  },
  mounted() {
    this.fetch().then(data => {
      if (data.items != undefined && data.items.length > 0) {
        if (this.isDataValidation) {
          this.checkRow(data.items[0])
        }
        this.form = data.items[0]
      }
      else {
        if (this.isDataValidation) {
          this.checkRow(this.form)
        }
      }
    })
  }
}
</script>
