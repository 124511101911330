<template>
  <div>
    <v-card class="mt-3">
      <v-card-title class="text-uppercase">Part A - Sponsorship Evaluation and Information about the Sponsor - To be filled out by sponsor</v-card-title>
    </v-card>
    <sponsorship-employement-history 
      :sectionId="sectionId" 
      sectionLabel="1. Sponsor's employment history" 
      :sectionInfoBox="sectionInfoBox2" 
      :memberUID="memberUID"
      :memberID="memberID"
      :layout="layout">
    </sponsorship-employement-history>

    <sponsorship-co-signer 
      :sectionId="sectionId" 
      sectionLabel="2. Other sponsorships"
      :memberUID="memberUID"
      :memberID="memberID"
      :layout="layout">
    </sponsorship-co-signer>

    <sponsorship-dependent
      :sectionId="sectionId" 
      sectionLabel="3. Other financially dependent people"
      :sectionInfoBox="sectionInfoBox3" 
      :memberUID="memberUID"
      :memberID="memberID"
      :layout="layout">
    </sponsorship-dependent>

    <level-education
      :sectionId="sectionId" 
      sectionLabel="4. Level of education"
      :memberUID="memberUID"
      :memberID="memberID"
      :layout="layout">
    </level-education>

    <address-history
      :sectionId="sectionId" 
      sectionLabel="5. Address history"
      :sectionInfoBox="sectionInfoBox3" 
      :memberUID="memberUID"
      :memberID="memberID"
      :layout="layout">
    </address-history>

    <information-about-relationship
      :sectionId="sectionId" 
      sectionLabel=""
      :memberUID="memberUID"
      :memberID="memberID"
      :layout="layout"
      >
    </information-about-relationship>

    <v-card class="mt-3">
      <v-card-title class="text-uppercase">PART B - INFORMATION ABOUT THE PRINCIPAL APPLICANT - TO BE FILLED OUT BY THE PRINCIPAL APPLICANT</v-card-title>
    </v-card>

    <sponsorship-pre-dependent
      :sectionId="sectionId" 
      sectionLabel="1. Before this relationship, was your sponsor related to you in any way?"
      :memberUID="memberUID"
      :memberID="memberID"
      :layout="layout">
    </sponsorship-pre-dependent>

    <sponsorship-family-member
      :sectionId="sectionId" 
      sectionLabel="2. Do you have any relatives (including aunts, uncles, cousins, etc.) living in Canada?"
      :memberUID="memberUID"
      :memberID="memberID"
      :layout="layout">
    </sponsorship-family-member>

    <another-relationship
      :sectionId="sectionId" 
      sectionLabel=""
      :memberUID="memberUID"
      :memberID="memberID"
      :layout="layout"
      >
    </another-relationship>

    <v-card class="mt-3">
      <v-card-title class="text-uppercase">PART C - INFORMATION ABOUT RELATIONSHIP - TO BE FILLED OUT BY THE SPONSOR AND THE PRINCIPAL APPLICANT</v-card-title>
    </v-card>

    <sponsorship-general
      :sectionId="sectionId"
      :memberUID="memberUID"
      :memberID="memberID"
      :layout="layout">
    </sponsorship-general>
  </div>
</template>

<script>
import SponsorshipEmployementHistory from '../sponsorship/sponsorship_employment_history';
import SponsorshipCoSigner from '../sponsorship/sponsorship_co_signer';
import SponsorshipDependent from '../sponsorship/sponsorship_dependent';
import SponsorshipPreDependent from '../sponsorship/sponsorship_pre_dependent';
import SponsorshipFamilyMember from '../sponsorship/sponsorship_pre_add_family_members';
import SponsorshipGeneral from '../sponsorship/sponsorship_general';
import InformationAboutRelationship from './information_about_relationship';
import AnotherRelationship from './another_relationship';
import LevelEducation from './level_education';
import AddressHistory from '../addresses';

export default {
  props: {
    sectionId: {
      type: String
    },
    layout: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      sectionInfoBox2: `Starting with your current employer, if applicable, give details of all employers you have worked for over the past five years. Make sure there are no gaps.
        · If you were unemployed, explain how you supported yourself.
        · If you were self-employed, write the name of your business and the date it was established.
        · Use the gross amount (before taxes) for your monthly salary or income.`,
      sectionInfoBox3: `Give details of any other person who is financially dependent on you (whether they are living with you or not) and who is not already listed in the tables
          above. Include previous spouses or partners and any children from past relationships.`,
      sectionInfoBox4: `List all addresses where you have lived in the past five years. Do not use P.O. box addresses.`
    }
  },
  components: {
    InformationAboutRelationship,
    SponsorshipEmployementHistory,
    SponsorshipCoSigner,
    SponsorshipDependent,
    SponsorshipPreDependent,
    SponsorshipFamilyMember,
    SponsorshipGeneral,
    AnotherRelationship,
    LevelEducation,
    AddressHistory
  }
}
</script>
