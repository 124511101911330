<template>
  <v-card class="mt-3">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()">Add</v-btn>
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{ sectionInfoBox }}
      </v-alert>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item :name="i+1" v-for="(item,i) in items" :key="i" :id="'row' + item.id">
          <template slot="title">
            <span class="el-col-title">{{item.applicantname}}</span>
          </template>
            <el-form :model="item" ref="form" label-position="top" class="mt-3 form">
              <v-card>
                <v-card-title>
                  <span>Canadian Immigration Application History?</span>
                  <v-spacer />
                </v-card-title>
                <v-card-text class="p-n">
                  <v-row dense class="medium-space">
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Have you or your spouse applied to a Canadian immigration program before?" prop="canada_img_prg">
                        <el-select :id="'canada_img_prg' + item.id" v-model="item.canada_img_prg" style="width: 100%">
                          <el-option value="Yes">Yes</el-option>
                          <el-option value="No">No</el-option>
                        </el-select>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Name of Applicant" prop="applicantname">
                        <el-input :id="'applicantname' + item.id" v-model="item.applicantname" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Name of Immigration Program " prop="nip">
                        <el-input :id="'nip' + item.id" v-model="item.nip" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="File Number" prop="file_num">
                        <el-input :id="'file_num' + item.id" v-model="item.file_num" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Proposed Destination " prop="pro_destination">
                        <el-input :id="'pro_destination' + item.id" v-model="item.pro_destination" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Status/Decision " prop="status">
                        <el-input :id="'status' + item.id" v-model="item.status" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Date of Decision" prop="decision_date">
                        <el-date-picker :id="'decision_date' + item.id" value-format="yyyy-MM-dd" v-model="item.decision_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mt-3">
                <v-card-title>
                  <span>Experience in Business Farm Management/ Ownership</span>
                  <v-spacer />
                </v-card-title>
                <v-card-text class="p-n">
                  <v-row dense class="medium-space">
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Registered Business Name" prop="reg_businessname">
                        <el-input :id="'reg_businessname' + item.id" v-model="item.reg_businessname" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="From(Entire period of involvement)" prop="entirefrom">
                        <el-date-picker :id="'entirefrom' + item.id" value-format="yyyy-MM-dd" v-model="item.entirefrom" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="To(Entire period of involvement)" prop="entireto">
                        <el-date-picker :id="'entireto' + item.id" value-format="yyyy-MM-dd" v-model="item.entireto" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Are you currently an owner or senior manager of this farm business?" prop="businessrole">
                        <el-select :id="'businessrole' + item.id" v-model="item.businessrole" style="width: 100%">
                          <el-option value="Owner">Owner</el-option>
                          <el-option value="Senior Manager">Senior Manager</el-option>
                        </el-select>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Specify period of your involvement in this business as an owner or senior farm managerperiod of involvement)" prop="period_from">
                        <el-date-picker :id="'period_from' + item.id" value-format="yyyy-MM-dd" v-model="item.period_from" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Specify period of your involvement in this business as an owner or senior farm manager " prop="period_to">
                        <el-date-picker :id="'period_to' + item.id" value-format="yyyy-MM-dd" v-model="item.period_to" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Identify your position " prop="position">
                        <el-input :id="'position' + item.id" v-model="item.position" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Identify the type of ownership" prop="type_ownership">
                        <el-input :id="'type_ownership' + item.id" v-model="item.type_ownership" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Identify the percentage of ownership in this farm business" prop="percentage_ownership">
                        <el-input :id="'percentage_ownership' + item.id" v-model="item.percentage_ownership" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Date of acquisition of this percentage of ownership" prop="percentage_date">
                        <el-date-picker :id="'percentage_date' + item.id" value-format="yyyy-MM-dd" v-model="item.percentage_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-text>
                  <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm(i,form)">
                    {{ $t('global.save') }}
                  </v-btn>
                  <v-btn color="error" small @click.native="deleteOpen(form,i)">Delete</v-btn>
                </v-card-text>
              </v-card>
            </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        canada_img_prg: null,
        applicantname: null,
        nip: null,
        file_num: null,
        pro_destination: null,
        status: null,
        decision_date: null,
        reg_businessname: null,
        entirefrom: null,
        entireto: null,
        businessrole: null,
        period_from: null,
        period_to: null,
        position: null,
        type_ownership: null,
        percentage_ownership: null,
        percentage_date: null
      },
      activeNames: -1,
      url: 'imms_table/prov_manitoba',
      state: 'manitoba',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country'
    }),
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/prov_manitoba?filter=member_uid=${this.memberUID}`
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) this.checkItemsFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.form = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        canada_img_prg: null,
        applicantname: null,
        nip: null,
        file_num: null,
        pro_destination: null,
        status: null,
        decision_date: null,
        reg_businessname: null,
        entirefrom: null,
        entireto: null,
        businessrole: null,
        period_from: null,
        period_to: null,
        position: null,
        type_ownership: null,
        percentage_ownership: null,
        percentage_date: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.form)
      var len = this.items.length
      this.activeNames = len
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true

          if (formObj.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>
