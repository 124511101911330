import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { unsetToken, unsetUser } from '../utils/auth';
import { Notification } from 'element-ui';
import { auth } from '@/service/firebase';
import store from '@/plugins/store';

Vue.use(VueAxios, axios);

const instanceApi = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
});

const instanceNone = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
});

instanceNone.interceptors.request.use(
  (config) => {
    let org_id = localStorage.getItem('org_id');
    if (org_id != undefined && org_id != null) {
      config.headers.common['orguid'] = org_id;
    }
    return config;
  },
  (error) => {
    throw error;
  },
);

instanceApi.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('token');
    let org_id = localStorage.getItem('org_id');
    if (token != undefined && token != null) {
      config.headers.common['token'] = token;
    }
    if (org_id != undefined && org_id != null) {
      config.headers.common['orguid'] = org_id;
    }
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const baseSecOrgId = urlParams.get('base_sec_org_id');
      if (
        baseSecOrgId != undefined &&
        baseSecOrgId != null &&
        baseSecOrgId != ''
      )
        config.headers.common['basesecorgid'] = baseSecOrgId;
    } catch (error) {
      console.error(error);
    }
    return config;
  },
  (error) => {
    throw error;
  },
);

instanceApi.interceptors.response.use(
  async (response) => {
    if (
      (response.status != undefined && response.status === 200) ||
      response.status === 201
    ) {
      return response;
    }
  },
  async (error) => {
    let res = error.response || null;
    if (res != null && res.status == 401) {
      try {
        const idToken = await auth.currentUser.getIdToken(false);
        const refRes = await axios.get(
          `${process.env.VUE_APP_API_ROOT}/users/authorize?accessToken=${idToken}`,
        );
        store.commit('user/USER_SUCCESS', refRes.data);
        Notification.warning({
          title: 'Your session was expired',
          message: 'You might need to try it again!',
        });
      } catch (refErr) {
        unsetUser();
        unsetToken();
        window.location.href = '/login';
        throw res;
      }
    } else if (res != null && res.status == 403) {
      Notification.error({
        title: 'Permission denied',
        message: 'Contact to the technical support team.',
      });
      throw res;
    } else {
      throw res;
    }
  },
);

export default {
  get: function (url, params) {
    return instanceApi.get(url, { params: params });
  },
  post: function (url, data) {
    return instanceApi.post(url, data);
  },
  resetPOST: function (url, data) {
    return instanceNone.post(url, data);
  },
  put: function (url, data, params) {
    return instanceApi.put(url, data, { params: params });
  },
  delete: function (url) {
    return instanceApi.delete(url);
  },
  patch: function (url, data, params) {
    return instanceApi.patch(url, data, { params: params });
  },
};
