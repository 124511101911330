<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ $t('title.imm_0008_current_country_residence') }}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="$t('infobox.imm_0008_current_country_residence') !== ''">
         {{ $t('infobox.imm_0008_current_country_residence') }}
      </v-alert>
      <v-row dense class="medium-space">
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'current_residence_country')">
          <el-form-item :label="$t('imm_0008.current_residence_country')" prop="current_residence_country">
            <div class="el-select native-select">
              <div class="el-input" :class="[{'is-disabled': isCheck}]">
                <select @change="saveIMM(form)" :disabled="isCheck" v-model="form.current_residence_country" placeholder="Select" class="el-input__inner">
                  <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                </select>
              </div>
            </div>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'current_residence_status')">
          <el-form-item :label="$t('imm_0008.current_residence_status')" prop="current_residence_status">
            <el-select @change="saveIMM(form)" v-model="form.current_residence_status" placeholder="Select" style="width: 100%;">
              <el-option :value="item.name" v-for="(item, index) in status" v-bind:key="index">{{ item.name }}</el-option>
            </el-select>
          </el-form-item>
        </v-col>
        <template v-if="form.current_residence_status == 'Other'">
          <v-col cols="12" md="9" v-if="dataValidation(form, 'current_residence_other')">
            <el-form-item :label="$t('imm_0008.current_residence_other')" prop="current_residence_other">
              <el-input @change="saveIMM(form)" v-model="form.current_residence_other" style="width: 100%;" />
            </el-form-item>
          </v-col>
        </template>
      </v-row>
      <v-row dense class="medium-space" v-if="form.current_residence_status !== 'Citizen'">
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'current_residence_from_date')">
          <el-form-item :label="$t('imm_0008.current_residence_from_date')" prop="current_residence_from_date">
            <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.current_residence_from_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'current_residence_to_date')">
          <el-form-item :label="$t('imm_0008.current_residence_to_date')" prop="current_residence_to_date">
            <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.current_residence_to_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  data() {
    return {
      isStatus: true,
      isOther: false
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      status: 'other/status'
    }),
    isCheck: function() {
      return parseFloat(this.$route.params.apptypeId) === 24 ? true : false
    }
  },
  mounted() {
    if (this.isDataValidation) {
      var item = {
        current_residence_country: null,
        current_residence_status: null,
        current_residence_other: null,
        current_residence_from_date: null,
        current_residence_to_date: null
      }

      if (this.form.current_residence_status !== 'Other') delete item.current_residence_other
      if (this.form.current_residence_status == 'Citizen') {
        delete item.current_residence_from_date
        delete item.current_residence_to_date
      }

      this.checkItem(this.form, item)
    }
  }
}
</script>
