<template>
  <v-card class="mt-3">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
      <v-btn class="mb-3" color="primary" dark small @click.native="saveForm()">Add</v-btn>
    </v-card-title>
    <v-card-text>
      <v-alert type="info" border="left" dense class="mb-2" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i" :id="'row' + form.id">
          <template slot="title">
            <div class="relative">
              <span class="el-col-title">{{ form.job_title }}</span>
            </div>
          </template>
          <el-form :model="form" ref="form" label-position="top">
            <v-row dense>
              <v-col cols="12" sm="6" md="4">
                <div class="el-form-item mt-4">
                  <label for="job_in_canada" class="label el-form-item__label">Do you have a job offer in Canada?</label>
                  <el-checkbox v-model="form.job_in_canada" class="ml-3 mt-1">Yes</el-checkbox>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div class="el-form-item mt-4">
                  <label for="job_fulltime_non_seasonal" class="label el-form-item__label">Is this job offer for full-time non-seasonal and permanent work?</label>
                  <el-checkbox v-model="form.job_fulltime_non_seasonal" class="ml-3 mt-1">Yes</el-checkbox>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <el-form-item label="NOC (4-digits)" prop="noc_code">
                  <el-input :maxlength="4" v-model="form.noc_code" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <el-form-item label="Job title" prop="job_title">
                  <el-input v-model="form.job_title" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <el-form-item label="How many employers made this job offer?" prop="how_many_employers">
                  <el-input type="number" v-model="form.how_many_employers" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <el-form-item label="Is the employer an embassy, consulate or high commission in Canada?" prop="embassy_consulate_high">
                  <el-select v-model="form.embassy_consulate_high" style="width: 100%">
                    <el-option label="Embassy" value="Embassy"></el-option>
                    <el-option label="Consulate" value="Consulate"></el-option>
                    <el-option label="High Commission" value="High Commission"></el-option>
                  </el-select>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div class="el-form-item mt-4">
                  <label for="eligible_employer" class="label el-form-item__label">An employer on the list of ineligible employers with the Government of Canada?</label>
                  <el-checkbox v-model="form.eligible_employer" class="ml-3 mt-1">Yes</el-checkbox>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <el-form-item label="Employer or company name" prop="employer_name">
                  <el-input v-model="form.employer_name" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <el-form-item label="Contact address of employer or company" prop="employer_contact_address">
                  <el-input v-model="form.employer_contact_address" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <el-form-item label="Province or territory of intended employment" prop="province_id">
                  <div class="el-select native-select">
                    <div class="el-input">
                      <select v-model="form.province_id" placeholder="Select" class="el-input__inner">
                        <option v-for="(item, index) in province" v-bind:key="index" :value="item.id" :label="item.province_name">{{ item.province_name }}</option>
                      </select>
                    </div>
                  </div>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <el-form-item label="City/Town of intended employment" prop="city_town">
                  <el-input v-model="form.city_town" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <el-form-item label="Street address of intended employment" prop="employer_street_address">
                  <el-input v-model="form.employer_street_address" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div class="el-form-item mt-4">
                  <label for="was_lmia_issued" class="label el-form-item__label">Was a positive LMIA issued for the job offer in Canada?</label>
                  <el-checkbox v-model="form.was_lmia_issued" class="ml-3 mt-1">Yes</el-checkbox>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div class="el-form-item mt-4">
                  <label for="currently_working_canada" class="label el-form-item__label">Are you currently working in Canada?</label>
                  <el-checkbox v-model="form.currently_working_canada" class="ml-3 mt-1">Yes</el-checkbox>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div class="el-form-item mt-4">
                  <label for="have_valid_work_permit" class="label el-form-item__label">Do you have a valid work permit for the current job in Canada?</label>
                  <el-checkbox v-model="form.have_valid_work_permit" class="ml-3 mt-1">Yes</el-checkbox>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div class="el-form-item mt-4">
                  <label for="current_work_same_current_job" class="label el-form-item__label">Is your job offer from the employer named on the current work permit?</label>
                  <el-checkbox v-model="form.current_work_same_current_job" class="ml-3 mt-1">Yes</el-checkbox>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <el-form-item label="Four digit NOC code of current work permit" prop="current_work_noc_code">
                  <el-input :maxlength="4" v-model="form.current_work_noc_code" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div class="el-form-item mt-4">
                  <label for="was_lmia_current_work_permit" class="label el-form-item__label">Was a positive LMIA issued for the current work permit?</label>
                  <el-checkbox v-model="form.was_lmia_current_work_permit" class="ml-3 mt-1">Yes</el-checkbox>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm(i,form)">
                  {{ $t('global.save') }}
                </v-btn>
                <v-btn color="error" small @click.native="deleteOpen(form,i)">Delete</v-btn>
              </v-col>
            </v-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        noc_code: null,
        city_town: null,
        current_work_noc_code: undefined,
        current_work_same_current_job: false,
        currently_working_canada: false,
        eligible_employer: false,
        embassy_consulate_high: null,
        employer_contact_address: null,
        employer_name: null,
        employer_street_address: null,
        have_valid_work_permit: false,
        how_many_employers: undefined,
        job_fulltime_non_seasonal: false,
        job_in_canada: false,
        job_title: null,
        province_id: undefined,
        was_lmia_current_work_permit: false,
        was_lmia_issued: false
      },
      activeNames: -1,
      url: 'imms_table/application_ee_work',
      state: 'intendedWorkCanada',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      province: 'other/province'
    }),
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    }
  },
  created() {
    this.fetchData()
    this.$store.dispatch('other/getProvince', 42)
  },
  methods: {
    checkDate: function(event) {
      this.$emit('checkDate', event)
    },
    fetchData() {
      this.$Progress.start()
      var url = `table/application_ee_work?filter=member_uid=${this.memberUID}`
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        noc_code: null,
        city_town: null,
        current_work_noc_code: null,
        current_work_same_current_job: false,
        currently_working_canada: false,
        eligible_employer: false,
        embassy_consulate_high: null,
        employer_contact_address: null,
        employer_name: null,
        employer_street_address: null,
        have_valid_work_permit: false,
        how_many_employers: undefined,
        job_fulltime_non_seasonal: false,
        job_in_canada: false,
        job_title: null,
        province_id: undefined,
        was_lmia_current_work_permit: false,
        was_lmia_issued: false
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true

          if (formObj.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.formItem.resetFields()
      this.nodeClear()
    }
  }
}
</script>

<style>
  .label{
    width: 80%;
  }
</style>
