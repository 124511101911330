import api from "./../plugins/axios";

export const state = {
  educationGeneral: {}
};

export const getters = {
  id(state) {
    return state.educationGeneral.id;
  },
  full_detail(state) {
    return state.educationGeneral.full_detail;
  },
  getOne(state) {
    return state.educationGeneral;
  }
};

export const actions = {
  getAll({ commit }, id) {
    return api.get(`table/application_eduction_general?filter=member_uid=${id}&order=id DESC`).then(
      (response) => {
        if (response.data.resource.length > 0) {
          commit("GET_DATA", response.data.resource[0]);
        }
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATA", err);
        return Promise.reject(err);
      }
    );
  },
  save({ commit }, data) {
    return api.post('imms_table/application_eduction_general', { resource: data }).then(
      (response) => {
        if (response.data.resource.length > 0) {
          commit("SET_ID", response.data.resource[0]);
          data.id = response.data.resource[0].id;
          data.imms_uuid = response.data.resource[0].imms_uuid;
          commit("GET_DATA", data);
        }
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  edit({ commit }, data) {
    api.get(`table/application_eduction_general?filter=member_uid=${data.member_uid}&order=id DESC`).then(res => {
      if (res.data.resource.length > 0) {
        return api.put("imms_table/application_eduction_general/" + res.data.resource[0].imms_uuid, data).then(
          (response) => {
            return Promise.resolve(response);
          },
          (err) => {
            commit("FAILURE_DATAS", err);
            return Promise.reject(err);
          }
        );
      }
      else {
        return api.post('imms_table/application_eduction_general', { resource: data }).then(
          (response) => {
            if (response.data.resource.length > 0) {
              commit("SET_ID", response.data.resource[0]);
              data.id = response.data.resource[0].id;
              data.imms_uuid = response.data.resource[0].imms_uuid;
              commit("GET_DATA", data);
            }
            return Promise.resolve(response);
          },
          (err) => {
            commit("FAILURE_DATAS", err);
            return Promise.reject(err);
          }
        );
      }
    })
  }
};

export const mutations = {
  GET_DATA(state, data) {
    if (data.full_detail === undefined) data.full_detail = false;
    state.educationGeneral = data;
  },
  SET_ID(state, id) {
    state.educationGeneral.id = id;
  },
  FAILURE_DATA(state, data) {
    state.educationGeneral = {
      id: undefined,
      full_detail: false
    };
  },
  SET_FULL_DETAIL(state, payload) {
    state.educationGeneral.full_detail = payload;
  }
};
