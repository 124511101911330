<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ $t('title.application_previous_marital_status') }}</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()" :disabled="notApplicable">{{ $t('global.add') }}</v-btn>
    </v-card-title>
    <v-card-text>
      <div class="mb-2">
        <el-checkbox v-model="notApplicable" @change="checkApplicable()">{{ $t('global.not_applicable') }}</el-checkbox>
      </div>
      <v-alert type="info" border="left" dense class="mb-3" v-if="$t('infobox.application_previous_marital_status') !== ''">
        {{ $t('infobox.application_previous_marital_status') }}
      </v-alert>
      <v-row dense class="customCollapseHeader">
        <v-col cols="6" sm="2">From Date</v-col>
        <v-col cols="6" sm="2">To Date</v-col>
        <v-col class="d-none d-sm-flex" sm="3">First Name</v-col>
        <v-col class="d-none d-sm-flex" sm="3">Last Name</v-col>
        <v-col class="d-none d-sm-flex" sm="2">Relationship</v-col>
      </v-row>
      <el-collapse v-model="activeNames" accordion v-show="!notApplicable">
        <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i">
          <template slot="title">
            <div class="customHead">
              <v-row dense>
                <v-col cols="6" sm="2">
                  <el-tag type="primary" v-if="form.from_date !== null && form.from_date != undefined">{{ form.from_date | day }}</el-tag>
                </v-col>
                <v-col cols="6" sm="2">
                  <el-tag type="success" v-if="form.to_date !== null && form.to_date != undefined">{{ form.to_date | day }}</el-tag>
                </v-col>
                <v-col sm="3"><div class="pl-2 pr-2">{{ form.first_name }}</div></v-col>
                <v-col sm="3"><div class="pl-3">{{ form.last_name }}</div></v-col>
                <v-col sm="2"><div class="pl-3">{{ form.type_of_relationship }}</div></v-col>
              </v-row>
            </div>
          </template>

          <el-form :model="form" :rules="rules" ref="form" label-position="top">
            <v-row dense class="medium-space">
              <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidationItems(form, 'first_name')">
                <el-form-item :label="$t('application_previous_marital_status.first_name')" prop="first_name">
                  <el-input v-model="form.first_name" @change="handleAutoSave(i, form)" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidationItems(form, 'family_name')">
                <el-form-item :label="$t('application_previous_marital_status.family_name')" prop="family_name">
                  <el-input v-model="form.family_name" @change="handleAutoSave(i, form)" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidationItems(form, 'last_name')">
                <el-form-item :label="$t('application_previous_marital_status.last_name')" prop="last_name">
                  <el-input v-model="form.last_name" @change="handleAutoSave(i, form)" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidationItems(form, 'type_of_relationship')">
                <el-form-item :label="$t('application_previous_marital_status.type_of_relationship')" prop="type_of_relationship">
                  <el-select v-model="form.type_of_relationship" @change="handleAutoSave(i, form)" placeholder="Select" style="width: 100%;">
                    <el-option value="Common-Law">Common-Law</el-option>
                    <el-option value="Married">Married</el-option>
                  </el-select>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidationItems(form, 'date_of_birth')">
                <el-form-item :label="$t('application_previous_marital_status.date_of_birth')" prop="date_of_birth">
                  <el-date-picker :picker-options="datePickerOptions" value-format="yyyy-MM-dd" v-model="form.date_of_birth" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidationItems(form, 'from_date')">
                <el-form-item :label="$t('application_previous_marital_status.from_date')" prop="from_date">
                  <el-date-picker :picker-options="datePickerOptions" value-format="yyyy-MM-dd" v-model="form.from_date" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidationItems(form, 'to_date')">
                <el-form-item :label="$t('application_previous_marital_status.to_date')" prop="to_date">
                  <el-date-picker :picker-options="datePickerOptions" value-format="yyyy-MM-dd" v-model="form.to_date" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="12">
                <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(i,form)">{{ $t('global.save') }}</v-btn>
                <v-btn :loading="autoSave" :disabled="autoSave" color="error" small @click.native="deleteOpen(form,i)">{{ $t('global.delete') }}</v-btn>
              </v-col>
            </v-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    var checkCharacter = (rule, value, callback) => {
      var patt = new RegExp("[^ A-Za-z а-яА-ЯЁё]");
      var res = patt.test(value);

      if (res) {
        callback(new Error('Please only use alpha characters (abc)'));
      }
      else {
        callback();
      }
    };
    return {
      maritalStatus: [
        { name: 'Citizen' },
        { name: 'Common-Law' },
        { name: 'Divorced' },
        { name: 'Legally Separated' },
        { name: 'Married' },
        { name: 'Single' },
        { name: 'Unknown' },
        { name: 'Widowed' }
      ],
      rules: {
        last_name: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        first_name: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        family_name: [
          { validator: checkCharacter, trigger: 'blur' }
        ]
      },
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        last_name: null,
        type_of_relationship: null,
        family_name: null,
        first_name: null,
        date_of_birth: null,
        from_date: null,
        to_date: null
      },
      activeNames: -1,
      url: 'imms_table/application_previous_marital_status',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      applicableId: 'applicable/id'
    }),
    notApplicable: {
      get() {
        return this.$store.state.applicable.notApplicable.previous_marital_status
      },
      set(val) {
        this.$store.commit('applicable/set_previous_marital_status', val)
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/application_previous_marital_status?filter=member_uid=${this.memberUID}&order=date_of_birth DESC`
      this.$store.dispatch('store/get', url).then(response => {
        let vm = this

        if (vm.isDataValidation) {
          if (vm.notApplicable) {
            vm.hideSection = false
          }
          else {
            response.resource.forEach(function(item) {
              var form = {
                last_name: null,
                type_of_relationship: null,
                first_name: null,
                date_of_birth: null,
                from_date: null,
                to_date: null
              }

              var obj = vm.checkFormItems(item, form)

              if (obj != 'empty') {
                vm.items.push(item)
              }
            })
            if (response.resource.length == 0) {
              vm.hideSection = true
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
        }
        else {
          vm.items = response.resource
        }
        this.$Progress.finish()
      })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        last_name: null,
        type_of_relationship: null,
        family_name: null,
        first_name: null,
        date_of_birth: null,
        from_date: null,
        to_date: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    checkApplicable() {
      var action = 'applicable/save'
      var that = this
      var data = {}
      data.previous_marital_status = that.notApplicable
      data.application_id = this.$route.params.id
      data.member_id = parseFloat(this.memberID)
      data.member_uid = this.memberUID
      if (that.applicableId != undefined) {
        data.id = that.applicableId
        action = 'applicable/edit'
      }
      this.$Progress.start()
      this.$store.dispatch(action, data).then(response => {
        this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        this.$Progress.finish()
      })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
          this.$Progress.fail()
        })
    }
  }
}
</script>
