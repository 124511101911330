<template>
  <div>
    <el-form
      :model="form"
      ref="formGeneric"
      :rules="rules"
      label-position="top">
      <v-card class="mt-3" v-if="hideSection">
        <v-card-title>
          <span>{{ $t('title.imm_0008_general_personal_info') }}</span>
          <v-spacer />
        </v-card-title>
        <v-card-text class="p-n">
          <v-alert type="info" border="left" dense class="mb-3" v-if="$t('infobox.imm_0008_general_personal_info') !== ''">
            {{ $t('infobox.imm_0008_general_personal_info') }}
          </v-alert>

          <v-row dense class="medium-space" v-if="isSinp">
            <v-col cols="12" v-if="!isDataValidation">
              <el-form-item prop="sinp_skilled_worker">
                <el-checkbox @change="saveIMM(form)" v-model="form.sinp_skilled_worker">I am a skilled worker who wants to work and live in Saskatchewan.</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="!isDataValidation">
              <el-form-item prop="sinp_foriegn_national_currently">
                <el-checkbox @change="saveIMM(form)" v-model="form.sinp_foriegn_national_currently">I am a foreign national currently working and living in Saskatchewan.</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="!isDataValidation">
              <el-form-item prop="sinp_management_talent">
                <el-checkbox @change="saveIMM(form)" v-model="form.sinp_management_talent">I wish to invest my management talent and capital in a Saskatchewan business or farm operation.</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>

          <v-row dense class="medium-space" v-if="form.app_primary">
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'intended_province')">
              <el-form-item :label="$t('global.intended_province')" prop="intended_province">
                <el-input @change="checkIntended()" v-model="intended.intended_province" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'intended_employer')">
              <el-form-item :label="$t('global.intended_employer')" prop="intended_employer">
                <el-input @change="checkIntended()" v-model="intended.intended_employer" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>

          <v-row dense class="medium-space" v-if="isOINP">
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'legal_immigration_status')">
              <el-form-item label="Do you have legal immigration status in Canada?" prop="legal_immigration_status">
                <el-input @change="saveIMM(form)" v-model="form.legal_immigration_status" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'current_immigration_status')">
              <el-form-item label="Indicate your current immigration status" prop="current_immigration_status">
                <el-select v-model="form.current_immigration_status" style="width: 100%" @change="handleAutoSave(i, form)">
                  <el-option label="Implied Status" value="Implied Status"></el-option>
                  <el-option label="Student" value="Student"></el-option>
                  <el-option label="Visitor" value="Visitor"></el-option>
                  <el-option label="Worker" value="Worker"></el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'expiration_date')">
              <el-form-item label="Expiration Date of Current IRCC Document (dd/mm/yyyy)" prop="expiration_date">
                <el-date-picker value-format="yyyy-MM-dd" v-model="form.expiration_date" @change="saveIMM(form)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'ircc_file_number')">
              <el-form-item label="IRCC File Number" prop="ircc_file_number">
                <el-input @change="saveIMM(form)" v-model="form.ircc_file_number" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>

          <v-row dense class="medium-space">
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'gen_program_id')">
              <el-form-item :label="$t('imm_0008.gen_program_id')" prop="gen_program_id">
                <el-select @change="saveIMM(form);getCategory()" v-model="form.gen_program_id" placeholder="Select" style="width: 100%;">
                  <el-option :value="item.id" :label="item.name" v-for="(item, index) in program" v-bind:key="index">{{ item.name }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'gen_category_id')">
              <el-form-item :label="$t('imm_0008.gen_category_id')" prop="gen_category_id">
                <el-select @change="saveIMM(form)" v-model="form.gen_category_id" placeholder="Select" style="width: 100%;">
                  <el-option :value="item.id" :label="item.name" v-for="(item, index) in category" v-bind:key="index">{{ item.name }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'gen_fammembers')">
              <el-form-item :label="$t('imm_0008.gen_fammembers')" prop="gen_fammembers">
                <el-input @change="saveIMM(form)" type="number" placeholder="How many family" v-model="form.gen_fammembers" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'uci_number')">
              <el-form-item :label="$t('imm_0008.uci_number')" prop="uci_number">
                <el-input @change="saveIMM(form)" placeholder="UCI Number" :maxlength="10" v-model="form.uci_number" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'gen_lang')">
              <el-form-item :label="$t('imm_0008.gen_lang')" prop="gen_lang">
                <el-select @change="saveIMM(form)" v-model="form.gen_lang" placeholder="Select" style="width: 100%;">
                  <el-option value="English">English</el-option>
                  <el-option value="French">French</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'gen_interview')">
              <el-form-item :label="$t('imm_0008.gen_interview')" prop="gen_interview">
                <el-select @change="saveIMM(form)" v-model="form.gen_interview" placeholder="Select" style="width: 100%;">
                  <el-option value="English">English</el-option>
                  <el-option value="French">French</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'gen_interpreter')">
              <el-form-item :label="$t('imm_0008.gen_interpreter')" prop="gen_interpreter">
                <el-select @change="saveIMM(form)" v-model="form.gen_interpreter" placeholder="Select" style="width: 100%;">
                  <el-option :value="$t('global.yes')">{{ $t('global.yes') }}</el-option>
                  <el-option :value="$t('global.no')">{{ $t('global.no') }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'canadaprovince')">
              <el-form-item :label="$t('imm_0008.canadaprovince')" prop="canadaprovince">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select @change="saveIMM(form)" v-model="form.canadaprovince" placeholder="Select" class="el-input__inner">
                      <option v-for="(item, index) in province" v-bind:key="index" :value="item.id" :label="item.province_name">{{ item.province_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
          </v-row>

          <v-row dense class="medium-space" v-if="!isStudyPermit">
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'gen_city')">
              <el-form-item :label="$t('imm_0008.gen_city')" prop="gen_city">
                <el-input @change="saveIMM(form)" v-model="form.gen_city" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'gen_certificat')">
              <el-form-item :label="$t('imm_0008.gen_certificat')" prop="gen_certificat">
                <el-select @change="saveIMM(form)" v-model="form.gen_certificat" placeholder="Select" style="width: 100%;">
                  <el-option :value="$t('global.yes')">{{ $t('global.yes') }}</el-option>
                  <el-option :value="$t('global.no')">{{ $t('global.no') }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'gen_certidate')">
              <el-form-item :label="$t('imm_0008.gen_certidate')" prop="gen_certidate">
                <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.gen_certidate" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
          </v-row>

          <v-row dense class="medium-space">
            <v-col cols="12" v-if="!isDataValidation">
              <div class="body-2 mb-2">{{ $t('global.imm_0008_appear_passport') }}.</div>
              <v-divider class="mb-3"></v-divider>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'fname')">
              <el-form-item :label="$t('imm_0008.fname')" prop="fname">
                <el-input @change="saveIMM(form)" v-model="form.fname" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'middlename')">
              <el-form-item :label="$t('imm_0008.middlename')" prop="middlename">
                <el-input @change="saveIMM(form)" v-model="form.middlename" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'lname')">
              <el-form-item :label="$t('imm_0008.lname')" prop="lname">
                <el-input @change="saveIMM(form)" v-model="form.lname" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>

          <v-row dense class="medium-space row-border">
            <v-col cols="12" v-if="dataValidation(form, 'applicable')">
              <div class="body-2 mb-2">
                {{ $t('imm_0008.applicable') }}
                <el-checkbox id="applicable" @change="saveIMM(form)" v-model="form.applicable" class="ml-5 pl-5">Not Applicable</el-checkbox>
              </div>
              <v-divider class="mb-3" v-if="!form.applicable"></v-divider>
            </v-col>
            <template v-if="!form.applicable">
              <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'other_fname')">
                <el-form-item :label="$t('imm_0008.other_fname')" prop="other_fname">
                  <el-input @change="saveIMM(form)" placeholder="First Name" v-model="form.other_fname" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'other_middlename')">
                <el-form-item :label="$t('imm_0008.other_middlename')" prop="other_middlename">
                  <el-input @change="saveIMM(form)" placeholder="Middle Name" v-model="form.other_middlename" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'other_lname')">
                <el-form-item :label="$t('imm_0008.other_lname')" prop="other_lname">
                  <el-input @change="saveIMM(form)" placeholder="Last Name" v-model="form.other_lname" style="width: 100%;" />
                </el-form-item>
              </v-col>
            </template>
          </v-row>
          <v-divider class="mb-3" v-if="!isDataValidation" v-show="form.applicable"></v-divider>
          <v-row dense class="medium-space row-border" v-if="dataValidation(form, 'fname_native_language')" v-show="form.applicable">
            <v-col cols="12" sm="6" md="4" lg="3">
              <label class="el-form-item__label mt-2">{{ $t('imm_0008.fname_native_language') }}</label>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item prop="fname_native_language">
                <el-input @change="saveIMM(form)" v-model="form.fname_native_language" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <v-divider class="mb-3"></v-divider>
            </v-col>
          </v-row>

          <v-row dense class="medium-space">
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'gender')">
              <el-form-item :label="$t('imm_0008.gender')" prop="gender">
                <el-select @change="saveIMM(form)" filterable v-model="form.gender" placeholder="Select" style="width: 100%;">
                  <el-option value="Male">Male</el-option>
                  <el-option value="Female">Female</el-option>
                  <el-option value="Unspecified">Unspecified</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'height')">
              <el-form-item :label="$t('imm_0008.height')" prop="height">
                <el-input @change="saveIMM(form);checkHeigth('sm')" type="number" v-model="form.height" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'height_ft')">
              <el-form-item :label="$t('imm_0008.height_ft')" prop="height_ft">
                <el-input @change="saveIMM(form);checkHeigth('ft')" type="number" v-model="form.height_ft" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'height_in')">
              <el-form-item :label="$t('imm_0008.height_in')" prop="height_in">
                <el-input @change="saveIMM(form);checkHeigth('in')" type="number" v-model="form.height_in" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'eye_color')">
              <el-form-item :label="$t('imm_0008.eye_color')" prop="eye_color">
                <el-select @change="saveIMM(form)" v-model="form.eye_color" placeholder="Select" style="width: 100%;">
                  <el-option v-for="(item, ind) in $t('eyeColors')" :key="ind" :value="item.name" :label="item.name"></el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'birth_date')">
              <el-form-item :label="$t('imm_0008.birth_date')" prop="birth_date">
                <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.birth_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
          </v-row>

          <v-row dense class="medium-space">
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'birth_place')">
              <el-form-item :label="$t('imm_0008.birth_place')" prop="birth_place">
                <el-input @change="saveIMM(form)" placeholder="Place of Birth (City)" v-model="form.birth_place" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'birth_country_id')">
              <el-form-item :label="$t('imm_0008.birth_country_id')" prop="birth_country_id">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select @change="saveIMM(form)" v-model="form.birth_country_id" placeholder="Select" class="el-input__inner">
                      <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'citizenship1')">
              <el-form-item :label="$t('imm_0008.citizenship1')" prop="citizenship1">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select @change="saveIMM(form)" v-model="form.citizenship1" placeholder="Select" class="el-input__inner">
                      <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'citizenship2')">
              <el-form-item :label="$t('imm_0008.citizenship2')" prop="citizenship2">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select @change="saveIMM(form)" v-model="form.citizenship2" placeholder="Select" class="el-input__inner">
                      <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </el-form>

    <oinp-section v-if="isOINP" :form="form"></oinp-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'
import OinpSection from './oinp'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    },
    isOINP: {
      type: Boolean
    }
  },
  data() {
    var validateFirstName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Please input the first name'))
      }
      else {
        callback()
      }
    };
    var validateLastName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Please input the last name'))
      }
      else {
        callback()
      }
    };
    var checkCharacter = (rule, value, callback) => {
      var patt = new RegExp("[^ A-Za-z а-яА-ЯЁё]");
      var res = patt.test(value);

      if (res) {
        callback(new Error('Please only use alpha characters (abc)'));
      }
      else {
        callback();
      }
    };
    return {
      intended: {},
      rules: {
        fname: [
          { validator: validateFirstName, required: true, trigger: 'blur' },
          { validator: checkCharacter, trigger: 'blur' }
        ],
        lname: [
          { validator: validateLastName, required: true, trigger: 'blur' },
          { validator: checkCharacter, trigger: 'blur' }
        ],
        fname_native_language: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        middlename: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        other_fname: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        other_middlename: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        other_lname: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        uci_number: [
          { validator: this.checkUCI, trigger: 'blur' }
        ]
      },
      appTypeId: this.$route.params.apptypeId,
      isStudyPermit: false
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      province: 'other/province',
      program: 'other/program',
      category: 'other/category'
    }),
    isSinp: function() {
      var typeID = parseInt(this.$route.params.apptypeId)
      return typeID == 19 || typeID == 66 || typeID == 21 || typeID == 51 ? true : false;
    }
  },
  mounted() {
    this.isStudyPermit = parseInt(this.appTypeId) == 23 ? true : false

    if (this.isDataValidation) {
      var item = {
        citizenship1: null,
        birth_country_id: null,
        birth_place: null,
        birth_date: null,
        eye_color: null,
        height_in: null,
        height_ft: null,
        height: null,
        gender: null,
        other_lname: null,
        other_fname: null,
        lname: null,
        fname: null,
        gen_certidate: null,
        gen_certificat: null,
        gen_city: null,
        canadaprovince: null,
        gen_interpreter: null,
        gen_interview: null,
        gen_lang: null,
        gen_fammembers: null,
        gen_category_id: null,
        gen_program_id: null
      }

      if (this.form.applicable) {
        delete item.other_fname
        delete item.other_lname
      }

      if (!this.isOINP) {
        delete item.gen_certidate
        delete item.legal_immigration_status
        delete item.current_immigration_status
        delete item.expiration_date
        delete item.ircc_file_number
      }

      this.checkItem(this.form, item)
    }
  },
  components: {
    OinpSection
  },
  created() {
    this.getCategory()
    this.$store.dispatch('other/getProgram')

    this.$store.dispatch('store/get', "table/application_list_org_view?filter=imms_uuid=" + this.$route.params.id).then(response => {
      if (response.resource.length > 0) {
        this.intended = response.resource[0]
      }
    })
  },
  methods: {
    checkUCI(rule, value, callback) {
      if (value) {
        if (value.toString().length === 10 || value.toString().length === 8) {
          callback()
        }
        else {
          callback(new Error('UCI Number field to 8 or 10 digits'))
        }
      }
      else {
        callback()
      }
    },
    getCategory() {
      if (this.form.gen_program_id) {
        this.$store.dispatch('other/getCategory', this.form.gen_program_id)
      }
    },
    checkHeigth(check) {
      var sm = 0
      var hIn = 0
      var ft = 0
      if (check === 'sm' && this.form.height > 0) {
        sm = this.form.height
        hIn = (this.form.height * 0.393701).toFixed(1)
        ft = (this.form.height * 0.0328084).toFixed(1)
      }
      if (check === 'in' && this.form.height_in > 0) {
        hIn = this.form.height_in
        sm = (this.form.height_in * 2.54).toFixed(1)
        ft = (this.form.height_in * 0.0833333).toFixed(1)
      }
      if (check === 'ft' && this.form.height_ft > 0) {
        ft = this.form.height_ft
        hIn = (this.form.height_ft * 12).toFixed(1)
        sm = (this.form.height_ft * 30.48).toFixed(1)
      }

      this.form.height = sm
      this.form.height_in = hIn
      this.form.height_ft = ft
    },
    checkIntended() {
      if (this.intended) {
        this.$store.dispatch('application/edit', this.intended)
          .then(response => {
            this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
          })
          .catch(err => {
            this.$store.dispatch('toaster/add', { text: 'Disabled', type: 'success' })
          })
      }
    },
    saveIMM(formGeneric) {
      formGeneric.uci_number = formGeneric.uci_number && !isNaN(formGeneric.uci_number) ? +formGeneric.uci_number : null
      formGeneric.application_id = +this.$route.params.id

      this.$refs.formGeneric.validate((valid) => {
        if (valid) {
          this.$store.dispatch('generic/edit', formGeneric)
        }
        else {
          return false
        }
      })
    }
  }
}
</script>
