import api from './../plugins/axios';

export const state = {
  form: {},
  formTemp: {
    first_name: null,
    last_name: null,
    middle_name: null,
    gender: null,
    height: null,
    height_ft: null,
    height_in: null,
    birth_country: null,
    birth_date: null,
    birth_place: null,
    current_residence_country: null,
    current_residence_from_date: null,
    current_residence_other: null,
    current_residence_to_date: null,
    current_residence_status: null,
    apt_unit: null,
    street_number: null,
    street_name: null,
    city: null,
    country_id: null,
    province: null,
    postal_code: null,
    po_box: null,
    phone_business: null,
    phone_cellular: null,
    email: null,
    skype_id: null,
    edu_year: null,
    edu_level: null,
    emp_intended_occupation: null,
    emp_experience: null,
    emp_current_occupation: null,
    emp_wage: null,
    marital_status: null,
    married_date: null,
    married_prev: null,
    married_from_date: null,
    married_to_date: null,
    position_applied: null,
    education_level: null,
    employment_wage: null,
    employment_experience: null,

    res_apartment_unit: null,
    res_street_name: null,
    res_city: null,
    res_streetno: null,
    res_country_id: null,
    res_province: null,
    res_postal_code: null,
    res_district: null,
  },
};

export const getters = {
  get(state) {
    return state.form;
  },
};

export const actions = {
  getAll({ commit }, params) {
    commit('FAILURE_DATAS');
    return api
      .get(
        'table/candidate_personal_information?filter=sys_user_id=' +
          params.userID,
      )
      .then(
        (response) => {
          commit('GET_DATA', { datas: response.data.resource, params: params });
          return Promise.resolve(response.data);
        },
        (err) => {
          commit('FAILURE_DATAS', err);
          return Promise.reject(err);
        },
      );
  },
  save({ commit }, data) {
    if (data.residential_address) {
      data.res_apartment_unit = data.apt_unit;
      data.res_streetno = data.street_number;
      data.res_street_name = data.street_name;
      data.res_city = data.city;
      data.res_country_id = data.country_id;
      data.res_province = data.province;
      data.res_postal_code = data.postal_code;
      data.res_district = data.po_box;
    }

    return api
      .post('table/candidate_personal_information', { resource: data })
      .then(
        (response) => {
          if (
            response.data.resource !== undefined &&
            response.data.resource[0] !== undefined
          ) {
            data.id = response.data.resource[0].id;
            commit('UPDATE_DATA', data);
          }
          return Promise.resolve(response);
        },
        (err) => {
          commit('FAILURE_DATAS', err);
          return Promise.reject(err);
        },
      );
  },
  edit({ commit }, data) {
    if (data.residential_address) {
      data.res_apartment_unit = data.apt_unit;
      data.res_streetno = data.street_number;
      data.res_street_name = data.street_name;
      data.res_city = data.city;
      data.res_country_id = data.country_id;
      data.res_province = data.province;
      data.res_postal_code = data.postal_code;
      data.res_district = data.po_box;
    }

    return api
      .put('table/candidate_personal_information/' + data.id, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (err) => {
          commit('FAILURE_DATAS', err);
          return Promise.reject(err);
        },
      );
  },
};

export const mutations = {
  GET_DATA(state, item) {
    if (item.datas.length == 0) {
      state.form = state.formTemp;
      state.form.sys_user_id = parseFloat(item.params.userID);
    } else {
      state.form = item.datas[0];
      state.form.res_country_id =
        item.datas[0].res_country_id != null
          ? parseInt(item.datas[0].res_country_id)
          : null;
      state.form.country_id =
        item.datas[0].country_id != null
          ? parseInt(item.datas[0].country_id)
          : null;
      state.form.sys_user_id = parseFloat(state.form.sys_user_id);
    }
  },
  FAILURE_DATAS(state) {
    state.form = {};
  },
  UPDATE_DATA(state, data) {
    state.form = data;
  },
};
