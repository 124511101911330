<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>{{ sectionLabel }}</v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-2" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="form">
          <v-row dense class="mt-3">
            <v-col cols="12">
              <div class="mt-3">
                <label class="el-form-item__label">What is the highest level of education you have completed?</label>
              </div>
            </v-col>
            <v-col cols="12">
              <table class="datatable table tbpad">
                <tbody>
                  <tr>
                    <td><el-checkbox v-model="form.node.elementary">Elementary/Primary school</el-checkbox></td>
                    <td class="pa-2"><div class="pull-left mr-3 el-form-item__label">Number of years completed </div><el-input size="medium" type="number" v-model="form.node.elementary_years" style="width: 150px;" /></td>

                    <td><el-checkbox v-model="form.node.college">College/University</el-checkbox></td>
                    <td class="pa-2"><div class="pull-left mr-3 el-form-item__label">Number of years completed </div><el-input size="medium" type="number" v-model="form.node.college_years" style="width: 150px;" /></td>
                  </tr>
                  <tr>
                    <td><el-checkbox v-model="form.node.secondary">Secondary/High school</el-checkbox></td>
                    <td class="pa-2"><div class="pull-left mr-3 el-form-item__label">Number of years completed </div><el-input size="medium" type="number" v-model="form.node.secondary_years" style="width: 150px;" /></td>

                    <td><el-checkbox v-model="form.node.post_graduate">Post-graduate studies</el-checkbox></td>
                    <td class="pa-2"><div class="pull-left mr-3 el-form-item__label">Number of years completed </div><el-input size="medium" type="number" v-model="form.node.post_graduate_years" style="width: 150px;" /></td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="loading" color="primary" dark small @click.native="submitForm()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/autosave.js'

export default {
  mixins: [autosave],
  props: {
    sectionId: {
      type: String
    },
    layout: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      form: {
        member_uid: this.memberUID,
        member_id: this.memberID,
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        node: {
          elementary: false,
          elementary_years: null,
          college: false,
          college_years: null,
          post_graduate: false,
          post_graduate_years: null,
          secondary: false,
          secondary_years: null
        },
        alias: 'level_education3'
      },
      loading: false,
      url: 'imms_table/application_sponsorship_relationship'
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_sponsorship_relationship?filter=(member_uid=${this.memberUID})AND(alias='level_education3')`)
        .then(response => {
          if (response.resource.length > 0) {
            this.form = response.resource[0]
            this.form.node = JSON.parse(response.resource[0].node)
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true

          if (this.form.id != undefined) this.edit = true
          var action = 'store/save'
          if (this.edit) {
            action = 'store/edit'
          }
          this.$Progress.start()
          this.form.node = JSON.stringify(this.form.node)
          this.$store.dispatch(action, { 'url': this.url, 'data': this.form })
            .then(response => {
              this.fetchData()
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    }
  }
}
</script>

<style>
  .tbpad > tbody > tr > td{
    padding-top: 5px;
    padding-bottom: 5px;
  }
</style>
