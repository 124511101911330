<template>
  <div class="pa-3" v-if="hideSection">
    <v-row dense class="medium-space">
      <v-col cols="12">
        <div class="body-2 mb-2 pull-left">{{ $t('global.current_mailing_address') }}</div>
      </v-col>
      <v-col cols="12"><v-divider class="mb-3"></v-divider></v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'cur_po_box')">
        <el-form-item :label="$t('imm_0008.cur_po_box')" prop="cur_po_box">
          <el-input @change="saveIMM(form)" v-model="form.cur_po_box" style="width: 100%;" />
        </el-form-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'cur_apartment_unit')">
        <el-form-item :label="$t('imm_0008.cur_apartment_unit')" prop="cur_apartment_unit">
          <el-input @change="saveIMM(form)" type="number" v-model="form.cur_apartment_unit" style="width: 100%;" />
        </el-form-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'cur_streetno')">
        <el-form-item :label="$t('imm_0008.cur_streetno')" prop="cur_streetno">
          <el-input @change="saveIMM(form)" v-model="form.cur_streetno" style="width: 100%;" />
        </el-form-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'cur_street_name')">
        <el-form-item :label="$t('imm_0008.cur_street_name')" prop="cur_street_name">
          <el-input @change="saveIMM(form)" v-model="form.cur_street_name" style="width: 100%;" />
        </el-form-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'cur_city')">
        <el-form-item :label="$t('imm_0008.cur_city')" prop="cur_city">
          <el-input @change="saveIMM(form)" v-model="form.cur_city" style="width: 100%;" />
        </el-form-item>
      </v-col>              
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'cur_country_id')">     
        <el-form-item :label="$t('imm_0008.cur_country_id')" prop="cur_country_id">
          <div class="el-select native-select">
            <div class="el-input">
              <select @change="saveIMM(form);getProvinceCurrent(form)" v-model="form.cur_country_id" placeholder="Select" class="el-input__inner">
                <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
              </select>
            </div>
          </div>
        </el-form-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'cur_province')">
        <el-form-item :label="$t('imm_0008.cur_province')" prop="cur_province">
          <div v-if="selectProvine" class="el-select native-select">
            <div class="el-input">
              <select @change="saveIMM(form)" v-model="form.cur_province" placeholder="Select" class="el-input__inner">
                <option v-for="(item, index) in province" v-bind:key="index" :value="item.province_name" :label="item.province_name">{{ item.province_name }}</option>
              </select>
            </div>
          </div>
          <el-input v-else v-model="form.cur_province" @change="saveIMM(form)" />
        </el-form-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'cur_postal_code')">
        <el-form-item :label="$t('imm_0008.cur_postal_code')" prop="cur_postal_code">
          <el-input @change="saveIMM(form)" v-model="form.cur_postal_code" style="width: 100%;" />
        </el-form-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'cur_district')">
        <el-form-item :label="$t('imm_0008.cur_district')" prop="cur_district">
          <el-input @change="saveIMM(form)" v-model="form.cur_district" style="width: 100%;" />
        </el-form-item>
      </v-col>
      <template v-if="isOINP || isYukon">
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'cur_address_line')">
          <el-form-item label="Address Line 2" prop="cur_address_line">
            <el-input type="textarea" @change="saveIMM(form)" v-model="form.cur_address_line" style="width: 100%;" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'other_language')">
          <el-form-item label="Other language you speaks" prop="other_language">
            <el-input @change="saveIMM(form)" v-model="form.other_language" style="width: 100%;" />
          </el-form-item>
        </v-col>
      </template>
    </v-row>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'
import api from '@/plugins/axios'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    isDataValidation: {
      type: Boolean
    },
    isOINP: {
      type: Boolean
    },
    isYukon: {
      type: Boolean
    }
  },
  data() {
    return {
      province: [],
      selectProvine: false
    }
  },
  watch: {
    hideSection: function(val) {
      if (this.isDataValidation) {
        this.$emit('checkSection', { 'item': val, 'id': 2 })
      }
    }
  },
  computed: mapGetters({
    country: 'other/country'
  }),
  mounted() {
    this.getProvinceCurrent(this.form)

    if (this.isDataValidation) {
      var item = {
        cur_po_box: null,
        cur_apartment_unit: null,
        cur_streetno: null,
        cur_street_name: null,
        cur_city: null,
        cur_country_id: null,
        cur_province: null,
        cur_postal_code: null,
        cur_district: null
      }

      if (!this.isOINP) {
        delete item.cur_address_line
        delete item.other_language
      }

      this.checkItem(this.form, item)
    }
  },
  methods: {
    getProvinceCurrent(item) {
      if (item.cur_country_id != undefined && item.cur_country_id != null) {
        if (42 == parseInt(item.cur_country_id) || 239 == parseInt(item.cur_country_id)) {
          this.selectProvine = true
          api.get(`/table/provinces?filter=country_id=${item.cur_country_id}&order=province_name asc`).then(response => {
            this.province = response.data.resource
          })
        }
        else {
          this.selectProvine = false
        }
      }
      else {
        this.selectProvine = false
      }
    }
  }
}
</script>