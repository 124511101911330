import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify, {
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAvatar,
  VMain,
  VContainer,
  VRow,
  VCard,
  VTab,
  VTabs,
  VTabsSlider,
  VTabItem,
  VTabsItems,
  VDataTable,
  VBtn,
  VCol,
  VSpacer,
  VAlert,
  VCardText,
  VCardActions,
  VCardTitle,
  VIcon,
  VTextField,
  VToolbar,
  VToolbarTitle,
  VToolbarItems,
  VList,
  VListItem,
  VListGroup,
  VListItemAvatar,
  VListItemTitle,
  VListItemContent,
  VListItemSubtitle,
  VListItemIcon,
  VListItemAction,
  VListItemActionText,
  VMenu,
  VTooltip,
  VNavigationDrawer,
  VDialog,
  VChip,
  VDivider,
  VProgressCircular,
  VProgressLinear,
  VSimpleCheckbox,
  VExpansionPanel,
  VExpansionPanels,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VEditDialog,
  VSubheader,
  VSnackbar,
  VSlideYTransition,
  VRadio,
  VOverlay,
  VSwitch,
  VSelect,
  VBadge,
  VCheckbox,
  VRadioGroup,
  VWindow,
  VWindowItem,
  VTimeline,
  VTimelineItem,
  VStepper,
  VStepperHeader,
  VStepperContent,
  VStepperStep,
  VStepperItems,
  VFileInput,
  VSlideXTransition,
  VCardSubtitle
} from 'vuetify/lib'
import { Ripple, Resize, Scroll } from 'vuetify/lib/directives';
Vue.use(Vuetify, {
  components: {
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAvatar,
    VMain,
    VContainer,
    VCard,
    VToolbar,
    VTab,
    VTabs,
    VTabsSlider,
    VTabItem,
    VTabsItems,
    VDataTable,
    VBtn,
    VRow,
    VCol,
    VSpacer,
    VAlert,
    VCardText,
    VCardActions,
    VCardTitle,
    VIcon,
    VTextField,
    VToolbarTitle,
    VToolbarItems,
    VList,
    VListItem,
    VListGroup,
    VListItemAvatar,
    VListItemTitle,
    VListItemContent,
    VListItemSubtitle,
    VListItemIcon,
    VListItemAction,
    VListItemActionText,
    VMenu,
    VTooltip,
    VNavigationDrawer,
    VDialog,
    VChip,
    VDivider,
    VProgressCircular,
    VProgressLinear,
    VSimpleCheckbox,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VEditDialog,
    VSubheader,
    VSnackbar,
    VSelect,
    VSlideYTransition,
    VRadio,
    VOverlay,
    VSwitch,
    VBadge,
    VCheckbox,
    VRadioGroup,
    VWindow,
    VWindowItem,
    VTimeline,
    VTimelineItem,
    VStepper,
    VStepperHeader,
    VStepperContent,
    VStepperStep,
    VStepperItems,
    VFileInput,
    VSlideXTransition,
    VCardSubtitle
  },
  directives: {
    Ripple,
    Resize,
    Scroll,
  },
});
const opts = {
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#226C9D',
        secondary: '#D9EBF7',
        darkblue: '#184C6F',
        blue: '#3BA6E3',
        pink: '#E91E63',
        cyan: '#00bcd4',
        darkgrey: '#454545',
        lightgrey: '#eef1f6',
        green: '#33A54C',
        darkred: '#B33A3A'
      }
    }
  }
}
export default new Vuetify(opts);
