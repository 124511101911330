import {
  setToken,
  unsetToken,
  setUser,
  unsetUser,
  unsetUserLogin,
} from './../utils/auth';
import api from './../plugins/axios';
import apiNode from './../plugins/axios.node';
let url = '/user';

export const state = {
  users: [],
  user: {
    email: window.localStorage.getItem('email'),
    first_name: window.localStorage.getItem('first_name'),
    last_name: window.localStorage.getItem('last_name'),
    org_id: window.localStorage.getItem('org_id') || '',
    org_name: window.localStorage.getItem('org_name') || '',
    high_level_admin: window.localStorage.getItem('high_level_admin') || '',
    logo_path: window.localStorage.getItem('logo_path'),
    stripe_public_key: window.localStorage.getItem('stripe_public_key'),
    time_zone: window.localStorage.getItem('time_zone'),
  },
  userConfig: {
    include: false,
  },
  calendars: [],
  currentUser: null,
  consCalendars: {},
};

export const getters = {
  id(state) {
    return state.userConfig.id;
  },
  all(state) {
    return state.users;
  },
  getOne(state) {
    return state.user;
  },
  getUser(state) {
    return state.userConfig;
  },
  calendars(state) {
    return state.calendars;
  },
  getCurrentUser(state) {
    return state.currentUser;
  },
};

export const actions = {
  getAll({ commit }, params) {
    return api.get(url, { params: params }).then(
      (response) => {
        commit('GET_DATAS', response.data);
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  getById({ commit }, id) {
    return api.get(url + '/' + id).then(
      (response) => {
        commit('GET_DATA', response.data);
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  getUser({ commit }, id) {
    return api.get('table/user_configs?filter=user_id=' + id).then(
      (response) => {
        commit('GET_USER', response.data.resource[0] || { include: false });
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  changepass({ commit }, data) {
    return api.post(url + '/passwordv2?reset=true', data).then(
      (response) => {
        commit('ADD_DATA', response.data);
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  save({ commit }, data) {
    return api.post(url + '/profile', data).then(
      (response) => {
        commit('ADD_DATA', response.data);
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  edit({ commit }, data) {
    return api.put(url, { resource: data }).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  getCalendar({ commit }, qp) {
    return apiNode.get('scheduling/calendars' + qp).then(
      (response) => {
        commit('GET_CALENDARS', response.data.results);
        return Promise.resolve(response);
      },
      (err) => {
        commit('FAILURE_CALENDARS', err);
        return Promise.reject(err);
      },
    );
  },
  saveUserConfig({ commit }, data) {
    const requestObject = { resource: [data] };
    return api.post('table/user_configs', requestObject).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  editUserConfig({ commit }, data) {
    return api.put('table/user_configs/' + data.id, data).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  getConsultantProfile({ commit }, id) {
    return api.get('table/consultant_profile?filter=sys_user_id=' + id).then(
      (response) => {
        return Promise.resolve(response.data.resource[0]);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  editConsultantProfile({ commit }, data) {
    return api.put('table/consultant_profile/' + data.id, data).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  saveConsultantProfile({ commit }, data) {
    const requestObject = { resource: [data] };
    return api.post('table/consultant_profile', requestObject).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  invite({ commit }, data) {
    return api
      .post(
        '/user/invite?send_invite=true&session_token=' +
          process.env.VUE_APP_API_KEY,
        { resource: [data] },
      )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (err) => {
          return Promise.reject(err);
        },
      );
  },
  delete({ commit }, data) {
    return api.delete(url + '/' + data.id).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (err) => {
        commit('DATAS_FAILURE', err);
        return Promise.reject(err);
      },
    );
  },
  changeName(context, payload) {
    context.commit('setName', payload);
  },
  changeOrg({ commit, dispatch }, { orgId, orgName }) {
    commit('SET_ORG', { orgId, orgName });
  },
};

export const mutations = {
  SET_ORG(state, { orgId, orgName }) {
    state.user.org_id = orgId;
    state.user.org_name = orgName;

    window.localStorage.setItem('org_id', orgId);
    window.localStorage.setItem('org_name', orgName);
  },
  setName(state, payload) {
    state.user.first_name = payload.first_name;
    state.user.last_name = payload.last_name;
    window.localStorage.setItem('last_name', payload.last_name);
    window.localStorage.setItem('first_name', payload.first_name);
  },
  setOrg(state, payload) {
    state.user.org_name = payload.name;
    state.user.logo_path = payload.logo_path;
    state.user.org_id = payload.id;
    state.user.stripe_public_key = payload.stripe_public_key;

    window.localStorage.setItem('org_name', payload.name);
    window.localStorage.setItem('logo_path', payload.logo_path);
    window.localStorage.setItem('org_id', payload.id);
    window.localStorage.setItem('stripe_public_key', payload.stripe_public_key);
    window.localStorage.setItem('tawkSrc', payload.tawk);
  },
  set_include(state, payload) {
    state.userConfig.include = payload;
  },
  GET_DATAS(state, datas) {
    state.users = datas;
  },
  GET_DATA(state, data) {
    state.user = data;
  },
  GET_USER(state, data) {
    state.userConfig = data;
  },
  ADD_DATA(state, data) {
    state.users.push(data);
  },
  UPDATE_DATA(state, data) {
    let idx = state.users.map((p) => p.id).indexOf(data.id);
    state.users.splice(idx, 1, data);
  },
  USER_SUCCESS(state, data) {
    setUser(data);
    setToken(data.token);
  },
  CURRENT_USER(state, data) {
    state.currentUser = data;
  },
  USER_LOGOUT(state) {
    unsetUser();
    unsetToken();
  },
  USER_LOGIN_LOGOUT(state) {
    unsetUserLogin();
    unsetToken();
  },
  DATA_FAILURE(state, data) {
    state.users = [];
  },
  DELETE_DATA(state, data) {
    let idx = state.users.map((p) => p.id).indexOf(data.id);
    state.users.splice(idx, 1);
  },
  GET_CALENDARS(state, datas) {
    state.calendars = datas;
  },
  FAILURE_CALENDARS(state) {
    state.calendars = [];
  },
  FAILURE_CONSULTANT_CALENDARS(state) {
    state.consCalendars = {};
  },
};
