<template>
  <section class="h-full">
    <router-view></router-view>
    <div v-if="!network.isOnline" class="error-bar">
      Your internet connection is disconnected. Please check network.
    </div>
  </section>
</template>
<script>
import { reactive } from 'vue';
import { useNetwork } from '@vueuse/core';
import constants from './constants.js';

export default {
  setup() {
    const network = reactive(useNetwork());
    return {
      network,
    };
  },
  watch: {
    $route() {
      this.runZohoSalesIQ();
    },
  },
  created() {
    let params = new URL(document.location).searchParams;
    let ref = params.get('ref');
    let returnUrl = params.get('returnUrl');
    switch (window.location.pathname) {
      case '/application/message_detail/':
        window.localStorage.setItem('returnUrl', window.location.href);
        window.localStorage.setItem(
          'locationPathname',
          window.location.pathname,
        );
        break;
    }
    let currentLocation = window.location;
    if (returnUrl != undefined && returnUrl != null) {
      window.localStorage.setItem('returnUrl', returnUrl);
    } else if (ref != undefined || ref != null) {
      if (
        currentLocation.pathname != '/' &&
        currentLocation.pathname != '/login' &&
        currentLocation.pathname != '/register' &&
        currentLocation.pathname != '/forgot' &&
        currentLocation.pathname != '/reset' &&
        currentLocation.pathname != '/confirmation'
      ) {
        window.localStorage.setItem('returnUrl', currentLocation.pathname);
      }
    }
  },
  methods: {
    runZohoSalesIQ() {
      if (window.localStorage.getItem('email')) {
        $zoho.salesiq.visitor.email(window.localStorage.getItem('email'));
      }

      let name = window.localStorage.getItem('first_name')
        ? window.localStorage.getItem('first_name')
        : '';

      if (window.localStorage.getItem('last_name')) {
        name += ` ${window.localStorage.getItem('last_name')}`;
      }
      if (name) {
        $zoho.salesiq.visitor.name(name);
      }

      let department = 'Visitor';

      if (window.localStorage.getItem('role_id')) {
        let roleId = window.localStorage.getItem('role_id');

        if (roleId && +roleId === constants.applicantId) {
          department = 'Applicant';
        }
        if (roleId && +roleId === constants.employerId) {
          department = 'Employer';
        }
      }
      $zoho.salesiq.chat.defaultdepartment(department);
    },
  },
};
</script>
<style scoped>
.error-bar {
  position: fixed;
  width: 100%;
  padding: 30px;
  left: 0%;
  top: 0;
  z-index: 99;
  background-color: #b00020;
  color: aliceblue;
}
</style>
