<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
      <v-btn class="mb-3" color="primary" dark small @click.native="saveForm()">Add</v-btn>
    </v-card-title>
    <v-card-text>
      <v-alert type="info" border="left" dense class="mb-2" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <el-collapse v-model="activeNames" accordion>
        <template v-for="(form,i) in items">
          <el-collapse-item :name="i+1" :key="i" :id="'row' + form.id">
            <template slot="title">
              <div class="customHead">
                <el-tag type="primary" v-if="form.date_test != null">{{ form.date_test | day }}</el-tag> -
                <el-tag type="success" v-if="form.date_of_result != null">{{ form.date_of_result | day }}</el-tag>
                <span class="el-col-title ml-2">{{ form.language_test }}</span>
                <span class="el-col-title ml-2">{{ form.language_test_version }}</span>
              </div>
            </template>
            <el-form :model="form" ref="form" label-position="top">
              <v-row dense>
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'language_test')">
                  <el-form-item label="Language test taken" prop="language_test">
                    <el-input v-model="form.language_test" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'language_test_version')">
                  <el-form-item label="Language test version" prop="language_test_version">
                    <el-input v-model="form.language_test_version" @change="handleAutoSave(i, form)" style="width: 100%;" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'certificate')">
                  <el-form-item label="Language test results from or certificate number" prop="certificate">
                    <el-input v-model="form.certificate" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'date_test')">
                  <el-form-item label="Date of language test" prop="date_test">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="form.date_test" v-on:click.native="checkDate($event)" @change="handleAutoSave(i, form)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'date_of_result')">
                  <el-form-item label="Date of test results" prop="date_of_result">
                    <el-date-picker :picker-options="datePickerOptions" value-format="yyyy-MM-dd" v-model="form.date_of_result" v-on:click.native="checkDate($event)" @change="handleAutoSave(i, form)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="3" v-if="dataValidationItems(form, 'speaking')">
                  <el-form-item label="Speaking" prop="speaking">
                    <el-input type="number" v-model="form.speaking" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="dataValidationItems(form, 'reading')">
                  <el-form-item label="Reading" prop="reading">
                    <el-input type="number" v-model="form.reading" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="dataValidationItems(form, 'listening')">
                  <el-form-item label="Listening" prop="listening">
                    <el-input type="number" v-model="form.listening" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="dataValidationItems(form, 'writing')">
                  <el-form-item label="Writing" prop="writing">
                    <el-input type="number" v-model="form.writing" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(i,form)">
                    {{ $t('global.save') }}
                  </v-btn>
                  <v-btn color="error" small @click.native="deleteOpen(form,i)">Delete</v-btn>
                </v-col>
              </v-row>
            </el-form>
          </el-collapse-item>
        </template>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        slug: 'officialLanguage',
        language_test_version: null,
        language_test: null,
        certificate: null,
        date_of_result: null,
        date_test: null,
        speaking: null,
        reading: null,
        writing: null,
        listening: null
      },
      activeNames: -1,
      items: [],
      url: 'imms_table/application_official_languages'
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/application_official_languages?filter=((member_uid=${this.memberUID})AND(slug=officialLanguage))`
      this.$store.dispatch('store/get', url).then(response => {
        let vm = this

        if (vm.isDataValidation) {
          response.resource.forEach(function(item) {
            var form = {
              language_test_version: null,
              language_test: null,
              certificate: null,
              date_of_result: null,
              date_test: null,
              speaking: null,
              reading: null,
              writing: null,
              listening: null
            }

            var obj = vm.checkFormItems(item, form)
            if (obj != 'empty') {
              vm.items.push(item)
            }
          })
          if (response.resource.length == 0) {
            vm.hideSection = true
          }
          else {
            vm.hideSection = vm.validationItems.length == 0 ? false : true
          }
        }
        else {
          vm.items = response.resource
        }

        this.$Progress.finish()
      })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        slug: 'officialLanguage',
        language_test_version: null,
        language_test: null,
        certificate: null,
        date_of_result: null,
        date_test: null,
        speaking: null,
        reading: null,
        writing: null,
        listening: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    }
  }
}
</script>
