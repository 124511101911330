import Vue from 'vue';
import { sync } from 'vuex-router-sync';

import App from './App';
import vuetify from './plugins/vuetify';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import './registerServiceWorker';

import store from './plugins/store';
import router from './router/index';
import * as filters from './filters/index';
import ISelect from '@/components/Select';
import VueProgressBar from 'vue-progressbar';
import tinymce from 'vue-tinymce-editor';
import i18n from './lang/lang';
import {
  checkFields,
  checkItemsFields,
  checkTableFields,
  disableDate,
} from '@/utils/helpers';

import VueShepherd from 'vue-shepherd';
import { auth } from './service/firebase';
import 'element-ui/lib/theme-chalk/index.css';
require('shepherd.js/dist/css/shepherd.css');
import './assets/css/main.css';
import './views/application/components/elements/_globals';
import constants from './constants.js';

import IdleVue from 'idle-vue';
const eventsHub = new Vue();
const options = {
  eventEmitter: eventsHub,
  store,
  idleTime: 60000 * 60,
  startAtIdle: false,
};

Vue.use(IdleVue, options);

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_URL,
  integrations: [new Integrations.BrowserTracing()],
});

import api from '@/plugins/axios';
import apiFile from '@/plugins/axiosFileService';
//global variable
Vue.prototype.$api = api;
Vue.prototype.$apiFile = apiFile;

if (window.localStorage.getItem('role_id') != undefined) {
  let roleID = parseInt(window.localStorage.getItem('role_id'));
  window.$isApplicant = Vue.prototype.$isApplicant = roleID === 3;
  window.$roleId = Vue.prototype.$roleId = roleID;
}
if (window.localStorage.getItem('high_level_admin') != undefined) {
  window.$highLevelAdmin = Vue.prototype.$highLevelAdmin = parseInt(
    window.localStorage.getItem('high_level_admin'),
  );
}
if (window.localStorage.getItem('isImmigratePortal') != undefined) {
  const isImmigratePortal = window.localStorage.getItem('isImmigratePortal');
  if (typeof isImmigratePortal === 'string' && isImmigratePortal === 'true')
    window.$isImmigratePortal = Vue.prototype.$isImmigratePortal = true;
}
if (window.localStorage.getItem('id') != undefined) {
  window.$currentUserId = Vue.prototype.$currentUserId = parseInt(
    window.localStorage.getItem('id'),
  );
}
if (window.localStorage.getItem('imms_uuid')) {
  window.$currentUserImmsUuid = Vue.prototype.$currentUserImmsUuid =
    window.localStorage.getItem('imms_uuid');
}
if (window.localStorage.getItem('email') != undefined) {
  window.$currentUserEmail = Vue.prototype.$currentUserEmail =
    window.localStorage.getItem('email');
}
if (window.localStorage.getItem('org_id') != undefined) {
  window.$orgId = Vue.prototype.$orgId = parseInt(
    window.localStorage.getItem('org_id'),
  );
}
if (window.localStorage.getItem('v_marketplace_user') != undefined) {
  const isMarketplaceUser = window.localStorage.getItem('v_marketplace_user');
  if (typeof isMarketplaceUser === 'string' && isMarketplaceUser === '1')
    window.$isMarketplaceUser = Vue.prototype.$isMarketplaceUser = true;
  else if (typeof isMarketplaceUser === 'number' && isMarketplaceUser == 1)
    window.$isMarketplaceUser = Vue.prototype.$isMarketplaceUser = true;
  else window.$isMarketplaceUser = Vue.prototype.$isMarketplaceUser = false;
}

Vue.prototype.$const = constants;

sync(store, router);

Vue.use(VueProgressBar, {
  color: '#2AC3FE',
  failedColor: '#bb002f',
  height: '2px',
});
Vue.use(ElementUI, { locale });
Vue.use(VueShepherd);

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.component('ISelect', ISelect);
Vue.component('Tinymce', tinymce);

Vue.mixin({
  methods: {
    checkFields(objArray, fields, sectionId) {
      checkFields(objArray, fields, sectionId);
    },
    checkItemsFields(objArray, fields, sectionId, refs) {
      checkItemsFields(objArray, fields, sectionId, refs);
    },
    checkTableFields(objArray, fields, sectionId) {
      checkTableFields(objArray, fields, sectionId);
    },
    disableDate(event) {
      disableDate(event);
    },
  },
});

let myApp;
const initialize = () => {
  if (!myApp) {
    myApp = new Vue({
      vuetify,
      router,
      store,
      i18n,
      render: (h) => h(App),
    }).$mount('#app');
  }
};

auth.onAuthStateChanged(async (user) => {
  if (user) {
    auth.currentUser?.getIdToken().then(function (idToken) {
      window.localStorage.setItem('token', idToken);
    });
    store.commit('user/CURRENT_USER', auth.currentUser);
  }
  initialize();
  window['vue'] = myApp;
});

auth.onIdTokenChanged(async (user) => {
  user.getIdToken().then(function (idToken) {
    window.localStorage.setItem('token', idToken);
  });
});

export { router, store, i18n };
