
<template>
  <div>
    <v-card>
      <v-card-title>Your Immigration History</v-card-title>
      <v-card-text>
        <v-row dense class="mb-3 mt-3">
          <v-col cols="3">
            <div class="pull-right mt-3">City/Port of entry</div>
          </v-col>
          <v-col cols="3">
            <el-input v-model="formGeneral.city_entry" @change="saveGeneral(formGeneral)" style="width: 100%" />
          </v-col>
          <v-col cols="3">
            <div class="pull-right mt-3">Province</div>
          </v-col>
          <v-col cols="3">
            <el-input v-model="formGeneral.province" @change="saveGeneral(formGeneral)" style="width: 100%" />
          </v-col>
        </v-row>
        <v-row dense class="mb-3 mt-3">
          <v-col cols="6">
            <div class="pull-left">Have you ever been issued a removal order in Canada?</div>
          </v-col>
          <v-col cols="6">
            <el-radio-group v-model="formGeneral.removal" @change="saveGeneral(formGeneral)">
              <el-radio label="Yes">Yes</el-radio>
              <el-radio label="No">No</el-radio>
            </el-radio-group>
          </v-col>
        </v-row>
        <v-row dense class="mb-3 mt-3">
          <v-col cols="6">
            <div class="pull-left">Has an Immigration Officer ever issued you a Report Under subsection 44(1) or made the decision that you have not met the residency obligations required of Canadian permanent residents?</div>
          </v-col>
          <v-col cols="6">
            <el-radio-group v-model="formGeneral.immigration_officer" @change="saveGeneral(formGeneral)">
              <el-radio label="Yes">Yes</el-radio>
              <el-radio label="No">No</el-radio>
            </el-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="hideSection" class="mt-3">
      <v-card-title>Travel History</v-card-title>
      <v-card-text>
        <p><b>Have you travelled or lived outside of Canada in the last five (5) years (or if you became a permanent resident less than five (5) years ago, since becoming a permanent residence)? Include ALL absences (including those due to employment, vacations, trips to the United States and any other time you left Canada).</b></p>
        <v-row dense class="mb-3 mt-3">
          <v-col cols="3">
            <el-radio-group v-model="formGeneral.travel_history" @change="saveGeneral(formGeneral)">
              <el-radio label="Yes">Yes</el-radio>
              <el-radio label="No">No</el-radio>
            </el-radio-group>
          </v-col>
          <v-col cols="3">
            <div class="pull-left">List all absences:</div>
          </v-col>
          <v-col cols="6">
            <b class="mb-3">Period to be assessed:</b>
            <br>
            <el-date-picker v-model="formGeneral.from_date" placeholder="From" @change="saveGeneral(formGeneral)" style="width: 150px;" />
            <el-date-picker v-model="formGeneral.to_date" placeholder="To" @change="saveGeneral(formGeneral)" style="width: 150px; margin-left: 25px" />
          </v-col>
        </v-row>

        <table class="table" style="width:100%">
          <thead>
            <tr>
              <th width="135">From</th>
              <th width="135">To</th>
              <th>Your location during your absence(city, country or territory)</th>
              <th>Reason for absence</th>
              <th width="80">Days</th>
              <th width="30">
                <v-btn color="green" small fab @click.native="saveForm">
                  <v-icon class="white--text">add</v-icon>
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(form,i) in items" :key="i" :id="'row' + form.id">
              <td class="pa-1">
                <el-date-picker v-if="dataValidationItems(form, 'from_date')" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.from_date" v-on:click.native="checkDate($event)" @change="handleAutoSave(i, form);getLength(form, i)" style="width: 100%;"></el-date-picker>
              </td>
              <td class="pa-1">
                <el-date-picker v-if="dataValidationItems(form, 'to_date')" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.to_date" v-on:click.native="checkDate($event)" @change="handleAutoSave(i, form);getLength(form, i)" style="width: 100%;"></el-date-picker>
              </td>
              <td class="pa-1">
                <el-input v-if="dataValidationItems(form, 'city')" v-model="form.city" style="width: 100%" @change="handleAutoSave(i, form)" />
              </td>
              <td class="pa-1">
                <el-input v-if="dataValidationItems(form, 'reason')" v-model="form.reason" style="width: 100%" @change="handleAutoSave(i, form)" />
              </td>
              <td class="pa-1 text-right">
                <el-input v-if="dataValidationItems(form, 'days')" v-model="form.days" disabled style="width: 100%" @change="handleAutoSave(i, form)" />
              </td>
              <td class="pa-1 text-center">
                <v-btn color="pink" small fab @click.native="deleteOpen(form, i)"><v-icon class="white--text">clear</v-icon></v-btn>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!isDataValidation">
            <tr>
              <td colspan="4" class="text-right body-2">Total number of days</td>
              <td class="pa-1 text-right">
                <el-input v-model="totalDays" disabled style="width: 100%" />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  name: 'ResidentObligationOutsideCanada',
  props: {
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        days: null,
        reason: null,
        city: null,
        from_date: null,
        to_date: null
      },
      formGeneral: {
        travel_history: null,
        from_date: null,
        to_date: null,
        immigration_officer: null,
        removal: null,
        city_entry: null,
        province: null
      },
      check: false,
      url: 'imms_table/application_permanent_resident',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    totalDays: function() {
      var count = 0

      this.items.forEach(function(item) {
        if (item.days === null || item.days === undefined) item.days = 0
        count += parseFloat(item.days)
      })

      return count
    }
  },
  mounted() {
    this.fetchData()
    this.getGeneral()
  },
  methods: {
    async getGeneral() {
      try {
        let response = await this.$api.get(`table/application_citizenship_calculator?filter=member_uid=${this.memberUID}`)
        if (response.data.resource && response.data.resource.length > 0) {
          this.formGeneral = response.data.resource[0]
        }
      }
      catch (err) {
        console.error(err)
      }
    },
    getLength(form, index) {
      if (form.from_date && form.to_date) {
        let total = this.checkLength(form.from_date, form.to_date)

        if (total > 0) {
          form.days = total
          this.check = false
        }
        else {
          this.check = true
          form.to_date = null
        }
      }
    },
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_permanent_resident?filter=(member_uid=${this.memberUID})AND(type="travel_history")&order=from_date ASC,to_date ASC`)
        .then(response => {
          let vm = this
          if (vm.isDataValidation) {
            response.resource.forEach(function(item) {
              var form = {
                reason: null,
                city: null,
                from_date: null,
                to_date: null
              }

              var obj = vm.checkFormItems(item, form)
              if (obj != 'empty') {
                vm.items.push(item)
              }
            })

            if (response.resource.length == 0) {
              vm.hideSection = false
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
          else {
            vm.items = response.resource
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        days: null,
        reason: null,
        city: null,
        from_date: null,
        to_date: null,
        type: 'travel_history'
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    },
    async saveGeneral(item) {
      try {
        let obj = {
          travel_history: item.travel_history,
          from_date: item.from_date,
          to_date: item.to_date,
          application_id: this.$route.params.id,
          member_uid: this.memberUID,
          member_id: parseFloat(this.memberID),
          immigration_officer: item.immigration_officer,
          removal: item.removal,
          city_entry: item.city_entry,
          province: item.province
        }
        let resGet = await this.$api.get(`table/application_citizenship_calculator?filter=member_uid=${this.memberUID}`)

        if (resGet.data.resource.length > 0) {
          obj.id = resGet.data.resource[0].id
          obj.imms_uuid = resGet.data.resource[0].imms_uuid
          await this.$api.put(`imms_table/application_citizenship_calculator/${obj.imms_uuid}`, obj)
        }
        else {
          let response = await this.$api.post('imms_table/application_citizenship_calculator', { resource: obj })
          if (response.data.resource && response.data.resource.length > 0) {
            this.formGeneral.id = response.data.resource[0].id
            this.formGeneral.imms_uuid = response.data.resource[0].imms_uuid
          }
        }
      }
      catch (err) {
        console.error(err)
      }
    }
  }
}
</script>
