<template>
  <v-card class="mt-3">
    <v-card-title>
      <span>{{sectionLabel}}</span>
      <v-spacer />
      <v-btn v-if="layout === 'collapse'" color="primary" dark small @click.native="saveForm()">Add</v-btn>
    </v-card-title>
    <v-card-text>
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>

      <v-row dense class="customCollapseHeader">
        <v-col cols="6" sm="2">From Date</v-col>
        <v-col cols="6" sm="2">To Date</v-col>
        <v-col class="d-none d-sm-flex" sm="2">Country</v-col>
        <v-col class="d-none d-sm-flex" sm="2">City</v-col>
        <v-col class="d-none d-sm-flex" sm="4">Name of Secondary/High School</v-col>
      </v-row>

      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i">

          <template slot="title">
            <div v-on:click="getProvince(form)" class="customHead">
              <v-row dense>
                <v-col cols="6" sm="2">
                  <el-tag type="primary" v-if="form.from_date !== null && form.from_date != undefined">{{ form.from_date | day }}</el-tag>
                </v-col>
                <v-col cols="6" sm="2">
                  <el-tag type="success" v-if="form.to_date !== null && form.to_date != undefined">{{ form.to_date | day }}</el-tag>
                </v-col>
                <v-col class="d-none d-sm-flex" sm="2"><div>{{ form.country_name }}</div></v-col>
                <v-col class="d-none d-sm-flex" sm="2"><div class="pl-2 pr-2">{{ form.city }}</div></v-col>
                <v-col class="d-none d-sm-flex" sm="4"><div class="pl-3 pr-2">{{ form.name_of_school }}</div></v-col>
              </v-row>
            </div>
          </template>

          <el-form :model="form" ref="form" label-position="top">
            <v-row dense>
              <v-col cols="6" sm="2" md="3">
                <el-form-item label="From" prop="from_date">
                  <el-date-picker :id="'from_date' + form.id" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.from_date" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="6" sm="2" md="3">
                <el-form-item label="To" prop="to_date">
                  <el-date-picker :id="'to_date' + form.id" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.to_date" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="8" md="6">
                <el-form-item label="Name of Secondary/High School" prop="name_of_school">
                  <el-input :id="'name_of_school' + form.id" v-model="form.name_of_school" @change="handleAutoSave(i, form)" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6">
                <el-form-item label="County" prop="country_id">
                  <div class="el-select native-select">
                    <div class="el-input">
                      <select :id="'country_id' + form.id" v-model="form.country_id" placeholder="Select" class="el-input__inner" @change="handleAutoSave(i, form)">
                        <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                      </select>
                    </div>
                  </div>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6">
                <el-form-item label="City" prop="city">
                  <el-input :id="'city' + form.id" v-model="form.city" @change="handleAutoSave(i, form)" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="8" md="4" lg="3">
                <label class="el-form-item__label mt-2">Successful Completion of High School</label>
              </v-col>
              <v-col cols="4" md="4" lg="3">
                <el-form-item prop="successful_completion">
                  <el-checkbox :id="'successful_completion' + form.id" v-model="form.successful_completion" @change="handleAutoSave(i, form)" class="mt-2">Yes</el-checkbox>
                </el-form-item>
              </v-col>
              <v-col cols="12">
                <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm(i,form)">
                  {{ $t('global.save') }}
                </v-btn>
                <v-btn color="error" small @click.native="deleteItem(form,i)">Delete</v-btn>
              </v-col>
            </v-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import autosave from '@/mixins/autosave.js'

export default {
  mixins: [autosave],
  props: {
    sectionId: {
      type: String
    },
    layout: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        slug: 'secondaryEducation',
        from_date: null,
        to_date: null,
        name_of_school: null,
        country_id: null,
        city: null,
        successful_completion: false
      },
      activeNames: -1,
      url: 'imms_table/imm_1294_education_history',
      deleteUrl: 'imms/imm_1294_education_history',
      loading: false,
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      province: 'other/province'
    })
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    getProvince(item) {
      if (item.country_id) item.country_id = parseFloat(item.country_id)
    },
    fetchData() {
      this.$Progress.start()

      var url = `table/imm_1294_edu_history_view?filter=(member_uid=${this.memberUID})AND(slug=secondaryEducation)&order=from_date DESC, to_date DESC`
      this.$store.dispatch('store/get', url)
        .then(response => {
          this.items = response.resource

          if (this.datas !== undefined) {
            this.checkTableFields(response.resource, this.datas, this.sectionId)
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        slug: 'secondaryEducation',
        from_date: null,
        to_date: null,
        city: '',
        name_of_school: '',
        country_id: null,
        successful_completion: false
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true

          if (formObj.id != undefined) this.edit = true
          var action = 'store/save'
          if (this.edit) {
            action = 'store/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    }
  }
}
</script>
