import api from "./../plugins/axios";

let url = "table/appointments";

export const state = {
  appointments: [],
  appointment: {},
  appointmentApp: [],
  purchaseApplications: [],
  statuses: [{ text: "Paid", value: "paid" }, { text: "Had a consultation", value: "Had a consultation" }, { text: "Bought Application", value: "Bought Application" }]
};

export const getters = {
  all(state) {
    return state.appointments;
  },
  allApp(state) {
    return state.appointmentApp;
  },
  getOne(state) {
    return state.appointment;
  },
  purchaseApplications(state) {
    return state.purchaseApplications;
  },
  getStatUses(state) {
    return state.statuses;
  }
};

export const actions = {
  saveAppointmentRequests({ commit }, data) {
    return api.post("table/appointment_requests", { resource: data }).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  getAll({ commit }, filter) {
    return api.get("/table/appointments_process_view" + filter).then(
      (response) => {
        commit("GET_DATAS", response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  getOne({ commit }, filter) {
    return api.get("/table/appointments_process_view/" + filter).then(
      (response) => {
        commit("GET_DATA", response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATA", err);
        return Promise.reject(err);
      }
    );
  },
  savePurchaseApp({ commit }, data) {
    return api.post("table/purchase_applications", { resource: data }).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  getApp({ commit }, id) {
    return api.get("table/appointment_applications_view" + "?filter=appointment_id=" + id).then(
      (response) => {
        commit("GET_APPOINTMENT_APP", response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  saveApp({ commit }, data) {
    return api.post("table/appointment_applications", { resource: data }).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  editApp({ commit }, data) {
    return api.put("table/appointment_applications", { resource: data }).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  deleteApp({ commit }, data) {
    return api.delete("table/appointment_applications" + "?filter=id=" + data.id).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  edit({ commit }, data) {
    return api.put(url, { resource: data }).then(
      (response) => {
        commit("UPDATE_DATA", data);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  delete({ commit }, data) {
    return api.delete(url + "?filter=id=" + data.id).then(
      (response) => {
        commit("DELETE_DATA", data);
        return Promise.resolve(response);
      },
      (error) => {
        commit("FAILURE_DATAS", error);
        return Promise.reject(error);
      }
    );
  }
};

export const mutations = {
  GET_APPOINTMENT_APP(state, datas) {
    state.appointmentApp = datas;
  },
  GET_DATAS(state, datas) {
    state.appointments = datas;
  },
  GET_PURCHASE_APPS(state, datas) {
    state.purchaseApplications = datas;
  },
  GET_DATA(state, data) {
    state.appointment = data[0];
  },
  ADD_DATA(state, data) {
    state.appointments.push(data);
  },
  UPDATE_DATA(state, data) {
    let idx = state.appointments.map((p) => p.id).indexOf(data.id);
    state.appointments.splice(idx, 1, data);
  },
  FAILURE_DATAS(state) {
    state.appointments = [];
  },
  FAILURE_DATA(state, data) {
    state.precresidence = {};
  },
  DELETE_DATA(state, data) {
    let idx = state.appointments.map((p) => p.id).indexOf(data.id);
    state.appointments.splice(idx, 1);
  }
};
