
<template>
  <v-card v-if="hideSection" class="mt-3">
    <v-card-title>
      <span>Absences from Canada: You will use this information when completing your physical presence calculation.</span>
    </v-card-title>
    <v-card-text class="p-n">
      <v-row dense v-if="!isDataValidation">
        <v-col cols="12" class="mb-3">
          <div class="body-2 pull-left">Have you been outside of Canada in the last 5 years?</div>
          <el-radio-group class="ml-3 mt-1 pull-left" v-model="formCalculate.absences_canada" @change="checkCitizenship(formCalculate);">
            <el-radio label="No">No</el-radio>
            <el-radio label="Yes">Yes</el-radio>
          </el-radio-group>
        </v-col>
      </v-row>
      <v-col cols="12" v-if="check">
        <el-alert
          title="Please check to date"
          type="error">
        </el-alert>
      </v-col>
      
      <table class="table" style="width:100%">
        <thead>
          <tr>
            <th width="135">From</th>
            <th width="135">To</th>
            <th>Destination</th>
            <th>Reason</th>
            <th width="80">Days</th>
            <th width="30">
              <v-btn :disabled="formCalculate.absences_canada === 'No' ? true : false" color="green" small fab @click.native="saveForm">
                <v-icon class="white--text">add</v-icon>
              </v-btn>
            </th>       
          </tr>
        </thead>
        <tbody v-if="formCalculate.absences_canada === 'Yes'">
          <tr v-for="(form,i) in items" :key="i" :id="'row' + form.id">
            <td class="pa-1">
              <el-date-picker v-if="dataValidationItems(form, 'from_date')" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.from_date" v-on:click.native="checkDate($event)" @change="handleAutoSave(i, form);getLength(form, i)" style="width: 100%;"></el-date-picker>                    
            </td>
            <td class="pa-1">
              <el-date-picker v-if="dataValidationItems(form, 'to_date')" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.to_date" v-on:click.native="checkDate($event)" @change="handleAutoSave(i, form);getLength(form, i)" style="width: 100%;"></el-date-picker>                    
            </td>
            <td class="pa-1">
              <el-input v-if="dataValidationItems(form, 'destination')" v-model="form.destination" style="width: 100%" @change="handleAutoSave(i, form)" />                    
            </td>
            <td class="pa-1">
              <el-input v-if="dataValidationItems(form, 'reason')" v-model="form.reason" style="width: 100%" @change="handleAutoSave(i, form)" />                    
            </td>   
            <td class="pa-1 text-right">
              <el-input v-if="dataValidationItems(form, 'days')" v-model="form.days" disabled style="width: 100%" @change="handleAutoSave(i, form)" />                    
            </td>      
            <td class="pa-1 text-center">
              <v-btn color="pink" small fab @click.native="deleteOpen(form, i)"><v-icon class="white--text">clear</v-icon></v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-if="!isDataValidation">
          <tr>
            <td colspan="4" class="text-right">Total number of days absent from Canada as a permanent residence.</td>
            <td class="pa-1 text-right"><b class="pl-2 pr-2">{{ totalDays }}</b></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import { checkLength } from '@/utils/helpers'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  name: 'PermanentResidentCalculator',
  props: {
    sectionId: {
      type: String
    },
    formCalculate: {
      type: Object
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        days: null,
        destination: null,
        reason: null,
        from_date: null,
        to_date: null,
        absent: 0
      },
      check: false,
      url: 'imms_table/application_permanent_resident',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    },
    totalDays: function(val) {
      this.formCalculate.days_absent_total = val
    },
    absent: function(val) {
      this.formCalculate.days_absent = val
    }
  },
  computed: {
    disabledButton: function() {
      return this.formCalculate.absences_canada === 'No' ? true : false
    },
    totalDays: function() {
      var count = 0

      if (this.formCalculate.absences_canada === 'Yes') {
        this.items.forEach(function(item) {
          if (item.days === null || item.days === undefined) item.days = 0
          count += parseFloat(item.days)
        })
      }
      if (count > 1095) {
        this.$notify({
          title: 'Congratulations',
          message: 'Congratulations, you are eligible to apply for Canadian Citizenship',
          type: 'success',
          duration: 0
        })
      }
      
      return count
    },
    absent: function() {
      var absent = 0

      if (this.formCalculate.absences_canada === 'Yes') {
        this.items.forEach(function(item) {
          if (item.absent === null || item.absent === undefined) item.absent = 0
          absent += parseFloat(item.absent)
        })
      }

      return absent
    }
  },
  mounted() {
    this.fetchData()
  }, 
  methods: {
    getLength(form, index) {
      if (form.from_date && form.to_date) {
        let length = checkLength(this.formCalculate.sign_before_date, this.formCalculate.pr_date, form.from_date, form.to_date, -1)

        let total = length.total
        let absent = length.absent

        if (absent > 0) {
          form.absent = absent
        }

        if (total > 0) {
          form.days = total
          this.check = false
        }
        else {
          this.check = true
          form.to_date = null
        }
      }
    },
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_permanent_resident?filter=member_uid=${this.memberUID}&order=from_date ASC,to_date ASC`)
        .then(response => {
          let vm = this
          if (vm.isDataValidation) {
            response.resource.forEach(function(item) {
              var form = {
                destination: null,
                reason: null,
                from_date: null,
                to_date: null
              }

              var obj = vm.checkFormItems(item, form)
              if (obj != 'empty') {
                vm.items.push(item)
              }
            })

            if (vm.formCalculate.absences_canada == 'No') {
              vm.hideSection = false
            }
            else if (response.resource.length == 0) {
              vm.hideSection = false
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
          else {
            vm.items = response.resource
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        days: null,
        destination: null,
        reason: null,
        from_date: null,
        to_date: null,
        absent: 0
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    },
    checkCitizenship(item) {
      if (item.id != undefined) {
        this.$store.dispatch('store/edit', { url: 'imms_table/application_citizenship_calculator', data: item })
          .then(response => {
            this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
          })
          .catch(err => {
            this.$store.dispatch('toaster/add', { text: 'Disabled', type: 'success' })
          })
      }
    }
  }
}
</script>