<template>
  <div>
    <el-form v-if="hideSection" :model="form" ref="form" label-position="top">
      <v-card class="mt-3">
        <v-card-title>
          <span>Application Program</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
            {{sectionInfoBox}}
          </v-alert>
          <v-row dense>
            <v-col cols="6" v-if="dataValidation(form, 'aigp')">
              <el-form-item>
                <el-checkbox v-model="form.aigp" @change="handleAutoSave(form)">Atlantic International Graduate Program (AIGP)</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'ahsp')">
              <el-form-item>
                <el-checkbox v-model="form.ahsp" @change="handleAutoSave(form)">Atlantic High-Skilled Program (AHSP)</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'aisp')">
              <el-form-item>
                <el-checkbox v-model="form.aisp" @change="handleAutoSave(form)">Atlantic Intermediate-Skilled Program (AISP)</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-3">
        <v-card-title>
          <span>Position Description</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" v-if="dataValidation(form, 'work_experience')">
              <el-form-item label="If the work experience is in a different NOC than the job offer, please explain below why you believe this work experience is related to the job offer">
                <el-input type="textarea" v-model="form.work_experience" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'contract_attached')">
              <el-form-item label="Position description and contract attached">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.contract_attached" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'expected_start_date')">
              <el-form-item label="Expected start date of employment">
                <el-date-picker :picker-options="datePickerOptions" @change="handleAutoSave(form)" v-model="form.expected_start_date" v-on:click.native="checkDate($event)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'expected_end_date')">
              <el-form-item label="Expected end date/duration of employment *please specify if it is a permanent position (i.e. no end date)">
                <el-date-picker :picker-options="datePickerOptions" @change="handleAutoSave(form)" v-model="form.expected_end_date" v-on:click.native="checkDate($event)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'business_address')">
              <el-form-item label="Business mailing address, including postal code">
                <el-input v-model="form.business_address" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'work_location')">
              <el-form-item label="Physical work location, including postal code">
                <el-input v-model="form.work_location" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-3">
        <v-card-title>
          <span>Recruitment Activities</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" lg="4" v-if="dataValidation(form, 'job_site')">
              <el-form-item>
                <el-checkbox v-model="form.job_site" @change="handleAutoSave(form)">Listing on Provincial job site</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" lg="4" v-if="dataValidation(form, 'immigration_rep')">
              <el-form-item>
                <el-checkbox v-model="form.immigration_rep" @change="handleAutoSave(form)">Immigration Representative</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" lg="4" v-if="dataValidation(form, 'employment_recruiter')">
              <el-form-item>
                <el-checkbox v-model="form.employment_recruiter" @change="handleAutoSave(form)">Employment recruiter</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" lg="4" v-if="dataValidation(form, 'family_member')">
              <el-form-item>
                <el-checkbox v-model="form.family_member" @change="handleAutoSave(form)">Contacted by a family member</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" lg="4" v-if="dataValidation(form, 'institution')">
              <el-form-item>
                <el-checkbox v-model="form.institution" @change="handleAutoSave(form)">Educational institution</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" lg="4" v-if="dataValidation(form, 'professional_links')">
              <el-form-item>
                <el-checkbox v-model="form.professional_links" @change="handleAutoSave(form)">Professional links</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" lg="4" v-if="dataValidation(form, 'other_employees')">
              <el-form-item>
                <el-checkbox v-model="form.other_employees" @change="handleAutoSave(form)">Other employees</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" lg="4" v-if="dataValidation(form, 'lawyer')">
              <el-form-item>
                <el-checkbox v-model="form.lawyer" @change="handleAutoSave(form)">Lawyer</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" lg="4" v-if="dataValidation(form, 'other_specify')">
              <el-form-item>
                <el-checkbox v-model="form.other_specify" @change="handleAutoSave(form)">Other (specify below)</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'other')">
              <el-form-item v-if="form.other_specify" label="Other">
                <el-input v-model="form.other" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'new_position')">
              <el-form-item label="Is this a new position?">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.new_position" class="mt-1 text-left">
                  <el-radio label="Yes">Yes, explain the reason</el-radio>
                  <el-radio label="No">No, explain why it could not be filled with local talent</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'yes_explain')">
              <el-form-item v-if="form.new_position == 'Yes'" label="If yes explain">
                <el-input type="textarea" v-model="form.yes_explain" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'activities')">
              <el-form-item label="What activities have you undertaken to recruit for your position(s) over the past 12 months?">
                <el-input type="textarea" v-model="form.activities" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item label="Indicate where and the dates that this position was advertised (add additional lines if required)."></el-form-item>
              <v-row dense>
                <v-col cols="12" sm="1" class="text-right">
                  <div class="body-2 mt-2">1</div>
                </v-col>
                <v-col cols="12" sm="11">
                  <el-form-item v-if="dataValidation(form, 'position_1')">
                    <el-input v-model="form.position_1" @change="handleAutoSave(form)" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="1" class="text-right">
                  <div class="body-2 mt-2">2</div>
                </v-col>
                <v-col cols="12" sm="11">
                  <el-form-item v-if="dataValidation(form, 'position_2')">
                    <el-input v-model="form.position_2" @change="handleAutoSave(form)" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="1" class="text-right">
                  <div class="body-2 mt-2">3</div>
                </v-col>
                <v-col cols="12" sm="11">
                  <el-form-item v-if="dataValidation(form, 'position_3')">
                    <el-input v-model="form.position_3" @change="handleAutoSave(form)" />
                  </el-form-item>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'position_advertised')">
              <el-form-item label="Where was the position advertised?">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.position_advertised" class="mt-1 text-left">
                  <el-radio label="Locally">Locally</el-radio>
                  <el-radio label="Nationally">Nationally</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'how_long_advertised')">
              <el-form-item label="For how long was it advertised?">
                <el-input v-model="form.how_long_advertised" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-3">
        <v-card-title>
          <span>Recruitment Company Information</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_name')">
              <el-form-item label="Company name">
                <el-input v-model="form.company_name" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'representative')">
              <el-form-item label="Representative">
                <el-input v-model="form.representative" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'recruitment_address')">
              <el-form-item label="Mailing address, including postal code">
                <el-input type="textarea" v-model="form.recruitment_address" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'recruitment_telephone')">
              <el-form-item label="Telephone">
                <el-input v-model="form.recruitment_telephone" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'recruitment_email')">
              <el-form-item label="Email">
                <el-input type="email" v-model="form.recruitment_email" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'recruitment_website')">
              <el-form-item label="Website">
                <el-input v-model="form.recruitment_website" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'relative')">
              <el-form-item label="Is the Principal Applicant your relative?">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.relative" class="mt-1 text-left">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'director')">
              <el-form-item label="Is the Principal Applicant a director, shareholder or investor in your business?">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.director" class="mt-1 text-left">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'relationship_explain')">
              <el-form-item v-if="form.director == 'Yes'" label="If yes, please explain the relationship and indicate the reason this person was hired for the job over other candidates">
                <el-input type="textarea" v-model="form.relationship_explain" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-3">
        <v-card-title>
          <span>Settlement and Integration</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-alert type="info" border="left">Employers must ensure each Principal Applicant and their accompanying family member(s) receives a comprehensive pre-endorsement needs assessment that includes customized information and service referrals.</v-alert>
            <v-col cols="12" v-if="dataValidation(form, 'orientation')">
              <el-form-item>
                <el-checkbox v-model="form.orientation" @change="handleAutoSave(form)">Information and Orientation to Life in Canada</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'housing')">
              <el-form-item>
                <el-checkbox v-model="form.housing" @change="handleAutoSave(form)">Housing</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'education')">
              <el-form-item>
                <el-checkbox v-model="form.education" @change="handleAutoSave(form)">Education</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'healtcare')">
              <el-form-item>
                <el-checkbox v-model="form.healtcare" @change="handleAutoSave(form)">Health Care</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'financial')">
              <el-form-item>
                <el-checkbox v-model="form.financial" @change="handleAutoSave(form)">Financial Services</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'language')">
              <el-form-item>
                <el-checkbox v-model="form.language" @change="handleAutoSave(form)">Language – Evaluation and Learning to CLB 5 or equivalent</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'employment')">
              <el-form-item>
                <el-checkbox v-model="form.employment" @change="handleAutoSave(form)">Employment Counselling and Career Development Support</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'community_service')">
              <el-form-item>
                <el-checkbox v-model="form.community_service" @change="handleAutoSave(form)">Connection to Community Services and Recreation</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'ethno_cultural')">
              <el-form-item>
                <el-checkbox v-model="form.ethno_cultural" @change="handleAutoSave(form)">Connection to Ethno-cultural and/or Faith-based Associations</el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'support_service')">
              <el-form-item>
                <el-checkbox v-model="form.support_service" @change="handleAutoSave(form)">Support Services to access Settlement Supports: Childcare; Interpretation and Translation; Transportation; Crisis Counselling; Provision for Disabilities</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-3">
        <v-card-title>
          <span>Employee Declaration</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <div v-for="n in 10" :key="'employee_dec_' + n">
            <v-row dense v-if="dataValidation(form, `employee_declaration${n}`)">
              <v-col cols="12" sm="2">
                <el-form-item>
                  <el-radio-group @change="handleAutoSave(form)" v-model="form[`employee_declaration${n}`]" class="mt-1 text-left">
                    <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                    <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="10">
                <el-form-item :label="$t(`global.employee_q${n}`)"></el-form-item>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </el-form>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  mixins: [autosave],
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        sec_org_id: this.$orgId,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        aigp: null,
        ahsp: null,
        aisp: null
      },
      url: 'imms_table/application_endorsement',
      getUrl: 'imms_table/application_endorsement?application_imms_uuid=' + this.$route.params.id
    }
  },
  mounted() {
    this.fetch().then(data => {
      if (data.items != undefined && data.items.length > 0) {
        if (this.isDataValidation) {
          this.checkRow(data.items[0])
        }
        this.form = data.items[0]
      }
      else {
        this.checkRow(this.form)
      }
    })
  }
}
</script>
