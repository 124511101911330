import apiNode from "./../plugins/axios.node";
import api from "./../plugins/axios";

let url = "/scheduling/appointments";

export const state = {
  appointments: [],
  appointment: null,
  calendars: [],
  availableTimes: [],
  appointment_types: []
};

export const getters = {
  all(state) {
    return state.appointments;
  },
  getOne(state) {
    return state.appointment;
  },
  getCalendar(state) {
    return state.calendars;
  },
  getAvailableTimes(state) {
    return state.availableTimes;
  },
  appointmentTypes(state) {
    return state.appointment_types;
  }
};

export const actions = {
  getAll({ commit }, filter) {
    return apiNode.get("/scheduling/events" + filter).then(
      (response) => {
        commit("GET_DATAS", response.data.results);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  getCalendar({ commit }, email) {
    var qs = "";
    if (email != undefined) qs = "?profile_name=" + email;
    return apiNode.get("/scheduling/calendars" + qs).then(
      (response) => {
        commit("GET_CALENDAR", response.data.results);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  getCalendarByCid({ commit }, consultantId) {
    return apiNode.get("/consultants/finder/?uid=" + consultantId).then((response) => {
      if (response.data.results == undefined || response.data.results.profile_name == undefined) return Promise.resolve(undefined);
      var email = response.data.results.profile_name;
      var qs = "";
      if (email != undefined) qs = "?profile_name=" + email;
      return apiNode.get("/scheduling/calendars" + qs).then(
        (caledarReponse) => {
          commit("GET_CALENDAR_ONE", { calendars: caledarReponse.data.results, email: email });
          return Promise.resolve(caledarReponse);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    });
  },
  clearAT({ commit }) {
    commit("CLEAR_AT");
  },
  getAvailableTimes({ commit }, filter) {
    return apiNode.get("/scheduling/calculateAvailableTimes" + filter).then(
      (response) => {
        commit("GET_AT", response.data.results);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_AT", err);
        return Promise.reject(err);
      }
    );
  },
  reschedule({ commit }, data) {
    return apiNode.put(url + "/" + data.id + "/reschedule", { datetime: data.datetime }).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  createAppointment({ commit }, data) {
    return api.post("/scheduling/appointment/" + data.consultant_id, data).then(
      (response) => {
        commit("GET_AT", []);
        return Promise.resolve(response);
      },
      (err) => {
        commit("GET_AT", []);
        return Promise.reject(err.response.data.error.message);
      }
    );
  },
  purchaseAppointment({ commit }, params) {
    var data = {
      consultation_service_id: params.consultation_service_id,
      duration: params.consultation_period,
      applicant_id: params.applicant_id,
      consultant_id: params.consultant_id,
      summary: params.consultation_service_title + " : " + params.clientFirstName + " " + params.clientLastName + " : " + "Please login to the portal and join in the meeting using Zoom.",
      start_time: params.datetime,
      end_time: null,
      applicant_email: params.clientEmail,
      applicant_birth_date: params.clienBirthDate != undefined ? params.clienBirthDate : null,
      applicant_last_name: params.clientLastName,
      applicant_phone: params.clientPhone != undefined ? params.clientPhone : null,
      applicant_address: params.clientAddress != undefined ? params.clientAddress : null,
      user_accept: params.user_accept,
      booked_user_id: params.bookedUserId
    };
    return api.post(`/appointments/purchases/${params.consultation_service_fee}/${params.stripeToken}/${params.use_discount}`, data).then(
      (response) => {
        commit("GET_DATA", null);
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  postTemplate({ commit }, data) {
    return api.post("/esign/templates?guid=" + data.esignatureid, data).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err.response.data.error.message);
      }
    );
  },
  setAppointment({ commit }, data) {
    commit("GET_DATA", data);
  },
  edit({ commit }, data) {
    return apiNode.put(url, { resource: data }).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  delete({ commit }, data) {
    return apiNode.delete(url + "/" + data.id).then(
      (response) => {
        commit("DELETE_DATA", data);
        return Promise.resolve(response);
      },
      (error) => {
        commit("FAILURE_DATAS", error);
        return Promise.reject(error);
      }
    );
  },
  getAppointmentTypes({ commit }) {
    return apiNode.get("/scheduling/appointment_types").then(
      (response) => {
        commit("GET_APPOINTMENT_TYPES", response.data.results);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  sendEmail({ commit }, data) {
    return api.post("/email_service/appoinment_invitation?template=appoinment_invitation", data).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
};

export const mutations = {
  GET_DATAS(state, datas) {
    state.appointments = datas;
  },
  GET_CALENDAR(state, datas) {
    state.calendars = datas;
  },
  GET_CALENDAR_ONE(state, datas) {
    datas.calendars.forEach((element) => {
      if (element.profile_name == datas.email) {
        state.calendars = element;
      }
    });
  },
  GET_AT(state, datas) {
    state.availableTimes = datas;
  },
  CLEAR_AT(state) {
    state.availableTimes = [];
  },
  GET_DATA(state, data) {
    state.appointment = data;
  },
  ADD_DATA(state, data) {
    state.appointments.push(data);
  },
  UPDATE_DATA(state, data) {
    let idx = state.appointments.map((p) => p.id).indexOf(data.id);
    state.appointments.splice(idx, 1, data);
  },
  FAILURE_DATAS(state) {
    state.appointments = [];
  },
  FAILURE_AT(state) {
    state.availableTimes = [];
  },
  FAILURE_DATA(state, data) {
    state.appointment = {};
  },
  DELETE_DATA(state, data) {
    let idx = state.appointments.map((p) => p.id).indexOf(data.id);
    state.appointments.splice(idx, 1);
  },
  GET_APPOINTMENT_TYPES(state, datas) {
    state.appointment_types = datas;
  }
};
