
<template>
  <v-card v-if="hideSection" class="mt-3">
    <v-card-title>Residence Obligation - Time Spent Outside Canada</v-card-title>
    <v-card-text>
      <p><b>Complete this section if you have been outside of Canada for 1095 days or more in the past five (5) years.</b></p>
      <p><b>List ALL your absences from Canada in the past five(5) years according to the situation that applies to you. If you became a permanent residence less than five (5) years ago: list ALL absences from the time you became a permanent residence to present. List your absences in chronological order from the oldest to the most recent.</b></p>
      <p><b>Situation 1: Absences while accompaning a family member who is Canadian citizen</b></p>
      <v-row dense class="mb-3 mt-3">
        <v-col cols="3">
          <div class="pull-left">Name of family member</div>
        </v-col>
        <v-col cols="6">
          <el-input v-model="formGeneral.name_family_member" @change="saveGeneral(formGeneral)" style="width: 150px; margin-left: 25px" />
        </v-col>
        <v-col cols="3">
          <div class="pull-left">Provide proof of Canadian citizenship.</div>
        </v-col>
      </v-row>
      <v-row dense class="mb-3">
        <v-col cols="3">
          <div class="pull-left">Relationship to you</div>
        </v-col>
        <v-col cols="6">
          <el-input v-model="formGeneral.relationship_you" @change="saveGeneral(formGeneral)" style="width: 150px; margin-left: 25px" />
        </v-col>
        <v-col cols="3">
          <div class="pull-left">Provide proof of your relationship.</div>
        </v-col>
      </v-row>

      <table class="table" style="width:100%">
        <thead>
          <tr>
            <th width="135">From</th>
            <th width="135">To</th>
            <th>No. and street</th>
            <th>City/Town, country or territory</th>
            <th width="80">Days</th>
            <th width="30">
              <v-btn color="green" small fab @click.native="saveForm">
                <v-icon class="white--text">add</v-icon>
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(form,i) in items" :key="i" :id="'row' + form.id">
            <td class="pa-1">
              <el-date-picker v-if="dataValidationItems(form, 'from_date')" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.from_date" v-on:click.native="checkDate($event)" @change="handleAutoSave(i, form);getLength(form, i)" style="width: 100%;"></el-date-picker>
            </td>
            <td class="pa-1">
              <el-date-picker v-if="dataValidationItems(form, 'to_date')" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.to_date" v-on:click.native="checkDate($event)" @change="handleAutoSave(i, form);getLength(form, i)" style="width: 100%;"></el-date-picker>
            </td>
            <td class="pa-1">
              <el-input v-if="dataValidationItems(form, 'street')" v-model="form.street" style="width: 100%" @change="handleAutoSave(i, form)" />
            </td>
            <td class="pa-1">
              <el-input v-if="dataValidationItems(form, 'city')" v-model="form.city" style="width: 100%" @change="handleAutoSave(i, form)" />
            </td>
            <td class="pa-1 text-right">
              <el-input v-if="dataValidationItems(form, 'days')" v-model="form.days" disabled style="width: 100%" @change="handleAutoSave(i, form)" />
            </td>
            <td class="pa-1 text-center">
              <v-btn color="pink" small fab @click.native="deleteOpen(form, i)"><v-icon class="white--text">clear</v-icon></v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-if="!isDataValidation">
          <tr>
            <td colspan="4" class="text-right body-2">Total number of days</td>
            <td class="pa-1 text-right">
              <el-input v-model="totalDays" disabled style="width: 100%" />
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  name: 'ResidentObligationOutsideCanada',
  props: {
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        days: null,
        street: null,
        city: null,
        from_date: null,
        to_date: null
      },
      formGeneral: {
        name_family_member: null,
        relationship_you: null
      },
      check: false,
      url: 'imms_table/application_permanent_resident',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    totalDays: function() {
      var count = 0

      this.items.forEach(function(item) {
        if (item.days === null || item.days === undefined) item.days = 0
        count += parseFloat(item.days)
      })

      return count
    }
  },
  mounted() {
    this.fetchData()
    this.getGeneral()
  },
  methods: {
    async getGeneral() {
      try {
        let response = await this.$api.get(`table/application_citizenship_calculator?filter=member_uid=${this.memberUID}`)
        if (response.data.resource && response.data.resource.length > 0) {
          this.formGeneral = response.data.resource[0]
        }
      }
      catch (err) {
        console.error(err)
      }
    },
    getLength(form, index) {
      if (form.from_date && form.to_date) {
        let total = this.checkLength(form.from_date, form.to_date)

        if (total > 0) {
          form.days = total
          this.check = false
        }
        else {
          this.check = true
          form.to_date = null
        }
      }
    },
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_permanent_resident?filter=(member_uid=${this.memberUID})AND(type="situation1")&order=from_date ASC,to_date ASC`)
        .then(response => {
          let vm = this
          if (vm.isDataValidation) {
            response.resource.forEach(function(item) {
              var form = {
                street: null,
                city: null,
                from_date: null,
                to_date: null
              }

              var obj = vm.checkFormItems(item, form)
              if (obj != 'empty') {
                vm.items.push(item)
              }
            })

            if (response.resource.length == 0) {
              vm.hideSection = false
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
          else {
            vm.items = response.resource
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        days: null,
        street: null,
        city: null,
        from_date: null,
        to_date: null,
        type: 'situation1'
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    },
    async saveGeneral(item) {
      try {
        let obj = {
          relationship_you: item.relationship_you,
          name_family_member: item.name_family_member,
          application_id: this.$route.params.id,
          member_uid: this.memberUID,
          member_id: parseFloat(this.memberID)
        }
        let resGet = await this.$api.get(`table/application_citizenship_calculator?filter=member_uid=${this.memberUID}`)

        if (resGet.data.resource.length > 0) {
          obj.id = resGet.data.resource[0].id
          obj.imms_uuid = resGet.data.resource[0].imms_uuid
          await this.$api.put(`imms_table/application_citizenship_calculator/${obj.imms_uuid}`, obj)
        }
        else {
          let response = await this.$api.post('imms_table/application_citizenship_calculator', { resource: obj })
          if (response.data.resource && response.data.resource.length > 0) {
            this.formGeneral.id = response.data.resource[0].id
            this.formGeneral.imms_uuid = response.data.resource[0].imms_uuid
          }
        }
      }
      catch (err) {
        console.error(err)
      }
    }
  }
}
</script>
