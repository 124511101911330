<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>
        <span>{{sectionLabel}}</span>
        <v-spacer />
        <v-btn color="primary" dark small @click.native="add()">Add</v-btn>
      </v-card-title>
      <v-card-text class="p-n">
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>

        <v-row dense class="customCollapseHeader">
          <v-col cols="3">First name</v-col>
          <v-col cols="3">Last name</v-col>
          <v-col cols="3">Date of birth</v-col>
          <v-col cols="3"></v-col>
        </v-row>
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i" :id="'row' + form.id">
            <template slot="title">
              <div v-on:click="openCollapse(form)" class="customHead">
                <v-row dense>
                  <v-col cols="3">{{ form.fname }}</v-col>
                  <v-col cols="3">{{ form.lname }}</v-col>
                  <v-col cols="3"><el-tag type="primary" v-if="form.date_birth != null">{{ form.date_birth | day }}</el-tag></v-col>
                  <v-col cols="3"></v-col>
                </v-row>
              </div>
            </template>
            <el-form :model="form" ref="form" label-position="top" class="pa-2">
              <v-row dense class="medium-space">
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item label="First name" prop="fname">
                    <el-input :id="'fname' + form.id" v-model="form.fname" style="width: 100%" @change="handleAutoSave(i, form)" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item label="Last name" prop="lname">
                    <el-input :id="'lname' + form.id" v-model="form.lname" style="width: 100%" @change="handleAutoSave(i, form)" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="6">
                  <el-form-item label="Other name(s) used" prop="other_name">
                    <el-input :id="'other_name' + form.id" v-model="form.other_name" style="width: 100%" @change="handleAutoSave(i, form)" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item label="Country of Birth" prop="country_birth">
                    <div class="el-select native-select">
                      <div class="el-input">
                        <select :id="'country_birth' + form.id" v-model="form.country_birth" placeholder="Select" class="el-input__inner" @change="handleAutoSave(i, form)">
                          <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                        </select>
                      </div>
                    </div>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item label="Date of Birth" prop="date_birth">
                    <el-date-picker :id="'date_birth' + form.id" v-model="form.date_birth" style="width: 100%" @change="handleAutoSave(i, form)"></el-date-picker>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item label="Citizenship certificate number" prop="certificate">
                    <el-input :id="'certificate' + form.id" type="number" v-model="form.certificate" style="width: 100%" @change="handleAutoSave(i, form)" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item label="Date of certificate" prop="date_certificate">
                    <el-date-picker :id="'date_certificate' + form.id" v-model="form.date_certificate" style="width: 100%" @change="handleAutoSave(i, form)"></el-date-picker>
                  </el-form-item>
                </v-col>
              </v-row>
              <v-row dense class="medium-space mb-3">
                <v-col cols="12" sm="6" class="citizen">
                  <el-form-item prop="grandparent_citizen">
                    <div class="text13 pull-left">Was the child's grandparent(s) a Canadian citizen at the time of their parent's birth</div>
                    <el-radio-group class="ml-3 mt-2 pull-left" @change="handleAutoSave(i, form)" v-model="form.grandparent_citizen">
                      <el-radio label="No">No</el-radio>
                      <el-radio label="Yes">Yes</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" class="citizen">
                  <el-form-item prop="parent">
                    <div class="text13 pull-left">Indicate whether the child's parent is the natural or adoptive parent</div>
                    <el-radio-group class="ml-3 mt-2 pull-left" @change="handleAutoSave(i, form)" v-model="form.parent">
                      <el-radio label="Natural">Natural</el-radio>
                      <el-radio label="Adoptive">Adoptive</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </v-col>
              </v-row>
              <v-row dense class="medium-space mb-3">
                <v-col cols="12" sm="6" class="citizen">
                  <el-form-item prop="parent_employed">
                    <div class="text13 pull-left">Was the parent employed outside Canada as a Crown servant at the time of the child's birth or adoption?</div>
                    <el-radio-group class="ml-3 mt-2 pull-left" @change="handleAutoSave(i, form)" v-model="form.parent_employed">
                      <el-radio label="No">No</el-radio>
                      <el-radio label="Yes">Yes</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" class="citizen">
                  <el-form-item prop="grandparent_employed">
                    <div class="text13 pull-left">Were either of the child's grandparents employed outside Canada as Crown servant at the time of the parent's birth or
                      adoption?</div>
                    <el-radio-group class="ml-3 mt-2 pull-left" @change="handleAutoSave(i, form)" v-model="form.grandparent_employed">
                      <el-radio label="No">No</el-radio>
                      <el-radio label="Yes">Yes</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </v-col>
              </v-row>
              <v-row dense class="medium-space mb-3">
                <v-col cols="12" sm="6" class="citizen">
                  <el-form-item prop="parent_leave">
                    <div class="text13 pull-left">Did the parent leave Canada for more than 1 year before February 15th, 1977?</div>
                    <el-radio-group class="ml-3 mt-2 pull-left" @change="handleAutoSave(i, form)" v-model="form.parent_leave">
                      <el-radio label="No">No</el-radio>
                      <el-radio label="Yes">Yes</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="form.parent_leave === 'Yes'">
                  <table class="table" style="width:100%">
                    <thead>
                      <tr>
                        <th width="80">From</th>
                        <th width="80">To</th>
                        <th>Destination (Country)</th>
                        <th width="30">
                          <v-btn color="green" small fab @click.native="newDestination(form)">
                            <v-icon class="white--text">add</v-icon>
                          </v-btn>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(fr,j) in destinations" :key="j">
                        <td class="pa-1">
                          <el-date-picker value="yyyy-MM-dd" v-model="fr.from_date" @change="saveDestination(form)"></el-date-picker>
                        </td>
                        <td class="pa-1">
                          <el-date-picker value="yyyy-MM-dd" v-model="fr.to_date" @change="saveDestination(form)"></el-date-picker>
                        </td>
                        <td class="pa-1">
                          <div class="el-select native-select">
                            <div class="el-input">
                              <select v-model="fr.country" placeholder="Select" class="el-input__inner" @change="saveDestination(form)">
                                <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                              </select>
                            </div>
                          </div>
                        </td>
                        <td class="pa-1 text-center">
                          <v-btn color="pink" small fab @click.native="deleteDestination(fr, j)">
                            <v-icon class="white--text">clear</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
                <v-col cols="12">
                  <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="save(i,form)">
                    {{ $t('global.save') }}
                  </v-btn>
                  <v-btn color="error" small @click.native="deleteOpen(form,i)">Delete</v-btn>
                </v-col>
              </v-row>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import autosave from '@/mixins/autosave.js'

export default {
  name: 'Parents',
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [autosave],
  data() {
    return {
      edit: false,
      activeNames: -1,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        grandparent_employed: null,
        parent_employed: null,
        parent_leave: null,
        parent: null,
        grandparent_citizen: null,
        certificate: null,
        date_certificate: null,
        date_birth: null,
        country_birth: null,
        other_name: null,
        fname: null,
        lname: null
      },
      url: 'imms_table/application_parent',
      destinations: [],
      items: [],
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country'
    })
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    openCollapse(item) {
      this.$store.dispatch('store/get', 'table/application_parent_destination?filter=application_parent_id=' + item.id + '&order=from_date DESC,to_date DESC')
        .then(response => {
          if (response.resource.length > 0) {
            this.destinations = response.resource
          }
        })
    },
    saveDestination(form) {
      let that = this
      let id = form.id || undefined

      if (that.destinations.length > 0 && id != undefined) {
        that.destinations.forEach(function(item) {
          if (item.id == undefined) {
            item.application_parent_id = id
            that.$store.dispatch('store/save', { url: 'imms_table/application_parent_destination', data: item })
              .then(response => {
                that.openCollapse(form)
              })
          }
          else {
            that.$store.dispatch('store/edit', { url: 'imms_table/application_parent_destination', data: item })
          }
        })
      }
    },
    newDestination(item) {
      var form = {
        from_date: null,
        to_date: null,
        country: null,
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        application_parent_id: null
      }
      this.destinations.push(form)
    },
    deleteDestination(item, index) {
      this.destinations.splice(index, 1)
      if (item.id != undefined) {
        this.$store.dispatch('store/delete', 'table/application_parent_destination/' + item.id)
      }
    },
    checkDate: function(event) {
      this.$emit('checkDate', event)
    },
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_parent?filter=member_uid=${this.memberUID}` + '&order=date_birth DESC')
        .then(response => {
          this.items = response.resource
          if (this.datas !== undefined) this.checkTableFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        destinations: [],
        grandparent_employed: null,
        parent_employed: null,
        parent_leave: null,
        parent: null,
        grandparent_citizen: null,
        certificate: null,
        date_certificate: null,
        date_birth: null,
        country_birth: null,
        other_name: null,
        fname: null,
        lname: null
      }
      this.edit = false
    },
    add() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
      this.destinations = []
    },
    save(index, formObj) {
      this.$emit('onSave')
    },
    allSaveForm() {
      if (this.items.length > 0) {
        this.loading = true

        this.items.forEach(function(form) {
          var action = 'store/save'

          if (form.id != undefined) {
            action = 'store/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': form })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                form.id = response.data.resource[0].id
                form.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.saveDestination()

              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
              this.activeNames = -1
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }, this)
      }
      else {
        return false
      }
    },
    submitForm(index, formObj) {
      if (index != undefined && formObj != undefined) {
        this.$refs.form[index].validate((valid) => {
          if (valid) {
            this.loading = true

            if (formObj.id != undefined) this.edit = true

            var action = 'store/save'
            if (this.edit) {
              action = 'store/edit'
            }
            this.$Progress.start()
            this.activeNames = -1
            this.$store.dispatch(action, { 'url': this.url, 'data': formObj })
              .then(response => {
                if (response.data.resource && response.data.resource.length > 0) {
                  formObj.id = response.data.resource[0].id
                  formObj.imms_uuid = response.data.resource[0].imms_uuid
                }
                this.saveDestination()
                this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
                this.$Progress.finish()
                this.loading = false
              })
              .catch(err => {
                this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
                this.$Progress.fail()
                this.loading = false
              })
          }
          else {
            return false
          }
        })
      }
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    },
    deleteOpen(item, index) {
      var that = this
      this.$confirm('Are you sure to delete this item?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        if (item.id == undefined) {
          that.items.splice(index, 1)
        }
        else {
          var url = this.url + '/' + item.id
          this.$store.dispatch('store/delete', url)
            .then(response => {
              this.$store.dispatch('toaster/add', { text: 'Delete', type: 'success' })
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Canceled', type: 'success' })
            })
        }
        that.activeNames = -1
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Canceled'
        })
      })
    }
  }
}
</script>

<style>
  .citizen .text13{
    width: 60%;
    line-height: 20px;
  }
  .table .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 150px;
  }
</style>
