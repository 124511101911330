<template>
  <el-form
    v-if="hideSection && isHideSection1 && isHideSection2"
    :model="form"
    ref="formGeneric"
    :rules="rules"
    class="mt-3"
    label-position="top">
    <v-card>
      <v-card-title>
        <span>{{ $t('title.imm_0008_contact_information') }}</span>
        <v-spacer />
      </v-card-title>
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>

      <current-mailing-address @checkSection="checkSection" :sectionId="sectionId" :form="form" :isDataValidation="isDataValidation" :isOINP="isOINP" :isYukon="isYukon"></current-mailing-address>

      <residential-address @checkSection="checkSection" :sectionId="sectionId" :form="form" :isDataValidation="isDataValidation" :isOINP="isOINP" :isYukon="isYukon"></residential-address>

      <v-card-text class="p-n" v-if="hideSection">
        <v-row dense class="medium-space" v-if="dataValidation(form, 'res_phone_country') || dataValidation(form, 'res_phone_type') || dataValidation(form, 'res_phone_number')">
          <v-col cols="12">
            <v-divider class="mb-3"></v-divider>
            <div class="body-2 mb-2 pull-left">Telephone No.</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_phone_country')">
            <el-form-item label="Country" prop="res_phone_country">
              <el-radio-group v-model="form.res_phone_country" @change="saveIMM(form)" style="width: 100%;">
                <el-radio label="Canada/US">Canada/US</el-radio>
                <el-radio label="Other">Other</el-radio>
              </el-radio-group>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_phone_type')">
            <el-form-item label="Type" prop="res_phone_type">
              <el-select v-model="form.res_phone_type" @change="saveIMM(form)" placeholder="Select" style="width: 100%;">
                <el-option value="Residence">Residence</el-option>
                <el-option value="Cellular">Cellular</el-option>
                <el-option value="Business">Business</el-option>
              </el-select>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_phone_number')">
            <el-form-item :label="$t('imm_0008.res_phone_number')" prop="res_phone_number">
              <div v-if="form.res_phone_country == 'Canada/US'" class="el-input">
                <the-mask mask="+F (SOO) OOO-OOOO" :tokens="phoneTokens" value="form.res_phone_number" v-model="form.res_phone_number" type="tel" @change.native="saveIMM(form)" class="el-input__inner"></the-mask>
                <el-tag size="small" type="info">Example: +1 (201) 555-0000</el-tag>
              </div>
              <el-input v-else type="number" @change="saveIMM(form);" v-model="form.res_phone_number" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <template v-if="isOINP">
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_phone_extension')">
              <el-form-item label="Extension" prop="res_phone_extension">
                <el-input v-model="form.res_phone_extension" @change="saveIMM(form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </template>
        </v-row>
        <v-row dense class="medium-space" v-if="dataValidation(form, 'alt_phone_country') || dataValidation(form, 'alt_phone_type') || dataValidation(form, 'alt_phone_number')">
          <v-col cols="12">
            <v-divider class="mb-3"></v-divider>
            <div class="body-2 mb-2 pull-left">Alternate Telephone No.</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'alt_phone_country')">
            <el-form-item label="Country" prop="alt_phone_country">
              <el-radio-group v-model="form.alt_phone_country" @change="saveIMM(form)" style="width: 100%;">
                <el-radio label="Canada/US">Canada/US</el-radio>
                <el-radio label="Other">Other</el-radio>
              </el-radio-group>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'alt_phone_type')">
            <el-form-item :label="$t('imm_0008.alt_phone_type')" prop="alt_phone_type">
              <el-select v-model="form.alt_phone_type" @change="saveIMM(form)" placeholder="Select" style="width: 100%;">
                <el-option value="Residence">Residence</el-option>
                <el-option value="Cellular">Cellular</el-option>
                <el-option value="Business">Business</el-option>
              </el-select>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'alt_phone_number')">
            <el-form-item :label="$t('imm_0008.alt_phone_number')" prop="alt_phone_number">
              <div v-if="form.alt_phone_country == 'Canada/US'" class="el-input">
                <the-mask mask="+F (SOO) OOO-OOOO" :tokens="phoneTokens" value="form.alt_phone_number" v-model="form.alt_phone_number" type="tel" @change.native="saveIMM(form)" class="el-input__inner"></the-mask>
                <el-tag size="small" type="info">Example: +1 (201) 555-0000</el-tag>
              </div>
              <el-input v-else type="number" v-model="form.alt_phone_number" @change="saveIMM(form);" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <template v-if="isOINP">
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'alt_phone_extension')">
              <el-form-item label="Extension" prop="alt_phone_extension">
                <el-input v-model="form.alt_phone_extension" @change="saveIMM(form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </template>
        </v-row>
        <v-row dense class="medium-space" v-if="dataValidation(form, 'res_fax_country') || dataValidation(form, 'res_fax_no')">
          <v-col cols="12">
            <v-divider class="mb-3"></v-divider>
            <div class="body-2 mb-2 pull-left">Fax No.</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_fax_country')">
            <el-form-item label="Country" prop="res_fax_country">
              <el-radio-group v-model="form.res_fax_country" @change="saveIMM(form)" style="width: 100%;">
                <el-radio label="Canada/US">Canada/US</el-radio>
                <el-radio label="Other">Other</el-radio>
              </el-radio-group>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_fax_no')">
            <el-form-item :label="$t('imm_0008.res_fax_no')" prop="res_fax_no">
              <div v-if="form.res_fax_country == 'Canada/US'" class="el-input">
                <the-mask mask="+F (SOO) OOO-OOOO" :tokens="phoneTokens" value="form.res_fax_no" v-model="form.res_fax_no" type="tel" @change.native="saveIMM(form)" class="el-input__inner"></the-mask>
                <el-tag size="small" type="info">Example: +1 (201) 555-0000</el-tag>
              </div>
              <el-input v-else type="number" v-model="form.res_fax_no" @change="saveIMM(form)" style="width: 100%;" />
            </el-form-item>
          </v-col>
        </v-row>
        <v-row dense class="medium-space">
          <v-col cols="12">
            <v-divider class="mb-3"></v-divider>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_email')">
            <el-form-item :label="$t('imm_0008.res_email')" prop="res_email" :rules="[{ type: 'email', message: 'Please input correct email address', trigger: 'blur,change' }]">
              <el-input type="email" v-model="form.res_email" @change="saveIMM(form)" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'alt_email')">
            <el-form-item label="Alternate email" prop="alt_email" :rules="[{ type: 'email', message: 'Please input correct email address', trigger: 'blur,change' }]">
              <el-input type="email" v-model="form.alt_email" @change="saveIMM(form)" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <template v-if="isOINP">
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'noc_code')">
              <el-form-item label="NOC code" prop="noc_code">
                <el-input v-model="form.noc_code" @change="saveIMM(form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'working_gta')">
              <el-form-item label="Is the location where you will be working in the Greater Toronto Area (GTA)?" prop="working_gta">
                <el-radio-group v-model="form.working_gta" @change="saveIMM(form)" style="width: 100%;">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col v-if="form.working_gta == 'Yes'" cols="12" sm="6" md="4" lg="3">
              <el-form-item v-if="dataValidation(form, 'working_gta_region')" label="In which region of the GTA" prop="working_gta_region">
                <el-select v-model="form.working_gta_region" @change="saveIMM(form)" style="width: 100%;">
                  <el-option value="City of Toronto" label="City of Toronto">City of Toronto</el-option>
                  <el-option value="Durham" label="Durham">Durham</el-option>
                  <el-option value="Halton" label="Halton">Halton</el-option>
                  <el-option value="York" label="York">York</el-option>
                  <el-option value="Peel" label="Peel">Peel</el-option>
                </el-select>
              </el-form-item>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
  </el-form>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import ResidentialAddress from './residential_address'
import CurrentMailingAddress from './current_mailing_address'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    },
    isOINP: {
      type: Boolean
    },
    isYukon: {
      type: Boolean
    }
  },
  data() {
    var checkPhoneRes = (rule, value, callback) => {
      if (value !== undefined && value !== null && value.length < 11) {
        if (this.form.res_phone_country == 'Canada/US') {
          callback(new Error('Please input 11 digits'));
        }
        else {
          callback();
        }
      }
      else {
        callback();
      }
    }
    var checkFax = (rule, value, callback) => {
      if (value !== undefined && value !== null && value.length < 11) {
        if (this.form.res_fax_country == 'Canada/US') {
          callback(new Error('Please input 11 digits'));
        }
        else {
          callback();
        }
      }
      else {
        callback();
      }
    }
    var checkPhone = (rule, value, callback) => {
      if (value == this.form.res_phone_number) {
        callback(new Error('Telephone and Alternate Telephone must be different'))
      }
      else {
        if (value !== undefined && value !== null && value.length < 11) {
          if (this.form.alt_phone_country == 'Canada/US') {
            callback(new Error('Please input 11 digits'));
          }
          else {
            callback();
          }
        }
        else {
          callback();
        }
      }
    }
    return {
      isHideSection1: true,
      isHideSection2: true,
      rules: {
        res_phone_number: [
          { validator: checkPhoneRes, trigger: 'blur' }
        ],
        alt_phone_number: [
          { validator: checkPhone, trigger: 'blur' }
        ],
        res_fax_no: [
          { validator: checkFax, trigger: 'blur' }
        ]
      },
      phoneTokens: {
        F: {
          pattern: /[1]/
        },
        S: {
          pattern: /[2-9]/
        },
        O: {
          pattern: /[0-9]/
        }
      }
    }
  },
  mounted() {
    if (this.isDataValidation) {
      var item = {
        alt_phone_type: null,
        alt_phone_number: null,
        res_phone_number: null,
        res_fax_no: null,
        res_email: null,
        res_fax_country: null,
        res_phone_type: null,
        alt_phone_country: null,
        alt_email: null
      }

      if (!this.isOINP) {
        delete item.res_phone_extension
        delete item.alt_phone_extension
        delete item.noc_code
        delete item.working_gta
        delete item.working_gta_region
      }

      this.checkItem(this.form, item)
    }
  },
  components: {
    TheMask,
    ResidentialAddress,
    CurrentMailingAddress
  },
  methods: {
    checkSection(item) {
      if (this.isDataValidation) {
        if (item.id == 1) {
          this.isHideSection1 = item.val
        }
        if (item.id == 2) {
          this.isHideSection2 = item.val
        }
      }
    },
    saveIMM(formGeneric) {
      var that = this
      that.$refs.formGeneric.validate((valid) => {
        if (valid) {
          that.$store.dispatch('generic/edit', formGeneric)
        }
        else {
          return false
        }
      })
    }
  }
}
</script>
