<template>
  <v-card v-if="hideSection">
    <v-card-text class="p-n">
      <table class="table" style="width:100%">
        <thead>
          <tr>
            <th>Status</th>
            <th>Date your status was <br> obtained/effective</th>
            <th>Date your status expired</th>
            <th width="30">
              <v-btn color="green" small fab @click.native="saveForm()">
                <v-icon class="white--text">add</v-icon>
              </v-btn>
            </th>       
          </tr>
        </thead>
        <tbody>
          <tr v-for="(form,i) in items" :key="i" :id="'row' + form.id">
            <td class="pa-1">
              <el-select v-if="dataValidationItems(form, 'status')" v-model="form.status" style="width: 100%" @change="handleAutoSave(i, form)">
                <el-option label="Visitor" value="Visitor"></el-option>
                <el-option label="Student" value="Student"></el-option>
                <el-option label="Temporary resident permit holder" value="Temporary resident permit holder"></el-option>
                <el-option label="Protected person" value="Protected person"></el-option>
              </el-select>                    
            </td>                
            <td class="pa-1">
              <el-date-picker v-if="dataValidationItems(form, 'obtained_date')" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.obtained_date" @change="handleAutoSave(i, form)" style="width: 100%;"></el-date-picker>
            </td>
            <td class="pa-1">
              <el-date-picker v-if="dataValidationItems(form, 'expired_date')" value-format="yyyy-MM-dd" v-model="form.expired_date" @change="handleAutoSave(i, form)" style="width: 100%;"></el-date-picker>
            </td>  
            <td class="pa-1 text-center">
              <v-btn color="pink" small fab @click.native="deleteOpen(form, i)">
                <v-icon class="white--text">clear</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  name: 'TemporaryResidenceStatus',
  props: {
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        status: null,
        obtained_date: null,
        expired_date: null
      },
      url: 'imms_table/application_temporary_residence_status',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  }, 
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_temporary_residence_status?filter=member_uid=${this.memberUID}`)
        .then(response => {
          let vm = this
          if (vm.isDataValidation) {
            response.resource.forEach(function(item) {
              var form = {
                year: null,
                required: null,
                taxes: null
              }

              var obj = vm.checkFormItems(item, form)

              if (obj != 'empty') {
                vm.items.push(item)
              }
            })
          
            if (response.resource.length == 0) {
              vm.hideSection = true
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
          else {
            vm.items = response.resource
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        status: null,
        obtained_date: null,
        expired_date: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>