<template>
  <div class="mt-3" v-if="hideSection">
    <v-card>
      <v-card-title>
        <span>{{ $t('title.imm_1294_residence') }}</span>
        <v-spacer />
        <v-btn color="primary" :disabled="!notApplicable" dark small @click.native="saveForm()">{{ $t('global.add') }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense class="mb-3 flex">
          <v-col cols="10">
            <div class="body-2">{{ $t('infobox.imm_1294_residence') }}</div>
          </v-col>
          <v-col cols="2">
            <el-checkbox class="ml-5 mt-2" v-model="notApplicable" @change="checkApplicable()">{{ $t('global.yes') }}</el-checkbox>
          </v-col>
        </v-row>
        <v-alert type="info" border="left" dense class="mb-3" v-if="$t('infobox.imm_1294_residence_infobox') !== ''">
           {{ $t('infobox.imm_1294_residence_infobox') }}
        </v-alert>
          <v-row dense class="customCollapseHeader">
            <v-col class="d-none d-sm-flex" sm="3">Country</v-col>
            <v-col class="d-none d-sm-flex" sm="3">Status</v-col>
            <v-col cols="6" sm="3">From Date</v-col>
            <v-col cols="6" sm="3">To Date</v-col>
          </v-row>
          <el-collapse v-model="activeNames" accordion v-if="notApplicable">
            <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i">
              <template slot="title">
                <div class="customHead">
                  <v-row dense>
                    <v-col class="d-none d-sm-flex" sm="3"><div>{{ form.country_name }}</div></v-col>
                    <v-col class="d-none d-sm-flex" sm="3"><div>{{ form.residence_status }}</div></v-col>
                    <v-col cols="6" sm="3">
                      <el-tag class="ml-3" type="primary" v-if="form.from_date !== null && form.from_date != undefined">{{ form.from_date | day }}</el-tag>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <el-tag class="ml-3" type="success" v-if="form.to_date !== null && form.to_date != undefined">{{ form.to_date | day }}</el-tag>
                    </v-col>
                  </v-row>
                </div>
              </template>

              <el-form :model="form" ref="form" label-position="top">
                <v-row dense>
                  <v-col cols="12" sm="6" md="3" v-if="dataValidationItems(form, 'country_id')">
                    <el-form-item :label="$t('imm_1294_residence.country_id')" prop="country_id">
                      <div class="el-select native-select">
                        <div class="el-input">
                          <select v-model="form.country_id" placeholder="Select" @change="changeCountry(form);handleAutoSave(i, form)" class="el-input__inner">
                            <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                          </select>
                        </div>
                      </div>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="dataValidationItems(form, 'residence_status')">
                    <el-form-item :label="$t('imm_1294_residence.residence_status')" prop="residence_status">
                      <el-select v-model="form.residence_status" @change="handleAutoSave(i, form)" placeholder="Select" style="width: 100%;">
                        <el-option :value="item.name" v-for="(item, index) in status" v-bind:key="index">{{ item.name }}</el-option>
                      </el-select>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="dataValidationItems(form, 'from_date')">
                    <el-form-item v-if="form.residence_status !== 'Citizen'" :label="$t('imm_1294_residence.from_date')" prop="from_date">
                      <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.from_date" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" v-if="dataValidationItems(form, 'to_date')">
                    <el-form-item v-if="form.residence_status !== 'Citizen'" :label="$t('imm_1294_residence.to_date')" prop="to_date">
                      <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.to_date" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" v-if="dataValidationItems(form, 'other')">
                    <el-form-item v-if="form.residence_status !== 'Citizen'" :label="$t('imm_1294_residence.other')" prop="other">
                      <el-input v-model="form.other" @change="handleAutoSave(i, form)" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                </v-row>
                <v-row dense class="mt-2">
                  <v-col cols="12">
                    <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(i,form)">
                      {{ $t('global.save') }}
                    </v-btn>
                    <v-btn :loading="autoSave" :disabled="autoSave" color="error" small @click.native="deleteItem(form,i)">{{ $t('global.delete') }}</v-btn>
                  </v-col>
                </v-row>
              </el-form>
            </el-collapse-item>
          </el-collapse>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        country_id: null,
        residence_status: null,
        other: null,
        from_date: null,
        to_date: null
      },
      activeNames: -1,
      applicable: false,
      url: 'imms_table/imm_1294_residence',
      deleteUrl: 'imms/imm_1294_residence',
      loading: false,
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      status: 'other/status',
      country: 'other/country',
      applicableId: 'applicable/id'
    }),
    notApplicable: {
      get() {
        return this.$store.state.applicable.notApplicable.prevresidence
      },
      set(val) {
        this.$store.commit('applicable/set_prevresidence', val)
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    changeCountry(item) {
      let idx = this.country.map(p => p.id).indexOf(item.country_id)
      item.country_name = this.country[idx].country_name
    },
    fetchData() {
      this.$Progress.start()
      var url = `table/imm_1294_residence_view?filter=member_uid=${this.memberUID}&order=to_date ASC`
      this.$store.dispatch('store/get', url).then(response => {
        let vm = this

        if (vm.isDataValidation) {
          if (!vm.notApplicable) {
            vm.hideSection = false
          }
          else {
            response.resource.forEach(function(item) {
              var form = {
                country_id: null,
                residence_status: null,
                from_date: null,
                to_date: null
              }

              if (item.residence_status == 'Citizen') {
                delete form.to_date
                delete form.from_date
              }

              var obj = vm.checkFormItems(item, form)

              if (obj != 'empty') {
                vm.items.push(item)
              }
            })
            if (response.resource.length == 0) {
              vm.hideSection = true
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
        }
        else {
          vm.items = response.resource
        }
        this.$Progress.finish()
      })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        country_id: null,
        residence_status: null,
        other: null,
        from_date: null,
        to_date: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    },
    checkApplicable() {
      var action = 'applicable/save'
      var that = this
      var data = {}
      var message = 'Yes'
      data.prevresidence = that.notApplicable
      data.application_id = this.$route.params.id
      data.member_id = parseFloat(this.memberID)
      data.member_uid = this.memberUID
      if (that.applicableId != undefined) {
        data.id = that.applicableId
        action = 'applicable/edit'
        message = 'No'
      }
      this.$Progress.start()
      this.$store.dispatch(action, data).then(response => {
        this.$store.dispatch('toaster/add', { text: message, type: 'success' })
        this.$Progress.finish()
      })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'No', type: 'success' })
          this.$Progress.fail()
        })
    }
  }
}
</script>
