<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <v-row dense class="medium-space">
        <v-col cols="12" v-if="dataValidation(form, 'work_type')">
          <el-form-item label="What type of work permit are you applying for?" prop="work_type">
            <el-select @change="saveIMM(form)" v-model="form.work_type" style="width: 100%;">
              <el-option v-for="(item, index) in workTypes" v-bind:key="index" :value="item" :label="item">{{ item }}</el-option>
            </el-select>
          </el-form-item>
        </v-col>
        <v-col cols="12" v-if="dataValidation(form, 'work_name_employer') || dataValidation(form, 'work_address_employer')">
          <div class="body-2 mb-2 pull-left">Details of my prospective employer (attach original offer of employment)</div>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'work_name_employer')">
          <el-form-item label="a. Name of Employer (if you are employed by a foriegn employer who has been awarded a contract to provide services to a Canadian entity, please the foreign employer here)" prop="work_name_employer">
            <el-input @change="saveIMM(form)" v-model="form.work_name_employer" style="width: 100%;" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'work_address_employer')">
          <el-form-item label="b. Complete Address of Employer (Canadian or Foriegn)" prop="work_address_employer">
            <el-input @change="saveIMM(form)" v-model="form.work_address_employer" style="width: 100%;" />
          </el-form-item>
        </v-col>
        <v-col cols="12" v-if="dataValidation(form, 'work_province') || dataValidation(form, 'work_city') || dataValidation(form, 'work_address')">
          <div class="body-2 mb-2 pull-left">Intended location of employment in Canada?</div>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'work_province')">
          <el-form-item label="Province" prop="work_province">
            <div class="el-select native-select">
              <div class="el-input">
                <select @change="saveIMM(form)" v-model="form.work_province" placeholder="Select" class="el-input__inner">
                  <option v-for="(item, index) in province" v-bind:key="index" :value="item.province_name" :label="item.province_name">{{ item.province_name }}</option>
                </select>
              </div>
            </div>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'work_city')">
          <el-form-item label="City/Town" prop="work_city">
            <el-input @change="saveIMM(form)" v-model="form.work_city" style="width: 100%" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'work_address')">
          <el-form-item label="Address" prop="work_address">
            <el-input @change="saveIMM(form)" v-model="form.work_address" type="textarea" :row="5" style="width: 100%" />
          </el-form-item>
        </v-col>
        <v-col cols="12" class="mb-3" v-if="dataValidation(form, 'work_job_title') || dataValidation(form, 'work_desc_duties')">
          <div class="body-2 mb-2 pull-left">My occupation in Canada will be:</div>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'work_job_title')">
          <el-form-item label="Job title" prop="work_job_title">
            <el-input @change="saveIMM(form)" v-model="form.work_job_title" style="width: 100%;" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'work_desc_duties')">
          <el-form-item label="Brief description of duties" prop="work_desc_duties">
            <el-input @change="saveIMM(form)" v-model="form.work_desc_duties" style="width: 100%;" />
          </el-form-item>
        </v-col>
      </v-row>
      <v-row dense class="medium-space">
        <v-col cols="12" class="mb-3" v-if="dataValidation(form, 'work_from_date') || dataValidation(form, 'work_to_date') || dataValidation(form, 'work_lmia')">
          <div class="body-2 mb-2 pull-left">Duration of expected employment:</div>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'work_from_date')">
          <el-form-item label="From" prop="work_from_date">
            <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.work_from_date" @click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'work_to_date')">
          <el-form-item label="To" prop="work_to_date">
            <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.work_to_date" @click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'work_lmia')">
          <el-form-item label="Labout Market Impact Assessment(LMIA) No. or Offer of Employment (LMIA Exempt) No" prop="work_lmia">
            <el-input @change="saveIMM(form)" v-model="form.work_lmia" style="width: 100%;" />
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  computed: mapGetters({
    province: 'other/province'
  }),
  data() {
    return {
      workTypes: ['Exemption from Labour Market Impact Assessment', 'Labour Market Impact Assessment Stream', 'Open Work Permit', 'Seasonal Agricultural Workers Program', 'Start-up Business Class', 'Other']
    }
  },
  mounted() {
    if (this.isDataValidation) {
      var item = {
        work_type: null,
        work_name_employer: null,
        work_address_employer: null,
        work_province: null,
        work_city: null,
        work_address: null,
        work_job_title: null,
        work_desc_duties: null,
        work_from_date: null,
        work_to_date: null,
        work_lmia: null
      }

      this.checkItem(this.form, item)
    }
  }
}
</script>
