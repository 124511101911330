
<template>
  <v-card class="mt-3">
    <v-card-title>
      <span>Ontario Corporate Stream Application</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()">Add</v-btn>
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{ sectionInfoBox }}
      </v-alert>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item :name="i+1" v-for="(item,i) in items" :key="i" :id="'row' + item.id">
          <template slot="title">
            <div v-on:click="collapse(item)" class="relative">
              <span class="el-col-title">{{item.corprate_name}}</span>
            </div>
          </template>
          <el-form :model="item" ref="form" label-position="top" class="mt-3 form">
            <v-row dense class="medium-space">
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="Is your proposed business a new business or a business succession?" prop="proposed_business">
                  <el-select :id="'proposed_business' + item.id" v-model="item.proposed_business" style="width: 100%;">
                    <el-option value="New business">New business</el-option>
                    <el-option value="Business succession">Business succession</el-option>
                  </el-select>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="Name of the corporation making the investment in Ontario?" prop="corprate_name">
                  <el-input :id="'corprate_name' + item.id" type="text" v-model="item.corprate_name" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="Date of establishment of corporation?" prop="est_date">
                  <el-date-picker :id="'est_date' + item.id" value-format="yyyy-MM-dd" v-model="item.est_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="Has this corporation operated continuously since establishment?" prop="corp_opertion">
                  <el-select :id="'corp_opertion' + item.id" v-model="item.corp_opertion" style="width: 100%;">
                    <el-option value="Yes">Yes</el-option>
                    <el-option value="No">No</el-option>
                  </el-select>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="medium-space">
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="If no, provide details (maximum 250 words)?" prop="details">
                  <el-input :id="'details' + item.id" type="textarea" :autosize="{ minRows: 4, maxRows: 5}" v-model="item.details" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="Type of business?" prop="business_type">
                  <el-input :id="'business_type' + item.id" v-model="item.business_type" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="Street No?" prop="strtno">
                  <el-input :id="'strtno' + item.id" v-model="item.strtno" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="No. Suffix?" prop="suffix">
                  <el-input :id="'suffix' + item.id" v-model="item.suffix" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="Street Type?" prop="strt_type">
                  <el-input :id="'strt_type' + item.id" v-model="item.strt_type" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="If other, specify" prop="other">
                  <el-input :id="'other' + item.id" v-model="item.other" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="Country" prop="country">
                  <div class="el-select native-select">
                    <div class="el-input">
                      <select :id="'country' + item.id" v-model="item.country" placeholder="Select" class="el-input__inner" @change="getProvince(item)">
                        <option v-for="(itemC, index) in country" v-bind:key="index" :value="itemC.id" :label="itemC.country_name">{{ itemC.country_name }}</option>
                      </select>
                    </div>
                  </div>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" v-show="selectProvine">
                <el-form-item label="Province" prop="province">
                  <div class="el-select native-select">
                    <div class="el-input">
                      <select :id="'province' + item.id" v-model="item.province" placeholder="Select" class="el-input__inner">
                        <option v-for="(itemP, index) in province" v-bind:key="index" :value="itemP.province_name" :label="itemP.province_name">{{ itemP.province_name }}</option>
                      </select>
                    </div>
                  </div>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="City" prop="city">
                  <el-input :id="'city' + item.id" v-model="item.city" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="Street Direction" prop="strt_direction">
                  <el-input :id="'strt_direction' + item.id" v-model="item.strt_direction" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="Unit/Suite/Apt" prop="unit">
                  <el-input :id="'unit' + item.id" v-model="item.unit" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <el-form-item label="Postal Code" prop="postcode">
                  <el-input :id="'postcode' + item.id" v-model="item.postcode" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12">
                <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm(i,item)">
                  {{ $t('global.save') }}
                </v-btn>
                <v-btn color="error" small @click.native="deleteOpen(item,i)">Delete</v-btn>
              </v-col>
            </v-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        proposed_business: null,
        corprate_name: null,
        est_date: null,
        corp_opertion: null,
        details: null,
        business_type: null,
        strtno: null,
        suffix: null,
        strt_type: null,
        other: null,
        strt_direction: null,
        unit: null,
        city: null,
        province: null,
        country: null,
        postcode: null
      },
      activeNames: -1,
      selectProvine: false,
      url: 'imms_table/prov_ont_corporate_stream',
      state: 'corporateStream',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      province: 'other/province'
    }),
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    collapse(item) {
      item.country = parseFloat(item.country)
      this.getProvince(item)
    },
    checkDate: function(event) {
      this.$emit('checkDate', event)
    },
    getProvince(item) {
      if (42 == item.country || 239 == item.country) {
        this.selectProvine = true
        this.$store.dispatch('other/getProvince', item.country)
      }
      else {
        this.selectProvine = false
      }
    },
    fetchData() {
      this.$Progress.start()
      var url = 'table/prov_ont_corporate_stream?filter=member_uid=' + this.memberUID
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) this.checkItemsFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.form = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        proposed_business: null,
        corprate_name: null,
        est_date: null,
        corp_opertion: null,
        details: null,
        business_type: null,
        strtno: null,
        suffix: null,
        strt_type: null,
        other: null,
        strt_direction: null,
        unit: null,
        city: null,
        province: null,
        country: null,
        postcode: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.form)
      var len = this.items.length
      this.activeNames = len
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true
          if (formObj.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.item.resetFields()
      this.nodeClear()
    }
  }
}
</script>
