
<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <v-row dense class="medium-space">
        <v-col cols="12" sm="9" class="personal" v-if="dataValidation(form, 'height')">
          <el-form-item class="first" :label="$t('imm_0008.height')" prop="height">
            <el-input id="height" @change="saveIMM(form);checkHeigth('sm')" type="number" v-model="form.height" style="width: 100%;" />
          </el-form-item>
          <strong>OR</strong>
          <el-form-item class="second" :label="$t('imm_0008.height_ft')" prop="height_ft">
            <el-input id="height_ft" @change="saveIMM(form);checkHeigth('ft')" type="number" v-model="form.height_ft" style="width: 100%;" />
          </el-form-item>
          <el-form-item class="third" :label="$t('imm_0008.height_in')" prop="height_in">
            <el-input id="height_in" @change="saveIMM(form);checkHeigth('in')" type="number" v-model="form.height_in" style="width: 100%;" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="3" v-if="dataValidation(form, 'eye_color')">
          <el-form-item :label="$t('imm_0008.eye_color')" prop="eye_color">
            <el-select id="eye_color" @change="saveIMM(form)" v-model="form.eye_color" placeholder="Select" style="width: 100%;">
              <el-option v-for="(item, ind) in colors" :key="ind" :value="item.name" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      colors: 'other/eyeColors'
    })
  },
  mounted() {
    if (this.isDataValidation) {
      var item = {
        height: null,
        eye_color: null
      }

      this.checkItem(this.form, item)
    }
  },
  methods: {
    checkHeigth(check) {
      var sm = 0
      var hIn = 0
      var ft = 0
      if (check === 'sm' && this.form.height > 0) {
        sm = this.form.height
        hIn = (this.form.height * 0.393701).toFixed(1)
        ft = (this.form.height * 0.0328084).toFixed(1)
      }
      if (check === 'in' && this.form.height_in > 0) {
        hIn = this.form.height_in
        sm = (this.form.height_in * 2.54).toFixed(1)
        ft = (this.form.height_in * 0.0833333).toFixed(1)
      }
      if (check === 'ft' && this.form.height_ft > 0) {
        ft = this.form.height_ft
        hIn = (this.form.height_ft * 12).toFixed(1)
        sm = (this.form.height_ft * 30.48).toFixed(1)
      }

      this.form.height = sm
      this.form.height_in = hIn
      this.form.height_ft = ft
    }
  }
}
</script>

<style scoped>
  .personal {
    position: relative
  }
  .personal .first{
    width: 36%;
    float: left;
  }
  .personal .second, .personal .third{
    width: 25%;
    float: left;
  }
  .personal strong{
    width: 6%;
    float: left;
    font-size: 24px;
    margin-right: 3%;
    margin-left: 3%;
    margin-top: 35px;
    text-align: center;
  }
</style>
