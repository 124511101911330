<template>
  <div class="mt-3" v-if="!isDataValidation">
    <v-card>
      <v-card-title>
        <span>{{sectionLabel}}</span>
      </v-card-title>
      <v-card-text class="p-n">
        <v-row dense class="mb-3">
          <v-col cols="12" class="mb-3">
            <div class="text13 mb-3">If you are 18 to 54 years of age you <strong>must</strong> submit proof that demonstrates that you have knowledge of English or French.</div>
            <div class="text13 pull-left">a) Do you have proof of knowledge of English or French?</div>
            <el-radio-group class="ml-3 mt-1 pull-left" @change="checkCitizenship()" v-model="form.knowledge">
              <el-radio label="No">No</el-radio>
              <el-radio label="Yes">Yes</el-radio>
            </el-radio-group>
          </v-col>
          <v-col cols="12" class="mb-3 ml-3">
            <div class="text13 pull-left">Did you successfully complete the LINC or CLIC courses at CLB 4/NCLC 4 or higher from January 1st, 2008 to October 31st,
            2012?</div>
            <el-radio-group class="ml-3 mt-1 pull-left" @change="checkCitizenship()" v-model="form.successfully">
              <el-radio label="No">No</el-radio>
              <el-radio label="Yes">Yes</el-radio>
            </el-radio-group>
          </v-col>
          <v-col cols="12" class="mb-3 ml-3" v-if="form.successfully !== 'No'">
            <div class="text13 pull-left">If yes and you do not have a copy of your certificate, pleas check the following box and your participation will be verified
            in the IRCC system.</div>
            <el-checkbox class="ml-3 pull-left" @change="checkCitizenship()" v-model="form.copy_certificate"></el-checkbox>
          </v-col>
        </v-row>
        <v-row dense class="mb-3">
          <v-col cols="12" class="mb-3">
            <div class="text13 pull-left">b) I am hearing impaired, which prevents me from submitting the proof of language described in 15A with my application.</div>
          </v-col>
          <v-col cols="2">
            <el-radio-group class="ml-3 mt-1 pull-left" @change="checkCitizenship()" v-model="form.hearing_impaired">
              <el-radio label="No">No</el-radio>
              <el-radio label="Yes">Yes</el-radio>
            </el-radio-group>
          </v-col>
          <v-col cols="10" v-if="form.hearing_impaired == 'Yes'">
            <div class="text13">If you check yes, you must submit supporting evidence with your application.</div>            
          </v-col>
        </v-row>
        <v-row dense class="mb-3">
          <v-col cols="12" class="mb-3">
            <div class="text13 pull-left">c) I have a disorder, disability or condition that is cognitive, psychiatric or psychological in nature which prevents me from
            submitting proof of language with my application.</div>
          </v-col>
          <v-col cols="2">
            <el-radio-group class="ml-3 mt-1 pull-left" @change="checkCitizenship()" v-model="form.have_disorder">
              <el-radio label="No">No</el-radio>
              <el-radio label="Yes">Yes</el-radio>
            </el-radio-group>
          </v-col>
          <v-col cols="10" v-if="form.have_disorder == 'Yes'">
            <div class="text13">If you check yes, you must submit supporting evidence with your application.</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      form: 'citizenship/get'
    })
  },
  methods: {
    checkCitizenship() {
      this.$store.dispatch('citizenship/edit', this.form)
        .then(response => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Disabled', type: 'success' })
        })
    }
  }
}
</script>