<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
        <v-btn v-if="layout === 'collapse'" color="primary" dark small @click.native="saveForm()">Add</v-btn>
      </v-card-title>
      <v-card-text class="p-n">
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{ sectionInfoBox }}
        </v-alert>
        <template v-if="layout === 'collapse'">
          <el-collapse v-model="activeNames" accordion>
            <el-collapse-item :name="i+1" v-for="(item,i) in items" :key="i">
              <template slot="title">
                <span class="el-col-title">{{item.name_supplier}}</span>
              </template>
              <el-form :model="item" ref="form" label-position="top">
                <v-row dense>
                  <v-col cols="12">
                    <el-form-item label="Name of Owner" prop="name_supplier">
                      <el-input :id="'name_supplier' + item.id" v-model="item.name_supplier" style="width: 100%" />
                    </el-form-item>
                    <el-form-item label="Supplier Contact Person" prop="supplier_contact">
                      <el-input :id="'supplier_contact' + item.id" v-model="item.supplier_contact" style="width: 100%" />
                    </el-form-item>
                    <el-form-item label="Phone Number for Contact at Supplier" prop="number_contact">
                      <el-input :id="'number_contact' + item.id" type="number" v-model="item.number_contact" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12">
                    <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm(i,item)">
                      {{ $t('global.save') }}
                    </v-btn>
                    <v-btn color="error" small @click.native="deleteOpen(item,i)">Delete</v-btn>
                  </v-col>
                </v-row>
              </el-form>
            </el-collapse-item>
          </el-collapse>
        </template>
        <template v-if="layout === 'table'">
          <table class="table" style="width:100%">
            <thead>
              <tr>
                <th>Name of Owner</th>
                <th>Supplier Contact Person</th>
                <th>Phone Number for Contact at Supplier</th>
                <th width="30">
                  <v-btn color="green" small fab @click.native="saveForm()">
                    <v-icon class="white--text">add</v-icon>
                  </v-btn>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in items" :key="i" :id="'row' + item.id">
                <td class="pa-1">
                  <el-input :id="'name_supplier' + item.id" v-model="item.name_supplier" style="width: 100%" />
                </td>
                <td class="pa-1">
                  <el-input :id="'supplier_contact' + item.id" v-model="item.supplier_contact" style="width: 100%" />
                </td>
                <td class="pa-1">
                  <el-input :id="'number_contact' + item.id" type="number" v-model="item.number_contact" style="width: 100%" />
                </td>
                <td class="pa-1 text-center">
                  <v-btn color="pink" small fab @click.native="deleteOpen(item, i)"><v-icon class="white--text">clear</v-icon></v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </v-card-text>
      <v-card-actions v-if="layout === 'table'" class="pa-3">
        <v-spacer />
        <v-btn :loading="loading" :disabled="loading" color="primary" dark small @click.native="allSaveForm()">
          {{ $t('global.save') }}

        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    layout: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        name_supplier: '',
        supplier_contact: '',
        number_contact: undefined
      },
      activeNames: -1,
      url: 'imms_table/application_supplier',
      state: 'supplier',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/application_supplier?filter=member_uid=${this.memberUID}`
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) this.checkTableFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.form = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        name_supplier: '',
        supplier_contact: '',
        number_contact: undefined
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.form)
      var len = this.items.length
      this.activeNames = len
    },
    allSaveForm() {
      if (this.items.length > 0) {
        this.loading = true
        this.items.forEach(function(form) {
          var action = 'all_stores/save'
          if (form.id != undefined) {
            action = 'all_stores/edit'
            form.id = parseFloat(form.id)
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': form, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                form.id = response.data.resource[0].id
                form.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }, this)
      }
      else {
        return false
      }
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true

          if (formObj.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>
