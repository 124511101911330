<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
    </v-card-title>
    <v-card-text>
      <v-alert type="info" border="left" dense class="mb-2" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <v-row dense class="mt-3" v-if="!isDataValidation">
        <v-col cols="12" sm="6" md="7">
          <label class="el-form-item__label">Have you applied to Citizenship and Immigration Canada before?</label>
        </v-col>
        <v-col cols="12" sm="6" md="5">
          <el-radio-group class="ml-3 mt-2" v-model="form.immigration_canada_check" @change="checkApplicable(form)">
            <el-radio value="Yes" label="Yes">Yes</el-radio>
            <el-radio value="No" label="No">No</el-radio>
          </el-radio-group>
        </v-col>
      </v-row>
      <v-row dense v-if="dataValidation(form, 'immigration_canada_text')">
        <v-col cols="12" v-if="isDataValidation">
          <label class="el-form-item__label">Have you applied to Citizenship and Immigration Canada before?</label>
        </v-col>
        <v-col cols="12" v-if="form.immigration_canada">
          <el-input class="mt-3" v-model="form.immigration_canada_text" @change="checkApplicable(form)" style="width: 100%;" />
        </v-col>
      </v-row>
      <v-row dense class="mt-3" v-if="!isDataValidation">
        <v-col cols="12" sm="6" md="7">
          <label class="el-form-item__label">Have you applied for Permanent Residence before?</label>
        </v-col>
        <v-col cols="12" sm="6" md="5">
          <el-radio-group class="ml-3 mt-2" v-model="form.permanent_residence_check" @change="checkApplicable(form)">
            <el-radio value="Yes" label="Yes">Yes</el-radio>
            <el-radio value="No" label="No">No</el-radio>
          </el-radio-group>
        </v-col>
      </v-row>
      <v-row dense v-if="dataValidation(form, 'permanent_residence_text')">
        <v-col cols="12" v-if="isDataValidation">
          <label class="el-form-item__label">Have you applied for Permanent Residence before?</label>
        </v-col>
        <v-col cols="12" v-if="form.permanent_residence">
          <el-input class="mt-3" v-model="form.permanent_residence_text" @change="checkApplicable(form)" style="width: 100%;" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    sectionSlug: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        id: undefined,
        immigration_canada: false,
        immigration_canada_text: null,
        permanent_residence: false,
        permanent_residence_text: null,
        sec_org_id: parseInt(localStorage.getItem('org_id'))
      },
      url: 'imms_table/application_family_info_summary',
      getUrl: `table/application_family_info_summary?filter=member_uid=${this.memberUID}`
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$store.dispatch('store/get', `${this.getUrl}`)
        .then(response => {
          if (response.resource.length > 0) {
            this.form = response.resource[0]
            this.form.immigration_canada_check = this.form.immigration_canada ? 'Yes' : 'No'
            this.form.permanent_residence_check = this.form.permanent_residence ? 'Yes' : 'No'

            if (this.isDataValidation) {
              this.checkRow(response.resource[0])
            }
          }
          else {
            if (this.isDataValidation) {
              this.checkRow(this.form)
            }
          }
        })
    },
    checkRow(data) {
      var form = {
        immigration_canada_text: null,
        permanent_residence_text: null
      }
      if (!data.immigration_canada) {
        delete form.immigration_canada_text
      }
      if (!data.permanent_residence) {
        delete form.permanent_residence_text
      }

      this.checkItem(data, form)
    },
    checkApplicable(form) {
      if (form.immigration_canada_check == 'Yes') {
        form.immigration_canada = true
      }
      else if (form.immigration_canada_check == 'No') {
        form.immigration_canada = false
      }

      if (form.permanent_residence_check == 'Yes') {
        form.permanent_residence = true
      }
      else if (form.permanent_residence_check == 'No') {
        form.permanent_residence = false
      }

      this.autoSaveSubmit(form)
    }
  }
}
</script>

<style>
  .el-form-item__label{
    line-height: 20px;
    text-align: left;
  }
</style>
