import api from "./../plugins/axios.node";

export const state = {
  consultants: [],
  consultant: {},
  experiences: []
};

export const getters = {
  all(state) {
    return state.consultants;
  },
  experiences(state) {
    return state.experiences;
  },
  getOne(state) {
    return state.consultant;
  }
};
export const actions = {
  getAll({ commit }, id) {
    return api.get("/consultants/index" + id).then(
      (response) => {
        var datas = response.data.results;
        commit("GET_DATAS", datas);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  getOne({ commit }, id) {
    return api.get("/consultants/finder/?uid=" + id).then(
      (response) => {
        var datas = response.data.results;
        commit("GET_DATA", datas);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATA", err);
        return Promise.reject(err);
      }
    );
  },
  getExperiences({ commit }, id) {
    return api.get("/consultant_application_types/experience?uid=" + id).then(
      (response) => {
        var datas = response.data.results;
        commit("GET_EXPERIENCES", datas);
        return Promise.resolve(datas);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  save({ commit }, data) {
    return api.post(data.url, { resource: data }).then(
      (response) => {
        commit("ADD_DATA", response.data);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  }
};

export const mutations = {
  GET_DATAS(state, datas) {
    datas.map(function(row) {
      row.date = null;
      row.menu = null;
      row.show = false;
    });
    state.consultants = datas;
  },
  GET_EXPERIENCES(state, datas) {
    state.experiences = datas;
  },
  GET_DATA(state, data) {
    state.consultant = data;
  },
  FAILURE_DATAS(state) {
    state.consultants = [];
  },
  FAILURE_DATA(state, data) {
    state.consultant = {};
  }
};
