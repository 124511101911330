<template>
  <v-card v-if="hideSection" class="mt-3">
    <v-card-title>
      <span>{{sectionLabel}}</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()">{{ $t('global.add') }}</v-btn>
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <v-row dense class="customCollapseHeader">
        <v-col cols="6" sm="4">Last name</v-col>
        <v-col cols="6" sm="4">First name</v-col>
        <v-col class="d-none d-sm-flex" sm="4">Why was this name used</v-col>
      </v-row>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i" :id="'row' + form.id">
          <template slot="title">
            <div class="customHead">
              <v-row dense>
                <v-col class="d-none d-sm-flex" sm="4"><div class="pl-2 pr-2">{{ form.lname }}</div></v-col>
                <v-col class="d-none d-sm-flex" sm="4"><div class="pl-2 pr-2">{{ form.fname }}</div></v-col>
                <v-col class="d-none d-sm-flex" sm="4"><div class="pl-3 pr-2">{{ form.name_used }}</div></v-col>
              </v-row>
            </div>
          </template>
          <el-form :model="form" ref="form" label-position="top">
            <v-row dense>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'lname')">
                <el-form-item label="Last name" prop="lname">
                  <el-input v-model="form.lname" style="width: 100%" @change="handleAutoSave(i, form)" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'fname')">
                <el-form-item label="First name" prop="fname" class="current">
                  <el-input v-model="form.fname" style="width: 100%;" @change="handleAutoSave(i, form)" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'name_used')">
                <el-form-item label="Why was this name used" prop="name_used" class="current">
                  <el-select v-model="form.name_used" style="width: 100%" @change="handleAutoSave(i, form)">
                    <el-option value="Details">Details</el-option>
                    <el-option value="Name at birth">Name at birth</el-option>
                    <el-option value="Maiden name">Maiden name</el-option>
                    <el-option value="Married name">Married name</el-option>
                    <el-option value="Previously married name">Previously married name</el-option>
                    <el-option value="Alias">Alias</el-option>
                    <el-option value="Nick name">Nick name</el-option>
                  </el-select>
                </el-form-item>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(i,form)">{{ $t('global.save') }}</v-btn>
                <v-btn :loading="autoSave" :disabled="autoSave" color="error" small @click.native="deleteOpen(form,i)">{{ $t('global.delete') }}</v-btn>
              </v-col>
            </v-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  name: 'ListAllNames',
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        lname: null,
        fname: null,
        name_used: null
      },
      url: 'imms_table/application_list_names',
      activeNames: -1,
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_list_names?filter=member_uid=${this.memberUID}`)
        .then(response => {
          let vm = this
          if (vm.isDataValidation) {
            response.resource.forEach(function(item) {
              var form = {
                lname: null,
                fname: null,
                name_used: null
              }

              var obj = vm.checkFormItems(item, form)

              if (obj != 'empty') {
                vm.items.push(item)
              }
            })
            if (response.resource.length == 0) {
              vm.hideSection = true
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
          else {
            vm.items = response.resource
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        lname: null,
        fname: null,
        name_used: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>
