<template>
  <v-card v-if="hideSection" class="mt-3">
    <v-card-title>
      <span>Section 3 - Calculating physical presence as a temporary resident or protected person, before becoming a permanent resident within the last 5 year eligibility period</span>
      <v-spacer />
    </v-card-title>
    <v-card-text class="p-n">
      <v-row dense v-if="!isDataValidation">
        <v-col cols="12" class="mb-3">
          <div class="body-2">
            "During your ELIGIBILITY PERIOD Date in Box B: <b>{{ formCalculate.sign_before_date }}</b> to Date in Box A <b>{{ formCalculate.sign_date }}</b>: did you have a Temporary Resident (TR) or Protected Person (PP) status in Canada before becoming a permanent resident?
          </div>
          <el-radio-group class="ml-3 mt-1 pull-left" v-model="formCalculate.eligibility_period" @change="checkCitizenship(formCalculate);">
            <el-radio label="No">No</el-radio>
            <el-radio label="Yes">Yes</el-radio>
          </el-radio-group>
        </v-col>
        <v-alert v-if="formCalculate.eligibility_period === 'No'" type="info" border="left" dense class="mb-3">
          If you answered NO, and you do not have at least 1095 days of physical presence as a permanent resident in Box F, you do not meet the requirements and must wait until you meet the physical presence requirement to apply for citizenship.
        </v-alert>
        <v-alert v-if="formCalculate.eligibility_period === 'Yes'" type="info" border="left" dense class="mb-3">
          "You may be able to use some of your time spent in Canada as a temporary resident or protected person towards your physical presence calculation.
          <br>
          Each day spent physically in Canada as a temporary resident or protected person within the last five (5) years, before becoming a permanent resident will count as one half day, with a maximum of 365 days towards your physical presence.
          <br>
          Temporary resident status includes lawful authorization to enter or remain in Canada as a: visitor, student, worker or temporary resident permit holder.
          <br>
          A protected person is someone who: was found to be in need of protection or a convention refugee by the Immigration Refugee Board or, a person who received a positive decision on a Pre-Removal Risk Assessment from IRCC."
        </v-alert>
      </v-row>
      <v-alert type="info" border="left" dense class="mb-3">
        "Only enter the temporary resident or protected person status that you held in the last 5 years during your eligibility period before becoming a permanent resident.
        <br>
        Note: If you are unsure if you held authorized temporary resident or protected person status for any period before becoming a permanent resident, do NOT include that period in your physical presence calculation. This will prevent you from applying too early and will avoid the rejection of your application."
      </v-alert>
      <table class="table" style="width:100%">
        <thead>
          <tr>
            <th>Status</th>
            <th>Date your status was <br> obtained/effective</th>
            <th>Date your status expired</th>
            <th>Total number of days as <br> a temporary  resident or protected <br> person before becoming <br> a permanent resident</th>
            <th width="30">
              <v-btn color="green" small fab @click.native="saveForm()">
                <v-icon class="white--text">add</v-icon>
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(form,i) in items" :key="i" :id="'row' + form.id">
            <td class="pa-1">
              <el-select v-if="dataValidationItems(form, 'status')" v-model="form.status" style="width: 100%" @change="handleAutoSave(i, form)">
                <el-option label="Visitor" value="Visitor"></el-option>
                <el-option label="Student" value="Student"></el-option>
                <el-option label="Temporary resident permit holder" value="Temporary resident permit holder"></el-option>
                <el-option label="Protected person" value="Protected person"></el-option>
              </el-select>
            </td>
            <td class="pa-1">
              <el-date-picker v-if="dataValidationItems(form, 'obtained_date')" value-format="yyyy-MM-dd" v-model="form.obtained_date" @change="handleAutoSave(i, form); getLength(form, i)" style="width: 100%;"></el-date-picker>
            </td>
            <td class="pa-1">
              <el-date-picker v-if="dataValidationItems(form, 'expired_date')" value-format="yyyy-MM-dd" v-model="form.expired_date" @change="handleAutoSave(i, form); getLength(form, i)" style="width: 100%;"></el-date-picker>
            </td>
            <td class="pa-1 text-right">
              <el-input v-if="dataValidationItems(form, 'days')" v-model="form.days" disabled style="width: 100%" @change="handleAutoSave(i, form)" />
            </td>
            <td class="pa-1 text-center">
              <v-btn color="pink" small fab @click.native="deleteOpen(form, i)">
                <v-icon class="white--text">clear</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-if="!isDataValidation">
          <tr>
            <td colspan="3" class="text-right">Total # of TR or PP days</td>
            <td class="pa-1 text-right"><b class="pl-2 pr-2">{{ totalDays }}</b></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import { checkLength } from '@/utils/helpers'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  name: 'TemporaryResidenceCalculator',
  props: {
    sectionId: {
      type: String
    },
    formCalculate: {
      type: Object
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        status: null,
        obtained_date: null,
        expired_date: null,
        days: null,
        absent: null
      },
      url: 'imms_table/application_temporary_residence_status',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    },
    totalDays: function(val) {
      this.formCalculate.total_eligibility_period = val
    }
  },
  computed: {
    totalDays: function() {
      var count = 0
      this.items.forEach(function(item) {
        if (item.days === null || item.days === undefined) item.days = 0
        count += parseFloat(item.days)
      })
      return count
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    getLength(form, index) {
      if (form.obtained_date && form.expired_date) {
        let length = checkLength(this.formCalculate.sign_before_date, this.formCalculate.pr_date, form.obtained_date, form.expired_date, 1)

        let total = length.total
        let absent = length.absent

        if (absent > 0) {
          form.absent = absent
        }

        if (total > 0) {
          form.days = total
          this.check = false
        }
        else {
          this.check = true
          form.expired_date = null
        }
      }
    },
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_temporary_residence_status?filter=member_uid=${this.memberUID}&order=obtained_date ASC,expired_date ASC`)
        .then(response => {
          let vm = this
          if (vm.isDataValidation) {
            response.resource.forEach(function(item) {
              var form = {
                status: null,
                obtained_date: null,
                expired_date: null
              }

              var obj = vm.checkFormItems(item, form)

              if (obj != 'empty') {
                vm.items.push(item)
              }
            })

            if (response.resource.length == 0) {
              vm.hideSection = false
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
          else {
            vm.items = response.resource
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        status: null,
        obtained_date: null,
        expired_date: null,
        days: null,
        absent: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    },
    checkCitizenship(item) {
      if (item.id != undefined) {
        this.$store.dispatch('store/edit', { url: 'imms_table/application_citizenship_calculator', data: item })
          .then(response => {
            this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
          })
          .catch(err => {
            this.$store.dispatch('toaster/add', { text: 'Disabled', type: 'success' })
          })
      }
    }
  }
}
</script>
