<template>
  <div class="mt-3">
    <financial-evaluation
      :sectionId="sectionId"
      :sectionSlug="sectionSlug"
      :sectionLabel="sectionLabel"
      :sectionInfoBox="sectionInfoBox"
      :memberID="memberID"
      :memberUID="memberUID"
      :datas="datas"
      :layout="layout"
      >
    </financial-evaluation>

    <v-card class="financial mt-3">
      <v-card-text class="p-n">
        <el-form :model="form" ref="form" label-position="top" class="form">
          <v-row dense>
            <v-col cols="12">
              <div><strong>11. Complete the sections (A, B and/or C) which apply to your situation during the 12 months preceding the date of your application. If your situation changed during that period, in the Period I column, enter in formation on the most recent period and, in the next columns, enter information on the previous period(s), which make(s) up the rest of the 12 months preceding your application. Refer to your guide for additional information. If you need additional space, provide details on a separate sheet.</strong></div>
            </v-col>
            <table class="tbpad">
              <tr class="text-uppercase">
                <td rowspan="3" class="text-center text-uppercase">
                  <div class="body-2">A</div>
                  <div class="body-2">Unemployed</div>
                </td>
                <td></td>
                <td colspan="2" class="text-center body-2">PERIOD I</td>
                <td colspan="2" class="text-center body-2">PERIOD II</td>
                <td colspan="2" class="text-center body-2">PERIOD III</td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <label>From Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period1.from_date"></el-date-picker>
                </td>
                <td>
                  <label>To Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period1.to_date"></el-date-picker>
                </td>
                <td>
                  <label>From Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period2.from_date"></el-date-picker>
                </td>
                <td>
                  <label>To Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period2.to_date"></el-date-picker>
                </td>
                <td>
                  <label>From Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period3.from_date"></el-date-picker>
                </td>
                <td>
                  <label>To Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period3.to_date"></el-date-picker>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Income</label>
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period1.income" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period2.income" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period3.income" />
                </td>
              </tr>
              <tr>
                <td rowspan="10" class="text-center text-uppercase">
                  <div class="body-2">B</div>
                  <div class="body-2">Employed</div>
                </td>
                <td></td>
                <td>
                  <label>From Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period1.from_date_second"></el-date-picker>
                </td>
                <td>
                  <label>To Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period1.to_date_second"></el-date-picker>
                </td>
                <td>
                  <label>From Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period2.from_date_second"></el-date-picker>
                </td>
                <td>
                  <label>To Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period2.to_date_second"></el-date-picker>
                </td>
                <td>
                  <label>From Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period3.from_date_second"></el-date-picker>
                </td>
                <td>
                  <label>To Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period3.to_date_second"></el-date-picker>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Name of employer</label>
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period1.name" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period2.name" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period3.name" />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Address</label>
                </td>
                <td colspan="2">
                  <el-input type="textarea" :row="3" v-model="form.node.period1.address" />
                </td>
                <td colspan="2">
                  <el-input type="textarea" :row="3" v-model="form.node.period2.address" />
                </td>
                <td colspan="2">
                  <el-input type="textarea" :row="3" v-model="form.node.period3.address" />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Telephone number</label>
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period1.telephone_number" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period2.telephone_number" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period3.telephone_number" />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Name of supervisor</label>
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period1.name_supervisor" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period2.name_supervisor" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period3.name_supervisor" />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Occupation/position</label>
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period1.position" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period2.position" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period3.position" />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Hours worked per week</label>
                </td>
                <td>
                  <el-input size="mini" v-model="form.node.period1.hours_worked" />
                </td>
                <td></td>
                <td>
                  <el-input size="mini" v-model="form.node.period2.hours_worked" />
                </td>
                <td></td>
                <td>
                  <el-input size="mini" v-model="form.node.period3.hours_worked" />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <label>Rate</label>
                  <el-checkbox v-model="form.node.per_week">per week</el-checkbox>
                </td>
                <td>
                  <el-input size="mini" v-model="form.node.period1.rate_per_week" />
                </td>
                <td></td>
                <td>
                  <el-input size="mini" v-model="form.node.period2.rate_per_week" />
                </td>
                <td></td>
                <td>
                  <el-input size="mini" v-model="form.node.period3.rate_per_week" />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <el-checkbox class="ml-4" v-model="form.node.per_hour">per hour</el-checkbox>
                </td>
                <td>
                  <el-input size="mini" v-model="form.node.period1.rate_per_hour" />
                </td>
                <td></td>
                <td>
                  <el-input size="mini" v-model="form.node.period2.rate_per_hour" />
                </td>
                <td></td>
                <td>
                  <el-input size="mini" v-model="form.node.period3.rate_per_hour" />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <label>Income</label>
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period1.income_second" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period2.income_second" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period3.income_second" />
                </td>
              </tr>
              <tr>
                <td rowspan="5" class="text-center text-uppercase">
                  <div class="body-2">C</div>
                  <div class="body-2">Self-Employed</div>
                </td>
                <td></td>
                <td>
                  <label>From Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period1.from_date_third"></el-date-picker>
                </td>
                <td>
                  <label>To Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period1.to_date_third"></el-date-picker>
                </td>
                <td>
                  <label>From Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period2.from_date_third"></el-date-picker>
                </td>
                <td>
                  <label>To Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period2.to_date_third"></el-date-picker>
                </td>
                <td>
                  <label>From Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period3.from_date_third"></el-date-picker>
                </td>
                <td>
                  <label>To Date</label>
                  <el-date-picker size="mini" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.node.period3.to_date_third"></el-date-picker>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Name of business</label>
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period1.name_business" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period2.name_business" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period3.name_business" />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Occupation/position</label>
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period1.occupation" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period2.occupation" />
                </td>
                <td colspan="2">
                  <el-input size="mini" v-model="form.node.period3.occupation" />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Share in business</label>
                </td>
                <td>
                  <el-input size="mini" v-model="form.node.period1.share_business" />
                </td>
                <td></td>
                <td>
                  <el-input size="mini" v-model="form.node.period2.share_business" />
                </td>
                <td></td>
                <td>
                  <el-input size="mini" v-model="form.node.period3.share_business" />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <label>Net income</label>
                </td>
                <td></td>
                <td>
                  <el-input size="mini" v-model="form.node.period1.net_income" />
                </td>
                <td></td>
                <td>
                  <el-input size="mini" v-model="form.node.period2.net_income" />
                </td>
                <td></td>
                <td>
                  <el-input size="mini" v-model="form.node.period3.net_income" />
                </td>
              </tr>
            </table>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="12">
              <div class="mt-3">
                <strong>12. Calculation of income based on the Canada Revenue Agency (CRA) Notice of Assessment</strong>
              </div>
            </v-col>
            <v-col cols="12">
              <table class="tbpad">
                <tr>
                  <td class="title"><div>12A</div></td>
                  <td><label>(see the Document Checklist for details on how to obtain your Notice of Assessment)</label></td>
                  <td width="20"></td>
                  <td><el-input size="mini" type="number" v-model="form.node.a_12" /></td>
                  <td width="100">1</td>
                </tr>
                <tr>
                  <td rowspan="7" class="title"><div>12B</div></td>
                  <td colspan="4"><label>Benefits and/or allowances included in line 120 on your Notice of Assessment:</label></td>
                </tr>
                <tr>
                  <td><label>Amount of provincial allowance you received for instruction or training</label></td>
                  <td width="20">+</td>
                  <td><el-input size="mini" type="number" v-model="form.node.b_12_provincial" /></td>
                  <td width="100"> 2</td>
                </tr>
                <tr>
                  <td><label>Amount of social assistance paid to you by a province</label></td>
                  <td width="20">+</td>
                  <td><el-input size="mini" type="number" v-model="form.node.b_12_social" /></td>
                  <td width="100">3</td>
                </tr>
                <tr>
                  <td><label>Amounts other than special benefits paid to you under the Employment Insurance Act</label></td>
                  <td width="20">+</td>
                  <td><el-input size="mini" type="number" v-model="form.node.b_12_special" /></td>
                  <td width="100">4</td>
                </tr>
                <tr>
                  <td><label>Amount of guaranteed income supplement paid to you under the Old age Security Act</label></td>
                  <td width="20">+</td>
                  <td><el-input size="mini" type="number" v-model="form.node.b_12_income" /></td>
                  <td width="100">5</td>
                </tr>
                <tr>
                  <td>
                    <label>Total amount of allowances and/or benefits included in line 120 on your Notice of Assessment:</label>
                  </td>
                  <td width="20">=</td>
                  <td><el-input size="mini" type="number" v-model="form.node.b_12_social" /></td>
                  <td width="100"><v-icon small>arrow_right</v-icon></td>
                </tr>
                <tr>
                  <td><label>add lines 2 to 5</label></td>
                  <td width="20">-</td>
                  <td><el-input size="mini" type="number" v-model="form.node.b_12" /></td>
                  <td width="100">6</td>
                </tr>
                <tr>
                  <td class="title"><div>12C</div></td>
                  <td><label class="el-item--label">Available income based on the Canada Revenue Agency (CRA) Notice of Assessment</label></td>
                  <td width="20">=</td>
                  <td><el-input size="mini" type="number" v-model="form.node.c_12" /></td>
                  <td width="100">7</td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="12">
              <div class="mt-3">
                <strong>13. Calculation of income based on preceding 12 months</strong>
              </div>
            </v-col>
            <v-col cols="12">
              <table class="tbpad">
                <tr>
                  <td class="title" rowspan="8"><div>13A</div></td>
                  <td colspan="4">
                    <strong>PERSONAL INCOME FROM EMPLOYMENT</strong>
                    <p>Income you earned from employment in the 12 months preceding your application and for which you have received or will receive a T-4</p>
                  </td>
                </tr>
                <tr>
                  <td><label>Add amounts entered on last line of columns I, II and III in Section 11B and enter total here</label></td>
                  <td width="20"></td>
                  <td><el-input size="mini" type="number" v-model="form.node.a13_1" /></td>
                  <td width="100">1</td>
                </tr>
                <tr>
                  <td><label>If necessary, provide on a separate sheet details of income earned from employment not included in line 1 and enter total here</label></td>
                  <td width="20">+</td>
                  <td><el-input size="mini" type="number" v-model="form.node.a13_2" /></td>
                  <td width="100"> 2</td>
                </tr>
                <tr>
                  <td colspan="4"><strong>PERSONAL INCOME FROM BUSINESS (PROFESSIONAL) AND OTHER SELF-EMPLOYMENT</strong></td>
                </tr>
                <tr>
                  <td><label>Add amounts entered on last line of columns I, II, and III in Section 11C and enter total here</label></td>
                  <td width="20">+</td>
                  <td><el-input size="mini" type="number" v-model="form.node.a13_3" /></td>
                  <td width="100">3</td>
                </tr>
                <tr>
                  <td><label>If necessary, provide on a separate sheet details of net income earned from self-employment not included in line 3 and enter total here</label></td>
                  <td width="20">+</td>
                  <td><el-input size="mini" type="number" v-model="form.node.a13_4" /></td>
                  <td width="100">4</td>
                </tr>
                <tr>
                  <td>
                    <label>Total personal income from employment, business and other self-employment: add lines 1 to 4</label>
                  </td>
                  <td width="20">=</td>
                  <td><el-input size="mini" type="number" v-model="form.node.a13_total" /></td>
                  <td width="100"></td>
                </tr>
                <tr>
                  <td colspan="2"><v-icon small>arrow_right</v-icon></td>
                  <td><el-input size="mini" type="number" v-model="form.node.a13_5" /></td>
                  <td width="100">5</td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="12">
              <table class="tbpad">
                <tr>
                  <td class="title" rowspan="7" width="30"><div>13B</div></td>
                  <td><strong>OTHER INCOME</strong></td>
                  <td width="20"></td>
                  <td width="150"></td>
                  <td width="100"></td>
                </tr>
                <tr>
                  <td><label>Net rental income</label></td>
                  <td width="20"></td>
                  <td width="150"><el-input size="mini" type="number" v-model="form.node.b13_6" /></td>
                  <td width="100">6</td>
                </tr>
                <tr>
                  <td><label>Investment and interest income</label></td>
                  <td width="20">+</td>
                  <td width="150"><el-input size="mini" type="number" v-model="form.node.b13_7" /></td>
                  <td width="100">7</td>
                </tr>
                <tr>
                  <td><label>Pension income</label></td>
                  <td width="20">+</td>
                  <td width="150"><el-input size="mini" type="number" v-model="form.node.b13_8" /></td>
                  <td width="100">8</td>
                </tr>
                <tr>
                  <td><label>Maternity, parental or sickness benefits paid under the Employment Insurance Act</label></td>
                  <td width="20">+</td>
                  <td width="150"><el-input size="mini" type="number" v-model="form.node.b13_9" /></td>
                  <td width="100">9</td>
                </tr>
                <tr>
                  <td><label class="pull-left mr-2">Other income ► Specify</label><el-input v-model="form.node.b13_specify" class="pull-left" style="width: 200px" /></td>
                  <td width="20">=</td>
                  <td width="150"><el-input size="mini" type="number" v-model="form.node.b13_10" /></td>
                  <td width="100">10</td>
                </tr>
                <tr>
                  <td><label>Total other income: add lines 6 to 10</label></td>
                  <td width="20"><v-icon small>arrow_right</v-icon></td>
                  <td width="150"><el-input size="mini" type="number" v-model="form.node.b13_11" /></td>
                  <td width="100">11</td>
                </tr>
                <tr>
                  <td class="title"><div>13C</div>
                  </td><td>Available income based on preceding 12 months: add lines 5 and 11</td>
                  <td width="20">=</td>
                  <td width="150"><el-input size="mini" type="number" v-model="form.node.c13" /></td>
                  <td width="100">12</td>
                </tr>
                <tr>
                  <td class="title"><div>14</div>
                  </td><td>Enter the greater of the two amounts in 12C or 13C; this is your available income.</td>
                  <td width="20"></td>
                  <td width="150"><el-input size="mini" type="number" v-model="form.node.section14" /></td>
                  <td width="100"></td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="12">
              <div class="mt-3">
                <strong>15. Your spouce's/common-law partner's current situation</strong>
              </div>
            </v-col>
            <v-col cols="12">
              <table class="tbpad">
                <tr>
                  <td width="200"><el-checkbox v-model="form.node.unemployed">Unemployed</el-checkbox></td>
                  <td>
                    <label>Give details of your spouse's/common-law partner's current situation and indicate how he/she support him/herself. If you need additional space, provide details on a separate sheet of paper.</label>
                    <el-input v-model="form.node.unemployed_desc" :row="5" />
                  </td>
                </tr>
                <tr>
                  <td width="200"><el-checkbox v-model="form.node.employed">Employed</el-checkbox></td>
                  <td><label> Your spouse/common-law partner is working for an employer and is paid a salary, wages or a commission for his/her services.</label></td>
                </tr>
                <tr>
                  <td width="200"><el-checkbox v-model="form.node.self_employed">Self-Employed</el-checkbox></td>
                  <td><label>Your spouse/common-law partner has his/her own business, practice a trade or a profession and conducts activities for a profit.</label></td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="loading" color="primary" dark small @click.native="submitForm()">Save</v-btn>
      </v-card-actions>
    </v-card>

    <financial-evaluation-third
      :sectionId="sectionId"
      :sectionSlug="sectionSlug"
      :sectionLabel="sectionLabel"
      :sectionInfoBox="sectionInfoBox"
      :memberID="memberID"
      :memberUID="memberUID"
      :datas="datas"
      :layout="layout"
      >
    </financial-evaluation-third>
  </div>
</template>

<script>
import autosave from '@/mixins/autosave.js'
import FinancialEvaluation from './financial_evaluation'
import FinancialEvaluationThird from './financial_evaluation_third'

export default {
  mixins: [autosave],
  props: {
    sectionId: {
      type: String
    },
    layout: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      form: {
        member_uid: this.memberUID,
        member_id: this.memberID,
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        node: {
          unemployed: false,
          self_employed: false,
          employed: false,
          unemployed_desc: null,
          per_week: null,
          per_hour: null,
          a_12: null,
          b_12: null,
          b_12_provincial: null,
          b_12_social: null,
          b_12_special: null,
          b_12_income: null,
          b_12_total: null,
          c_12: null,
          a12_1: null,
          a13_2: null,
          a13_3: null,
          a13_4: null,
          a13_5: null,
          a13_total: null,
          b13_6: null,
          b13_7: null,
          b13_8: null,
          b13_9: null,
          b13_10: null,
          b13_11: null,
          c13: null,
          section14: null,
          period1: {
            name: null,
            position: null,
            income: null,
            from_date: null,
            from_date_second: null,
            to_date: null,
            to_date_second: null,
            name_supervisor: null,
            telephone_number: null,
            address: null,
            rate_per_hour: null,
            rate_per_week: null,
            income_second: null,
            to_date_third: null,
            from_date_third: null,
            net_income: null,
            share_business: null,
            occupation: null,
            name_business: null
          },
          period2: {
            name: null,
            position: null,
            income: null,
            from_date: null,
            from_date_second: null,
            to_date: null,
            to_date_second: null,
            name_supervisor: null,
            telephone_number: null,
            address: null,
            rate_per_hour: null,
            rate_per_week: null,
            income_second: null,
            to_date_third: null,
            from_date_third: null,
            net_income: null,
            share_business: null,
            occupation: null,
            name_business: null
          },
          period3: {
            name: null,
            position: null,
            income: null,
            from_date: null,
            from_date_second: null,
            to_date: null,
            to_date_second: null,
            name_supervisor: null,
            telephone_number: null,
            address: null,
            rate_per_hour: null,
            rate_per_week: null,
            income_second: null,
            to_date_third: null,
            from_date_third: null,
            net_income: null,
            share_business: null,
            occupation: null,
            name_business: null
          }
        },
        alias: 'financial_evaluation'
      },
      loading: false,
      url: 'imms_table/application_sponsorship_relationship'
    }
  },
  components: {
    FinancialEvaluation,
    FinancialEvaluationThird
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_sponsorship_relationship?filter=(member_uid=${this.memberUID})AND(alias='financial_evaluation')`)
        .then(response => {
          if (response.resource.length > 0) {
            this.form = response.resource[0]
            this.form.node = JSON.parse(response.resource[0].node)
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true

          if (this.form.id != undefined) this.edit = true
          var action = 'store/save'
          if (this.edit) {
            action = 'store/edit'
          }
          this.$Progress.start()
          this.form.node = JSON.stringify(this.form.node)
          this.$store.dispatch(action, { 'url': this.url, 'data': this.form })
            .then(response => {
              this.fetchData()
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    }
  }
}
</script>

<style>
  .tbpad {
    max-width: 100%;
    width: 100%;
  }
  table .el-date-editor.el-input, table .el-date-editor.el-input__inner{
    width: auto;
  }
  .tbpad td.title{
    vertical-align: top;
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .financial .el-form-item__label{
    font-size: 15px;
  }
</style>
