// import { app } from "@/main";
import i18n from "@/lang/lang";

export const state = {
  lang: "en"
};

export const getters = {
  getLang(state) {
    return state.lang;
  }
};

export const mutations = {
  SET_LANG(state, payload) {
    i18n.locale = payload;
    state.lang = payload;
  }
};

export const actions = {
  setLang({ commit }, payload) {
    commit("SET_LANG", payload);
  },
  async setLangNew({ commit }, payload) {
    if (payload in i18n.messages) {
      commit("SET_LANG", payload);
    }
    else {
      try {
        const res = require(`@/lang/${payload}.json`);
        i18n.setLocaleMessage(payload, res);
        commit("SET_LANG", payload);
      }
      catch (e) {}
    }
  }
};
