<template>
  <div>
    <v-card v-if="hideSection" class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="yukon-checkbox">
          <el-form-item prop="settle_airfare" v-if="dataValidation(form, 'settle_airfare')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.settle_airfare"></el-checkbox>
            <label class="ml-4">Airfare (mandatory): If this nominee is not alreadt residing in Yukon, pay for the air travel cost for the nominee to come to Yukon from his/her place of permanent residence (but not for travel costs of family or dependents, if the nominee is not in Canada at the time of the applical approval). </label>
          </el-form-item>
          <el-form-item prop="settle_health_insurance" v-if="dataValidation(form, 'settle_health_insurance')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.settle_health_insurance"></el-checkbox>
            <label class="ml-4">Health Insurance: The employer must provide free to the nominee health insurance that give similar coverage ro what a Yukon resident gets under the Health Care Insurance Plan Actuntil the nominee is eligible for insured health care under the Health Care Insurance Plan Act. This is mandatory.</label>
          </el-form-item>
          <el-form-item prop="settle_temp_accommodation" v-if="dataValidation(form, 'settle_temp_accommodation')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.settle_temp_accommodation"></el-checkbox>
            <label class="ml-4">Temporary Accomodation. If you are providing other assistance , please specify: We will source affordable accomodation for the employee only.</label>
          </el-form-item>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  mixins: [autosave],
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        settle_temp_accommodation: null,
        settle_health_insurance: null,
        settle_airfare: null
      },
      url: 'imms_table/lmia_yukon_immigrant_declaration',
      getUrl: 'imms_table/lmia_yukon_immigrant_declaration?application_imms_uuid=' + this.$route.params.id
    }
  },
  mounted() {
    this.fetch().then(data => {
      if (data.items != undefined && data.items.length > 0) {
        if (this.isDataValidation) {
          this.checkRow(data.items[0])
        }
        this.form = data.items[0]
      }
      else {
        if (this.isDataValidation) {
          this.checkRow(this.form)
        }
      }
    })
  }
}
</script>
