<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>US PR Card</span>
      <v-spacer />
      <div class="body-2 mr-3">Are you a lawful Permanent Resident of the United States with a valid alien registration card (green card)?</div>
      <div class="body-2 mb-2">
        <el-checkbox @change="saveIMM(form)" v-model="form.user_pr_card" class="ml-5 pl-5">Yes</el-checkbox>
      </div>
    </v-card-title>
    <v-card-text class="p-n" v-if="form.user_pr_card">
      <v-row dense class="medium-space">
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'upc_number')">
          <el-form-item label="Document number" prop="upc_number">
            <el-input @change="saveIMM(form)" v-model="form.upc_number" style="width: 100%;" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'upc_expiry_date')">
          <el-form-item label="Expiry Date" prop="upc_expiry_date">
            <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.upc_expiry_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  computed: mapGetters({
    country: 'other/country'
  }),
  mounted() {
    if (this.isDataValidation) {
      if (!this.form.user_pr_card) {
        this.hideSection = false
      }
      else {
        var item = {
          user_pr_card: null,
          upc_number: null,
          upc_expiry_date: null
        }

        this.checkItem(this.form, item)
      }
    }
  }
}
</script>