<template>
  <v-card class="mt-3" ref="languageTestDetails">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
      <v-btn v-if="layout === 'collapse'" :disabled="notApplicable === 'No' || notApplicable === '' ? true : false" class="mb-3" color="primary" dark small @click.native="saveForm()">{{ $t('global.add') }}</v-btn>
    </v-card-title>
    <v-card-text>
      <div class="mb-3 mt-1">
        Have you completed a valid language proficiency test within the past two years?
        <el-radio-group v-model="notApplicable" @change="checkApplicable()" class="ml-3">
          <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
          <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
        </el-radio-group>
      </div>
      <v-alert type="info" border="left" dense class="mb-2" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <template v-if="layout === 'collapse'">
        <el-collapse v-model="activeNames" accordion v-if="notApplicable === 'Yes'">
          <el-collapse-item :name="i+1" v-for="(item,i) in items" :key="i">
            <template slot="title">
              <el-tag type="primary">{{ item.date_test | day }}</el-tag>
              <span class="el-col-title">{{ item.type_taken }}</span>
            </template>
            <el-form :model="item" ref="form" label-position="top">
              <v-row dense>
                <v-col cols="12" sm="6">
                  <el-form-item label="Taken type" prop="type_taken">
                    <el-select :id="'type_taken' + item.id" v-model="item.type_taken" style="width: 100%;">
                      <el-option v-for="(itemT, index) in testTaken" :key="index" :label="itemT.name" :value="itemT.name"></el-option>
                    </el-select>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6">
                  <el-form-item label="Date of signing" prop="date_test">
                    <el-date-picker :id="'date_test' + item.id" value-format="yyyy-MM-dd" v-model="item.date_test" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="3">
                  <el-form-item :label="$t('application_official_languages.listening')" prop="listening">
                    <el-input :id="'listening' + item.id" type="number" v-model="item.listening" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <el-form-item :label="$t('application_official_languages.reading')" prop="reading">
                    <el-input :id="'reading' + item.id" type="number" v-model="item.reading" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <el-form-item :label="$t('application_official_languages.writing')" prop="writing">
                    <el-input :id="'writing' + item.id" type="number" v-model="item.writing" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <el-form-item :label="$t('application_official_languages.speaking')" prop="speaking">
                    <el-input :id="'speaking' + item.id" type="number" v-model="item.speaking" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12">
                  <el-form-item label="Registration number" prop="report_number">
                    <el-input :id="'report_number' + item.id" type="number" v-model="item.report_number" style="width: 100%" />
                  </el-form-item>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm(i, item)">
                      {{ $t('global.save') }}
                  </v-btn>
                  <v-btn color="error" small @click.native="deleteOpen(item,i)">{{ $t('global.delete') }}</v-btn>
                </v-col>
              </v-row>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </template>
      <template v-if="layout === 'table'">
        <table class="table" style="width:100%">
          <thead>
            <tr>
              <th width="100">Taken type</th>
              <th>Date of signing</th>
              <th>{{ $t('application_official_languages.listening') }}</th>
              <th>{{ $t('application_official_languages.reading') }}</th>
              <th>{{ $t('application_official_languages.writing') }}</th>
              <th>{{ $t('application_official_languages.speaking') }}</th>
              <th>Registration number</th>
              <th width="30">
                <v-btn :disabled="notApplicable === 'No' ? true : false" color="green" small fab @click.native="saveForm()">
                  <v-icon class="white--text">add</v-icon>
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>>
            <template v-for="(item,i) in items">
              <tr v-if="notApplicable === 'Yes'" :key="i" :id="'row' + item.id">
                <td class="pa-1">
                  <el-select :id="'type_taken' + item.id" v-model="item.type_taken">
                    <el-option v-for="(itemT, index) in testTaken" :key="index" :label="itemT.name" :value="itemT.name"></el-option>
                  </el-select>
                </td>
                <td class="pa-1">
                  <el-date-picker :id="'date_test' + item.id" value-format="yyyy-MM-dd" v-model="item.date_test" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </td>
                <td class="pa-1">
                  <el-input :id="'listening' + item.id" type="number" v-model="item.listening" style="width: 100%" />
                </td>
                <td class="pa-1">
                  <el-input :id="'reading' + item.id" type="number" v-model="item.reading" style="width: 100%" />
                </td>
                <td class="pa-1">
                  <el-input :id="'writing' + item.id" type="number" v-model="item.writing" style="width: 100%" />
                </td>
                <td class="pa-1">
                  <el-input :id="'speaking' + item.id" type="number" v-model="item.speaking" style="width: 100%" />
                </td>
                <td class="pa-1">
                  <el-input :id="'report_number' + item.id" type="number" v-model="item.report_number" style="width: 100%" />
                </td>
                <td class="pa-1 text-center">
                  <v-btn color="pink" small fab @click.native="deleteOpen(item, i)"><v-icon class="white--text">clear</v-icon></v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
    </v-card-text>
    <v-card-actions v-if="layout === 'table'" class="pa-3">
      <v-spacer />
      <v-btn :loading="loading" :disabled="loading || disableButton" color="primary" small @click.native="allSaveForm()">
        {{ $t('global.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    },
    memberUID: {
      type: String
    },
    memberID: {
      type: String
    },
    datas: {
      type: Array
    },
    layout: {
      type: String
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        slug: 'languageTestDetails',
        type_taken: null,
        date_text: null,
        report_number: undefined,
        speaking: null,
        reading: null,
        writing: null
      },
      activeNames: -1,
      url: 'imms_table/application_official_languages',
      state: 'languageTestDetails',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      applicableId: 'applicable/id'
    }),
    disableButton: function() {
      return this.notApplicable === 'No' ? true : false
    },
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    },
    testTaken: {
      get() {
        return this.$store.state.other.testTaken
      }
    },
    notApplicable: {
      get() {
        return this.$store.state.applicable.notApplicable.language_test_details
      },
      set(val) {
        this.$store.commit('applicable/set_language_test_details', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = 'table/application_official_languages?filter=((member_uid=' + this.memberUID + ') AND (slug=' + this.state + '))'
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) {
            this.checkTableFields(this.items, this.datas, this.sectionId)

            if (this.notApplicable) {
              $(this.$refs.languageTestDetails).remove()
            }
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        slug: 'languageTestDetails',
        type_taken: null,
        date_text: null,
        report_number: undefined,
        speaking: null,
        reading: null,
        writing: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    allSaveForm() {
      if (this.items.length > 0) {
        this.loading = true
        this.items.forEach(function(form) {
          var action = 'all_stores/save'
          if (form.id != undefined) {
            action = 'all_stores/edit'
            form.id = parseFloat(form.id)
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': form, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                form.id = response.data.resource[0].id
                form.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }, this)
      }
      else {
        return false
      }
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true
          if (formObj.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.formItem.resetFields()
      this.nodeClear()
    },
    checkApplicable() {
      var action = 'applicable/save'
      var that = this
      var data = {}
      data.language_test_details = that.notApplicable
      data.application_id = this.$route.params.id
      data.member_id = parseFloat(this.memberID)
      data.member_uid = this.memberUID
      if (that.applicableId != undefined) {
        data.id = that.applicableId
        action = 'applicable/edit'
      }
      this.$Progress.start()
      this.$store.dispatch(action, data).then(response => {
        this.$store.dispatch('toaster/add', { text: 'Successful save', type: 'success' })
        this.$Progress.finish()
      })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Canceled', type: 'success' })
          this.$Progress.fail()
        })
    }
  }
}
</script>
