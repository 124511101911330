<template>
  <v-card v-if="hideSection" class="mt-3">
    <v-card-title>
      <span>{{sectionLabel}}</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()">Add</v-btn>
    </v-card-title>
    <v-card-text class="p-n">
      <v-row dense class="employment" v-if="!isDataValidation">
        <v-col cols="12">
          <p>Tell us what you have been doing during your eligibility period, inside and outside of Canada. For example, were you working,
          studying, a caregiver or unemployed? Please be sure to provide information for the entire eligibility period. If there are
          gaps or missing periods your application will be returned to you.</p>
        </v-col>
        <v-col cols="12" sm="5" md="4">
          <div class="text13">Check <strong>all</strong> that apply (You may check more than one.)</div>
        </v-col>
        <v-col cols="12" sm="7" md="8">
          <v-row dense class="employment">
            <v-col cols="12" class="mb-3 ml-3">
              <div class="text13 pull-left">I was employed</div>
              <el-radio-group class="ml-3 mt-1 pull-left" @change="checkCitizenship()" v-model="form.employed">
                <el-radio label="No">No</el-radio>
                <el-radio label="Yes">Yes</el-radio>
              </el-radio-group>
            </v-col>
            <v-col cols="12" class="mb-3 ml-3">
              <div class="text13 pull-left">I was self-employed</div>
              <el-radio-group class="ml-3 mt-1 pull-left" @change="checkCitizenship()" v-model="form.self_employed">
                <el-radio label="No">No</el-radio>
                <el-radio label="Yes">Yes</el-radio>
              </el-radio-group>
            </v-col>
            <v-col cols="12" class="mb-3 ml-3">
              <div class="text13 pull-left">I was in school</div>
              <el-radio-group class="ml-3 mt-1 pull-left" @change="checkCitizenship()" v-model="form.school">
                <el-radio label="No">No</el-radio>
                <el-radio label="Yes">Yes</el-radio>
              </el-radio-group>
            </v-col>
            <v-col cols="12" class="mb-3 ml-3">
              <div class="text13 pull-left">I was unemployed, a homemaker or retired</div>
              <el-radio-group class="ml-3 mt-1 pull-left" @change="checkCitizenship()" v-model="form.unemployed">
                <el-radio label="No">No</el-radio>
                <el-radio label="Yes">Yes</el-radio>
              </el-radio-group>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <p>Enter details of your employment and education history during the eligibility period below. Be sure to provide information
          for the entire eligibility period. If there are gaps or missing periods, your application will be returned to you.</p>
        </v-col>
      </v-row>
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>

      <v-row dense class="customCollapseHeader">
        <v-col cols="2">From Date</v-col>
        <v-col cols="2">To Date</v-col>
        <v-col cols="4">Name of employer</v-col>
        <v-col cols="4">City</v-col>
      </v-row>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i" :id="'row' + form.id">
          <template slot="title">
            <div class="customHead">
              <v-row dense>
                <v-col cols="2">
                  <el-tag type="primary" v-if="form.from_date !== null && form.from_date != undefined">{{ form.from_date | day }}</el-tag>
                </v-col>
                <v-col cols="2">
                  <el-tag type="success" v-if="form.to_date !== null && form.to_date != undefined">{{ form.to_date | day }}</el-tag>
                </v-col>
                <v-col cols="4">{{ form.name }}</v-col>
                <v-col cols="4">{{ form.city }}</v-col>
              </v-row>
            </div>
          </template>
          <el-form :model="form" ref="form" label-position="top">
            <v-row dense class="medium-space">
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'from_date')">
                <el-form-item label="From" prop="from_date">
                  <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.from_date" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'to_date')">
                <el-form-item label="To" prop="to_date">
                  <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.to_date" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="medium-space">
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'name')">
                <el-form-item label="Name of employer, school, business or other" prop="name">
                  <el-input v-model="form.name" style="width: 100%" @change="handleAutoSave(i, form)" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'occupation')">
                <el-form-item label="Occupation field of employment other field of studies" prop="occupation">
                  <el-input v-model="form.occupation" style="width: 100%" @change="handleAutoSave(i, form)" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'address')">
                <el-form-item label="Where were you physically located?(Address number and street name)" prop="address">
                  <el-input v-model="form.address" style="width: 100%" @change="handleAutoSave(i, form)" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'city')">
                <el-form-item label="City" prop="city">
                  <el-input v-model="form.city" style="width: 100%" @change="handleAutoSave(i, form)" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'country')">
                <el-form-item label="Country" prop="country">
                  <div class="el-select native-select">
                    <div class="el-input">
                      <select v-model="form.country" placeholder="Select" class="el-input__inner" @change="handleAutoSave(i, form)">
                        <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                      </select>
                    </div>
                  </div>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'contact')">
                <el-form-item label="Name of contact" prop="contact">
                  <el-input v-model="form.contact" style="width: 100%" @change="handleAutoSave(i, form)" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'phone')">
                <el-form-item label="Phone number" prop="phone">
                  <el-input type="number" v-model="form.phone" style="width: 100%" @change="handleAutoSave(i, form)" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'email')">
                <el-form-item label="Email address" prop="email" :rules="[{ type: 'email', message: 'Please input correct email address', trigger: 'blur,change' }]">
                  <el-input type="email" v-model="form.email" style="width: 100%;" @change="handleAutoSave(i, form)" />
                </el-form-item>
              </v-col>
              <v-col cols="12">
                <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(i,form)">{{ $t('global.save') }}</v-btn>
                <v-btn :loading="autoSave" color="error" small @click.native="deleteOpen(form,i)">Delete</v-btn>
              </v-col>
            </v-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  name: 'EmploymentEducation',
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      activeNames: -1,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        from_date: null,
        to_date: null,
        name: null,
        occupation: null,
        address: null,
        city: null,
        country: null,
        contact: null,
        phone: null,
        email: null
      },
      url: 'imms_table/application_employment_education',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      form: 'citizenship/get'
    })
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_employment_education?filter=member_uid=${this.memberUID}&order=to_date DESC`)
        .then(response => {
          let vm = this
          if (vm.isDataValidation) {
            response.resource.forEach(function(item) {
              var form = {
                from_date: null,
                to_date: null,
                name: null,
                occupation: null,
                address: null,
                city: null,
                country: null,
                contact: null,
                phone: null,
                email: null
              }

              var obj = vm.checkFormItems(item, form)

              if (obj != 'empty') {
                vm.items.push(item)
              }
            })

            if (response.resource.length == 0) {
              vm.hideSection = true
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
          else {
            vm.items = response.resource
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    checkCitizenship() {
      this.$store.dispatch('citizenship/edit', this.form)
        .then(response => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Disabled', type: 'success' })
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        from_date: null,
        to_date: null,
        name: null,
        occupation: null,
        address: null,
        city: null,
        country: null,
        contact: null,
        phone: null,
        email: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>

<style>
  .employment .text13 {
    width: 250px;
  }
</style>
