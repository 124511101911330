<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ $t('title.imm_0008_national_identity_document') }}</span>
      <v-spacer />
      <template v-if="!isDataValidation">
        <div class="body-2 mr-3">Do you have a national identity document?</div>
        <div class="body-2 mb-2">
          <el-checkbox id="national_identity_document" v-model="form.national_identity_document" class="ml-5 pl-5">Yes</el-checkbox>
        </div>
      </template>
    </v-card-title>
    <v-card-text class="p-n" v-if="form.national_identity_document">
      <v-row dense class="medium-space">
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'nid_number')">
          <el-form-item :label="$t('imm_0008.nid_number')" prop="nid_number">
            <el-input @change="saveIMM(form)" v-model="form.nid_number" style="width: 100%;" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'nid_country_issue')">    
          <el-form-item :label="$t('imm_0008.nid_country_issue')" prop="nid_country_issue">
            <div class="el-select native-select">
              <div class="el-input">
                <select @change="saveIMM(form)" v-model="form.nid_country_issue" class="el-input__inner">
                  <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                </select>
              </div>
            </div>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'nid_issuedate')">    
          <el-form-item :label="$t('imm_0008.nid_issuedate')" prop="nid_issuedate">
            <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.nid_issuedate" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'nid_expireedate')">
          <el-form-item :label="$t('imm_0008.nid_expireedate')" prop="nid_expireedate">
            <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.nid_expireedate" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  computed: mapGetters({
    country: 'other/country'
  }),
  mounted() {
    if (this.isDataValidation) {
      if (!this.form.national_identity_document) {
        this.hideSection = false
      }
      else {
        var item = {
          nid_country_issue: null,
          nid_expireedate: null,
          nid_issuedate: null,
          nid_number: null
        }

        this.checkItem(this.form, item) 
      }
    }
  }
}
</script>