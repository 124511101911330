<template>
  <el-form
    v-if="hideSection"
    :model="form"
    ref="formGeneric"
    :rules="rules"
    label-position="top">
    <v-card class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">{{sectionInfoBox}}</v-alert>

        <v-row dense class="medium-space" v-if="form.app_primary && !isEOI">
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'intended_province')">
            <el-form-item :label="$t('global.intended_province')" prop="intended_province">
              <el-input @change="checkIntended()" v-model="intended.intended_province" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'intended_employer')">
            <el-form-item :label="$t('global.intended_employer')" prop="intended_employer">
              <el-input @change="checkIntended()" v-model="intended.intended_employer" style="width: 100%;" />
            </el-form-item>
          </v-col>
        </v-row>

        <v-row dense class="medium-space" v-if="!isEOI">
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'uci_number')">
            <el-form-item v-if="sectionSlug == 'BcPnp'" label="CIC Client ID/UCI Number" prop="uci_number">
              <el-input @change="saveIMM(form)" placeholder="CIC Client ID/UCI Number" :maxlength="10" v-model="form.uci_number" style="width: 100%;" />
            </el-form-item>
            <el-form-item v-else :label="$t('imm_0008.uci_number')" prop="uci_number">
              <el-input @change="saveIMM(form)" :placeholder="$t('imm_0008.uci_number')" :maxlength="10" v-model="form.uci_number" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'service_in')">
            <el-form-item :label="$t('imm_0008.service_in')" prop="service_in">
              <el-select @change="saveIMM(form)" v-model="form.service_in" placeholder="Select" style="width: 100%;">
                <el-option value="English">English</el-option>
                <el-option value="French">French</el-option>
              </el-select>
            </el-form-item>
          </v-col>
        </v-row>

        <v-row dense class="medium-space" v-if="sectionSlug === 'BcPnp' && !isEOI">
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'date_signed')">
            <el-form-item label="Date Signed" prop="date_signed">
              <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.date_signed" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'valid_until')">
            <el-form-item label="Valid Until" prop="valid_until">
              <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.valid_until" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </v-col>
        </v-row>

        <v-row dense class="medium-space">
          <v-col cols="12" v-if="!isDataValidation">
            <div class="body-2 mb-2">Your name(s) as they appear on your passport.</div>
            <v-divider class="mb-3"></v-divider>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'fname')">
            <el-form-item :label="$t('imm_0008.fname')" prop="fname">
              <el-input @change="saveIMM(form)" v-model="form.fname" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'middlename')">
            <el-form-item :label="$t('imm_0008.middlename')" prop="middlename">
              <el-input @change="saveIMM(form)" v-model="form.middlename" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'lname')">
            <el-form-item :label="$t('imm_0008.lname')" prop="lname">
              <el-input @change="saveIMM(form)" v-model="form.lname" style="width: 100%;" />
            </el-form-item>
          </v-col>
        </v-row>
        <v-row dense class="medium-space row-border">
          <v-col cols="12" v-if="dataValidation(form, 'applicable')">
            <div class="body-2 mb-2">
              {{ $t('imm_0008.applicable') }}
              <el-checkbox @change="saveIMM(form)" v-model="form.applicable" class="ml-5 pl-5">Not Applicable</el-checkbox>
            </div>
          </v-col>
        </v-row>
        <v-row dense class="medium-space row-border" v-show="!form.applicable">
          <v-col cols="12" v-if="!isDataValidation">
            <v-divider class="mb-3"></v-divider>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'other_fname')">
            <el-form-item :label="$t('imm_0008.other_fname')" prop="other_fname">
              <el-input @change="saveIMM(form)" v-model="form.other_fname" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'other_middlename')">
            <el-form-item :label="$t('imm_0008.other_middlename')" prop="other_middlename">
              <el-input @change="saveIMM(form)" v-model="form.other_middlename" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'other_lname')">
            <el-form-item :label="$t('imm_0008.other_lname')" prop="other_lname">
              <el-input @change="saveIMM(form)" v-model="form.other_lname" style="width: 100%;" />
            </el-form-item>
          </v-col>
        </v-row>
        <v-divider class="mb-3" v-if="!isDataValidation" v-show="form.applicable"></v-divider>
        <v-row dense class="medium-space row-border" v-if="dataValidation(form, 'applicable')" v-show="form.applicable">
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="el-form-item__label mt-2">{{ $t('imm_0008.fname_native_language') }}</label>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <el-form-item prop="fname_native_language">
              <el-input @change="saveIMM(form)" v-model="form.fname_native_language" style="width: 100%;" />
            </el-form-item>
          </v-col>
        </v-row>
        <v-divider class="mb-3" v-if="!isDataValidation"></v-divider>
        <v-row dense class="medium-space" v-if="!isEOI">
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'gender')">
            <el-form-item :label="$t('imm_0008.gender')" prop="gender">
              <el-select @change="saveIMM(form)" filterable v-model="form.gender" placeholder="Select" style="width: 100%;">
                <el-option value="Male">Male</el-option>
                <el-option value="Female">Female</el-option>
                <el-option value="Unspecified">Unspecified</el-option>
              </el-select>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'birth_place')">
            <el-form-item :label="$t('imm_0008.birth_place')" prop="birth_place">
              <el-input @change="saveIMM(form)" v-model="form.birth_place" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'birth_country_id')">
            <el-form-item :label="$t('imm_0008.birth_country_id')" prop="birth_country_id">
              <div class="el-select native-select">
                <div class="el-input">
                  <select @change="saveIMM(form);getProvinceBirth(form.birth_country_id)" v-model="form.birth_country_id" placeholder="Select" class="el-input__inner">
                    <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                  </select>
                </div>
              </div>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'citizenship1')">
            <el-form-item :label="$t('imm_0008.citizenship1')" prop="citizenship1">
              <div class="el-select native-select">
                <div class="el-input">
                  <select @change="saveIMM(form)" v-model="form.citizenship1" placeholder="Select" class="el-input__inner">
                    <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                  </select>
                </div>
              </div>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'birth_date')">
            <el-form-item :label="$t('imm_0008.birth_date')" prop="birth_date">
              <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.birth_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="isYukon">
            <el-form-item v-if="dataValidation(form, 'native_name')" :label="$t('imm_0008.native_name')" prop="native_name">
              <div class="el-select native-select">
                <div class="el-input">
                  <select @change="saveIMM(form)" v-model="form.native_name" placeholder="Select" class="el-input__inner">
                    <option v-for="(item, index) in languages" v-bind:key="index" :value="item.name" :label="item.name">{{ item.name }}</option>
                  </select>
                </div>
              </div>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'birth_province')">
            <el-form-item label="Province/State of Birth" prop="birth_province">
              <div v-if="selectProvineBirth" class="el-select native-select">
                <div class="el-input">
                  <select @change="saveIMM(form)" v-model="form.birth_province" placeholder="Select" class="el-input__inner">
                    <option v-for="(item, index) in provinces" v-bind:key="index" :value="item.province_name" :label="item.province_name">{{ item.province_name }}</option>
                  </select>
                </div>
              </div>
              <el-input v-else v-model="form.birth_province" @change="saveIMM(form)" />
            </el-form-item>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'
import api from '@/plugins/axios'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    datas: {
      type: Array
    },
    isDataValidation: {
      type: Boolean
    },
    isYukon: {
      type: Boolean
    }
  },
  data() {
    var validateFirstName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Please input the first name'))
      }
      else {
        callback()
      }
    };
    var validateLastName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Please input the last name'))
      }
      else {
        callback()
      }
    };
    var checkCharacter = (rule, value, callback) => {
      var patt = new RegExp("[^ A-Za-z а-яА-ЯЁё]");
      var res = patt.test(value);

      if (res) {
        callback(new Error('Please only use alpha characters (abc)'));
      }
      else {
        callback();
      }
    };
    return {
      intended: {},
      rules: {
        fname: [
          { validator: validateFirstName, required: true, trigger: 'blur' },
          { validator: checkCharacter, trigger: 'blur' }
        ],
        lname: [
          { validator: validateLastName, required: true, trigger: 'blur' },
          { validator: checkCharacter, trigger: 'blur' }
        ],
        fname_native_language: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        middlename: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        other_fname: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        other_middlename: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        other_lname: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        uci_number: [
          { validator: this.checkUCI, trigger: 'blur' }
        ]
      },
      selectProvineBirth: false,
      provinces: [],
      isEOI: false,
      appTypeId: this.$route.params.apptypeId
    }
  },
  mounted() {
    this.isEOI = parseInt(this.appTypeId) === 76 ? true : false
    this.getProvinceBirth(this.form.birth_country_id)
    if (this.isDataValidation) {
      var item = {
        birth_date: null,
        citizenship1: null,
        birth_country_id: null,
        birth_place: null,
        gender: null,
        other_lname: null,
        other_fname: null,
        lname: null,
        fname: null,
        valid_until: null,
        date_signed: null,
        service_in: null
      }

      if (this.sectionSlug !== 'BcPnp') {
        delete item.date_signed
        delete item.valid_until
      }
      if (this.form.applicable) {
        delete item.other_fname
        delete item.other_lname
      }

      this.checkItem(this.form, item)
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      languages: 'other/nativeLanguages'
    })
  },
  created() {
    this.$store.dispatch('other/getNativeLanguages')

    this.$store.dispatch('store/get', "table/application_list_org_view?filter=imms_uuid=" + this.$route.params.id).then(response => {
      if (response.resource.length > 0) {
        this.intended = response.resource[0]
      }
    })
  },
  methods: {
    checkIntended() {
      if (this.intended) {
        this.$store.dispatch('application/edit', this.intended)
          .then(response => {
            this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
          })
          .catch(err => {
            this.$store.dispatch('toaster/add', { text: 'Disabled', type: 'success' })
          })
      }
    },
    saveIMM(formGeneric) {
      formGeneric.uci_number = formGeneric.uci_number && !isNaN(formGeneric.uci_number) ? +formGeneric.uci_number : null
      formGeneric.application_id = +this.$route.params.id

      this.$refs.formGeneric.validate((valid) => {
        if (valid) {
          this.$store.dispatch('generic/edit', formGeneric)
        }
        else {
          return false
        }
      })
    },
    checkUCI(rule, value, callback) {
      if (value) {
        if (value.toString().length === 10 || value.toString().length === 8) {
          callback()
        }
        else {
          callback(new Error('UCI Number field to 8 or 10 digits'))
        }
      }
      else {
        callback()
      }
    },
    getProvinceBirth(id) {
      if (id != undefined && id != null) {
        if (42 == parseInt(id) || 239 == parseInt(id)) {
          this.selectProvineBirth = true
          api.get(`/table/provinces?filter=country_id=${id}&order=province_name asc`).then(response => {
            this.provinces = response.data.resource
          })
        }
        else {
          this.selectProvineBirth = false
        }
      }
      else {
        this.selectProvineBirth = false
      }
    }
  }
}
</script>
