<template>
  <div class="pa-3" v-if="hideSection">
    <v-row dense class="medium-space">
      <v-col cols="12">
        <div class="body-2 mb-2 pull-left">{{ $t('global.residential_address') }}</div>
        <el-form-item prop="mailing_address" class="pull-right mb-0" v-if="dataValidation(form, 'mailing_address')">
          <el-checkbox @change="saveIMM(form)" v-model="form.mailing_address">{{ $t('global.same_mailing_address') }}</el-checkbox>
        </el-form-item>
      </v-col>
    </v-row>
    <v-row dense class="medium-space">
      <v-col cols="12"><v-divider class="mb-3"></v-divider></v-col>
      <v-col class="rel" cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_apartment_unit')">
        <el-form-item :label="$t('imm_0008.res_apartment_unit')" prop="res_apartment_unit">
          <el-input @change="saveIMM(form)" type="number" v-model="form.res_apartment_unit" style="width: 100%;" />
        </el-form-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_streetno')">
        <el-form-item :label="$t('imm_0008.res_streetno')" prop="res_streetno">
          <el-input @change="saveIMM(form)" v-model="form.res_streetno" style="width: 100%;" />
        </el-form-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_street_name')">
        <el-form-item :label="$t('imm_0008.res_street_name')" prop="res_street_name">
          <el-input @change="saveIMM(form)" v-model="form.res_street_name" style="width: 100%;" />
        </el-form-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_city')">
        <el-form-item :label="$t('imm_0008.res_city')" prop="res_city">
          <el-input @change="saveIMM(form)" v-model="form.res_city" style="width: 100%;" />
        </el-form-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_country_id')">
        <el-form-item :label="$t('imm_0008.res_country_id')" prop="res_country_id">
          <div class="el-select native-select">
            <div class="el-input">
              <select @change="saveIMM(form);getProvinceRes(form)" v-model="form.res_country_id" placeholder="Select" class="el-input__inner">
                <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
              </select>
            </div>
          </div>
        </el-form-item>
      </v-col>
      <!-- <template v-if="selectProvine"> -->
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_province')">
          <el-form-item :label="$t('imm_0008.res_province')" prop="res_province">
            <div v-if="selectProvine" class="el-select native-select">
              <div class="el-input">
                <select @change="saveIMM(form)" v-model="form.res_province" placeholder="Select" class="el-input__inner">
                  <option v-for="(item, index) in province" v-bind:key="index" :value="item.province_name" :label="item.province_name">{{ item.province_name }}</option>
                </select>
              </div>
            </div>
            <el-input v-else v-model="form.res_province" @change="saveIMM(form)" />
          </el-form-item>
        </v-col>
      <!-- </template> -->
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_postal_code')">
        <el-form-item :label="$t('imm_0008.res_postal_code')" prop="res_postal_code">
          <el-input @change="saveIMM(form)" v-model="form.res_postal_code" style="width: 100%;" />
        </el-form-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_district')">
        <el-form-item :label="$t('imm_0008.res_district')" prop="res_district">
          <el-input @change="saveIMM(form)" v-model="form.res_district" style="width: 100%;" />
        </el-form-item>
      </v-col>
      <template v-if="isOINP || isYukon">
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'res_address_line')">
          <el-form-item label="Address Line 2" prop="res_address_line">
            <el-input type="textarea" @change="saveIMM(form)" v-model="form.res_address_line" style="width: 100%;" />
          </el-form-item>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'
import api from '@/plugins/axios'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    isDataValidation: {
      type: Boolean
    },
    isOINP: {
      type: Boolean
    },
    isYukon: {
      type: Boolean
    }
  },
  data() {
    return {
      province: [],
      selectProvine: false
    }
  },
  watch: {
    hideSection: function(val) {
      if (this.isDataValidation) {
        this.$emit('checkSection', { 'item': val, 'id': 1 })
      }
    }
  },
  mounted() {
    this.getProvinceRes(this.form)

    if (this.isDataValidation) {
      var item = {
        mailing_address: null,
        res_apartment_unit: null,
        res_street_name: null,
        res_city: null,
        res_streetno: null,
        res_country_id: null,
        res_district: null,
        res_postal_code: null,
        res_province: null
      }

      if (!this.isOINP) {
        delete item.res_address_line
      }

      this.checkItem(this.form, item)
    }
  },
  computed: mapGetters({
    country: 'other/country'
  }),
  methods: {
    getProvinceRes(item) {
      if (item.res_country_id != undefined) {
        if (42 == parseInt(item.res_country_id) || 239 == parseInt(item.res_country_id)) {
          this.selectProvine = true
          api.get(`/table/provinces?filter=country_id=${item.res_country_id}&order=province_name asc`).then(response => {
            this.province = response.data.resource
          })
        }
        else {
          this.selectProvine = false
        }
      }
      else {
        this.selectProvine = false
      }
    }
  }
}
</script>