<template>
  <div class="mt-3">
    <el-form v-if="hideSection" :model="form" ref="form" :rules="rules" label-position="top" class="mt-3 personal-details">
      <v-card>
        <v-card-title v-if="!isDataValidation" v-text="sectionLabel" />
        <v-card-text>
          <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
            {{sectionInfoBox}}
          </v-alert>
          <v-row dense class="medium-space">
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'service_in')">
              <el-form-item label="In which language would you like to receive service" prop="service_in">
                <el-select @change="handleAutoSave(0, form);" v-model="form.service_in" placeholder="Select" style="width: 100%;">
                  <el-option value="English">English</el-option>
                  <el-option value="French">French</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'accommodation')">
              <el-form-item label="Do you have special needs requiring accommodation" prop="accommodation">
                <el-select @change="handleAutoSave(0, form);" v-model="form.accommodation" placeholder="Select" style="width: 100%;">
                  <el-option value="Yes">Yes</el-option>
                  <el-option value="No">No</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="form.accommodation === 'Yes'">
              <el-form-item v-if="dataValidation(form, 'type_accommodation')" label="If yes, please select the type of accommodation required" prop="type_accommodation">
                <el-select @change="handleAutoSave(0, form);" v-model="form.type_accommodation" placeholder="Select" style="width: 100%;">
                  <el-option value="Sing language interpretation">Sing language interpretation</el-option>
                  <el-option value="Visually impaired">Visually impaired</el-option>
                  <el-option value="Wheelchair access">Wheelchair access</el-option>
                  <el-option value="Other">Other</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="form.type_accommodation === 'Other'">
              <el-form-item v-if="dataValidation(form, 'type_accommodation_other')" label="If you choose 'Other', provide more information about your accommodation in this box" prop="type_accommodation_other">
                <el-input @change="handleAutoSave(0, form);" v-model="form.type_accommodation_other" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title class="mt-3" v-if="!isDataValidation" v-text="'Tell us about yourself'" />
        <v-card-text class="p-n">
          <v-row dense class="medium-space">
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'canadian_citizenship')">
              <el-form-item label="Have you applied for Canadian Citizenship before?" prop="canadian_citizenship">
                <el-select @change="handleAutoSave(0, form);" v-model="form.canadian_citizenship" placeholder="Select" style="width: 100%;">
                  <el-option value="No">No</el-option>
                  <el-option value="Yes">Yes</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'client_id')">
              <el-form-item label="What is your Client ID # or Unique Client Identifier (UCI) as shown exactly on your most recent immigration document?" prop="client_id">
                <el-input @change="handleAutoSave(0, form);" v-model="form.client_id" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="medium-space">
            <v-col cols="12" v-if="!isDataValidation">
              <div class="body-2 mb-2">What is your name as shown exactly on your most recent immigration document?</div>
              <v-divider class="mb-3"></v-divider>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'lname')">
              <el-form-item label="Last Name" prop="lname">
                <el-input @change="handleAutoSave(0, form);" v-model="form.lname" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'middlename')">
              <el-form-item label="Middle Name" prop="middlename">
                <el-input @change="handleAutoSave(0, form);" v-model="form.middlename" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'fname')">
              <el-form-item label="First Name" prop="fname">
                <el-input @change="handleAutoSave(0, form);" v-model="form.fname" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'date_birth')">
              <el-form-item label="What is your date of birth as shown exactly on your most recent immigration document?" prop="date_birth">
                <el-date-picker @change="handleAutoSave(0, form);" value-format="yyyy-MM-dd" v-model="form.date_birth" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'date_permanent')">
              <el-form-item label="What date did you become a permanent resident of Canada (landed immigrant)?" prop="date_permanent">
                <el-date-picker @change="handleAutoSave(0, form);" value-format="yyyy-MM-dd" v-model="form.date_permanent" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="medium-space">
            <v-col cols="12" class="mb-3" v-if="dataValidation(form, 'change_name')">
              <div class="text13 pull-left">Have you changed your name or sex designation since becoming a permanent resident?</div>
              <el-radio-group @change="handleAutoSave(0, form);" class="ml-3 mt-1 pull-left" v-model="form.change_name">
                <el-radio label="No">No</el-radio>
                <el-radio label="Yes">Yes</el-radio>
              </el-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="form.change_name === 'Yes'">
              <el-form-item v-if="dataValidation(form, 'accommodation_required')" label="Select the type of accommodation required" prop="accommodation_required">
                <el-select @change="handleAutoSave(0, form);" v-model="form.accommodation_required" placeholder="Select" style="width: 100%;">
                  <el-option value="Legal name change">Legal name change</el-option>
                  <el-option value="Change of sex designation">Change of sex designation</el-option>
                  <el-option value="Both">Both</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <template v-if="form.change_name === 'Yes' && (form.accommodation_required === 'Legal name change' || form.accommodation_required === 'Change of sex designation')">
              <v-col cols="12" v-if="!isDataValidation">
                <div class="text13 mb-2">Insert new legal name</div>
                <v-divider class="mb-2"></v-divider>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'legal_last_name')">
                <el-form-item label="Last name" prop="legal_last_name">
                  <el-input @change="handleAutoSave(0, form);" v-model="form.legal_last_name" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'legal_first_name')">
                <el-form-item label="First name" prop="legal_first_name">
                  <el-input @change="handleAutoSave(0, form);" v-model="form.legal_first_name" style="width: 100%;" />
                </el-form-item>
              </v-col>
            </template>
          </v-row>
          <v-row dense class="medium-space" v-if="dataValidation(form, 'citizenship_application')">
            <v-col cols="12" class="mb-3">
              <div class="text13 pull-left">Is this the name you would like to use for your citizenship application?</div>
              <el-radio-group @change="handleAutoSave(0, form);" class="ml-3 mt-1 pull-left" v-model="form.citizenship_application">
                <el-radio label="No">No</el-radio>
                <el-radio label="Yes">Yes</el-radio>
              </el-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title class="mt-3" v-if="!isDataValidation" v-text="'Contact Information'" />
        <v-card-text>
          <v-row dense class="medium-space">
            <v-col cols="12" v-if="!isDataValidation">
              <div class="body-2 mb-2 pull-left">Home address</div>
            </v-col>
            <v-col cols="12" v-if="!isDataValidation">
              <v-divider class="mb-3"></v-divider>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3" v-if="dataValidation(form, 'email')">
              <el-form-item label="Email address" prop="email" :rules="[{ type: 'email', message: 'Please input correct email address', trigger: 'blur,change' }]">
                <el-input @change="handleAutoSave(0, form);" v-model="form.email" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3" v-if="dataValidation(form, 'confirm_email')">
              <el-form-item label="Confirm email address" prop="confirm_email" :rules="[{ type: 'email', message: 'Please input correct email address', trigger: 'blur,change' }]">
                <el-input @change="handleAutoSave(0, form);" v-model="form.confirm_email" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" v-if="dataValidation(form, 'address')">
              <el-form-item label="Home address (residence)" prop="address">
                <el-input @change="handleAutoSave(0, form);" v-model="form.address" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'unit')">
              <el-form-item label="Apt/Unit" prop="unit">
                <el-input @change="handleAutoSave(0, form);" type="number" value="number" v-model="form.unit" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'city')">
              <el-form-item label="City/Town" prop="city">
                <el-input @change="handleAutoSave(0, form);" v-model="form.city" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'province')">
              <el-form-item label="Province" prop="province">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select @change="handleAutoSave(0, form);" v-model="form.province" placeholder="Select" class="el-input__inner">
                      <option v-for="(item, index) in province" v-bind:key="index" :value="item.province_name" :label="item.province_name">{{ item.province_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'postal_code')">
              <el-form-item label="Postal code" prop="postal_code">
                <el-input @change="handleAutoSave(0, form);" v-model="form.postal_code" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'phone')">
              <el-form-item label="Best phone number to reach you" prop="phone">
                <el-input @change="handleAutoSave(0, form);" type="number" value="number" v-model="form.phone" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'other_phone')">
              <el-form-item label="Other phone number" prop="other_phone">
                <el-input @change="handleAutoSave(0, form);" type="number" value="number" v-model="form.other_phone" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="medium-space">
            <v-col cols="12" class="mb-3" v-if="dataValidation(form, 'mailing')">
              <div class="body-2 pull-left">Is the mailing address the same as the home address?</div>
              <el-radio-group @change="handleAutoSave(0, form);" class="ml-3 mt-1 pull-left" v-model="form.mailing">
                <el-radio label="No">No</el-radio>
                <el-radio label="Yes">Yes</el-radio>
              </el-radio-group>
            </v-col>
            <template v-if="form.mailing !== 'Yes'">
              <v-col cols="12" v-if="!isDataValidation">
                <div class="body-2 mb-2 pull-left">Mailing address</div>
              </v-col>
              <v-col cols="12" v-if="!isDataValidation">
                <v-divider class="mb-3"></v-divider>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" v-if="dataValidation(form, 'mailing_address')">
                <el-form-item label="Mailing address" prop="mailing_address">
                  <el-input @change="handleAutoSave(0, form);" v-model="form.mailing_address" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'mailing_unit')">
                <el-form-item label="Apt/Unit" prop="mailing_unit">
                  <el-input @change="handleAutoSave(0, form);" id="mailing_unit" value="number" v-model="form.mailing_unit" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'mailing_city')">
                <el-form-item label="City/Town" prop="mailing_city">
                  <el-input @change="handleAutoSave(0, form);" v-model="form.mailing_city" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'mailing_province')">
                <el-form-item label="Province" prop="mailing_province">
                  <div class="el-select native-select">
                    <div class="el-input">
                      <select @change="handleAutoSave(0, form);" v-model="form.mailing_province" placeholder="Select" class="el-input__inner">
                        <option v-for="(item, index) in province" v-bind:key="index" :value="item.province_name" :label="item.province_name">{{ item.province_name }}</option>
                      </select>
                    </div>
                  </div>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'mailing_postal_code')">
                <el-form-item label="Postal code" prop="mailing_postal_code">
                  <el-input @change="handleAutoSave(0, form);" v-model="form.mailing_postal_code" style="width: 100%;" />
                </el-form-item>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-card-title class="mt-3" v-if="!isDataValidation" v-text="'Assessing your eligibility'" />
        <v-card-text>
          <v-row dense class="medium-space">
            <v-col cols="12" class="mb-3 ml-3" v-if="dataValidation(form, 'eligible_based')">
              <div class="text13 pull-left">a) Are you eligible based on the Physical Presence Calculator to apply for a grant of citizenship?</div>
              <el-radio-group @change="handleAutoSave(0, form);" class="ml-3 mt-1 pull-left" v-model="form.eligible_based">
                <el-radio label="No">No</el-radio>
                <el-radio label="Yes">Yes</el-radio>
              </el-radio-group>
            </v-col>
            <template v-if="!isDataValidation">
              <v-col cols="12" class="mb-3 ml-3" v-if="form.eligible_based === 'No'">
                <div class="text13 pull-left">If you checked NO, you should wait until you meet the physical presence requirement before completing the application</div><br>
              </v-col>
              <v-col cols="12" class="mb-3 ml-3" v-if="form.eligible_based === 'No'">
                <el-form-item prop="oppc" v-if="dataValidation(form, 'oppc')">
                  <el-checkbox @change="handleAutoSave(0, form);" v-model="form.oppc">You must provide a signed copy of the Online Physical Presence Calculator</el-checkbox>
                </el-form-item>
                <el-form-item prop="cpp" v-if="dataValidation(form, 'cpp')">
                  <el-checkbox @change="handleAutoSave(0, form);" v-model="form.cpp">or How to Calculate Physical Presence form (CIT 0407)</el-checkbox>
                </el-form-item>
              </v-col>
            </template>
            <v-col cols="12" class="mb-3" v-if="!isDataValidation">
              <div class="text13">
                <div class="pull-left">Enter the dates from the calculator here: Your eligibility period is </div>
                <el-form-item prop="period" class="pull-left" v-if="dataValidation(form, 'period')">
                  <input @change="handleAutoSave(0, form);" v-model="form.period" class="pull-left w300 ml-3 mr-3">
                </el-form-item>
                <div class="pull-left"> to </div>
                <el-form-item prop="to_period" class="pull-left" v-if="dataValidation(form, 'to_period')">
                  <input @change="handleAutoSave(0, form);" v-model="form.to_period" class="pull-left w300 ml-3 mr-3">
                </el-form-item>
              </div>
            </v-col>
            <v-col cols="12" class="mb-3 ml-3" v-if="dataValidation(form, 'eligible_period')">
              <div class="text13 pull-left">b) During your eligility period did you have Temporary Resident or Protected Person status in Canada before becoming a permanent resident?</div>
              <el-form-item prop="eligible_period">
                <el-radio-group @change="handleAutoSave(0, form);" class="ml-3 mt-1 pull-left" v-model="form.eligible_period">
                  <el-radio label="No">No</el-radio>
                  <el-radio label="Yes">Yes</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" class="mb-3 ml-5" v-if="!isDataValidation">
              <div class="text13">If yes, provide details in the chart below</div>
              <div class="text13 mt-2">Temporary residence status includes lawful authorization to enter or remain in Canada as a:</div>
              <ul class=" mt-2">
                <li>visitor,</li>
                <li>student,</li>
                <li>worder or,</li>
                <li>temporary resident permit holder</li>
              </ul>
              <div class="text13  mt-2">A protected person is someone who:</div>
              <ul class=" mt-2">
                <li>was found to be in need of protection or a convention refugee by the Immigration and Refugee Board or,</li>
                <li>a person who received a positive decision on a Pre-Remove Risk Assessment from IRCC.</li>
              </ul>
              <b class=" mt-2">NOTE: If you made a refugee claim, or were included on a family members refugee claim, you will not be credited time in Canada from the date of the refugee claim until you received a positive decision confirming that you are a protected person as described above.</b>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <temporary-residence-status
        v-if="form.eligible_period === 'Yes'"
        ref="temporaryResidenceStatus"
        :sectionId="sectionId"
        :memberUID="memberUID"
        :memberID="memberID"
        :isDataValidation="isDataValidation"
      />

      <v-card>
        <v-card-text v-if="dataValidation(form, 'eligility_period_outside')">
          <v-col cols="12" class="mb-3 ml-3">
            <div class="text13 pull-left">c) During your eligility period did you live outside of Canada:</div>
            <el-form-item prop="eligility_period_outside">
              <el-radio-group @change="handleAutoSave(0, form);" class="ml-3 mt-1 pull-left" v-model="form.eligility_period_outside">
                <el-radio label="No">No</el-radio>
                <el-radio label="Yes">Yes</el-radio>
              </el-radio-group>
            </el-form-item>
          </v-col>
        </v-card-text>
        <v-card-actions class="pa-3">
          <v-btn :loading="autoSave" :disabled="autoSave" color="primary" dark small class="ml-3" @click.native="handleAutoSave(0, form)">
            {{ $t('global.save') }}

          </v-btn>
        </v-card-actions>
      </v-card>
    </el-form>
    <div class="error--snack error" v-if="errorMessage.length > 0">
      <template v-for="(message, index) in errorMessage">
        <div :key="index" class="error--snack__content">
            {{ message.message }}
        </div>
      </template>
    </div>

    <list-all-names
      ref="listAllNames"
      sectionLabel="List All Names"
      sectionInfoBox="List all names that you have used, including name at birth, maiden name, previously married name(s), alias(es) and nicknames."
      :sectionId="sectionId"
      :memberUID="memberUID"
      :memberID="memberID"
      :isDataValidation="isDataValidation"
    />
    <section-addresses
      ref="addresses"
      :sectionId="sectionId"
      :memberUID="memberUID"
      :memberID="memberID"
      :isDataValidation="isDataValidation"
    />
    <another-country
      ref="anotherCountry"
      sectionLabel="Another Country"
      :form="form"
      :sectionId="sectionId"
      :memberUID="memberUID"
      :memberID="memberID"
      :isDataValidation="isDataValidation"
    />
    <employment-education
      ref="employmentEducation"
      sectionLabel="Employment and Education"
      :sectionId="sectionId"
      :memberUID="memberUID"
      :memberID="memberID"
      :isDataValidation="isDataValidation"
    />
    <tax-insurance
      ref="taxInsurance"
      sectionLabel="Tax Insurance"
      :sectionId="sectionId"
      :memberUID="memberUID"
      :memberID="memberID"
      :isDataValidation="isDataValidation"
    />

    <citizenship-status
      ref="citizenshipStatus"
      sectionLabel="Immigration and Citizenship Status"
      :sectionId="sectionId"
      :memberUID="memberUID"
      :memberID="memberID"
      :isDataValidation="isDataValidation"
    />

    <travel-document
      ref="travelDocument"
      sectionLabel="Travel Document"
      :sectionId="sectionId"
      :memberUID="memberUID"
      :memberID="memberID"
      :isDataValidation="isDataValidation"
    />

    <language-evidence
      sectionLabel="Language Evidence"
      :sectionId="sectionId"
      :memberUID="memberUID"
      :memberID="memberID"
      :isDataValidation="isDataValidation"
    />
    <section-prohibitions
      sectionLabel="Prohibitions"
      :sectionId="sectionId"
      :memberUID="memberUID"
      :memberID="memberID"
      :isDataValidation="isDataValidation"
    />
    <disclose-personal-information
      sectionLabel="Consent to Disclose Personal Information after You Acquire Citizenship"
      ref="disclosePersonalInformation"
      :sectionId="sectionId"
      :memberUID="memberUID"
      :memberID="memberID"
      :isDataValidation="isDataValidation"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TemporaryResidenceStatus from './temporary_residence_status'
import ListAllNames from './list_all_names'
import TravelDocument from './travel_document'
import AnotherCountry from './another_country'
import EmploymentEducation from './employment_education'
import TaxInsurance from './tax_insurance'
import CitizenshipStatus from './citizenship_status'
import LanguageEvidence from './language_evidence'
import SectionAddresses from './citizenship_addresses'
import SectionProhibitions from './prohibitions'
import DisclosePersonalInformation from './disclose_personal_information'

import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    layout: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean,
      default: false
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        service_in: null,
        accommodation: null,
        type_accommodation: null,
        type_accommodation_other: null,
        canadian_citizenship: null,
        fname: null,
        lname: null,
        middlename: null,
        client_id: null,
        date_birth: null,
        date_permanent: null,
        citizenship_application: null,
        country_id: null,
        phone: null,
        other_phone: null,
        address: null,
        email: null,
        confirm_email: null,
        postal_code: null,
        unit: null,
        city: null,
        province: null,
        mailing_address: null,
        mailing_country_id: null,
        mailing_city: null,
        mailing_postal_code: null,
        mailing_province: null,
        mailing: null,
        paying_someone: null,
        eligible_based: null,
        oppc: null,
        cpp: null,
        eligible_period: null,
        period: null,
        to_period: null,
        accommodation_required: null,
        change_name: null,
        legal_last_name: null,
        legal_first_name: null,
        mailing_unit: null,
        eligility_period_outside: null
      },
      rules: {
        uci_number: [
          { validator: this.checkUCI, trigger: 'blur' }
        ]
      },
      errorMessage: [],
      selectProvine: false,
      loading: false,
      url: 'imms_table/application_citizenship_adult'
    }
  },
  components: {
    TemporaryResidenceStatus,
    ListAllNames,
    SectionAddresses,
    TravelDocument,
    AnotherCountry,
    EmploymentEducation,
    TaxInsurance,
    CitizenshipStatus,
    LanguageEvidence,
    SectionProhibitions,
    DisclosePersonalInformation
  },
  computed: {
    ...mapGetters({
      members: 'generic/all',
      province: 'other/province'
    })
  },
  created() {
    this.$store.dispatch('other/getProvince', 42)
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_citizenship_adult?filter=member_uid=${this.memberUID}&order=id ASC`)
        .then(response => {
          if (response.resource.length > 0) {
            this.form = response.resource[0]
            if (this.isDataValidation) {
              this.checkRow(response.resource[0])
            }
          }
          else {
            if (this.isDataValidation) {
              this.checkRow(this.form)
            }
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    checkRow(data) {
      if (data.accommodation !== 'Yes') {
        delete this.form.type_accommodation
      }
      if (data.type_accommodation !== 'Other') {
        delete this.form.type_accommodation_other
      }
      if (data.change_name === 'Yes') {
        delete this.form.legal_last_name
        delete this.form.legal_first_name
        delete this.form.accommodation_required
      }

      if (data.mailing == 'Yes') {
        delete this.form.mailing_address
        delete this.form.mailing_postal_code
        delete this.form.mailing_province
        delete this.form.mailing_city
        delete this.form.mailing_unit
      }
      delete this.form.oppc
      delete this.form.cpp
      delete this.form.period
      delete this.form.to_period
      delete this.form.country_id
      delete this.form.mailing_country_id
      delete this.form.paying_someone
      delete data.representative
      delete data.provide_number
      delete data.authorize
      delete data.travel_document_details
      delete data.person_applying
      delete data.year
      delete data.applied
      delete data.legally
      delete data.cur_fname
      delete data.cur_lname
      delete data.cur_middlename
      delete data.other_fname
      delete data.other_middlename
      delete data.other_lname
      delete data.birth_place
      delete data.birth_country_id
      delete data.citizenship
      delete data.street
      delete data.maritial_status
      delete data.gender
      delete data.height
      delete data.height_ft
      delete data.home_phone
      delete data.work_phone
      delete data.cell_phone
      delete data.print_name
      delete data.copy_certificate
      delete data.more_situations
      delete data.none_situations

      this.checkItem(data, this.form)
    },
    checkUCI(rule, value, callback) {
      var idx = this.errorMessage.map(p => 'Have you applied for Canadian Citizenship before?').indexOf(rule.field)

      if (value) {
        if (value.toString().length === 10 || value.toString().length === 8) {
          if (idx !== -1) this.errorMessage.splice(idx, 1)
          callback()
        }
        else {
          if (idx === -1) this.errorMessage.push({ "message": "UCI Number field to 8 or 10 digits", "field": "uci_number" })
          callback(new Error(' '))
        }
      }
      else {
        if (idx !== -1) this.errorMessage.splice(idx, 1)
        callback()
      }
    },
    onSave() {
      this.submitForm()
      if (this.form.eligible_period === 'Yes') {
        this.$refs.temporaryResidenceStatus.allSaveForm()
      }

      this.$refs.travelDocument.allSaveForm()
      this.$refs.addresses.allSaveForm()
      this.$refs.anotherCountry.allSaveForm()
      this.$refs.employmentEducation.submitForm()
      this.$refs.taxInsurance.allSaveForm()
      this.$refs.citizenshipStatus.allSaveForm()
      this.$refs.listAllNames.allSaveForm()
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$Progress.start()
          this.$store.dispatch('citizenship/edit', this.form)
            .then(response => {
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    }
  }
}
</script>
