<template>
  <v-card v-if="hideSection" class="mt-3">
    <v-card-title>
      <span>{{ $t('title.application_military_service') }}</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()" :disabled="notApplicable">{{ $t('global.add') }}</v-btn>
    </v-card-title>
    <v-card-text>
      <div class="mb-3 mt-1">
        <el-checkbox v-model="notApplicable" @change="checkApplicable()">{{ $t('global.not_applicable') }}</el-checkbox>
      </div>
      <v-alert type="info" border="left" dense class="mb-3" v-if="$t('infobox.application_military_service') !== ''">
          {{ $t('infobox.application_military_service') }}
      </v-alert>
      <v-row dense class="customCollapseHeader">
        <v-col cols="6" sm="3">From Date</v-col>
        <v-col cols="6" sm="3">To Date</v-col>
        <v-col class="d-none d-sm-flex" cols="6">Branch of service</v-col>
      </v-row>
      <el-collapse v-model="activeNames" accordion v-show="!notApplicable">
        <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i" :id="'row' + form.id">
          <template slot="title">
            <div class="customHead" v-on:click="getProvince(form)">
              <v-row dense>
                <v-col cols="6" sm="3">
                  <el-tag type="primary" v-if="form.date_from !== null && form.date_from != undefined">{{ form.date_from | day }}</el-tag>
                </v-col>
                <v-col cols="6" sm="3">
                  <el-tag type="success" v-if="form.date_to !== null && form.date_to != undefined">{{ form.date_to | day }}</el-tag>
                </v-col>
                <v-col class="d-none d-sm-flex" cols="6"><div class="pl-2 pr-2">{{ form.branch }}</div></v-col>
              </v-row>
            </div>
          </template>
          <el-form :model="form" ref="form" label-position="top">
            <v-row dense>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'date_from')">
                <el-form-item :label="$t('application_military_service.date_from')" prop="date_from">
                  <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.date_from" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'date_to')">
                <el-form-item :label="$t('application_military_service.date_to')" prop="date_to">
                  <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.date_to" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'location')">
                <el-form-item v-show="sectionSlug === 'МilitaryService'" :label="$t('application_military_service.location')" prop="location">
                  <el-input v-model="form.location" @change="handleAutoSave(i, form)" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'country_id')">
                <el-form-item :label="$t('application_military_service.country_id')" prop="country_id">
                  <div class="el-select native-select">
                    <div class="el-input">
                      <select v-model="form.country_id" placeholder="Select" class="el-input__inner" @change="getProvince(form);handleAutoSave(i, form)">
                        <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                      </select>
                    </div>
                  </div>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'province')">
                <el-form-item :label="$t('application_military_service.province')" prop="province">
                  <div v-if="selectProvine" class="el-select native-select">
                    <div class="el-input">
                      <select v-model="form.province" placeholder="Select" @change="handleAutoSave(i, form)" class="el-input__inner">
                        <option v-for="(item, index) in province" v-bind:key="index" :value="item.province_name" :label="item.province_name">{{ item.province_name }}</option>
                      </select>
                    </div>
                  </div>
                  <el-input v-else v-model="form.province" @change="handleAutoSave(i, form)" style="width: 100%" />
                </el-form-item>
              </v-col>
            </v-row>
              <v-row dense v-show="sectionSlug === 'Military'">
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'city')">
                  <el-form-item :label="$t('application_military_service.city')" prop="city">
                    <el-input v-model="form.city" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'branch')">
                  <el-form-item :label="$t('application_military_service.branch')" prop="branch">
                    <el-input v-model="form.branch" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'places')">
                  <el-form-item :label="$t('application_military_service.places')" prop="places">
                    <el-input v-model="form.places" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'reason_for_end_service')">
                  <el-form-item :label="$t('application_military_service.reason_for_end_service')" prop="reason_for_end_service">
                    <el-input v-model="form.reason_for_end_service" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'rank')">
                  <el-form-item :label="$t('application_military_service.rank')" prop="rank">
                    <el-input v-model="form.rank" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
              </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(i,form)">{{ $t('global.save') }}</v-btn>
                <v-btn :loading="autoSave" :disabled="autoSave" color="error" small @click.native="deleteItem(form,i)">{{ $t('global.delete') }}</v-btn>
              </v-col>
            </v-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      selectProvine: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        date_from: null,
        date_to: null,
        branch: null,
        rank: null,
        places: null,
        reason_for_end_service: null,
        country_id: null,
        city: null,
        location: null,
        province: null
      },
      activeNames: -1,
      url: 'imms_table/application_military_service',
      deleteUrl: 'imms/application_military_service',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      applicableId: 'applicable/id',
      province: 'other/province'
    }),
    notApplicable: {
      get() {
        return this.$store.state.applicable.notApplicable.paramilitary_service
      },
      set(val) {
        this.$store.commit('applicable/set_paramilitary_service', val)
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    getProvince(item) {
      if (item.country_id) {
        item.country_id = parseFloat(item.country_id)
      }
      if (42 == item.country_id || 239 == item.country_id) {
        this.selectProvine = true
        this.phoneMasked = true
        this.$store.dispatch('other/getProvince', item.country_id)
      }
      else {
        this.phoneMasked = false
        this.selectProvine = false
      }
    },
    fetchData() {
      this.$Progress.start()
      var url = `table/application_military_service_view?filter=member_uid=${this.memberUID}&order=date_to DESC`
      this.$store.dispatch('store/get', url).then(response => {
        let vm = this

        if (vm.isDataValidation) {
          if (vm.notApplicable) {
            vm.hideSection = false
          }
          else {
            response.resource.forEach(function(item) {
              var form = {
                date_from: null,
                date_to: null,
                branch: null,
                rank: null,
                places: null,
                reason_for_end_service: null,
                country_id: null,
                city: null,
                location: null,
                province: null
              }

              if (vm.sectionSlug !== 'Military') {
                delete form.city
                delete form.branch
                delete form.places
                delete form.reason_for_end_service
                delete form.rank
              }

              if (vm.sectionSlug !== 'МilitaryService') {
                delete form.location
              }

              var obj = vm.checkFormItems(item, form)

              if (obj != 'empty') {
                vm.items.push(item)
              }
            })
            if (response.resource.length == 0) {
              vm.hideSection = true
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
        }
        else {
          vm.items = response.resource
        }
        this.$Progress.finish()
      })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        date_from: null,
        date_to: null,
        branch: null,
        rank: null,
        places: null,
        reason_for_end_service: null,
        country_id: null,
        city: null,
        location: null,
        province: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    checkApplicable() {
      var action = 'applicable/save'
      var that = this
      var data = {}
      data.paramilitary_service = that.notApplicable
      data.application_id = this.$route.params.id
      data.member_id = parseFloat(this.memberID)
      data.member_uid = this.memberUID
      if (that.applicableId != undefined) {
        data.id = that.applicableId
        action = 'applicable/edit'
      }
      this.$Progress.start()
      this.$store.dispatch(action, data).then(response => {
        this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        this.$Progress.finish()
      })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
          this.$Progress.fail()
        })
    }
  }
}
</script>
