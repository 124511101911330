import api from "./../plugins/axios";

let url = "table/application_employer";

export const state = {
  employers: [],
  employer: {}
};

export const getters = {
  all(state) {
    return state.employers;
  },
  getOne(state) {
    return state.employer;
  }
};

export const actions = {
  getAll({ commit }, id) {
    return api.get(url + "?filter=member_uid=" + id).then(
      (response) => {
        commit("GET_DATAS", response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  getById({ commit }, id) {
    return api.get(url + "?filter=member_uid=" + id).then(
      (response) => {
        commit("GET_DATA", response.data.resource[0]);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATA", err);
        return Promise.reject(err);
      }
    );
  },
  save({ commit }, data) {
    return api.post(url, { resource: data }).then(
      (response) => {
        commit("ADD_DATA", response.data);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  edit({ commit }, data) {
    return api.put(url + "/" + data.id, data).then(
      (response) => {
        commit("UPDATE_DATA", response.data);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  delete({ commit }, data) {
    return api.delete(url + "/" + data.id).then(
      (response) => {
        commit("DELETE_DATA", data);
        return Promise.resolve(response);
      },
      (error) => {
        commit("FAILURE_DATAS", error);
        return Promise.reject(error);
      }
    );
  }
};

export const mutations = {
  GET_DATAS(state, datas) {
    state.employers = datas;
  },
  GET_DATA(state, data) {
    state.employer = data;
  },
  ADD_DATA(state, data) {
    state.employers.push(data);
  },
  UPDATE_DATA(state, data) {
    let idx = state.employers.map((p) => p.id).indexOf(data.id);
    state.employers.splice(idx, 1, data);
  },
  FAILURE_DATAS(state) {
    state.employers = [];
  },
  FAILURE_DATA(state, data) {
    state.precresidence = {};
  },
  DELETE_DATA(state, data) {
    let idx = state.employers.map((p) => p.id).indexOf(data.id);
    state.employers.splice(idx, 1);
  }
};
