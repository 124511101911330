<template>
  <v-card class="mt-3">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()">Add</v-btn>
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{ sectionInfoBox }}
      </v-alert>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item :name="i+1" v-for="(item,i) in items" :key="i" :id="'row' + item.id">
          <template slot="title">
            <span class="el-col-title">{{item.trade_name}} - {{ item.legal_name }}</span>
          </template>
          <el-form :model="item" ref="form" label-position="top" class="mt-3 form">
            <v-card>
              <v-card-title>
                <span>Please include information concerning all previous immigration
                    applications (including a Certificat de sélection du Québec (CSQ – Québec Selection Certificate) or an
                    application to the Provincial Nominee Program) made by you and your spouse or common-law</span>
                <v-spacer />
              </v-card-title>
              <v-card-text class="p-n">
                <v-row dense class="medium-space">
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Company Name/Trade Name" prop="trade_name">
                      <el-input :id="'trade_name' + item.id" v-model="item.trade_name" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Corporate/Legal Name" prop="legal_name">
                      <el-input :id="'legal_name' + item.id" v-model="item.legal_name" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Company Address" prop="comp_add">
                      <el-input :id="'comp_add' + item.id" v-model="item.comp_add" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Website Addresss" prop="webadd">
                      <el-input :id="'webadd' + item.id" v-model="item.webadd" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Year Established" prop="est_year">
                      <el-input :id="'est_year' + item.id" type="number" v-model="item.est_year" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Type of Business Structure?" prop="bus_structure">
                      <el-select :id="'bus_structure' + item.id" v-model="item.bus_structure" style="width: 100%;">
                          <el-option value="Private">Private</el-option>
                          <el-option value="Public">Public</el-option>
                          <el-option value="Non-Profit">Non-Profit</el-option>
                      </el-select>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Federal Taxation Number (Business Number)" prop="federal_taxnum">
                      <el-input :id="'federal_taxnum' + item.id" type="number" v-model="item.federal_taxnum" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Workplace Health and Safety Compensation Commission number" prop="comm_number">
                      <el-input :id="'comm_number' + item.id" type="number" v-model="item.comm_number" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                </v-row>
                <v-row dense class="medium-space">
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Primary Contact – Name" prop="contact_name">
                      <el-input :id="'contact_name' + item.id" v-model="item.contact_name" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Primary Contact – Title" prop="title">
                      <el-input :id="'title' + item.id" v-model="item.title" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Primary Contact – Telephone Number" prop="tel_num">
                      <el-input :id="'tel_num' + item.id" type="number" v-model="item.tel_num" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Primary Contact – Email Address" prop="primary_email" :rules="[
                        { type: 'email', message: 'Please input correct email address', trigger: 'blur,change' }
                      ]">
                      <el-input :id="'primary_email' + item.id" v-model="item.primary_email" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                </v-row>
                <v-row dense class="medium-space">
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Alternate Contact – Name" prop="alt_contact">
                      <el-input :id="'alt_contact' + item.id" v-model="item.alt_contact" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Alternate Contact – Title" prop="alt_title">
                      <el-input :id="'alt_title' + item.id" v-model="item.alt_title" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Alternate Contact – Telephone Number" prop="alt_tel">
                      <el-input :id="'alt_tel' + item.id" type="number" v-model="item.alt_tel" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-3">
              <v-card-title>
                <span>Employee and Employment Position Information</span>
                <v-spacer />
              </v-card-title>
              <v-card-text class="p-n">
                <v-row dense class="medium-space">
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Employee Name" prop="employee_name">
                      <el-input :id="'employee_name' + item.id" v-model="item.employee_name" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Position Title " prop="position_title">
                      <el-input :id="'position_title' + item.id" v-model="item.position_title" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Start Date or Intended Start Date" prop="start_date">
                      <el-date-picker :id="'start_date' + item.id" value-format="yyyy-MM-dd" v-model="item.start_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Length of Employment" prop="emp_length">
                      <el-input :id="'emp_length' + item.id" type="number" v-model="item.emp_length" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Employment Benefits (health insurance, etc.)" prop="emp_benefits">
                      <el-input :id="'emp_benefits' + item.id" v-model="item.emp_benefits" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Starting Salary or Wage" prop="emp_salary">
                      <el-input :id="'emp_salary' + item.id" type="number" v-model="item.emp_salary" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Location of Employment" prop="emp_loc">
                      <el-input :id="'emp_loc' + item.id" v-model="item.emp_loc" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Unionized Position? " prop="authorised_position">
                      <el-select :id="'authorised_position' + item.id" v-model="item.authorised_position" style="width: 100%;">
                        <el-option value="Yes">Yes</el-option>
                        <el-option value="No">No</el-option>
                      </el-select>
                    </el-form-item>
                  </v-col>
                </v-row>
                <v-row dense class="medium-space">
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Canada/Newfoundland and Labrador Industry/Association Standards Required" prop="ind_association">
                      <el-select :id="'ind_association' + item.id" v-model="item.ind_association" style="width: 100%;">
                        <el-option value="Association Standards">Association Standards</el-option>
                        <el-option value="Apprenticeships Standards">Apprenticeships Standards</el-option>
                        <el-option value="Journeyperson Standards">Journeyperson Standards</el-option>
                        <el-option value="Industry Standards">Industry Standards</el-option>
                      </el-select>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="English Language Fluency Standards" prop="eng_lang">
                      <el-select :id="'eng_lang' + item.id" v-model="item.eng_lang" style="width: 100%;">
                        <el-option :value="itemS.name" :label="itemS.name" v-for="(itemS, index) in standards" v-bind:key="index">{{ itemS.name }}</el-option>
                      </el-select>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="French Language Fluency Standards" prop="french_lang">
                      <el-select :id="'french_lang' + item.id" v-model="item.french_lang" style="width: 100%;">
                        <el-option :value="itemSS.name" :label="itemSS.name" v-for="(itemSS, index) in standards" v-bind:key="index">{{ itemSS.name }}</el-option>
                      </el-select>
                    </el-form-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-text>
                <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm(i,item)">
                  {{ $t('global.save') }}
                </v-btn>
                <v-btn color="error" small @click.native="deleteOpen(item,i)">Delete</v-btn>
              </v-card-text>
            </v-card>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      standards: [
        { name: 'High-Speak' },
        { name: 'High-Read' },
        { name: 'High-Write' },
        { name: 'High-Listen' },
        { name: 'Moderate-Speak' },
        { name: 'Moderate-Read' },
        { name: 'Moderate-Write' },
        { name: 'Moderate-Listen' },
        { name: 'Basic-Speak' },
        { name: 'Basic-Read' },
        { name: 'Basic-Write' },
        { name: 'Basic-Listen' },
        { name: 'None-Speak' },
        { name: 'None-Read' },
        { name: 'None-Write' },
        { name: 'None-Listen' }
      ],
      edit: false,
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        trade_name: null,
        legal_name: null,
        webadd: null,
        est_year: null,
        bus_structure: null,
        federal_taxnum: null,
        comm_number: null,
        contact_name: null,
        title: null,
        primary_email: null,
        alt_contact: null,
        alt_title: null,
        tel_num: null,
        employee_name: null,
        position_title: null,
        start_date: null,
        emp_length: null,
        emp_benefits: null,
        emp_salary: null,
        emp_loc: null,
        authorised_position: null,
        ind_association: null,
        eng_lang: null,
        french_lang: null
      },
      activeNames: -1,
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: mapGetters({
    items: 'employer/all',
    country: 'other/country'
  }),
  mounted() {
    this.fetchData()
  },
  methods: {
    checkDate: function(event) {
      this.$emit('checkDate', event)
    },
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('employer/getAll', this.memberUID)
        .then(response => {
          if (this.datas !== undefined) this.checkItemsFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.form = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        trade_name: null,
        legal_name: null,
        webadd: null,
        est_year: null,
        bus_structure: null,
        federal_taxnum: null,
        comm_number: null,
        contact_name: null,
        title: null,
        primary_email: null,
        alt_contact: null,
        alt_title: null,
        tel_num: null,
        employee_name: null,
        position_title: null,
        start_date: null,
        emp_length: null,
        emp_benefits: null,
        emp_salary: null,
        emp_loc: null,
        authorised_position: null,
        ind_association: null,
        eng_lang: null,
        french_lang: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.form)
      var len = this.items.length
      this.activeNames = len
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true
          if (formObj.id != undefined) this.edit = true
          var actionMessage = ' save'
          var action = 'employer/save'
          if (this.edit) {
            actionMessage = ' edit'
            action = 'employer/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, formObj)
            .then(response => {
              this.$store.dispatch('toaster/add', { text: actionMessage, type: 'success' })
              this.$Progress.finish()
              this.fetchData()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.item.resetFields()
      this.nodeClear()
    },
    deleteOpen(item, index) {
      var that = this
      this.$confirm('Are you sure to delete this item?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        if (item.id == undefined) {
          that.items.splice(index, 1)
          that.activeNames = -1
        }
        else {
          this.$Progress.start()
          this.$store.dispatch('employer/delete', item)
            .then(response => {
              this.$store.dispatch('toaster/add', { text: 'delete', type: 'success' })
              this.$Progress.finish()
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Canceled', type: 'success' })
              this.$Progress.fail()
            })
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Canceled'
        })
      })
    }
  }
}
</script>
