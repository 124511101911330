<template>
  <v-card class="mt-3">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()">Add</v-btn>
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{ sectionInfoBox }}
      </v-alert>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item :name="i+1" v-for="(item,i) in items" :key="i" :id="'row' + item.id">
          <template slot="title">
            <div class="relative" v-on:click="getProvince(item)">
              <span class="el-col-title">{{item.workername}} - {{ item.position_offered }}</span>
            </div>
          </template>
          <el-form :model="item" ref="form" label-position="top" class="mt-3 form">
            <v-card>
              <v-card-title>
                <span>Employee Generic</span>
                <v-spacer />
              </v-card-title>
              <v-card-text class="p-n">
                <v-row dense class="medium-space">
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Name of worker" prop="workername">
                      <el-input :id="'workername' + item.id" v-model="item.workername" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Position offered" prop="position_offered">
                      <el-input :id="'position_offered' + item.id" v-model="item.position_offered" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-3">
              <v-card-title>
                <span>Employer Information</span>
                <v-spacer />
              </v-card-title>
              <v-card-text class="p-n">
                <v-row dense class="medium-space">
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Business name" prop="employee_name">
                      <el-input :id="'employee_name' + item.id" v-model="item.employee_name" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Name of business contact" prop="position_title">
                      <el-input :id="'position_title' + item.id" v-model="item.position_title" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Telephone" prop="tel">
                      <el-input :id="'tel' + item.id" type="number" v-model="item.tel" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Email" prop="email" :rules="[
                        { type: 'email', message: 'Please input correct email address', trigger: 'blur,change' }
                      ]">
                      <el-input :id="'email' + item.id" v-model="item.email" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Website" prop="website">
                      <el-input :id="'website' + item.id" v-model="item.website" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Street address" prop="strtadd">
                      <el-input :id="'strtadd' + item.id" v-model="item.strtadd" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="City/Town" prop="city">
                      <el-input :id="'city' + item.id" v-model="item.city" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Postal code" prop="postalcode">
                      <el-input :id="'postalcode' + item.id" v-model="item.postalcode" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-3">
              <v-card-title>
                <span>Head office information, if applicable</span>
                <v-spacer />
              </v-card-title>
              <v-card-text class="p-n">
                <v-row dense class="medium-space">
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Country" prop="head_country">
                      <div class="el-select native-select">
                        <div class="el-input">
                          <select :id="'head_country' + item.id" @change="getProvince(item)" v-model="item.head_country" placeholder="Select" class="el-input__inner">
                            <option v-for="(itemC, index) in country" v-bind:key="index" :value="itemC.id" :label="itemC.country_name">{{ itemC.country_name }}</option>
                          </select>
                        </div>
                      </div>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="City/Town" prop="head_city">
                      <el-input :id="'head_city' + item.id" v-model="item.head_city" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Street address" prop="head_strtadd">
                      <el-input :id="'head_strtadd' + item.id" v-model="item.head_strtadd" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Postal code" prop="head_postalcode">
                      <el-input :id="'head_postalcode' + item.id" v-model="item.head_postalcode" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" v-if="!phoneMasked">
                    <el-form-item label="Telephone" prop="head_tel">
                      <el-input :id="'head_tel' + item.id" type="number" v-model="item.head_tel" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" v-else>
                    <el-form-item label="Telephone" prop="head_tel">
                      <div class="el-input">
                        <masked-input
                          :id="'head_tel' + item.id"
                          v-model="item.head_tel"
                          :mask="phoneMask"
                          class="el-input__inner"
                        />
                      </div>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Email" prop="head_email" :rules="[
                        { type: 'email', message: 'Please input correct email address', trigger: 'blur,change' }
                      ]">
                      <el-input :id="'head_email' + item.id" v-model="item.head_email" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Website" prop="head_web">
                      <el-input :id="'head_web' + item.id" v-model="item.head_web" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Corporate registry number" prop="corpreg_number">
                      <el-input :id="'corpreg_number' + item.id" v-model="item.corpreg_number" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Canada Revenue Agency business number" prop="cadreg_numb">
                      <el-input :id="'cadreg_numb' + item.id" v-model="item.cadreg_numb" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Year business established" prop="year_establish">
                      <el-input :id="'year_establish' + item.id" type="number" v-model="item.year_establish" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Date business purchased" prop="purchase_date">
                      <el-date-picker :id="'purchase_date' + item.id" :picker-options="datePickerOptions" value-format="yyyy-MM-dd" v-model="item.purchase_date" v-on:click.native="checkDate($event)" style="width: 100%"></el-date-picker>
                    </el-form-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-text>
                <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm(i,item)">
                  {{ $t('global.save') }}
                </v-btn>
                <v-btn color="error" small @click.native="deleteOpen(item,i)">Delete</v-btn>
              </v-card-text>
            </v-card>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import MaskedInput from 'vue-text-mask'
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        workername: null,
        position_offered: null,
        employee_name: null,
        position_title: null,
        tel: null,
        email: null,
        website: null,
        strtadd: null,
        city: null,
        postalcode: null,
        head_country: null,
        head_city: null,
        corpreg_number: null,
        cadreg_numb: null,
        year_establish: null,
        purchase_date: null,
        head_strtadd: null,
        head_postalcode: null,
        head_tel: null,
        head_email: null,
        head_web: null
      },
      activeNames: -1,
      selectProvine: false,
      phoneMasked: false,
      phoneMask: ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      url: 'imms_table/application_employee_generic',
      state: 'employeeGeneric',
      loading: false,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date()
        }
      }
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  components: {
    MaskedInput
  },
  computed: {
    ...mapGetters({
      country: 'other/country'
    }),
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    getProvince(item) {
      if (42 == item.head_country || 239 == item.head_country) {
        this.phoneMasked = true
        this.selectProvine = true
      }
      else {
        this.phoneMasked = false
        this.selectProvine = false
      }
    },
    checkDate: function(event) {
      this.$emit('checkDate', event)
    },
    fetchData() {
      this.$Progress.start()
      var url = 'table/application_employee_generic?filter=member_uid=' + this.memberUID
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) this.checkItemsFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.form = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        workername: null,
        position_offered: null,
        employee_name: null,
        position_title: null,
        tel: null,
        email: null,
        website: null,
        strtadd: null,
        city: null,
        postalcode: null,
        head_country: null,
        head_city: null,
        corpreg_number: null,
        cadreg_numb: null,
        year_establish: null,
        purchase_date: null,
        head_strtadd: null,
        head_postalcode: null,
        head_tel: null,
        head_email: null,
        head_web: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.form)
      var len = this.items.length
      this.activeNames = len
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true
          if (formObj.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.item.resetFields()
      this.nodeClear()
    }
  }
}
</script>
