<template>
  <v-card class="mt-3">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()">Add</v-btn>
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{ sectionInfoBox }}
      </v-alert>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item :name="i+1" v-for="(item,i) in items" :key="i" :id="'row' + item.id">
          <template slot="title">
            <span class="el-col-title">{{item.compname}}</span>
          </template>
          <el-form :model="item" ref="form" label-position="top" class="mt-3 form">
            <v-card>
              <v-card-title>
                <span>Skilled wolker</span>
                <v-spacer />
              </v-card-title>
              <v-card-text class="p-n">
                <v-row dense class="medium-space">
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Skilled or Semi-Skilled Worker" prop="semi">
                      <el-select :id="'semi' + item.id" v-model="item.semi" style="width: 100%">
                        <el-option value="Skilled Worker" label="Skilled Worker">Skilled Worker</el-option>
                        <el-option value="Semi-Skilled Worker" label="Semi-Skilled Worker">Semi-Skilled Worker</el-option>
                      </el-select>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Company name" prop="compname">
                      <el-input :id="'compname' + item.id" v-model="item.compname" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Name of company contact" prop="comp_contact">
                      <el-input :id="'comp_contact' + item.id" v-model="item.comp_contact" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Title of employer contact" prop="emp_contact">
                      <el-input :id="'emp_contact' + item.id" v-model="item.emp_contact" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Employer's address" prop="emp_address">
                      <el-input :id="'emp_address' + item.id" v-model="item.emp_address" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="City/Town" prop="city">
                      <el-input :id="'city' + item.id" v-model="item.city" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Province/Territory" prop="canada_province">
                      <div class="el-select native-select">
                        <div class="el-input">
                          <select :id="'canada_province' + item.id" v-model="item.canada_province" placeholder="Select" class="el-input__inner">
                            <option v-for="(pro, index) in province" v-bind:key="index" :value="pro.id" :label="pro.province_name">{{ pro.province_name }}</option>
                          </select>
                        </div>
                      </div>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Postal code" prop="postal_code">
                      <el-input :id="'postal_code' + item.id" v-model="item.postal_code" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Employer's website" prop="emp_website">
                      <el-input :id="'emp_website' + item.id" v-model="item.emp_website" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Employer's phone" prop="emp_phone">
                      <el-input :id="'emp_phone' + item.id" v-model="item.emp_phone" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Employer's fax" prop="fax">
                      <el-input :id="'fax' + item.id" v-model="item.fax" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Employer's email" prop="emp_email">
                      <el-input :id="'emp_email' + item.id" v-model="item.emp_email" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Head office address (if applicable)" prop="emp_headoffice">
                      <el-input :id="'emp_headoffice' + item.id" v-model="item.emp_headoffice" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Number of employees in Alberta" prop="num_emp">
                      <el-input :id="'num_emp' + item.id" v-model="item.num_emp" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Year business established:" prop="year_of_business">
                      <el-input :id="'year_of_business' + item.id" v-model="item.year_of_business" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="How many Temporary Foreign Workers currently work for the employer in Alberta?" prop="noforeign">
                      <el-input :id="'noforeign' + item.id" v-model="item.noforeign" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Describe the company" prop="description">
                      <el-input :id="'description' + item.id" v-model="item.description" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Address of employment of candidate: " prop="add_of_candidate">
                      <el-input :id="'add_of_candidate' + item.id" v-model="item.add_of_candidate" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-3">
              <v-card-title>
                <span>Candidate's Job details</span>
                <v-spacer />
              </v-card-title>
              <v-card-text class="p-n">
                <v-row dense class="medium-space">
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Is this a permanentand full-time job?" prop="fulltime">
                      <el-select :id="'fulltime' + item.id" v-model="item.fulltime" placeholder="Select" style="width: 100%;">
                        <el-option value="Yes">Yes</el-option>
                        <el-option value="No">No</el-option>
                      </el-select>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Is this a union position?" prop="businesssta">
                      <el-select :id="'businesssta' + item.id" v-model="item.businesssta" placeholder="Select" style="width: 100%;">
                        <el-option value="Yes">Yes</el-option>
                        <el-option value="No">No</el-option>
                      </el-select>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Union name" prop="unionname">
                      <el-input :id="'unionname' + item.id" v-model="item.unionname" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Current wage or salary hourly/Annually (CAD )" prop="wage">
                      <el-input :id="'wage' + item.id" v-model="item.wage" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="How long has this candidate been working for this employer?" prop="work_year">
                      <el-input :id="'work_year' + item.id" v-model="item.work_year" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Describe how the employer hired, or learned about, the candidate" prop="hire">
                      <el-input :id="'hire' + item.id" v-model="item.hire" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Attached all required and supporting document outlined in the document checklist?" prop="file">
                      <el-input :id="'file' + item.id" type="file" v-model="item.file" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="I have attached all required and supporting document outlined in the document checklist?" prop="attached">
                      <el-select :id="'attached' + item.id" v-model="item.attached" placeholder="Select" style="width: 100%;">
                        <el-option value="Yes">Yes</el-option>
                        <el-option value="No">No</el-option>
                      </el-select>
                    </el-form-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-text>
                <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm(i,form)">
                  {{ $t('global.save') }}
                </v-btn>
                <v-btn color="error" small @click.native="deleteOpen(form,i)">Delete</v-btn>
              </v-card-text>
            </v-card>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        semi: null,
        compname: null,
        emp_contact: null,
        comp_contact: null,
        emp_address: null,
        city: null,
        canada_province: null,
        postal_code: null,
        emp_website: null,
        emp_phone: null,
        fax: null,
        emp_email: null,
        emp_headoffice: null,
        num_emp: null,
        year_of_business: null,
        noforeign: null,
        description: null,
        add_of_candidate: null,
        fulltime: null,
        businesssta: null,
        unionname: null,
        wage: null,
        work_year: null,
        hire: null,
        file: null,
        attached: null
      },
      activeNames: -1,
      url: 'imms_table/prov_alberta_skilled_worker',
      state: 'skilledWolker',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      province: 'other/province'
    }),
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    }
  },
  mounted() {
    this.fetchData()
    this.$store.dispatch('other/getProvince', 42)
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/prov_alberta_skilled_worker?filter=member_uid=${this.memberUID}`
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) this.checkItemsFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.form = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        semi: null,
        compname: null,
        emp_contact: null,
        comp_contact: null,
        emp_address: null,
        city: null,
        canada_province: null,
        postal_code: null,
        emp_website: null,
        emp_phone: null,
        fax: null,
        emp_email: null,
        emp_headoffice: null,
        num_emp: null,
        year_of_business: null,
        noforeign: null,
        description: null,
        add_of_candidate: null,
        fulltime: null,
        businesssta: null,
        unionname: null,
        wage: null,
        work_year: null,
        hire: null,
        file: null,
        attached: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.form)
      var len = this.items.length
      this.activeNames = len
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true
          if (formObj.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>
