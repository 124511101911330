<template>
  <div class="mt-3" v-if="hideSection">
    <v-card>
      <v-card-title>
        <span>Witnessed or Participated</span>
        <v-spacer />
        <v-btn color="primary" dark small @click.native="saveForm()" :disabled="notApplicable === 'No' || notApplicable === '' ? true : false">{{ $t('global.add') }}</v-btn>
      </v-card-title>
      <v-card-text class="p-n">
        <div class="mb-3 mt-1">
          Have you ever witnessed or participated in the ill treatment of prisoners or civilians, looting or desecration of religious buildings?
          <el-radio-group v-model="notApplicable" @change="checkApplicable()" class="ml-3">
            <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
            <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
          </el-radio-group>
        </div>
        <v-row dense class="customCollapseHeader">
          <v-col cols="6" sm="3">From Date</v-col>
          <v-col cols="6" sm="3">To Date</v-col>
          <v-col class="d-none d-sm-flex" sm="6">Location</v-col>
        </v-row>
        <el-collapse v-model="activeNames" accordion v-if="notApplicable === 'Yes'">
          <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i" :id="'row' + form.id">
            <template slot="title">
              <div class="customHead" v-on:click="getProvince(form)">
                <v-row dense>
                  <v-col cols="6" sm="3">
                    <el-tag type="primary" v-if="form.from_date !== null && form.from_date != undefined">{{ form.from_date | day }}</el-tag>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <el-tag type="success" v-if="form.to_date !== null && form.to_date != undefined">{{ form.to_date | day }}</el-tag>
                  </v-col>
                  <v-col sm="6" class="d-none d-sm-flex"><div class="pl-2 pr-2">{{ form.location }}</div></v-col>
                </v-row>
              </div>
            </template>
            <el-form :model="form" ref="form" label-position="top">
              <v-row dense>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'date_from')">
                  <el-form-item :label="$t('application_membership_associations.date_from')" prop="from_date">
                    <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.from_date" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'date_to')">
                  <el-form-item :label="$t('application_membership_associations.date_to')" prop="to_date">
                    <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.to_date" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'location')">
                  <el-form-item :label="$t('application_membership_associations.location')" prop="location">
                    <el-input v-model="form.location" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'country_id')">
                  <el-form-item :label="$t('application_membership_associations.country_id')" prop="country_id">
                    <div class="el-select native-select">
                      <div class="el-input">
                        <select v-model="form.country_id" placeholder="Select" class="el-input__inner" @change="getProvince(form);handleAutoSave(i, form)">
                          <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                        </select>
                      </div>
                    </div>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'province')">
                  <el-form-item :label="$t('application_membership_associations.province')" prop="province">
                    <div v-if="selectProvine" class="el-select native-select">
                      <div class="el-input">
                        <select v-model="form.province" placeholder="Select" @change="handleAutoSave(i, form)" class="el-input__inner">
                          <option v-for="(item, index) in province" v-bind:key="index" :value="item.province_name" :label="item.province_name"></option>
                        </select>
                      </div>
                    </div>
                    <el-input v-else v-model="form.province" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" v-if="dataValidationItems(form, 'details')">
                  <el-form-item :label="$t('application_membership_associations.details')" prop="details">
                    <el-input v-model="form.details" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" class="mt-2">
                  <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(i,form)">
                    {{ $t('global.save') }}
                  </v-btn>
                  <v-btn :loading="autoSave" :disabled="autoSave" color="error" small @click.native="deleteItem(form,i)">{{ $t('global.delete') }}</v-btn>
                </v-col>
              </v-row>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        country_id: null,
        from_date: null,
        to_date: null,
        province: null,
        location: null,
        details: null,
        sec_org_id: parseFloat(localStorage.getItem('org_id'))
      },
      activeNames: -1,
      url: 'imms_table/application_witness_participate',
      deleteUrl: 'imms/application_witness_participate',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      applicableId: 'applicable/id',
      country: 'other/country',
      province: 'other/province'
    }),
    notApplicable: {
      get() {
        return this.$store.state.applicable.notApplicable.witness_participated
      },
      set(val) {
        this.$store.commit('applicable/set_witness_participated', val)
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/application_witness_participate_view?filter=member_uid=${this.memberUID}&order=from_date DESC, to_date DESC`
      this.$store.dispatch('store/get', url).then(response => {
        let vm = this

        if (vm.isDataValidation) {
          if (vm.notApplicable == 'No') {
            vm.hideSection = false
          }
          else {
            response.resource.forEach(function(item) {
              var form = {
                country_id: null,
                from_date: null,
                to_date: null,
                province: null,
                location: null,
                details: null
              }

              var obj = vm.checkFormItems(item, form)

              if (obj != 'empty') {
                vm.items.push(item)
              }
            })
            if (response.resource.length == 0) {
              vm.hideSection = true
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
        }
        else {
          vm.items = response.resource
        }
        this.$Progress.finish()
      })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    checkApplicable() {
      var action = 'applicable/save'
      var that = this
      var data = {}
      data.witness_participated = that.notApplicable
      data.application_id = this.$route.params.id
      data.member_id = parseFloat(this.memberID)
      data.member_uid = this.memberUID
      if (that.applicableId != undefined) {
        data.id = that.applicableId
        action = 'applicable/edit'
      }
      this.$Progress.start()
      this.$store.dispatch(action, data).then(response => {
        this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        this.$Progress.finish()
      })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        sec_org_id: parseFloat(localStorage.getItem('org_id')),
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        country_id: null,
        from_date: null,
        to_date: null,
        province: null,
        location: null,
        details: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    }
  }
}
</script>
