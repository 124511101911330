import _ from "lodash";

export default {
  data() {
    return {
      autoSave: false,
      validation: true,
      phoneMasked: false,
      validationItems: [],
      secOrgID: this.$orgId,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        }
      }
    };
  },
  computed: {
    isDataValidation: function() {
      return this.datas && this.datas.length > 0 ? true : false;
    }
  },
  methods: {
    disabledSymbols(event) {},
    checkRow(data) {
      let vm = this;
      let hideField = {};
      let array = [];

      if (data.deceased) {
        array = vm.deseadedFields;
      }
      else {
        array = vm.fields;
      }

      for (let key in data) {
        let index = array.indexOf(key);

        if (index > -1) {
          if (vm.empty(data[key])) {
            hideField[key] = data[key];
          }
        }
      }
      let count = Object.keys(hideField).length;

      if (count == 0) {
        return "empty";
      }
      else {
        hideField.id = data.id;
        return hideField;
      }
    },
    dataValidation(data, key) {
      if (key == undefined) {
        return true;
      }
      else {
        if (this.isDataValidation) {
          return this.isCheckValidation(data, key);
        }
        else {
          return true;
        }
      }
    },
    isCheckValidation(data, key) {
      let index = this.validationItems.map((p) => parseInt(p.id)).indexOf(parseInt(data.id));

      if (index == -1) {
        return false;
      }
      else {
        let value = this.validationItems[index];
        return key in value;
      }
    },
    empty(e) {
      switch (e) {
        case "":
        case null:
        case typeof this == "undefined":
          return true;
        default:
          return false;
      }
    },
    checkDate(event) {
      if (event.target) event.target.readOnly = true;
    },
    resetForm() {
      this.$refs.form.resetFields();
      this.nodeClear();
    },
    handleAutoSave(index, form) {
      var that = this;
      that.submitData(index, that, form);
    },
    submitData: _.debounce((index, that, form) => {
      var action = "all_stores/save";
      var edit = false;
      if (form.imms_uuid !== undefined && form.imms_uuid !== null && form.imms_uuid !== '') {
        action = "store/edit";
        edit = true;
      }
      that.loading = true;

      if (form.sec_org_id == undefined && form.sec_org_id == null) {
        form.sec_org_id = that.$orgId;
      }

      that.$store
        .dispatch(action, { url: that.url, data: form, slug: that.state })
        .then((response) => {
          if (!edit && response.data.resource !== undefined && response.data.resource.length > 0) {
            form.id = response.data.resource[0].id;
            form.imms_uuid = response.data.resource[0].imms_uuid;
          }
          that.loading = false;
        })
        .catch((err) => {
          that.loading = true;
        });
    }, 2000),
    async deleteOpen(item, index) {
      this.$confirm("Are you sure to delete this item?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(async() => {
        if (item.id) {
          try {
            let url = `${this.url}/${item.id}`
            if (item.imms_uuid !== undefined && item.imms_uuid !== null && this.url.includes('imms_table')) {
              url = `${this.url}/${item.imms_uuid}`
            }
            await this.$api.delete(url)
            this.items.splice(index, 1);
            this.activeNames = -1;
            this.$store.dispatch('toaster/add', { text: 'Deleted', type: 'success' })
          }
          catch (err) {
            console.error(err);
            this.$store.dispatch('toaster/add', { text: `Cannot delete item. Please contact supporting team!`, type: 'error' })
          }
        }
        else {
          this.items.splice(index, 1);
          this.activeNames = -1;
        }
      })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Canceled"
          });
        });
    },
    async deleteItem(item, index) {
      this.$confirm("Are you sure to delete this item?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(async() => {
        if (item.id) {
          try {
            let url = `${this.deleteUrl}/${item.id}`
            if (item.imms_uuid !== undefined && item.imms_uuid !== null && this.url.includes('imms')) {
              url = `${this.deleteUrl}/${item.imms_uuid}`
            }
            await this.$api.delete(url)
            this.items.splice(index, 1);
            this.activeNames = -1;
            this.$store.dispatch('toaster/add', { text: 'Deleted', type: 'success' })
          }
          catch (err) {
            console.error(err);
            this.$store.dispatch('toaster/add', { text: `Cannot delete item. Please contact supporting team!`, type: 'error' })
          }
        }
        else {
          this.items.splice(index, 1);
          this.activeNames = -1;
        }
      })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Canceled"
          });
        });
    },
    handleAutoSaveCitizenship(form) {
      var that = this;
      this.submitDataCitizenship(that, form);
    },
    submitDataCitizenship: _.debounce((that, form) => {
      var action = "store/save";
      var edit = false;
      if (form.id !== undefined && form.id !== null) {
        action = "store/edit";
        edit = true;
      }
      that.$store.dispatch(action, { url: "table/application_citizenship_calculator", data: form }).then((response) => {
        if (!edit && response.data.resource !== undefined && response.data.resource[0] !== undefined) {
          form.id = response.data.resource[0].id;
        }
      });
    }, 2000)
  },
  watch: {
    formGeneric: {
      handler(newval, oldval) {
        if (newval == oldval) {
          var formGeneric = newval;
          var that = this;
          var uci = formGeneric.uci_number;
          var save = false;

          if (formGeneric.mailing_address) {
            formGeneric.res_apartment_unit = formGeneric.cur_apartment_unit;
            formGeneric.res_streetno = formGeneric.cur_streetno;
            formGeneric.res_street_name = formGeneric.cur_street_name;
            formGeneric.res_city = formGeneric.cur_city;
            formGeneric.res_country_id = formGeneric.cur_country_id;
            formGeneric.res_province = formGeneric.cur_province;
            formGeneric.res_postal_code = formGeneric.cur_postal_code;
            formGeneric.res_district = formGeneric.cur_district;
          }

          if (uci === null || uci === "" || uci == undefined) {
            save = true;
          }
          else if (uci.toString().length === 10 || uci.toString().length === 8) {
            save = true;
          }
          else {
            save = false;
          }

          if (save) {
            if (formGeneric.uci_number !== null && formGeneric.uci_number !== "") {
              formGeneric.uci_number = parseFloat(formGeneric.uci_number);
            }
            else {
              formGeneric.uci_number = null;
            }
            formGeneric.application_id = parseFloat(that.$route.params.id);

            that.$store.dispatch("generic/edit", formGeneric);
          }
        }
      },
      deep: true
    },
    formCitizenship: {
      handler(newval, oldval) {
        if (newval == oldval) {
          var formGeneric = newval;
          var that = this;
          that.$store.dispatch("citizenship/edit", formGeneric);
        }
      },
      deep: true
    },
    formMinor: {
      handler(newval, oldval) {
        if (newval == oldval) {
          var formMinor = newval;
          var that = this;
          if (formMinor.id != undefined) that.edit = true;
          var action = "all_stores/save";
          if (that.edit) {
            action = "all_stores/edit";
          }
          that.$store.dispatch(action, { url: that.url, data: formMinor, slug: that.state }).then((response) => {
            if (!that.edit) formMinor.id = response.data.resource[0].id;
          });
        }
      },
      deep: true
    }
  }
};
