<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
        <v-btn :loading="loading" color="primary" dark small @click.native="submitForm()">Save</v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-2" v-if="sectionInfoBox">{{sectionInfoBox}}</v-alert>

        <el-form :model="form" ref="form" label-position="top" class="form">
          <v-row dense>
            <v-col cols="12">
              <div
                class="title font-weight-black text-uppercase mb-3"
              >ABOUT YOU AND, IF APPLICABLE, YOUR CO-SIGNER</div>
              <div>
                <strong>1. A) Your full name</strong>
              </div>
            </v-col>
            <v-col cols="12" sm="9">
              <v-row dense>
                <v-col cols="12" sm="6">
                  <el-form-item label="Family name">
                    <el-input v-model="form.node.family_name" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6">
                  <el-form-item label="Given name (s)">
                    <el-input v-model="form.node.given_name" />
                  </el-form-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <div>
                <strong>&nbsp;&nbsp; B) Is your spouse or common-law partner co-signing the undertaking?</strong>
              </div>
            </v-col>
            <v-col cols="12" sm="12">
              <v-row dense class="mt-3">
                <v-col cols="12" sm="12">
                  <el-radio v-model="form.node.is_spouse_common_law" label="No">No</el-radio>
                  <el-radio v-model="form.node.is_spouse_common_law" label="Yes">Yes</el-radio>
                  <v-icon small>play_arrow</v-icon>
                  <span>Give the following details.</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="12">
              <div>
                <strong>Full name of your spouse of common-law partner</strong>
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="mt-3">
              <v-row dense>
                <v-col cols="12" sm="4">
                  <el-form-item label="Family name">
                    <el-input v-model="form.node.spouse_family_name" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="4">
                  <el-form-item label="Given name (s)">
                    <el-input v-model="form.node.spouse_given_name" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="4">
                  <el-form-item label="Date of birth">
                    <el-date-picker
                      type="date"
                      v-model="form.node.spouse_birth_date"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                  </el-form-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-2"></v-divider>
          <v-row dense>
            <v-col cols="12">
              <div
                class="title font-weight-black text-uppercase mb-3"
              >YOUR FAMILY MEMBERS AND PERSONS INCLUDED IN UNDERTAKINGS IN EFFECT OR NOT YET IN EFFECT SIGNED BY YOU AND, IF APPLICABLE, YOUR CO-SIGNER</div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="10">
              <div>
                <strong>2. You</strong>
              </div>
            </v-col>
            <v-col cols="2" class="text-right">
              <el-input
                size="mini"
                type="number"
                class="text-right"
                v-model="form.node.undertaking_you"
              />
            </v-col>
          </v-row>
          <!-- Section 3 -->
          <v-row dense>
            <v-col cols="10">
              <div>
                <strong>3. A) Current undertaking</strong>
              </div>
              <div
                class="pl-3 mt-3"
              >Number of persons included in box #3 of the Generic Application Form for Canada (IMM 0008), under section Application Details.</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="pt-4">
                <el-input
                  size="mini"
                  type="number"
                  class="text-right"
                  v-model="form.node.undertaking_current"
                />
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="10">
              <div>
                <strong>&nbsp;&nbsp; B) Previous undertaking</strong>
              </div>
              <div
                class="pl-3 mt-3"
              >i) Number of persons included in previous undertakings you signed as a sponsor and that are still in effect. Provide details on the back of this page.</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="pt-4">
                <el-input
                  size="mini"
                  type="number"
                  class="text-right"
                  v-model="form.node.undertaking_prev_1"
                />
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="10">
              <div
                class="pl-3 mt-3"
              >ii) Number of persons included in previous undertakings you signed as a sponsor and that are not yet in effect (persons who have not yet become permanent residents). Provide details on the back of this page.</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="pt-3">
                <el-input
                  size="mini"
                  type="number"
                  class="text-right"
                  v-model="form.node.undertaking_prev_2"
                />
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="10">
              <div
                class="pl-3 mt-3"
              >iii) Number of persons included in previous undertakings you co-signed, where these undertakings are still in effect. Provide details on the back of this page</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="pt-3">
                <el-input
                  size="mini"
                  type="number"
                  class="text-right"
                  v-model="form.node.undertaking_prev_3"
                />
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="10">
              <div
                class="pl-3 mt-3"
              >iv) Number of persons included in previous undertakings you co-signed, where these undertakings are not yet in effect (persons who have not yet become permanent residents). Provide details on the back of this page</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="pt-3">
                <el-input
                  size="mini"
                  type="number"
                  class="text-right"
                  v-model="form.node.undertaking_prev_4"
                />
              </div>
            </v-col>
          </v-row>
          <!-- Section4 -->
          <v-row dense class="mt-3">
            <v-col cols="12">
              <div>
                <strong>4. Complete this section only if your spouse or common-law partner is co-signing the undertaking</strong>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="10">
              <div class="pl-3 mt-3">i) Number of persons included in previous undertakings your spouse or common-law partner signed as a sponsor and that are still in effect. Do not include persons already accounted for in 2. or 3.B(iii). Provide details on the back of this page.</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="pt-3">
                <el-input
                  size="mini"
                  type="number"
                  class="text-right"
                  v-model="form.node.undertaking_co_signing_1"
                />
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="10">
              <div class="pl-3 mt-3">ii) Number of persons included in previous undertakings your spouse or common-law partner signed as a sponsor and that are not yet in effect (persons who have not yet become permanent residents). Do not include persons already accounted for in question 3.B(iv). Provide details on the back of this page.</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="pt-3">
                <el-input
                  size="mini"
                  type="number"
                  class="text-right"
                  v-model="form.node.undertaking_co_signing_2"
                />
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="10">
              <div class="pl-3 mt-3">iii) Number of persons included in previous undertakings your spouse or common-law partner co-signed, where these undertakings are still in effect. Do not include persons already accounted for in question 3.B(i). Provide details on the back of this page.</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="pt-3">
                <el-input
                  size="mini"
                  type="number"
                  class="text-right"
                  v-model="form.node.undertaking_co_signing_3"
                />
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="10">
              <div class="pl-3 mt-3">iv) Number of persons included in previous undertakings your spouse or common-law partner co-signed, where these undertakings are not yet in effect (persons who have not yet become permanent residents). Do not include persons already accounted for in question 3.B(ii). Provide details on the back of this page.</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="pt-3">
                <el-input
                  size="mini"
                  type="number"
                  class="text-right"
                  v-model="form.node.undertaking_co_signing_4"
                />
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="10">
              <div>
                <strong>5. Your spouse or common-law partner, if not included in 3. above.</strong> Provide details on the back of this page if he or she is not co-signing the undertaking
              </div>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="pt-2">
                <el-input
                  size="mini"
                  type="number"
                  class="text-right"
                  v-model="form.node.five_1"
                />
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="10">
              <div>
                <strong>6. Every other family member</strong> not included above and dependent on you financially, whether they are living with you or not. Enter their number in the box on the right and provide details on the back of this page.
              </div>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="pt-2">
                <el-input size="mini" type="number" class="text-right" v-model="form.node.six_1" />
              </div>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-2"></v-divider>
          <v-row dense class="mt-3">
            <v-col cols="8">
              <div>
                <strong>7. Total number of persons for the purpose of determining your	minimum necessary income.</strong>
              </div>
            </v-col>
            <v-col cols="2">
              Add the numbers entered in the boxes 2, 3, 4, 5, and 6 above 	and write the total here.
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="pt-2">
                <el-input size="mini" type="number" class="text-right" v-model="seven" />
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="8">
              <div>
                <strong>8. Minimum necessary income.</strong>
              </div>
            </v-col>
            <v-col cols="2">
              See instructions on how to complete this form for details
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="pt-2">
                <el-input size="mini" type="number" class="text-right" v-model="form.node.eight" />
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="8">
              <div>
                <strong>9. Total income available to sponsor</strong>
              </div>
            </v-col>
            <v-col cols="2">
              Add amounts in boxes 14 and 19 and enter total here
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="pt-2">
                <el-input size="mini" type="number" class="text-right" v-model="form.node.nine" />
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-3" v-for="(item, index) in form.node.items" :key="index">
            <v-col cols="12">
              <div class="mb-3">{{ item.label }}</div>
              <v-card>
                <table class="datatable table tbpad">
                  <thead>
                    <tr>
                      <th>Family name</th>
                      <th>Given name(s)</th>
                      <th>Date of birth</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(relation, subIndex) in item.rows" :key="'rel'+subIndex">
                      <td>
                        <el-input size="medium" type="text" v-model="relation.family_name" />
                      </td>
                      <td>
                        <el-input size="medium" type="text" v-model="relation.given_name" />
                      </td>
                      <td>
                        <el-date-picker
                          size="medium"
                          type="date"
                          v-model="relation.birth_day"
                          value-format="yyyy-MM-dd"
                          style="width: 100%"
                        ></el-date-picker>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-card>
            </v-col>
          </v-row>

          <v-row dense class="mt-3">
            <v-col cols="12">
              <div class="mt-3">
                <strong class="mb-3">10. Your current situation</strong>
                <p>Check box below which best describes your current situation.</p>
              </div>
            </v-col>
            <v-col cols="12">
              <table class="tbpad">
                <tr>
                  <td width="200"><el-checkbox v-model="form.node.unemployed">Unemployed</el-checkbox></td>
                  <td>
                    <label>Give details of your current situation and indicate how you support yourself. If you need additional space, provide details on a separate sheet of paper.</label>
                    <el-input size="mini" v-model="form.node.unemployed_desc" :row="5" />
                  </td>
                </tr>
                <tr>
                  <td width="200"><el-checkbox v-model="form.node.employed">Employed</el-checkbox></td>
                  <td><label>You are working for an employer and are paid a salary, wages or a commission for your services.</label></td>
                </tr>
                <tr>
                  <td width="200"><el-checkbox v-model="form.node.self_employed">Self-Employed</el-checkbox></td>
                  <td><label>You have your own business, practice a trade or a profession and conduct activities for a profit.</label></td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="loading" color="primary" dark small @click.native="submitForm()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    sectionId: {
      type: String
    },
    layout: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      form: {
        member_uid: this.memberUID,
        member_id: this.memberID,
        sec_org_id: parseInt(localStorage.getItem("org_id")),
        node: {
          is_spouse_common_law: null,
          family_name: null,
          given_name: null,
          spouse_family_name: null,
          spouse_given_name: null,
          spouse_birth_date: null,
          undertaking_you: null,
          undertaking_current: null,
          undertaking_prev_1: null,
          undertaking_prev_2: null,
          undertaking_prev_3: null,
          undertaking_prev_4: null,
          undertaking_co_signing_1: null,
          undertaking_co_signing_2: null,
          undertaking_co_signing_3: null,
          undertaking_co_signing_4: null,
          five_1: null,
          siz_1: null,
          seven: null,
          nine: null,
          eight: null,
          items: [
            {
              label: '3. B. i) Persons included in previous undertakings you signed as a sponsor and that are still in effect',
              rows: [
                {
                  birth_day: "",
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                }
              ]
            },
            {
              label: '3. B. ii) Persons included in previous undertakings you signed as a sponsor and that are not yet in effect.',
              rows: [
                {
                  birth_day: "",
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                }
              ]
            },
            {
              label: '3. B. iii) Persons included in previous undertakings you co-signed and that are still in effect.',
              rows: [
                {
                  birth_day: "",
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                }
              ]
            },
            {
              label: '3. B. iv) Persons included in previous undertakings you co-signed and that are not yet in effect.',
              rows: [
                {
                  birth_day: "",
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                }
              ]
            },
            {
              label: '4. i) Persons included in previous undertakings your spouse or common-law partner signed as a sponsor and that are still in effect.',
              rows: [
                {
                  birth_day: "",
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                }
              ]
            },
            {
              label: '4. ii) Persons included in previous undertakings your spouse or common-law partner signed as a sponsor and that are not yet in effect.',
              rows: [
                {
                  birth_day: "",
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                }
              ]
            },
            {
              label: '4. iii) Persons included in previous undertakings your spouse or common-law partner co-signed and that are still in effect.',
              rows: [
                {
                  birth_day: "",
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                }
              ]
            },
            {
              label: '4. iv) Persons included in previous undertakings your spouse or common-law partner co-signed and that are not yet in effect.',
              rows: [
                {
                  birth_day: "",
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                }
              ]
            },
            {
              label: '5. Your spouse or common-law partner, if he or she is not co-signing the undertaking.',
              rows: [
                {
                  birth_day: "",
                  family_name: null,
                  given_name: null
                }
              ]
            },
            {
              label: '6. Every other family member not listed above and dependent on you financially, whether they are living with you or not.',
              rows: [
                {
                  birth_day: "",
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                },
                {
                  birth_day: null,
                  family_name: null,
                  given_name: null
                }
              ]
            }
          ]
        },
        alias: "financial_evaluation_first"
      },
      loading: false,
      url: 'imms_table/application_sponsorship_relationship'
    }
  },
  watch: {
    seven: function(val) {
      this.form.node.seven = val
    }
  },
  computed: {
    seven: function() {
      var one = this.form.node.undertaking_you == null ? 0 : parseFloat(this.form.node.undertaking_you)
      var three = this.form.node.undertaking_current == null ? 0 : parseFloat(this.form.node.undertaking_current)
      var four = this.form.node.undertaking_prev_1 == null ? 0 : parseFloat(this.form.node.undertaking_prev_1)
      var five = this.form.node.undertaking_prev_2 == null ? 0 : parseFloat(this.form.node.undertaking_prev_2)
      var six = this.form.node.undertaking_prev_3 == null ? 0 : parseFloat(this.form.node.undertaking_prev_3)
      var seven = this.form.node.undertaking_prev_4 == null ? 0 : parseFloat(this.form.node.undertaking_prev_4)
      var eight = this.form.node.undertaking_co_signing_1 == null ? 0 : parseFloat(this.form.node.undertaking_co_signing_1)
      var nine = this.form.node.undertaking_co_signing_2 == null ? 0 : parseFloat(this.form.node.undertaking_co_signing_2)
      var ten = this.form.node.undertaking_co_signing_3 == null ? 0 : parseFloat(this.form.node.undertaking_co_signing_3)
      var eleven = this.form.node.undertaking_co_signing_4 == null ? 0 : parseFloat(this.form.node.undertaking_co_signing_4)
      var twelve = this.form.node.five_1 == null ? 0 : parseFloat(this.form.node.five_1)
      var thirdteen = this.form.node.six_1 == null ? 0 : parseFloat(this.form.node.six_1)

      let total = one + three + four + five + six + seven + eight + nine + ten + eleven + twelve + thirdteen

      return total
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start();
      this.$store.dispatch("store/get", `table/application_sponsorship_relationship?filter=(member_uid=${this.memberUID})AND(alias='financial_evaluation_first')`)
        .then(response => {
          if (response.resource.length > 0) {
            this.form = response.resource[0];
            this.form.node = JSON.parse(response.resource[0].node);
          }
          this.$Progress.finish();
        })
        .catch(err => {
          this.$Progress.fail();
        });
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;

          if (this.form.id != undefined) this.edit = true;
          var action = "store/save";
          if (this.edit) {
            action = "store/edit";
          }
          this.$Progress.start();
          this.form.node = JSON.stringify(this.form.node);
          this.$store
            .dispatch(action, { url: this.url, data: this.form })
            .then(response => {
              this.fetchData();
              this.$store.dispatch("toaster/add", {
                text: "Save",
                type: "success"
              });
              this.$Progress.finish();
              this.loading = false;
            })
            .catch(err => {
              this.$store.dispatch("toaster/add", {
                text: "Error",
                type: "success"
              });
              this.$Progress.fail();
              this.loading = false;
            });
        }
        else {
          return false;
        }
      });
    },
    addRelationRow() {
      this.form.node.prev_relationships.push({
        birth_day: null,
        family_name: null,
        given_name: null,
        length_of_relationship_from: null,
        length_of_relationship_to: null,
        date_of_divorce_or_death_separation: null
      });
    },
    removeRelationRow(item, index) {
      this.$confirm("Are you sure to delete this item?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          this.form.node.prev_relationships.splice(index, 1);
        })
        .catch(() => {});
    }
  }
};
</script>

<style>
.tbpad > tbody > tr > td {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
