<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>OINP</span>
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <div class="body-2 mb-2 pull-left">Learning about OINP</div>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'learn_about_oinp')">
          <el-form-item label="How did you learn about the Ontario Immigrant Nominee Program (OINP)?" prop="learn_about_oinp">
            <el-input v-model="form.learn_about_oinp" @change="saveIMM(form)" style="width: 100%" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'specify_oinp')">
          <el-form-item label="Please Specify/Other" prop="specify_oinp">
            <el-input v-model="form.specify_oinp" @change="saveIMM(form)" style="width: 100%" />
          </el-form-item>
        </v-col>
        <v-col cols="12">
          <div class="body-2 mb-2 pull-left">Travel History</div>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'visited_ten_last_years')">
          <el-form-item prop="visited_ten_last_years">
            <el-checkbox v-model="form.visited_ten_last_years" @change="saveIMM(form)">Have you visited Canada in the last ten years?</el-checkbox>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'resided_five_last_years')">
          <el-form-item prop="resided_five_last_years">
            <el-checkbox v-model="form.resided_five_last_years" @change="saveIMM(form)">Have you resided in Canada within the last five years?</el-checkbox>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'travelled_five_last_years')">
          <el-form-item prop="travelled_five_last_years">
            <el-checkbox v-model="form.travelled_five_last_years" @change="saveIMM(form)">Have you travelled outside of Canada within the last five years?</el-checkbox>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'length_of_stay')">
          <el-form-item label="Length of Stay (in days)" prop="length_of_stay">
            <el-input v-model="form.length_of_stay" @change="saveIMM(form)" style="width: 100%" />
          </el-form-item>
        </v-col>
        <v-col cols="12">
          <div class="body-2 mb-2 pull-left">Intention to Reside in Ontario</div>

        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'intention_to_reside')">
          <el-form-item label="Please list all of your established ties in Ontario below (maximum 1000 characters):" prop="intention_to_reside">
            <el-input v-model="form.intention_to_reside" type="textarea" :row="8" @change="saveIMM(form)" />
          </el-form-item>
        </v-col>
        <v-col cols="12">
          <div class="body-2 mb-2 pull-left">Employment</div>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'applicant_currently_work_oinp')">
          <el-form-item label="Is the applicant currently working for the business in Ontario?" prop="applicant_currently_work_oinp">
            <el-input v-model="form.applicant_currently_work_oinp" @change="saveIMM(form)" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'family_member_hold_oinp')">
          <el-form-item label="Does the applicant or any of the applicants family members hold or have held equity in the business?" prop="family_member_hold_oinp">
            <el-input v-model="form.family_member_hold_oinp" @change="saveIMM(form)" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'position_offered_part_oinp')">
          <el-form-item label="Is the position being offered part of a regulated profession in Ontario?" prop="position_offered_part_oinp">
            <el-input v-model="form.position_offered_part_oinp" @change="saveIMM(form)" />
          </el-form-item>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'occupation_oinp')">
          <el-form-item label="In what occupation do you intend to work in canada?" prop="occupation_oinp">
            <el-input v-model="form.occupation_oinp" @change="saveIMM(form)" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'year_experience_oinp')">
          <el-form-item label="Total years of experience in the intended Ontario occupation" prop="year_experience_oinp">
            <el-input v-model="form.year_experience_oinp" @change="saveIMM(form)" />
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    },
    isOINP: {
      type: Boolean
    }
  },
  mounted() {
    if (this.isDataValidation) {
      var item = {
        specify_oinp: null,
        learn_about_oinp: null,
        visited_ten_last_years: null,
        travelled_five_last_years: null,
        resided_five_last_years: null,
        length_of_stay: null,
        intention_to_reside: null,
        applicant_currently_work_oinp: null,
        family_member_hold_oinp: null,
        position_offered_part_oinp: null,
        occupation_oinp: null,
        year_experience_oinp: null
      }

      this.checkItem(this.form, item)
    }
  }
}
</script>
