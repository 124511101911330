<template>
  <div v-if="hideSection">
    <v-card class="mt-3 bquestion">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" icon="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="form">
          <v-row dense>
            <v-col cols="12">
              <el-form-item prop="province" label="What province are you located in?"> 
                <el-select @change="autoSaveSubmit(form)" v-model="form.province" filterable class="mt-1" style="width: 350px">
                  <el-option v-for="(item, index) in province" :key="'province' + index" :value="item.province_name" :label="item.province_name">{{ item.province_name }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a1" label="Have you completed your studies prior to the date of your possible application but after 2017?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a1" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a2" label="Have you studied at a Designated Learning Institute (DLI) approved Canadian School?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a2" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  <el-radio :value="$t('global.not_sure')" :label="$t('global.not_sure')">{{ $t('global.not_sure') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a3" label="Have you received one of the following?"> 
                <el-select @change="autoSaveSubmit(form)" v-model="form.a3" class="mt-1" style="width: 350px">
                  <el-option value="A degree at least 8 months in duration">A degree at least 8 months in duration</el-option>
                  <el-option value="a degree, diploma or certificate - leading to occupation in a skilled trade Annex A ">a degree, diploma or certificate - leading to occupation in a skilled trade Annex A </el-option>
                  <el-option value="1 or more diplomas or certificates - min 8 months in duration and combined length min 16 months">1 or more diplomas or certificates - min 8 months in duration and combined length min 16 months</el-option>
                  <el-option :value="$t('global.not_sure')" :label="$t('global.not_sure')">{{ $t('global.not_sure') }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a4" label="Your course must be at least 1800 hours in duration. Does your course match this requirement?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a4" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  <el-radio :value="$t('global.not_sure')" :label="$t('global.not_sure')">{{ $t('global.not_sure') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a5" label="Do you have a valid Study or Post-Grad work permit?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a5" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  <el-radio :value="$t('global.not_sure')" :label="$t('global.not_sure')">{{ $t('global.not_sure') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a6" label="Are you employed in Canada?">
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a6" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a7" label="Are you self-employed?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a7" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a8" label="Are you currently earning a wage or commission?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a8" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a9" label="Do you have your CLB 5 in French (Unlimited) or English (40000)?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a9" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a10" label="CLB 5 in French (unlimited) or English (40000)"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a10" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  <el-radio :value="$t('global.not_sure')" :label="$t('global.not_sure')">{{ $t('global.not_sure') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a11" label="Have you completed your Language Test within the last 2 years?">
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a11" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a12" label="Do you reside in Canada?">
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a12" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a13" label="Can you physically be in Canada at the time of issuance of possible Permanent Residency?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a13" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  <el-radio :value="$t('global.not_sure')" :label="$t('global.not_sure')">{{ $t('global.not_sure') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a14" label="Do you intend to reside in a Canadian province or territory?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a14" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  <el-radio :value="$t('global.not_sure')" :label="$t('global.not_sure')">{{ $t('global.not_sure') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a15" label="Are you inadmissible?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a15" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  <el-radio :value="$t('global.not_sure')" :label="$t('global.not_sure')">{{ $t('global.not_sure') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a16" label="If you qualify for this new program, would you be interested in an Immigration Consultant assisting you during the application process?">
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a16" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  <el-radio :value="$t('global.not_sure')" :label="$t('global.not_sure')">{{ $t('global.not_sure') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a17" label="Are you aware this new program opens on May 6th and may fill up quickly?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a17" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a18" label="Do you have a spouse or children outside of Canada that would like to be included in your application?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a18" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  <el-radio :value="$t('global.not_sure')" :label="$t('global.not_sure')">{{ $t('global.not_sure') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a19" label="Are you able to pay for your application and right of landing fee at the time of applying?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a19" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  <el-radio :value="$t('global.not_sure')" :label="$t('global.not_sure')">{{ $t('global.not_sure') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" @click.native="autoSaveSubmit(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'
import { mapGetters } from 'vuex'

export default {
  mixins: [datavalidation],
  props: {
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    layout: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  data() {
    return {
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        province: null,
        occupation_a: null,
        occupation_b: null,
        a1: null,
        a2: null,
        a3: null,
        a4: null,
        a5: null,
        a6: null,
        a7: null,
        a8: null,
        a9: null,
        a10: null,
        a11: null,
        a12: null,
        a13: null,
        a14: null,
        a15: null,
        a17: null,
        a18: null,
        a19: null,
        sec_org_id: this.$orgId,
        type: 'international_graduates'
      },
      url: 'imms_table/application_assessment_questions',
      getUrl: `table/application_assessment_questions?filter=(member_uid=${this.memberUID})AND(type=international_graduates)`
    }
  },
  computed: {
    ...mapGetters({
      province: 'other/province'
    })
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `${this.getUrl}`)
        .then(response => {
          if (response.resource.length > 0) {
            this.form = response.resource[0]
            if (this.isDataValidation) {
              this.checkRow(response.resource[0])
            }
          }
          else {
            if (this.isDataValidation) {
              this.checkRow(this.form)
            }
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    checkRow(data) {
      delete this.form.sec_org_id

      this.checkItem(data, this.form)
    }
  }
}
</script>
