
<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>
        <span>{{ sectionLabel }}</span>
      </v-card-title>
      <v-card-text class="p-n">
        <v-row dense class="mb-4 flex">
          <v-col cols="6">
            <div class="text13 mt-2 pull-left">When did the child become a permanent resident of Canada?</div>
          </v-col>
          <v-col cols="6">
            <el-date-picker class="ml-3 pull-left" value-format="yyyy-MM-dd" v-model="formCitizenship.child_become" @change="checkCitizenship()" v-on:click.native="checkDate($event)"></el-date-picker>
          </v-col>
        </v-row>
        <v-row dense class="mb-3 flex">
          <v-col cols="6">
            <div class="text13 pull-left">Has the child left Canada for a total of 6 months or longer since becoming a permanent resident?</div>
          </v-col>
          <v-col cols="6">
            <el-radio-group class="ml-3 mt-2 pull-left" @change="checkCitizenship();" v-model="formCitizenship.child_left">
              <el-radio label="No">No</el-radio>
              <el-radio label="Yes">Yes</el-radio>
            </el-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="formCitizenship.child_left === 'Yes'">
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <v-col cols="12" v-if="check">
          <el-alert
            title="Please check to date"
            type="error">
          </el-alert>
        </v-col>
        <table class="table" style="width:100%">
          <thead>
            <tr>
              <th width="135">From</th>
              <th width="135">To</th>
              <th>Destination</th>
              <th>Reason</th>
              <th width="80">Days</th>
              <th width="30">
                <v-btn color="green" small fab @click.native="saveForm()">
                  <v-icon class="white--text">add</v-icon>
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in items" :key="i">
              <td class="pa-1">
                <el-date-picker v-if="dataValidationItems(item, 'from_date')" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="item.from_date" v-on:click.native="checkDate($event)" @change="handleAutoSave(i, item);getLength(item)" style="width: 100%;"></el-date-picker>
              </td>
              <td class="pa-1">
                <el-date-picker v-if="dataValidationItems(item, 'to_date')" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="item.to_date" v-on:click.native="checkDate($event)" @change="handleAutoSave(i, item);getLength(item)" style="width: 100%;"></el-date-picker>
              </td>
              <td class="pa-1">
                <el-input v-if="dataValidationItems(item, 'destination')" v-model="item.destination" style="width: 100%" @change="handleAutoSave(i, item)" />
              </td>
              <td class="pa-1">
                <el-input v-if="dataValidationItems(item, 'reason')" v-model="item.reason" style="width: 100%" @change="handleAutoSave(i, item)" />
              </td>
              <td class="pa-1 text-right">
                <el-input v-if="dataValidationItems(item, 'days')" v-model="item.days" disabled style="width: 100%" @change="handleAutoSave(i, item)" />
              </td>
              <td class="pa-1 text-center">
                <v-btn color="pink" small fab @click.native="deleteOpen(item, i)"><v-icon class="white--text">clear</v-icon></v-btn>
              </td>
            </tr>
          </tbody>
          <tbody v-if="totalDays > 0">
            <tr>
              <td colspan="4" class="text-right"><b>TOTAL</b></td>
              <td class="pa-1 text-right"><b class="pl-2 pr-2">{{ totalDays }}</b></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <v-row dense class="mb-3">
          <v-col cols="12">
            <div class="text13 mb-3">You must include photocopies of the biographical pages of any passports or travel documents covering the five (5) years immediately before the date of the child's application, or since the child became a permanent resident, if the child became a permanent resident within the past five years.</div>
          </v-col>
          <v-col cols="12">
            <div class="mb-3 text13 pull-left">Do you have all of the required photocopies?</div>
            <el-radio-group class="mb-3 ml-3 mt-1 pull-left" @change="checkCitizenship()" v-model="formCitizenship.photocopies">
              <el-radio label="Yes">Yes</el-radio>
              <el-radio label="No">No</el-radio>
            </el-radio-group>
          </v-col>
          <v-col cols="12" v-if="formCitizenship.photocopies === 'No'">
            <v-alert type="info" border="left" dense>
              If no, explain why these documents are not in your possession, in the box below.
              <br>
              <strong>NOTE:</strong> if your passport validity was extended, include a photocopy of the page(s) containing the extension stamp(s).
            </v-alert>
            <el-input class="mt-3" v-model="formCitizenship.photocopies_text" @change="checkCitizenship()" type="textarea" :rows="4" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <v-row dense class="mb-3">
          <v-col cols="12">
            <div class="text13 mb-3">Are there any gaps in time in the validity period of the child's passports or travel documents during the past five (5) years, or since the child became a permanent resident, within the past five years?</div>
            <el-radio-group class="ml-3 mb-3 mt-1 pull-left" @change="checkCitizenship()" v-model="formCitizenship.password_travel">
              <el-radio label="Yes">Yes</el-radio>
              <el-radio label="No">No</el-radio>
            </el-radio-group>
          </v-col>
          <v-col cols="12" v-if="formCitizenship.password_travel === 'Yes'">
            <v-alert type="info" border="left" dense>
              <strong>NOTE:</strong> If yes, explain why the child's passports or travel documents do not cover the entire period of time in the box below. If
              you do not provide an explanation for any period when your child did not have a valid passport, your application will be
              returned to you.
            </v-alert>
            <el-input class="mt-3" v-model="formCitizenship.password_travel_text" @change="checkCitizenship()" type="textarea" :rows="4" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <v-row dense class="mb-3">
          <v-col cols="12">
            <div class="text13 mb-3">In the past four (4) years, was the child present in a country other than Canada for a total of 183 days or more <b>while the child was 14 years of age or older</b>?</div>
            <el-radio-group class="mb-3 ml-3 mt-1 pull-left" @change="checkCitizenship()" v-model="formCitizenship.age_order">
              <el-radio label="Yes">Yes</el-radio>
              <el-radio label="No">No</el-radio>
              <el-radio label="Not applicable">Not applicable (child under 14 years of age)</el-radio>
            </el-radio-group>
          </v-col>
          <v-col cols="12" v-if="formCitizenship.age_order === 'Yes'">
            <v-alert type="info" border="left" dense>
              If you have checked 'YES', indicate each country in the box below. You must provide police certificates for each country
              where the child has been present for 183 days or more. If the child cannot get a police certificate from a country, please
              provide an explanation in the box below.
            </v-alert>
            <el-input class="mt-3" v-model="formCitizenship.age_order_text" @change="checkCitizenship()" type="textarea" :rows="4" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  name: 'PermanentResident',
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        days: null,
        destination: null,
        reason: null,
        from_date: null,
        to_date: null
      },
      activeNames: -1,
      check: false,
      url: 'imms_table/application_permanent_resident',
      urlCitizenship: 'imms_table/application_citizenship_minor',
      checkEdit: false,
      loading: false,
      items: [],
      formCitizenship: {
        child_become: null,
        child_left: null,
        photocopies: null,
        photocopies_text: null,
        password_travel: null,
        password_travel_text: null,
        age_order: null,
        age_order_text: null
      }
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    totalDays: function() {
      var count = 0
      this.items.forEach(function(item) {
        count += parseFloat(item.days)
      })
      return count
    }
  },
  mounted() {
    this.fetchData()
    this.getCitizenship()
  },
  methods: {
    getLength(item) {
      if (item.from_date && item.to_date) {
        var a = moment(item.from_date, 'YYYY-MM-DD')
        var b = moment(item.to_date, 'YYYY-MM-DD')
        var length = b.diff(a, 'days')

        if (length > 0) {
          item.days = length
          this.check = false
        }
        else {
          this.check = true
          item.to_date = null
        }
      }
    },
    getCitizenship() {
      this.$store.dispatch('store/get', `table/application_citizenship_minor?filter=member_uid=${this.memberUID}`)
        .then(response => {
          if (response.resource.length) {
            this.formCitizenship = response.resource[0]
            this.checkEdit = true
          }
        })
    },
    checkCitizenship() {
      var action = 'store/save'
      if (this.formCitizenship.id == undefined && !this.checkEdit) {
        action = 'store/save'
      }
      else {
        this.formCitizenship.application_id = this.$route.params.id
        this.formCitizenship.member_uid = this.memberUID
        this.formCitizenship.member_id = this.memberID
        this.checkEdit = true
        action = 'store/edit'
      }
      this.$store.dispatch(action, { 'url': this.urlCitizenship, 'data': this.formCitizenship })
        .then(response => {
          this.checkEdit = false
          this.getCitizenship()
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
        })
    },
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_permanent_resident?filter=member_uid=${this.memberUID}&order=from_date ASC,to_date ASC`)
        .then(response => {
          let vm = this
          if (vm.isDataValidation) {
            response.resource.forEach(function(item) {
              var form = {
                days: null,
                destination: null,
                reason: null,
                from_date: null,
                to_date: null
              }

              var obj = vm.checkFormItems(item, form)

              if (obj != 'empty') {
                vm.items.push(item)
              }
            })

            if (vm.formCalculate.child_left === 'Yes') {
              vm.hideSection = false
            }
            else if (response.resource.length == 0) {
              vm.hideSection = false
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
          else {
            vm.items = response.resource
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        days: null,
        destination: null,
        reason: null,
        from_date: null,
        to_date: null
      }
    },
    saveForm() {
      this.nodeClear()
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    resetForm() {
      this.$refs.item.resetFields()
      this.nodeClear()
    }
  }
}
</script>
