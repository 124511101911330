export default {
  adminId: 1,
  consultantId: 2,
  applicantId: 3,
  businessPartnerId: 4,
  orgAdminId: 79,
  agentId: 84,
  employerId: 87,
  staffId: 90,
  salesId: 91,
  accountingId: 92,
  prodEnv: ['staging', 'production'],
  defaultConsultantId: 4,
  DEFAULT_MEETING_DURATION: 30,
  DEFAULT_DATE_RANGE: 14,
  EXTENDED_DATE_RANGE: 60,
  APP_STATUS_CREATED: 'created',
  APP_STATUS_PURCHASED: 'purchased',
  APP_STATUS_RCIC_ONBOARDING: 'rcic_onboarding',
  APP_STATUS_FORMS_RECRUITMENT: 'forms_recruitment',
  APP_STATUS_REVIEW: 'review',
  APP_STATUS_WAITING_FOR_PAYMENT: 'waiting_for_final_payment',
  APP_STATUS_READY_TO_SUBMIT: 'ready_to_submit',
  APP_STATUS_SUBMITTED: 'submitted',
  APP_STATUS_FOLLOW_UP: 'follow_up',
  APP_STATUS_COMPLETE: 'completed',
  APP_STATUS_ARCHIVED: 'archived',
  CENT_TO_INT: 100,
  DOLLAR: 1,
  AGRI_IDS: [66, 68, 69, 70],
  MAX_FILE_SIZE: 1024 * 1024 * 4, //4mb
  MAX_ZIP_FILE_SIZE: 1024 * 1024 * 10, //10mb
  APP_STATUS_CREATED_LABEL: 'Created',
  APP_STATUS_PURCHASED_LABEL: 'Purchased',
  APP_STATUS_RCIC_ONBOARDING_LABEL: 'RCIC onboarding',
  APP_STATUS_FORMS_RECRUITMENT_LABEL: 'Forms/Recruitment',
  APP_STATUS_REVIEW_LABEL: 'Review',
  APP_STATUS_WAITING_FOR_PAYMENT_LABEL: 'Waiting for final payment',
  APP_STATUS_READY_TO_SUBMIT_LABEL: 'Ready to Submit',
  APP_STATUS_SUBMITTED_LABEL: 'Submitted',
  APP_STATUS_FOLLOW_UP_LABEL: 'Follow up',
  APP_STATUS_COMPLETE_LABEL: 'Completed',
  APP_STATUS_ARCHIVED_LABEL: 'Archived',
  APP_STATUS_CREATED_LABEL_FOR_CLIENT: 'Initial Payment Due',
  APP_STATUS_PURCHASED_LABEL_FOR_CLIENT: 'Consultation to be scheduled',
  APP_STATUS_RCIC_ONBOARDING_LABEL_FOR_CLIENT: 'Onboarding',
  APP_STATUS_FORMS_RECRUITMENT_LABEL_FOR_CLIENT: 'In Progress',
  APP_STATUS_REVIEW_LABEL_FOR_CLIENT: 'Review',
  APP_STATUS_WAITING_FOR_LABEL_PAYMENT_FOR_CLIENT: 'Final Payment Due',
  APP_STATUS_READY_TO_SUBMIT_LABEL_FOR_CLIENT: 'Final Review',
  APP_STATUS_SUBMITTED_LABEL_FOR_CLIENT: 'Application Submitted',
  APP_STATUS_FOLLOW_UP_LABEL_FOR_CLIENT: 'Action Required',
  APP_STATUS_COMPLETE_LABEL_FOR_CLIENT: 'Finalized',
  APP_STATUS_ARCHIVED_LABEL_FOR_CLIENT: 'Archived',
  APP_MAIN_CONSULTANT_EMAIL: 'dirk@iibc.ca',
};
