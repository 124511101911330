<template>
  <div class="mt-3">
    <v-card>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-2" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="form">
          <v-card flat>
            <v-card-title>3. Have you ever been married or in a common-law relationship with another person?</v-card-title>
          </v-card>
          <v-row dense>
            <v-col cols="12">
              <label class="el-form-item__label">(You have been in a common-law relationship if you previously lived together with a partner in a committed and marriage-like relationship for a period of
              one year or more)</label>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row dense>
                <v-col cols="4" md="2">
                  <el-radio v-model="form.node.another_person" label="Yes">Yes</el-radio>
                </v-col>
                <v-col cols="4" md="1">
                  <v-icon small>play_arrow</v-icon>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4" md="2">
                  <el-radio v-model="form.node.another_person" label="No">No</el-radio>
                </v-col>
                <v-col cols="4" md="1">
                  <v-icon small>play_arrow</v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="12">
              <div class="mt-3">
                  <label class="el-form-item__label">If yes, give details of your previous spouse(s) and partner(s) in the table below.</label>
              </div>
            </v-col>
            <v-col cols="12">
              <v-card>
                <table class="datatable table tbpad">
                  <thead>
                    <tr>
                      <th>Date of birth</th>
                      <th>Family name</th>
                      <th>Given name(s)</th>
                      <th colspan="2">Length of Relationship</th>
                      <th>Date of Divorce / Death/Separation</th>
                      <th>
                          <el-button type="success" icon="el-icon-plus" @click="addRelationRow()" circle size="mini"></el-button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(relation, index) in form.node.prev_relationships" :key="'rel'+index">
                      <td><el-date-picker size="medium" type="date" v-model="relation.birth_day" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker></td>
                      <td><el-input size="medium" type="text" v-model="relation.family_name" /></td>
                      <td><el-input size="medium" type="text" v-model="relation.given_name" /></td>
                      <td><el-date-picker size="medium" type="date" v-model="relation.length_of_relationship_from" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker></td>
                      <td><el-date-picker size="medium" :picker-options="datePickerOptions" type="date" v-model="relation.length_of_relationship_to" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker></td>
                      <td><el-date-picker size="medium" :picker-options="datePickerOptions" type="date" v-model="relation.date_of_divorce_or_death_separation" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker></td>
                      <td width="100" class="text-center">
                        <el-row class="mt-2 mb-2">
                          <el-button type="danger" icon="el-icon-delete" @click="removeRelationRow(relation, index)" circle size="mini"></el-button>
                        </el-row>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-card>
            </v-col>
          </v-row>
          <v-card flat class="mt-3">
            <v-card-title>4. Consent to disclose</v-card-title>
          </v-card>
          <v-row dense>
            <v-col cols="12">
              <div class="mt-3">
                <div class="el-form-item__label">If there is an investigation into marriage fraud about you, do you consent to Immigration, Refugees and Citizenship Canada and the Canada Border</div>
                <div class="el-form-item__label">Services Agency releasing the results to your spouse or partner?</div>
              </div>
              <div class="mt-3">
                <el-radio v-model="form.node.consent_to_disclose" label="Yes">Yes</el-radio>
                <el-radio v-model="form.node.consent_to_disclose" label="No">No</el-radio>
              </div>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="loading" color="primary" dark small @click.native="submitForm()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/autosave.js'

export default {
  mixins: [autosave],
  props: {
    sectionId: {
      type: String
    },
    layout: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      form: {
        member_uid: this.memberUID,
        member_id: this.memberID,
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        node: {
          another_person: null,
          prev_relationships: [
            {
              birth_day: null,
              family_name: null,
              given_name: null,
              length_of_relationship_from: null,
              length_of_relationship_to: null,
              date_of_divorce_or_death_separation: null
            },
            {
              birth_day: null,
              family_name: null,
              given_name: null,
              length_of_relationship_from: null,
              length_of_relationship_to: null,
              date_of_divorce_or_death_separation: null
            },
            {
              birth_day: null,
              family_name: null,
              given_name: null,
              length_of_relationship_from: null,
              length_of_relationship_to: null,
              date_of_divorce_or_death_separation: null
            }
          ],
          consent_to_disclose: null
        },
        alias: 'part-b'
      },
      loading: false,
      url: 'imms_table/application_sponsorship_relationship'
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_sponsorship_relationship?filter=(member_uid=${this.memberUID})AND(alias='part-b')`)
        .then(response => {
          if (response.resource.length > 0) {
            this.form = response.resource[0]
            this.form.node = JSON.parse(response.resource[0].node)

            if (response.resource[0].node.prev_relationships == null && response.resource[0].node.prev_relationships == undefined) {
              this.form.node.prev_relationships = []
            }
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true

          if (this.form.id != undefined) this.edit = true
          var action = 'store/save'
          if (this.edit) {
            action = 'store/edit'
          }
          this.$Progress.start()
          this.form.node = JSON.stringify(this.form.node)
          this.$store.dispatch(action, { 'url': this.url, 'data': this.form })
            .then(response => {
              this.fetchData()
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    addRelationRow() {
      this.form.node.prev_relationships.push(
        {
          birth_day: null,
          family_name: null,
          given_name: null,
          length_of_relationship_from: null,
          length_of_relationship_to: null,
          date_of_divorce_or_death_separation: null
        }
      )
    },
    removeRelationRow(item, index) {
      this.$confirm('Are you sure to delete this item?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.form.node.prev_relationships.splice(index, 1)
      }).catch(() => {})
    }
  }
}
</script>

<style>
  .tbpad > tbody > tr > td{
    padding-top: 5px;
    padding-bottom: 5px;
  }
</style>
