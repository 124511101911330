<template>
  <div>
    <el-form
      :model="formGeneric"
      ref="formGeneric"
      :rules="rules"
      label-position="top"
      class="mt-3"
      :class="!isDataValidation ? 'personal-details' : ''">
      <template v-for="(section, sindex) in sections">
        <components
          :key="sindex"
          :is="section.alias"
          :sectionId="section.section_id"
          :sectionSlug="section.slug"
          :sectionLabel="section.label"
          :sectionInfoBox="section.info"
          :form="formGeneric"
          :memberUID="memberUID"
          :memberID="memberID"
          :layout="layout"
          :datas="datas"
          :isDataValidation="isDataValidation"
          :isOINP="isOINP"
          :isYukon="isYukon"
          :applicantID="applicantID">
        </components>
      </template>
    </el-form>
    <v-card v-if="!isDataValidation">
      <v-card-text>
        <v-spacer />
        <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="saveIMM(formGeneric)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-text>
    </v-card>

    <div class="error--snack error" v-if="errorMessage.length > 0">
      <template v-for="(message, ind) in errorMessage">
        <div :key="ind" class="error--snack__content">
          {{ message.message }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  name: 'PersonalDetails',
  mixins: [datavalidation],
  props: {
    sections: {
      type: Array
    },
    layout: {
      type: String
    },
    applicantID: {
      type: Number
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    },
    isOINP: {
      type: Boolean
    },
    isYukon: {
      type: Boolean
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      rules: {},
      errorMessage: [],
      generic: {},
      loading: false,
      user: {}
    }
  },
  computed: {
    ...mapGetters({
      members: 'generic/all',
      country: 'other/country',
      province: 'other/province',
      isMobile: 'sidebar/isMobile'
    }),
    formGeneric: function() {
      return this.generic
    },
    errorDisabled: function() {
      return this.errorMessage.length === 0 ? false : true
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
    this.$store.dispatch('other/getCountry')
    this.$store.dispatch('other/getProvince')
  },
  methods: {
    checkMessage(data) {
      if (!data.fname && data.fname === null) {
        this.errorMessage.push({ "message": "Please input the first name", "field": "fname" })
      }
      if (!data.lname && data.lname === null) {
        this.errorMessage.push({ "message": "Please input the last name", "field": "lname" })
      }
    },
    fetchData() {
      this.members.forEach(function(member) {
        if (member.u_id === this.memberUID) {
          this.checkMessage(member)
          this.generic = member

          if (parseFloat(this.$route.params.apptypeId) === 24) {
            this.generic.current_residence_country = 42
          }

          if (member.app_primary) {
            this.$store.dispatch('sysuser/getUser', member.applicant_id).then(response => {
              this.user = response
            })
          }
        }
      }, this)
    }
  }
}
</script>
