
<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <div>{{ $t('title.imm_0008_last_entry_canada') }}</div>
      <el-checkbox v-model="form.last_entry_not_applicable" class="ml-5">Not Applicable</el-checkbox>
      <v-spacer />
    </v-card-title>
    <v-card-text v-if="!form.last_entry_not_applicable">
      <v-row dense class="medium-space">
        <v-col cols="12" sm="5" md="4" v-if="dataValidation(form, 'last_entry_date')">
          <el-form-item :label="$t('imm_0008.last_entry_date')" prop="last_entry_date">
            <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.last_entry_date" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="5" md="4" v-if="dataValidation(form, 'last_entry_place')">
          <el-form-item :label="$t('imm_0008.last_entry_place')" prop="last_entry_place">
            <el-input @change="saveIMM(form)" v-model="form.last_entry_place" style="width: 100%;" />
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mounted() {
    if (this.isDataValidation) {
      if (this.form.last_entry_not_applicable) {
        this.hideSection = false
      }
      else {
        var item = {
          last_entry_date: null,
          last_entry_place: null
        }

        this.checkItem(this.form, item)
      }
    }
  }
}
</script>