<template>
  <v-card v-if="!isDataValidation" class="mt-3">
    <v-card-title>
      <span>{{sectionLabel}}</span>
    </v-card-title>
    <v-card-text class="p-n">
      <v-row dense>
        <v-col cols="12" class="mb-3">
          <div class="text13 mb-3 text-uppercase"><strong>a) Congratulatory letter</strong></div>
          <div class="text13 mb-1 ml-2"><b>Request for Authorization</b></div>
          <div class="text13 mb-3 ml-2">Members of Parliament (MPs) send a letter of congratulations to new citizens in their ridings. <b>Do you authorize IRCC to forward your name, residential address, and preferred official language to your Member of Parliament?
          No other information will be forwarded. </b></div>
          
          <el-radio-group class="ml-4" @change="checkCitizenship()" v-model="form.congratulatory">
            <el-radio label="No">No</el-radio>
            <el-radio label="Yes">Yes</el-radio>
          </el-radio-group>
        </v-col>
        <v-col cols="12" class="mb-3">
          <div class="text13 mb-3 text-uppercase"><strong>b) National register of electors</strong></div>
          <div class="text13 mb-1 ml-2"><b>Request for Authorization </b></div>
          <div class="text13 mb-3 ml-2">Canadian citizens who are 18 years of age or older have the right to vote in federal elections and referendums. Elections
          Canada needs your authorization to add your name to the National Register of Electors which is used to produce the voters
          lists for federal elections and referendums.</div>
          <div class="text13 mb-3 ml-2">Do you authorize IRCC to give your name, residential and mailing addresses, sex, date of birth, Unique Client Identifier
          (UCI), and the date your citizenship was granted to Elections Canada, after you acquire citizenship, so that Elections Canada
          can include this information in the National Register of Electors?</div>

          <el-radio-group class="ml-3" @change="checkCitizenship()" v-model="form.national_register">
            <el-radio label="No">No</el-radio>
            <el-radio label="Yes">Yes</el-radio>
          </el-radio-group>
        </v-col>
        <v-col cols="12" class="mb-3">
          <v-alert class="ml-2" color="info" icon="info" :value="true">
            Whether you answer "Yes" or "No" is completely your choice. Your answer will not affect your citizenship application. Inclusion
            in the National Register of Electors is optional.
            <br> If you answer yes, your information will automatically be included on the lists of electors during a federal election
            and you will receive a voter information card during the elections to inform you when and where to vote.
            <br> If you answer no, you will still be able to vote, but you will have to register at your local Elections Canada office
            during the election, or at your polling station.
          </v-alert>
        </v-col>
        <v-col cols="12" class="mb-3">
          <div class="text13 mb-3 text-uppercase"><strong>c) Quebec's permanent list of electors</strong></div>
          <div class="text13 mb-1 ml-2"><b>Request for Authorization </b></div>
          <div class="text13 mb-3 ml-2">In Quebec, the permanent list of electors is used to compile the lists of electors used in provincial, municipal and school
          elections. If you re under 18 years of age and older and reside in Quebec, to make sure that you are on the lists of electors
          (voters), the Chief Electoral Officer of Quebec needs your authorization to add your name to Quebec's permanent list of electors.</div>
          <div class="text13 mb-3 ml-2">Do you authorize IRCC to forward your name, residential address and the date at which you started to reside at this address,
          sex, date of birth, Unique Client Identifier (UCI), and the date your citizenship was granted to the Chief Electoral Officer
          of Quebec so that this information can be added to the permanent list of electors?</div>

          <el-radio-group class="ml-3 mt-2" @change="checkCitizenship()" v-model="form.quebec_permanent">
            <el-radio label="No">No</el-radio>
            <el-radio label="Yes">Yes</el-radio>
          </el-radio-group>
        </v-col>
        <v-col cols="12" class="mb-3">
          <v-alert class="ml-2" color="info" icon="info" :value="true">
            Whether you answer "Yes" or "No" is completely your choice. Your answer will not affect your citizenship application. Inclusion
            in the permanent list is not mandatory.
            <br> No information will be forwarded until you acquire Canadian Citizenship. your information will only be used for purposes
            permitted under the Election Act (RLRQ, C. E-3.3).
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      form: 'citizenship/get'
    })
  }, 
  methods: {
    checkCitizenship() {
      this.$store.dispatch('citizenship/edit', this.form)
        .then(response => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Disabled', type: 'success' })
        })
    }
  }
}
</script>