export const orderObjectBy = (objArray, prop, direction) => {
  var direct = direction !== undefined ? direction : 1;
  var propPath = prop.constructor === Array ? prop : prop.split(".");
  var filteredItems = [];
  objArray.forEach(function(item) {
    filteredItems.push(item);
  });
  filteredItems.sort(function(a, b) {
    for (var p in propPath) {
      if (a[propPath[p]] && b[propPath[p]]) {
        a = a[propPath[p]];
        b = b[propPath[p]];
      }
      else {
        a = a[propPath[p]];
        b = b[propPath[p]];
      }
    }
    try {
      a = a.match(/^\d+$/) ? +a : a;
    }
    catch (err) {
      a = a;
    }
    try {
      b = b.match(/^\d+$/) ? +b : b;
    }
    catch (err) {
      b = b;
    }
    return a < b ? -1 * direct : a > b ? 1 * direct : 0;
  });

  return filteredItems;
};

export const sortMember = (objArray) => {
  var son = [];
  var principal = [];
  var daughter = [];
  var other = [];
  var spouce = [];

  objArray.forEach(function(item) {
    item.check = false;
    switch (item.relationship_name) {
      case "Principal":
        principal.push(item);
        break;
      case "Spouse/Common-law partner":
        spouce.push(item);
        break;
      case "Daughter":
        daughter.push(item);
        break;
      case "Son":
        son.push(item);
        break;
      default:
        other.push(item);
        break;
    }
  });
  var newArray = principal.concat(spouce);
  var newArray2 = newArray.concat(daughter);
  var newArray3 = newArray2.concat(son);
  var newArray4 = newArray3.concat(other);

  return newArray4;
};

export const sortConfiguranFolderMember = (objArray) => {
  var son = [];
  var principal = [];
  var daughter = [];
  var other = [];
  var spouce = [];

  objArray.forEach(function(item) {
    item.check = false;
    switch (item.relationship) {
      case "Principal":
        principal.push(item);
        break;
      case "Spouse/Common-law partner":
        spouce.push(item);
        break;
      case "Daughter":
        daughter.push(item);
        break;
      case "Son":
        son.push(item);
        break;
      default:
        other.push(item);
        break;
    }
  });
  var newArray = principal.concat(spouce);
  var newArray2 = newArray.concat(daughter);
  var newArray3 = newArray2.concat(son);
  var newArray4 = newArray3.concat(other);

  return newArray4;
};
