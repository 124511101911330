<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>Permanent Resident Card</span>
    </v-card-title>
    <v-card-text>
      <v-row dense class="medium-space">
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'language_prefer')">
          <el-form-item label="Language you prefer for correspondence" prop="language_prefer">
            <el-radio-group @change="saveIMM(form)" v-model="form.language_prefer" style="width: 100%;">
              <el-radio label="English">English</el-radio>
              <el-radio label="French">French</el-radio>
            </el-radio-group>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'date_pr_card')">
          <el-form-item label="Date you became a perminant resident of Canada" prop="date_pr_card">
            <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.date_pr_card" v-on:click.native="checkDate($event)" style="width: 100%"></el-date-picker>
          </el-form-item>
        </v-col>
        <v-col cols="12" v-if="dataValidation(form, 'apply_pr_card')">
          <el-form-item label="You are applying to" prop="apply_pr_card">
            <el-radio-group @change="saveIMM(form)" v-model="form.apply_pr_card" style="width: 100%;">
              <el-radio label="Obtain your first Perminent Residence Card">Obtain your first Perminent Residence Card</el-radio>
              <el-radio label="Renew your present Perminent Residence Card">Renew your present Perminent Residence Card</el-radio>
              <el-radio label="Replace a lot, stolen, destroyed or never recieved Perminant Residence Card">Replace a lot, stolen, destroyed or never recieved Perminant Residence Card</el-radio>
            </el-radio-group>
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mounted() {
    if (this.isDataValidation) {
      var item = {
        language_prefer: null,
        date_pr_card: null,
        apply_pr_card: null
      }

      this.checkItem(this.form, item)
    }
  }
}
</script>
