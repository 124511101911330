import _ from 'lodash';

export default {
  data() {
    return {
      actionSave: 0,
      autoSave: false,
      selectProvine: false,
      province: [],
      items: [],
      phoneMask: [
        '+',
        '1',
        ' ',
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
      activeNames: -1,
      validationItems: [],
      validateItem: {},
      hideSection: true,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
    };
  },
  computed: {
    validateClass() {
      return this.isDataValidation ? 'error-field' : '';
    },
  },
  methods: {
    checkRow(data) {
      this.checkForm(data, this.form);
    },
    checkForm(data, form) {
      let vm = this;
      let hideField = {};

      for (let key in data) {
        let index = key in form;

        if (index) {
          if (vm.isEmpty(data[key])) {
            hideField[key] = data[key];
          }
        }
      }

      let size = Object.keys(hideField).length;

      if (size == 0) {
        vm.hideSection = false;
        return 'empty';
      } else {
        hideField.id = data.id;
        vm.validateItem = hideField;
        vm.hideSection = true;
        return hideField;
      }
    },
    checkFormItems(data, form) {
      let vm = this;
      let hideField = {};

      for (let key in data) {
        let index = key in form;

        if (index) {
          if (vm.isEmpty(data[key])) {
            hideField[key] = data[key];
          }
        }
      }

      let size = Object.keys(hideField).length;

      if (size == 0) {
        return 'empty';
      } else {
        hideField.id = data.id;
        vm.validationItems.push(hideField);
        return hideField;
      }
    },
    dataValidation(data, key) {
      if (!this.isDataValidation || key == undefined) {
        return true;
      }

      let size = Object.keys(this.validateItem).length;
      if (size > 0) {
        return key in this.validateItem;
      } else {
        return false;
      }
    },
    dataValidationItems(data, key) {
      if (!this.isDataValidation || key == undefined) {
        return true;
      }

      let index = this.validationItems
        .map((p) => parseInt(p.id))
        .indexOf(parseInt(data.id));
      if (index == -1) {
        return true;
      } else {
        return key in this.validationItems[index];
      }
    },
    isEmpty(e) {
      switch (e) {
        case '':
        case null:
        case typeof this == 'undefined':
          return true;
        default:
          return false;
      }
    },
    fetch(params) {
      return new Promise((resolve, reject) => {
        let url =
          params != undefined
            ? params
            : this.url + '?filter=application_id=' + this.$route.params.lmiaId;

        if (this.getUrl != undefined && this.getUrl != null) {
          url = this.getUrl;
        }

        this.$store.dispatch('store/get', url).then(
          (response) => {
            let items = response.resource;
            resolve({
              items,
            });
          },
          (error) => {
            reject(error);
          },
        );
      });
    },
    async fetchData(params) {
      try {
        let url =
          params != undefined
            ? params
            : `${this.url}?filter=application_id=${this.$route.params.lmiaId}`;
        if (this.getUrl != undefined && this.getUrl != null) {
          url = this.getUrl;
        }
        let res = await this.$api.get(url);
        this.items = res.data.resource || [];
      } catch (err) {}
    },
    checkDate(event) {
      if (event.target) event.target.readOnly = true;
    },
    getProvince(id) {
      if (id != undefined && (42 == parseInt(id) || 239 == parseInt(id))) {
        this.selectProvine = true;
        this.$store
          .dispatch(
            'store/get',
            `/table/provinces?filter=country_id=${id}&order=province_name ASC`,
          )
          .then((response) => {
            this.province = response.resource;
          });
      } else {
        this.selectProvine = false;
      }
    },
    handleAutoSaveItems(form) {
      var vm = this;
      vm.autoSave = true;
      vm.submitDataItems(vm, form);
    },
    submitDataItems: _.debounce((vm, form) => {
      var action = 'store/save';
      var edit = false;
      if (form.id !== undefined && form.id !== null) {
        action = 'store/edit';
        edit = true;
      }
      vm.$store
        .dispatch(action, { url: vm.url, data: form })
        .then((response) => {
          if (
            !edit &&
            response.data.resource !== undefined &&
            response.data.resource.length > 0
          ) {
            form.id = response.data.resource[0].id;
            form.imms_uuid = response.data.resource[0].imms_uuid;
          }
          vm.autoSave = false;
        });
    }, 1000),
    handleAutoSave(form) {
      var vm = this;
      vm.autoSave = true;
      vm.submitData(vm, form);
    },
    submitData: _.debounce(
      async function (vm, form) {
        try {
          let url = `${vm.url}?filter=application_id=${vm.$route.params.lmiaId}`;
          if (vm.getUrl !== undefined) {
            url = vm.getUrl;
          }
          const res = await vm.$api.get(url);
          if (res.data.resource.length === 0) {
            const saveRes = await vm.$api.post(vm.url, { resource: form });
            if (saveRes.data.resource.length > 0) {
              form.id = saveRes.data.resource[0].id;
              form.imms_uuid = saveRes.data.resource[0].imms_uuid;
            }
          } else {
            form.id = res.data.resource[0].id;
            form.imms_uuid = res.data.resource[0].imms_uuid;
            let url = `${vm.url}/${form.id}`;
            if (
              form.imms_uuid !== undefined &&
              form.imms_uuid !== null &&
              vm.url.includes('imms_table')
            ) {
              url = `${vm.url}/${form.imms_uuid}`;
            }
            await vm.$api.put(`${url}`, form);
          }
          vm.autoSave = false;
        } catch (err) {
          vm.autoSave = false;
        }
      },
      1000,
      { maxWait: 3000 },
    ),
    async candidateAutoSave(form) {
      this.autoSave = true;
      try {
        let res = await this.$api.get(
          `table/candidate_personal_information?filter=sys_user_id=${form.sys_user_id}`,
        );
        if (res.data.resource.length === 0) {
          if (this.actionSave == 0) {
            await this.$store.dispatch('candidate/save', form);
            this.autoSave = false;
            this.actionSave = 1;
          }
        } else {
          this.autoSave = false;
          await this.$store.dispatch('candidate/edit', form);
          this.autoSave = false;
          this.actionSave = 1;
        }
      } catch (err) {
        this.actionSave = 0;
        this.autoSave = false;
      }
    },
    add() {
      this.nodeClear();
      this.edit = false;
      this.items.push(this.formItem);
      this.activeNames = this.items.length;
    },
    resetForm() {
      this.$refs.form.resetFields();
      this.nodeClear();
    },
    saveAll() {
      let vm = this;
      if (vm.items.length > 0) {
        vm.autoSave = true;
        vm.items.forEach(function (form) {
          var edit = false;
          var action = 'store/save';

          if (form.id != undefined) {
            edit = true;
            action = 'store/edit';
            form.id = parseFloat(form.id);
          }
          vm.$store
            .dispatch(action, { url: vm.url, data: form })
            .then((response) => {
              if (!edit && response.data.resource.length > 0) {
                form.id = response.data.resource[0].id;
                form.imms_uuid = response.data.resource[0].imms_uuid;
              }
              vm.$store.dispatch('toaster/add', {
                text: 'Save',
                type: 'success',
              });
              vm.autoSave = false;
              vm.activeNames = -1;
            })
            .catch((err) => {
              vm.$store.dispatch('toaster/add', {
                text: 'Error',
                type: 'success',
              });
              vm.autoSave = false;
            });
        }, vm);
      } else {
        return false;
      }
    },
    deleteOpen(item, index) {
      let vm = this;
      vm.$confirm('Are you sure to delete this item?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
        .then(() => {
          if (item.id !== undefined) {
            let url = `${vm.url}/${item.id}`;
            if (
              item.imms_uuid !== undefined &&
              item.imms_uuid !== null &&
              vm.url.includes('imms_table')
            ) {
              url = `${vm.url}/${item.imms_uuid}`;
            }
            vm.$store
              .dispatch('store/delete', url)
              .then((response) => {
                vm.$store.dispatch('toaster/add', {
                  text: 'Deleted',
                  type: 'success',
                });
              })
              .catch((err) => {
                vm.$store.dispatch('toaster/add', {
                  text: 'Canceled',
                  type: 'success',
                });
              });
          }
          vm.items.splice(index, 1);
          vm.activeNames = -1;
        })
        .catch(() => {
          vm.$message({
            type: 'info',
            message: 'Canceled',
          });
        });
    },
    async deleteItem(item, index) {
      this.$confirm('Are you sure to delete this item?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
        .then(async () => {
          if (item.id) {
            try {
              let url = `${this.deleteUrl}/${item.id}`;
              if (
                item.imms_uuid !== undefined &&
                item.imms_uuid !== null &&
                this.url.includes('imms')
              ) {
                url = `${this.deleteUrl}/${item.imms_uuid}`;
              }
              await this.$api.delete(url);
              this.items.splice(index, 1);
              this.activeNames = -1;
              this.$store.dispatch('toaster/add', {
                text: 'Deleted',
                type: 'success',
              });
            } catch (err) {
              this.$store.dispatch('toaster/add', {
                text: `Cannot delete item. Please contact supporting team!`,
                type: 'error',
              });
            }
          } else {
            this.items.splice(index, 1);
            this.activeNames = -1;
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Canceled',
          });
        });
    },
  },
};
