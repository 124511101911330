<template>
  <v-card v-if="hideSection" class="mt-3">
    <v-card-title>
      <span>{{sectionLabel}}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text class="p-n">
      <v-row dense v-if="!isDataValidation">
        <v-col cols="12" class="mb-3 ml-3">
          <div class="text13 mb-3">a) Do you have a Social Insurance Number (SIN), Temporary Tax Number (TTN), and/or Individual Tax Number (ITN)?</div>
          <el-radio @change="checkCitizenship()" v-model="form.tax_insurance" label="Yes">Yes, Provide the most recent federally issued 9-digit number you use to file your income taxes so that we may verify with Canada Revenue Agency</el-radio>
          <el-input @change="checkCitizenship()" v-model="form.provide_number" :maxlength="9" v-if="form.tax_insurance === 'Yes'" style="width: 150px; margin-left: 25px" />
        </v-col>
        <v-col cols="12" class="mb-3 ml-3">
          <el-radio @change="checkCitizenship()" v-model="form.tax_insurance" label="No">No, I declare that I do not have a SIN, an ITN or a TTN and was not required to file income taxes for any of the past five (5) taxation years.</el-radio>
        </v-col>
        <v-col cols="12">
          <p>b) Tell us when you filed your income taxes in the last five (5) years using the chart below.</p>
        </v-col>
      </v-row>
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <table class="table" style="width:100%">
        <thead>
          <tr>
            <th rowspan="2">Tax Year</th>
            <th colspan="2">Required to file</th>
            <th colspan="2">Taxes files</th>
            <th rowspan="2" width="30">
              <v-btn color="green" small fab @click.native="saveForm()">
                <v-icon class="white--text">add</v-icon>
              </v-btn>
            </th>
          </tr>
          <tr>
            <th width="100">Yes</th>
            <th width="100">No</th>
            <th width="100">Yes</th>
            <th width="100">No</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(form,i) in items" :key="i">
            <td class="pa-1">
              <el-input v-if="dataValidationItems(form, 'year')" type="number" v-model="form.year" style="width: 100%" @change="handleAutoSave(i, form)" />
            </td>
            <td class="pa-1 text-center">
              <el-radio v-if="dataValidationItems(form, 'required')" v-model="form.required" label="Yes" @change="handleAutoSave(i, form)"></el-radio>
            </td>
            <td class="pa-1 text-center">
              <el-radio v-if="dataValidationItems(form, 'required')" v-model="form.required" label="No" @change="handleAutoSave(i, form)"></el-radio>
            </td>
            <td class="pa-1 text-center">
              <el-radio v-if="dataValidationItems(form, 'taxes')" v-model="form.taxes" label="Yes" @change="handleAutoSave(i, form)"></el-radio>
            </td>
            <td class="pa-1 text-center">
              <el-radio v-if="dataValidationItems(form, 'taxes')" v-model="form.taxes" label="No" @change="handleAutoSave(i, form)"></el-radio>
            </td>
            <td class="pa-1 text-center">
              <v-btn color="pink" small fab @click.native="deleteOpen(form, i)"><v-icon class="white--text">clear</v-icon></v-btn>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="mt-3 mb-1">With your consent to the sharing of your income tax filing history between IRCC and Canada Revenue Agency (CRA), IRCC will
      use this information to determine if you meet the income tax filing requirement and physical presence requirement of the
      Citizenship Act. Note that if you do not provide your consent for these specific purposes, your application will be returned
      as incomplete and will not be processed.</p>
    </v-card-text>
    <v-card-text class="pa-2 pt-0" v-if="!isDataValidation">
      <v-row dense>
        <v-col cols="12">
          <div class="text13 mb-3">c) I authorize IRCC to disclose my SIN, ITN, or TTN, as the case may be, to the CRA for the purpose of requesting my income
          tax information and filing history from the CRA and for the CRA to disclose my income tax information and filing history
          from the CRA's Income Tax and Benefit Return file to IRCC for the taxation year(s) indicated above for the purpose of assessing
          my citizenship application against the tax filing requirement of subparagraph 5(1)(c)(iii) of the Citizenship Act and the
          physical presence requirement of subparagraphs 5(1)(c)(i) of the Citizenship Act.</div>
          <el-radio-group class="ml-3 mt-1" @change="checkCitizenship()" v-model="form.authorize">
            <el-radio label="No">No</el-radio>
            <el-radio label="Yes">Yes</el-radio>
          </el-radio-group>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  name: 'TaxInsurance',
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        year: null,
        required: null,
        taxes: null
      },
      url: 'imms_table/application_tax_insurance',
      loading: false,
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      form: 'citizenship/get'
    })
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_tax_insurance?filter=member_uid=${this.memberUID}&order=year DESC`)
        .then(response => {
          let vm = this
          if (vm.isDataValidation) {
            response.resource.forEach(function(item) {
              var form = {
                year: null,
                required: null,
                taxes: null
              }

              var obj = vm.checkFormItems(item, form)

              if (obj != 'empty') {
                vm.items.push(item)
              }
            })

            if (response.resource.length == 0) {
              vm.hideSection = true
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
          else {
            vm.items = response.resource
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    checkCitizenship() {
      this.$store.dispatch('citizenship/edit', this.form)
        .then(response => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Disabled', type: 'success' })
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        year: null,
        required: null,
        taxes: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>
