<template>
  <div>
    <v-card v-if="hideSection" class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
        <el-checkbox label="Is the foreign national currently in Canada" @change="handleAutoSave(form)" v-model="form.foreign_national_current_in_canada"></el-checkbox>
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="yukon-checkbox">
          <el-form-item prop="declare_canada_pr_citizen" v-if="dataValidation(form, 'declare_canada_pr_citizen')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.declare_canada_pr_citizen"></el-checkbox>
            <label class="ml-4">I declare that I am a Canadian Citizen or permanent resident of Canada.</label>
          </el-form-item>
          <el-form-item prop="business_good_standing_yukon" v-if="dataValidation(form, 'business_good_standing_yukon')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.business_good_standing_yukon"></el-checkbox>
            <label class="ml-4">I declare that the business is in good standing with the Yukon Employment Standards and Yukon Workers Compensation Health and Safety Board and that there are no current labour disputes.</label>
          </el-form-item>
          <el-form-item prop="no_conflict_position" v-if="dataValidation(form, 'no_conflict_position')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.no_conflict_position"></el-checkbox>
            <label class="ml-4">I delcare the employment of the posistion does not conflict with any existing bargining agreements, the settlement of any labour disputeor the employmnet of a person involved in such a dispute.</label>
          </el-form-item>
          <el-form-item prop="no_charge" v-if="dataValidation(form, 'no_charge')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.no_charge"></el-checkbox>
            <label class="ml-4">I delcare that I will not directly or indirectly, charge or collect a fee or recieve any financial gains in nominating this foreign worker.</label>
          </el-form-item>
          <el-form-item prop="verified_work_experience" v-if="dataValidation(form, 'verified_work_experience')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.verified_work_experience"></el-checkbox>
            <label class="ml-4">I declare that I have verified the foreign nationals previous work experience outside of Canada, (f applicable) status, in Canada (if applicable) and have submitted proof of verification with my application.</label>
          </el-form-item>
          <el-form-item prop="interviewed" v-if="dataValidation(form, 'interviewed')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.interviewed"></el-checkbox>
            <label class="ml-4">I declare that I have interviewed the foreign national and determined that he/she has the required skills as per EDSC NOC job description, and the experience to perform the duties of the position.</label>
          </el-form-item>
          <el-form-item prop="provide_employment" v-if="dataValidation(form, 'provide_employment')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.provide_employment"></el-checkbox>
            <label class="ml-4">I agree to provide employment for the foreign national on a permanent (indeterminable) full-time basis as outlined in the Guaranteed Employment Offer or Section 2 of the application.</label>
          </el-form-item>
          <el-form-item prop="health_insurance" v-if="dataValidation(form, 'health_insurance')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.health_insurance"></el-checkbox>
            <label class="ml-4">I understand that I have to provide health insurance benefits for the nominee until they become eligible for Yukon insured health care.</label>
          </el-form-item>
          <el-form-item prop="provide_complete_app" v-if="dataValidation(form, 'provide_complete_app')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.provide_complete_app"></el-checkbox>
            <label class="ml-4">I understand that failure to provide a complete application, including all required forms and credible supporting documentation, may result in the return or denial of this application.</label>
          </el-form-item>
          <el-form-item prop="will_be_disclosed" v-if="dataValidation(form, 'will_be_disclosed')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.will_be_disclosed"></el-checkbox>
            <label class="ml-4">I understand that if an employer is found in violation of any term of the TPA as a result of investgations conducted by Advanced Education or the federal and territorial departments, and/or agencies, their names will be disclosed to the public.</label>
          </el-form-item>
          <el-form-item prop="tpa" v-if="dataValidation(form, 'tpa')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.tpa"></el-checkbox>
            <label class="ml-4">I will contact the Advanced Education Branch, within 14 calendar days of the nominee's arrival in Yukon to schedule and entry interview to sign the Tri-Partite Agreement (TPA). If the nominee is already present in Yukon, I will schedule an entry interview fro the nominee before the nominee comences work, within 14 days of the issuance of the acceptance letter. The entry interview must be held before the nominee comences work.</label>
          </el-form-item>
          <el-form-item prop="advanced_education_discretion" v-if="dataValidation(form, 'advanced_education_discretion')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.advanced_education_discretion"></el-checkbox>
            <label class="ml-4">I agree that a YNP officer may visit the site of future employement to ensure compliance with the requirements of the Yukon Nominee Program. The timing, number and lengths of visits will be at Advanced Education's discretion.</label>
          </el-form-item>
          <el-form-item prop="foregoing_statement" v-if="dataValidation(form, 'foregoing_statement')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.foregoing_statement"></el-checkbox>
            <label class="ml-4">I understand all foregoing statements. I have asked for and obtained an explanation for every point which was not clear.</label>
          </el-form-item>
          <el-form-item prop="tpa_future" v-if="dataValidation(form, 'tpa_future')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.tpa_future"></el-checkbox>
            <label class="ml-4">I understand that any false statements, concealment of a material fact or breach of the Tri-Partite Agreement may result in my organization's exclusion from the Yukon Nominee Program in the future, and/or the revocation of a nomination.</label>
          </el-form-item>
          <el-form-item prop="exempt_lmia" v-if="dataValidation(form, 'exempt_lmia')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.exempt_lmia"></el-checkbox>
            <label class="ml-4">I understand that employers of foreign nationals who are exempt from the Labour Market impact Assessment (LMIA) process will be required to submit the offer of employment information and pay the compliance fee using IRCC Employer Portal http://www.cic.gic.gc.ca/english/e-services/employer-portal.asp. This will need to be done before the foreign national can apply for an employer-specific work permit, at a Mission overseas or in-Canada office.</label>
          </el-form-item>
          <el-form-item prop="collect_info_ynp" v-if="dataValidation(form, 'collect_info_ynp')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.collect_info_ynp"></el-checkbox>
            <label class="ml-4">I authorize the YNP to collect information from other sources inside or outside Canada ofr the purpose of assessing this application for the YNP, verifying information provided in this application, and evaluating the YNP. These sources may include, but are not limited to professional organizations, indusrty associations, educational institutions, financial institutions, government or quasi government agencies, and law enforcement agencies.</label>
          </el-form-item>
          <el-form-item prop="application_true" v-if="dataValidation(form, 'application_true')">
            <el-checkbox @change="handleAutoSave(form)" v-model="form.application_true"></el-checkbox>
            <label class="ml-4">I declare that the information I have given in this application is truthful, complete and correct.</label>
          </el-form-item>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  mixins: [autosave],
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        declare_canada_pr_citizen: null,
        business_good_standing_yukon: null,
        no_conflict_position: null,
        no_charge: null,
        verified_work_experience: null,
        interviewed: null,
        provide_employment: null,
        health_insurance: null,
        provide_complete_app: null,
        will_be_disclosed: null,
        tpa: null,
        advanced_education_discretion: null,
        foregoing_statement: null,
        tpa_future: null,
        exempt_lmia: null,
        collect_info_ynp: null,
        application_true: null
      },
      url: 'imms_table/lmia_yukon_employer_declarations',
      getUrl: 'imms_table/lmia_yukon_employer_declarations?application_imms_uuid=' + this.$route.params.id
    }
  },
  mounted() {
    this.fetch().then(data => {
      if (data.items != undefined && data.items.length > 0) {
        if (this.isDataValidation) {
          this.checkRow(data.items[0])
        }
        this.form = data.items[0]
      }
      else {
        if (this.isDataValidation) {
          this.checkRow(this.form)
        }
      }
    })
  }
}
</script>
