<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>
        <span>Please provide an itemized list of your expenditures to date, including a short description of the expenditure.</span>
        <v-spacer />
        <v-btn color="primary" dark small @click.native="saveForm()">Add</v-btn>
      </v-card-title>
      <v-card-text class="p-n">
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item :name="i+1" v-for="(item,i) in items" :key="i" :id="'row' + item.id">
            <template slot="title">
              <span class="el-col-title">{{item.expenditure_item}}</span>
            </template>
            <el-form :model="item" ref="form" label-position="top">
              <v-row dense>
                <v-col cols="12" sm="6">
                  <el-form-item label="Expenditure Item" prop="expenditure_item">
                    <el-input :id="'expenditure_item' + item.id" v-model="item.expenditure_item" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6">
                  <el-form-item label="Actual Investment ($CAD)" prop="actual_investment">
                    <el-input :id="'actual_investment' + item.id" type="number" v-model="item.actual_investment">
                      <template slot="prepend">$</template>
                    </el-input>
                  </el-form-item>
                </v-col>
                <v-col cols="12">
                  <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm(i,item)">
                    {{ $t('global.save') }}
                  </v-btn>
                  <v-btn color="error" small @click.native="deleteOpen(item,i)">Delete</v-btn>
                </v-col>
              </v-row>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        expenditure_item: '',
        actual_investment: ''
      },
      activeNames: -1,
      url: 'imms_table/application_expenditure',
      state: 'expenditure'
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = 'table/application_expenditure?filter=member_uid=' + this.memberUID
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) this.checkItemsFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.form = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        expenditure_item: '',
        actual_investment: ''
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.form)
      var len = this.items.length
      this.activeNames = len
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true

          if (formObj.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>
