<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
        <v-btn v-if="layout === 'collapse'" color="primary" dark small @click.native="saveForm()">Add</v-btn>
      </v-card-title>
      <v-card-text class="p-n">
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <template v-if="layout === 'collapse'">
          <el-collapse v-model="activeNames" accordion>
            <el-collapse-item :name="i+1" v-for="(item,i) in items" :key="i" :id="'row' + item.id">
              <template slot="title">
                <el-tag type="primary" v-if="item.startdate != null">{{ item.startdate | day }}</el-tag> -
                <el-tag type="success" v-if="item.enddate != null">{{ item.enddate | day }}</el-tag>
                {{item.bussinessname}}
              </template>
              <el-form :model="item" ref="form" label-position="top">
                <v-row dense>
                    <v-col cols="12" sm="6">
                      <el-form-item label="Business Name" prop="bussinessname">
                        <el-input :id="'bussinessname' + item.id" v-model="item.bussinessname" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <el-form-item label="Title" prop="title">
                        <el-input :id="'title' + item.id" v-model="item.title" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <el-form-item label="Start Dates" prop="startdate">
                        <el-date-picker :id="'startdate' + item.id" value-format="yyyy-MM-dd" v-model="item.startdate" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <el-form-item label="End Dates" prop="enddate">
                        <el-date-picker :id="'enddate' + item.id" value-format="yyyy-MM-dd" v-model="item.enddate" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <el-form-item label="Location" prop="location">
                        <el-input :id="'location' + item.id" v-model="item.location" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <el-form-item label="# employees managed" prop="emp_managed">
                        <el-input :id="'emp_managed' + item.id" type="number" v-model="item.emp_managed" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                  <v-col cols="12">
                    <v-btn :loading="loading" :disabled="loading" color="primary" @click.native="submitForm(i,item)">
                      {{ $t('global.save') }}
                      <template #loader>
                        <span>Saving...</span>
                      </template>
                    </v-btn>
                    <v-btn color="error" small @click.native="deleteOpen(item,i)">Delete</v-btn>
                  </v-col>
                </v-row>
              </el-form>
            </el-collapse-item>
          </el-collapse>
        </template>
        <template v-if="layout === 'table'">
          <table class="table" style="width:100%">
            <thead>
              <tr>
                <th>Business Name</th>
                <th>Title</th>
                <th>Start Dates</th>
                <th>End Dates</th>
                <th>Location</th>
                <th># employees managed</th>
                <th width="30">
                  <v-btn color="green" small fab @click.native="saveForm()">
                    <v-icon class="white--text">add</v-icon>
                  </v-btn>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in items" :key="i" :id="'row' + item.id">
                <td class="pa-1">
                  <el-input :id="'bussinessname' + item.id" v-model="item.bussinessname" style="width: 100%" />
                </td>
                <td class="pa-1">
                  <el-input :id="'title' + item.id" v-model="item.title" style="width: 100%" />
                </td>
                <td class="pa-1">
                  <el-date-picker :id="'startdate' + item.id" value-format="yyyy-MM-dd" v-model="item.startdate" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </td>
                <td class="pa-1">
                  <el-date-picker :id="'enddate' + item.id" value-format="yyyy-MM-dd" v-model="item.enddate" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </td>
                <td class="pa-1">
                  <el-input :id="'location' + item.id" v-model="item.location" style="width: 100%" />
                </td>
                <td class="pa-1">
                  <el-input :id="'emp_managed' + item.id" type="number" v-model="item.emp_managed" style="width: 100%" />
                </td>
                <td class="pa-1 text-center">
                  <v-btn color="pink" small fab @click.native="deleteOpen(item, i)"><v-icon class="white--text">clear</v-icon></v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </v-card-text>
      <v-card-actions v-if="layout === 'table'" class="pa-3">
        <v-spacer />
        <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="allSaveForm()">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    },
    layout: {
      type: String
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        title: null,
        location: null,
        startdate: null,
        enddate: null,
        emp_managed: null,
        bussinessname: null,
        sec_org_id: this.$orgId
      },
      activeNames: -1,
      url: 'imms_table/application_business_experience',
      state: 'businessExperience',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    checkDate: function(event) {
      this.$emit('checkDate', event)
    },
    fetchData() {
      this.$Progress.start()
      var url = 'table/application_business_experience?filter=member_uid=' + this.memberUID
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state, 'order': 'to_date' })
        .then(response => {
          if (this.datas !== undefined) this.checkTableFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.form = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        title: null,
        location: null,
        startdate: null,
        enddate: null,
        emp_managed: null,
        bussinessname: null,
        sec_org_id: this.$orgId
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.form)
      var len = this.items.length
      this.activeNames = len
    },
    allSaveForm() {
      if (this.items.length > 0) {
        this.loading = true
        this.items.forEach(function(item) {
          var action = 'all_stores/save'
          if (item.id != undefined) {
            action = 'all_stores/edit'
            item.id = parseFloat(item.id)
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': item, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                item.id = response.data.resource[0].id
                item.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }, this)
      }
      else {
        return false
      }
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true

          if (formObj.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.item.resetFields()
      this.nodeClear()
    }
  }
}
</script>
