<template>
  <div class="mt-3 citizenship-calculator">
    <v-card v-if="hideSection">
      <v-card-title>
        <span>{{sectionLabel}}</span>
      </v-card-title>
      <v-card-text class="p-n">
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="left">
          <v-row dense v-if="dataValidation(form, 'sign_date')">
            <v-col cols="12" sm="7" md="8">
              <div class="text13">
                <strong>A </strong>Enter the date you will sign your application form in Box A.</div>
            </v-col>
            <v-col cols="12" sm="5" md="4">
              <el-form-item props="sign_date" label="Box A">
                <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="form.sign_date" @change="handleAutoSave(0, form);boxA()"></el-date-picker>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense v-if="dataValidation(form, 'sign_before_date')">
            <v-col cols="12" sm="7" md="8">
              <div class="text13">
                <strong>B </strong>Enter the date 5 years immediately before you will sign your application in Box B.</div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item props="sign_before_date" label="Box B">
                <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="form.sign_before_date" disabled></el-date-picker>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense v-if="dataValidation(form, 'sign_between_days')">
            <v-col cols="12" sm="7" md="8">
              <div class="text13">
                <strong>C </strong>Calculate the total number of days between Box B and Box A.</div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <label class="box-label">Total number of days</label>
              <el-form-item props="sign_between_days" label="Box C">
                <el-input placeholder="Total number of days" v-model="form.sign_between_days" class="w220" disabled />
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
    </v-card>
    <v-card v-if="hideSection" class="mt-3">
      <v-card-title>
        <span>Section 2 - Calculating physical present
          <u>after becoming a permanent resident</u> in 5 years immediately before the date you sign your application</span>
      </v-card-title>
      <v-card-text class="p-n">
        <el-form :model="form" ref="form" label-position="left">
          <v-row dense v-if="dataValidation(form, 'pr_date')">
            <v-col cols="12" sm="7" md="8">
              <div class="text13">
                <strong>D </strong>Enter the date that you became a permanent resident in Box D.</div>
            </v-col>
            <v-col cols="12" sm="5" md="4">
              <el-form-item props="pr_date" label="Box D">
                <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="form.pr_date" @change="handleAutoSave(0, form);boxD()"></el-date-picker>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense v-if="dataValidation(form, 'pr_sign_between_days')">
            <v-col cols="12" sm="7" md="8">
              <div class="text13">
                <strong>E </strong>Calculate the total number of days between Box D and in Box A.</div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <label class="box-label">Total number of days</label>
              <el-form-item props="pr_sign_between_days" label="Box E">
                <el-input placeholder="Total number of days" v-model="form.pr_sign_between_days" class="w220" disabled />
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
    </v-card>

    <permanent-resident-calculator
      :formCalculate="form"
      :memberUID="memberUID"
      :memberID="memberID"
      :isDataValidation="isDataValidation"
    />

    <v-card v-if="!isDataValidation">
      <v-card-text>
        <el-form :model="form" ref="form" label-position="right" label-width="500px">
          <v-row dense>
            <v-col cols="12" sm="8" offset-sm="4">
              <el-form-item props="days_absent_temporary" label="Enter the total number of days absent from Canada">
                <el-input v-model="days_absent_temporary" class="w220" disabled />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="8" offset-sm="4">
              <el-form-item props="days_absent" label="Days of physical PR presence">
                <el-input v-model="form.days_absent" class="w220" disabled />
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
    </v-card>

    <temporary-residence-calculator
      v-if="form.days_absent_total <= 1095"
      :formCalculate="form"
      :memberUID="memberUID"
      :memberID="memberID"
      :isDataValidation="isDataValidation"
    />

    <v-card v-if="!isDataValidation">
      <v-card-text>
        <el-form :model="form" ref="form" label-position="right" label-width="500px">
          <v-row dense>
            <v-col cols="12" sm="8" offset-sm="4">
              <el-form-item props="pp_total_credit" label="Physical Presence as a Permanent Resident">
                <el-input v-model="pp_total_credit" class="w220" disabled />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="8" offset-sm="4">
              <el-form-item props="tp_pp_total_credit" label="Physical Presence as Temporary Resident or Protected Person credit">
                <el-input v-model="form.tp_pp_total_credit" class="w220" disabled />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="8" offset-sm="4">
              <el-form-item props="total_physical_presence" label="Total Physical Presence">
                <el-input v-model="form.total_physical_presence" class="w220" disabled />
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="handleAutoSave(0, form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import PermanentResidentCalculator from './permanent_resident_calculator'
import TemporaryResidenceCalculator from './temporary_residence_calculator'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        sign_date: null,
        sign_before_date: null,
        sign_between_days: null,
        pr_date: null,
        pr_sign_between_days: null,
        days_absent_total: null,
        days_absent: 0,
        days_absent_temporary: null,
        total_eligibility_period: null,
        pp_total_credit: null,
        tp_pp_total_credit: null,
        total_physical_presence: null,
        absences_canada: null,
        eligibility_period: null
      },
      url: 'imms_table/application_citizenship_calculator',
      item: {},
      loading: false,
      count: 0
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    },
    days_absent_temporary: function(val) {
      this.form.days_absent_temporary = val
    },
    pp_total_credit: function(val) {
      this.form.pp_total_credit = val

      var tp_pp_total_credit = this.form.total_eligibility_period - this.form.days_absent
      if (tp_pp_total_credit > 365) {
        tp_pp_total_credit = 365
      }
      this.form.tp_pp_total_credit = tp_pp_total_credit
      var total_physical_presence = this.form.pp_total_credit + tp_pp_total_credit
      this.form.total_physical_presence = total_physical_presence
    },
    totalDays: function(val) {
      this.form.box_i = val
    }
  },
  computed: {
    days_absent_temporary: function() {
      return this.form.days_absent_total - this.form.days_absent
    },
    pp_total_credit: function() {
      return this.form.pr_sign_between_days - this.form.days_absent_temporary
    }
  },
  components: {
    PermanentResidentCalculator,
    TemporaryResidenceCalculator
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_citizenship_calculator?filter=member_uid=${this.memberUID}&order=id ASC`)
        .then(response => {
          if (response.resource.length > 0) {
            this.form = response.resource[0]
            if (this.isDataValidation) {
              this.checkRow(response.resource[0])
            }
          }
          else {
            if (this.isDataValidation) {
              this.checkRow(this.form)
            }
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    boxA() {
      if (this.form.sign_date !== null && this.form.sign_date !== undefined) {
        var boxB = moment(this.form.sign_date).add(-5, 'y')
        var a = moment(this.form.sign_date, 'YYYY-MM-DD')
        var b = moment(boxB, 'YYYY-MM-DD')

        var length = a.diff(b, 'days')
        this.form.sign_before_date = moment(boxB).format('YYYY-MM-DD')
        if (length >= 1827) {
          this.$message.error('Please check to date.')
        }
        else {
          this.form.sign_between_days = length
        }
      }
    },
    boxD() {
      if (this.form.pr_date !== null && this.form.pr_date !== undefined && this.form.sign_date !== null && this.form.sign_date !== undefined) {
        var a = moment(this.form.sign_date, 'YYYY-MM-DD')
        var d = moment(this.form.pr_date, 'YYYY-MM-DD')

        var length = a.diff(d, 'days')
        if (length < 0) {
          this.form.pr_date = null
          this.form.pr_sign_between_days = null
          this.$message.error('Please check gaps between dates the Box D and Box A.')
        }
        else {
          this.form.pr_sign_between_days = length
        }
      }
    }
  }
}
</script>
