<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <span></span>
        <v-spacer />
        <v-btn color="primary" dark small @click.native="saveForm()">Add</v-btn>
      </v-card-title>
      <v-card-text class="p-n">
        <v-alert type="info" border="left" dense class="mb-2" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <v-row dense class="customCollapseHeader">
          <v-col cols="6" sm="4">First name</v-col>
          <v-col cols="6" sm="4">Last name</v-col>
          <v-col class="d-none d-sm-flex" sm="4">Birth Date</v-col>
        </v-row>
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i">
            <template slot="title">
              <div class="customHead">
                <v-row dense>
                  <v-col cols="6" sm="4">{{ form.fname }}</v-col>
                  <v-col cols="6" sm="4">{{ form.lname }}</v-col>
                  <v-col class="d-none d-sm-flex" sm="4">
                    <el-tag type="primary" class="ml-3" v-if="form.birth_date !== null && form.birth_date != undefined">{{ form.birth_date | day }}</el-tag>
                  </v-col>
                </v-row>
              </div>
            </template>
            <el-form :model="form" ref="form" label-position="top">
              <v-row dense>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <el-form-item label="First name" prop="fname">
                    <el-input :id="'fname' + form.id" @change="handleAutoSave(i, form)" v-model="form.fname" style="width: 100%;" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <el-form-item label="Middle name" prop="middle_name">
                    <el-input :id="'middle_name' + form.id" @change="handleAutoSave(i, form)" v-model="form.middle_name" style="width: 100%;" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <el-form-item label="Last name" prop="lname">
                    <el-input :id="'lname' + form.id" @change="handleAutoSave(i, form)" v-model="form.lname" style="width: 100%;" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <el-form-item label="Date of Birth" prop="birth_date">
                    <el-date-picker :id="'birth_date' + form.id" :picker-options="datePickerOptions" @change="handleAutoSave(i, form)" value-format="yyyy-MM-dd" v-model="form.birth_date" v-on:click.native="checkDate($event)" style="width: 100%"></el-date-picker>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <el-form-item label="Relationship" prop="type">
                    <el-select :id="'type' + form.id" v-model="form.type" @change="handleAutoSave(i, form)" placeholder="Select" style="width: 100%;">
                      <el-option :value="item.name" v-for="(item, index) in relationshipStatus" v-bind:key="index">{{ item.name }}</el-option>
                    </el-select>
                  </el-form-item>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                  <el-form-item label="Marital Status" prop="marital_status">
                    <el-select :id="'marital_status' + form.id" @change="handleAutoSave(i, form)" v-model="form.marital_status" style="width: 100%;">
                      <el-option v-for="item in maritalStatus" :key="item.name" :label="item.name" :value="item.name">{{ item.name }}</el-option>
                    </el-select>
                  </el-form-item>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                  <el-form-item label="Place of Birth (City/Town)" prop="place">
                    <el-input :id="'place' + form.id" @change="handleAutoSave(i, form)" v-model="form.place" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                  <el-form-item label="Country of Birth" prop="country_id">
                    <div class="el-select native-select">
                      <div class="el-input">
                        <select :id="'country_id' + form.id" @change="handleAutoSave(i, form)" v-model="form.country_id" placeholder="Select" class="el-input__inner">
                          <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                        </select>
                      </div>
                    </div>
                  </el-form-item>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                  <el-form-item label="Present Address" prop="address">
                    <el-input :id="'address' + form.id" @change="handleAutoSave(i, form)" v-model="form.address" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12">
                  <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="handleAutoSave(i,form)">
                    {{ $t('global.save') }}
                  </v-btn>
                  <v-btn color="error" small @click.native="deleteOpen(form,i)">Delete</v-btn>
                </v-col>
              </v-row>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import autosave from '@/mixins/autosave.js'

export default {
  mixins: [autosave],
  props: {
    sectionId: {
      type: String
    },
    layout: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        fname: null,
        middle_name: null,
        lname: null,
        birth_date: null,
        place: null,
        marital_status: null,
        address: null,
        type: null,
        country_id: null
      },
      activeNames: -1,
      loading: false,
      url: 'imms_table/application_sponsorship_pre_add_family_members',
      fields: ['fname', 'middle_name', 'lname', 'birth_date', 'place', 'marital_status', 'address', 'type', 'country_id'],
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      maritalStatus: 'other/maritalStatus',
      relationshipStatus: 'other/relationshipStatus'
    })
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/application_sponsorship_pre_add_family_members?filter=member_uid=${this.memberUID}&order=birth_date DESC`
      this.$store.dispatch('store/get', url)
        .then(response => {
          this.items = response.resource
          if (this.datas !== undefined) {
            this.checkItemsFields(response.resource, this.datas, this.sectionId)
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        family_name: null,
        middle_name: null,
        given_name: null,
        birth_date: null,
        place: null,
        marital_status: null,
        address: null,
        type: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>
