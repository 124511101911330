<template>
  <div>
    <v-card class="mt-3 bquestion">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="form citizenship_act">
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label"><b>A</b> Is the child now:</label>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="7" offset-sm="4" sm="4" offset-md="7" md="3">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label">on probation in Canada?</label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="a1">
                <el-radio-group v-model="form.a1" class="pt-0" @change="submitForm">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="7" offset-sm="4" sm="4" offset-md="7" md="3">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label">on parole in Canada?</label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="a2">
                <el-radio-group v-model="form.a2" class="pt-0" @change="submitForm">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="7" offset-sm="4" sm="4" offset-md="7" md="3">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label">serving a term of imprisonment in Canada?</label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="a3">
                <el-radio-group v-model="form.a3" class="pt-0" @change="submitForm">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-2"></v-divider>
          <v-row dense class="radio-parent">
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label">
                  <b>B.</b> Is the child now serving a sentence outside Canada for an offence?
                </label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="a3">
                <el-radio-group v-model="form.b" class="pt-0" @change="submitForm">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-2"></v-divider>
          <v-row dense class="radio-parent">
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label"><b>C.</b> Is the child now charged with, on trial for, or subject to or a party to an appeal relating to an offence under the Citizenship
                Act or an indictable offence in Canada?</label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="c">
                <el-radio-group v-model="form.c" class="pt-0" @change="submitForm">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-2"></v-divider>
          <v-row dense class="radio-parent">
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label"><b>D.</b> Is the child now charged with, on trial for, or subject to or a party to an appeal relating to an offence committed outside
                Canada?</label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="d">
                <el-radio-group v-model="form.d" class="pt-0" @change="submitForm">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-2"></v-divider>
          <v-row dense class="radio-parent">
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label"><b>E.</b> Is the child now, or has the child ever been, under a removal order (has the child been asked by Canadian officials to leave
                Canada)?</label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="e">
                <el-radio-group v-model="form.e" class="pt-0" @change="submitForm">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-2"></v-divider>
          <v-row dense class="radio-parent">
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label"><b>F.</b> Is the child now under investigation for, charged with, on trial for, subject to or a party to an appeal relating to or has
                the child ever been convicted of a war crime or a crime against humanity?</label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="f">
                <el-radio-group v-model="form.f" class="pt-0" @change="submitForm">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-2"></v-divider>
          <v-row dense class="radio-parent">
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label"><b>G.</b> In the past five (5) years, was the child prohibited from being granted citizenship or taking the Oath of Citizenship for
                reasons of misrepresentation or withholding of material circumstances?</label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="g">
                <el-radio-group v-model="form.g" class="pt-0" @change="submitForm">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-2"></v-divider>
          <v-row dense class="radio-parent">
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label"><b>H.</b> In the past four (4) years, has the child been convicted in Canada of an indictable offence under any Act of Parliament or
                an offence under the Citizenship Act?</label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="h">
                <el-radio-group v-model="form.h" class="pt-0" @change="submitForm">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-2"></v-divider>
          <v-row dense class="radio-parent">
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label"><b>I.</b> In the past four (4) years, has the child been convicted outside Canada of an offence, regardless of whether the child was
                pardoned or otherwise granted amnesty for the offence?</label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="i">
                <el-radio-group v-model="form.i" class="pt-0" @change="submitForm">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-2"></v-divider>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label"><b>J.</b> While a permanent resident, has the child:</label>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="7" offset-sm="2" sm="8" offset-md="3" md="7">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label">been convicted in Canada of terrorism, high treason, treason, or spying offences?</label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="j1">
                <el-radio-group v-model="form.j1" class="pt-0" @change="submitForm">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="7" offset-sm="2" sm="8" offset-md="3" md="7">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label">been convicted outside Canada of terrorism?</label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="j2">
                <el-radio-group v-model="form.j2" class="pt-0" @change="submitForm">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="7" offset-sm="2" sm="8" offset-md="3" md="7">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label">served as a member of an armed force of a country OR organized armed group and that country or group engaged in armed conflict with Canada?</label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="j3">
                <el-radio-group v-model="form.j3" class="pt-0" @change="submitForm">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <div class="el-form-item mt-1">
                <label class="el-form-item__label">I am the parent or guardian of this child. I have read and understand the prohibitions under the Citizenship act. <br> If you
                have checked "YES" to any of the prohibitions listed above, provide details below. If applicable, attach court documents.</label>
              </div>
              <el-form-item prop="j_text">
                <el-input v-model="form.j_text" type="textarea" :rows="10" @change="submitForm" />
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="save()">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'CitizenshipAct',
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      formNode: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        a1: null,
        a2: null,
        a3: null,
        b: null,
        c: null,
        d: null,
        e: null,
        f: null,
        g: null,
        h: null,
        i: null,
        j1: null,
        j2: null,
        j3: null,
        j_text: null
      },
      url: 'imms_table/application_citizenship_act',
      state: 'citizenshipAct',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    item: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    },
    form: function() {
      if (this.item.length > 0) {
        return this.item[0]
      }
      else {
        return this.formNode
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/application_citizenship_act?filter=member_uid=${this.memberUID}`
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    save() {
      this.$emit("onSave")
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.form.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': this.form, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                this.form.id = response.data.resource[0].id
                this.form.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    }
  }
}
</script>

<style>
  .citizenship_act .el-form-item{
    margin-bottom: 8px;
  }
</style>
