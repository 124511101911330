<template>
  <div>
    <components
      v-for="(section, sindex) in sections"
      :key="sindex"  
      :is="section.alias"
      :sectionLabel="section.label"
      :sectionInfoBox="section.info"
      :memberUID="memberUID"
      :memberID="memberID"
      :datas="datas"
      @checkDate="checkDate"
    >
    </components>
  </div>
</template>

<script>
import Asset from './financial/asset'
import BankDeposit from './financial/bank_deposit'
import Business from './financial/business'
import FixedDeposit from './financial/fixed_deposit'
import Property from './financial/property'
import Stock from './financial/stock'
import NetWorth from './financial/net_worth'

export default {
  props: {
    sections: {
      type: Array
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  components: {
    Asset,
    BankDeposit,
    Business,
    FixedDeposit,
    Property,
    Stock,
    NetWorth
  },
  methods: {
    checkDate: function(event) {
      this.$emit('checkDate', event)
    }
  }
}
</script>