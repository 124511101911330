<template>
  <div class="mt-3" ref="businessExplorationTrip">
    <v-card>
      <v-card-title>
        <span>{{ $t('title.application_business_exploration_trip') }}</span>
        <el-checkbox class="ml-5" v-model="notApplicable" @change="checkApplicable()">{{ $t('global.yes') }}</el-checkbox>
      </v-card-title>
      <v-card-text class="p-n">
        <v-alert type="info" border="left" dense class="mb-3" v-if="$t('infobox.application_business_exploration_trip') !== ''">
           {{ $t('infobox.application_business_exploration_trip') }}
        </v-alert>
        <table class="table" style="width:100%">
          <thead>
            <tr>
              <th>{{ $t('application_business_exploration_trip.give_details')}}</th>
              <th width="30">
                <v-btn :disabled="!notApplicable" color="green" small fab @click.native="addForm()">
                  <v-icon class="white--text">add</v-icon>
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in items" :key="i" :id="'row' + item.id">
              <td class="pa-1">
                <el-input :disabled="!notApplicable" :id="'give_details' + item.id" v-model="item.give_details" style="width: 100%" />
              </td>
              <td class="pa-1 text-center">
                <v-btn :disabled="!notApplicable" color="pink" small fab @click.native="deleteOpen(item, i)"><v-icon class="white--text">clear</v-icon></v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn :loading="loading" :disabled="loading || !notApplicable" color="primary" small @click.native="submitForm()">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        give_details: null
      },
      state: 'businessExplorationTrip',
      url: 'imms_table/application_business_exploration_trip',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      applicableId: 'applicable/id'
    }),
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    },
    notApplicable: {
      get() {
        return this.$store.state.applicable.notApplicable.business_exploration_trip
      },
      set(val) {
        this.$store.commit('applicable/set_business_exploration_trip', val)
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = 'table/application_business_exploration_trip?filter=member_uid=' + this.memberUID
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) {
            this.checkTableFields(this.items, this.datas, this.sectionId)

            if (this.notApplicable) {
              $(this.$refs.businessExplorationTrip).remove()
            }
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.form = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        give_details: null
      }
    },
    addForm() {
      this.nodeClear()
      this.items.push(this.form)
    },
    submitForm() {
      if (this.items.length > 0) {
        this.loading = true
        this.items.forEach(function(form) {
          var action = 'all_stores/save'
          if (form.id != undefined) {
            action = 'all_stores/edit'
            form.id = parseFloat(form.id)
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': form, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                form.id = response.data.resource[0].id
                form.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }, this)
      }
      else {
        return false
      }
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    },
    checkApplicable() {
      var action = 'applicable/save'
      var that = this
      var data = {}
      data.business_exploration_trip = that.notApplicable
      data.application_id = this.$route.params.id
      data.member_id = parseFloat(this.memberID)
      data.member_uid = this.memberUID
      if (that.applicableId != undefined) {
        data.id = that.applicableId
        action = 'applicable/edit'
      }
      this.$Progress.start()
      this.$store.dispatch(action, data).then(response => {
        this.$store.dispatch('toaster/add', { text: 'Yes', type: 'success' })
        this.$Progress.finish()
      })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'No', type: 'success' })
          this.$Progress.fail()
        })
    }
  }
}
</script>
