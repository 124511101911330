<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>
        <span>{{ $t('global.net_worth') }}</span>
      </v-card-title>
      <v-card-text class="p-n">
        <table class="table" style="width:100%">
          <tbody>
            <tr>
              <td class="pa-1">
                 {{ $t('global.total_assets') }} (A + B + C + D + E)
              </td>
              <td class="pa-1 text-right">
                CDN$
              </td>
              <td class="pa-1 text-right" width="150">
                <div>{{ total_assets | currency('',0) }}</div>
              </td>
            </tr>
            <tr>
              <td class="pa-1">
                {{ $t('global.less_total_liabilities') }} (F + G)
              </td>
              <td class="pa-1 text-right">
                - CDN$
              </td>
              <td class="pa-1 text-right" width="150">
                <div>{{ less_total | currency('',0) }}</div>
              </td>
            </tr>
            <tr>
              <td class="pa-1">
                <b>= {{ $t('global.net_worth') }}</b>
              </td>
              <td class="pa-1 text-right">
                  <b>= CDN$</b>
              </td>
              <td class="pa-1 text-right" width="150">
                <b>{{ total_assets - less_total | currency('',0) }}</b>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr><td colspan="3"></td></tr>
            <tr>
              <td class="pa-1">
                 {{ $t('global.total_funds_avialable') }}
              </td>
              <td class="pa-1 text-right">
                CDN$
              </td>
              <td class="pa-1 text-right" width="150">

              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    memberUID: {
      type: String,
      required: true
    }
  },
  computed: {
    asset: {
      get() {
        return this.$store.state.store['assetTotal']
      }
    },
    other_debt: {
      get() {
        return this.$store.state.store['otherDebtTotal']
      }
    },
    liability: {
      get() {
        return this.$store.state.store['liabilityTotal']
      }
    },
    bank_deposit: {
      get() {
        return this.$store.state.store['bankDepositTotal']
      }
    },
    business: {
      get() {
        return this.$store.state.store['businessTotal']
      }
    },
    fixed_deposit: {
      get() {
        return this.$store.state.store['fixedDepositTotal']
      }
    },
    property: {
      get() {
        return this.$store.state.store['propertyTotal']
      }
    },
    stock: {
      get() {
        return this.$store.state.store['stockTotal']
      }
    },
    total_assets: function() {
      return parseFloat(this.asset.amount_canadian) + parseFloat(this.bank_deposit.amount_canadian) + 
      parseFloat(this.business.amount_canadian) + parseFloat(this.fixed_deposit.amount_canadian) + 
      parseFloat(this.property.amount_canadian) + parseFloat(this.stock.amount_canadian)
    },
    less_total: function() {
      return parseFloat(this.liability.amount_canadian) + parseFloat(this.other_debt.amount_canadian)
    }
  },
  created() {
    this.$store.dispatch('store/getTotal', { 'url': 'table/application_other_debts?filter=member_uid=' + this.memberUID, state: 'otherDebtTotal' })
    this.$store.dispatch('store/getTotal', { 'url': 'table/application_liablities?filter=member_uid=' + this.memberUID, state: 'liabilityTotal' })
  }
}
</script>