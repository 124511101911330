import api from "./../plugins/axios";
import { sortMember } from "@/utils/sort";

let url = "table/imm_0008";

export const state = {
  generics: [],
  generic: {}
};

export const getters = {
  all(state) {
    return state.generics;
  },
  getOne(state) {
    return state.generic;
  }
};

export const actions = {
  getAll({ commit }, id) {
    return api.get(`application/imm_0008?applicant_id=${id}`).then(
      (response) => {
        var datas = sortMember(response.data.resource);
        commit("GET_DATAS", datas);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  getById({ commit }, id) {
    return api.get(url + "?filter=u_id=" + id).then(
      (response) => {
        commit("GET_DATA", response.data.resource[0]);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATA", err);
        return Promise.reject(err);
      }
    );
  },
  save({ commit }, data) {
    return api.post(url, { resource: data }).then(
      (response) => {
        commit("ADD_DATA", response.data);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  edit({ commit }, data) {
    return api.put(url + "/" + data.id, data).then(
      (response) => {
        commit("UPDATE_DATA", data);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  }
};

export const mutations = {
  GET_DATAS(state, datas) {
    state.generics = datas;
  },
  GET_DATA(state, data) {
    if (data != undefined && data.canadaprovince != undefined && data.canadaprovince !== null) data.canadaprovince = parseFloat(data.canadaprovince);
    state.generic = data;
  },
  ADD_DATA(state, data) {
    state.generics.push(data);
  },
  UPDATE_DATA(state, data) {
    let idx = state.generics.map((p) => p.id).indexOf(data.id);
    state.generics.splice(idx, 1, data);
  },
  FAILURE_DATAS(state) {
    state.generics = [];
  }
};
