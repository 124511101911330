import Vue from "vue";
import moment from "moment";

Vue.filter("times", function(value, formatString) {
  formatString = formatString || "HH:mm";
  return moment(value).format(formatString);
});

Vue.filter("time", function(value, formatString) {
  if (value == null || value === "") return "";
  formatString = formatString || "YYYY-MM-DD HH:mm";
  return moment(value).format(formatString);
});

Vue.filter("day", function(value, formatString) {
  formatString = formatString || "YYYY-MM-DD";
  if (value != "" && value != null) return moment(value).format(formatString);
  return "";
});

Vue.filter("date", function(value, formatString) {
  formatString = formatString || "dddd, MMMM DD, YYYY";
  if (value != "" && value != null) return moment(value).format(formatString);
  return "";
});

Vue.filter("month", function(value, formatString) {
  formatString = formatString || "YYYY-MM";
  return moment(value).format(formatString);
});

Vue.filter("year", function(value, formatString) {
  formatString = formatString || "YYYY";
  return moment(value).format(formatString);
});

const digitsRE = /(\d{3})(?=\d)/g;

Vue.filter("currency", function(value, currency, decimals) {
  value = parseFloat(value);
  if (!isFinite(value) || (!value && value !== 0)) return "";
  currency = currency != null ? currency : "$";
  decimals = decimals != null ? decimals : 2;
  var stringified = Math.abs(value).toFixed(decimals);
  var _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
  var i = _int.length % 3;
  var head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? "," : "") : "";
  var _float = decimals ? stringified.slice(-1 - decimals) : "";
  var sign = value < 0 ? "-" : "";
  return sign + head + _int.slice(i).replace(digitsRE, "$1,") + _float;
});
