import api from '../plugins/axios';
import axios from 'axios';
import constants from '../constants.js';

export const state = {
  applications: [],
  application: {},
  appType: [],
  appProcesses: [],
  files: [],
  processInputes: [
    { field: 'date_at_submitted', label: 'App Submit Date', type: 'date' },
    { field: 'date_hired', label: 'Date Hired', type: 'date' },
    { field: 'xxx', label: 'Arrival Date', type: 'date' },
    { field: 'expiry_date', label: 'Expiry Date', type: 'date' },
    { field: 'gov_portal_login', label: 'Portal Login', type: 'text' },
    { field: 'gov_portal_password', label: 'Portal Password', type: 'text' },
    { field: 'xxx', label: 'File No Got', type: 'date' },
    { field: 'cic_app_number', label: 'File Number', type: 'text' },
    { field: 'xxx', label: 'File Sent To', type: 'date' },
    { field: 'originals_mailed', label: 'Originals Mailed Date', type: 'date' },
    { field: 'xxx', label: 'Application Mailed', type: 'date' },
    { field: 'track_no', label: 'Tracking No', type: 'text' },
    {
      field: 'app_delivered_date',
      label: 'Application Delivered',
      type: 'date',
    },
    { field: 'approval_date', label: 'Approval Date', type: 'date' },
    {
      field: 'nomination_expiry_date',
      label: 'Nomination Expiried',
      type: 'date',
    },
    { field: 'medical_exam_date', label: 'Medical Examination', type: 'date' },
    {
      field: 'police_certificate_requested',
      label: 'Police Certificate Requested',
      type: 'date',
    },
    {
      field: 'police_certificate_submitted',
      label: 'Police Certificate Submitted',
      type: 'date',
    },
    {
      field: 'other_request_received',
      label: 'Other Requests Date Received',
      type: 'date',
    },
    {
      field: 'other_request_submitted',
      label: 'Other Request Submitted',
      type: 'date',
    },
    {
      field: 'landing_fee_requested',
      label: 'Landing Fee (RPRF) Requested',
      type: 'date',
    },
    {
      field: 'landing_fee_paid',
      label: 'Landing Fee (RPRF) paid',
      type: 'date',
    },
    { field: 'passport_request', label: 'Passport Request', type: 'date' },
    {
      field: 'got_confirmation_docs',
      label: 'Got confirmation docs',
      type: 'date',
    },
    { field: 'sent_type', label: 'Sent Type', type: 'text' },
    { field: 'sent_to', label: 'Sent To', type: 'text' },
  ],

  statuses: [
    { text: 'Created', value: constants.APP_STATUS_CREATED },
    { text: 'Purchased', value: constants.APP_STATUS_PURCHASED },
    { text: 'RCIC onboarding', value: constants.APP_STATUS_RCIC_ONBOARDING },
    {
      text: 'Forms/Recruitment',
      value: constants.APP_STATUS_FORMS_RECRUITMENT,
    },
    { text: 'Review', value: constants.APP_STATUS_REVIEW },
    {
      text: 'Waiting for final payment',
      value: constants.APP_STATUS_WAITING_FOR_PAYMENT,
    },
    { text: 'Ready to Submit', value: constants.APP_STATUS_READY_TO_SUBMIT },
    { text: 'Submitted', value: constants.APP_STATUS_SUBMITTED },
    { text: 'Follow up', value: constants.APP_STATUS_FOLLOW_UP },
    { text: 'Completed', value: constants.APP_STATUS_COMPLETE },
    { text: 'Archived', value: constants.APP_STATUS_ARCHIVED },
  ],

  applicationMenus: [
    { text: 'Edit', icon: 'edit', alias: 'edit', condition: 'isAll' },
    {
      text: 'Change organization',
      icon: 'swap_horiz',
      alias: 'organization',
      condition: 'isOfas',
    },
    {
      text: 'Change company',
      icon: 'sync',
      alias: 'company',
      condition: 'isAdmin',
    },
    {
      text: 'File Number',
      icon: 'insert_drive_file',
      alias: 'file_number',
      condition: 'isAll',
    },
    {
      text: 'Files',
      icon: 'insert_drive_file',
      alias: 'documents',
      condition: 'isAll',
    },
    {
      text: 'Run Robot',
      icon: 'picture_as_pdf',
      alias: 'pdf_generate',
      condition: '!isEmployer',
    },
    {
      text: 'Processing Details',
      icon: 'verified_user',
      alias: 'processing_details',
      condition: '!isEmployer',
    },
    {
      text: 'Position',
      icon: 'work',
      alias: 'position',
      condition: '!isEmployer',
    },
    {
      text: 'Change description',
      icon: 'description',
      alias: 'change_description',
      condition: 'isAll',
    },
    {
      text: 'Location',
      icon: 'location_on',
      alias: 'change_location',
      condition: 'isAll',
    },
    {
      text: 'Gov Request',
      icon: 'assignment_turned_in',
      alias: 'gov_request',
      condition: '!isEmployer',
    },
    {
      text: 'Data validation',
      icon: 'assignment_late',
      alias: 'data_validation',
      condition: '!isEmployer',
    },
    {
      text: 'Application Type Change',
      icon: 'info',
      alias: 'app_type',
      condition: 'isConsultantAdmin',
    },
    {
      text: 'Assigned user',
      icon: 'assignment_ind',
      alias: 'assigned_user',
      condition: 'isConsultantAdmin',
    },
    {
      text: 'Send email',
      icon: 'mail_outline',
      alias: 'send_email',
      condition: 'exceptApplicant',
    },
    {
      text: 'Copy application to',
      icon: 'swap_horiz',
      alias: 'copy_application',
      condition: '!isEmployer',
    },
    {
      text: 'Robot Files',
      icon: 'refresh',
      alias: 'robot',
      condition: '!isEmployer',
    },
    { text: 'Delete', icon: 'delete', alias: 'remove', condition: 'isAdmin' },
    {
      text: 'Create Application',
      icon: 'apps',
      alias: 'create_apps_lmia',
      condition: 'isAdmin',
    },
  ],
};

export const getters = {
  getAppMenus(state) {
    return state.applicationMenus;
  },
  all(state) {
    return state.applications;
  },
  getOne(state) {
    return state.application;
  },
  getAppType(state) {
    return state.appType;
  },
  processes(state) {
    return state.appProcesses;
  },
  processInputes(state) {
    return state.processInputes;
  },
  getFiles(state) {
    return state.files;
  },
  getStatUses(state) {
    return state.statuses;
  },
  actions(state) {
    return state.actions;
  },
};

export const actions = {
  getAppType({ commit }, id) {
    return api
      .get('table/lmia_application_types?order=display_order ASC,name ASC')
      .then(
        (response) => {
          commit('GET_APP_TYPE', response.data.resource);
          return Promise.resolve(response);
        },
        (err) => {
          commit('FAILURE_DATAS', err);
          return Promise.reject(err);
        },
      );
  },
  getAll({ commit }, filter) {
    return api.get('table/lmia_application_view' + filter).then(
      (response) => {
        commit('GET_DATAS', response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit('FAILURE_DATAS', err);
        return Promise.reject(err);
      },
    );
  },
  getOne({ commit }, filter) {
    return api.get('table/lmia_application_view' + filter).then(
      (response) => {
        commit('GET_DATA', response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit('FAILURE_DATAS', err);
        return Promise.reject(err);
      },
    );
  },
  getFiles({ commit }, url) {
    return axios.get(url).then(
      (response) => {
        commit('GET_FILES', response.data);
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  getProcess({ commit }, filter) {
    return api.get('table/process_tables_view' + filter).then(
      (response) => {
        commit('GET_APP_PROCESSS', response.data.resource);
        return Promise.resolve(response.data.resource);
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  },
  save({ commit }, data) {
    return api.post('application_management/lmia_applications', data).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        commit('FAILURE_DATAS', err);
        return Promise.reject(err);
      },
    );
  },
  edit({ commit }, data) {
    return api.put('table/lmia_applications/' + data.id, data).then(
      (response) => {
        commit('UPDATE_DATA', data);
        return Promise.resolve(response);
      },
      (err) => {
        commit('FAILURE_DATAS', err);
        return Promise.reject(err);
      },
    );
  },
};

export const mutations = {
  GET_FILES(state, datas) {
    state.files = datas;
  },
  GET_APP_PROCESSS(state, datas) {
    state.appProcesses = datas;
  },
  GET_APP_TYPE(state, datas) {
    datas.forEach(function (element) {
      element.count = undefined;
    });
    state.appType = datas;
  },
  FAILURE_APP_TYPE(state) {
    state.appType = [];
  },
  GET_DATAS(state, datas) {
    state.applications = datas;
  },
  GET_DATA(state, data) {
    state.application = data[0] || {};
  },
  UPDATE_DATA(state, data) {
    state.application = data;
  },
  FAILURE_DATAS(state) {
    state.applications = [];
  },
  FAILURE_DATA(state, data) {
    state.application = {};
  },
  DELETE_DATA(state, data) {
    let idx = state.applications.map((p) => p.id).indexOf(data.id);
    state.applications.splice(idx, 1);
  },
};
