import api from "./../plugins/axios";

let orgUrl = "table/sec_organizations";

export const state = {
  organizations: []
};

export const getters = {
  organizations(state) {
    return state.organizations;
  }
};

export const actions = {
  getOrganizations({ commit }, filter) {
    return api.get("table/sec_org_view" + filter).then(
      (response) => {
        commit("GET_ORG", response.data.resource);

        return Promise.resolve(response.data.resource);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  getOrganization({ commit }, id) {
    return api.get(orgUrl + "/" + id).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  editOrganization({ commit }, data) {
    return api.put(orgUrl + "/" + data.id, data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  addOrganization({ commit }, data) {
    var reqObj = { resource: [data] };
    return api.post(orgUrl, reqObj).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  deleteOrganization({ commit }, data) {
    return api.delete(orgUrl + "/" + data.id).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  assignToPartner({ commit }, data) {
    var reqObj = { resource: data };
    return api.post("table/sec_business_partner_applications", reqObj).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  deleteFromPartner({ commit }, filter) {
    return api.delete("table/sec_business_partner_applications" + filter).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  deletePatch({ commit }, data) {
    var reqObj = { resource: data };
    return api.post("table/sec_business_partner_applications?method=DELETE", reqObj).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
};

export const mutations = {
  GET_ORG(state, list) {
    var map = {},
      node,
      roots = [],
      i;
    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i;
      list[i].children = [];
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parent_org_id !== "0" && node.parent_org_id != null) {
        list[map[node.parent_org_id]].children.push(node);
      }
      else {
        roots.push(node);
      }
    }

    state.organizations = roots;
  }
};
