import axios from "axios";
import api from "./../plugins/axios";
import { orderObjectBy } from "./../utils/sort";

let url = "messages";

export const state = {
  messages: [],
  unreadMessage: 0,
  messageUsers: [],
  message: {},
  unread: 0
};

export const getters = {
  all(state) {
    return state.messages;
  },
  getUnread(state) {
    return state.unread;
  },
  getUnreadMessage(state) {
    return state.unreadMessage;
  },
  getUsers(state) {
    return state.messageUsers;
  },
  getOne(state) {
    return state.message;
  }
};

export const actions = {
  getAll({ commit }, filter) {
    return api.get("table/v_messages" + filter).then(
      (response) => {
        var datas = orderObjectBy(response.data.resource, "message_date", -1);
        commit("GET_DATAS", datas);
        return Promise.resolve(response.data.resource);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  getUnreadMessage({ commit }, filter) {
    return api.get("table/v_messages" + filter).then(
      (response) => {
        commit("GET_UNREAD", response.data.resource.length);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_UNREAD", err);
        return Promise.reject(err);
      }
    );
  },
  getUsers({ commit }, filter) {
    return api.get("table/" + filter).then(
      (response) => {
        commit("GET_USERS", response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  getById({ commit }, id) {
    return api.get(url + "/" + id).then(
      (response) => {
        commit("GET_DATA", response.data);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATA", err);
        return Promise.reject(err);
      }
    );
  },
  save({ commit }, data) {
    return api.post("function/send_message", data).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  sendEmail({ commit }, data) {
    return api.post("/email_service?template=message_template", data).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  changeStatus({ commit }, data) {
    return api.post("function/change_message_read_status", { params: data.params }).then(
      (response) => {
        commit("UPDATE_DATA", data.item);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  getFiles({ commit }, params) {
    return axios.get(params.url).then(
      (response) => {
        params.item.files = response.data.resource;
        commit("UPDATE_DATA", params.item);
        return Promise.resolve(response.data.resource);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  getFile({ commit }, params) {
    return axios.get(params.url).then(
      (response) => {
        return Promise.resolve(response.data.resource);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  delete({ commit }, data) {
    return api.delete(url + "/" + data.id).then(
      (response) => {
        commit("DELETE_DATA", data);
        return Promise.resolve(response);
      },
      (error) => {
        commit("FAILURE_DATAS", error);
        return Promise.reject(error);
      }
    );
  }
};

export const mutations = {
  GET_DATAS(state, datas) {
    state.messages = datas;
  },
  GET_UNREAD(state, data) {
    state.unread = data;
  },
  GET_USERS(state, datas) {
    state.messageUsers = datas;
  },
  GET_DATA(state, data) {
    state.message = data;
  },
  ADD_DATA(state, data) {
    state.messages.push(data);
  },
  UPDATE_DATA(state, data) {
    if (!data.read_status) {
      state.unread = state.unread - 1;
    }
    data.read_status = true;
    let idx = state.messages.map((p) => p.id).indexOf(data.id);
    state.messages.splice(idx, 1, data);
  },
  FAILURE_DATAS(state) {
    state.messages = [];
  },
  FAILURE_DATA(state, data) {
    state.message = {};
  },
  DELETE_DATA(state, data) {
    let idx = state.messages.map((p) => p.id).indexOf(data.id);
    state.messages.splice(idx, 1);
  }
};
