<template>
  <v-card v-if="hideSection" class="mt-3">
    <v-card-title>
      <span>{{ $t('title.application_travel_history') }}</span>
      <div class="ml-3">
        <el-checkbox v-model="notApplicable" @change="checkApplicable()">{{ $t('global.not_applicable') }}</el-checkbox>
      </div>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()" :disabled="notApplicable">{{ $t('global.add') }}</v-btn>
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="$t('infobox.application_travel_history') !== '' && !isAlertHide">
        {{ $t('infobox.application_travel_history') }}
      </v-alert>
      <v-col cols="12" v-if="check">
        <el-alert
          title="Please check to date"
          type="error">
        </el-alert>
      </v-col>
      <v-row dense class="customCollapseHeader">
        <v-col cols="6" sm="2">From Date</v-col>
        <v-col cols="6" sm="2">To Date</v-col>
        <v-col class="d-none d-sm-flex" sm="3">Country</v-col>
        <v-col class="d-none d-sm-flex" sm="3">City</v-col>
        <v-col class="d-none d-sm-flex" sm="2">Length</v-col>
      </v-row>
      <el-collapse v-model="activeNames" accordion v-show="!notApplicable">
        <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i">
          <template slot="title">
            <div class="customHead">
              <v-row dense>
                <v-col cols="6" sm="2">
                  <el-tag type="primary" v-if="form.from_date !== null && form.from_date != undefined">{{ form.from_date | day }}</el-tag>
                </v-col>
                <v-col cols="6" sm="2">
                  <el-tag type="success" v-if="form.to_date !== null && form.to_date != undefined">{{ form.to_date | day }}</el-tag>
                </v-col>
                <v-col class="d-none d-sm-flex" sm="3"><div class="pl-2 pr-2">{{ form.country_name }}</div></v-col>
                <v-col class="d-none d-sm-flex" sm="3"><div class="pl-2 pr-2">{{ form.location }}</div></v-col>
                <v-col class="d-none d-sm-flex" sm="2"><div class="pl-3 pr-2">{{ form.length }}</div></v-col>
              </v-row>
            </div>
          </template>
          <el-form :model="form" ref="form" label-position="top">
            <v-row dense>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'from_date')">
                <el-form-item :label="$t('application_travel_history.from_date')" prop="from_date">
                  <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.from_date" v-on:click.native="checkDate($event)" @change="getLength(form);handleAutoSave(i, form)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'to_date')">
                <el-form-item :label="$t('application_travel_history.to_date')" prop="to_date">
                  <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.to_date" v-on:click.native="checkDate($event)" @change="getLength(form);handleAutoSave(i, form)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'country_id')">
                <el-form-item :label="$t('application_travel_history.country_id')" prop="country_id">
                  <el-select filterable v-model="form.country_id" @change="changeCountry(form);handleAutoSave(i, form)" placeholder="Select" style="width: 100%">
                    <el-option
                      v-for="(item, index) in country"
                      v-bind:key="index"
                      :value="item.id"
                      :label="item.country_name">
                      {{ item.country_name }}
                    </el-option>
                  </el-select>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'location')">
                <el-form-item :label="$t('application_travel_history.location')" prop="location">
                  <el-input v-model="form.location" @change="handleAutoSave(i, form)" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'street_no')">
                <el-form-item label="Street #" prop="street_no">
                  <el-input v-model="form.street_no" @change="handleAutoSave(i, form)" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'building_no')">
                <el-form-item label="Building #" prop="building_no">
                  <el-input v-model="form.building_no" @change="handleAutoSave(i, form)" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'length')">
                <el-form-item :label="$t('application_travel_history.length')" prop="length">
                  <el-input @change="handleAutoSave(i, form)" disabled style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'purpose_of_travel')">
                <el-form-item :label="$t('application_travel_history.purpose_of_travel')" prop="purpose_of_travel">
                  <el-input v-model="form.purpose_of_travel" @change="handleAutoSave(i, form)" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'postal_code')">
                <el-form-item label="Postal Code" prop="postal_code">
                  <el-input v-model="form.postal_code" @change="handleAutoSave(i, form)" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(i,form)">{{ $t('global.save') }}</v-btn>
                <v-btn :loading="autoSave" :disabled="autoSave" color="error" small @click.native="deleteItem(form,i)">{{ $t('global.delete') }}</v-btn>
              </v-col>
            </v-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import datavalidation from '@/mixins/datavalidation.js'
// eslint-disable-next-line no-unused-vars
import momentPreciseRangePlugin from 'moment-precise-range-plugin'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    },
    isOINP: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        length: null,
        country_id: '',
        location: '',
        purpose_of_travel: '',
        from_date: null,
        to_date: null,
        street_no: null,
        building_no: null,
        postal_code: null
      },
      activeNames: -1,
      check: false,
      url: 'imms_table/application_travel_history',
      deleteUrl: 'imms/application_travel_history',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      isMobile: 'sidebar/isMobile',
      applicableId: 'applicable/id'
    }),
    notApplicable: {
      get() {
        return this.$store.state.applicable.notApplicable.travel_history
      },
      set(val) {
        this.$store.commit('applicable/set_travel_history', val)
      }
    },
    isAlertHide: function() {
      return this.$route.name == 'section_comparison' ? true : false
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    changeCountry(item) {
      let idx = this.country.map(p => p.id).indexOf(item.country_id)
      item.country_name = this.country[idx].country_name
    },
    getLength(form) {
      if (form.from_date && form.to_date) {
        var a = moment(form.from_date, 'YYYY-MM-DD')
        var b = moment(form.to_date, 'YYYY-MM-DD')
        var length = b.diff(a, 'days') + 1
        var date = a.add(-1, 'days')
        var diff = moment.preciseDiff(date, b)
        if (length > 0) {
          form.length = diff
          this.check = false
        }
        else {
          this.check = true
          form.to_date = null
        }
      }
    },
    fetchData() {
      this.$Progress.start()
      var url = `table/travel_history_view?filter=member_uid=${this.memberUID}&order=to_date DESC`
      this.$store.dispatch('store/get', url).then(response => {
        let vm = this

        if (vm.isDataValidation) {
          if (vm.notApplicable) {
            vm.hideSection = false
            return false
          }
          response.resource.forEach(function(item) {
            var form = {
              country_id: '',
              location: '',
              purpose_of_travel: '',
              from_date: null,
              to_date: null
            }

            var obj = vm.checkFormItems(item, form)

            if (obj != 'empty') {
              vm.items.push(item)
            }
          })
          if (response.resource.length == 0) {
            vm.hideSection = true
          }
          else {
            vm.hideSection = vm.validationItems.length == 0 ? false : true
          }
        }
        else {
          vm.items = response.resource
        }
        this.$Progress.finish()
      })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        length: null,
        country_id: '',
        location: '',
        purpose_of_travel: '',
        from_date: null,
        to_date: null,
        street_no: null,
        building_no: null,
        postal_code: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    checkApplicable() {
      var action = 'applicable/save'
      var that = this
      var data = {}
      data.travel_history = that.notApplicable
      data.application_id = this.$route.params.id
      data.member_id = parseFloat(this.memberID)
      data.member_uid = this.memberUID
      if (that.applicableId != undefined) {
        data.id = that.applicableId
        action = 'applicable/edit'
      }
      this.$Progress.start()
      this.$store.dispatch(action, data).then(response => {
        this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        this.$Progress.finish()
      })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
          this.$Progress.fail()
        })
    }
  }
}
</script>
