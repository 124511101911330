<template>
  <div>
    <el-form :model="form" ref="form" label-position="top" class="form">
      <v-card class="mt-3">
        <v-card-title>
          <span>Express Entry Information</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense v-if="isEOI">
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'bc_registration')">
              <el-form-item prop="bc_registration" label="Do you have an active registration with the BC Provincial Nominee Program?">
                <el-radio-group v-model="form.bc_registration" @change="autoSaveSubmit(form)">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'bc_applied')">
              <el-form-item prop="bc_applied" label="Have you applied to BC Provincial Nominee Program in the past?">
                <el-radio-group v-model="form.bc_applied" @change="autoSaveSubmit(form)">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense v-if="!isEOI">
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'profile_number')">
              <el-form-item prop="profile_number" label="Express Entry profile number">
                <el-input v-model="form.profile_number" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'submission_expiry_date')">
              <el-form-item prop="submission_expiry_date" label="Express Entry profile submission expiry date">
                <el-date-picker v-model="form.submission_expiry_date" @change="autoSaveSubmit(form)" value-format="yyyy-MM-dd" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'validation_code')">
              <el-form-item prop="validation_code" label="Job seeker validation code">
                <el-input v-model="form.validation_code" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'ranking_score')">
              <el-form-item prop="ranking_score" label="Comprenensive ranking score">
                <el-input v-model="form.ranking_score" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'job_title')">
              <el-form-item prop="job_title" label="Job title (as supplied to IRCC)">
                <el-input v-model="form.job_title" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'noc')">
              <el-form-item prop="noc" label="NOC (4-digits as supplied to IRCC)">
                <el-input v-model="form.noc" :maxlength="4" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'bc_job_offer')">
              <el-form-item prop="bc_job_offer" label="Are you currently working for the B.C. employer supporting your job offer?">
                <el-radio-group v-model="form.bc_job_offer" @change="autoSaveSubmit(form)">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <template v-if="!isEOI">
              <v-col cols="12" sm="6" v-if="dataValidation(form, 'working_job_offered')">
                <el-form-item prop="working_job_offered" label="Are you currently working in the job being offered?">
                  <el-radio-group v-model="form.working_job_offered" @change="autoSaveSubmit(form)">
                    <el-radio label="Yes">Yes</el-radio>
                    <el-radio label="No">No</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </template>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'job_offer_indicated')">
              <el-form-item prop="job_offer_indicated" label="Do you have any full-time work experience within the past ten years that is directly related to the job offer indicated above?">
                <el-radio-group v-model="form.job_offer_indicated" @change="autoSaveSubmit(form)">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'job_title')">
              <el-form-item prop="job_title" label="Job title (as supplied to IRCC)">
                <el-input v-model="form.job_title" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'noc')">
              <el-form-item prop="noc" label="NOC (4-digits as supplied to IRCC)">
                <el-input v-model="form.noc" :maxlength="4" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'from_date')">
              <el-form-item prop="from_date" label="From">
                <el-date-picker value-format="yyyy-MM-dd" @change="autoSaveSubmit(form)" v-model="form.from_date" v-on:click.native="checkDate($event)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'to_date')">
              <el-form-item prop="to_date" label="To">
                <el-date-picker value-format="yyyy-MM-dd" @change="autoSaveSubmit(form)" v-model="form.to_date" v-on:click.native="checkDate($event)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'total_months')">
              <el-form-item prop="total_months" label="Present total months">
                <el-input v-model="form.total_months" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'highest_level_education')">
              <el-form-item prop="highest_level_education" label="What is the highest level of education you have completed?">
                <el-select v-model="form.highest_level_education" @change="handleAutoSave(i, form)" style="width: 100%">
                  <el-option v-for="(item, index) in array" :key="index" :label="item" :value="item">{{ item }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'highest_level_education_date')">
              <el-form-item prop="highest_level_education_date" label="When did you complete this education?">
                <el-date-picker v-model="form.highest_level_education_date" value-format="yyyy-MM-dd" @change="autoSaveSubmit(form)" v-on:click.native="checkDate($event)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'obtain_education_canada')">
              <el-form-item prop="obtain_education_canada" label="Did you obtain this education in Canada?">
                <el-radio-group v-model="form.obtain_education_canada" @change="autoSaveSubmit(form)">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'obtain_education_bc')">
              <el-form-item prop="obtain_education_bc" label="Did you obtain this education in Canada?">
                <el-radio-group v-model="form.obtain_education_bc" @change="autoSaveSubmit(form)">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'education_credential_assessment')">
              <el-form-item prop="education_credential_assessment" label="Do you have an Education Credential Assessment issued from a qualified supplier?">
                <el-radio-group v-model="form.education_credential_assessment" @change="autoSaveSubmit(form)">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <template v-if="dataValidation(form, 'certificate_number')">
              <v-col cols="12" sm="6" md="4" v-if="form.education_credential_assessment == 'Yes'">
                <el-form-item prop="certificate_number" label="Certificate Number">
                  <el-input v-model="form.certificate_number" @change="autoSaveSubmit(form)" style="width: 100%" />
                </el-form-item>
              </v-col>
            </template>
            <template v-if="dataValidation(form, 'suppliers')">
              <v-col cols="12" sm="6" md="4" v-if="form.education_credential_assessment == 'Yes'">
                <el-form-item prop="suppliers" label="Suppliers">
                  <el-select v-model="form.suppliers" @change="autoSaveSubmit(form)" style="width: 100%">
                    <el-option v-for="(item, index) in suppliers" :key="index" :label="item" :value="item">{{ item }}</el-option>
                  </el-select>
                </el-form-item>
              </v-col>
            </template>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'industry_training_authority')">
              <el-form-item prop="industry_training_authority" label="Has your training and experience been assessed and certified by the Industry Training Authority? ">
                <el-radio-group v-model="form.industry_training_authority" @change="autoSaveSubmit(form)">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <template v-if="dataValidation(form, 'industry_training_authority_certificate')">
              <v-col cols="12" sm="6" md="4" v-if="form.industry_training_authority == 'Yes'">
                <el-form-item prop="industry_training_authority_certificate" label="Certificate Number">
                  <el-input v-model="form.industry_training_authority_certificate" @change="autoSaveSubmit(form)" style="width: 100%" />
                </el-form-item>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-3">
        <v-card-title>
          <span>Company/ Organization information</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense class="radio-parent">
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'legal_name')">
              <el-form-item prop="legal_name" label="Legal name of company/organization">
                <el-input v-model="form.legal_name" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'operation_name')">
              <el-form-item prop="operation_name" label="Operating name (if different from legal name)">
                <el-input v-model="form.operation_name" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-3">
        <v-card-title>
          <span>B.C Job offer information</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense class="radio-parent">
            <v-col cols="12" v-if="dataValidation(form, 'job_offer_title')">
              <el-form-item prop="job_offer_title" label="Job title">
                <el-input v-model="form.job_offer_title" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-if="dataValidation(form, 'job_offer_noc')">
              <el-form-item prop="job_offer_noc" label="NOC">
                <el-input v-model="form.job_offer_noc" :maxlength="4" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-if="dataValidation(form, 'hourly_wage')">
              <el-form-item prop="hourly_wage" label="Hourly wage">
                <el-input type="number" v-model="form.hourly_wage" @change="autoSaveSubmit(form)" class="text-right">
                  <template slot="prepend">$</template>
                </el-input>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-if="dataValidation(form, 'annual_wage')">
              <el-form-item prop="annual_wage" label="Annual wage">
                <el-input type="number" v-model="form.annual_wage" @change="autoSaveSubmit(form)" class="text-right">
                  <template slot="prepend">$</template>
                </el-input>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-if="dataValidation(form, 'work_per_week')">
              <el-form-item prop="work_per_week" label="Hours of work per week">
                <el-input type="number" v-model="form.work_per_week" @change="autoSaveSubmit(form)" class="text-right">
                  <template slot="prepend">$</template>
                </el-input>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'location_address')">
              <el-form-item prop="location_address" label="B.C. Work location address (number and street)">
                <el-input v-model="form.location_address" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'city_town')">
              <el-form-item prop="city_town" label="City/Town">
                <el-input v-model="form.city_town" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'postal_code')">
              <el-form-item prop="postal_code" label="Postal/Zip code">
                <el-input v-model="form.postal_code" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'phone_number')">
              <el-form-item prop="phone_number" label="Phone number">
                <el-input v-model="form.phone_number" type="number" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-3" v-if="isEOI">
        <v-card-title>
          <span>This is a part-time work</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'company_name')">
              <el-form-item prop="company_name" label="Company name/Organization">
                <el-input v-model="form.company_name" @change="autoSaveSubmit(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'work_canada')">
              <el-form-item prop="work_canada" label="Was this work in Canada?">
                <el-radio-group v-model="form.work_canada" @change="autoSaveSubmit(form)">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-3" v-if="!isEOI">
        <v-card-title>
          <span>Current/Previous Applications</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
            {{sectionInfoBox}}
          </v-alert>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'q1')">
              <v-col cols="12">
                <el-form-item prop="q1">
                  <el-checkbox @change="autoSaveSubmit(form)" v-model="form.q1">1. Do you have an active application for provincial nomination or permanent residence under any other federal or provincial program?</el-checkbox>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense v-show="form.q1" @change="autoSaveSubmit(form)" class="radio-parent">
              <v-col cols="12" v-if="dataValidation(form, 'q1_text')">
                <el-form-item prop="q1_text">
                  <el-input @change="autoSaveSubmit(form)" v-model="form.q1_text" class="mt-3" />
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'q2')">
              <v-col cols="12">
                <el-form-item prop="q2">
                  <el-checkbox id="q2" @change="autoSaveSubmit(form)" v-model="form.q2">2. Have you ever had an application rejected for provincial nomination or permanent residence in Canada under any other federal or provicnial program?</el-checkbox>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense v-show="form.q2" class="radio-parent">
              <v-col cols="12" v-if="dataValidation(form, 'q2_text')">
                <el-form-item prop="q2_text">
                  <el-input @change="autoSaveSubmit(form)" v-model="form.q2_text" class="mt-3" />
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'q3')">
              <v-col cols="12">
                <el-form-item prop="q3">
                  <el-checkbox @change="autoSaveSubmit(form)" v-model="form.q3">3. Have you ever had an application rejected for a Canadian visitor visa, study permit or temporary work permit?</el-checkbox>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense v-show="form.q3" class="radio-parent">
              <v-col cols="12" v-if="dataValidation(form, 'q3_text')">
                <el-form-item prop="q3_text">
                  <el-input @change="autoSaveSubmit(form)" v-model="form.q3_text" class="mt-3" />
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'q4')">
              <v-col cols="12">
                <el-form-item prop="q4">
                  <el-checkbox id="q4" @change="autoSaveSubmit(form)" v-model="form.q4">4.  Do you have an active registration with the BC Provincial Nominee Program?</el-checkbox>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense v-show="form.q4" class="radio-parent">
              <v-col cols="12" v-if="dataValidation(form, 'q4_text')">
                <el-form-item prop="q4_text">
                  <el-input @change="autoSaveSubmit(form)" v-model="form.q4_text" class="mt-3" />
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'q5')">
              <v-col cols="12">
                <el-form-item prop="q5">
                  <el-checkbox @change="autoSaveSubmit(form)" v-model="form.q5">5. Have you applied to BC Provincial Nominee Program in the past?</el-checkbox>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense v-show="form.q5" class="radio-parent">
              <v-col cols="12" v-if="dataValidation(form, 'q5_text')">
                <el-form-item prop="q5_text" label="Are you currently working in the job being offered?">
                  <el-input @change="autoSaveSubmit(form)" v-model="form.q5_text" class="mt-3" />
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'q6')">
              <v-col cols="12">
                <el-form-item prop="q6">
                  <el-checkbox @change="autoSaveSubmit(form)" v-model="form.q6">6. Have you had an application or Entrepreneur Immigration Registration refused or approved under the BC PNP?</el-checkbox>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense v-show="form.q6" class="radio-parent">
              <v-col cols="12" v-if="dataValidation(form, 'q6_text')">
                <el-form-item prop="q6_text">
                  <el-input @change="autoSaveSubmit(form)" v-model="form.q6_text" class="mt-3" />
                </el-form-item>
              </v-col>
            </v-row>

            <v-row dense class="radio-parent" v-if="dataValidation(form, 'q7')">
              <v-col cols="12">
                <el-form-item prop="q7">
                  <el-checkbox @change="autoSaveSubmit(form)" v-model="form.q7">7. Have you made a claim for refugee protection in Canada, or have you been refused refugee status in Canada?</el-checkbox>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense v-show="form.q7" class="radio-parent">
              <v-col cols="12" v-if="dataValidation(form, 'q7_text')">
                <el-form-item prop="q7_text">
                  <el-input @change="autoSaveSubmit(form)" v-model="form.q7_text" class="mt-3" />
                </el-form-item>
              </v-col>
            </v-row>

            <v-row dense class="radio-parent" v-if="dataValidation(form, 'q8')">
              <v-col cols="12">
                <el-form-item prop="q8">
                  <el-checkbox @change="autoSaveSubmit(form)" v-model="form.q8">8. Are you under a removal order from Canada (l.e. departure order or exclusion order)?</el-checkbox>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense v-show="form.q8" class="radio-parent">
              <v-col cols="12" v-if="dataValidation(form, 'q8_text')">
                <el-form-item prop="q8_text">
                  <el-input @change="autoSaveSubmit(form)" v-model="form.q8_text" class="mt-3" />
                </el-form-item>
              </v-col>
            </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-3" v-if="!isEOI">
        <v-card-title>
          <span>Details of your visits to Canada</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense class="radio-parent" v-if="dataValidation(form, 'datails_of_visit')">
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item">
                <label class="el-form-item__label">
                  Have you previously visited Canada within the last 10 years?
                </label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="datails_of_visit">
                <el-checkbox id="datails_of_visit" @change="autoSaveSubmit(form)" v-model="form.datails_of_visit" class="mt-3">Yes</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense v-show="form.datails_of_visit" class="radio-parent">
            <v-col cols="12" v-if="dataValidation(form, 'details_of_visit_text')">
              <el-form-item prop="details_of_visit_text">
                <el-input id="details_of_visit_text" @change="autoSaveSubmit(form)" v-model="form.details_of_visit_text" class="mt-3" style="width: 100%" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div v-if="!isEOI">
        <v-card class="mt-3" v-if="dataValidation(form, 'intended_place')">
          <v-card-title>
            <span>Intended Place of Residence</span>
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <el-form-item prop="intended_place" label="In which city/town within B.C. do you intend to live upon nomination?">
                  <el-input id="intended_place" v-model="form.intended_place" @change="autoSaveSubmit(form)" />
                </el-form-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mt-3" v-if="dataValidation(form, 'settlement_fund')">
          <v-card-title>
            <span>Settlement Funds</span>
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-row dense class="radio-parent">
              <v-col cols="12" class="mb-4">
                <label class="el-form-item__label mt-2">Indicate the amount of unencumbered transferable and available funds you have, in Canadian dollars.</label>
              </v-col>
              <v-col cols="12" sm="6">
                <el-form-item prop="settlement_fund" label="Amount(s)">
                  <el-input id="settlement_fund" type="number" v-model="form.settlement_fund" @change="autoSaveSubmit(form)" class="text-right">
                    <template slot="prepend">$</template>
                  </el-input>
                </el-form-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <v-card class="mt-3" v-if="!isEOI">
        <v-card-title>
          <span>Current Status in Canada</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense v-if="dataValidation(form, 'currently_canada')">
            <v-col cols="12" sm="6">
              <el-form-item prop="currently_canada" label="Are you currently in Canada?">
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.currently_canada" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense v-if="form.currently_canada == 'Yes'">
            <v-col cols="12">
              <el-form-item v-if="dataValidation(form, 'current_status_canada')" prop="current_status_canada" label="Indicate your current status in Canada:">
                <el-radio-group v-model="form.current_status_canada" @change="autoSaveSubmit(form)">
                  <el-radio label="Visitor">Visitor</el-radio>
                  <el-radio label="Student">Student</el-radio>
                  <el-radio label="Worker">Worker</el-radio>
                  <el-radio label="Other">Other</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense v-if="form.currently_canada == 'No'">
            <v-col cols="12">
              <el-form-item label="If you are not currently in Canada, indicate your current country of residence:"></el-form-item>
            </v-col>
          </v-row>
          <v-row dense v-if="form.currently_canada == 'No'" class="pa-4">
            <v-col cols="12" v-if="dataValidation(form, 'current_country')">
              <el-form-item prop="current_country" label="Country">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select @change="autoSaveSubmit(form)" v-model="form.current_country" placeholder="Select" class="el-input__inner">
                      <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'current_status')">
              <el-form-item label="Status" prop="current_status">
                <el-select v-model="form.current_status" @change="autoSaveSubmit(form)" placeholder="Select" style="width: 100%;">
                  <el-option :value="item.name" v-for="(item, index) in status" v-bind:key="index">{{ item.name }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'current_from_date')">
              <el-form-item label="From" prop="current_from_date">
                <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" @change="autoSaveSubmit(form)" v-model="form.current_from_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'current_to_date')">
              <el-form-item label="To" prop="current_to_date">
                <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" @change="autoSaveSubmit(form)" v-model="form.current_to_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-actions class="pa-3">
          <v-spacer />
          <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="autoSaveSubmit(form)">
            {{ $t('global.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </el-form>
  </div>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'
import { mapGetters } from 'vuex'

export default {
  mixins: [datavalidation],
  props: {
    sectionLabel: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  data() {
    return {
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: this.memberID,
        q1: null,
        q1_text: null,
        q2: null,
        q2_text: null,
        q3: null,
        q3_text: null,
        q4: null,
        q4_text: null,
        q5: null,
        q5_text: null,
        q6: null,
        q6_text: null,
        q7: null,
        q7_text: null,
        q8: null,
        q8_text: null,
        sec_org_id: this.$orgId,
        highest_level_education_date: null,
        currently_canada: null,
        current_status_canada: null,
        current_country: null,
        current_status: null,
        current_from_date: null,
        current_to_date: null
      },
      loading: false,
      url: '/imms_table/bc_pnp_background_questions',
      getUrl: `/table/bc_pnp_background_questions?filter=member_uid=${this.memberUID}`,
      suppliers: [
        'Comparative Education Service - University of Toronto School of Continuing Studies',
        'International Credential Assessment Service of Canada',
        'World Education Services',
        'International Qualifications Assessment Service',
        'International Credential Evaluation Service',
        'Medical Council of Canada (for Doctors)',
        'Pharmacy Examining Board of Canada (for Pharmacists)'
      ],
      array: [
        "Secondary School or less",
        "Associate Degree",
        "Post-Secondary Diploma/Certificate (non-trades)",
        "Post-Secondary Diploma/Certificate (trades)",
        "Bachelor's Degree",
        "Post-Graduate Certificate/Diploma",
        "Master's Degree",
        "Doctorate/PhD"
      ],
      isEOI: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  mounted() {
    this.isEOI = this.sectionSlug === 'EOI' ? true : false
    this.fetchData()
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      status: 'other/status'
    })
  },
  methods: {
    async fetchData() {
      try {
        const response = await this.$api.get(`${this.getUrl}`)
        if (response.data.resource && response.data.resource.length > 0) {
          this.form = response.data.resource[0]
          if (this.isDataValidation) {
            this.checkRow(response.data.resource[0])
          }
        }
        else {
          if (this.isDataValidation) {
            this.checkRow(this.form)
          }
        }
      }
      catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style>
  .el-form-item__label{
    line-height: 20px;
    text-align: left;
  }
</style>
