<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <v-row dense class="medium-space">
        <v-col cols="12">
          <label class="body-2 mb-3">I have been accepted at the following educational institution</label>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'name_of_school')">
          <el-form-item :label="$t('imm_0008.name_of_school')" prop="name_of_school">
            <el-input @change="saveIMM(form)" v-model="form.name_of_school" style="width: 100%;" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'level_of_study')">
          <el-form-item :label="$t('imm_0008.level_of_study')" prop="level_of_study">
            <el-select @change="saveIMM(form)" filterable v-model="form.level_of_study" placeholder="Select" style="width: 100%;">
              <el-option v-for="item in levelStudy" :key="item.name" :value="item.name" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'field_of_study')">
          <el-form-item :label="$t('imm_0008.field_of_study')" prop="field_of_study">
            <el-select @change="saveIMM(form)" filterable v-model="form.field_of_study" placeholder="Select" style="width: 100%;">
              <el-option v-for="item in fieldStudy" :key="item.name" :value="item.name" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
        </v-col>
        <v-col cols="12">
          <label class="body-2 mb-3">Complete address of school in Canada</label>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'canada_province')">
          <el-form-item :label="$t('imm_0008.canada_province')" prop="canada_province">
            <div class="el-select native-select">
              <div class="el-input">
                <select @change="saveIMM(form)" v-model="form.canada_province" placeholder="Select" class="el-input__inner">
                  <option v-for="(item, index) in canadaProvinces" v-bind:key="index" :value="item.id" :label="item.province_name">{{ item.province_name }}</option>
                </select>
              </div>
            </div>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'study_city')">
          <el-form-item :label="$t('imm_0008.study_city')" prop="study_city">
            <el-input @change="saveIMM(form)" v-model="form.study_city" style="width: 100%;" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'study_address')">
          <el-form-item :label="$t('imm_0008.study_address')" prop="study_address">
            <el-input @change="saveIMM(form)" v-model="form.study_address" style="width: 100%;" />
          </el-form-item>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'dli')">
          <el-form-item :label="$t('imm_0008.dli')" prop="dli">
            <el-input @change="saveIMM(form)" v-model="form.dli" style="width: 100%;" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'student_id')">
          <el-form-item :label="$t('imm_0008.student_id')" prop="student_id">
            <el-input @change="saveIMM(form)" v-model="form.student_id" style="width: 100%;" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6">
          <label class="mb-2">{{ $t('global.imm_0008_duration_expected_study') }}:</label>
          <v-row dense class="medium-space">
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'study_from_date')">
              <el-form-item :label="$t('imm_0008.study_from_date')" prop="study_from_date">
                <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.study_from_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'study_to_date')">
              <el-form-item :label="$t('imm_0008.study_to_date')" prop="study_to_date">
                <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.study_to_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row dense class="medium-space">
        <v-col cols="12">
          <label class="body-2 mb-3">The cost of my studles will be:</label>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'tuition')">
          <el-form-item :label="$t('imm_0008.tuition')" prop="tuition">
            <el-input @change="saveIMM(form)" type="number" v-model="form.tuition">
              <template slot="prepend">$</template>
            </el-input>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'room_rent')">
          <el-form-item :label="$t('imm_0008.room_rent')" prop="room_rent">
            <el-input @change="saveIMM(form)" type="number" v-model="form.room_rent">
              <template slot="prepend">$</template>
            </el-input>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'other_expense')">
          <el-form-item :label="$t('imm_0008.other_expense')" prop="other_expense">
            <el-input @change="saveIMM(form)" type="number" v-model="form.other_expense">
              <template slot="prepend">$</template>
            </el-input>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'funds')">
          <el-form-item :label="$t('imm_0008.funds')" prop="funds">
            <el-input @change="saveIMM(form)" type="number" v-model="form.funds">
              <template slot="prepend">$</template>
            </el-input>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'expense_by')">
          <el-form-item :label="$t('imm_0008.expense_by')" prop="expense_by">
            <el-select @change="saveIMM(form)" filterable v-model="form.expense_by" placeholder="Select" style="width: 100%;">
              <el-option value="Myself">Myself</el-option>
              <el-option value="Parents">Parents</el-option>
              <el-option value="Other">Other</el-option>
            </el-select>
          </el-form-item>
        </v-col>
        <template v-if="form.expense_by === 'Other'">
          <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'study_other')">
            <el-form-item :label="$t('imm_0008.study_other')" prop="study_other">
              <el-input @change="saveIMM(form)" v-model="form.study_other" />
            </el-form-item>
          </v-col>
        </template>
      </v-row>
      <v-row dense class="medium-space">
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'study_apply_wp')">
          <el-form-item label="In addition to a study permit, are you also applying for a work permit?" prop="study_apply_wp">
            <el-radio-group @change="saveIMM(form)" v-model="form.study_apply_wp" class="mt-1">
              <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
              <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'study_type_wp')">
          <el-form-item label="What type of work permit are you applying for" prop="study_type_wp">
            <el-input @change="saveIMM(form)" v-model="form.study_type_wp" style="width: 100%;" />
          </el-form-item>
        </v-col>
        <v-col cols="12">
          <label>If you have been issued a Quebec Acceptance Certificate(CAQ), provide the:</label>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'study_certificate_number')">
          <el-form-item label="Certificate Number" prop="study_certificate_number">
            <el-input @change="saveIMM(form)" v-model="form.study_certificate_number" style="width: 100%;" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'study_expiry_date')">
          <el-form-item label="Expiry Date" prop="study_expiry_date">
            <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.study_expiry_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  data() {
    return {
      canadaProvinces: [],
      levelStudy: [
        { name: 'Primary School' },
        { name: 'Secondary School' },
        { name: 'PTC/TCST/DVS/AVS' },
        { name: 'CEGGEP - Pre-university' },
        { name: 'CEGEP - Technical' },
        { name: 'College - Certificate' },
        { name: 'College - Diploma' },
        { name: 'College - Applied degree' },
        { name: "University - Bachelor's Deg" },
        { name: "University - Master's Deg" },
        { name: 'University - Doctorate' },
        { name: 'University - Other Studies' },
        { name: 'ESL/FSL' },
        { name: 'ESL/FSL and College' },
        { name: 'ESL/FSL and University' },
        { name: 'Other Studies' },
        { name: 'Not Applicable' }
      ],
      fieldStudy: [
        { name: "Arts/Humanities/Social Sciences" },
        { name: "Arts, Fine/visual/Performing" },
        { name: "Business/Commerce" },
        { name: "Computing/IT" },
        { name: "ESL/FSL" },
        { name: "Flight Training" },
        { name: "Hospitality/Tourism" },
        { name: "Law" },
        { name: "Medicine" },
        { name: "Science, Applied" },
        { name: "Sciences, General" },
        { name: "Sciences, Health" },
        { name: "Trades/Vocational" },
        { name: "Theology/Religious Studies" },
        { name: "Other" },
        { name: "Agric/Agric Ops/Rel Sciences" },
        { name: "Architecture and Rel Services" },
        { name: "Biological/Biomed Sciences" },
        { name: "Business/Mgmt/Marketing" }
      ]
    }
  },
  mounted() {
    this.$store.dispatch('other/getProvince', 42)
      .then(response => {
        this.canadaProvinces = response.data.resource
      })

    if (this.isDataValidation) {
      var item = {
        name_of_school: null,
        level_of_study: null,
        field_of_study: null,
        canada_province: null,
        study_city: null,
        study_address: null,
        dli: null,
        student_id: null,
        study_from_date: null,
        study_to_date: null,
        tuition: null,
        room_rent: null,
        other_expense: null,
        funds: null,
        expense_by: null,
        study_other: null,
        study_expiry_date: null,
        study_certificate_number: null,
        study_type_wp: null,
        study_apply_wp: null
      }
      this.checkItem(this.form, item)
    }
  }
}
</script>
