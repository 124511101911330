<template>
  <div>
    <v-card class="mt-3">
      <v-card-title v-if="sectionLabel">
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="form">
          <v-row dense>
            <v-col cols="12">
              <div class="body-2 mb-2">1. When was the first time you met in person?</div>
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <el-form-item label="Date" prop="first_meeting_date">
                <el-date-picker id="first_meeting_date" :picker-options="datePickerOptions" value-format="yyyy-MM-dd" v-model="form.first_meeting_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
            <v-col cols="12" md="8" lg="8">
              <el-form-item label="Where" prop="first_meeting_where">
                <el-input id="first_meeting_where" v-model="form.first_meeting_where" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item label="Describe the circumstances of you first meeting" prop="first_meeting_description">
                <el-input id="first_meeting_description" v-model="form.first_meeting_description" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row dense>
            <v-col cols="12" sm="8" md="6" lg="4">
              <div class="body-2 mb-2">2. Did you have any contact before you met in person?</div>
            </v-col>
            <v-col cols="12" sm="3" md="2">
              <el-form-item prop="contact_before_description_checked">
                <el-radio-group id="contact_before_description_checked" v-model="form.contact_before_description_checked" class="mt-1">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="form.contact_before_description_checked == 'Yes'">
              <el-form-item label="If YES, give details (e.g. who initiated the first contact, date of first contact, was it by phone, letter, e-mail, social media, etc.)" prop="contact_before_description">
                <el-input id="contact_before_description" v-model="form.contact_before_description" style="width: 100%;" type="textarea" :autosize="{ minRows: 3, maxRows: 5}" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row dense>
            <v-col cols="12" sm="8" md="4" lg="3">
              <div class="body-2 mb-2">3. Are you living together now?</div>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <el-form-item prop="live_together_checked">
                <el-radio-group id="live_together_checked" v-model="form.live_together_checked" class="mt-1">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="form.live_together_checked == 'Yes'">
              <el-form-item label="If yes, how long have you been cohabiting (living together)? Give the period(s) you have been living together after your conjugal* relationship started.
* Conjugal relationship means a committed and mutually interdependent relationship of some permanence where a couple has combined their affairs to the
extent possible (marriage-like)." prop="live_together">
                <el-input id="live_together" v-model="form.live_together" style="width: 100%;" type="textarea" :autosize="{ minRows: 3, maxRows: 5}" />
              </el-form-item>
            </v-col>
            <v-col cols="12" v-else>
              <el-form-item label="If no, explain why not. If you have lived together in the past, list when and where you lived together. If either of you are living with someone else, include their name and their relationship in the explanation." prop="live_together">
                <el-input id="live_together" v-model="form.live_together" style="width: 100%;" type="textarea" :autosize="{ minRows: 3, maxRows: 5}" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>

          <v-row dense>
            <v-col cols="12" sm="8" md="9" lg="6">
              <div class="body-2 mb-2">4. If you are not living together, have you visited each other during your relationship?</div>
            </v-col>
            <v-col cols="12" sm="4" md="3" lg="2">
              <el-form-item prop="visited_each_other_checked">
                <el-radio-group id="visited_each_other_checked" v-model="form.visited_each_other_checked" class="mt-1">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="form.visited_each_other_checked == 'No'">
              <el-form-item label="If no, explain why not" prop="visited_each_other">
                <el-input id="visited_each_other" v-model="form.visited_each_other" style="width: 100%;" type="textarea" :autosize="{ minRows: 3, maxRows: 5}" />
              </el-form-item>
            </v-col>
          </v-row>

          <sponsorship-visit-history
            v-if="form.visited_each_other_checked == 'Yes'"
            sectionLabel="Give details below."
            :sectionId="sectionId"
            :memberUID="memberUID"
            :memberID="memberID"
            :layout="layout">
          </sponsorship-visit-history>

          <v-divider class="mb-3"></v-divider>

          <v-row dense>
            <v-col cols="12">
              <div class="body-2 mb-2">5. What language(s) do you use when communicating with each other?</div>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="what_language_to_communicate">
                <el-input id="what_language_to_communicate" v-model="form.what_language_to_communicate" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row dense>
            <v-col cols="12">
              <div class="body-2 mb-2">6. How often do you communicate when you are not together and how?</div>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="how_often_contact">
                <el-input id="how_often_contact" v-model="form.how_often_contact" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row dense>
            <v-col cols="12" md="9" lg="5">
              <div class="body-2 mb-2">7. Do you close friends, family and children know about your relationship?</div>
            </v-col>
            <v-col cols="12" md="3">
              <el-form-item prop="close_friend_know_checked">
                <el-radio-group id="close_friend_know_checked" v-model="form.close_friend_know_checked" class="mt-1">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="form.close_friend_know_checked == 'No'">
              <el-form-item label="If no, explain why not." prop="close_friend_know">
                <el-input id="close_friend_know" v-model="form.close_friend_know" style="width: 100%;" type="textarea" :autosize="{ minRows: 3, maxRows: 5}" />
              </el-form-item>
            </v-col>
          </v-row>

          <sponsorship-know-people
            v-if="form.close_friend_know_checked == 'Yes'"
            sectionLabel="If yes, give details below."
            :sectionId="sectionId"
            :memberUID="memberUID"
            :memberID="memberID"
            :layout="layout">
          </sponsorship-know-people>

          <v-divider class="mb-3"></v-divider>
          <v-row dense>
            <v-col cols="12" sm="8" md="4" lg="3">
              <div class="body-2 mb-2">8. Was your marriage arranged?</div>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <el-form-item prop="marriage_arranged_checked">
                <el-radio-group id="marriage_arranged_checked" v-model="form.marriage_arranged_checked" class="mt-1">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="form.marriage_arranged_checked == 'Yes'">
              <el-form-item label="If yes, describe how the marriage was arranged (by whom, when, where)" prop="marriage_arranged">
                <el-input id="marriage_arranged" v-model="form.marriage_arranged" style="width: 100%;" type="textarea" :autosize="{ minRows: 3, maxRows: 5}" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>

          <v-row dense>
            <v-col cols="12">
              <div class="body-2 mb-2">9. Where there formal ceremonies or events to recognize or celebrate your relationship, e.g., engagement ceremony, traditional or customary marriage ceremony, commitment ceremony, partnership ceremony, reception, honeymoon, etc?</div>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <el-form-item prop="about_ceremony_checked">
                <el-radio-group id="about_ceremony_checked" v-model="form.about_ceremony_checked" class="mt-1">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item v-if="form.about_ceremony_checked == 'No'" label="If no, explain why not." prop="about_ceremony">
                <el-input id="about_ceremony" v-model="form.about_ceremony" style="width: 100%;" type="textarea" :autosize="{ minRows: 3, maxRows: 5}" />
              </el-form-item>
            </v-col>
          </v-row>

          <sponsorship-ceremonies
            v-if="form.about_ceremony_checked == 'Yes'"
            sectionLabel="If yes, give details below."
            :sectionId="sectionId"
            :memberUID="memberUID"
            :memberID="memberID"
            :layout="layout">
          </sponsorship-ceremonies>
          <v-divider class="mb-3"></v-divider>

          <div class="ml-3">
            <v-row dense>
              <v-col cols="12">
                <div class="body-2 mb-2">Did any of the following people got to any of the events above?</div>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent">
              <v-col cols="12">
                <el-form-item prop="parents">
                  <el-checkbox id="parents" v-model="form.parents">a) Principal applicant's parent(s)?</el-checkbox>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent">
              <v-col cols="12">
                <el-form-item prop="children">
                  <el-checkbox id="children" v-model="form.children">b) Principal applicant's child(ren) if applicable?</el-checkbox>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent">
              <v-col cols="12">
                <el-form-item prop="family_members">
                  <el-checkbox id="family_members" v-model="form.family_members">c) Principal applicant's other family members or relatives?</el-checkbox>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent">
              <v-col cols="12">
                <el-form-item prop="sponsor_parent">
                  <el-checkbox id="sponsor_parent" v-model="form.sponsor_parent">d) Sponsor's parent(s)?</el-checkbox>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent">
              <v-col cols="12">
                <el-form-item prop="sponsor_child">
                  <el-checkbox id="sponsor_child" v-model="form.sponsor_child">e) Sponsor's child(ren) if applicable?</el-checkbox>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent">
              <v-col cols="12">
                <el-form-item prop="sponsor_other_members">
                  <el-checkbox id="sponsor_other_members" v-model="form.sponsor_other_members">f) Sponsor's other family members or relatives?</el-checkbox>
                </el-form-item>
              </v-col>
            </v-row>
          </div>
          <v-divider class="mb-3"></v-divider>

          <v-row dense>
            <v-col cols="12">
              <div class="body-2 mb-2">10. Are either of you pregnant?</div>
            </v-col>
            <v-col cols="12" md="2">
              <el-form-item prop="are_you_pregnant">
                <el-radio-group id="are_you_pregnant" v-model="form.are_you_pregnant" class="mt-1">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" md="3" class="text-right" v-if="form.are_you_pregnant == 'Yes'">
              <label class="el-form-item__label mt-2">If yes, what is the due date?</label>
            </v-col>
            <v-col cols="12" md="3" v-if="form.are_you_pregnant == 'Yes'">
              <el-form-item label="" prop="pregnant_date">
                <el-date-picker id="pregnant_date" value-format="yyyy-MM-dd" v-model="form.pregnant_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row dense>
            <v-col cols="12">
              <div class="body-2 mb-2">11. Is there more information you wish to share to support your relationship?</div>
            </v-col>
            <v-col cols="12">
              <el-form-item label="" prop="more_information">
                <el-input id="more_information" v-model="form.more_information" style="width: 100%;" type="textarea" :autosize="{ minRows: 5, maxRows: 10}" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row dense>
            <v-col cols="12">
              <div class="body-2 mb-2">Residence in Canada</div>
            </v-col>
            <v-col cols="12" md="2">
              <el-form-item prop="residence_canada">
                <el-radio-group id="residence_canada" v-model="form.residence_canada" class="mt-1">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" md="10">
              <el-form-item label="When did you last live in Canada?" prop="last_live_canada">
                <el-input id="last_live_canada" v-model="form.last_live_canada" style="width: 100%;" />
              </el-form-item>
              <el-form-item label="When did you plan to return to Canada to resume residence?" prop="plan_to_return_canada">
                <el-input id="plan_to_return_canada" v-model="form.plan_to_return_canada" style="width: 100%;" />
              </el-form-item>
              <el-form-item label="When did you plan to live when you return to Canada?" prop="where_live_canada">
                <el-input id="where_live_canada" v-model="form.where_live_canada" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
        </el-form>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm()">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SponsorshipVisitHistory from './sponsorship_visit_history'
import SponsorshipKnowPeople from './sponsorship_know_people'
import SponsorshipCeremonies from './sponsorship_ceremonies'

export default {
  props: {
    sectionId: {
      type: String
    },
    layout: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      formNode: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        sponsor_child: false,
        sponsor_parent: false,
        family_members: false,
        children: false,
        parents: false,
        are_you_pregnant: null,
        pregnant_date: null,
        sponsor_other_members: false,
        first_meeting_date: null,
        first_meeting_description: null,
        what_language_to_communicate: null,
        contact_before_description: null,
        contact_before_description_checked: 'No',
        close_friend_know: null,
        marriage_arranged: null,
        about_ceremony: null,
        about_ceremony_checked: null,
        visited_each_other: null,
        visited_each_other_checked: 'No',
        more_information: null,
        first_meeting_where: null,
        live_together: null,
        live_together_checked: 'No',
        where_live_canada: null,
        plan_to_return_canada: null,
        last_live_canada: null,
        how_often_contact: null,
        residence_canada: 'No'
      },
      fields: ['residence_canada', 'how_often_contact', 'last_live_canada', 'plan_to_return_canada', 'where_live_canada', 'live_together_checked', 'live_together', 'first_meeting_where', 'more_information', 'visited_each_other_checked', 'visited_each_other', 'about_ceremony_checked', 'about_ceremony', 'marriage_arranged', 'close_friend_know', 'contact_before_description_checked', 'contact_before_description', 'what_language_to_communicate', 'first_meeting_description', 'first_meeting_date', 'sponsor_other_members', 'sponsor_child', 'sponsor_parent', 'family_members', 'children', 'parents', 'are_you_pregnant', 'pregnant_date', 'sponsor_other_members'],
      url: 'imms_table/application_sponsorship_general',
      state: 'sGeneral',
      loading: false,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date()
        }
      }
    }
  },
  components: {
    SponsorshipVisitHistory,
    SponsorshipKnowPeople,
    SponsorshipCeremonies
  },
  computed: {
    item: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    },
    form: function() {
      if (this.item.length > 0) {
        return this.item[0]
      }
      else {
        return this.formNode
      }
    }
  },
  mounted() {
    if (this.datas !== undefined) {
      this.checkFields(this.fields, this.datas, this.sectionId)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    checkDate: function(event) {
      this.$emit('checkDate', event)
    },
    fetchData() {
      this.$Progress.start()
      var url = `table/application_sponsorship_general?filter=member_uid=${this.memberUID}`
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true

          if (this.form.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': this.form, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                this.form.id = response.data.resource[0].id
                this.form.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    }
  }
}
</script>

<style>
  .form-list li{
    margin: 20px 0;
  }
  .form-list .list__tile{
    height: auto;
  }
  .form-list .list__tile__sub-title, .form-list .list__tile__title{
    white-space: normal;
  }
  .textarea{
    height: auto;
  }
</style>
