<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ $t('title.imm_0008_languages') }}</span>
      <v-spacer />              
    </v-card-title>
    <v-card-text>
      <v-row dense class="medium-space">
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'native_name')">
          <el-form-item :label="$t('imm_0008.native_name')" prop="native_name">
            <div class="el-select native-select">
              <div class="el-input">
                <select @change="saveIMM(form)" v-model="form.native_name" placeholder="Select" class="el-input__inner">
                  <option v-for="(item, index) in languages" v-bind:key="index" :value="item.name" :label="item.name">{{ item.name }}</option>
                </select>
              </div>
            </div>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'communicate_english_french')">
          <el-form-item :label="$t('imm_0008.communicate_english_french')" prop="communicate_english_french">
            <el-select @change="saveIMM(form)" v-model="form.communicate_english_french" placeholder="Select" style="width: 100%;">
              <el-option value="English">English</el-option>
              <el-option value="French">French</el-option>
              <el-option :value="$t('global.both')">{{ $t('global.both') }}</el-option>
              <el-option :value="$t('global.neither')">{{ $t('global.neither') }}</el-option>
            </el-select>
          </el-form-item>
        </v-col>        
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'frequently_lang')">
          <el-form-item :label="$t('imm_0008.frequently_lang')" prop="frequently_lang">
            <el-select @change="saveIMM(form)" v-model="form.frequently_lang" placeholder="Select" style="width: 100%;">
                <el-option 
                  v-for="(item, index) in CanadaOfficialLanguages" 
                  :key="index"
                  :value="item.name"
                  :label="item.name">
              </el-option>
            </el-select>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'language_test')">
          <el-form-item :label="$t('imm_0008.language_test')" prop="language_test">
            <el-select @change="saveIMM(form)" v-model="form.language_test" placeholder="Select" style="width: 100%;">
              <el-option value="IELTS" label="IELTS"></el-option>
              <el-option value="CELPIP" label="CELPIP"></el-option>
              <el-option value="TEF" label="TEF"></el-option>
              <el-option value="No" label="No"></el-option>
            </el-select>
          </el-form-item>
        </v-col>
      </v-row>
      <v-row dense v-if="isOINP">
        <v-col cols="12" sm="6" md="3">
          <el-form-item :label="$t('application_official_languages.listening')" prop="listening">
            <el-input type="number" v-model="form.listening" style="width: 100%" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <el-form-item :label="$t('application_official_languages.reading')" prop="reading">
            <el-input type="number" v-model="form.reading" style="width: 100%" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <el-form-item :label="$t('application_official_languages.writing')" prop="writing">
            <el-input type="number" v-model="form.writing" style="width: 100%" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <el-form-item :label="$t('application_official_languages.speaking')" prop="speaking">
            <el-input type="number" v-model="form.speaking" style="width: 100%" />
          </el-form-item>
        </v-col> 
        <v-col cols="12" sm="6">
          <el-form-item label="Date of Test" prop="date_test">
            <el-date-picker value-format="yyyy-MM-dd" v-model="form.date_test" v-on:click.native="checkDate($event)" style="width: 100%"></el-date-picker>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6">
          <el-form-item label="Date of test result" prop="date_test_result">
            <el-date-picker value-format="yyyy-MM-dd" v-model="form.date_test_result" v-on:click.native="checkDate($event)" style="width: 100%"></el-date-picker>
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    },
    isOINP: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      languages: 'other/nativeLanguages',
      CanadaOfficialLanguages: 'other/CanadaOfficialLanguages'
    })
  },
  mounted() {
    if (this.isDataValidation) {
      var item = {
        native_name: null,
        communicate_english_french: null,
        frequently_lang: null,
        language_test: null
      }

      this.checkItem(this.form, item)
    }
  },
  created() {
    this.$store.dispatch('other/getNativeLanguages')
  }
}

</script>