<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <el-form :model="form" ref="form" label-position="top" class="form">
        <v-row dense>
          <v-col cols="12" class="mb-3" v-if="!isDataValidation">
            <label class="black--text body-1">{{ $t('global.date_place_original_entry') }}</label>
          </v-col>
          <v-col cols="12" sm="6" v-if="dataValidation(form, 'coming_date')">
            <el-form-item :label="$t('imm_0008.coming_date')" prop="coming_date">
              <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.coming_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" v-if="dataValidation(form, 'coming_place')">
            <el-form-item :label="$t('imm_0008.coming_place')" prop="coming_place">
              <el-input @change="saveIMM(form)" v-model="form.coming_place" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" v-if="dataValidation(form, 'coming_purpose')">
            <el-form-item :label="$t('imm_0008.coming_purpose')" prop="coming_purpose">
              <el-select @change="saveIMM(form)" v-model="form.coming_purpose" style="width: 100%;">
                <el-option v-for="(item, ind) in status" :key="ind" :label="item.name" :value="item.name"></el-option>
              </el-select>
            </el-form-item>
          </v-col>
          <template v-if="form.coming_purpose == 'Other'">
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'coming_other')">
              <el-form-item :label="$t('imm_0008.coming_other')" prop="coming_other">
                <el-input @change="saveIMM(form)" v-model="form.coming_other" style="width: 100%" />
              </el-form-item>
            </v-col>
          </template>
          <v-col cols="12" class="mb-3" v-if="!isDataValidation">
            <label class="black--text body-1">{{ $t('global.date_place_most_recent') }}</label>
          </v-col>
          <v-col cols="12" sm="6" v-if="dataValidation(form, 'coming_recent_date')">
            <el-form-item :label="$t('imm_0008.coming_recent_date')" prop="coming_recent_date">
              <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.coming_recent_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" v-if="dataValidation(form, 'coming_recent_place')">
            <el-form-item :label="$t('imm_0008.coming_recent_place')" prop="coming_recent_place">
              <el-input @change="saveIMM(form)" v-model="form.coming_recent_place" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <v-col cols="12" class="mb-3" v-if="!isDataValidation">
            <label class="black--text body-1">{{ $t('global.provide_number') }}</label>
          </v-col>
          <v-col cols="12" sm="6" v-if="dataValidation(form, 'coming_document_number')">
            <el-form-item :label="$t('imm_0008.coming_document_number')" prop="coming_document_number">
              <el-input @change="saveIMM(form)" v-model="form.coming_document_number" style="width: 100%;" />
            </el-form-item>
          </v-col>
        </v-row>
      </el-form>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  data() {
    return {
      status: [
        { name: 'Business' },
        { name: 'Tourism' },
        { name: 'Study' },
        { name: 'Work' },
        { name: 'Other' },
        { name: 'Family Visit' }
      ]
    }
  },
  mounted() {
    if (this.isDataValidation) {
      var item = {
        coming_date: null,
        coming_purpose: null,
        coming_recent_date: null,
        coming_recent_place: null,
        coming_document_number: null,
        coming_place: null
      }

      if (this.form.coming_purpose !== 'Other') {
        delete item.coming_other
      }

      this.checkItem(this.form, item)
    }
  }
}
</script>

<style>
  .small-input .el-input{
    width: 50px;
    float: left;
    margin-right: 15px;
  }
  .small-input .el-form-item__content{
    line-height: inherit;
  }
  .small-input .el-form-item__content .el-form-item__label{
    display: inherit;
    padding-top: 5px;
  }
</style>
