import api from "./../plugins/axios";
import { orderObjectBy } from "./../utils/sort";

export const state = {
  secondaryEducation: [],
  outsideCanada: [],
  withinCanada: [],
  withinBC: [],
  intendedWorkCanada: [],
  officialLanguage: [],
  workExperience: [],
  address: [],
  travel: [],
  languageTestDetails: [],
  occupation: [],
  employment: [],
  familyInfoSummary: [],
  education: [],
  familyMembersCanada: [],
  military: [],
  membership: [],
  government: [],
  prevresidence: [],
  prevMaritalStatus: [],
  sinp: [],
  businessOwnership: [],
  businessPlan: [],
  businessExplorationTrip: [],
  appFamilyMember: [],
  sVisitHistory: [],
  sEmploymentHistory: [],
  sCoSigners: [],
  sDependents: [],
  sPreDependent: [],
  sPreAddFamilyMember: [],
  sEligibility: [],
  sGeneral: [],
  sCeremony: [],
  sKnowPeople: [],
  businessExperience: [],
  applicationPR: [],
  companyInformation: [],
  corporateStream: [],
  currentBuniess: [],
  employeeGeneric: [],
  expenditure: [],
  humanResource: [],
  manitoba: [],
  navoscotia: [],
  ownershipBreakdown: [],
  supplier: [],
  skilledWolker: [],
  semiSkilledWolker: [],
  backgroundQuestionSinp: [],
  backgroundQuestion: [],
  albertaIndustry: [],
  educationGeneral: [],
  usefulLinks: [],
  visitCanadaDetails: [],
  citizenshipAdult: [],
  listNames: [],
  temporaryResidenceStatus: [],
  anotherCountry: [],
  employmentEducation: [],
  taxInsurance: [],
  citizenshipStatus: [],
  travelDocuments: [],
  citizenshipMinor: [],
  parents: [],
  permanentResident: [],
  citizenshipAct: [],
  citizenshipCalculator: [],
  absentCanada: [],
  sysMenus: [],
  sysMenuRoles: [],
  secRoleResources: [],
  sysUserGroup: [],
  sysUsers: [],
  secBusinessPartners: [],
  applicationTypes: [],
  sinpEligibilityQuestions: []
};

export const actions = {
  getAll({ commit }, params) {
    return api.get(params.url).then(
      (response) => {
        var datas = response.data.resource;
        if (params.order !== undefined) datas = orderObjectBy(response.data.resource, params.order, -1);
        commit("GET_DATAS", { slug: params.slug, datas: datas });
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", params.slug);
        return Promise.reject(err);
      }
    );
  },
  getById({ commit }, params) {
    return api.get(params.url).then(
      (response) => {
        commit("GET_DATAS", { slug: params.slug, datas: response.data.resource[0] || {} });
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", params.slug);
        return Promise.reject(err);
      }
    );
  },
  save({ commit }, params) {
    return api.post(params.url, { resource: params.data }).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", params.slug);
        return Promise.reject(err);
      }
    );
  },
  edit({ commit }, params) {
    let url = `${params.url}/${params.data.id}`
    if (params.data.imms_uuid !== undefined && params.data.imms_uuid !== null && params.url.includes('imms_table')) {
      url = `${params.url}/${params.data.imms_uuid}`
    }
    return api.put(url, params.data).then(
      (response) => {
        commit("UPDATE_DATA", { item: params.data, slug: params.slug });
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", params.slug);
        return Promise.reject(err);
      }
    );
  },
  delete({ commit }, params) {
    return api.delete(params.url).then(
      (response) => {
        commit("DELETE_DATA", { item: params.data, slug: params.slug });
        return Promise.resolve(response);
      },
      (error) => {
        commit("FAILURE_DATAS", params.slug);
        return Promise.reject(error);
      }
    );
  }
};

export const mutations = {
  GET_DATAS(state, data) {
    state[data.slug] = data.datas;
  },
  UPDATE_DATA(state, data) {
    if (data.slug != undefined) {
      let idx = state[data.slug].map((p) => p.id).indexOf(data.item.id);
      state[data.slug].splice(idx, 1, data.item);
    }
  },
  FAILURE_DATAS(state, slug) {
    state[slug] = [];
  },
  DELETE_DATA(state, data) {
    let idx = state[data.slug].map((p) => p.id).indexOf(data.item.id);
    state[data.slug].splice(idx, 1);
  }
};
