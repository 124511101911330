<template>
  <v-card class="mt-3">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()">Add</v-btn>
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{ sectionInfoBox }}
      </v-alert>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item :name="i+1" v-for="(item,i) in items" :key="i" :id="'row' + item.id">
          <template slot="title">
            <div v-on:click="getProvince(item);getProv(item)" class="relative">
              <span class="el-col-title">{{item.legal_name}} - {{ item.operating_name }}</span>
            </div>
          </template>
            <el-form :model="item" ref="form" label-position="top" class="mt-3 form">
              <v-card>
                <v-card-title>
                  <span>Nova Scotia Nominee Program: International Graduate Entrepreneur Stream NSNP 500 – Application Form for the Principal ApplicantCurrent Business Information</span>
                  <v-spacer />
                </v-card-title>
                <v-card-text class="p-n">
                  <v-row dense class="medium-space">
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Is your business" prop="business">
                        <el-select :id="'business' + item.id" v-model="item.business" style="width: 100%">
                          <el-option value="Start-up">Start-up</el-option>
                          <el-option value="Business Succession">Business Succession</el-option>
                        </el-select>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Legal Name of Company/Organization" prop="legal_name">
                        <el-input :id="'legal_name' + item.id" v-model="item.legal_name" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Operating Name" prop="operating_name">
                        <el-input :id="'operating_name' + item.id" v-model="item.operating_name" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Number (BN) for tax/payroll purposes" prop="number_tax_payer">
                        <el-input :id="'number_tax_payer' + item.id" v-model="item.number_tax_payer" type="number" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Registry of Joint Stocks Number(Business Registration)" prop="registry_joints_stocks">
                        <el-input :id="'registry_joints_stocks' + item.id" v-model="item.registry_joints_stocks" type="number" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Start-up investment Amount (if applicable)" prop="startup_amount">
                        <el-input :id="'startup_amount' + item.id" v-model="item.startup_amount" type="number" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Business Purchase Price (if applicable)" prop="business_price">
                        <el-input :id="'business_price' + item.id" v-model="item.business_price" type="number" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Business Website" prop="business_website">
                        <el-input :id="'business_website' + item.id" v-model="item.business_website" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mt-3">
                <v-card-title>
                  <span>Current Business Address</span>
                  <v-spacer />
                </v-card-title>
                <v-card-text class="p-n">
                  <v-row dense class="medium-space">
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Country" prop="country">
                        <div class="el-select native-select">
                          <div class="el-input">
                            <select @change="getProv(item)" :id="'country' + item.id" v-model="item.country" placeholder="Select" class="el-input__inner">
                              <option v-for="(itemC, index) in country" v-bind:key="index" :value="itemC.id" :label="itemC.country_name">{{ itemC.country_name }}</option>
                            </select>
                          </div>
                        </div>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" v-show="selectProv">
                      <el-form-item label="Province" prop="province">
                        <div class="el-select native-select">
                          <div class="el-input">
                            <select v-model="item.province" :id="'province' + item.id" placeholder="Select" class="el-input__inner">
                              <option v-for="(itemP, index) in prov" v-bind:key="index" :value="itemP.id" :label="itemP.province_name">{{ itemP.province_name }}</option>
                            </select>
                          </div>
                        </div>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="City/Town" prop="city">
                        <el-input :id="'city' + item.id" v-model="item.city" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Street address" prop="street_address">
                        <el-input :id="'street_address' + item.id" v-model="item.street_address" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Postal code" prop="postal_code">
                        <el-input :id="'postal_code' + item.id" v-model="item.postal_code" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mt-3">
                <v-card-title>
                  <span>Current Business Mailing Address</span>
                  <v-spacer />
                </v-card-title>
                <v-card-text class="p-n">
                  <v-row dense class="medium-space">
                    <v-col cols="12">
                      <v-row dense class="medium-space">
                        <v-col cols="12" sm="6" md="4" lg="3">
                          <el-form-item label="Country" prop="current_country">
                            <div class="el-select native-select">
                              <div class="el-input">
                                <select @change="getProvince(item)" :id="'current_country' + item.id" v-model="item.current_country" placeholder="Select" class="el-input__inner">
                                  <option v-for="(itemCC, index) in country" v-bind:key="index" :value="itemCC.id" :label="itemCC.country_name">{{ itemCC.country_name }}</option>
                                </select>
                              </div>
                            </div>
                          </el-form-item>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" v-show="selectProvine">
                          <el-form-item label="Province" prop="current_province">
                            <div class="el-select native-select">
                              <div class="el-input">
                                <select :id="'current_province' + item.id" v-model="item.current_province" placeholder="Select" class="el-input__inner">
                                  <option v-for="(itemPP, index) in province" v-bind:key="index" :value="itemPP.province_name" :label="itemPP.province_name">{{ itemPP.province_name }}</option>
                                </select>
                              </div>
                            </div>
                          </el-form-item>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                          <el-form-item label="City/Town" prop="current_city">
                            <el-input :id="'current_city' + item.id" v-model="item.current_city" style="width: 100%" />
                          </el-form-item>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                          <el-form-item label="Street address" prop="current_street_address">
                            <el-input :id="'current_street_address' + item.id" v-model="item.current_street_address" style="width: 100%" />
                          </el-form-item>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                          <el-form-item label="Postal code" prop="current_postalcode">
                            <el-input :id="'current_postalcode' + item.id" v-model="item.current_postalcode" style="width: 100%" />
                          </el-form-item>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mt-3">
                <v-card-title>
                  <span>Current Business Information- Continuation</span>
                  <v-spacer />
                </v-card-title>
                <v-card-text class="p-n">
                  <v-row dense class="medium-space">
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="What is your current position or title in your current business (Example: owner, partner, manager, etc.)" prop="current_position">
                        <el-input :id="'current_position' + item.id" v-model="item.current_position" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="How many hours per week do you spend managing the business" prop="it">
                        <el-input :id="'it' + item.id" v-model="item.it" type="number" style="width: 100%"/>
                      </el-form-item>
                    </v-col> -->
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="How many years have you owned the business?" prop="years_owned">
                        <el-input :id="'years_owned' + item.id" v-model="item.years_owned" type="number" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="What type of business do you own? (Example: manufacturing, exporting, processing)" prop="type_of_business">
                        <el-input :id="'type_of_business' + item.id" v-model="item.type_of_business" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="How many employees does the company currently have?" prop="number_of_employees">
                        <el-input :id="'number_of_employees' + item.id" v-model="item.number_of_employees" type="number" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="How many employees do you manage?" prop="employees_managed">
                        <el-input :id="'employees_managed' + item.id" v-model="item.employees_managed" type="number" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="What are the total company assets?" prop="total_assets">
                        <el-input :id="'total_assets' + item.id" v-model="item.total_assets" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="What is your level of decision-making ability in the business?" prop="decision_making">
                        <el-input :id="'decision_making' + item.id" v-model="item.decision_making" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mt-3">
                <v-card-title>
                  <span>Industry/Sector</span>
                  <v-spacer />
                </v-card-title>
                <v-card-text class="p-n">
                  <v-row dense class="medium-space">
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item prop="aerospace">
                        <el-checkbox :id="'aerospace' + item.id" v-model="item.aerospace">Aerospace</el-checkbox>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item prop="agri_food">
                        <el-checkbox :id="'agri_food' + item.id" v-model="item.agri_food">Agri-food/seafood</el-checkbox>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item prop="biotechnology">
                        <el-checkbox :id="'biotechnology' + item.id" v-model="item.biotechnology">Biotechnology</el-checkbox>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item prop="culture">
                        <el-checkbox :id="'culture' + item.id" v-model="item.culture">Culture</el-checkbox>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item prop="energy">
                        <el-checkbox :id="'energy' + item.id" v-model="item.energy">Energy</el-checkbox>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item prop="forestry">
                        <el-checkbox :id="'forestry' + item.id" v-model="item.forestry">Forestry</el-checkbox>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item prop="manufacturing">
                        <el-checkbox :id="'manufacturing' + item.id" v-model="item.manufacturing">Manufacturing</el-checkbox>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item prop="materials">
                        <el-checkbox :id="'materials' + item.id" v-model="item.materials">Mining/materials</el-checkbox>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item prop="oceans_technology">
                        <el-checkbox :id="'oceans_technology' + item.id" v-model="item.oceans_technology">Oceans technology</el-checkbox>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item prop="retail">
                        <el-checkbox :id="'retail' + item.id" v-model="item.retail">Retail/services</el-checkbox>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item prop="tourism">
                        <el-checkbox :id="'tourism' + item.id" v-model="item.tourism">Tourism</el-checkbox>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item prop="other">
                        <el-checkbox :id="'other' + item.id" v-model="item.other">If Other (please specifiy)?</el-checkbox>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Identify the type of ownership" prop="type_of_ownership">
                        <el-select :id="'type_of_ownership' + item.id" v-model="item.type_of_ownership" placeholder="Select" style="width: 100%;">
                          <el-option value="Sole Proprietorship" label="Sole Proprietorship">Sole Proprietorship</el-option>
                          <el-option value="Partnership" label="Partnership">Partnership</el-option>
                          <el-option value="Corporationp" label="Corporationp">Corporationp</el-option>
                        </el-select>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Describe any capital asset purchases (eg: machinery and equipment)" prop="describe_capital">
                        <el-input :id="'describe_capital' + item.id" type="textarea" v-model="item.describe_capital" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Provide a detailed description of the products/services your company offers its customers." prop="describe_products">
                        <el-input :id="'describe_products' + item.id" type="textarea" v-model="item.describe_products" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Describe your inventory by type (eg: finalized products, raw materials, etc)" prop="describe_inventory">
                        <el-input :id="'describe_inventory' + item.id" type="textarea" v-model="item.describe_inventory" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-text>
                  <v-btn :loading="loading" :disabled="loading || isCheck" color="primary" small @click.native="submitForm(i,item)">
                    {{ $t('global.save') }}
                  </v-btn>
                  <v-btn color="error" small @click.native="deleteOpen(item,i)">Delete</v-btn>
                </v-card-text>
              </v-card>
            </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/plugins/axios'
import { orderObjectBy } from '@/utils/sort'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        business: null,
        legal_name: null,
        operating_name: null,
        number_tax_payer: null,
        registry_joints_stocks: null,
        startup_amount: null,
        business_price: null,
        business_website: null,
        country: null,
        city: null,
        province: null,
        street_address: null,
        postal_code: null,
        current_country: null,
        current_province: null,
        current_city: null,
        current_street_address: null,
        current_postalcode: null,
        current_position: null,
        years_owned: null,
        type_of_business: null,
        number_of_employees: null,
        total_assets: null,
        employees_managed: null,
        decision_making: null,
        aerospace: null,
        agri_food: null,
        biotechnology: null,
        culture: null,
        energy: null,
        forestry: null,
        manufacturing: null,
        materials: null,
        retail: null,
        oceans_technology: null,
        tourism: null,
        other: null,
        type_of_ownership: null,
        describe_capital: null,
        describe_products: null,
        describe_inventory: null,
        it: null
      },
      activeNames: -1,
      prov: [],
      selectProvine: false,
      selectProv: false,
      phoneMasked: false,
      phoneMask: ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      url: 'imms_table/application_current_business',
      state: 'currentBuniess',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      province: 'other/province'
    }),
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    getProvince(item) {
      if (item.current_country !== null || item.current_country !== '') {
        item.current_country = parseFloat(item.current_country)
      }
      if (42 == item.current_country || 239 == item.current_country) {
        this.phoneMasked = true
        this.selectProvine = true
        this.$store.dispatch('other/getProvince', item.current_country)
      }
      else {
        this.phoneMasked = false
        this.selectProvine = false
      }
    },
    getProv(item) {
      if (item.country !== null || item.country !== '') {
        item.country = parseFloat(item.country)
      }
      if (42 == item.country || 239 == item.country) {
        this.selectProv = true
        api.get('table/provinces?filter=country_id=' + item.country)
          .then(response => {
            var datas = orderObjectBy(response.data.resource, 'province_name', 1)
            this.prov = datas
          })
      }
      else {
        this.selectProv = false
      }
    },
    fetchData() {
      this.$Progress.start()
      var url = 'table/application_current_business?filter=member_uid=' + this.memberUID
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) this.checkItemsFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.form = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        business: null,
        legal_name: null,
        operating_name: null,
        number_tax_payer: null,
        registry_joints_stocks: null,
        startup_amount: null,
        business_price: null,
        business_website: null,
        country: null,
        city: null,
        province: null,
        street_address: null,
        postal_code: null,
        current_country: null,
        current_province: null,
        current_city: null,
        current_street_address: null,
        current_postalcode: null,
        current_position: null,
        years_owned: null,
        type_of_business: null,
        number_of_employees: null,
        total_assets: null,
        employees_managed: null,
        decision_making: null,
        aerospace: null,
        agri_food: null,
        biotechnology: null,
        culture: null,
        energy: null,
        forestry: null,
        manufacturing: null,
        materials: null,
        retail: null,
        oceans_technology: null,
        tourism: null,
        other: null,
        type_of_ownership: null,
        describe_capital: null,
        describe_products: null,
        describe_inventory: null,
        it: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.form)
      var len = this.items.length
      this.activeNames = len
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true

          if (formObj.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.item.resetFields()
      this.nodeClear()
    }
  }
}
</script>
