<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>
        <span>{{ $t('title.application_sinp') }}</span>
        <v-spacer />
        <v-btn color="primary" dark small @click.native="add()">{{ $t('global.add') }}</v-btn>
      </v-card-title>
      <v-card-text class="p-n">
        <v-alert type="info" border="left" dense class="mb-3" v-if="$t('infobox.application_sinp') !== ''">
           {{ $t('infobox.application_sinp') }}
        </v-alert>
        <table class="table" style="width:100%">
          <thead>
            <tr>
              <th>{{ $t('application_sinp.enterprice_name') }}</th>
              <th>{{ $t('application_sinp.start_date') }}</th>
              <th>{{ $t('application_sinp.end_date') }}</th>
              <th>{{ $t('global.action') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id" :id="'row' + item.id">
              <td>{{ item.enterprice_name }}</td>
              <td>{{ item.start_date | day}}</td>
              <td>{{ item.end_date | day}}</td>
              <td class="text-right" width="80">
                <v-btn icon small @click.native="edit(item)">
                  <v-icon small>edit</v-icon>
                </v-btn>
                <v-btn icon small @click.native="deleteOpen(item)">
                  <v-icon small>delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </v-card>

    <el-form v-if="visible" :model="form" ref="form" label-position="top" class="mt-3">
      <v-card>
        <v-card-title>
          <span>{{ $t('global.source_funds') }}</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <el-form-item :label="$t('application_sinp.enterprice_name')" prop="enterprice_name">
                <el-input :id="'enterprice_name' + form.id" v-model="form.enterprice_name" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item :label="$t('application_sinp.start_date')" prop="start_date">
                <el-date-picker :id="'start_date' + form.id" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.start_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item :label="$t('application_sinp.end_date')" prop="end_date">
                <el-date-picker :id="'end_date' + form.id" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.end_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item :label="$t('application_sinp.enterprice_name')" prop="position_enterprice">
                <el-radio-group :id="'position_enterprice' + form.id" v-model="form.position_enterprice">
                  <el-radio :label="$t('global.owner')">{{ $t('global.owner') }}</el-radio>
                  <el-radio :label="$t('global.employee')">{{ $t('global.employee') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" md="8">
              <el-form-item :label="$t('application_sinp.responsibilities')" prop="responsibilities">
                <el-input :id="'responsibilities' + form.id" type="textarea" :rows="3" v-model="form.responsibilities" style="width: 100%" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-3">
        <v-card-title>
          <span>{{ $t('global.income_work_period') }}</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <el-form-item :label="$t('application_sinp.annual_income')" prop="annual_income">
                <el-input :id="'annual_income' + form.id" type="number" v-model="form.annual_income" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item :label="$t('application_sinp.bonuses')" prop="bonuses">
                <el-input :id="'bonuses' + form.id" type="number" v-model="form.bonuses" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item :label="$t('application_sinp.commission')" prop="commission">
                <el-input :id="'commission' + form.id" type="number" v-model="form.commission" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item :label="$t('application_sinp.dividends_paid')" prop="dividends_paid">
                <el-input :id="'dividends_paid' + form.id" type="number" v-model="form.dividends_paid" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item :label="$t('application_sinp.gifts')" prop="gifts">
                <el-input :id="'gifts' + form.id" type="number" v-model="form.gifts" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item :label="$t('application_sinp.inheritance')" prop="inheritance">
                <el-input :id="'inheritance' + form.id" type="number" v-model="form.inheritance" style="width: 100%" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-3">
        <v-card-title>
          <span>{{ $t('global.investment_work_period') }}</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <el-form-item :label="$t('application_sinp.investment_enterprise')" prop="investment_enterprise">
                <el-input :id="'investment_enterprise' + form.id" v-model="form.investment_enterprise" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item :label="$t('application_sinp.purchase_price')" prop="purchase_price">
                <el-input :id="'purchase_price' + form.id" v-model="form.purchase_price" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item :label="$t('application_sinp.current_price')" prop="current_price">
                <el-input :id="'current_price' + form.id" v-model="form.current_price" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item :label="$t('application_sinp.percentage')" prop="percentage">
                <el-input :id="'percentage' + form.id" v-model="form.percentage" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item :label="$t('application_sinp.property')" prop="property">
                <el-input :id="'property' + form.id" type="textarea" :rows="3" v-model="form.property" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item :label="$t('application_sinp.investment')" prop="investment">
                <el-input :id="'investment' + form.id" type="textarea" :rows="3" v-model="form.investment" style="width: 100%" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-3">
        <v-card-title>
          <span>{{ $t('global.loans_work_period') }}</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="7" md="8">
              <el-form-item :label="$t('application_sinp.bank_loan')" prop="bank_loan">
                <el-input :id="'bank_loan' + form.id" v-model="form.bank_loan" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="5" md="4">
              <el-form-item :label="$t('application_sinp.bank_loan_date')" prop="bank_loan_date">
                <el-input :id="'bank_loan_date' + form.id" v-model="form.bank_loan_date" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="7" md="8">
              <el-form-item :label="$t('application_sinp.mortgage_loan')" prop="mortgage_loan">
                <el-input :id="'mortgage_loan' + form.id" v-model="form.mortgage_loan" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="5" md="4">
              <el-form-item :label="$t('application_sinp.mortgage_loan_date')" prop="mortgage_loan_date">
                <el-input :id="'mortgage_loan_date' + form.id" v-model="form.mortgage_loan_date" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="7" md="8">
              <el-form-item :label="$t('application_sinp.personal_loan')" prop="personal_loan">
                <el-input :id="'personal_loan' + form.id" v-model="form.personal_loan" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="5" md="4">
              <el-form-item :label="$t('application_sinp.personal_loan_date')" prop="personal_loan_date">
                <el-input :id="'personal_loan_date' + form.id" v-model="form.personal_loan_date" style="width: 100%" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm()">
            {{ $t('global.save') }}
          </v-btn>
          <v-btn small @click.native="visible = false">{{ $t('global.close') }}</v-btn>
        </v-card-text>
      </v-card>
    </el-form>
  </div>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date()
        }
      },
      checkEdit: false,
      visible: false,
      form: {},
      formAdd: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        enterprice_name: '',
        postal_code: '',
        position_enterprice: '',
        start_date: null,
        end_date: null,
        responsibilities: null,
        annual_income: null,
        bonuses: null,
        commission: null,
        dividends_paid: null,
        gifts: null,
        inheritance: null,
        percentage: null,
        current_price: null,
        purchase_price: null,
        investment_enterprise: null,
        property: null,
        investment: null,
        bank_loan: null,
        mortgage_loan: null,
        personal_loan: null,
        bank_loan_date: null,
        mortgage_loan_date: null,
        personal_loan_date: null
      },
      activeNames: -1,
      state: 'sinp',
      url: 'imms_table/application_sinp',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/application_sinp?filter=member_uid=${this.memberUID}`
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) this.checkItemsFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    edit(item) {
      this.visible = true
      this.form = item
      this.checkEdit = true
    },
    add() {
      this.form = this.formAdd
      this.visible = true
      this.checkEdit = false
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true

          var action = 'all_stores/save'
          if (this.checkEdit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': this.form, 'slug': this.state })
            .then(response => {
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.fetchData()
              this.visible = false
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    }
  }
}
</script>
