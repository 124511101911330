<template>
  <div v-if="hideSection">
    <template v-if="isHideParents">
      <v-card class="mt-3" v-if="!visible">
        <v-card-title>
          <span>Principal and Parents</span>
          <v-spacer />
          <v-btn v-if="!isDataValidation" color="primary" dark small :loading="loading" @click.native="addMember('parent');">Add Parent</v-btn>
        </v-card-title>
        <v-card-text>
          <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
            {{sectionInfoBox}}
          </v-alert>
          <v-data-table
            dense
            class="ofas-datatable"
            v-bind:headers="headers"
            v-bind:items="memberItems.parents"
            hide-default-footer
            disable-pagination
            >
            <template #[`item`]="{ item }">
              <tr>
                <td>{{ item.fname }}</td>
                <td>{{ item.lname }}</td>
                <td>{{ item.relationship_name }}</td>
                <td>{{ item.occupation_current }}</td>
                <td>{{ item.res_city }}</td>
                <td>{{ item.country_name }}</td>
                <td>{{ item.deceased ? '(deceased)' : item.age }}</td>
                <td class="text-right" width="80">
                  <v-btn icon small @click.native="editMember(item);">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn v-if="item.relationship_name !== 'Principal'" icon small @click.native="deleteMember(item)">
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>

    <template v-if="isHideSpouse">
      <v-card class="mt-3" v-if="!visible">
        <v-card-title>
          <span>Spouse or Common-law partner</span>
          <v-spacer />
          <v-btn v-if="!isDataValidation" :disabled="isSpouse" color="primary" dark small :loading="loading" @click.native="checkSpouse();">Add Spouse</v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            dense
            class="ofas-datatable"
            v-bind:headers="headers"
            v-bind:items="memberItems.spouse"
            hide-default-footer
            disable-pagination
            >
            <template #[`item`]="{ item }">
              <tr>
                <td>{{ item.fname }}</td>
                <td>{{ item.lname }}</td>
                <td>{{ item.relationship_name }}</td>
                <td>{{ item.occupation_current }}</td>
                <td>{{ item.res_city }}</td>
                <td>{{ item.country_name }}</td>
                <td>{{ item.deceased ? '(deceased)' : item.age }}</td>
                <td class="text-right" width="80">
                  <v-btn icon small @click.native="editMember(item);">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn icon small @click.native="deleteMember(item)">
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>

    <template v-if="isHideChildren">
      <v-card class="mt-3" v-if="!visible">
        <v-card-title>
          <span>Children</span>
          <v-spacer />
          <v-btn v-if="!isDataValidation" color="primary" dark small @click.native="addMember('children');">Add</v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            dense
            class="ofas-datatable"
            v-bind:headers="headers"
            v-bind:items="memberItems.children"
            hide-default-footer
            disable-pagination
            >
            <template #[`item`]="{ item }">
              <tr>
                <td>{{ item.fname }}</td>
                <td>{{ item.lname }}</td>
                <td>{{ item.relationship_name }}</td>
                <td>{{ item.occupation_current }}</td>
                <td>{{ item.res_city }}</td>
                <td>{{ item.country_name }}</td>
                <td>{{ item.deceased ? '(deceased)' : item.age }}</td>
                <td class="text-right" width="80">
                  <v-btn icon small @click.native="editMember(item, 'children');">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn icon small @click.native="deleteMember(item)">
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>

    <template v-if="isHideSublings">
      <v-card class="mt-3" v-if="!visible">
        <v-card-title>
          <span>Brothers and Sisters</span>
          <v-spacer />
          <v-btn v-if="!isDataValidation" color="primary" dark small @click.native="addMember('brothers');">Add</v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            dense
            class="ofas-datatable"
            v-bind:headers="headers"
            v-bind:items="memberItems.brothers"
            hide-default-footer
            disable-pagination
            >
            <template #[`item`]="{ item }">
              <tr>
                <td>{{ item.fname }}</td>
                <td>{{ item.lname }}</td>
                <td>{{ item.relationship_name }}</td>
                <td>{{ item.occupation_current }}</td>
                <td>{{ item.res_city }}</td>
                <td>{{ item.country_name }}</td>
                <td>{{ item.deceased ? '(deceased)' : item.age }}</td>
                <td class="text-right" width="80">
                  <v-btn icon small @click.native="editMember(item, 'brothers');">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn icon small @click.native="deleteMember(item)">
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>

    <el-form v-if="visible" :rules="rules" :model="form" ref="form" label-position="top" class="mt-3">
      <v-card>
        <v-card-title>
          <span>Members of Family</span>
          <v-spacer />
          <v-list class="form-list pa-0">
            <v-list-item v-if="!isDataValidation" class="ma-0">
              <v-list-item-content class="mt-2">
                <v-list-item-title>
                  <el-checkbox v-model="form.deceased" @change="chechDeceased(form)">{{ $t('application_family_members.deceased') }}</el-checkbox>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!isDataValidation" class="ma-0">
              <v-list-item-content class="mt-2">
                <v-list-item-title>
                  <el-checkbox v-model="form.accompany">{{ $t('application_family_members.accompany') }}</el-checkbox>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'fname')">
              <el-form-item :label="$t('application_family_members.fname')" prop="fname">
                <el-input v-model="form.fname" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'middle_name')">
              <el-form-item :label="$t('application_family_members.middle_name')" prop="middlename">
                <el-input v-model="form.middlename" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'lname')">
              <el-form-item :label="$t('application_family_members.lname')" prop="lname">
                <el-input v-model="form.lname" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense v-if="dataValidationItems(form,'fname_native_language') && !form.deceased">
            <v-col cols="12">
              <v-divider class="mb-3"></v-divider>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <label class="el-form-item__label">{{ $t('application_family_members.fname_native_language') }}</label>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item prop="fname_native_language">
                <el-input v-model="form.fname_native_language" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <v-divider class="mb-3"></v-divider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'birth_date')">
              <el-form-item :label="$t('application_family_members.birth_date')" prop="birth_date">
                <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.birth_date"
                  v-on:click.native="checkDate($event)" style="width: 100%"></el-date-picker>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'birth_country_id')">
              <el-form-item :label="$t('application_family_members.country_id')" prop="birth_country_id">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select v-model="form.birth_country_id" placeholder="Select" class="el-input__inner">
                      <option v-for="(item, index) in country" v-bind:key="'birth_country' + index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'birth_place') && !form.deceased">
              <el-form-item :label="$t('application_family_members.city_birth')" prop="birth_place">
                <el-input v-model="form.birth_place" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'relationship_to_principal') && isRelationship">
              <el-form-item :label="$t('application_family_members.type') + ' ' + applicantName" prop="relationship_to_principal">
                <el-select @change="getRelationship(form.relationship_to_principal)" v-model="form.relationship_to_principal" placeholder="Select" style="width: 100%;">
                  <el-option v-for="(item, index) in relationshipStatus" v-bind:key="'relationship_to_principal_' + index" :value="item.id" :label="item.name">{{ item.name }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'family_name_birth') && !form.deceased">
              <el-form-item :label="$t('application_family_members.family_name_birth')" prop="family_name_birth">
                <el-input v-model="form.family_name_birth" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'email_address') && !form.deceased">
              <el-form-item :label="$t('application_family_members.email_address')" prop="email_address" :rules="[{ type: 'email', message: 'Please input correct email address', trigger: 'blur,change' }]">
                <el-input type="email" v-model="form.email_address" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'marital_status') && !form.deceased">
              <el-form-item :label="$t('application_family_members.marital_status')" prop="marital_status">
                <el-select v-model="form.marital_status" style="width: 100%;">
                  <el-option v-for="item in maritalStatus" :key="'marital_status_' + item.name" :label="item.name" :value="item.name">{{ item.name }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'not_accompanying') && !form.deceased && isShow && form.accompany === false">
              <el-form-item :label="$t('application_family_members.not_accompanying')" prop="not_accompanying">
                <el-input v-model="form.not_accompanying" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'current_residence_country') && !form.deceased">
              <el-form-item :label="$t('application_family_members.country_residence')" prop="current_residence_country">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select v-model="form.current_residence_country" placeholder="Select" class="el-input__inner">
                      <option v-for="(item, index) in country" v-bind:key="'current_residence_country_' + index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'res_city') && !form.deceased">
              <el-form-item :label="$t('application_family_members.city_residence')" prop="res_city">
                <el-input v-model="form.res_city" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'address_new') && !form.deceased">
              <el-form-item :label="$t('application_family_members.address')" prop="address_new">
                <el-input v-model="form.address_new" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'occupation_current') && !form.deceased">
              <el-form-item :label="$t('application_family_members.present_occupation')" prop="occupation_current">
                <el-input v-model="form.occupation_current" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'citizenship1')">
              <el-form-item label="Country of Citizenship" prop="citizenship1">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select v-model="form.citizenship1" placeholder="Select" class="el-input__inner">
                      <option v-for="(item, index) in country" v-bind:key="'citizenship1_' + index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'death_date') && form.deceased">
              <el-form-item :label="$t('application_family_members.death_date')" prop="death_date">
                <el-date-picker value-format="yyyy-MM-dd" v-model="form.death_date" :picker-options="datePickerOptions"
                  v-on:click.native="checkDate($event)" @change="chechDeathAddress(form)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'death_place') && form.deceased">
              <el-form-item :label="$t('application_family_members.death_place')" prop="death_place">
                <el-input @change="chechDeathAddress(form)" v-model="form.death_place" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'country_death') && form.deceased">
              <el-form-item label="Country of Death" prop="country_death">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select v-model="form.country_death" @change="chechDeathAddress(form)" placeholder="Select" class="el-input__inner">
                      <option v-for="(item, index) in country" v-bind:key="'country_death_' + index" :value="item.country_name" :label="item.country_name">{{
                        item.country_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'res_postal_code') && !form.deceased">
              <el-form-item label="Postal Code" prop="res_postal_code">
                <el-input v-model="form.res_postal_code" style="width: 100%" />
              </el-form-item>
            </v-col>
            <template v-if="isOINP">
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'resident_canada')">
                <el-form-item label="Is your family member a Canadian citizen or permanent resident of Canada?" prop="resident_canada">
                  <el-radio-group v-model="form.resident_canada" style="width: 100%;">
                    <el-radio label="Yes" value="Yes"></el-radio>
                    <el-radio label="No" value="No"></el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'accompany_to_ontario')">
                <el-form-item label="Is your family member accompanying you to Ontario?" prop="accompany_to_ontario">
                  <el-radio-group v-model="form.accompany_to_ontario" @change="submitForm()" style="width: 100%;">
                    <el-radio label="Yes" value="Yes"></el-radio>
                    <el-radio label="No" value="No"></el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </template>
            <v-col cols="12">
              <v-divider class="mb-3" />
              <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm('submit')">{{ $t('global.save') }}</v-btn>
              <v-btn small @click.native="getMembers(); visible = false">Close</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </el-form>

    <div class="error--snack error" v-if="errorMessage.length > 0">
      <template v-for="(message, ind) in errorMessage">
        <div :key="'message_' + ind" class="error--snack__content">
            {{ message.message }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import datavalidation from '@/mixins/datavalidation.js'
import { guid } from '@/utils/guid'

export default {
  mixins: [datavalidation],
  props: {
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionAlias: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    datas: {
      type: Array
    },
    memberName: {
      type: String
    },
    urlView: {
      type: String
    },
    country: {
      type: Array
    },
    maritalStatus: {
      type: Array
    },
    isOINP: {
      type: Boolean
    },
    url: {
      type: String
    },
    isShow: {
      type: Boolean
    },
    isDataValidation: {
      type: Boolean
    },
    applicantID: {
      type: Number
    }
  },
  data() {
    var validateFirstName = (rule, value, callback) => {
      let idx = this.errorMessage.map(p => p.field).indexOf(rule.field)
      if (!value) {
        if (idx === -1) this.errorMessage.push({ message: 'Please input the first name', field: rule.field })
        callback(new Error(' '))
      }
      else {
        if (idx !== -1) this.errorMessage.splice(idx, 1)
        callback()
      }
    };
    var validateLastName = (rule, value, callback) => {
      let idx = this.errorMessage.map(p => p.field).indexOf(rule.field)
      if (!value) {
        if (idx === -1) this.errorMessage.push({ message: 'Please input the last name', field: rule.field })
        callback(new Error(' '))
      }
      else {
        if (idx !== -1) this.errorMessage.splice(idx, 1)
        callback()
      }
    };
    var checkCharacter = (rule, value, callback) => {
      var patt = new RegExp("[^ A-Za-z а-яА-ЯЁё]");
      var res = patt.test(value);

      if (res) {
        callback(new Error('Please only use alpha characters (abc)'));
      }
      else {
        callback();
      }
    };
    return {
      hideFamily: false,
      isRelationship: false,
      edit: false,
      form: {
        app_primary: false,
        u_id: null,
        fname: '',
        middlename: '',
        lname: '',
        birth_date: null,
        place: '',
        marital_status: null,
        address_new: null,
        type: null,
        relationship_to_principal: null,
        country_id: null,
        fname_native_language: null,
        family_name_birth: null,
        deceased: false,
        accompany: false,
        death_date: null,
        death_place: null,
        not_accompanying: null,
        dependant_type: null,
        email_address: '',
        birth_place: null,
        res_city: null,
        country_residence: null,
        occupation_current: null,
        citizenship1: null,
        sec_org_id: this.$orgId,
        resident_canada: null,
        accompany_to_ontario: null,
        res_postal_code: null
      },
      activeNames: -1,
      loading: false,
      items: [],
      memberItems: {
        parents: [],
        spouse: [],
        children: [],
        brothers: []
      },
      isSpouse: true,
      headers: [
        { text: 'First Name', value: 'fname', sortable: false, align: 'left' },
        { text: 'Last Name', value: 'lname', sortable: false, align: 'left' },
        { text: 'Relationship', value: 'relationship_name', sortable: false, align: 'left' },
        { text: 'Occupation', value: 'occupation_current', sortable: false, align: 'left' },
        { text: 'City of Residence', value: 'res_city', sortable: false, align: 'left' },
        { text: 'Country of Residence', value: 'country_name', sortable: false, align: 'left' },
        { text: 'Age', value: 'age', sortable: false, align: 'left' },
        { text: 'Action', sortable: false, align: 'left' }
      ],
      visible: false,
      ownMaritalStatus: null,
      rules: {
        fname: [
          { validator: validateFirstName, required: true, trigger: 'blur' },
          { validator: checkCharacter, trigger: 'blur' }
        ],
        lname: [
          { validator: validateLastName, required: true, trigger: 'blur' },
          { validator: checkCharacter, trigger: 'blur' }
        ],
        middlename: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        family_name_birth: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        relationship_to_principal: [
          { message: 'Please select the relationship to principal', required: true, trigger: 'blur' }
        ]
      },
      errorMessage: [],
      isHideChildren: true,
      isHideSublings: true,
      isHideSpouse: true,
      isHideParents: true
    }
  },
  computed: {
    ...mapGetters({
      relationshipStatus: 'other/relationships'
    }),
    disabledError: function() {
      return this.errorMessage.length > 0 ? true : false
    },
    applicantName: function() {
      return this.memberName != undefined ? this.memberName : ''
    }
  },
  created() {
    this.getMembers()
    this.getRelationshipStatus('name!=Principal')
  },
  methods: {
    getRelationshipStatus(filter) {
      this.$store.dispatch('other/getRelationShips', '?filter=' + filter + '&order=rel_order ASC,name ASC')
    },
    checkMessage(data) {
      if (!data.fname && data.fname === null) {
        this.errorMessage.push({ "message": "Please input the first name", "field": "fname" })
      }
      if (!data.lname && data.lname === null) {
        this.errorMessage.push({ "message": "Please input the last name", "field": "lname" })
      }
    },
    getMembers() {
      this.validationItems = []
      this.validateItem = []

      this.$api.get(this.urlView).then(response => {
        let items = response.data.resource
        let now = new Date()

        items.forEach(function(item) {
          item.age = null
          if (item.birth_date != null && !item.deceased) {
            let birth = moment(item.birth_date, 'YYYY')
            let month = moment(item.birth_date).get('month')
            let nowDate = moment(now, 'YYYY')
            let nowMonth = moment(now).get('month')
            let ageYear = nowDate.diff(birth, 'years')
            let ageMonth = nowMonth - month
            if (ageMonth < 0) {
              ageYear = ageYear - 1
              ageMonth = 12 + ageMonth
            }

            item.age = `${ageYear}.${ageMonth}`
          }
        })
        this.errorMessage = []
        this.visible = false
        this.getDataMember(items)
        this.$Progress.finish()
      })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    getDataMember(datas) {
      let parents = []
      let children = []
      let brothers = []
      let spouse = []
      this.isSpouse = false

      if (this.isDataValidation) {
        datas.forEach(function(data) {
          var form = {
            fname: '',
            lname: '',
            birth_date: null,
            place: '',
            marital_status: null,
            address_new: null,
            type: null,
            relationship_to_principal: null,
            country_id: null,
            death_date: null,
            death_place: null,
            not_accompanying: null,
            res_city: null,
            country_residence: null,
            occupation_current: null,
            country_death: null,
            resident_canada: null
          }

          if (!this.isOINP) {
            delete form.resident_canada
            delete form.accompany_to_ontario
          }

          if (!data.deceased) {
            delete form.death_date
            delete form.death_place
            delete form.country_death
          }
          else {
            delete form.occupation_current
            delete form.marital_status
            delete form.not_accompanying
            delete form.country_residence
            delete form.res_city
            delete form.address_new
          }

          var obj = this.checkFormItems(data, form);

          if (obj != 'empty') {
            switch (data.relationship_name) {
              case 'Principal':
                parents.push(data)
                break;
              case 'Father':
              case 'Mother':
                parents.push(data)
                break;
              case 'Spouse/Common-law partner':
              case 'Common-law':
                this.isSpouse = true
                spouse.push(data)
                break;
            }

            if (data.bothers_and_sisters) {
              brothers.push(data)
            }
            if (data.child) {
              children.push(data)
            }
          }
        }, this)
        if (datas.length == 0) {
          this.hideSection = true
        }
        else {
          this.hideSection = this.validationItems.length == 0 ? false : true
        }
      }
      else {
        datas.forEach(function(data) {
          switch (data.relationship_name) {
            case 'Principal':
              parents.push(data)
              break;
            case 'Father':
            case 'Mother':
              parents.push(data)
              break;
            case 'Spouse/Common-law partner':
            case 'Common-law':
              this.isSpouse = true
              spouse.push(data)
              break;
          }

          if (data.bothers_and_sisters) {
            brothers.push(data)
          }
          if (data.child) {
            children.push(data)
          }
        }, this)
      }

      if (!this.isDataValidation) {
        this.isHideChildren = true
        this.isHideSublings = true
        this.isHideSpouse = true
        this.isHideParents = true
      }
      else {
        this.isHideParents = parents.length == 0 ? false : true
        this.isHideChildren = children.length == 0 ? false : true
        this.isHideSpouse = spouse.length == 0 ? false : true
        this.isHideSublings = brothers.length == 0 ? false : true
      }

      this.memberItems.parents = parents
      this.memberItems.spouse = spouse
      this.memberItems.children = children
      this.memberItems.brothers = brothers
    },
    chechDeceased(form) {
      if (form.deceased) {
        form.marital_status = 'Not applicable - deceased'
        this.chechDeathAddress(form)
      }
      else {
        form.marital_status = null
        form.address_new = null
      }
    },
    chechDeathAddress(form) {
      if (form.deceased) {
        form.address_new = `Deceased on ${moment(form.death_date).format('YYYY-MM-DD')} at ${form.death_place}, ${form.country_death}`
      }
    },
    checkCountry(form) {
      if (form.relationship_to_principal != null) {
        form.relationship_to_principal = parseInt(form.relationship_to_principal)
      }
      if (form.country_id != null) {
        form.country_id = parseInt(form.country_id)
      }
      if (form.current_residence_country != null) {
        form.current_residence_country = parseInt(form.current_residence_country)
      }
    },
    clear() {
      this.form = {
        app_primary: false,
        u_id: null,
        fname: '',
        middlename: '',
        lname: '',
        birth_date: null,
        place: '',
        marital_status: null,
        address_new: null,
        type: null,
        relationship_to_principal: null,
        country_id: null,
        fname_native_language: null,
        family_name_birth: null,
        deceased: false,
        accompany: false,
        death_date: null,
        death_place: null,
        not_accompanying: null,
        dependant_type: null,
        email_address: '',
        birth_place: null,
        res_city: null,
        country_residence: null,
        occupation_current: null,
        resident_canada: null,
        citizenship1: null,
        res_postal_code: null
      }
      this.edit = false
    },
    getRelationship(id) {
      if (id === 1) {
        this.form.app_primary = true
      }
      else {
        this.form.app_primary = false
      }
    },
    async newSpouse() {
      try {
        this.loading = true
        let formAdd = {}
        formAdd.relationship_to_principal = 2
        formAdd.u_id = guid()
        formAdd.applicant_id = this.applicantID
        formAdd.marital_status = 'Married'
        formAdd.sec_org_id = this.$orgId

        await this.$api.post(`imms_table/imm_0008`, { resource: formAdd })
        this.loading = false
        this.getMembers()
      }
      catch (err) {
        console.error(err)
        this.loading = false
        this.$store.dispatch('toaster/add', { text: `Please contact supporting team!`, type: 'error' })
      }
    },
    async checkSpouse() {
      try {
        this.loading = true
        const res = await this.$api.get(`application/imm_0008?applicant_id=${this.applicantID}&relationship_to_principal=2`)
        if (res.data.resource.length === 0) {
          this.newSpouse()
        }
        else {
          this.loading = false
        }
      }
      catch (err) {
        console.error(err)
        this.loading = false
        this.$store.dispatch('toaster/add', { text: `Please contact supporting team!`, type: 'error' })
      }
    },
    deleteSpouse() {
      let vm = this;
      if (vm.memberItems.spouse.length > 0) {
        vm.memberItems.spouse.forEach(function(item) {
          vm.$api.delete(`/imms/imm_0008/${item.imms_uuid}`)
        })
      }
    },
    async deleteMember(item) {
      this.$confirm("Are you sure to delete this item?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(async() => {
        try {
          console.log('imms_uuid', item.imms_uuid)
          if (!item.imms_uuid) {
            this.$store.dispatch('toaster/add', { text: `Cannot delete item. Please contact supporting team!`, type: 'error' })
            return
          }
          await this.$api.delete(`/imms/imm_0008/${item.imms_uuid}`)
          this.getMembers()
          this.visible = false
          this.$store.dispatch('toaster/add', { text: 'Deleted', type: 'success' })
        }
        catch (err) {
          this.visible = false
          console.error(err);
          this.$store.dispatch('toaster/add', { text: `Cannot delete item. Please contact supporting team!`, type: 'error' })
        }
      })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Canceled"
          });
        });
    },
    checkMaritalStatus(item) {
      let vm = this
      let own = vm.ownMaritalStatus
      if (item.relationship_name == 'Principal' && (own == 'Married-physically present' || own == 'Married' || own == 'Common-law')) {
        if (item.marital_status != 'Married-physically present' && item.marital_status != 'Married' && item.marital_status != 'Common-law') {
          vm.$confirm('Your Spouse or Common-law partner information would be deleted?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(() => {

          }).catch(() => {
            vm.form.marital_status = own
            vm.$message({
              type: 'info',
              message: 'Canceled'
            })
          })
        }
      }
    },
    addMember(filter) {
      this.clear()
      this.visible = true
      this.checkEdit = false
      let filt = ''
      if (filter === 'brothers') {
        this.isRelationship = true
        filt = 'bothers_and_sisters = true'
      }
      else if (filter === 'parent') {
        this.isRelationship = true
        filt = '((id=8)OR(id=9))'
      }
      else if (filter === 'children') {
        this.isRelationship = true
        filt = 'child = true'
      }
      else {
        this.isRelationship = false
      }
      this.getRelationshipStatus(filt)
    },
    editMember(item, filter) {
      this.ownMaritalStatus = item.marital_status
      this.visible = true
      this.form = item

      if (this.form.relationship_to_principal !== null && this.form.relationship_to_principal) {
        this.form.relationship_to_principal = parseFloat(this.form.relationship_to_principal)
      }
      if (this.form.country_id !== null && this.form.country_id) {
        this.form.country_id = parseFloat(this.form.country_id)
      }

      this.checkEdit = true

      this.checkMessage(item)

      let filt = ''
      if (filter === 'brothers') {
        this.isRelationship = true
        filt = 'bothers_and_sisters = true'
      }
      else if (filter === 'parent') {
        this.isRelationship = true
        filt = '((id=8)OR(id=9))'
      }
      else if (filter === 'children') {
        this.isRelationship = true
        filt = 'child = true'
      }
      else {
        this.isRelationship = false
      }
      this.getRelationshipStatus(filt)
    },
    submitForm(check) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true

          if (this.form.relationship_name == 'Principal') {
            if (this.form.marital_status == 'Married-physically present' || this.form.marital_status == 'Married' || this.form.marital_status == 'Common-law') {
              this.checkSpouse(this.form)
            }
            else {
              this.deleteSpouse()
            }
          }

          this.$Progress.start()
          var action = 'save'
          var actionMessage = 'Successfull save'

          this.form.applicant_id = this.applicantID
          if (this.form.u_id === null || this.form.u_id === undefined) {
            this.form.u_id = guid()
          }
          if (this.checkEdit) {
            action = 'edit'
            actionMessage = 'Successfull edit'
          }

          this.form.sec_org_id = this.form.sec_org_id != null && this.form.sec_org_id != undefined ? this.form.sec_org_id : this.$orgId
          this.$store.dispatch('generic/' + action, this.form).then(response => {
            this.$store.dispatch('toaster/add', { text: actionMessage, type: 'success' })

            if (check != undefined) {
              this.getMembers()
              this.visible = false
            }
            this.loading = false
            this.$Progress.finish()
          }).catch(err => {
            this.visible = false
            this.loading = false
            this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
            this.$Progress.fail()
          })
        }
        else {
          return false
        }
      })
    }
  }
}
</script>

<style>
  .addinional-family-member .el-collapse-item__header{
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .addinional-family-member .el-collapse-item__arrow{
    line-height: 24px;
  }
</style>
