<template>
  <div>
    <v-card v-if="hideSection" class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="yukon-checkbox">
          <v-row dense>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'owner_last_name')">
              <el-form-item prop="owner_last_name" label="Last Name">
                <el-input @change="handleAutoSave(form)" v-model="form.owner_last_name" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'owner_first_name')">
              <el-form-item prop="owner_first_name" label="First Name">
                <el-input @change="handleAutoSave(form)" v-model="form.owner_first_name" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'owner_title')">
              <el-form-item prop="owner_title" label="Title">
                <el-input @change="handleAutoSave(form)" v-model="form.owner_title" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'owner_phone')">
              <el-form-item prop="owner_phone" label="Phone">
                <el-input @change="handleAutoSave(form)" v-model="form.owner_phone" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'owner_fax')">
              <el-form-item prop="owner_fax" label="Fax">
                <el-input @change="handleAutoSave(form)" v-model="form.owner_fax" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'owner_email')">
              <el-form-item prop="owner_email" label="Confidential email">
                <el-input @change="handleAutoSave(form)" v-model="form.owner_email" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'owner_immigration_status')">
              <el-form-item prop="owner_immigration_status" label="Immigration status of employer">
                <el-input @change="handleAutoSave(form)" v-model="form.owner_immigration_status" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'owner_other')">
              <el-form-item prop="owner_other" label="Other">
                <el-input @change="handleAutoSave(form)" v-model="form.owner_other" />
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  mixins: [autosave],
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        owner_first_name: null,
        owner_last_name: null,
        owner_title: null,
        owner_phone: null,
        owner_fax: null,
        owner_email: null,
        owner_immigration_status: null,
        owner_other: null
      },
      url: 'imms_table/lmia_yukon_app',
      getUrl: 'imms_table/lmia_yukon_app?application_imms_uuid=' + this.$route.params.id
    }
  },
  mounted() {
    this.fetch().then(data => {
      if (data.items != undefined && data.items.length > 0) {
        if (this.isDataValidation) {
          this.checkRow(data.items[0])
        }
        this.form = data.items[0]
      }
      else {
        if (this.isDataValidation) {
          this.checkRow(this.form)
        }
      }
    })
  }
}
</script>
