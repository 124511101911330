import api from "./../plugins/axios";

let urlSysUser = "table/sys_users";

export const state = {
  sysuser: {},
  sysusers: [],
  roles: [],
  usersByRoles: [],
  calendars: [],
  consultants: [],
  consultant_app_types: []
};

export const getters = {
  all(state) {
    return state.sysusers;
  },
  getUser(state) {
    return state.sysuser;
  },
  roles(state) {
    return state.roles;
  },
  usersByRoles(state) {
    return state.usersByRoles;
  },
  calendars(state) {
    return state.calendars;
  },
  consultants(state) {
    return state.consultants;
  },
  consAppTypes(state) {
    return state.consultant_app_types;
  }
};

export const actions = {
  getCalendar({ commit }, qp) {
    return api.get("table/scheduling/calendars" + qp).then(
      (response) => {
        commit("GET_CALENDARS", response.data.resource);
      },
      (err) => {
        commit("FAILURE_CALENDARS", err);
      }
    );
  },
  getConsAppType({ commit }, qp) {
    return api.get("table/consultant_application_types" + qp).then(
      (response) => {
        commit("GET_CONS_APP_TYPES", response.data.resource);
      },
      (err) => {
        commit("FAILURE_CONS_APP_TYPES", err);
      }
    );
  },
  saveConsAppType({ commit }, data) {
    var requestObject = { resource: data };
    return api.post("table/consultant_application_types", requestObject).then(
      (response) => {
        data.id = response.data.resource[0].id;
        commit("ADD_CONS_APP_TYPES", data);
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  editConsAppType({ commit }, data) {
    return api.put("table/consultant_application_types/" + data.id, data).then(
      (response) => {
        commit("PUT_CONS_APP_TYPES", data);
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  deleteConsAppType({ commit }, data) {
    return api.delete("table/consultant_application_types/" + data.id).then(
      (response) => {
        commit("DELETE_CONS_APP_TYPES", data);
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  getUsers({ commit }) {
    return api.get("table/users").then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  getAll({ commit }, params) {
    return api.get("table/users", params).then(
      (response) => {
        api.get("table/sys_user_group?order=group_name ASC").then(
          (responseByRole) => {
            commit("GET_DATAS_BY_ROLE", { roles: responseByRole.data.resource, users: response.data.resource });
            commit("GET_DATAS", response.data.resource);
            commit("GET_ROLES", responseByRole.data.resource);
          },
          (err) => {
            commit("FAILURE_ROLES", err);
            return Promise.reject(err);
          }
        );
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  getAllFilter({ commit }, filter) {
    return api.get("table/users" + filter).then(
      (response) => {
        commit("GET_DATAS", response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  getUser({ commit }, id) {
    return api.get(urlSysUser + "?filter=id=" + id).then((response) => {
      commit("GET_USER", response.data.resource[0]);
      return Promise.resolve(response.data.resource[0]);
    });
  },
  save({ commit }, data) {
    var requestObject = { resource: [data] };
    return api.post(urlSysUser, requestObject).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  edit({ commit }, data) {
    return api.put(urlSysUser + "/" + data.id, data).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  create({ commit }, data) {
    var requestObject = { resource: [data] };
    return api.post("sysusers/create", requestObject).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
};

export const mutations = {
  GET_USER(state, data) {
    state.sysuser = data;
  },
  GET_DATAS(state, datas) {
    state.sysusers = datas;
  },
  GET_DATAS_BY_ROLE(state, params) {
    var consUsers = [];
    params.roles.map(function(role, key) {
      role.users = [];
      params.users.map(function(user, uKey) {
        if (role.id == user.group_id) {
          if (role.id == 2) {
            user.consultant_conf = true;
            consUsers.push(user);
          }
          else if (role.id == 3) {
            user.create_application = true;
          }
          else if (role.id == 87) {
            user.create_lmia_application = true;
          }
          else if (role.id == 79) {
            user.business_partner = true;
          }
          user.is_active = user.status === "active" ? true : false;
          role.users.push(user);
        }
      });
    });
    state.usersByRoles = params.roles;
    state.consultants = consUsers;
  },
  FAILURE_DATAS(state) {
    state.sysusers = [];
  },
  GET_ROLES(state, datas) {
    state.roles = datas;
  },
  FAILURE_ROLES(state) {
    state.roles = [];
  },
  GET_CALENDARS(state, datas) {
    state.calendars = datas;
  },
  FAILURE_CALENDARS(state) {
    state.calendars = [];
  },
  GET_CONS_APP_TYPES(state, datas) {
    state.consultant_app_types = datas;
  },
  ADD_CONS_APP_TYPES(state, data) {
    state.consultant_app_types.push(data);
  },
  PUT_CONS_APP_TYPES(state, data) {
    let idx = state.consultant_app_types.map((p) => p.id).indexOf(data.id);
    state.consultant_app_types.splice(idx, 1, data);
  },
  DELETE_CONS_APP_TYPES(state, data) {
    let idx = state.consultant_app_types.map((p) => p.id).indexOf(data.id);
    state.consultant_app_types.splice(idx, 1);
  },
  FAILURE_CONS_APP_TYPES(state) {
    state.consultant_app_types = [];
  }
};
