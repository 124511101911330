<template>
  <model-select :options="filteredOptions" :filterPredicate="startsWithPredicate" :placeholder="placeholder" :disabled="disabled" v-model="currentValue" :class="[{ 'is-disabled': disabled }]" v-on:click.native="MouseOver($event)"> </model-select>
</template>

<script>
import { ModelSelect } from "vue-search-select";

export default {
  props: {
    placeholder: {
      type: String,
      default: "Select"
    },
    items: {
      type: Array
    },
    selectText: String,
    selectValue: String,
    value: [String, Number, Object],
    disabled: Boolean
  },
  watch: {
    currentValue(val, oldValue) {
      this.$emit("change", val);
    }
  },
  data() {
    return {
      currentValue: this.value === null || this.value === undefined ? "" : this.value
    };
  },
  computed: {
    filteredOptions: function() {
      var datas = [];
      var text = this.selectText;
      var value = this.selectValue;
      this.items.forEach(function(item) {
        var obj = {};
        obj.text = item[text];
        obj.value = item[value];
        datas.push(obj);
      });
      return datas;
    }
  },
  components: {
    ModelSelect
  },
  methods: {
    MouseOver(event) {
      if (event.target) event.target.disabled = this.disabled;
    },
    startsWithPredicate(text, inputText) {
      return text.toLowerCase().startsWith(inputText.toLowerCase());
    },
    setCurrentValue(val) {
      if (val === this.currentValue) return;
      this.$nextTick((_) => {
        this.resizeTextarea();
      });
      this.currentValue = val;
      if (this.validateEvent) {
        this.dispatch("ElFormItem", "el.form.change", [val]);
      }
    }
  }
};
</script>

<style>
.el-form-item__content .ui.fluid.dropdown[data-v-3a0c7bea] {
  line-height: 40px;
  padding: 0 30px 0 15px;
  border: 1px solid #d8dce5;
}
.el-form-item__content .ui.search.selection.dropdown > input.search[data-v-3a0c7bea] {
  line-height: 24px;
}
.el-form-item__content .ui.search.dropdown > .text[data-v-3a0c7bea] {
  color: #555;
}
.el-form-item__content .ui.dropdown > .dropdown.icon[data-v-3a0c7bea] {
  font-family: element-icons !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: baseline;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 25px;
  text-align: center;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
  font-size: 14px;
  color: #777;
  line-height: 40px;
  margin: 0;
  padding: 0;
  top: 0;
  right: 3px;
}
.el-form-item__content .ui.dropdown > .dropdown.icon[data-v-3a0c7bea]:before {
  content: "\E605";
}
.el-form-item__content .ui.search.dropdown > .text[data-v-3a0c7bea] {
  left: 0;
}
.el-form-item__content .ui.selection.active.dropdown .menu[data-v-3a0c7bea] {
  margin: 12px 0;
  border: 1px solid #dfe4ed;
  border-radius: 4px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 5px 0;
}
.el-form-item__content .ui.dropdown .menu > .item,
.ui.selection.dropdown .menu > .item[data-v-3a0c7bea] {
  white-space: nowrap;
  color: #5a5e66;
  height: 34px;
  line-height: 34px;
  padding: 0 20px !important;
  font-size: 12px;
  border-top: none;
}
.el-form-item__content .ui.dropdown .menu > .item.current {
  color: #409eff;
  font-weight: 700;
}
.el-form-item__content .ui.dropdown.active.selection[data-v-3a0c7bea] {
  border-radius: 4px !important;
  border-color: #409eff;
}
.el-form-item__content .ui.search.selection.dropdown.is-disabled {
  background-color: #f5f7fa;
  border-color: #dfe4ed;
  color: #b4bccc;
  cursor: not-allowed;
  z-index: 99;
}
.el-form-item__content .ui.search.selection.dropdown.is-disabled input.search[data-v-3a0c7bea] {
  cursor: not-allowed;
}
.el-form-item__content .ui.search.selection.dropdown.is-disabled.active .menu {
  display: none !important;
}
.el-form-item__content .ui.search.selection.dropdown.is-disabled.active {
  box-shadow: none !important;
}
</style>
