import api from "./../plugins/axios";

export const state = {
  notApplicable: {
    id: undefined,
    imms_uuid: undefined,
    member_id: undefined,
    member_uid: undefined,
    travel_history: false,
    membership_of_organization: false,
    government_positions: false,
    paramilitary_service: false,
    previous_marital_status: false,
    business_ownership: false,
    business_exploration_trip: false,
    prevresidence: false,
    family_members_canada: false,
    witness_participated: "",
    language_test_details: ""
  }
};
export const getters = {
  id(state) {
    return state.notApplicable.id;
  },
  imms_uuid(state) {
    return state.notApplicable.imms_uuid;
  },
  travel_history(state) {
    return state.notApplicable.travel_history;
  },
  membership_of_organization(state) {
    return state.notApplicable.membership_of_organization;
  },
  government_positions(state) {
    return state.notApplicable.government_positions;
  },
  paramilitary_service(state) {
    return state.notApplicable.paramilitary_service;
  },
  previous_marital_status(state) {
    return state.notApplicable.previous_marital_status;
  },
  business_ownership(state) {
    return state.notApplicable.business_ownership;
  },
  business_exploration_trip(state) {
    return state.notApplicable.business_exploration_trip;
  },
  prevresidence(state) {
    return state.notApplicable.prevresidence;
  },
  family_members_canada(state) {
    return state.notApplicable.family_members_canada;
  },
  witness_participated(state) {
    return state.notApplicable.witness_participated;
  },
  language_test_details(state) {
    return state.notApplicable.language_test_details;
  }
};
export const actions = {
  getOne({ commit }, id) {
    return api.get("imms_table/applicable_checkboxes?application_imms_uuid=" + id).then(
      (response) => {
        if (response.data.resource.length > 0) {
          commit("GET_DATAS", response.data.resource[0]);
        }
        return Promise.resolve(response.data.resource);
      },
      (err) => {
        commit("FAILURE_DATA", err);
        return Promise.reject(err);
      }
    );
  },
  save({ commit }, data) {
    return api.post('imms_table/applicable_checkboxes', { resource: data }).then(
      (response) => {
        if (response.data.resource.length > 0) {
          commit("SET_ID", response.data.resource[0]);
        }
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  edit({ commit }, data) {
    api.get("imms_table/applicable_checkboxes?application_imms_uuid=" + data.application_id).then(res => {
      if (res.data.resource.length > 0) {
        return api.put("imms_table/applicable_checkboxes/" + res.data.resource[0].imms_uuid, data).then(
          (response) => {
            return Promise.resolve(response);
          },
          (err) => {
            return Promise.reject(err);
          }
        );
      }
      else {
        delete data.id
        delete data.imms_uuid
        return api.post('imms_table/applicable_checkboxes', { resource: data }).then(
          (response) => {
            if (response.data.resource.length > 0) {
              commit("SET_ID", response.data.resource[0]);
            }
            return Promise.resolve(response);
          },
          (err) => {
            return Promise.reject(err);
          }
        );
      }
    })
  }
};
export const mutations = {
  set_travel_history(state, payload) {
    state.notApplicable.travel_history = payload;
  },
  set_membership_of_organization(state, payload) {
    state.notApplicable.membership_of_organization = payload;
  },
  set_paramilitary_service(state, payload) {
    state.notApplicable.paramilitary_service = payload;
  },
  set_government_positions(state, payload) {
    state.notApplicable.government_positions = payload;
  },
  set_previous_marital_status(state, payload) {
    state.notApplicable.previous_marital_status = payload;
  },
  set_business_ownership(state, payload) {
    state.notApplicable.business_ownership = payload;
  },
  set_business_exploration_trip(state, payload) {
    state.notApplicable.business_exploration_trip = payload;
  },
  set_prevresidence(state, payload) {
    state.notApplicable.prevresidence = payload;
  },
  set_family_members_canada(state, payload) {
    state.notApplicable.family_members_canada = payload;
  },
  set_witness_participated(state, payload) {
    state.notApplicable.witness_participated = payload;
  },
  set_language_test_details(state, payload) {
    state.notApplicable.language_test_details = payload;
  },
  SET_ID(state, data) {
    state.notApplicable.id = data.id;
    state.notApplicable.imms_uuid = data.imms_uuid;
  },
  GET_DATAS(state, item) {
    state.notApplicable.id = item.id;
    state.notApplicable.imms_uuid = item.imms_uuid;
    if (item.travel_history) {
      state.notApplicable.travel_history = item.travel_history;
    }
    if (item.membership_of_organization) {
      state.notApplicable.membership_of_organization = item.membership_of_organization;
    }
    if (item.government_positions) {
      state.notApplicable.government_positions = item.government_positions;
    }
    if (item.paramilitary_service) {
      state.notApplicable.paramilitary_service = item.paramilitary_service;
    }
    if (item.previous_marital_status) {
      state.notApplicable.previous_marital_status = item.previous_marital_status;
    }
    if (item.business_ownership) {
      state.notApplicable.business_ownership = item.business_ownership;
    }
    if (item.business_exploration_trip) {
      state.notApplicable.business_exploration_trip = item.business_exploration_trip;
    }
    if (item.prevresidence) {
      state.notApplicable.prevresidence = item.prevresidence;
    }
    if (item.family_members_canada) {
      state.notApplicable.family_members_canada = item.family_members_canada;
    }
    if (item.witness_participated) {
      state.notApplicable.witness_participated = item.witness_participated;
    }
    if (item.language_test_details) {
      state.notApplicable.language_test_details = item.language_test_details;
    }
  }
};
