<template>
  <v-card v-if="hideSection" class="mt-3">
    <v-card-title>
      <span>{{sectionLabel}}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text class="p-n">
      <v-row dense v-if="!isDataValidation">
        <v-col cols="12" class="mb-3">
          <div class="text13 pull-left">Have you ever had immigration or citizenship status in any country other than Canada, including your country of birth?</div>
          <el-radio-group class="ml-3 mt-1 pull-left" @change="checkCitizenship()" v-model="form.citizenship_status">
            <el-radio label="No">No</el-radio>
            <el-radio label="Yes">Yes</el-radio>
          </el-radio-group>
        </v-col>
      </v-row>
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <table class="table" style="width:100%" v-if="form.citizenship_status === 'Yes'">
        <thead>
          <tr>
            <th width="150">Country</th>
            <th>Status</th>
            <th width="100">Date Obtained/Effective date (YYYY/MM)</th>
            <th width="100">If your status is no longer valid indicate <br> the end data. If your status is still<br>  valid indicate 'current'</th>
            <th rowspan="2" width="30">
              <v-btn color="green" small fab @click.native="saveForm()">
                <v-icon class="white--text">add</v-icon>
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(form,i) in items" :key="i">
            <td class="pa-1">
              <div v-if="dataValidationItems(form, 'country')" class="el-select native-select mt21">
                <div class="el-input">
                  <select v-model="form.country" placeholder="Select" class="el-input__inner" @change="handleAutoSave(i, form)">
                    <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                  </select>
                </div>
              </div>
            </td>
            <td class="pa-1">
              <el-input v-if="dataValidationItems(form, 'status')" v-model="form.status" class="mt21" @change="handleAutoSave(i, form)" />
            </td>
            <td class="pa-1">
              <el-date-picker v-if="dataValidationItems(form, 'date')" type="month" value-format="yyyy-MM-dd" value="yyyy-MM" v-model="form.date" class="mt21" @change="handleAutoSave(i, form)"></el-date-picker>
            </td>
            <td class="pa-1">
              <el-checkbox v-model="form.current" @change="handleAutoSave(i, form); changeCurrent(form)" class="current-checkbox">Current</el-checkbox>
              <el-date-picker v-if="dataValidationItems(form, 'end_date')" :disabled="form.current" type="month" value-format="yyyy-MM-dd" value="yyyy-MM" :picker-options="datePickerOptions" @change="handleAutoSave(i, form)" v-model="form.end_date"></el-date-picker>
            </td>
            <td class="pa-1 text-center">
              <v-btn color="pink" small fab @click.native="deleteOpen(form, i)">
                <v-icon class="white--text">clear</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  name: 'CitizenshipStatus',
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        country: null,
        status: null,
        end_date: null,
        date: null,
        current: false,
        sec_org_id: this.$orgId
      },
      url: 'imms_table/application_citizenship_status',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      form: 'citizenship/get'
    })
  },
  created() {
    this.fetchData()
  },
  methods: {
    changeCurrent(item) {
      if (item.current === true) item.end_date = null
    },
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `table/application_citizenship_status?filter=member_uid=${this.memberUID}`)
        .then(response => {
          let vm = this
          if (vm.isDataValidation) {
            response.resource.forEach(function(item) {
              var form = {
                country: null,
                status: null,
                end_date: null,
                date: null
              }

              if (item.current) {
                delete form.end_date
              }

              var obj = vm.checkFormItems(item, form)

              if (obj != 'empty') {
                vm.items.push(item)
              }
            })

            if (response.resource.length == 0) {
              vm.hideSection = false
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
          else {
            vm.items = response.resource
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    checkCitizenship() {
      this.$store.dispatch('citizenship/edit', this.form)
        .then(response => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Disabled', type: 'success' })
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        country: null,
        status: null,
        end_date: null,
        date: null,
        current: false,
        sec_org_id: this.$orgId
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>

<style>
.mt21{
  margin-top: 21px !important;
}
.current-checkbox{
  display: block;
  width: 100%;
}
</style>
