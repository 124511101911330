<template>
  <div>
    <el-form :model="form" ref="form" label-position="top" class="form">
      <v-card class="mt-3">
        <v-card-title>
          <span>{{ sectionLabel }}</span>
          <v-spacer />
        </v-card-title>
        <v-card-text class="p-n">
          <v-row dense>
            <v-col cols="12" sm="6">
              <el-form-item prop="education_level" label="The highest level of education for SINP">
                <el-select v-model="form.education_level" @change="handleAutoSave(0, form)" style="width: 100%">
                  <el-option v-for="(item, index) in educationLevel" :key="index" :value="item" :label="item"></el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6">
              <el-form-item prop="professional_status" label="Do you have proof of Professional Status or Licensure, if required for your occupation?">
                <el-radio-group v-model="form.professional_status" @change="handleAutoSave(0, form)">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6">
              <el-form-item prop="degree_diploma" label="Do you have a degree, diploma or certificate from a post-secondary institution in Canada in your intended occupation?">
                <el-radio-group v-model="form.degree_diploma" @change="handleAutoSave(0, form)">
                  <el-radio label="Yes">Yes</el-radio>
                  <el-radio label="No">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6">
              <el-form-item prop="work_experience_recent" label="Work experience during the most recent five years prior to application:">
                <el-select v-model="form.work_experience_recent" @change="handleAutoSave(0, form)" style="width: 100%">
                  <el-option v-for="(item, index) in workExperienceMost" :key="index" :value="item" :label="item"></el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6">
              <el-form-item prop="work_experience_six_ten" label="Work Experience during the six to ten year period prior to application:">
                <el-select v-model="form.work_experience_six_ten" @change="handleAutoSave(0, form)" style="width: 100%">
                  <el-option v-for="(item, index) in workExperienceNext" :key="index" :value="item" :label="item"></el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6">
              <el-form-item prop="first_language" label="First Language CLB:">
                <el-select v-model="form.first_language" @change="handleAutoSave(0, form)" style="width: 100%">
                  <el-option v-for="(item, index) in firstLanguage" :key="index" :value="item" :label="item"></el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6">
              <el-form-item prop="age_time_application" label="Age at time of application submission:">
                <el-select v-model="form.age_time_application" @change="handleAutoSave(0, form)" style="width: 100%">
                  <el-option v-for="(item, index) in ageTime" :key="index" :value="item" :label="item"></el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6">
              <el-form-item prop="checkbox_work_experience" label="Checkboxes:" class="mb-0">
                <el-checkbox v-model="form.checkbox_work_experience" @change="handleAutoSave(0, form)">Previous work experience in Saskatchewan</el-checkbox>
              </el-form-item>
              <el-form-item prop="checkbox_student_experience" class="mb-0">
                <el-checkbox v-model="form.checkbox_student_experience" @change="handleAutoSave(0, form)">Previous student experience in Saskatchewan</el-checkbox>
              </el-form-item>
              <el-form-item prop="checkbox_close_relative" class="mb-0">
                <el-checkbox v-model="form.checkbox_close_relative" @change="handleAutoSave(0, form)">Close relative in Saskatchewan</el-checkbox>
              </el-form-item>
              <el-form-item prop="checkbox_na" class="mb-0">
                <el-checkbox v-model="form.checkbox_na" @change="handleAutoSave(0, form)">N/A</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-3">
          <v-spacer />
          <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="handleAutoSave(0, form)">
            {{ $t('global.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </el-form>
  </div>
</template>

<script>
import autosave from '@/mixins/autosave.js'

export default {
  mixins: [autosave],
  props: {
    sectionId: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      orgID: parseInt(localStorage.getItem('org_id')),
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: this.memberID,
        education_level: null,
        professional_status: null,
        degree_diploma: null,
        work_experience_recent: null,
        work_experience_six_ten: null,
        first_language: null,
        age_time_application: null,
        checkbox_work_experience: false,
        checkbox_student_experience: false,
        checkbox_close_relative: false,
        checkbox_na: false,
        sec_org_id: parseInt(localStorage.getItem('org_id'))
      },
      fields: ['checkbox_work_experience', 'checkbox_student_experience', 'checkbox_close_relative', 'checkbox_na', 'education_level', 'professional_status', 'degree_diploma', 'work_experience_recent', 'work_experience_six_ten', 'first_language', 'age_time_application'],
      loading: false,
      url: '/imms_table/application_sinp_eoi',
      educationLevel: [
        "Master's or Doctorate degree, Canadian equivalency",
        "Bachelor's degree OR a three or more year degree program at a university or college",
        "Trade certification equivalent to journey person status in Saskatchewan",
        "Canadian equivalency Diploma that requires two but less than three years at a university, college, trade or technical school, or other formal post-secondary institution",
        "Canadian equivalency Certificate or at least two semesters but less than a two year program at a university, college, trade or technical school, or other formal post-secondary institution",
        "No post-secondary education or training"
      ],
      workExperienceMost: [
        "5 years",
        "4 years",
        "3 years",
        "2 years",
        "1 year",
        "0 years"
      ],
      workExperienceNext: [
        "5 years",
        "4 years",
        "3 years",
        "2 years",
        "1 year or less"
      ],
      ageTime: [
        ">50",
        "<18",
        "18-21",
        "22-34",
        "35-45",
        "46-50"
      ],
      firstLanguage: [
        "CLB 8 and higher",
        "CLB 7",
        "CLB 6",
        "CLB 5",
        "CLB 4",
        "English or French native speaker without language test results"
      ]
    }
  },
  mounted() {
    if (this.datas !== undefined) {
      this.checkFields(this.fields, this.datas, this.sectionId)
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$store.dispatch('store/get', `table/application_sinp_eoi?filter=member_uid=${this.memberUID}`)
        .then(response => {
          if (response.resource.length > 0) {
            this.form = response.resource[0]
          }
        })
    }
  }
}
</script>
