<template>
    <v-card class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
        <v-btn class="mb-3" color="primary" dark small @click.native="saveForm()">Add</v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-2" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <v-row dense class="customCollapseHeader">
          <v-col cols="2">From Date</v-col>
          <v-col cols="2">To Date</v-col>
          <v-col cols="3">Job title</v-col>
          <v-col cols="3">Name of Company/Organization</v-col>
          <v-col cols="4">Country</v-col>
        </v-row>
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i" :id="'row' + form.id">
            <template slot="title">
              <div v-on:click="getProvince(form)" class="customHead">
                <v-row dense>
                  <v-col cols="2">
                    <el-tag type="primary" v-if="form.from_date !== null && form.from_date != undefined">{{ form.from_date | day }}</el-tag>
                  </v-col>
                  <v-col cols="2">
                    <el-tag type="success" v-if="form.current">Current</el-tag>
                    <el-tag type="success" v-else-if="form.to_date !== null && form.to_date != undefined">{{ form.to_date | day }}</el-tag>
                  </v-col>
                  <v-col cols="3">{{ form.job_title }}</v-col>
                  <v-col cols="3">{{ form.name_of_employer }}</v-col>
                  <v-col cols="4">{{ form.country_name }}</v-col>
                </v-row>
              </div>
            </template>

            <el-form :model="form" ref="form" label-position="top">
              <v-row dense>
                <v-col cols="12">
                  <el-form-item label="Job title" prop="job_title">
                    <el-input :id="'job_title' + form.id" v-model="form.job_title" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6">
                  <el-form-item label="NOC (4-digits)" prop="noc_code">
                    <el-input :id="'noc_code' + form.id" :maxlength="4" v-model="form.noc_code" @change="handleAutoSave(i, form)" style="width: 100%;" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="3">
                  <el-form-item label="From" prop="from_date">
                    <el-date-picker :id="'from_date' + form.id" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.from_date" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="3">
                  <el-form-item label="To" prop="to_date">
                    <el-date-picker :id="'to_date' + form.id" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.to_date" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
                <v-col cols="12">
                  <el-form-item label="Name of Company/Organization" prop="name_of_employer">
                    <el-input :id="'name_of_employer' + form.id" v-model="form.name_of_employer" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="3">
                  <el-form-item label="Country" prop="country_id">
                    <div class="el-select native-select">
                      <div class="el-input">
                        <select :id="'country_id' + form.id" v-model="form.country_id" placeholder="Select" class="el-input__inner" @change="handleAutoSave(i, form); getProvince(form)">
                          <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                        </select>
                      </div>
                    </div>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="3">
                  <el-form-item label="Province/State" prop="province">
                    <div class="el-select native-select">
                      <div class="el-input" :class="[{'is-disabled': !selectProvine}]">
                        <select :id="'country_id' + form.id" :disabled="!selectProvine" v-model="form.country_id" placeholder="Select" class="el-input__inner" @change="handleAutoSave(i, form);getProvince(form)">
                          <option v-for="(item, index) in province" v-bind:key="index" :value="item.id" :label="item.province_name">{{ item.province_name }}</option>
                        </select>
                      </div>
                    </div>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6">
                  <el-form-item label="City/Town" prop="city">
                    <el-input :id="'city_town' + form.id" v-model="form.city" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="7">
                  <el-form-item label="Address Line (Number and Street)" prop="address">
                    <el-input :id="'address' + form.id" v-model="form.address" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="5">
                  <el-form-item label="Postal/Zip code" prop="postal">
                    <el-input :id="'postal' + form.id" v-model="form.postal" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="7">
                  <el-form-item label="Company Website" prop="website">
                    <el-input :id="'website' + form.id" v-model="form.website" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="5">
                  <el-form-item label="Company Phone Number" prop="company_phone">
                    <div v-if="selectProvine" class="el-input">
                      <masked-input :id="'company_phone' + form.id" v-model="form.company_phone" :mask="phoneMask" @change="handleAutoSave(i, form)" class="el-input__inner" />
                    </div>
                    <el-input v-else :id="'company_phone' + form.id" type="number" v-model="form.company_phone" @change="handleAutoSave(i, form)" style="width: 100%;" />
                  </el-form-item>
                </v-col>
                <v-col cols="12">
                  <el-form-item label="Your Responsibilities" prop="responsibility">
                    <el-input type="textarea" :id="'responsibility' + form.id" :autosize="{ minRows: 5, maxRows: 8}" v-model="form.responsibility" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm(i,form)">
                    {{ $t('global.save') }}
                  </v-btn>
                  <v-btn color="error" small @click.native="deleteItem(form,i)">Delete</v-btn>
                </v-col>
              </v-row>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import MaskedInput from 'vue-text-mask'
import autosave from '@/mixins/autosave.js'

export default {
  name: 'WorkExperience',
  mixins: [autosave],
  props: {
    sectionId: {
      type: String
    },
    layout: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        job_title: null,
        noc_code: null,
        from_date: null,
        to_date: null,
        name_of_employer: null,
        address: null,
        city: null,
        province: null,
        country_id: null,
        website: null,
        company_phone: null,
        responsibility: null
      },
      activeNames: -1,
      selectProvine: false,
      phoneMask: ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      url: 'imms_table/imm_1294_employment_history',
      deleteUrl: 'imms/imm_1294_employment_history',
      state: 'workExperience',
      loading: false,
      items: []
    }
  },
  components: {
    MaskedInput
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      province: 'other/province'
    })
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    getProvince(form) {
      if (form.country_id) {
        form.country_id = parseFloat(form.country_id)
      }
      if (form.province) {
        form.province = parseFloat(form.province)
      }
      if (42 === form.country_id || 239 === form.country_id) {
        this.selectProvine = true
        this.$store.dispatch('other/getProvince', form.country_id)
      }
      else {
        form.province = undefined
        this.selectProvine = false
      }
    },
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `/table/imm_1294_employment_view?filter=member_uid=${this.memberUID}&order=from_date DESC, to_date DESC`)
        .then(response => {
          this.items = response.resource

          if (this.datas !== undefined) this.checkItemsFields(response.resource, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        job_title: null,
        noc_code: null,
        from_date: null,
        to_date: null,
        name_of_employer: null,
        address: null,
        city: null,
        province: null,
        country_id: null,
        website: null,
        company_phone: null,
        responsibility: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true

          if (formObj.id != undefined) this.edit = true
          var action = 'store/save'
          if (this.edit) {
            action = 'store/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    }
  }
}
</script>
