import Cookie from 'js-cookie';
import moment from 'moment';
import axios from 'axios';
import api from './../plugins/axios';
import { auth } from '@/service/firebase';
import constants from './../constants';

export const getCurrentUserToken = async () => {
  try {
    return await auth.currentUser.getIdToken(false);
  } catch (err) {}
};

export const setToken = (token) => {
  window.localStorage.setItem('token', token);
  Cookie.set('jwt', token);
  window.localStorage.setItem(
    'login_date',
    moment().format('YYYY-MM-DD HH:mm:ss'),
  );
  window.localStorage.setItem('visit_time', Date.now());
};

export const unsetToken = () => {
  window.localStorage.removeItem('token');
  Cookie.remove('jwt');
  window.localStorage.setItem('logout', Date.now());
};

export const setUser = (data) => {
  window.localStorage.setItem('id', data.user_id);
  window.localStorage.setItem('imms_uuid', data.imms_uuid);
  window.localStorage.setItem('first_name', data.first_name);
  window.localStorage.setItem('last_name', data.last_name);
  window.localStorage.setItem('email', data.user_name);
  window.localStorage.setItem('role', data.role);
  window.localStorage.setItem('role_id', data.role_id);
  window.localStorage.setItem('time_zone', data.time_zone);
  window.localStorage.setItem('business_partner_id', data.business_partner_id);
  window.localStorage.setItem('is_read_tos', data.is_read_tos);
  window.localStorage.setItem('v_marketplace_user', data.v_marketplace_user);
  window.localStorage.setItem('allow_referrals', data.allow_referrals);
  if (data.high_level_admin != undefined)
    window.localStorage.setItem('high_level_admin', data.high_level_admin);
};

export const unsetUserLogin = () => {
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('id');
  window.localStorage.removeItem('imms_uuid');
  window.localStorage.removeItem('first_name');
  window.localStorage.removeItem('last_name');
  window.localStorage.removeItem('email');
  window.localStorage.removeItem('role');
  window.localStorage.removeItem('role_id');
  window.localStorage.removeItem('high_level_admin');
  window.localStorage.removeItem('login_date');
  window.localStorage.removeItem('visit_time');
  window.localStorage.removeItem('business_partner_id');
  window.localStorage.removeItem('menus_ircc');
  window.localStorage.removeItem('applicant');
  window.localStorage.removeItem('is_read_tos');
  window.localStorage.removeItem('v_marketplace_user');
};

export const unsetUser = () => {
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('id');
  window.localStorage.removeItem('imms_uuid');
  window.localStorage.removeItem('first_name');
  window.localStorage.removeItem('last_name');
  window.localStorage.removeItem('email');
  window.localStorage.removeItem('role');
  window.localStorage.removeItem('role_id');
  window.localStorage.removeItem('high_level_admin');
  window.localStorage.removeItem('login_date');
  window.localStorage.removeItem('visit_time');
  window.localStorage.removeItem('menus_ircc');
  window.localStorage.removeItem('org_name');
  window.localStorage.removeItem('logo_path');
  window.localStorage.removeItem('org_id');
  window.localStorage.removeItem('stripe_public_key');
  window.localStorage.removeItem('time_zone');
  window.localStorage.removeItem('applicant');
  window.localStorage.removeItem('is_read_tos');
  window.localStorage.removeItem('v_marketplace_user');
};

export const getUserFromCookie = (req) => {
  try {
    if (!req.headers.cookie) return;
    const jwtCookie = req.headers.cookie
      .split(';')
      .find((c) => c.trim().startsWith('jwt='));
    if (!jwtCookie) return;
    const jwt = jwtCookie.split('=')[1];
    return jwt;
  } catch (err) {}
};

export const getUserFromLocalStorage = () => {
  const json = window.localStorage.user;
  return json ? JSON.parse(json) : undefined;
};

export const checkAuth = (router) => {
  const loggedUser = window.localStorage.getItem('token');
  if (loggedUser === undefined || loggedUser === null) {
    window.location.href = '/login';
  }
};

export const checkSession = (next) => {
  let visitTime = window.localStorage.getItem('visit_time');
  if (visitTime !== undefined) {
    api.get('/users/session').then(
      (response) => {
        next();
      },
      (err) => {
        if (err.status === 401 || err.status === 403 || err.status == 500) {
          window.location.href = '/login';
        } else {
          next();
        }
      },
    );
  } else {
    window.location.href = '/login';
  }
};

export const checkDeployVersion = async (hasNewUpdates, deployVersion) => {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_API_ROOT}/check_version`,
    );
    let data = response.data || null;
    if (data != undefined && data != null) {
      let refreshCount = window.localStorage.getItem('refresh_count');
      const dv = window.localStorage.getItem('deploy_version');
      if (
        dv != undefined &&
        dv != 'undefined' &&
        dv != null &&
        dv != 'null' &&
        data.deploy_version != undefined &&
        data.deploy_version != null &&
        data.deploy_version != ''
      ) {
        if (data.deploy_version !== dv) {
          hasNewUpdates = true;
        }
        deployVersion = dv;
      } else if (data.deploy_version) {
        window.localStorage.setItem('deploy_version', data.deploy_version);
        deployVersion = data.deploy_version;
      }
      if (
        refreshCount != undefined &&
        refreshCount != 'undefined' &&
        refreshCount != null &&
        refreshCount != 'null'
      ) {
        if (refreshCount >= 1 && hasNewUpdates) {
          window.localStorage.setItem('deploy_version', data.deploy_version);
          window.localStorage.setItem('refresh_count', 0);
        } else {
          refreshCount = parseInt(refreshCount) + 1;
          window.localStorage.setItem('refresh_count', refreshCount);
        }
      } else {
        window.localStorage.setItem('refresh_count', 0);
      }
    }
    return {
      hasNewUpdates,
      deployVersion,
    };
  } catch (error) {
    return {
      hasNewUpdates,
      deployVersion,
    };
  }
};
export const getUrlByReturn = (returnUrl, idToken, hostname) => {
  window.localStorage.removeItem('returnUrl');
  window.localStorage.removeItem('locationPathname');
  let isOutboundUrl = false;
  if (returnUrl.indexOf('https') !== -1 || returnUrl.indexOf('http') !== -1) {
    returnUrl = `${returnUrl}`;
    isOutboundUrl = true;
  } else if (hostname != undefined) {
    returnUrl = `https://${hostname}${returnUrl}`;
  } else {
    returnUrl = `${window.location.origin}${returnUrl}`;
  }

  if (returnUrl.indexOf('?') !== -1 && idToken != undefined && isOutboundUrl) {
    returnUrl = `${returnUrl}&token=${idToken}`;
  } else if (idToken != undefined && isOutboundUrl) {
    returnUrl = `${returnUrl}?token=${idToken}`;
  }
  return returnUrl;
};
export const redirectToNextPage = (response, idToken) => {
  let returnUrl = window.localStorage.getItem('returnUrl');
  if (returnUrl != undefined && returnUrl != null && returnUrl != '') {
    window.location.assign(getUrlByReturn(returnUrl, idToken));
  } else if (
    response.data.high_level_admin != undefined &&
    response.data.high_level_admin === 1
  ) {
    window.location.href = window.location.origin + '/organization/grid';
  } else if (
    [
      constants.businessPartnerId,
      constants.orgAdminId,
      constants.staffId,
    ].includes(response.data.role_id)
  ) {
    window.location.href = window.location.origin + '/dashboard/staff';
  } else if ([constants.applicantId].includes(response.data.role_id)) {
    window.location.href = window.location.origin + '/dashboard/applicant';
  } else if ([constants.consultantId].includes(response.data.role_id)) {
    window.location.href = window.location.origin + '/dashboard/consultant';
  } else if ([constants.employerId].includes(response.data.role_id)) {
    window.location.href = window.location.origin + '/dashboard/employer';
  } else if (
    [constants.agentId, constants.salesId].includes(response.data.role_id)
  ) {
    window.location.href = window.location.origin + '/dashboard/sales';
  } else if ([constants.accountingId].includes(response.data.role_id)) {
    window.location.href = window.location.origin + '/dashboard/accounting';
  } else {
    window.location.href = window.location.origin + '/application/list';
  }
};
