<template>
  <div>
    <v-card class="mt-3">
      <v-card-title>
        <span>Co-Signer Residency Declaration</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <el-form :model="form" ref="form" label-position="top" class="form">
          <v-row dense>
            <v-col cols="12">
              <div class="el-form-item mb-0">
                <label class="el-form-item__label">Are you Canadian citizen living exclusively outside Canada? See "Who can sponsor" in the Guide for information on residency.</label>
              </div>
              <el-form-item prop="six" class="ml-3">
                <el-radio-group id="six" v-model="form.declaration_citizen" class="mt-1">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <div class="el-form-item mb-0">
                <label class="el-form-item__label">Immediately after my dependent children become permanent residents, I intend to live in:</label>
              </div>
              <el-form-item prop="six" class="ml-3">
                <el-radio-group id="six" v-model="form.declaration_next" class="mt-1">
                  <el-radio label="a Canadian province or territory other than Quebec">a Canadian province or territory other than Quebec</el-radio>
                  <el-radio label="the province of Quebec">the province of Quebec</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
    </v-card>
    <v-card class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="form">
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q1">
                <el-checkbox id="q1" v-model="form.q1">1. Are you 18 years of age or older?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q2">
                <el-checkbox id="q2" v-model="form.q2">2. Are you a Canadian citizen or permanent resident?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q3">
                <el-checkbox id="q3" v-model="form.q3">3. Do you reside in Canada and in no other country?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q4">
                <el-checkbox id="q4" v-model="form.q4">3. Do you reside in Canada and in no other country?
                  <p class="mb-0 ml-2 grey--text">If you answered “NO”, but are a Canadian citizen living exclusively outside Canada, you may still be eligible to sponsor.</p>
                  <p class="ml-2 grey--text">If you answer “YES” to question 4 to 12 you are not eligible to be a co-signer. See <strong>Who can sponsor</strong> in the Guide for information.</p>
                </el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q5">
                <el-checkbox id="q5" v-model="form.q5">4. Are you in receipt of social assistance for a reason other than disability?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q6">
                <el-checkbox id="q6" v-model="form.q6">5. Are you an undischarged bankrupt under the Bankruptcy and Insolvency Act?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q7">
                <el-checkbox id="q7" v-model="form.q7">6. Have you been ordered to leave Canada?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q8">
                <el-checkbox id="q8" v-model="form.q8">7. Did you co-sign an undertaking where the sponsored person or his or her family members received social assistance during
                the validity period of the undertaking?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q9">
                <el-checkbox id="q9" v-model="form.q9">8. Have you been ordered to leave Canada?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q10">
                <el-checkbox id="q10" v-model="form.q10">9. Are you late in making a required payment on an immigration loan, a performance bond or any other amounts you agreed
                to pay under Canadian immigration legislation, and have not made arrangements to defer payments?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q11">
                <el-checkbox id="q11" v-model="form.q11">10. Are you currently detained in jail, prison, penitentiary or reformatory?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q12">
                <el-checkbox id="q12" v-model="form.q12">11. Have you been convicted of a sexual offence or serious violent offence against anyone, or an offence causing bodily harm
                against someone who is or was related to you, or an attempt to commit such an offence?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q13">
                <el-checkbox id="q13" v-model="form.q13">12. Are you in default of court order to make support payments to your spouse, former spouse, common-law partner, former common-law partner or child?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q14">
                <el-checkbox id="q14" v-model="form.q14">13. Are you the subject of an application to revoke your <strong>Canadian</strong> citizenship?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q15">
                <el-checkbox id="q15" v-model="form.q15">14. Are you the subject of a report on inadmissibility?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q16">
                <el-checkbox id="q16" v-model="form.q16">15. Have you been charged with an offence under an Act of Parliament punishable by a maximum term of imprisonment of at least 10 years?</el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="radio-parent">
            <v-col cols="12">
              <el-form-item prop="q18_text" label="If you answered Yes (checked the box) to question 10, 13, 14, or 15 please provide details including date and place.">
                <el-input id="q18_text" :disabled="disabled" v-model="form.q18_text" type="textarea" :autosize="{ minRows: 4, maxRows: 6}" />
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm()">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      formNode: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        q1: false,
        q2: false,
        q3: false,
        q4: false,
        q5: false,
        q6: false,
        q7: false,
        q8: false,
        q9: false,
        q10: false,
        q11: false,
        q12: false,
        q13: false,
        q14: false,
        q15: false,
        q16: false,
        q17: false,
        q18: false,
        q18_text: '',
        declaration_next: null,
        declaration_citizen: null
      },
      fields: ['declaration_next', 'declaration_citizen', 'q1', 'q2', 'q3', 'q4', 'q5', 'q6', 'q7', 'q8', 'q9', 'q10', 'q11', 'q12', 'q13', 'q14', 'q15', 'q16', 'q17', 'q18', 'q18_text'],
      url: 'imms_table/application_sponsorship_eligibility',
      state: 'sEligibility',
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    },
    form: function() {
      if (this.item.length > 0) {
        return this.item[0]
      }
      else {
        return this.formNode
      }
    },
    disabled: function() {
      return this.form.q18 || this.form.q17 || this.form.q16 || this.form.q13 ? false : true
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/application_sponsorship_eligibility?filter=member_uid=${this.memberUID}`
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true

          if (this.form.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': this.form, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                this.form.id = response.data.resource[0].id
                this.form.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    }
  }
}
</script>
