import api from "./../plugins/axios";

let url = "/table/";

export const state = {
  country: [],
  province: [],
  program: [],
  category: [],
  nativeLanguages: [],
  status: [{ name: "Citizen" }, { name: "Permanent resident" }, { name: "Foreign National" }, { name: "Visitor" }, { name: "Worker" }, { name: "Student" }, { name: "Other" }, { name: "Protected Person" }, { name: "Refugee Claimant" }],
  maritalStatus: [{ name: "Annulled marriage" }, { name: "Common-law" }, { name: "Divorced" }, { name: "Legally separated" }, { name: "Married" }, { name: "Married-physically present" }, { name: "Married-not physically present" }, { name: "Single" }, { name: "Unknown" }, { name: "Widowed" }],
  CanadaOfficialLanguages: [{ name: "English" }, { name: "French" }],
  DependentRelationshipStatus: [{ name: "Spouse/Common-law partner" }, { name: "Son" }, { name: "Daughter" }, { name: "Step-son" }, { name: "Step-daughter" }],
  relationshipStatus: [{ name: "Spouse/Common-law partner" }, { name: "Son" }, { name: "Daughter" }, { name: "Step-son" }, { name: "Step-daughter" }, { name: "Grandson" }, { name: "Granddaughter" }, { name: "Step-Grandson" }, { name: "Step-Granddaughter" }, { name: "Father" }, { name: "Mother" }, { name: "Brother" }, { name: "Sister" }, { name: "Step-brother" }, { name: "Step-sister" }, { name: "Half-brother" }, { name: "Half-sister" }],
  purposeMyVisit: [{ name: "Business" }, { name: "Tourism" }, { name: "Short-Term Studies" }, { name: "Returning Student" }, { name: "Super Visa, For Parents or Grandparents" }, { name: "Other" }, { name: "Family Visit" }, { name: "Visit" }],
  relationships: [],
  eyeColors: [{ name: "Black" }, { name: "Blue" }, { name: "Brown" }, { name: "Green" }, { name: "Grey" }, { name: "Hazel" }, { name: "Sea green" }, { name: "Pink" }, { name: "Other" }],
  testTaken: [{ name: "CELPIP General" }, { name: "CELPIP General-LS" }, { name: "IELTS - General" }, { name: "Test d'Évaluation de Français (TEF)" }, { name: "Test d'Évaluation de Français (TEFAQ" }, { name: "TEF pour la naturalisation" }],
  profileTabs: "Profile"
};

export const getters = {
  country(state) {
    return state.country;
  },
  province(state) {
    return state.province;
  },
  program(state) {
    return state.program;
  },
  category(state) {
    return state.category;
  },
  nativeLanguages(state) {
    return state.nativeLanguages;
  },
  status(state) {
    return state.status;
  },
  maritalStatus(state) {
    return state.maritalStatus;
  },
  CanadaOfficialLanguages(state) {
    return state.CanadaOfficialLanguages;
  },
  DependentRelationshipStatus(state) {
    return state.DependentRelationshipStatus;
  },
  relationshipStatus(state) {
    return state.relationshipStatus;
  },
  purposeMyVisit(state) {
    return state.purposeMyVisit;
  },
  relationships(state) {
    return state.relationships;
  },
  eyeColors(state) {
    return state.eyeColors;
  },
  profileTabs: (state) => {
    return state.profileTabs;
  }
};

export const actions = {
  changeProfileTabs(context, payload) {
    context.commit("changeProfileTabs", payload);
  },
  getCountry({ commit }, filter) {
    return api.get(`${url}countries?order=country_name asc`).then(
      (response) => {
        commit("GET_COUNTRIES", response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_COUNTRIES", err);
        return Promise.reject(err);
      }
    );
  },
  getProvince({ commit }, id) {
    var country_id = 42;
    if (id !== undefined) country_id = id;
    return api.get(`${url}provinces?filter=country_id=${country_id}&order=province_name asc`).then(
      (response) => {
        commit("GET_PROVINCES", response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_PROVINCES", err);
        return Promise.reject(err);
      }
    );
  },
  getProgram({ commit }, filter) {
    return api.get(url + "generic_program?order=name asc").then(
      (response) => {
        commit("GET_PROGRAMS", response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_PROGRAMS", err);
        return Promise.reject(err);
      }
    );
  },
  getCategory({ commit }, id) {
    return api.get(`${url}generic_category?filter=program_id=${id}&order=name asc`).then(
      (response) => {
        commit("GET_CATEGORY", response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_CATEGORY", err);
        return Promise.reject(err);
      }
    );
  },
  getNativeLanguages({ commit }, id) {
    return api.get(url + "native_languages?order=name asc").then(
      (response) => {
        commit("GET_NATIVE_LANGUAGES", response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_NATIVE_LANGUAGES", err);
        return Promise.reject(err);
      }
    );
  },
  getRelationShips({ commit }, filter) {
    return api.get(url + "relationships" + filter).then(
      (response) => {
        commit("GET_RELATIONSHIPS", response.data.resource);
        return Promise.resolve(response.data.resource);
      },
      (err) => {
        commit("FAILURE_RELATIONSHIPS", err);
        return Promise.reject(err);
      }
    );
  }
};

export const mutations = {
  changeProfileTabs(state, payload) {
    state.profileTabs = payload;
  },
  GET_COUNTRIES(state, datas) {
    state.country = datas;
  },
  FAILURE_COUNTRIES(state) {
    state.country = [];
  },
  GET_PROVINCES(state, datas) {
    state.province = datas;
  },
  FAILURE_PROVINCES(state) {
    state.province = [];
  },
  GET_PROGRAMS(state, datas) {
    state.program = datas;
  },
  FAILURE_PROGRAMS(state) {
    state.program = [];
  },
  GET_CATEGORY(state, datas) {
    state.category = datas;
  },
  FAILURE_CATEGORY(state) {
    state.category = [];
  },
  GET_NATIVE_LANGUAGES(state, datas) {
    state.nativeLanguages = datas;
  },
  FAILURE_NATIVE_LANGUAGES(state) {
    state.nativeLanguages = [];
  },
  GET_RELATIONSHIPS(state, datas) {
    state.relationships = datas;
  },
  FAILURE_RELATIONSHIPS(state) {
    state.relationships = [];
  }
};
