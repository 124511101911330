<template>
  <v-card class="mt-3" v-if="!isDataValidation">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <v-row dense>
        <v-col cols="8" sm="8" md="7" lg="6">
          <label class="el-form-item__label">{{ $t('imm_0008.canada_certificate_qualification') }} Do you have a certificate of qualification from a Canadian province or territory?</label>
        </v-col>
        <v-col cols="4" sm="3" md="3" lg="3">
          <el-radio-group id="canada_certificate_qualification" v-model="form.canada_certificate_qualification" class="mt-3">
            <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
            <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
          </el-radio-group>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  }
}
</script>
