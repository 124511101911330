<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
      <div class="body-2 mr-3">Are you currently in Canada?</div>
      <el-radio-group id="currently_in_canada" v-model="form.currently_in_canada" class="mt-1">
        <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
        <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
      </el-radio-group>
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <v-row dense class="medium-space" v-if="form.currently_in_canada == 'Yes'">
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'current_status_canada')">
          <el-form-item label="Indicate Your Current Status in Canada" prop="current_status_canada">
            <el-select id="current_status_canada" v-model="form.current_status_canada" placeholder="Select" style="width: 100%;">
              <el-option value="Visitor">Visitor</el-option>
              <el-option value="Student">Student</el-option>
              <el-option value="Worker">Worker</el-option>
              <el-option value="Other">Other</el-option>
            </el-select>
          </el-form-item>
        </v-col>
        <template v-if="form.current_status_canada == 'Worker'">
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'work_permit_information')">
            <el-form-item label="Work Permit Information" prop="work_permit_information">
              <el-input id="work_permit_information" v-model="form.work_permit_information" style="width: 100%;" />
            </el-form-item>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mounted() {
    if (this.isDataValidation) {
      if (this.form.currently_in_canada === 'Yes') {
        var item = {
          work_permit_information: null,
          current_status_canada: null
        }

        if (this.form.current_status_canada !== 'Worker') {
          delete item.work_permit_information
        }

        this.checkItem(this.form, item)
      }
      else {
        this.hideSection = false
      }
    }
  }
}
</script>
