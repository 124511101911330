<template>
  <div>
    <v-card v-if="hideSection" class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top">
          <v-row dense>
            <v-col cols="12" v-if="dataValidation(form, 'in_canada')">
              <el-form-item prop="in_canada" label="If you are currently in Canada, please indicate your status">
                <el-input v-model="form.in_canada" @change="handleAutoSave(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'immigration_status')">
              <el-form-item prop="immigration_status" label="Other (please specify)">
                <el-input v-model="form.immigration_status" @change="handleAutoSave(form)" style="width: 100%" />
                <label>(Students, Visitors, and Refugees are not eligile for this program)</label>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'valid_permit')">
              <el-form-item prop="valid_permit" label="If you hold a valid Temporary Work Permit in Canada, please specify the type of work permit:">
                <el-input v-model="form.valid_permit" @change="handleAutoSave(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'other')">
              <el-form-item prop="other" label="Other (please specify):">
                <el-input v-model="form.other" @change="handleAutoSave(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'client_id')">
              <el-form-item prop="client_id" label="Client ID">
                <el-input v-model="form.client_id" @change="handleAutoSave(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'expiry_date')">
              <el-form-item prop="expiry_date" label="Expiry Date">
                <el-date-picker v-model="form.expiry_date" @change="handleAutoSave(form)" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <label>Immigration History</label>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'admission_to_canada')">
              <el-form-item prop="admission_to_canada">
                <el-checkbox v-model="form.admission_to_canada" label="Have you ever applied for admission into Canada as an immigrant?" @change="handleAutoSave(form)"></el-checkbox>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'refused_in_canada')">
              <el-form-item prop="refused_in_canada">
                <el-checkbox v-model="form.refused_in_canada" label="Have you ever been refused admission into Canada as an immigrant?" @change="handleAutoSave(form)"></el-checkbox>
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  mixins: [autosave],
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        in_canada: null,
        refused_in_canada: null,
        admission_to_canada: null,
        expiry_date: null,
        client_id: null,
        other: null,
        valid_permit: null,
        immigration_status: null
      },
      url: 'imms_table/lmia_yukon_immigrant_info',
      getUrl: 'imms_table/lmia_yukon_immigrant_info?application_imms_uuid=' + this.$route.params.id
    }
  },
  mounted() {
    this.fetch().then(data => {
      if (data.items != undefined && data.items.length > 0) {
        if (this.isDataValidation) {
          this.checkRow(data.items[0])
        }
        this.form = data.items[0]
      }
      else {
        if (this.isDataValidation) {
          this.checkRow(this.form)
        }
      }
    })
  }
}
</script>
