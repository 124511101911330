<template>
  <div v-if="hideSection">
    <template v-if="isHideParents">
      <v-card class="mt-3" v-if="!visible">
        <v-card-title>
          <span>Parents</span>
          <v-spacer />
          <v-btn v-if="!isDataValidation" color="primary" dark small @click.native="addMember('parents')">Add</v-btn>
        </v-card-title>
        <v-card-text>
          <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
            {{sectionInfoBox}}
          </v-alert>
          <v-data-table
            dense
            class="ofas-datatable"
            v-bind:headers="headers"
            v-bind:items="memberItems.parents"
            hide-default-footer
            disable-pagination
            >
            <template #[`item`]="{ item }">
              <tr>
                <td>{{ item.fname }}</td>
                <td>{{ item.lname }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.present_occupation }}</td>
                <td>{{ item.city_residence }}</td>
                <td>{{ item.country_residence_name }}</td>
                <td>{{ item.deceased ? '(deceased)' : item.age }}</td>
                <td class="text-right" width="80">
                  <v-btn icon small @click.native="editMember(item)">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn icon small @click.native="deleteItem(item)">
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>

    <template v-if="isHideSpouse">
      <v-card class="mt-3" v-if="!visible">
        <v-card-title>
          <span>Spouse or Common-law partner</span>
          <v-spacer />
          <v-btn v-if="!isDataValidation" :disabled="memberItems.spouse.length >= 1" color="primary" dark small @click.native="addMember('spouse')">Add</v-btn>
        </v-card-title>
        <v-card-text>
          <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
            {{sectionInfoBox}}
          </v-alert>
          <v-data-table
            dense
            class="ofas-datatable"
            v-bind:headers="headers"
            v-bind:items="memberItems.spouse"
            hide-default-footer
            disable-pagination
            >
            <template #[`item`]="{ item }">
              <tr>
                <td>{{ item.fname }}</td>
                <td>{{ item.lname }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.present_occupation }}</td>
                <td>{{ item.city_residence }}</td>
                <td>{{ item.country_residence_name }}</td>
                <td>{{ item.deceased ? '(deceased)' : item.age }}</td>
                <td class="text-right" width="80">
                  <v-btn icon small @click.native="editMember(item)">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn icon small @click.native="deleteItem(item)">
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>

    <template v-if="isHideChildren">
      <v-card class="mt-3" v-if="!visible">
        <v-card-title>
          <span>Children</span>
          <v-spacer />
          <v-btn v-if="!isDataValidation" color="primary" dark small @click.native="addMember('children')">Add</v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            dense
            class="ofas-datatable"
            v-bind:headers="headers"
            v-bind:items="memberItems.children"
            hide-default-footer
            disable-pagination
            >
            <template #[`item`]="{ item }">
              <tr>
                <td>{{ item.fname }}</td>
                <td>{{ item.lname }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.present_occupation }}</td>
                <td>{{ item.city_residence }}</td>
                <td>{{ item.country_residence_name }}</td>
                <td>{{ item.deceased ? '(deceased)' : item.age }}</td>
                <td class="text-right" width="80">
                  <v-btn icon small @click.native="editMember(item, 'children')">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn icon small @click.native="deleteItem(item)">
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>

    <template v-if="isHideSublings">
      <v-card class="mt-3" v-if="!visible">
        <v-card-title>
          <span>Brothers and Sisters</span>
          <v-spacer />
          <v-btn v-if="!isDataValidation" color="primary" dark small @click.native="addMember('brothers')">Add</v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            dense
            class="ofas-datatable"
            v-bind:headers="headers"
            v-bind:items="memberItems.brothers"
            hide-default-footer
            disable-pagination
            >
            <template #[`item`]="{ item }">
              <tr>
                <td>{{ item.fname }}</td>
                <td>{{ item.lname }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.present_occupation }}</td>
                <td>{{ item.city_residence }}</td>
                <td>{{ item.country_residence_name }}</td>
                <td>{{ item.deceased ? '(deceased)' : item.age }}</td>
                <td class="text-right" width="80">
                  <v-btn icon small @click.native="editMember(item, 'brothers')">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn icon small @click.native="deleteItem(item)">
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>

    <el-form v-if="visible" :model="form" :rules="rules" ref="form" label-position="top" class="mt-3">
      <v-card>
        <v-card-title>
          <span>Members of Family</span>
          <v-spacer />
          <v-list class="form-list pa-0">
            <v-list-item v-if="!isDataValidation" class="ma-0">
              <v-list-item-content class="mt-2">
                <v-list-item-title>
                  <el-checkbox v-model="form.deceased" @change="submitForm();chechDeceased(form)">{{ $t('application_family_members.deceased') }}</el-checkbox>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!isDataValidation && !form.deceased" class="ma-0">
              <v-list-item-content class="mt-2">
                <v-list-item-title>
                  <el-checkbox v-model="form.accompany" @change="submitForm();">{{ $t('application_family_members.accompany') }}</el-checkbox>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'fname')">
              <el-form-item :label="$t('application_family_members.fname')" prop="fname">
                <el-input v-model="form.fname" @change="submitForm()" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'middle_name')">
              <el-form-item :label="$t('application_family_members.middle_name')" prop="middle_name">
                <el-input v-model="form.middle_name" @change="submitForm()" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'lname')">
              <el-form-item :label="$t('application_family_members.lname')" prop="lname">
                <el-input v-model="form.lname" @change="submitForm()" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense v-show="dataValidationItems(form,'fname_native_language') && !form.deceased">
            <v-col cols="12">
              <v-divider class="mb-3"></v-divider>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <label class="el-form-item__label">{{ $t('application_family_members.fname_native_language') }}</label>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item prop="fname_native_language">
                <el-input @change="submitForm()" v-model="form.fname_native_language" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <v-divider class="mb-3"></v-divider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'birth_date')">
              <el-form-item :label="$t('application_family_members.birth_date')" prop="birth_date">
                <el-date-picker @change="submitForm()" :picker-options="datePickerOptions"
                  value-format="yyyy-MM-dd" v-model="form.birth_date"
                  v-on:click.native="checkDate($event)" style="width: 100%"></el-date-picker>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'country_id')">
              <el-form-item :label="$t('application_family_members.country_id')" prop="country_id">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select v-model="form.country_id" @change="submitForm()" placeholder="Select" class="el-input__inner">
                      <option v-for="(item, index) in country" v-bind:key="'country_id_' + index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'city_birth') && !form.deceased">
              <el-form-item :label="$t('application_family_members.city_birth')" prop="city_birth">
                <el-input v-model="form.city_birth" @change="submitForm()" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'relationship_id')">
              <el-form-item :label="$t('application_family_members.type') + ' ' + memberName" prop="relationship_id">
                <el-select v-model="form.relationship_id" placeholder="Select" style="width: 100%;" @change="submitForm()">
                  <el-option v-for="(item, index) in relationshipStatus" v-bind:key="'relationship_id_' + index" :value="item.id" :label="item.name">{{ item.name }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'family_name_birth') && !form.deceased">
              <el-form-item :label="$t('application_family_members.family_name_birth')" prop="family_name_birth">
                <el-input v-model="form.family_name_birth" @change="submitForm()" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'email_address') && !form.deceased">
              <el-form-item :label="$t('application_family_members.email_address')" prop="email_address" :rules="[{ type: 'email', message: 'Please input correct email address', trigger: 'blur,change' }]">
                <el-input type="email" v-model="form.email_address" @change="submitForm()" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'marital_status') && !form.deceased">
              <el-form-item :label="$t('application_family_members.marital_status')" prop="marital_status">
                <el-select v-model="form.marital_status" @change="submitForm()" style="width: 100%;">
                  <el-option v-for="item in maritalStatus" :key="'marital_status_' + item.name" :label="item.name" :value="item.name">{{
                    item.name }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'not_accompanying') && !form.deceased && isShow && form.accompany === false">
              <el-form-item :label="$t('application_family_members.not_accompanying')" prop="not_accompanying">
                <el-input v-model="form.not_accompanying" @change="submitForm()" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'country_residence_id') && !form.deceased">
              <el-form-item :label="$t('application_family_members.country_residence')" prop="country_residence_id">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select v-model="form.country_residence_id" @change="submitForm()" placeholder="Select" class="el-input__inner">
                      <option v-for="(item, index) in country" v-bind:key="'country_residence_id_' + index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'city_residence') && !form.deceased">
              <el-form-item :label="$t('application_family_members.city_residence')" prop="city_residence">
                <el-input v-model="form.city_residence" @change="submitForm()" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'present_address') && isShow && !form.deceased">
              <el-form-item :label="$t('application_family_members.present_address')" prop="present_address">
                <el-input v-model="form.present_address" @change="submitForm()" style="width: 100%" />
              </el-form-item>
            </v-col>

            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'address') && !form.deceased">
              <el-form-item :label="$t('application_family_members.address')" prop="address">
                <el-input v-model="form.address" @change="submitForm()" style="width: 100%" />
              </el-form-item>
            </v-col>

            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'present_occupation') && !form.deceased">
              <el-form-item :label="$t('application_family_members.present_occupation')" prop="present_occupation">
                <el-input v-model="form.present_occupation" @change="submitForm()" style="width: 100%" />
              </el-form-item>
            </v-col>

            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'death_date') && form.deceased">
              <el-form-item :label="$t('application_family_members.death_date')" prop="death_date">
                <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions"
                  v-model="form.death_date" v-on:click.native="checkDate($event)"
                  @change="submitForm();chechDeathAddress(form)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>

            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'death_place') && form.deceased">
              <el-form-item :label="$t('application_family_members.death_place')" prop="death_place">
                <el-input v-model="form.death_place" @change="submitForm();chechDeathAddress(form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'country_residence') && form.deceased">
              <el-form-item label="Country of Death" prop="country_residence">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select v-model="form.country_residence" @change="submitForm();chechDeathAddress(form)" placeholder="Select" class="el-input__inner">
                      <option v-for="(item, index) in country" v-bind:key="'country_residence_' + index" :value="item.country_name" :label="item.country_name">{{
                        item.country_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form,'postal_code') && !form.deceased">
              <el-form-item label="Postal Code" prop="postal_code">
                <el-input v-model="form.postal_code" @change="submitForm()" style="width: 100%" />
              </el-form-item>
            </v-col>
            <template v-if="isOINP">
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'resident_canada')">
                <el-form-item label="Is your family member a Canadian citizen or permanent resident of Canada?" prop="resident_canada">
                  <el-radio-group v-model="form.resident_canada" @change="submitForm()" style="width: 100%;">
                    <el-radio label="Yes" value="Yes"></el-radio>
                    <el-radio label="No" value="No"></el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'accompany_to_ontario')">
                <el-form-item label="Is your family member accompanying you to Ontario?" prop="accompany_to_ontario">
                  <el-radio-group v-model="form.accompany_to_ontario" @change="submitForm()" style="width: 100%;">
                    <el-radio label="Yes" value="Yes"></el-radio>
                    <el-radio label="No" value="No"></el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </template>
            <v-col cols="12" class="mt-2">
              <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm(form)">{{ $t('global.save') }}</v-btn>
              <v-btn small @click.native="getMembers(); visible = false">Close</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </el-form>
    <div class="error--snack error" v-if="errorMessage.length > 0">
      <template v-for="(message, ind) in errorMessage">
        <div :key="'message_' + ind" class="error--snack__content">
            {{ message.message }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionAlias: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    },
    memberName: {
      type: String
    },
    urlView: {
      type: String
    },
    country: {
      type: Array
    },
    maritalStatus: {
      type: Array
    },
    isOINP: {
      type: Boolean
    },
    isShow: {
      type: Boolean
    },
    isDataValidation: {
      type: Boolean
    }
  },
  data() {
    var validateFirstName = (rule, value, callback) => {
      let idx = this.errorMessage.map(p => p.field).indexOf(rule.field)
      if (!value) {
        if (idx === -1) this.errorMessage.push({ message: 'Please input the first name', field: rule.field })
        callback(new Error(' '))
      }
      else {
        if (idx !== -1) this.errorMessage.splice(idx, 1)
        callback()
      }
    };
    var validateLastName = (rule, value, callback) => {
      let idx = this.errorMessage.map(p => p.field).indexOf(rule.field)
      if (!value) {
        if (idx === -1) this.errorMessage.push({ message: 'Please input the last name', field: rule.field })
        callback(new Error(' '))
      }
      else {
        if (idx !== -1) this.errorMessage.splice(idx, 1)
        callback()
      }
    };
    var checkCharacter = (rule, value, callback) => {
      var patt = new RegExp("[^ A-Za-z а-яА-ЯЁё]");
      var res = patt.test(value);

      if (res) {
        callback(new Error('Please only use alpha characters (abc)'));
      }
      else {
        callback();
      }
    };
    return {
      edit: false,
      form: {
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        fname: '',
        middle_name: '',
        lname: '',
        birth_date: null,
        place: null,
        marital_status: null,
        present_address: null,
        address: null,
        type: null,
        relationship_id: null,
        country_id: null,
        fname_native_language: null,
        family_name_birth: null,
        deceased: false,
        accompany: false,
        death_date: null,
        death_place: null,
        not_accompanying: null,
        dependant_type: null,
        email_address: null,
        city_birth: null,
        city_residence: null,
        country_residence: null,
        country_residence_id: null,
        present_occupation: null,
        resident_canada: null,
        accompany_to_ontario: null,
        postal_code: null
      },
      rules: {
        fname: [
          { validator: validateFirstName, required: true, trigger: 'blur' },
          { validator: checkCharacter, trigger: 'blur' }
        ],
        lname: [
          { validator: validateLastName, required: true, trigger: 'blur' },
          { validator: checkCharacter, trigger: 'blur' }
        ],
        middle_name: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        family_name_birth: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        relationship_id: [
          { message: 'Please select the relationship to principal', required: true, trigger: 'blur' }
        ]
      },
      loading: false,
      items: [],
      memberItems: {
        parents: [],
        spouse: [],
        children: [],
        brothers: []
      },
      headers: [
        { text: 'First Name', value: 'fname', sortable: false, align: 'left' },
        { text: 'Last Name', value: 'lname', sortable: false, align: 'left' },
        { text: 'Relationship', value: 'name', sortable: false, align: 'left' },
        { text: 'Occupation', value: 'present_occupation', sortable: false, align: 'left' },
        { text: 'City of Residence', value: 'city_residence', sortable: false, align: 'left' },
        { text: 'Country of Residence', value: 'country_residence_name', sortable: false, align: 'left' },
        { text: 'Age', value: 'age', sortable: false, align: 'left' },
        { text: 'Action', sortable: false, align: 'left' }
      ],
      isRelationship: false,
      checkEdit: false,
      visible: false,
      errorMessage: [],
      isHideChildren: true,
      isHideSublings: true,
      isHideParents: true,
      url: '/imms_table/application_family_members'
    }
  },
  computed: {
    ...mapGetters({
      relationshipStatus: 'other/relationships'
    })
  },
  created() {
    this.getMembers()
    this.getRelationshipStatus('name!=Principal')
  },
  methods: {
    getRelationshipStatus(filter) {
      this.$store.dispatch('other/getRelationShips', '?filter=' + filter + '&order=rel_order ASC,name ASC')
    },
    checkMessage(data) {
      if (!data.fname && data.fname === null) {
        this.errorMessage.push({ "message": "Please input the first name", "field": "fname" })
      }
      if (!data.lname && data.lname === null) {
        this.errorMessage.push({ "message": "Please input the last name", "field": "lname" })
      }
    },
    async getMembers() {
      this.$Progress.start()
      try {
        const res = await this.$api.get(`/application/application_family_member_view?member_uid=${this.memberUID}&order=rel_order ASC,birth_date ASC`)
        let items = res.data.resource || []
        let now = new Date()

        items.forEach(function(item) {
          item.age = null
          if (item.birth_date != null && !item.deceased) {
            let birth = moment(item.birth_date, 'YYYY')
            let month = moment(item.birth_date).get('month')
            let nowDate = moment(now, 'YYYY')
            let nowMonth = moment(now).get('month')
            let ageYear = nowDate.diff(birth, 'years')
            let ageMonth = nowMonth - month
            if (ageMonth < 0) {
              ageYear = ageYear - 1
              ageMonth = 12 + ageMonth
            }

            item.age = `${ageYear}.${ageMonth}`
          }
        })
        this.getDataMember(items)
        this.$Progress.finish()
      }
      catch (err) {
        console.error(err)
        this.$Progress.fail()
      }
    },
    getDataMember(datas) {
      let parents = []
      let spouse = []
      let children = []
      let brothers = []

      if (this.isDataValidation) {
        datas.forEach(function(data) {
          var form = {
            fname: null,
            lname: null,
            birth_date: null,
            marital_status: null,
            address: null,
            country_id: null,
            death_date: null,
            death_place: null,
            city_birth: null,
            city_residence: null,
            country_residence: null,
            country_residence_id: null
          }

          if (!data.deceased) {
            delete form.death_date
            delete form.death_place
            delete form.country_residence
          }
          else {
            delete form.marital_status
            delete form.country_residence
            delete form.city_birth
            delete form.city_residence
            delete form.country_residence_id
          }

          if (!this.isOINP) {
            delete form.resident_canada
            delete form.accompany_to_ontario
          }

          var obj = this.checkFormItems(data, form);

          if (obj != 'empty') {
            switch (data.name) {
              case 'Father':
              case 'Mother':
                parents.push(data)
                break;
            }
            if (data.relationship_id === 2) {
              spouse.push(data)
            }
            if (data.bothers_and_sisters) {
              brothers.push(data)
            }
            if (data.child) {
              children.push(data)
            }
          }
        }, this)
        if (datas.length == 0) {
          this.hideSection = true
        }
        else {
          this.hideSection = this.validationItems.length == 0 ? false : true
        }
      }
      else {
        datas.forEach(function(data) {
          switch (data.name) {
            case 'Father':
            case 'Mother':
              parents.push(data)
              break;
          }
          if (data.relationship_id === 2) {
            spouse.push(data)
          }
          if (data.bothers_and_sisters) {
            brothers.push(data)
          }
          if (data.child) {
            children.push(data)
          }
        }, this)
      }

      if (!this.isDataValidation) {
        this.isHideChildren = true
        this.isHideSublings = true
        this.isHideSpouse = true
      }
      else {
        this.isHideParents = parents.length == 0 ? false : true
        this.isHideChildren = children.length == 0 ? false : true
        this.isHideSublings = brothers.length == 0 ? false : true
        this.isHideSpouse = spouse.length == 0 ? false : true
      }

      this.memberItems.parents = parents
      this.memberItems.children = children
      this.memberItems.brothers = brothers
      this.memberItems.spouse = spouse
    },
    chechDeceased(form) {
      if (form.deceased) {
        form.marital_status = 'Not applicable - deceased'
        this.chechDeathAddress(form)
      }
      else {
        form.marital_status = null
        form.address = null
      }
    },
    chechDeathAddress(form) {
      if (form.deceased) {
        form.address = `Deceased on ${moment(form.death_date).format('YYYY-MM-DD')} at ${form.death_place}, ${form.country_residence}`
      }
    },
    checkCountry(form) {
      if (form.country_id) {
        form.country_id = parseFloat(form.country_id)
      }
    },
    nodeClear() {
      this.form = {
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        fname: '',
        middle_name: '',
        lname: '',
        birth_date: null,
        place: '',
        relationship_id: null,
        marital_status: null,
        present_address: null,
        address: null,
        type: null,
        country_id: null,
        fname_native_language: null,
        family_name_birth: null,
        deceased: false,
        accompany: false,
        death_date: null,
        death_place: null,
        not_accompanying: null,
        dependant_type: null,
        email_address: null,
        city_birth: null,
        city_residence: null,
        country_residence: null,
        present_occupation: null,
        country_residence_id: null,
        resident_canada: null,
        accompany_to_ontario: null,
        postal_code: null
      }
      this.checkEdit = false
    },
    addMember(filter) {
      this.nodeClear()
      this.visible = true
      this.checkEdit = false
      let filt = ''
      if (filter === 'parents') {
        this.isRelationship = true
        filt = '(id = 8) OR (id = 9)'
      }
      else if (filter === 'spouse') {
        this.isRelationship = true
        filt = '(id = 2)'
      }
      else if (filter === 'brothers') {
        this.isRelationship = true
        filt = 'bothers_and_sisters = true'
      }
      else if (filter === 'children') {
        this.isRelationship = true
        filt = 'child = true'
      }
      else {
        this.isRelationship = false
      }
      this.getRelationshipStatus(filt)
    },
    editMember(item, filter) {
      this.visible = true
      this.form = item
      if (this.form.relationship_to_principal !== null && this.form.relationship_to_principal) {
        this.form.relationship_to_principal = parseFloat(this.form.relationship_to_principal)
      }
      if (this.form.country_id !== null && this.form.country_id) {
        this.form.country_id = parseFloat(this.form.country_id)
      }

      this.checkEdit = true

      let filt = ''
      if (filter === 'parents') {
        this.isRelationship = true
        filt = '(id = 8) OR (id = 9)'
      }
      else if (filter === 'brothers') {
        this.isRelationship = true
        filt = 'bothers_and_sisters = true'
      }
      else if (filter === 'children') {
        this.isRelationship = true
        filt = 'child = true'
      }
      else {
        this.isRelationship = false
      }
      this.getRelationshipStatus(filt)

      this.checkMessage(item)
    },
    submitForm(check) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let vm = this
          vm.loading = true

          if (vm.form.id != undefined) vm.checkEdit = true
          var action = 'store/save'
          if (vm.checkEdit) {
            action = 'store/edit'
          }
          vm.$Progress.start()
          vm.$store.dispatch(action, { 'url': vm.url, 'data': vm.form })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                vm.form.id = response.data.resource[0].id
                vm.form.imms_uuid = response.data.resource[0].imms_uuid
              }

              vm.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              vm.$Progress.finish()
              vm.loading = false

              if (check != undefined) {
                vm.getMembers()
                vm.visible = false
              }
            })
            .catch(err => {
              vm.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              vm.$Progress.fail()
              vm.loading = false
              vm.visible = false
            })
        }
        else {
          return false
        }
      })
    },
    async deleteItem(item) {
      this.$confirm("Are you sure to delete this item?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(async() => {
        try {
          if (!item.imms_uuid) {
            this.$store.dispatch('toaster/add', { text: `Cannot delete item. Please contact supporting team!`, type: 'error' })
            return
          }
          await this.$api.delete(`/imms/application_family_members/${item.imms_uuid}`)
          this.getMembers()
          this.$store.dispatch('toaster/add', { text: 'Deleted', type: 'success' })
        }
        catch (err) {
          console.error(err);
          this.$store.dispatch('toaster/add', { text: `Cannot delete item. Please contact supporting team!`, type: 'error' })
        }
      })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Canceled"
          });
        });
    }
  }
}
</script>

<style>
.addinional-family-member .el-collapse-item__header{
  position: relative;
  width: 100%;
  overflow: hidden;
}
.addinional-family-member .el-collapse-item__arrow{
  line-height: 24px;
}
</style>
