<template>
  <div>
    <el-form v-if="hideSection" :model="form" ref="form" label-position="top">
      <v-card class="mt-3">
        <v-card-title>
          <span>Employer Details</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
            {{sectionInfoBox}}
          </v-alert>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'company_name')">
              <el-form-item label="Company Legal Name">
                <el-input v-model="form.company_name" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'operating')">
              <el-form-item label="Operating as (if applicable)">
                <el-input v-model="form.operating" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'officer_primary_name')">
              <el-form-item label="Name of primary authorized signing officer">
                <el-input v-model="form.officer_primary_name" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'officer_secondary_name')">
              <el-form-item label="Name of secondary authorized signing officer">
                <el-input v-model="form.officer_secondary_name" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'position')">
              <el-form-item label="Position within company">
                <el-input v-model="form.position" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'position_applicable')">
              <el-form-item label="Position within company (if applicable)">
                <el-input v-model="form.position_applicable" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'telephone')">
              <el-form-item label="Telephone">
                <el-input v-model="form.telephone" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'email')">
              <el-form-item label="Email">
                <el-input type="email" v-model="form.email" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'naics')">
              <el-form-item label="Two-digit code(s) of business sector under the North American Industry Classification System (NAICS)">
                <el-input v-model="form.naics" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'taxation_number')">
              <el-form-item label="Revenue Canada Taxation Number">
                <el-input v-model="form.taxation_number" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'corporate_number')">
              <el-form-item label="Corporate Registration number [e.g., Newfoundland and Labrador Registry of Deeds Number]">
                <el-input v-model="form.corporate_number" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'website')">
              <el-form-item label="Company Website">
                <el-input v-model="form.website" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'mailing_address')">
              <el-form-item label="Mailing Address, including postal code">
                <el-input v-model="form.mailing_address" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'location')">
              <el-form-item label="Business Location, including postal code.">
                <el-input v-model="form.location" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'complaints')">
              <el-form-item label="Have there been any complaints, investigations or decision(s) against your company including, but not limited to decisions under: Provincial labour standards/Federal labour standards/Occupational Health and Safety/Human Rights/Immigration, Refugee and Protection Act (IRPA) or Immigration, Refugee and Protection Regulations (IRPR)?">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.complaints" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-3">
        <v-card-title>
          <span>Company Description</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'established_year')">
              <el-form-item label="Year the company established in the Province">
                <el-input v-model="form.established_year" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'active_operation_provice')">
              <el-form-item label="Number of years in continuous active operation in the Province">
                <el-input v-model="form.active_operation_provice" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'active_operation_management')">
              <el-form-item label="Number of years in continuous active operation under current management">
                <el-input v-model="form.active_operation_management" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'total_employees')">
              <el-form-item label="Total Number of Employees at time of application">
                <el-input v-model="form.total_employees" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'fulltime_employees')">
              <el-form-item label="Full Time Employees">
                <el-input v-model="form.fulltime_employees" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'parttime_employees')">
              <el-form-item label="Part Time Employees">
                <el-input v-model="form.parttime_employees" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'lmia_approved')">
              <el-form-item label="Does your company have an approved Labour Market Impact Assessment (LMIA)">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.lmia_approved" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'tfw_employ')">
              <el-form-item label="Does your company employ Temporary Foreign Workers with a valid LMIA-exempt work permit">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.tfw_employ" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <template v-if="form.tfw_employ == 'Yes'">
              <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'tfw_number')">
                <el-form-item label="If yes, provide the number of Temporary Foreign Workers with a valid work permit (not PRs/Canadians)">
                  <el-input v-model="form.tfw_number" @change="handleAutoSave(form)" />
                </el-form-item>
              </v-col>
            </template>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'lmia_number')">
              <el-form-item label="Number with LMIA">
                <el-input v-model="form.lmia_number" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'lmia_exempt_number')">
              <el-form-item label="Number of LMIA-exempt">
                <el-input v-model="form.lmia_exempt_number" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'purpose_describe')">
              <el-form-item label="Describe your company’s purpose and activities">
                <el-input type="textarea" v-model="form.purpose_describe" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'registered_business')">
              <el-form-item label="Is your business registered or operating from a residential address">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.registered_business" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <template v-if="form.registered_business == 'Yes'">
              <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'activities_describe')">
                <el-form-item label="If yes, please describe the primary activities taking place at this location">
                  <el-input type="textarea" v-model="form.activities_describe" @change="handleAutoSave(form)" />
                </el-form-item>
              </v-col>
            </template>
            <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'business')">
              <el-form-item label="Is your business or are your employees located in a temporary or shared (hub) space?">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.business" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <template v-if="form.business == 'Yes'">
              <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'business_describe')">
                <el-form-item label="If yes, please describe your plans to supervise the newcomer and foster a welcoming workplace. Also describe your business’ long term plans for workspace">
                  <el-input type="textarea" v-model="form.business_describe" @change="handleAutoSave(form)" />
                </el-form-item>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-3">
        <v-card-title>
          <span>Labour needs</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" v-if="dataValidation(form, 'position_number')">
              <el-form-item label="Do you know approximately how many positions you intend to fill through the pilot?">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.position_number" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item label="Please provide the number of expected hires in each of the following three years:" />
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'provide1')">
              <el-form-item :label="prevYearTitle">
                <el-input v-model="form.provide1" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'provide2')">
              <el-form-item :label="yearTitle">
                <el-input v-model="form.provide2" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'provide3')">
              <el-form-item :label="nextYearTitle">
                <el-input v-model="form.provide3" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'vacancies')">
              <el-form-item label="Are these vacancies a result of business expansion or are they currently unfilled positions within your organization?">
                <el-radio-group v-model="form.vacancies" @change="handleAutoSave(form)">
                  <el-radio label="Expansion">Expansion</el-radio>
                  <el-radio label="Current unfilled positions">Current unfilled positions</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'vacancies_explain')">
              <el-form-item label="Please explain">
                <el-input type="textarea" v-model="form.vacancies_explain" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'labout_gaps')">
              <el-form-item label="Describe the labour gaps in your company and why you are considering hiring one or more foreign national(s) through the Atlantic Immigration Pilot to address your labour needs">
                <el-input type="textarea" v-model="form.labout_gaps" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'recruit')">
              <el-form-item label="To date, what methods have you used to recruit for these positions?">
                <el-input type="textarea" v-model="form.recruit" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <v-divider class="mt-2 mb-2" />
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'job_title1')">
              <el-form-item label="Job Title">
                <el-input v-model="form.job_title1" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'location1')">
              <el-form-item label="Location of Employment">
                <el-input v-model="form.location1" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'noc1')">
              <el-form-item label="NOC">
                <el-input v-model="form.noc1" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'salary1')">
              <el-form-item label="Salary">
                <el-input v-model="form.salary1" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'position1')">
              <el-form-item label="Number of Positions">
                <el-input v-model="form.position1" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'start_date1')">
              <el-form-item label="Approximate Start Date">
                <el-date-picker v-model="form.start_date1" @change="handleAutoSave(form)" value-format="yyyy-MM-dd" v-on:click.native="checkDate($event)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <v-divider class="mt-2 mb-2" />
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'job_title2')">
              <el-form-item label="Job Title">
                <el-input v-model="form.job_title2" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'location2')">
              <el-form-item label="Location of Employment">
                <el-input v-model="form.location2" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'noc2')">
              <el-form-item label="NOC">
                <el-input v-model="form.noc2" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'salary2')">
              <el-form-item label="Salary">
                <el-input v-model="form.salary2" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'position2')">
              <el-form-item label="Number of Positions">
                <el-input v-model="form.position2" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'start_date2')">
              <el-form-item label="Approximate Start Date">
                <el-date-picker v-model="form.start_date2" @change="handleAutoSave(form)" value-format="yyyy-MM-dd" v-on:click.native="checkDate($event)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <v-divider class="mt-2 mb-2" />
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'job_title3')">
              <el-form-item label="Job Title">
                <el-input v-model="form.job_title3" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'location3')">
              <el-form-item label="Location of Employment">
                <el-input v-model="form.location3" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'noc3')">
              <el-form-item label="NOC">
                <el-input v-model="form.noc3" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'salary3')">
              <el-form-item label="Salary">
                <el-input v-model="form.salary3" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'position3')">
              <el-form-item label="Number of Positions">
                <el-input v-model="form.position3" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'start_date3')">
              <el-form-item label="Approximate Start Date">
                <el-date-picker v-model="form.start_date3" @change="handleAutoSave(form)" value-format="yyyy-MM-dd" v-on:click.native="checkDate($event)" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-3">
        <v-card-title>
          <span>Commitment to settlement supports</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'info_settlement')">
              <el-form-item label="Indicate the name and contact information of the Settlement Service Provider you contacted. If the preferred service provider in your community/region is not on the attached list, please provide details and explanation">
                <el-input type="textarea" v-model="form.info_settlement" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'info_shared')">
              <el-form-item label="Provide an overview of the information shared with you as well as your plan to support newcomers and foster a welcoming workplace.">
                <el-input type="textarea" v-model="form.info_shared" @change="handleAutoSave(form)" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-3">
        <v-card-title>
          <span>Employer declaration</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" v-if="dataValidation(form, 'declaration1')">
              <el-form-item label="Identify and hire qualified foreign nationals">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.declaration1" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'declaration2')">
              <el-form-item label="Apply for an endorsement from the Government of Newfoundland and Labrador for any foreign national I am prepared to hire under the Atlantic Immigration Pilot, and accept the terms and conditions of the endorsement application">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.declaration2" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'declaration3')">
              <el-form-item label="Provide a full-time non-seasonal genuine offer of employment to employees brought in to Atlantic Canada through this program.">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.declaration3" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'declaration4')">
              <el-form-item label="Cover the costs associated with a temporary work permit holder ’s return to their country of origin, where that individual would have applied for the Intermediate Skilled (NOC C) class and where this designation has been revoked and the Government of Newfoundland and Labrador is unable to find an the worker alternate employment.">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.declaration4" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'declaration5')">
              <el-form-item label="Report on the number of foreign nationals recruited under the Atlantic Immigration Pilot, their employment status, details on their position/wage/hours, and the settlement supports provided to them for up to three years after you hired them, or the duration of their employment if less than three years.">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.declaration5" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'declaration6')">
              <el-form-item label="Comply with the IRPA, IRPR, the Province’s labour standards and Occupational Health and Safety legislation as well as applicable Federal labour legislation for federally regulated companies.">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.declaration6" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'declaration7')">
              <el-form-item label="Fulfil my obligations to partner with an immigrant settlement service provider organization, ensure that the workplace is welcoming to newcomers, including providing workplace cultural sensitivity and awareness training if an immigrant settlement service provider organization has deemed it appropriate or necessary, and provide support and pay for settlement services for newcomer employees and their family members as stated in the commitment to settlement supports section above.">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.declaration7" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'declaration8')">
              <el-form-item label="Provide further information as requested by the Government of Newfoundland and Labrador.">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.declaration8" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'declaration9')">
              <el-form-item label="I understand that any violation of IRPA or IRPR will result in my employer designation being revoked.">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.declaration9" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'declaration10')">
              <el-form-item label="I understand that any non-compliance with any federal or provincial legislation, or with the terms and conditions of the Atlantic Immigration Pilot may result in probation, suspension or termination from the Atlantic Immigration Pilot.">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.declaration10" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'declaration11')">
              <el-form-item label="I agree to immediately notify the Government of Newfoundland and Labrador of any complaint, investigation or decision under IRPA, applicable labour codes, employment or health and safety standards or non-compliance with the terms of the Atlantic Immigration Program.">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.declaration11" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'declaration12')">
              <el-form-item label="I agree to immediately notify the Government of Newfoundland and Labrador, in writing, if there are any changes in the ownership structure of the company, if the company is sold or if it closes, permanently or temporarily and if any changes occur with the position offered.">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.declaration12" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'declaration13')">
              <el-form-item label="I agree to immediately notify the Government of Newfoundland and Labrador, in writing if the candidate quits, is terminated or is laid off from their position.">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.declaration13" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'declaration14')">
              <el-form-item label="I declare that I will meet the above commitments outlined in this employer declaration, and that the information given in this form is truthful, complete and correct.">
                <el-radio-group @change="handleAutoSave(form)" v-model="form.declaration14" class="mt-1 text-left">
                  <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </el-form>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  mixins: [autosave],
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        sec_org_id: this.$orgId,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID)
      },
      yearTitle: null,
      prevYearTitle: null,
      nextYearTitle: null,
      url: 'imms_table/application_aipp_employer',
      getUrl: 'imms_table/application_aipp_employer?application_imms_uuid=' + this.$route.params.id
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      var d = new Date();
      var year = d.getFullYear();
      this.yearTitle = year.toString()
      this.prevYearTitle = (year - 1).toString()
      this.nextYearTitle = (year + 1).toString()
      this.fetch().then(data => {
        if (data.items != undefined && data.items.length > 0) {
          if (this.isDataValidation) {
            this.checkRow(data.items[0])
          }
          this.form = data.items[0]
        }
        else {
          if (this.isDataValidation) {
            this.checkRow(this.form)
          }
        }
      })
    }
  }
}
</script>
