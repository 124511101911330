<template>
  <div v-if="hideSection">
    <v-card class="mt-3 bquestion">
      <v-card-title>
        <span>{{ $t('title.application_background_question_sinp') }}</span>
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3">
          {{ $t('infobox.application_background_question_sinp') }}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="form">
          <div>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'a')">
              <v-col cols="12">
                <el-form-item prop="a" :label="'a. ' + $t('application_background_question_sinp.a')">
                  <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a" class="mt-2">
                    <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                    <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'b')">
              <v-col cols="12">
                <el-form-item prop="b" :label="'b. ' + $t('application_background_question_sinp.b')">
                  <el-radio-group @change="autoSaveSubmit(form)" v-model="form.b" class="mt-2">
                    <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                    <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'c')">
              <v-col cols="12">
                <el-form-item prop="c" :label="'c. ' + $t('application_background_question_sinp.c')">
                  <el-radio-group @change="autoSaveSubmit(form)" v-model="form.c" class="mt-2">
                    <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                    <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'd')">
              <v-col cols="12">
                <el-form-item prop="d" :label="'d. ' + $t('application_background_question_sinp.d')">
                  <el-radio-group @change="autoSaveSubmit(form)" v-model="form.d" class="mt-2">
                    <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                    <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'e')">
              <v-col cols="12">
                <el-form-item prop="e" :label="'e. ' + $t('application_background_question_sinp.e')">
                  <el-radio-group @change="autoSaveSubmit(form)" v-model="form.e" class="mt-2">
                    <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                    <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'f')">
              <v-col cols="12">
                <el-form-item prop="f" :label="'f. ' + $t('application_background_question_sinp.f')">
                  <el-radio-group @change="autoSaveSubmit(form)" v-model="form.f" class="mt-2">
                    <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                    <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'g')">
              <v-col cols="12">
                <el-form-item prop="g" :label="'g. ' + $t('application_background_question_sinp.g')">
                  <el-radio-group @change="autoSaveSubmit(form)" v-model="form.g" class="mt-2">
                    <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                    <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'h')">
              <v-col cols="12">
                <el-form-item prop="h" :label="'h. ' + $t('application_background_question_sinp.h')">
                  <el-radio-group @change="autoSaveSubmit(form)" v-model="form.h" class="mt-2">
                    <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                    <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'i')">
              <v-col cols="12">
                <el-form-item prop="i" :label="'i. ' + $t('application_background_question_sinp.i')">
                  <el-radio-group @change="autoSaveSubmit(form)" v-model="form.i" class="mt-2">
                    <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                    <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'j')">
              <v-col cols="12">
                <el-form-item prop="j" :label="'j. ' + $t('application_background_question_sinp.j')">
                  <el-radio-group @change="autoSaveSubmit(form)" v-model="form.j" class="mt-2">
                    <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                    <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense class="radio-parent" v-if="dataValidation(form, 'k')">
              <v-col cols="12">
                <el-form-item prop="k" :label="'k. ' + $t('application_background_question_sinp.k')">
                  <el-radio-group @change="autoSaveSubmit(form)" v-model="form.k" class="mt-2">
                    <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                    <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </v-row>

          </div>

          <v-row dense class="radio-parent" v-if="dataValidation(form, 'details')">
            <v-col cols="12" v-if="isDetail">
              <el-form-item class="mt-2" :label="$t('application_background_question_sinp.details')" prop="details">
                <el-input @change="autoSaveSubmit(form)" v-model="form.details" style="width: 100%" type="textarea" :rows="4" :class="isClass" />
                <strong>Please enter {{ $t('application_background_question_sinp.details') }} data.</strong>
              </el-form-item>
            </v-col>
          </v-row>
          <template v-if="isOINP">
            <v-row dense v-if="dataValidation(form, 'oinp_bg_1')">
              <v-col cols="12">
                <el-form-item prop="oinp_bg_1" :label="$t('application_background_question_sinp.oinp_bg_1')">
                  <el-radio-group @change="autoSaveSubmit(form)" v-model="form.oinp_bg_1" class="mt-2">
                    <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                    <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense v-if="dataValidation(form, 'oinp_bg_2')">
              <v-col cols="12">
                <el-form-item class="mt-2" :label="$t('application_background_question_sinp.oinp_bg_2')" prop="oinp_bg_2">
                  <el-input @change="autoSaveSubmit(form)" v-model="form.oinp_bg_2" />
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense v-if="dataValidation(form, 'date_application_submitted_1')">
              <v-col cols="12">
                <el-form-item class="mt-2" :label="$t('application_background_question_sinp.date_application_submitted_1')" prop="date_application_submitted_1">
                  <el-date-picker value-format="dd-MM-yyyy" format="dd-MM-yyyy" @change="autoSaveSubmit(form)" v-model="form.date_application_submitted_1"></el-date-picker>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense v-if="dataValidation(form, 'oinp_bg_3')">
              <v-col cols="12">
                <el-form-item prop="oinp_bg_1" :label="$t('application_background_question_sinp.oinp_bg_3')">
                  <el-radio-group @change="autoSaveSubmit(form)" v-model="form.oinp_bg_3" class="mt-2">
                    <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                    <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense v-if="dataValidation(form, 'oinp_bg_4')">
              <v-col cols="12">
                <el-form-item class="mt-2" :label="$t('application_background_question_sinp.oinp_bg_4')" prop="oinp_bg_4">
                  <el-input @change="autoSaveSubmit(form)" v-model="form.oinp_bg_4" />
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense v-if="dataValidation(form, 'date_application_submitted_2')">
              <v-col cols="12">
                <el-form-item class="mt-2" :label="$t('application_background_question_sinp.date_application_submitted_2')" prop="date_application_submitted_2">
                  <el-date-picker value-format="dd-MM-yyyy" format="dd-MM-yyyy" @change="autoSaveSubmit(form)" v-model="form.date_application_submitted_2"></el-date-picker>
                </el-form-item>
              </v-col>
            </v-row>

          </template>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" @click.native="autoSaveSubmit(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    },
    isOINP: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        a: null,
        b: null,
        c: null,
        d: null,
        e: null,
        f: null,
        g: null,
        h: null,
        i: null,
        j: null,
        k: null,
        details: '',
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        oinp_bg_1: null,
        oinp_bg_2: null,
        date_application_submitted_1: null,
        oinp_bg_3: null,
        oinp_bg_4: null,
        date_application_submitted_2: null
      },
      loading: false,
      isClass: '',
      url: 'imms_table/application_background_question_sinp',
      getUrl: `table/application_background_question_sinp?filter=member_uid=${this.memberUID}`
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'sidebar/isMobile'
    }),
    isDetail: function() {
      let check = this.form.a == 'Yes' || this.form.b == 'Yes' || this.form.c == 'Yes' || this.form.d == 'Yes' ||
                  this.form.e == 'Yes' || this.form.f == 'Yes' || this.form.g == 'Yes' || this.form.h == 'Yes' ||
                  this.form.i == 'Yes' || this.form.j == 'Yes' || this.form.k == 'Yes' ? true : false

      return check
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `${this.getUrl}`)
        .then(response => {
          if (response.resource.length > 0) {
            this.form = response.resource[0]

            if (this.isDataValidation) {
              this.checkRow(response.resource[0])
            }
          }
          else {
            if (this.isDataValidation) {
              this.checkRow(this.form)
            }
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    checkRow(data) {
      var form = {
        a: null,
        b: null,
        c: null,
        d: null,
        e: null,
        f: null,
        g: null,
        h: null,
        i: null,
        j: null,
        k: null,
        details: null,
        oinp_bg_1: null,
        oinp_bg_2: null,
        date_application_submitted_1: null,
        oinp_bg_3: null,
        oinp_bg_4: null,
        date_application_submitted_2: null
      }

      if (!this.isOINP) {
        delete form.oinp_bg_1
        delete form.oinp_bg_2
        delete form.date_application_submitted_1
        delete form.oinp_bg_3
        delete form.oinp_bg_4
        delete form.date_application_submitted_2
      }

      if (!this.isDetail) {
        delete form.details
      }

      this.checkItem(data, form)
    }
  }
}
</script>
