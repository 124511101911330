<template>
  <v-card class="mt-3">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()">Add</v-btn>
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{ sectionInfoBox }}
      </v-alert>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item :name="i+1" v-for="(item,i) in items" :key="i" :id="'row' + form.id">
          <template slot="title">
            <span class="el-col-title">{{item.f_weeks}}</span>
          </template>
            <el-form :model="form" ref="form" label-position="top" class="mt-3 form">
              <v-card>
                <v-card-title>
                  <span>Nova Scotia - Mandatory Recruitment Activities</span>
                  <v-spacer />
                </v-card-title>
                <v-card-text class="p-n">
                  <v-row dense class="medium-space">
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Was the applicant’s work permit issued based on a valid LMIA?" prop="lmia_based">
                        <el-select :id="'lmia_based' + item.id" v-model="item.lmia_based" style="width: 100%;">
                          <el-option value="Yes">Yes</el-option>
                          <el-option value="No">No</el-option>
                        </el-select>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Is the position offered and/or the applicant exempt from an LMIA?" prop="lmia_exempt">
                        <el-select :id="'lmia_exempt' + item.id" v-model="item.lmia_exempt" style="width: 100%;">
                          <el-option value="Yes">Yes</el-option>
                          <el-option value="No">No</el-option>
                        </el-select>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="How long was this position vacant? (weeks)" prop="f_weeks">
                        <el-input :id="'f_weeks' + item.id" type="number" v-model="item.f_weeks" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="How long was this position vacant? (months)" prop="f_months">
                        <el-input :id="'f_months' + item.id" type="number" v-model="item.f_months" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                  </v-row>
                  <v-row dense class="medium-space">
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="How long was this position vacant? (years)" prop="f_years">
                        <el-input :id="'f_years' + item.id" type="number" v-model="item.f_years" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Was the position advertised?" prop="advert">
                        <el-select :id="'advert' + item.id" v-model="item.advert" style="width: 100%;">
                          <el-option value="Locally">Locally</el-option>
                          <el-option value="Nationally">Nationally</el-option>
                        </el-select>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="If internet postings, provide" prop="internetpost">
                        <el-input :id="'internetpost' + item.id" v-model="item.internetpost" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Date posted" prop="dateposted">
                        <el-date-picker :id="'dateposted' + item.id" value-format="yyyy-MM-dd" v-model="item.dateposted" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </v-col>
                  </v-row>
                  <v-row dense class="medium-space">
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Date deleted" prop="delete_date">
                        <el-date-picker :id="'delete_date' + item.id" value-format="yyyy-MM-dd" v-model="item.delete_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="Advertisement #" prop="advertnumber">
                        <el-input :id="'advertnumber' + item.id" v-model="item.advertnumber" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <el-form-item label="# of responses" prop="no_responses">
                        <el-input :id="'no_responses' + item.id" type="number" v-model="item.no_responses" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                  </v-row>
                  <v-row dense class="medium-space">
                    <v-col cols="12">
                      <el-form-item label="State why the position could not be filled by a Canadian citizen or permanent resident
                                  (including but notlimited to: number of applicants, number of those interviewed)." prop="reason_position">
                        <el-input :id="'reason_position' + item.id" type="textarea" :autosize="{ minRows: 4, maxRows: 5}" v-model="item.reason_position" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12">
                      <el-form-item label="If you have not recruited for this position, explain why ?" prop="explain">
                        <el-input :id="'explain' + item.id" type="textarea" :autosize="{ minRows: 4, maxRows: 5}" v-model="item.explain" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-text>
                  <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm(i,form)">
                    {{ $t('global.save') }}
                  </v-btn>
                  <v-btn color="error" small @click.native="deleteOpen(form,i)">Delete</v-btn>
                </v-card-text>
              </v-card>
            </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        lmia_based: null,
        lmia_exempt: null,
        f_weeks: null,
        f_months: null,
        f_years: null,
        advert: null,
        internetpost: null,
        dateposted: null,
        delete_date: null,
        advertnumber: null,
        no_responses: null,
        reason_position: null,
        explain: null
      },
      activeNames: -1,
      url: 'imms_table/prov_novascotia',
      state: 'navoscotia',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/prov_novascotia?filter=member_uid=${this.memberUID}`
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) this.checkItemsFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.form = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        lmia_based: null,
        lmia_exempt: null,
        f_weeks: null,
        f_months: null,
        f_years: null,
        advert: null,
        internetpost: null,
        dateposted: null,
        delete_date: null,
        advertnumber: null,
        no_responses: null,
        reason_position: null,
        explain: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.form)
      var len = this.items.length
      this.activeNames = len
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true

          if (formObj.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>
