<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ $t('title.imm_1294_employment_history') }}</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm();">{{ $t('global.add') }}</v-btn>
    </v-card-title>
    <v-card-text>
      <v-alert type="info" border="left" dense class="mb-3" v-if="$t('infobox.imm_1294_employment_history') !== '' && !isAlertHide">
        {{ $t('infobox.imm_1294_employment_history') }}
      </v-alert>
      <v-alert v-if="isCheck" type="error" border="left" dense class="mb-3">
        {{ $t('global.between_dates') }}
      </v-alert>

      <v-row dense class="customCollapseHeader">
        <v-col cols="6" sm="1">From Date</v-col>
        <v-col cols="6" sm="1">To Date</v-col>
        <v-col class="d-none d-sm-flex" sm="2">Activity Type</v-col>
        <v-col class="d-none d-sm-flex" sm="2">Job title</v-col>
        <v-col class="d-none d-sm-flex" sm="2">Country</v-col>
        <v-col class="d-none d-sm-flex" sm="2">City</v-col>
        <v-col class="d-none d-sm-flex" sm="2">Company/Facility Name</v-col>
      </v-row>
      <el-collapse v-model="activeNames" accordion>
        <template v-for="(form,i) in items">
          <el-collapse-item :name="i+1" :key="i" :id="'row' + form.id">
            <template slot="title">
              <div v-on:click="getProvince(form)" class="customHead">
                <v-row dense>
                  <v-col cols="6" sm="1">
                    <el-tag type="primary" v-if="form.from_date !== null && form.from_date != undefined">{{ form.from_date | month }}</el-tag>
                  </v-col>
                  <v-col cols="6" sm="1">
                    <el-tag type="success" v-if="form.current">Current</el-tag>
                    <el-tag type="success" v-else-if="form.to_date !== null && form.to_date != undefined">{{ form.to_date | month }}</el-tag>
                  </v-col>
                  <v-col class="d-none d-sm-flex" sm="2">{{ form.activity }}</v-col>
                  <v-col class="d-none d-sm-flex" sm="2">{{ form.job_title }}</v-col>
                  <v-col class="d-none d-sm-flex" sm="2"><div class="pl-2 pr-2">{{ form.country_name }}</div></v-col>
                  <v-col class="d-none d-sm-flex" sm="2"><div class="pl-2 pr-2">{{ form.city }}</div></v-col>
                  <v-col class="d-none d-sm-flex" sm="2"><div class="pl-3 pr-2">{{ form.name_of_employer }}</div></v-col>
                </v-row>
              </div>
            </template>
            <el-form :model="form" ref="form" label-position="top">
              <v-row dense>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'from_date')">
                  <el-form-item :label="$t('imm_1294_employment_history.from_date')" prop="from_date">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="form.from_date" :picker-options="datePickerOptions" v-on:click.native="checkDate($event)" @change="handleAutoSave(i, form)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'to_date')">
                  <el-form-item :label="$t('imm_1294_employment_history.to_date')" prop="to_date" class="current">
                    <el-checkbox v-model="form.current">Current</el-checkbox>
                    <el-date-picker v-if="!form.current" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.to_date" v-on:click.native="checkDate($event)" @change="handleAutoSave(i, form)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'country_id')">
                  <el-form-item :label="$t('imm_1294_employment_history.country_id')" prop="country_id">
                    <div class="el-select native-select">
                      <div class="el-input">
                        <select @change="getProvince(form); handleAutoSave(i, form)" v-model="form.country_id" placeholder="Select" class="el-input__inner">
                          <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                        </select>
                      </div>
                    </div>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'province') && selectProvine">
                  <el-form-item :label="$t('imm_1294_employment_history.province')" prop="province">
                    <div class="el-select native-select">
                      <div class="el-input">
                        <select v-model="form.province" @change="handleAutoSave(i, form)" placeholder="Select" class="el-input__inner">
                          <option v-for="(item, index) in province" v-bind:key="index" :value="item.province_name" :label="item.province_name">{{ item.province_name}}</option>
                        </select>
                      </div>
                    </div>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'city')">
                  <el-form-item :label="$t('imm_1294_employment_history.city')" prop="city">
                    <el-input v-model="form.city" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <template v-if="isOINP || isEE">
                  <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'street_address')">
                    <el-form-item label="Street Address" prop="street_address">
                      <el-input v-model="form.street_address" @change="handleAutoSave(i, form)" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'postal_code')">
                    <el-form-item label="Postal Code" prop="postal_code">
                      <el-input v-model="form.postal_code" @change="handleAutoSave(i, form)" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                </template>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'name_of_employer')">
                  <el-form-item :label="$t('imm_1294_employment_history.name_of_employer')" prop="name_of_employer">
                    <el-input v-model="form.name_of_employer" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'activity')">
                  <el-form-item prop="activity">
                  <label>{{ $t('imm_1294_employment_history.activity') }}
                      <el-tooltip placement="top">
                        <div slot="content"> {{ $t('global.activity_type_popup') }}</div>
                        <i class="el-icon-question icon-doc"></i>
                      </el-tooltip>
                    <el-input v-model="form.activity" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </label>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'job_title')">
                  <div class="el-form-item">
                    <label for="job_title" class="el-form-item__label">
                      {{ $t('imm_1294_employment_history.job_title') }}
                    </label>
                    <div class="el-form-item__content">
                      <el-input v-model="form.job_title" @change="handleAutoSave(i, form)" style="width: 100%" /></div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'hour_per_week')">
                  <el-form-item prop="hour_per_week" label="Hours per week" v-if="isOINP || isEE">
                    <el-input v-model="form.hour_per_week" @change="handleAutoSave(i, form)" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'status')">
                  <el-form-item prop="status" :label="$t('imm_1294_employment_history.status')" v-if="sectionSlug === 'EmploymentHistory'">
                    <el-select v-model="form.status" style="width: 100%" @change="handleAutoSave(i, form)">
                      <el-option label="Citizen" value="Citizen"></el-option>
                      <el-option label="Permanent Resident" value="Permanent Resident"></el-option>
                      <el-option label="Student" value="Student"></el-option>
                      <el-option label="Visitor" value="Visitor"></el-option>
                      <el-option label="Worker" value="Worker"></el-option>
                      <el-option label="Other" value="Other"></el-option>
                    </el-select>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'other')">
                  <el-form-item prop="other" :label="$t('imm_1294_employment_history.other')" v-if="sectionSlug == 'EmploymentHistory' && form.status == 'Other'">
                    <el-input v-model="form.other" @change="handleAutoSave(i, form)" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'self_employed_work')">
                  <el-form-item prop="self_employed_work" v-if="isOINP">
                    <el-checkbox v-model="form.self_employed_work" @change="handleAutoSave(i, form)">Self-employed work</el-checkbox>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'work_permit')">
                  <el-form-item prop="work_permit" v-if="isOINP">
                    <el-checkbox v-model="form.work_permit" @change="handleAutoSave(i, form)">Were you issued a valid work permit for this job?</el-checkbox>
                  </el-form-item>
                </v-col>
                <v-col cols="12">
                  <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(i,form)">{{ $t('global.save') }}</v-btn>
                  <v-btn :loading="autoSave" :disabled="autoSave" color="error" small @click.native="deleteItem(form,i)">{{ $t('global.delete') }}</v-btn>
                </v-col>
              </v-row>
            </el-form>
          </el-collapse-item>
        </template>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    },
    isOINP: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        country_id: '',
        city: '',
        province: '',
        street_address: null,
        postal_code: null,
        name_of_employer: '',
        activity: '',
        from_date: null,
        to_date: null,
        status: null,
        other: null,
        job_title: null,
        current: false,
        hour_per_week: null,
        self_employed_work: null,
        work_permit: null
      },
      activeNames: -1,
      selectProvine: false,
      url: 'imms_table/imm_1294_employment_history',
      deleteUrl: 'imms/imm_1294_employment_history',
      state: 'employment',
      loading: false,
      items: [],
      secOrgID: this.$orgId,
      isEE: false,
      appTypeId: this.$route.params.apptypeId
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      province: 'other/province'
    }),
    isCheck: function() {
      var from_date = null
      var alert = false

      if (!this.isDataValidation) {
        this.items.forEach(function(item, index) {
          if (!item.current) {
            if (item.to_date && item.from_date) {
              var a = moment(from_date)
              var b = moment(item.to_date)
              var date = a.diff(b, 'days')
              if (date > 1) {
                alert = true
              }
            }
            else {
              alert = true
            }
          }
          from_date = item.from_date
        })
      }

      return alert
    },
    isAlertHide: function() {
      return this.$route.name == 'section_comparison' ? true : false
    }
  },
  mounted() {
    this.isEE = parseInt(this.appTypeId) == 7 || parseInt(this.appTypeId) == 6 ? true : false
    this.fetchData()
  },
  methods: {
    getProvince(item) {
      let idx = this.country.map(p => p.id).indexOf(item.country_id)

      if (idx != -1) {
        item.country_name = this.country[idx].country_name

        if (42 == item.country_id || 239 == item.country_id) {
          this.selectProvine = true
          this.phoneMasked = true
          this.$store.dispatch('other/getProvince', item.country_id)
        }
        else {
          this.phoneMasked = false
          this.selectProvine = false
        }
      }
    },
    async fetchData() {
      try {
        this.$Progress.start()
        this.validationItems = []
        let res = await this.$api.get(`/table/imm_1294_employment_view?filter=member_uid=${this.memberUID}&order=from_date DESC, to_date DESC`)
        let vm = this
        if (vm.isDataValidation) {
          let datas = []

          res.data.resource.forEach(function(item) {
            var form = {
              country_id: '',
              city: '',
              name_of_employer: '',
              activity: '',
              province: '',
              from_date: null,
              to_date: null,
              status: null,
              other: null,
              job_title: null,
              street_address: null,
              postal_code: null
            }

            if (item.country_id !== 42 || item.country_id !== 239) delete form.province
            if (vm.sectionSlug !== 'EmploymentHistory') delete form.status
            if (item.status !== 'Other') delete form.other
            if (item.current) delete form.to_date
            if (!vm.isOINP) {
              delete form.hour_per_week
              delete form.self_employed_work
              delete form.work_permit
              delete form.street_address
              delete form.postal_code
            }

            var obj = vm.checkFormItems(item, form)

            if (obj != 'empty') {
              datas.push(item)
            }
          })

          if (res.data.resource.length == 0) {
            vm.hideSection = false
          }
          else {
            vm.hideSection = vm.validationItems.length == 0 ? false : true
          }
          vm.items = datas
        }
        else {
          vm.items = res.data.resource
        }
      }
      catch (e) {
        this.$Progress.fail()
      }
      finally {
        this.$Progress.finish()
      }
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        country_id: '',
        city: '',
        name_of_employer: '',
        activity: '',
        province: '',
        from_date: null,
        to_date: null,
        status: null,
        other: null,
        job_title: null,
        current: false,
        hour_per_week: null,
        self_employed_work: null,
        work_permit: null,
        street_address: null,
        postal_code: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>

<style>
  .icon-doc{
    font-size: 16px;
    color: #1976C6;
    margin-right: 5px;
  }
  .current .el-checkbox{
    position: absolute;
    top: -40px;
    left: 40px;
  }
</style>