<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <v-row dense class="medium-space">
        <v-col cols="12" v-if="dataValidation(form, 'language_test_completed')">
          <el-form-item prop="language_test_completed" label="Have you completed a valid language proficiency test within the past two years?">
            <el-radio-group v-model="form.language_test_completed" @change="saveIMM(form)" style="width: 100%">
              <el-radio label="Yes">Yes</el-radio>
              <el-radio label="No">No</el-radio>
            </el-radio-group>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'communicate_english_french')">
          <el-form-item :label="$t('imm_0008.communicate_english_french')" prop="communicate_english_french">
            <el-select @change="saveIMM(form)" v-model="form.communicate_english_french" placeholder="Select" style="width: 100%;">
              <el-option value="English">English</el-option>
              <el-option value="French">French</el-option>
              <el-option :value="$t('global.both')">{{ $t('global.both') }}</el-option>
              <el-option :value="$t('global.neither')">{{ $t('global.neither') }}</el-option>
            </el-select>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'language_test')">
          <el-form-item :label="$t('imm_0008.language_test')" prop="language_test">
            <el-select @change="saveIMM(form)" v-model="form.language_test" placeholder="Select" style="width: 100%;">
              <el-option value="IELTS" label="IELTS"></el-option>
              <el-option value="CELPIP" label="CELPIP"></el-option>
              <el-option value="TEF" label="TEF"></el-option>
              <el-option value="No" label="No"></el-option>
            </el-select>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'date_test')">
          <el-form-item prop="date_test" label="Date of signing">
            <el-date-picker v-model="form.date_test" @change="saveIMM(form)" value-format="yyyy-MM-dd" v-on:click.native="checkDate($event)" style="width: 100%"></el-date-picker>
          </el-form-item>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6" md="3">
          <el-form-item :label="$t('application_official_languages.listening')" prop="listening">
            <el-input type="number" v-model="form.listening" @change="saveIMM(form)" style="width: 100%" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <el-form-item :label="$t('application_official_languages.reading')" prop="reading">
            <el-input type="number" v-model="form.reading" @change="saveIMM(form)" style="width: 100%" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <el-form-item :label="$t('application_official_languages.writing')" prop="writing">
            <el-input type="number" v-model="form.writing" @change="saveIMM(form)" style="width: 100%" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <el-form-item :label="$t('application_official_languages.speaking')" prop="speaking">
            <el-input type="number" v-model="form.speaking" @change="saveIMM(form)" style="width: 100%" />
          </el-form-item>
        </v-col>
      </v-row>
      <v-row dense v-if="isBCPNP">
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'language_validation_number')">
          <el-form-item prop="language_validation_number" label="Registration number">
            <el-input v-model="form.language_validation_number" @change="saveIMM(form)" style="width: 100%" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" v-if="dataValidation(form, 'language_pin_number')">
          <el-form-item prop="language_pin_number" label="PIN Number">
            <el-input v-model="form.language_pin_number" @change="saveIMM(form)" style="width: 100%" />
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    },
    isOINP: {
      type: Boolean
    }
  },
  data() {
    return {
      appTypeId: this.$route.params.apptypeId,
      isBCPNP: false
    }
  },
  mounted() {
    this.isBCPNP = parseInt(this.appTypeId) == 11 || parseInt(this.appTypeId) == 76 ? true : false
    if (this.isDataValidation) {
      var item = {
        communicate_english_french: null,
        language_test: null,
        listening: null,
        reading: null,
        writing: null,
        speaking: null,
        language_validation_number: null,
        language_pin_number: null,
        language_test_completed: null
      }

      this.checkItem(this.form, item)
    }
  }
}

</script>
