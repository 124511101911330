import api from "./../plugins/axios";

let url = "table/application_citizenship_adult";

export const state = {
  item: {}
};

export const getters = {
  get: (state) => state.item
};

export const actions = {
  get({ commit }, params) {
    return api.get(url + params).then(
      (response) => {
        var datas = response.data.resource;
        commit("GET_DATA", datas);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATA");
        return Promise.reject(err);
      }
    );
  },
  save({ commit }, data) {
    return api.post(url, { resource: data }).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATA");
        return Promise.reject(err);
      }
    );
  },
  edit({ commit }, data) {
    let url = `table/application_citizenship_adult${data.id}`
    if (data.imms_uuid !== undefined && data.imms_uuid !== null) {
      url = `imms_table/application_citizenship_adult/${data.imms_uuid}`
    }
    return api.put(url, data).then(
      (response) => {
        commit("UPDATE_DATA", data);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATA");
        return Promise.reject(err);
      }
    );
  }
};

export const mutations = {
  GET_DATA(state, data) {
    state.item = data[0] || {};
  },
  UPDATE_DATA(state, data) {
    state.item = data;
  },
  FAILURE_DATA(state) {
    state.item = {};
  }
};
