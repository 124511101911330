<template>
  <div class="mt-3" v-if="hideSection">
    <v-card>
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text class="p-n">
        <table class="v-table" style="width:100%">
          <thead>
            <tr>
              <th>Last name</th>
              <th>First name</th>
              <th>Sex</th>
              <th width="140">Date of Birth</th>
              <th width="140">Relationship to you</th>
              <th width="140">Passport Number</th>
              <th width="140">Passport Expiry</th>
              <th width="30">
                <v-btn color="green" small fab @click.native="add()">
                  <v-icon class="white--text">add</v-icon>
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in items" :key="i" :id="'row' + item.id">
              <td class="pa-1">
                <el-input v-if="dataValidationItems(item, 'lname')" v-model="item.lname" style="width: 100%" @change="handleAutoSaveItems(item)" />
              </td>
              <td class="pa-1">
                <el-input v-if="dataValidationItems(item, 'fname')" v-model="item.fname" style="width: 100%" @change="handleAutoSaveItems(item)" />
              </td>
              <td class="pa-1">
                <el-radio-group v-if="dataValidationItems(item, 'gender')" v-model="item.gender" style="width: 100%" @change="handleAutoSaveItems(item)">
                  <el-radio value="Female" label="Female">Female</el-radio>
                  <el-radio value="Male" label="Male">Male</el-radio>
                </el-radio-group>
              </td>
              <td class="pa-1">
                <el-date-picker
                  v-if="dataValidationItems(item, 'birth_date')"
                  value-format="yyyy-MM-dd"
                  v-model="item.birth_date"
                  v-on:click.native="checkDate($event)"
                  @change="handleAutoSaveItems(item)"
                  style="width: 100%;">
                </el-date-picker>
              </td>
              <td class="pa-1">
                <el-select v-if="dataValidationItems(item, 'relationship')" v-model="item.relationship" style="width: 100%" @change="handleAutoSaveItems(item)">
                  <el-option v-for="(rel, index) in relationshipStatus" v-bind:key="index" :value="rel.name" :label="rel.name">{{ rel.name }}</el-option>
                </el-select>
              </td>
              <td class="pa-1">
                <el-input v-if="dataValidationItems(item, 'passport_number')" v-model="item.passport_number" style="width: 100%" @change="handleAutoSaveItems(item)" />
              </td>
              <td class="pa-1">
                <el-date-picker
                  v-if="dataValidationItems(item, 'passport_expiry')"
                  value-format="yyyy-MM-dd"
                  v-model="item.passport_expiry"
                  v-on:click.native="checkDate($event)"
                  @change="handleAutoSaveItems(item)"
                  style="width: 100%;">
                </el-date-picker>
              </td>
              <td class="pa-1 text-center">
                <v-btn color="pink" small fab @click.native="deleteOpen(item, i)">
                  <v-icon class="white--text">clear</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="saveAll()">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import autosave from '@/mixins/lmia_autosave'

export default {
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  mixins: [autosave],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        fname: null,
        lname: null,
        birth_date: null,
        relationship: null,
        gender: null,
        passport_number: null,
        passport_expiry: null
      },
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        fname: null,
        lname: null,
        birth_date: null,
        relationship: null,
        gender: null,
        passport_number: null,
        passport_expiry: null
      },
      url: 'imms_table/application_family_members',
      getUrl: `table/application_family_members?filter=member_uid=${this.memberUID}`
    }
  },
  computed: {
    ...mapGetters({
      relationshipStatus: 'other/relationships'
    })
  },
  watch: {
    memberUID: function() {
      this.getData()
    }
  },
  mounted() {
    this.$store.dispatch('other/getRelationShips', '?filter=name!=Principal&order=rel_order ASC,name ASC')
    this.getData()
  },
  methods: {
    getData() {
      this.getUrl = `table/application_family_members?filter=member_uid=${this.memberUID}`
      this.fetch().then(data => {
        let vm = this;

        if (vm.isDataValidation) {
          let datas = []

          data.items.forEach(function(item) {
            var obj = vm.checkFormItems(item, vm.form);

            if (obj != 'empty') {
              datas.push(item)
            }
          })
          vm.items = datas
        }
        else {
          let items = []
          data.items.forEach(function(item) {
            items.push(item)
          })
          vm.items = items
        }
      })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        method: null,
        ad_source: null,
        web_address: null,
        ads_number: null,
        expiry_date: null,
        publication_date: null
      }
      this.edit = false
    }
  }
}
</script>
