import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  base: __dirname,
  mode: 'history',
  routes: [
    { path: '*', redirect: '/login' },
    {
      path: '/application_type',
      component: () => import('../layouts/default'),
      children: [
        {
          path: ':apptypeId/:id/:title',
          name: 'work',
          component: () => import('../views/application/components/AppTab.vue'),
        },
        {
          path: 'new/:apptypeId/:id/:lmiaUserId/:lmiaId/:title',
          name: 'new',
          component: () =>
            import('../views/application/components/AppTabNew.vue'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/lmia_application_type',
      component: () => import('../layouts/default'),
      children: [
        {
          path: ':lmiaTypeId/:lmiaId/:title',
          name: 'lmia_work',
          component: () =>
            import('../views_lmia/application/components/AppTab.vue'),
          meta: { requiresAuth: true },
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/organization',
      component: () => import('../layouts/comparison'),
      children: [
        {
          path: 'grid',
          name: 'organization_grid',
          component: () => import('../views/organization/grid.vue'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/section_comparison',
      component: () => import('../layouts/comparison'),
      children: [
        {
          path: ':apptypeId/:appId/:userId/:screen',
          name: 'section_comparison',
          component: () =>
            import('../views/application/components/section_comparison'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/dashboard',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'applicant',
          name: 'applicant_dashboard',
          component: () => import('../views/dashboard/applicant.vue'),
        },
        {
          path: 'admin',
          name: 'admin_dashboard',
          component: () => import('../views/dashboard/admin.vue'),
        },
        {
          path: 'consultant',
          name: 'consultant_dashboard',
          component: () => import('../views/dashboard/consultant.vue'),
        },
        {
          path: 'staff',
          name: 'staff_dashboard',
          component: () => import('../views/dashboard/staff.vue'),
        },
        {
          path: 'employer',
          name: 'employer_dashboard',
          component: () => import('../views/dashboard/employer.vue'),
        },
        {
          path: 'sales',
          name: 'sales_dashboard',
          component: () => import('../views/dashboard/sales.vue'),
        },
        {
          path: 'accounting',
          name: 'accounting_dashboard',
          component: () => import('../views/dashboard/accounting.vue'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/style_guide',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'basics',
          name: 'basics',
          component: () => import('../views/application/ui_style_guide.vue'),
        },
        {
          path: 'small_form',
          name: 'small_form',
          component: () =>
            import('../views/application/small_form_page_type.vue'),
        },
        {
          path: 'large_form',
          name: 'large_form',
          component: () =>
            import('../views/application/large_form_page_type.vue'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/application',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'create/:id',
          name: 'application',
          component: () => import('../views/application/create'),
        },
        {
          path: 'family_member/:userId',
          name: 'family_member',
          component: () =>
            import('../views/application/applicant_family_member'),
        },
        {
          path: 'message/inbox',
          name: 'inbox',
          component: () => import('../views/message/inbox'),
        },
        {
          path: 'message/sent',
          name: 'sent',
          component: () => import('../views/message/sent'),
        },
        {
          path: 'message_create',
          name: 'message_create',
          component: () => import('../views/message/create'),
        },
        {
          path: 'message_create/:appId',
          name: 'message_create_by_app_id',
          component: () => import('../views/message/create'),
        },
        {
          path: 'message/inbox/:id',
          name: 'inbox id',
          component: () => import('../views/message/reply'),
        },
        {
          path: 'message/sent/:id',
          name: 'sent id',
          component: () => import('../views/message/reply'),
        },
        {
          path: 'scheduling_payment/:id',
          name: 'scheduling_payment',
          component: () => import('../views/schedule/payment'),
        },
        {
          path: 'appointment_update/:appointment_id/:consultant_id/:event_id',
          name: 'application update scheduling',
          component: () =>
            import('../views/schedule/availability_times.update'),
        },
        {
          path: 'skills/:id',
          name: 'skills',
          component: () => import('../views/schedule/skills'),
        },
        {
          path: 'list',
          name: 'applications',
          component: () => import('../views/application/application'),
        },
        {
          path: 'document/:applicantId/:applicationTypeId/:applicationId',
          name: 'Supporting Document',
          component: () => import('../views/document/document'),
        },
        {
          path: 'document_new/:applicantId/:applicationTypeId/:applicationId',
          name: 'Supporting Document New',
          component: () => import('../views/document/view.new'),
        },
        {
          path: 'default_folder_configuration',
          name: 'application_default_folder_configuration',
          component: () =>
            import('../views/document/default_folder_configuration'),
        },
        {
          path: 'folder_configuration',
          name: 'folder_configuration',
          component: () => import('../views/document/folder_configuration'),
        },
        {
          path: 'folder_configuration/:applicationTypeId/:applicantId',
          name: 'folder_configuration_apptype_applicant_id',
          component: () => import('../views/document/folder_configuration'),
        },
        {
          path: 'processing/:appId/:apptypeId/:appBaseTypeId',
          name: 'processing',
          component: () => import('../views/application/processing_details'),
        },
        {
          path: 'appointment_invitation',
          name: 'invitation',
          component: () => import('../views/schedule/appointment_invitation'),
        },
        {
          path: 'purchase_remain_payment/:appId/:applicantId/:purchaseBatchId',
          name: 'purchase_remainder',
          component: () => import('../views/application/purchase_remainder'),
        },
        {
          path: 'signature_file/:applicationId',
          name: 'signature_file',
          component: () => import('../views/document/signature_file'),
        },
        {
          path: 'recently_updated',
          name: 'application_recently_updated',
          component: () => import('../views/activity/recently_updated'),
        },
        {
          path: 'notes/user/:userId/application/:appId',
          name: 'application notes',
          component: () => import('../views/application/notes'),
        },
        {
          path: 'lmia',
          name: 'lmia_application',
          component: () => import('../views_lmia/application/application'),
        },
        {
          path: 'process_flow',
          name: 'process flow report',
          component: () => import('../views/report/process_flow'),
        },
        {
          path: 'change_organization/:id',
          name: 'change_organization',
          component: () => import('../views/application/change_organization'),
        },
        {
          path: 'assigned_user/:applicationId',
          name: 'assigned_user',
          component: () => import('../views/application/assigned_user'),
        },
        {
          path: 'assgined_users',
          name: 'assgined_users_view_in_organization',
          component: () => import('../views/organization/assgined_users'),
        },
        {
          path: 'recommended_applications',
          name: 'recommended_applications',
          component: () =>
            import('../views/application/recommended_applications'),
        },
        {
          path: 'create_recommended_app',
          name: 'create_recommended_applications',
          component: () =>
            import('../views/application/recommended_applications_create'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/automation',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'queue',
          name: 'queue',
          component: () => import('../views/application/queue'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/retainer',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'list',
          name: 'retainer_users',
          component: () => import('../views/contract/retainer_list'),
        },
        {
          path: 'view/:id',
          name: 'retainer_view',
          component: () => import('../views/contract/view'),
        },
        {
          path: 'create',
          name: 'retainer_create',
          component: () => import('../views/contract/create'),
        },
        {
          path: 'pay/:id',
          name: 'retainer_pay',
          component: () => import('../views/contract/pay'),
        },
        {
          path: 'pay_v2/:id',
          name: 'retainer_pay',
          component: () => import('../views/contract/pay_v2'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/purchase',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'index',
          name: 'primary',
          component: () => import('../views/purchase/index'),
        },
        {
          path: 'retainer',
          name: 'purchase_retainer',
          component: () => import('../views/purchase/retainer_option_1'),
        },
        {
          path: 'lmia',
          name: 'lmia',
          component: () => import('../views/purchase/index'),
        },
        {
          path: 'cart',
          name: 'cart',
          component: () => import('../views/purchase/make_payment'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/billing',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'retainer_payments',
          name: 'retainer_payments',
          component: () => import('../views/contract/payments'),
        },
        {
          path: 'contract_transaction_details',
          name: 'contract_transaction_details',
          component: () =>
            import('../views/contract/contract_transaction_details'),
        },
        {
          path: 'contract_payments_in_brief',
          name: 'contract_payments_in_brief',
          component: () =>
            import('../views/contract/contract_payments_in_brief'),
        },
        {
          path: 'payment_history',
          name: 'payment',
          component: () => import('../views/payment/payment'),
        },
        {
          path: 'lmia_payment_history',
          name: 'payment',
          component: () => import('../views/payment/payment_lmia'),
        },
        {
          path: 'card',
          name: 'payment summary',
          component: () => import('../views/payment/card'),
        },
        {
          path: 'complete',
          name: 'complete',
          component: () => import('../views/purchase/complete'),
        },
        {
          path: 'fail',
          name: 'fail',
          component: () => import('../views/purchase/fail'),
        },
        {
          path: 'payment_users/:userId/:appId',
          name: 'payment_users',
          component: () => import('../views/payment/payment_users'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/payment',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'history',
          name: 'payment',
          component: () => import('../views/payment/payment'),
        },
        {
          path: 'card',
          name: 'payment summary',
          component: () => import('../views/payment/card'),
        },
        {
          path: 'complete',
          name: 'complete',
          component: () => import('../views/purchase/complete'),
        },
        {
          path: 'fail',
          name: 'fail',
          component: () => import('../views/purchase/fail'),
        },
        {
          path: 'payment_users/:userId/:appId',
          name: 'payment_users',
          component: () => import('../views/payment/payment_users'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/account',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'profile',
          name: 'profile',
          component: () => import('../views/user/user_profile'),
        },
        {
          path: 'changepassword',
          name: 'changepassword',
          component: () => import('../views/user/change_password'),
        },
        {
          path: 'personal_information',
          name: 'PersonalInformation',
          component: () =>
            import('../views_lmia/jobboard/candidate_information'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/organization_setup',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'configure_site_policies',
          name: 'configure_site_policies',
          component: () => import('../views/sysconfig/configure_site_policies'),
        },
        {
          path: 'type_configuration',
          name: 'type_configuration',
          component: () => import('../views/application/type_configuration'),
        },
        {
          path: 'email_templates',
          name: 'email_templates',
          component: () =>
            import('../views/sysconfig/email_templates/templates'),
        },
        {
          path: 'message_templates',
          name: 'message_templates',
          component: () =>
            import('../views/sysconfig/message_templates/templates'),
        },
        {
          path: 'retainer_templates',
          name: 'retainer_templates',
          component: () =>
            import('../views/sysconfig/retainer_templates/templates'),
        },
        {
          path: 'agents',
          name: 'agents',
          component: () => import('../views/sysconfig/user/agents'),
        },
        {
          path: 'recruiter_agreement/:id',
          name: 'recruiter_agreement',
          component: () => import('../views/contract/recruiter_agreement'),
        },
        {
          path: 'employers',
          name: 'Employers',
          component: () => import('../views/employers/index'),
        },
        {
          path: 'employees',
          name: 'users_employees',
          component: () => import('../views/sysconfig/users'),
        },
        {
          path: 'users_employers',
          name: 'users_employers',
          component: () => import('../views/sysconfig/employers'),
        },
        {
          path: 'users_business_partners',
          name: 'users_business_partners',
          component: () => import('../views/sysconfig/business_partner_users'),
        },
        {
          path: 'applicants',
          name: 'users_applicants',
          component: () => import('../views/sysconfig/applicants'),
        },
        {
          path: 'applicants/follow_up/:id',
          name: 'users_applicants_follow_up',
          component: () => import('../views/sysconfig/applicants_follow_up'),
        },
        {
          path: 'user/edit/:id',
          name: 'users_edit',
          component: () => import('../views/sysconfig/user/sys_user_edit'),
        },
        {
          path: 'edit_applicant/:id',
          name: 'users_applicant_edit',
          component: () => import('../views/sysconfig/user/sys_user_edit'),
        },
        {
          path: 'edit_candidate/:id',
          name: 'users_candidate_edit',
          component: () => import('../views/sysconfig/user/sys_user_edit'),
        },
        {
          path: 'edit_agent/:id',
          name: 'users_agent_edit',
          component: () => import('../views/sysconfig/user/sys_user_edit'),
        },
        {
          path: 'organizations',
          name: 'organization',
          component: () =>
            import('../views/sysconfig/organization/organization'),
        },
        {
          path: 'organization_edit/:id',
          name: 'organization_edit',
          component: () =>
            import('../views/sysconfig/organization/organization_form_edit'),
        },
        {
          path: 'organization_add',
          name: 'organization_add',
          component: () =>
            import('../views/sysconfig/organization/organization_form_add'),
        },
        {
          path: 'change_organization/:id',
          name: 'change_organization',
          component: () =>
            import('../views/sysconfig/user/change_organization'),
        },
        {
          path: 'transfer_data/selector',
          name: 'transfer_data_selector',
          component: () =>
            import(
              '../views/sysconfig/organization/transfer_data_module_selector'
            ),
        },
        {
          path: 'transfer_data/organization',
          name: 'transfer_data_organization',
          component: () =>
            import(
              '../views/sysconfig/organization/transfer_data_organization'
            ),
        },
        {
          path: 'transfer_data/application_configuration',
          name: 'transfer_data_application_configuration',
          component: () =>
            import(
              '../views/sysconfig/organization/transfer_data_application_configuration'
            ),
        },
        {
          path: 'transfer_data/user',
          name: 'transfer_data_user',
          component: () =>
            import('../views/sysconfig/organization/transfer_data_user'),
        },
        {
          path: 'reports',
          name: 'reports_selector',
          component: () => import('../views/sysconfig/reports/report_selector'),
        },
        {
          path: 'reports/users',
          name: 'reports_export_users',
          component: () => import('../views/sysconfig/reports/export_users'),
        },
        {
          path: 'manage_marketplace_organization',
          name: 'manage_marketplace_organization',
          component: () =>
            import(
              '../views/sysconfig/organization/manage_marketplace_organization'
            ),
        },
        {
          path: 'consultants',
          name: 'user_consultants',
          component: () => import('../views/sysconfig/consultants'),
        },
        {
          path: 'consultants_edit/:id',
          name: 'consultants_edit',
          component: () => import('../views/sysconfig/user/sys_user_edit'),
        },
        {
          path: 'sales',
          name: 'sales',
          component: () => import('../views/sysconfig/sales'),
        },
        {
          path: 'edit_sales/:id',
          name: 'sales_edit',
          component: () => import('../views/sysconfig/user/sys_user_edit'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/sysconfig',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'types',
          name: 'application_types',
          component: () => import('../views/application/types'),
        },
        {
          path: 'ads',
          name: 'ads',
          component: () => import('../views/content/ads'),
        },
        {
          path: 'webinars',
          name: 'webinars',
          component: () => import('../views/content/webinars'),
        },
        {
          path: 'faqs',
          name: 'faqs',
          component: () => import('../views/content/faqs'),
        },
        {
          path: 'ui_tab',
          name: 'ui_tab',
          component: () => import('../views/sysconfig/ui_tab'),
        },
        {
          path: 'ui_layout_conf',
          name: 'ui_layout_conf',
          component: () => import('../views/sysconfig/ui_layout_conf'),
        },
        {
          path: 'useful_links',
          name: 'useful_links',
          component: () => import('../views/sysconfig/useful_links'),
        },
        {
          path: 'organization/users',
          name: 'organization_users',
          component: () => import('../views/sysconfig/users'),
        },
        {
          path: 'organization/users/edit/:id',
          name: 'organization_users_edit',
          component: () => import('../views/sysconfig/user/sys_user_edit'),
        },
        {
          path: 'sys_menus',
          name: 'sys_menus',
          component: () => import('../views/sysconfig/menu/sys_menus'),
        },
        {
          path: 'sys_menus_roles',
          name: 'sys_menu_role',
          component: () => import('../views/sysconfig/menu/sys_menu_role'),
        },
        {
          path: 'sys_user_group',
          name: 'sys_user_group',
          component: () => import('../views/sysconfig/user/sys_user_group'),
        },
        {
          path: 'sys_user_group_add',
          name: 'sys_user_group_add',
          component: () =>
            import('../views/sysconfig/user/sys_user_group_form'),
        },
        {
          path: 'sys_user_group_edit/:id',
          name: 'sys_user_group_edit',
          component: () =>
            import('../views/sysconfig/user/sys_user_group_form'),
        },
        {
          path: 'cache',
          name: 'cache',
          component: () => import('../views/sysconfig/cache/cache'),
        },
        {
          path: 'pdf_form',
          name: 'pdf_form',
          component: () => import('../views/sysconfig/pdf/pdf_form'),
        },
        {
          path: 'pdf_application_type_form',
          name: 'pdf_application_type_form',
          component: () =>
            import('../views/sysconfig/pdf/pdf_application_type_form'),
        },
        {
          path: 'document_type',
          name: 'document_type',
          component: () => import('../views/document/document_type'),
        },
        {
          path: 'default_folder_configuration',
          name: 'default_folder_configuration',
          component: () =>
            import('../views/document/default_folder_configuration'),
        },
        {
          path: 'sec_resources_v2',
          name: 'sec_resources_v2',
          component: () => import('../views/sysconfig/secure/sec_resources_v2'),
        },
        {
          path: 'sys_user_to_applicant/:id',
          name: 'sys_user_to_applicant',
          component: () => import('../views/sysconfig/user/to_applicant'),
        },
        {
          path: 'document_checklist',
          name: 'document_checklist',
          component: () => import('../views/document/document_checklist'),
        },
        {
          path: 'change_password/:id',
          name: 'change_password',
          component: () => import('../views/sysconfig/change_password'),
        },
      ],
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: '/business_partners',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'clientqueue',
          name: 'clientqueue',
          component: () =>
            import('../views/sysconfig/business_partner/sec_business_partners'),
        },
        {
          path: 'app_assignment',
          name: 'app_assignment',
          component: () =>
            import('../views/sysconfig/business_partner/app_assignment'),
        },
        {
          path: 'lmia_app_assignment',
          name: 'lmia_app_assignment',
          component: () =>
            import('../views/sysconfig/business_partner/lmia_app_assignment'),
        },
        {
          path: 'users',
          name: 'business_partner_users',
          component: () => import('../views/sysconfig/users'),
        },
        {
          path: 'users/edit/:id',
          name: 'business_partner',
          component: () => import('../views/sysconfig/user/sys_user_edit'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/scheduling',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'list',
          name: 'schedule_list',
          component: () => import('../views/schedule/schedule'),
        },
        {
          path: 'consultants',
          name: 'scheduling_consultants',
          component: () => import('../views/schedule/consultants'),
        },
        {
          path: 'availability_times/:consultant_id',
          name: 'availability_times',
          component: () => import('../views/schedule/availability_times'),
        },
        {
          path: 'appointment_update/:appointment_id/:consultant_id/:event_id',
          name: 'update scheduling',
          component: () =>
            import('../views/schedule/availability_times.update'),
        },
        {
          path: 'appointment_confirm/:consultant_id',
          name: 'appointment_confirm',
          component: () => import('../views/schedule/appointment_confirm'),
        },
        {
          path: 'skills/:id',
          name: 'scheduling skills',
          component: () => import('../views/schedule/skills'),
        },
        {
          path: 'appointment_invitation_free',
          name: 'invitation_free',
          component: () =>
            import('../views/schedule/appointment_invitation_free'),
        },
        {
          path: 'invitation_select_time/:u_id',
          name: 'invitation_select_time',
          component: () => import('../views/schedule/invitation_select_time'),
        },
        {
          path: 'follow_up/:id',
          name: 'follow_up',
          component: () => import('../views/schedule/follow_up'),
        },
        {
          path: 'reschedule/:appointment_process_id',
          name: 'reschedule',
          component: () => import('../views/schedule/reschedule'),
        },
        {
          path: 'reschedule_free/:appointment_process_id',
          name: 'reschedule',
          component: () => import('../views/schedule/reschedule_free'),
        },
        {
          path: 'change/:appointment_process_id',
          name: 'change-consultation',
          component: () => import('../views/schedule/change_consultation'),
        },
        {
          path: 'change_free_consultation/:appointment_invitation_id',
          name: 'change-free-consultation',
          component: () => import('../views/schedule/change_free_consultation'),
        },
        {
          path: 'book/select_time/new_employer',
          name: 'book_select_time_new_employer',
          component: () =>
            import('../views/schedule/book_new_employer_select_time'),
        },
        {
          path: 'book/enter_detail/new_employer',
          name: 'book_enter_detail_new_employer',
          component: () =>
            import('../views/schedule/book_new_employer_enter_detail'),
        },
        {
          path: 'reschedule_restriction/:appointment_process_id',
          name: 'reschedule_restriction',
          component: () => import('../views/schedule/reschedule_restriction'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/pages',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'terms_of_use',
          name: 'terms_of_use',
          component: () => import('../views/pages/terms_of_use'),
        },
        {
          path: 'privacy_policy',
          name: 'privacy_policy',
          component: () => import('../views/pages/privacy_policy'),
        },
        {
          path: 'complete_action',
          name: 'pages_complete_action',
          component: () => import('../views/pages/action_complete'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/print',
      component: () => import('../layouts/print'),
      children: [
        {
          path: 'payment/:id',
          name: 'view',
          component: () => import('../views/payment/view'),
        },
        {
          path: 'retainer_signed/:purchaseBatchId',
          name: 'retainer_signed',
          component: () => import('../views/contract/retainer_signed'),
        },
        {
          path: 'recruitment_summary/:id',
          name: 'recruitment_summary_print',
          component: () =>
            import('../views_lmia/advertisement/recruitment_summary_view'),
        },
        {
          path: 'advertisement/:id',
          name: 'advertisement_print',
          component: () => import('../views/report/advertisement_view'),
        },
        {
          path: 'initial_consutation_retainer/:consultantId/:applicantId/:appId',
          name: 'initial_consutation_retainer',
          component: () =>
            import('../views/contract/initial_consutation_retainer'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/report',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'login_history',
          name: 'login_history_report',
          component: () => import('../views/activity/login_history'),
        },
        {
          path: 'upload_history',
          name: 'upload_history_report',
          component: () => import('../views/activity/upload_history'),
        },
        {
          path: 'email_logs',
          name: 'email_logs_report',
          component: () => import('../views/activity/email_logs'),
        },
        {
          path: 'recently_updated',
          name: 'application_recently_updated_report',
          component: () => import('../views/activity/recently_updated'),
        },
        {
          path: 'reset_password_log',
          name: 'reset_password_report',
          component: () => import('../views/report/password_reset_log.vue'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/activity',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'login_history',
          name: 'login_history',
          component: () => import('../views/activity/login_history'),
        },
        {
          path: 'upload_history',
          name: 'upload_history',
          component: () => import('../views/activity/upload_history'),
        },
        {
          path: 'email_logs',
          name: 'email_logs',
          component: () => import('../views/activity/email_logs'),
        },
        {
          path: 'send_email',
          name: 'activity_send_email',
          component: () => import('../views/activity/send_email'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/email',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'create/:appId/application_type/:appTypeId',
          name: 'Create email',
          component: () => import('../views/email/create'),
        },
        {
          path: 'lmia_create/:appId/application_type/:appTypeId',
          name: 'lmia_create',
          component: () => import('../views/email/create'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/prospect',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'add',
          name: 'prospect add',
          component: () => import('../views/prospect/add'),
        },
        {
          path: 'edit/:id',
          name: 'prospect edit',
          component: () => import('../views/prospect/add'),
        },
        {
          path: 'profile',
          name: 'prospect-profile',
          component: () => import('../views/prospect/add'),
        },
        {
          path: 'index',
          name: 'prospect index',
          component: () => import('../views/prospect/index'),
        },
        {
          path: 'follow_up/:id',
          name: 'follow_up prospect',
          component: () => import('../views/prospect/follow_up'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/task',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'list',
          name: 'Task',
          component: () => import('../views/task/index'),
        },
        {
          path: 'detail/:id',
          name: 'Detail task',
          component: () => import('../views/task/detail'),
        },
        {
          path: 'create',
          name: 'Create task',
          component: () => import('../views/task/create'),
        },
        {
          path: 'edit/:id',
          name: 'Edit task',
          component: () => import('../views/task/create'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/lmia_application',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'create/:id',
          name: 'lmia_application_creation',
          component: () => import('../views_lmia/application/create'),
        },
        {
          path: 'copy/:appId/:appTypeId/:userId',
          name: 'copy',
          component: () => import('../views_lmia/application/copy'),
        },
        {
          path: 'document/:applicantId/:applicationTypeId/:applicationId',
          name: 'Supporting Document',
          component: () => import('../views_lmia/document/view'),
        },
        {
          path: 'folder_configuration/:applicationTypeId/:applicantId',
          name: 'folder_configuration_apptype_applicant_id',
          component: () =>
            import('../views_lmia/document/folder_configuration'),
        },
        {
          path: 'change_organization/:id',
          name: 'change_organization',
          component: () =>
            import('../views_lmia/application/change_organization'),
        },
        {
          path: 'assigned_user/:applicationId',
          name: 'assigned_user',
          component: () => import('../views_lmia/application/assigned_user'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/lmia_settings',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'types',
          name: 'application_types',
          component: () => import('../views_lmia/application/types'),
        },
        {
          path: 'ui_tab',
          name: 'ui_tab',
          component: () => import('../views_lmia/sysconfig/ui_tab'),
        },
        {
          path: 'ui_layout_conf',
          name: 'ui_layout_conf',
          component: () => import('../views_lmia/sysconfig/ui_layout_conf'),
        },
        {
          path: 'document_type',
          name: 'document_type',
          component: () => import('../views_lmia/document/document_type'),
        },
        {
          path: 'default_folder_configuration',
          name: 'default_folder_configuration',
          component: () =>
            import('../views_lmia/document/default_folder_configuration'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/advertisement',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'advertisement_tool',
          name: 'advertisement_tool',
          component: () => import('../views_lmia/advertisement/tools'),
        },
        {
          path: 'process_company',
          name: 'process_company',
          component: () => import('../views_lmia/advertisement/company'),
        },
        {
          path: 'process_occupation',
          name: 'process_occupation',
          component: () => import('../views_lmia/advertisement/index'),
        },
        {
          path: 'list',
          name: 'advertisement_list',
          component: () => import('../views_lmia/advertisement/index'),
        },
        {
          path: 'company',
          name: 'advertisement_company',
          component: () => import('../views_lmia/advertisement/company'),
        },
        {
          path: 'recruitment_summary/:id',
          name: 'recruitment_summary',
          component: () =>
            import('../views_lmia/advertisement/recruitment_summary'),
        },
        {
          path: 'process/:id',
          name: 'process',
          component: () => import('../views_lmia/advertisement/process_table'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/job_board',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'jobs',
          component: () => import('../views_lmia/jobboard/jobs'),
        },
        {
          path: 'jobs/create',
          component: () => import('../views_lmia/jobboard/job_create'),
        },
        {
          path: 'jobs/edit/:id',
          component: () => import('../views_lmia/jobboard/job_create'),
        },
        {
          path: 'jobs/:id',
          component: () => import('../views_lmia/jobboard/jobs_view'),
        },
        {
          path: 'job_applications',
          component: () => import('../views_lmia/jobboard/job_applications'),
        },
        {
          path: 'job_applications_group',
          component: () =>
            import('../views_lmia/jobboard/job_applications_group'),
        },
        {
          path: 'candidates',
          component: () => import('../views_lmia/jobboard/candidates'),
        },
        {
          path: 'candidates_export',
          component: () => import('../views_lmia/jobboard/candidates_export'),
        },
        {
          path: 'candidate_information',
          component: () =>
            import('../views_lmia/jobboard/candidate_information'),
        },
        {
          path: 'myapplied',
          component: () => import('../views_lmia/jobboard/myapplied'),
        },
        {
          path: 'candidate_to_applicant/:id',
          name: 'candidate_to_applicant',
          component: () => import('../views/sysconfig/user/to_applicant'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/job_match',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'index',
          name: 'Job Match Listing',
          component: () => import('../views/job_match/index'),
        },
        {
          path: 'view/:id',
          name: 'Job Match View',
          component: () => import('../views/job_match/view'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/window',
      component: () => import('../layouts/comparison'),
      children: [
        {
          path: 'application_note/user/:userId/application/:appId',
          name: 'application note',
          component: () => import('../views/application/notes_application'),
        },
        {
          path: 'create_note/user/:userId/application/:appId',
          name: 'general note',
          component: () => import('../views/application/notes'),
        },
        {
          path: 'create_note/lmia_user/:userId',
          name: 'general lmia note',
          component: () => import('../views/application/notes'),
        },
        {
          path: 'upload/user/:userUuid/application/:appId',
          name: 'upload document',
          component: () => import('../views/application/upload'),
        },
        {
          path: 'report/user/:userUuid',
          name: 'view report',
          component: () => import('../components/ViewReport'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/privacy_policy',
      component: () => import('../views/pages/privacy_policy_public'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../layouts/auth'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/admin_2zXCaBMNfm',
      name: 'LoginMaster',
      component: () => import('../layouts/auth'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/forgot',
      name: 'Forgot',
      component: () => import('../layouts/auth'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/reset',
      name: 'Reset',
      component: () => import('../layouts/auth'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/confirmation',
      name: 'Confirmation',
      component: () => import('../layouts/auth'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/sign_up',
      name: 'Register',
      component: () => import('../layouts/auth'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/thanks',
      name: 'Thanks',
      component: () => import('../layouts/auth'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/websites',
      component: () => import('../layouts/website'),
      children: [
        {
          path: 'index',
          name: 'websites-index',
          component: () => import('../views/websites/index'),
        },
        {
          path: 'edit/:website_id/content/:id',
          name: 'websites-content-edit',
          component: () => import('../views/websites/websites_content_form'),
        },
        {
          path: 'view/:website_id/content/:type',
          name: 'websites-view',
          component: () => import('../views/websites/websites_content'),
        },
        {
          path: 'view/:website_id/content_add/:type',
          name: 'websites-content-add',
          component: () => import('../views/websites/websites_content_form'),
        },
        {
          path: 'view/:website_id/content_types',
          name: 'websites-content-types',
          component: () => import('../views/websites/websites_content_type'),
        },
        {
          path: 'view/:website_id/content_types_add',
          name: 'websites-content-types-add',
          component: () =>
            import('../views/websites/websites_content_type_form'),
        },
        {
          path: 'view/:website_id/content_types_edit/:id',
          name: 'websites-content-types-edit',
          component: () =>
            import('../views/websites/websites_content_type_form'),
        },
        {
          path: 'view/:website_id/contact',
          name: 'websites-contact',
          component: () => import('../views/websites/websites_contact'),
        },
        {
          path: 'view/:website_id/contact_add',
          name: 'websites-contact-add',
          component: () => import('../views/websites/websites_contact_form'),
        },
        {
          path: 'view/:website_id/contact_edit/:id',
          name: 'websites-contact-edit',
          component: () => import('../views/websites/websites_contact_form'),
        },
        {
          path: 'view/:website_id/setting',
          name: 'websites-setting-edit',
          component: () =>
            import('../views/websites/websites_content_setting_form'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/referral',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'pending',
          name: 'referral_pending',
          component: () => import('../views/referral/pending'),
        },
        {
          path: 'sec_creds',
          name: 'sec_creds_index',
          component: () => import('../views/referral/sec_cred_index'),
        },
        {
          path: 'sec_creds/create',
          name: 'sec_creds_create',
          component: () => import('../views/referral/sec_cred_form'),
        },
        {
          path: 'sec_creds/edit/:id',
          name: 'sec_creds_edit',
          component: () => import('../views/referral/sec_cred_form'),
        },
        {
          path: 'sec_creds/view/:id',
          name: 'sec_creds_view',
          component: () => import('../views/referral/sec_cred_view'),
        },
        {
          path: 'invitation',
          name: 'referral_invitation',
          component: () => import('../views/referral/invitation'),
        },
        {
          path: 'notif',
          name: 'referral_notif',
          component: () => import('../views/referral/notif'),
        },
        {
          path: 'redeems',
          name: 'referral_redeems',
          component: () => import('../views/referral/redeemed'),
        },
        {
          path: 'rewards',
          name: 'referral_rewards',
          component: () => import('../views/referral/rewards'),
        },
        {
          path: 'dashboard',
          name: 'referral_dashboard',
          component: () => import('../views/referral/referral_dashboard'),
        },
        {
          path: 'pending',
          name: 'referral_pending',
          component: () => import('../views/referral/pending'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/consultant_matching',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'profile/:uid',
          name: 'consultant_profile',
          component: () => import('../views/sysconfig/user/consultant_profile'),
        },
        {
          path: 'visa_types',
          name: 'visa_types',
          component: () => import('../views/consultant_matching/visa_types'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/provinces',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'ca',
          name: 'provinces_ca',
          component: () => import('../views/province/provinces_ca'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/company',
      component: () => import('../layouts/default'),
      children: [
        {
          path: 'information',
          name: 'company_information',
          component: () => import('../views/company/information'),
        },
        {
          path: 'subsidiaries',
          name: 'company_subsidiaries',
          component: () => import('../views/company/subsidiaries'),
        },
        {
          path: 'subsidiaries/:id',
          name: 'company_subsidiary',
          component: () => import('../views/company/subsidiary'),
        },
        {
          path: 'users',
          name: 'company_users',
          component: () => import('../views/company/users'),
        },
        {
          path: 'locations',
          name: 'company_locations',
          component: () => import('../views/company/locations'),
        },
        {
          path: 'locations/create',
          name: 'company_create_location',
          component: () => import('../views/company/create_location'),
        },
        {
          path: 'locations/:id',
          name: 'company_location',
          component: () => import('../views/company/location'),
        },
      ],
    },
  ],
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  let token = window.localStorage.getItem('token');
  let isAdmin = ['1', '79', '90'].includes(window.localStorage.role_id);
  if (!requiresAuth) {
    next();
  } else if (requiresAdmin && !isAdmin) {
    next('/login');
  } else if (token == undefined || token == null) {
    next('/login');
  } else {
    next();
  }
});
export default router;
