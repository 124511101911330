<template>
  <div>
    <v-card class="mt-3" v-if="hideSection">
      <v-card-title>
        <span>{{ $t('title.application_membership_associations') }}</span>
        <v-spacer />
        <v-btn color="primary" dark small @click.native="saveForm()" :disabled="notApplicable">{{ $t('global.add') }}</v-btn>
      </v-card-title>
      <v-card-text class="p-n">
        <div class="mb-3 mt-1">
          <el-checkbox v-model="notApplicable" @change="checkApplicable()">{{ $t('global.not_applicable') }}</el-checkbox>
        </div>
        <v-alert type="info" border="left" dense class="mb-3" v-if="$t('title.application_membership_associations') !== ''">
          <span v-if="isOINP">
              Do you have any other activities, excluding education and paid work history, in the last ten years (or since the age of 18, if this was less than ten years ago)?
          </span>
          <span v-else>
           {{ $t('infobox.application_membership_associations') }}
          </span>
        </v-alert>
        <v-row dense class="customCollapseHeader">
          <v-col cols="6" sm="3">From Date</v-col>
          <v-col cols="6" sm="3">To Date</v-col>
          <v-col class="d-none d-sm-flex" sm="6">Name of Organization</v-col>
        </v-row>
        <el-collapse v-model="activeNames" accordion v-show="!notApplicable">
          <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i" :id="'row' + form.id">
            <template slot="title">
              <div class="customHead" v-on:click="getProvince(form)">
                <v-row dense>
                  <v-col cols="6" sm="3">
                    <el-tag type="primary" v-if="form.date_from !== null && form.date_from != undefined">{{ form.date_from | day }}</el-tag>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <el-tag type="success" v-if="form.date_to !== null && form.date_to != undefined">{{ form.date_to | day }}</el-tag>
                  </v-col>
                  <v-col sm="6" class="d-none d-sm-flex"><div class="pl-2 pr-2">{{ form.name_of_org }}</div></v-col>
                </v-row>
              </div>
            </template>
            <el-form :model="form" ref="form" label-position="top">
              <v-row dense>

                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'date_from')">
                  <el-form-item :label="$t('application_membership_associations.date_from')" prop="date_from">
                    <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.date_from" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'date_to')">
                  <el-form-item :label="$t('application_membership_associations.date_to')" prop="date_to">
                    <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.date_to" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
                <template v-show="isOINP">
                  <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'activity_type')">
                    <el-form-item :label="$t('application_membership_associations.activity_type')" prop="activity_type">
                      <el-input v-model="form.activity_type" @change="handleAutoSave(i, form)" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                </template>
                <template v-if="isOINP">
                  <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'ongoing')">
                    <el-form-item label="Ongoing" prop="ongoing">
                      <el-radio-group v-model="form.ongoing" @change="handleAutoSave(i, form)">
                        <el-radio value="Yes" label="Yes">Yes</el-radio>
                        <el-radio value="No" label="No">No</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </v-col>
                </template>
                <template v-if="isOINP">
                  <v-col cols="12" sm="12" v-if="dataValidationItems(form, 'describing')">
                    <el-form-item :label="$t('application_membership_associations.describing')" prop="describing">
                      <el-input v-model="form.describing" @change="handleAutoSave(i, form)" style="width: 100%" type="textarea" :rows="4" />
                    </el-form-item>
                  </v-col>
                </template>
                <template v-show="sectionSlug === 'MembershipOfOrganizations'">
                  <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'name_of_org')">
                    <el-form-item :label="$t('application_membership_associations.name_of_org')" prop="name_of_org">
                      <el-input v-model="form.name_of_org" @change="handleAutoSave(i, form)" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'type_of_organization')">
                    <el-form-item :label="$t('application_membership_associations.type_of_organization')" prop="type_of_organization">
                      <el-input v-model="form.type_of_organization" @change="handleAutoSave(i, form)" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="12" v-if="dataValidationItems(form, 'activities')">
                    <el-form-item :label="$t('application_membership_associations.activities')" prop="activities">
                      <el-input v-model="form.activities" @change="handleAutoSave(i, form)" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                </template>
              </v-row>
              <v-row dense>
                <template v-show="sectionSlug === 'MembershipOrganization'">
                  <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'location')">
                    <el-form-item :label="$t('application_membership_associations.location')" prop="location">
                      <el-input v-model="form.location" @change="handleAutoSave(i, form)" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                </template>
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'country_id')">
                  <el-form-item :label="$t('application_membership_associations.country_id')" prop="country_id">
                    <div class="el-select native-select">
                      <div class="el-input">
                        <select v-model="form.country_id" placeholder="Select" class="el-input__inner" @change="getProvince(form);handleAutoSave(i, form)">
                          <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                        </select>
                      </div>
                    </div>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'province')">
                  <el-form-item :label="$t('application_membership_associations.province')" prop="province">
                    <div v-if="selectProvine" class="el-select native-select">
                      <div class="el-input">
                        <select v-model="form.province" placeholder="Select" @change="handleAutoSave(i, form)" class="el-input__inner">
                          <option v-for="(item, index) in province" v-bind:key="index" :value="item.province_name" :label="item.province_name"></option>
                        </select>
                      </div>
                    </div>
                    <el-input v-else v-model="form.province" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <template v-show="sectionSlug === 'MembershipOfOrganizations'">
                  <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'city')">
                    <el-form-item :label="$t('application_membership_associations.city')" prop="city">
                      <el-input v-model="form.city" @change="handleAutoSave(i, form)" style="width: 100%" />
                    </el-form-item>
                  </v-col>
                </template>

                <v-col cols="12" v-if="dataValidationItems(form, 'details')">
                  <el-form-item v-show="sectionSlug === 'MembershipOrganization'" :label="$t('application_membership_associations.details')" prop="details">
                    <el-input v-model="form.details" @change="handleAutoSave(i, form)" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" class="mt-2">
                  <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(i,form)">
                    {{ $t('global.save') }}
                  </v-btn>
                  <v-btn :loading="autoSave" :disabled="autoSave" color="error" small @click.native="deleteItem(form,i)">{{ $t('global.delete') }}</v-btn>
                </v-col>
              </v-row>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </v-card-text>
    </v-card>

    <withness-participated
      :sectionLabel="sectionLabel"
      :sectionInfoBox="sectionInfoBox"
      :sectionSlug="sectionSlug"
      :memberUID="memberUID"
      :memberID="memberID"
      :isDataValidation="isDataValidation"
      >
    </withness-participated>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'
import WithnessParticipated from './witness_participated.vue'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    },
    isOINP: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        name_of_org: null,
        type_of_organization: null,
        activities: null,
        country_id: null,
        date_from: null,
        date_to: null,
        city: null,
        province: null,
        location: null,
        details: null,
        activity_type: null,
        ongoing: null,
        describing: null
      },
      activeNames: -1,
      url: 'imms_table/application_membership_associations',
      deleteUrl: 'imms/application_membership_associations',
      items: []
    }
  },
  components: { WithnessParticipated },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      applicableId: 'applicable/id',
      country: 'other/country',
      province: 'other/province'
    }),
    notApplicable: {
      get() {
        return this.$store.state.applicable.notApplicable.membership_of_organization
      },
      set(val) {
        this.$store.commit('applicable/set_membership_of_organization', val)
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/application_membership_associations_view?filter=member_uid=${this.memberUID}&order=date_from DESC, date_to DESC`
      this.$store.dispatch('store/get', url).then(response => {
        let vm = this

        if (vm.isDataValidation) {
          if (vm.notApplicable) {
            vm.hideSection = false
          }
          else {
            response.resource.forEach(function(item) {
              var form = {
                name_of_org: null,
                type_of_organization: null,
                activities: null,
                country_id: null,
                date_from: null,
                date_to: null,
                city: null,
                province: null,
                location: null,
                details: null
              }

              if (vm.sectionSlug !== 'MembershipOfOrganizations') {
                delete form.name_of_org
                delete form.type_of_organization
                delete form.activities
                delete form.city
              }

              if (vm.sectionSlug !== 'MembershipOrganization') {
                delete form.details
                delete form.location
              }

              if (!vm.isOINP) {
                delete form.activity_type
                delete form.ongoing
                delete form.describing
              }

              var obj = vm.checkFormItems(item, form)

              if (obj != 'empty') {
                vm.items.push(item)
              }
            })

            if (response.resource.length == 0) {
              vm.hideSection = true
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
        }
        else {
          vm.items = response.resource
        }
        this.$Progress.finish()
      })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    checkApplicable() {
      var action = 'applicable/save'
      var that = this
      var data = {}
      data.membership_of_organization = that.notApplicable
      data.application_id = this.$route.params.id
      data.member_id = parseFloat(this.memberID)
      data.member_uid = this.memberUID
      if (that.applicableId != undefined) {
        data.id = that.applicableId
        action = 'applicable/edit'
      }
      this.$Progress.start()
      this.$store.dispatch(action, data).then(response => {
        this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        this.$Progress.finish()
      })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        name_of_org: null,
        type_of_organization: null,
        activities: null,
        country_id: null,
        date_from: null,
        date_to: null,
        city: null,
        province: null,
        location: null,
        details: null,
        activity_type: null,
        ongoing: null,
        describing: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    }
  }
}
</script>
