import api from "./../plugins/axios";

export const state = {
  pages: [],
  page: {}
};

export const getters = {
  all(state) {
    return state.pages;
  },
  getOne(state) {
    return state.page;
  }
};

export const actions = {
  getAll({ commit }, filter) {
    return api.get("table/pages" + filter).then(
      (response) => {
        commit("GET_DATAS", response.data.resource);
        return Promise.resolve(response.data.resource);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  create({ commit }, data) {
    return api.post("table/pages", { resource: data }).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  update({ commit }, data) {
    return api.put("table/pages/" + data.id, data).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  delete({ commit }, data) {
    return api.delete(data.url + "/" + data.id).then(
      (response) => {
        commit("DELETE_DATA", data);
        return Promise.resolve(response);
      },
      (error) => {
        commit("FAILURE_DATAS", error);
        return Promise.reject(error);
      }
    );
  }
};

export const mutations = {
  GET_DATAS(state, datas) {
    state.messages = datas;
  },
  GET_DATA(state, data) {
    state.message = data;
  },
  FAILURE_DATAS(state) {
    state.messages = [];
  },
  FAILURE_DATA(state, data) {
    state.message = {};
  },
  DELETE_DATA(state, data) {
    let idx = state.messages.map((p) => p.id).indexOf(data.id);
    state.messages.splice(idx, 1);
  }
};
