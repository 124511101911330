<template>
  <div class="mt-3">
    <v-card class="elevation-0">
      <v-card-title>
        <span>{{ $t('global.imm_0008_name_address_relationship') }}:</span>
        <v-spacer />
        <v-btn color="primary" dark small @click.native="add()">{{ $t('global.add') }}</v-btn>
      </v-card-title>
      <v-card-text>
        <template v-for="(form,i) in items">
          <el-form :model="form" ref="form" label-position="top" :id="'row' + form.id" :key="i">
            <v-row dense class="medium-space">
              <v-col cols="12" sm="9" md="10" lg="11">
                <v-row dense class="medium-space">
                  <v-col cols="12" sm="6" md="4">
                    <el-form-item :label="$t('application_visit_canada_detail.name_relation')" prop="name_relation">
                      <el-input :id="'name_relation' + form.id" v-model="form.name_relation" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <el-form-item :label="$t('application_visit_canada_detail.relation_home')" prop="relation_home">
                      <el-input :id="'relation_home' + form.id" v-model="form.relation_home" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <el-form-item :label="$t('application_visit_canada_detail.addin_canada')" prop="addin_canada">
                      <el-input :id="'addin_canada' + form.id" v-model="form.addin_canada" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <el-form-item :label="$t('application_visit_canada_detail.name_relation1')" prop="name_relation1">
                      <el-input :id="'name_relation1' + form.id" v-model="form.name_relation1" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <el-form-item :label="$t('application_visit_canada_detail.relation_home1')" prop="relation_home1">
                      <el-input :id="'relation_home1' + form.id" v-model="form.relation_home1" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <el-form-item :label="$t('application_visit_canada_detail.addin_canada1')" prop="addin_canada1">
                      <el-input :id="'addin_canada1' + form.id" v-model="form.addin_canada1" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="3" md="2" lg="1">
                <v-btn color="pink" fab dark x-small class="ma-1" @click.native="deleteOpen(form,i)"><v-icon small>close</v-icon></v-btn>
                <v-btn :loading="loading" :disabled="loading" color="green" fab dark x-small class="ma-1" @click.native="submitForm(i,form)">
                  <v-icon small>add</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3"></v-divider>
          </el-form>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    sectionId: {
      type: Number
    },
    memberUID: {
      type: String
    },
    memberID: {
      type: String
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        name_relation: null,
        relation_home: null,
        addin_canada: null,
        name_relation1: null,
        relation_home1: null,
        addin_canada1: null
      },
      url: 'imms_table/application_visit_canada_detail',
      state: 'visitCanadaDetails',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/application_visit_canada_detail?filter=member_uid=${this.memberUID}`
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) this.checkItemsFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.form = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        name_relation: null,
        relation_home: null,
        addin_canada: null,
        name_relation1: null,
        relation_home1: null,
        addin_canada1: null
      }
      this.edit = false
    },
    add() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true

          if (formObj.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    },
    async deleteOpen(item, index) {
      this.$confirm("Are you sure to delete this item?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(async() => {
        try {
          await this.$api.delete(`${this.url}/${item.imms_uuid}`)
          this.items.splice(index, 1);
          this.activeNames = -1;
          this.$store.dispatch('toaster/add', { text: 'Deleted', type: 'success' })
        }
        catch (err) {
          console.error(err);
          this.$store.dispatch('toaster/add', { text: `Cannot delete item. Please contact supporting team!`, type: 'error' })
        }
      })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Canceled"
          });
        });
    }
  }
}
</script>
