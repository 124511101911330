import api from "./../plugins/axios";
import { totalAmount } from "./../utils/helpers";

export const state = {
  assetTotal: {},
  bankDepositTotal: {},
  businessTotal: {},
  fixedDepositTotal: {},
  propertyTotal: {},
  stockTotal: {},
  liabilityTotal: {},
  otherDebtTotal: {},
  asset: [],
  bankDeposit: [],
  business: [],
  fixedDeposit: [],
  property: [],
  stock: [],
  liability: [],
  otherDebt: [],
  filter: {
    search: null,
    start_date: null,
    end_date: null,
    activeBtnIndex: 100,
    selectedStatus: null,
    spouseName: null,
    appNumber: null,
    applicantEmail: null,
    applicantFullName: null,
    cicAppNumber: null,
    applicationTypeName: null,
    internalFileNumber: null
  },
  filterJobboard: {
    search: "",
    searchAgentId: "",
    searchCandidateId: "",
    activeBtnIndex: 100,
    pagination: {
      page: 1,
      itemsPerPage: 50,
      sortBy: 'expiry_date',
      sortDesc: true
    }
  },
  filterCandidate: {
    name: "",
    username: "",
    jobTitle: "",
    experience: "",
    education: "",
    score: "",
    job: "",
    searchCountryId: "",
    agent: "",
    inCompleted: "",
    had_consultation: false,
    pagination: {
      itemsPerPage: 20,
      sortBy: "created_date",
      sortDesc: true,
      totalItems: 0,
      page: 1
    }
  },
  filterPremierCandidate: {
    name: "",
    username: "",
    jobTitle: "",
    experience: "",
    education: "",
    score: "",
    job: "",
    searchCountryId: "",
    agent: "",
    inCompleted: "",
    had_consultation: false,
    pagination: {
      itemsPerPage: 20,
      sortBy: 'created_date',
      sortDesc: true,
      totalItems: 0,
      page: 1
    }
  },
  filterCandidateExport: {
    searchCountryId: "",
    searchUsername: "",
    searchCandidateFn: "",
    searchCandidateLn: "",
    inCompleted: "",
    experience: "",
    currentPage: 1,
    pageSize: 50,
    totalPage: 0
  },
  steps: [{ label: "Generated PDF", alias: "PdfWidget" }, { label: "Document to Sign", alias: "WidgetSignatureFile" }, { label: "Completed Document", alias: "WidgetCompleteApplication" }],
  isStep: {
    view: "PdfWidget",
    index: 1
  },
  appendix: []
};

export const actions = {
  setAppendix({ commit }, data) {
    commit("SET_APPENDIX", data);
  },
  setFilter({ commit }, data) {
    commit("SET_FILTER", data);
  },
  setStepView({ commit }, data) {
    commit("SET_STEP_VIEW", data);
  },
  setFilterJobboard({ commit }, data) {
    commit("SET_FILTER_JOBBOARD", data);
  },
  setFilterCandidate({ commit }, data) {
    commit("SET_FILTER_CANDIDATE", data);
  },
  setFilterPremierCandidate({ commit }, data) {
    commit("SET_FILTER_PREMIER_CANDIDATE", data);
  },
  setFilterCandidateExport({ commit }, data) {
    commit("SET_FILTER_CANDIDATE_EXPORT", data);
  },
  get({ commit }, url) {
    return api.get(url).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  getTotal({ commit }, params) {
    return api.get(params.url).then(
      (response) => {
        commit("GET_TOTALS", { slug: params.state, total: params.stateTotal, datas: response.data.resource });
        return Promise.resolve(response.data);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  save({ commit }, params) {
    return api.post(params.url, { resource: params.data }).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  edit({ commit }, params) {
    let url = `${params.url}/${params.data.id}`
    if (params.data.imms_uuid !== undefined && params.data.imms_uuid !== null && params.url.includes('imms_table')) {
      url = `${params.url}/${params.data.imms_uuid}`
    }
    return api.put(url, params.data).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  delete({ commit }, url) {
    return api.delete(url).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
};

export const mutations = {
  SET_STEP_VIEW(state, data) {
    state.isStep.view = data.view;
    state.isStep.index = data.index;
  },
  SET_FILTER(state, data) {
    state.filter = data;
  },
  SET_APPENDIX(state, data) {
    state.appendix = data;
  },
  RESET_FILTER(state) {
    state.filter = {
      search: null,
      start_date: null,
      end_date: null,
      activeBtnIndex: 100,
      selectedStatus: null,
      spouseName: null,
      appNumber: null,
      applicantEmail: null,
      applicantFullName: null,
      cicAppNumber: null,
      applicationTypeName: null,
      internalFileNumber: null
    };
  },
  SET_FILTER_JOBBOARD(state, data) {
    state.filterJobboard = data;
  },
  RESET_FILTER_JOBBOARD(state) {
    state.filterJobboard = {
      search: "",
      searchAgentId: "",
      searchCandidateId: "",
      activeBtnIndex: 100,
      pagination: {
        page: 1,
        itemsPerPage: 50,
        sortBy: 'expiry_date',
        sortDesc: true
      }
    };
  },
  SET_FILTER_CANDIDATE(state, data) {
    state.filterCandidate = data;
  },
  RESET_FILTER_CANDIDATE(state) {
    state.filterCandidate = {
      name: "",
      username: "",
      jobTitle: "",
      experience: "",
      agent: "",
      education: "",
      score: "",
      job: "",
      searchCountryId: "",
      inCompleted: "",
      had_consultation: false,
      pagination: {
        itemsPerPage: 20,
        sortBy: "created_date",
        sortDesc: true,
        totalItems: 0,
        page: 1
      }
    };
  },
  SET_FILTER_PREMIER_CANDIDATE(state, data) {
    state.filterPremierCandidate = data;
  },
  RESET_FILTER_PREMIER_CANDIDATE(state) {
    state.filterPremierCandidate = {
      name: "",
      username: "",
      jobTitle: "",
      experience: "",
      agent: "",
      education: "",
      score: "",
      job: "",
      searchCountryId: "",
      inCompleted: "",
      had_consultation: false,
      pagination: {
        itemsPerPage: 20,
        sortBy: "created_date",
        sortDesc: true,
        totalItems: 0,
        page: 1
      }
    };
  },
  SET_FILTER_CANDIDATE_EXPORT(state, data) {
    state.filterCandidateExport = data;
  },
  RESET_FILTER_CANDIDATE_EXPORT(state) {
    state.filterCandidateExport = {
      searchCountryId: "",
      searchUsername: "",
      searchCandidateFn: "",
      searchCandidateLn: "",
      inCompleted: "",
      experience: "",
      currentPage: 1,
      pageSize: 50,
      totalPage: 0
    };
  },
  SET_AGREEMENT_FILTER(state, data) {
    state.filter = data;
  },
  GET_TOTALS(state, data) {
    var total = totalAmount(data.datas);
    state[data.total] = total;
    state[data.slug] = data.datas;
  }
};
