<template>
  <div class="mt-3">
    <el-form :model="form" ref="form" :rules="rules" label-position="top" class="mt-3 personal-details">
      <v-card>
        <v-card-title>
          <span>{{ sectionLabel }}</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
            {{sectionInfoBox}}
          </v-alert>
          <v-row dense class="medium-space">
            <v-col cols="12" sm="6">
              <el-form-item label="I want service in" prop="service_in">
                <el-select id="service_in" v-model="form.service_in" placeholder="Select" @change="handleAutoSave(i, form)" style="width: 100%;">
                  <el-option value="English">English</el-option>
                  <el-option value="French">French</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6">
              <el-form-item label="Does the child have special needs that require accommodation?" prop="accommodation">
                <el-select id="accommodation" v-model="form.accommodation" placeholder="Select" @change="handleAutoSave(i, form)" style="width: 100%;">
                  <el-option value="Yes">Yes</el-option>
                  <el-option value="No">No</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="form.accommodation === 'Yes'">
              <el-form-item label="If yes, explain:" prop="accommodation_other">
                <el-input id="accommodation_other" v-model="form.accommodation_other" @change="handleAutoSave(i, form)" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="medium-space">
            <v-col cols="12" sm="6">
              <el-form-item label="Have you applied for the child to obtain citizenship in the past?" prop="applied">
                <el-select id="applied" v-model="form.applied" placeholder="Select" @change="handleAutoSave(i, form)" style="width: 100%;">
                  <el-option value="Yes">Yes</el-option>
                  <el-option value="No">No</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="form.applied === 'Yes'">
              <el-form-item label="When?" prop="year">
                <el-input id="year" type="number" placeholder="Year" v-model="form.year" @change="handleAutoSave(i, form)" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="medium-space">
            <v-col cols="12" sm="6">
              <el-form-item label="The person applying for the child is the" prop="person_applying">
                <el-select id="person_applying" v-model="form.person_applying" placeholder="Select" @change="handleAutoSave(i, form)" style="width: 100%;">
                  <el-option value="Parent">Parent</el-option>
                  <el-option value="Guardian">Guardian</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6">
              <el-form-item label="Print your name" prop="print_name">
                <el-input id="print_name" placeholder="Print your name" v-model="form.print_name" @change="handleAutoSave(i, form)" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text class="p-n">
          <v-row dense class="medium-space">
            <v-col cols="12" sm="6" md="2">
              <el-form-item label="Is the child legally adopted?" prop="legally" @change="handleAutoSave(i, form)"></el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <el-form-item prop="legally">
                <el-select id="legally" v-model="form.legally" placeholder="Select" @change="handleAutoSave(i, form)" style="width: 100%;">
                  <el-option value="No">No</el-option>
                  <el-option value="Yes">Yes</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <v-alert type="info" border="left" dense>
                <strong>NOTE:</strong> Guardians can apply but one of the natural or adoptive parents must be a Canadian citizen or applying to become a
                Canadian citizen. you must provide a birth certificate, adoption order or a legal document showing guardianship.
              </v-alert>
            </v-col>
            <v-col cols="12" sm="6" md="8" lg="9">
              <el-form-item label="What is your Client ID # or Unique Client Identifier (UCI) as shown exactly on your most recent immigration document?" prop="client_id">
                <el-input id="client_id" v-model="form.client_id" placeholder="UCI" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="medium-space">
            <v-col cols="12">
              <div class="body-2 mb-2">Child's name (<strong>exactly</strong> as it appears on the immigration document)</div>
              <v-divider class="mb-3"></v-divider>
            </v-col>
            <v-col class="ml-3 noflex"></v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Last Name" prop="lname">
                <el-input id="lname" v-model="form.lname" style="width: 100%;" @change="handleAutoSave(i, form)" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Middle Name" prop="middlename">
                <el-input id="middlename" v-model="form.middlename" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="First Name" prop="fname">
                <el-input id="fname" v-model="form.fname" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="medium-space">
            <v-col cols="12">
              <div class="body-2 mb-2">Your current name, if different from above.</div>
              <v-divider class="mb-3"></v-divider>
            </v-col>
            <v-col class="noflex ml-3"></v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Last Name" prop="cur_lname">
                <el-input id="cur_lname" v-model="form.cur_lname" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Middle Name" prop="cur_middlename">
                <el-input id="cur_middlename" v-model="form.cur_middlename" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="First Name" prop="cur_fname">
                <el-input id="cur_fname" v-model="form.cur_fname" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="medium-space">
            <v-col cols="12">
              <div class="body-2 mb-2">List any other names the child has used in the past or now uses (i.e. aliases, birth name, nickname.) These names will not appear on the child's certificate of citizenship.</div>
              <v-divider class="mb-3"></v-divider>
            </v-col>
            <v-col class="noflex ml-3"></v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Last Name" prop="other_lname">
                <el-input id="other_lname" v-model="form.other_lname" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Middle Name" prop="other_middlename">
                <el-input id="other_middlename" v-model="form.other_middlename" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="First Name" prop="other_fname">
                <el-input id="other_fname" v-model="form.other_fname" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="medium-space">
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Date of Birth" prop="date_birth">
                <el-date-picker id="date_birth" value-format="yyyy-MM-dd" v-model="form.date_birth" v-on:click.native="checkDate($event)" @change="handleAutoSave(i, form)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Place of Birth (City/Town)" prop="birth_place">
                <el-input id="birth_place" v-model="form.birth_place" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Country of Birth" prop="birth_country_id">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select id="birth_country_id" v-model="form.birth_country_id" placeholder="Select" @change="handleAutoSave(i, form)" class="el-input__inner">
                      <option v-for="(itemC, index) in country" v-bind:key="index" :value="itemC.id" :label="itemC.country_name">{{ itemC.country_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Indicate the child's current citizenship" prop="citizenship">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select id="citizenship" v-model="form.citizenship" placeholder="Select" @change="handleAutoSave(i, form)" class="el-input__inner">
                      <option v-for="(itemC, index) in country" v-bind:key="index" :value="itemC.id" :label="itemC.country_name">{{ itemC.country_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Gender" prop="gender">
                <el-select id="gender" filterable v-model="form.gender" placeholder="Select" @change="handleAutoSave(i, form)" style="width: 100%;">
                  <el-option value="Male">Male</el-option>
                  <el-option value="Female">Female</el-option>
                  <el-option value="Unspecified">Unspecified</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Marital Status" prop="maritial_status">
                <el-select v-model="form.maritial_status" placeholder="Select" @change="handleAutoSave(i, form)" style="width: 100%;">
                  <el-option :value="itemS.name" v-for="(itemS, index) in maritalStatus" v-bind:key="index">{{ itemS.name }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <personal-information-family-member :form="form" sectionLabel="Personal Information"></personal-information-family-member>

      <v-card>
        <v-card-title>
          <span>Contact Information</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-row dense class="medium-space">
            <v-col cols="12">
              <div class="body-2 mb-2 pull-left">Home address</div>
            </v-col>
            <v-col cols="12">
              <v-divider class="mb-3"></v-divider>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="8">
              <el-form-item label="No. and street" prop="address">
                <el-input id="address" v-model="form.address" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <el-form-item label="Apt/Unit" prop="unit">
                <el-input id="unit" type="number" value="number" v-model="form.unit" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item label="City/Town" prop="city">
                <el-input id="city" v-model="form.city" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item label="Province" prop="province">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select id="province" v-model="form.province" placeholder="Select" @change="handleAutoSave(i, form)" class="el-input__inner">
                      <option v-for="(itemP, index) in province" v-bind:key="index" :value="itemP.province_name" :label="itemP.province_name">{{ itemP.province_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item label="Postal code" prop="postal_code">
                <el-input id="postal_code" v-model="form.postal_code" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="medium-space">
            <v-col cols="12" class="mb-3">
              <div class="body-2 pull-left">Is the mailing address the same as the home address?</div>
              <el-radio-group id="mailing" class="ml-3 mt-1 pull-left" @change="handleAutoSave(i, form)" v-model="form.mailing">
                <el-radio label="No">No</el-radio>
                <el-radio label="Yes">Yes</el-radio>
              </el-radio-group>
            </v-col>
          </v-row>
          <v-row dense class="medium-space" v-if="form.mailing !== 'Yes'">
            <v-col cols="12">
              <div class="body-2 mb-2 pull-left">Mailing address</div>
            </v-col>
            <v-col cols="12">
              <v-divider class="mb-3"></v-divider>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="8">
              <el-form-item label="No. and street" prop="mailing_address">
                <el-input id="mailing_address" v-model="form.mailing_address" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item label="Apt/Unit" prop="mailing_unit">
                <el-input id="mailing_unit" type="number" value="number" v-model="form.mailing_unit" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item label="City/Town" prop="mailing_city">
                <el-input id="mailing_city" v-model="form.mailing_city" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item label="Province" prop="mailing_province">
                <div class="el-select native-select">
                  <div class="el-input">
                    <select id="mailing_province" v-model="form.mailing_province" placeholder="Select" @change="handleAutoSave(i, form)" class="el-input__inner">
                      <option v-for="(itemP, index) in province" v-bind:key="index" :value="itemP.province_name" :label="itemP.province_name">{{ itemP.province_name }}</option>
                    </select>
                  </div>
                </div>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <el-form-item label="Postal code" prop="mailing_postal_code">
                <el-input id="mailing_postal_code" v-model="form.mailing_postal_code" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense class="medium-space">
            <v-col cols="12"><v-divider class="mb-3"></v-divider></v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Home Telephone" prop="home_phone">
                <div v-if="phoneMasked" class="el-input">
                  <masked-input id="home_phone" v-model="form.home_phone" :mask="phoneMask" class="el-input__inner" />
                </div>
                <el-input v-else type="number" id="home_phone" v-model="form.home_phone" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Work Telephone" prop="work_phone">
                <div v-if="phoneMasked" class="el-input">
                  <masked-input id="work_phone" v-model="form.work_phone" :mask="phoneMask" class="el-input__inner" />
                </div>
                <el-input v-else type="number" id="work_phone" v-model="form.work_phone" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Cell Telephone" prop="cell_phone">
                <div v-if="phoneMasked" class="el-input">
                  <masked-input :mask="phoneMask" id="cell_phone" v-model="form.cell_phone" class="el-input__inner" />
                </div>
                <el-input v-else type="number" id="cell_phone" v-model="form.cell_phone" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <el-form-item label="Email address" prop="email" :rules="[{ type: 'email', message: 'Please input correct email address', trigger: 'blur,change' }]">
                <el-input type="email" id="email" v-model="form.email" @change="handleAutoSave(i, form)" style="width: 100%;" />
              </el-form-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-actions class="pa-3">
          <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="onSave()">
            {{ $t('global.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </el-form>
    <div class="error--snack error" v-if="errorMessage.length > 0">
      <template v-for="(message, index) in errorMessage">
        <div :key="index" class="error--snack__content">
            {{ message.message }}
        </div>
      </template>
    </div>
    <details-parents
      ref="parents"
      :sectionId="sectionId"
      sectionLabel="Details of Child's Parents"
      :memberUID="memberUID"
      :memberID="memberID"
      :datas="datas"
      @onSave="onSave"
      ></details-parents>
    <citizenship-act
      ref="citizenshipAct"
      :sectionId="sectionId"
      sectionLabel="Prohibitions Under the Citizenship Act"
      :memberUID="memberUID"
      :memberID="memberID"
      :datas="datas"
      @onSave="onSave"
    ></citizenship-act>
    <permanent-resident
      ref="permanentResident"
      :sectionId="sectionId"
      sectionLabel="Permanent Resident"
      :memberUID="memberUID"
      :memberID="memberID"
      :datas="datas"
      @onSave="onSave"
    ></permanent-resident>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DetailsParents from './parents'
import PermanentResident from './permanent_resident'
import CitizenshipAct from './citizenship_act'
import PersonalInformationFamilyMember from './../sections/personal_information_family_members'

import autosave from '@/mixins/autosave.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [autosave],
  data() {
    return {
      i: 0,
      formNode: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        service_in: null,
        accommodation: null,
        accommodation_other: null,
        person_applying: null,
        year: null,
        applied: null,
        print_name: null,
        legally: null,
        fname: null,
        lname: null,
        middlename: null,
        cur_fname: null,
        cur_lname: null,
        cur_middlename: null,
        other_fname: null,
        other_lname: null,
        other_middlename: null,
        date_birth: null,
        birth_place: null,
        birth_country_id: null,
        citizenship: null,
        street: null,
        maritial_status: null,
        gender: null,
        home_phone: null,
        work_phone: null,
        cell_phone: null,
        country_id: null,
        address: null,
        email: null,
        postal_code: null,
        unit: null,
        city: null,
        province: null,
        mailing_address: null,
        mailing_country_id: null,
        mailing_city: null,
        mailing_postal_code: null,
        mailing_province: null,
        mailing: null,
        client_id: null,
        mailing_unit: null
      },
      fields: ['mailing_unit', 'client_id', 'mailing', 'mailing_province', 'mailing_postal_code', 'mailing_city', 'mailing_country_id', 'mailing_address', 'province', 'city', 'unit', 'postal_code', 'email', 'address', 'country_id', 'cell_phone', 'home_phone', 'gender', 'maritial_status', 'street', 'citizenship', 'birth_country_id', 'birth_place', 'date_birth', 'other_middlename', 'other_lname', 'other_fname', 'cur_middlename', 'cur_lname', 'cur_fname', 'middlename', 'lname', 'fname', 'legally', 'print_name', 'service_in', 'accommodation', 'accommodation_other', 'person_applying', 'year', 'applied'],
      phoneMasked: false,
      maritalStatus: [
        { name: 'Single' },
        { name: 'Married' },
        { name: 'Common-Law' },
        { name: 'Widowed' },
        { name: 'Divorced' },
        { name: 'Separated' }
      ],
      rules: {
        uci_number: [
          { validator: this.checkUCI, trigger: 'blur' }
        ]
      },
      url: 'imms_table/application_citizenship_minor',
      state: 'citizenshipMinor',
      errorMessage: [],
      selectProvine: false,
      loading: false
    }
  },
  mounted() {
    if (this.datas !== undefined) {
      this.checkFields(this.fields, this.datas, this.sectionId)
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  components: {
    DetailsParents,
    PermanentResident,
    CitizenshipAct,
    PersonalInformationFamilyMember
  },
  computed: {
    ...mapGetters({
      members: 'generic/all',
      province: 'other/province',
      country: 'other/country'
    }),
    item: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    },
    form: function() {
      if (this.item.length > 0) {
        return this.item[0]
      }
      else {
        return this.formNode
      }
    }
  },
  created() {
    this.fetchData()
    this.$store.dispatch('other/getProvince', 42)
  },
  methods: {
    checkDate: function(event) {
      this.$emit('checkDate', event)
    },
    checkUCI(rule, value, callback) {
      var idx = this.errorMessage.map(p => 'Have you applied for Canadian Citizenship before?').indexOf(rule.field)

      if (value) {
        if (value.toString().length === 10 || value.toString().length === 8) {
          if (idx !== -1) this.errorMessage.splice(idx, 1)
          callback()
        }
        else {
          if (idx === -1) this.errorMessage.push({ "message": "UCI Number field to 8 or 10 digits", "field": "uci_number" })
          callback(new Error(' '))
        }
      }
      else {
        if (idx !== -1) this.errorMessage.splice(idx, 1)
        callback()
      }
    },
    fetchData() {
      this.$Progress.start()
      var url = `table/application_citizenship_minor?filter=member_uid=${this.memberUID}` + '&order=id DESC'
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    onSave() {
      this.submitForm()
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true

          if (this.form.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': this.form, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                this.form.id = response.data.resource[0].id
                this.form.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.loading = false
              this.$Progress.finish()
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.loading = false
              this.$Progress.fail()
            })
        }
        else {
          return false
        }
      })
    }
  }
}
</script>

<style>
  .noflex{
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
  }
</style>
