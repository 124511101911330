<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ $t('title.imm_0008_passport') }}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <v-row dense class="medium-space">
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'passport_number')">
          <el-form-item :label="$t('imm_0008.passport_number')" prop="passport_number">
            <el-input @change="saveIMM(form)" v-model="form.passport_number" style="width: 100%;" />
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'passport_issue_country_id')">
          <el-form-item :label="$t('imm_0008.passport_issue_country_id')" prop="passport_issue_country_id">
            <div class="el-select native-select">
              <div class="el-input">
                <select @change="saveIMM(form)" v-model="form.passport_issue_country_id" placeholder="Select" class="el-input__inner">
                  <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                </select>
              </div>
            </div>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'passport_issue_date')">
          <el-form-item :label="$t('imm_0008.passport_issue_date')" prop="passport_issue_date">
            <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.passport_issue_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'passport_expire_date')">
          <el-form-item :label="$t('imm_0008.passport_expire_date')" prop="passport_expire_date">
            <el-date-picker @change="saveIMM(form)" value-format="yyyy-MM-dd" v-model="form.passport_expire_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  computed: mapGetters({
    country: 'other/country'
  }),
  mounted() {
    if (this.isDataValidation) {
      var item = {
        passport_number: null,
        passport_issue_country_id: null,
        passport_issue_date: null,
        passport_expire_date: null
      }

      this.checkItem(this.form, item)
    }
  }
}
</script>