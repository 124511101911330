<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text class="p-n">
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{ sectionInfoBox }}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="mt-3 form">
          <v-card>
            <v-card-title>
              <span>Association Standards required and obtained Personal information</span>
              <v-spacer />
            </v-card-title>
            <v-card-text class="p-n">
              <v-row dense class="medium-space">
                <v-col cols="12">
                  <div class="el-form-item">
                    <div class="body-1">Select your Industry and Association standards</div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item prop="js">
                    <el-checkbox id="js" v-model="form.js">Journeyperson Certification</el-checkbox>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item prop="cpsa">
                    <el-checkbox id="cpsa" v-model="form.cpsa">College of Physicians and Surgeons of Alberta</el-checkbox>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item prop="carna">
                    <el-checkbox id="carna" v-model="form.carna">College and Association of Registered Nurses of Alberta (CARNA)</el-checkbox>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item prop="apega">
                    <el-checkbox id="apega" v-model="form.apega">Association of Professional Engineers and Geoscientists of Alberta</el-checkbox>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item prop="whmis">
                    <el-checkbox id="whmis" v-model="form.whmis">Certification in Workplace Hazardous Materials Information System (WHMIS)</el-checkbox>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item prop="other">
                    <el-checkbox id="other" v-model="form.other">Other standards required and obtained (specify below)</el-checkbox>
                  </el-form-item>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mt-3">
            <v-card-title>
              <span>Marketing</span>
              <v-spacer />
            </v-card-title>
            <v-card-text class="p-n">
              <v-row dense class="medium-space">
                <v-col cols="12">
                  <div class="el-form-item">
                    <div class="body-1">How did you learn about the AINP? (check all that apply)</div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item prop="apm">
                    <el-checkbox id="apm" v-model="form.apm">Alberta promotional material</el-checkbox>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item prop="aw">
                    <el-checkbox id="aw" v-model="form.aw">AINP website</el-checkbox>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item prop="fm">
                    <el-checkbox id="fm" v-model="form.fm">Family member</el-checkbox>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item prop="iais">
                    <el-checkbox id="iais" v-model="form.iais">Immigrate to Alberta Information Service</el-checkbox>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item prop="ra">
                    <el-checkbox id="ra" v-model="form.ra">Recruitment agency</el-checkbox>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item prop="agw">
                    <el-checkbox id="agw" v-model="form.agw">Alberta government webinar</el-checkbox>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <el-form-item prop="other_marketing">
                    <el-checkbox id="other_marketing" v-model="form.other_marketing">Other</el-checkbox>
                  </el-form-item>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pa-3">
              <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm()">
                {{ $t('global.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </el-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  data() {
    return {
      offsetTop: 0,
      formNode: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        js: null,
        cpsa: null,
        carna: null,
        apega: null,
        whmis: null,
        other: null,
        apm: null,
        aw: null,
        fm: null,
        iais: null,
        ra: null,
        agw: null,
        other_marketing: null
      },
      url: 'imms_table/prov_alberta_industry_association',
      state: 'albertaIndustry',
      fields: ['js', 'cpsa', 'carna', 'apega', 'whmis', 'other', 'apm', 'aw', 'fm', 'iais', 'ra', 'agw', 'other_marketing'],
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    item: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    },
    form: function() {
      if (this.item.length > 0) {
        return this.item[0]
      }
      else {
        return this.formNode
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = 'table/prov_alberta_industry_association?filter=member_uid=' + this.memberUID
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true

          if (this.form.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': this.form, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                this.form.id = response.data.resource[0].id
                this.form.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    }
  }
}
</script>
