<template>
  <div v-if="hideSection">
    <v-card class="mt-3 bquestion">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert
          type="info"
          icon="info"
          border="left"
          dense
          class="mb-3"
          v-if="sectionInfoBox"
        >
          {{ sectionInfoBox }}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="form">
          <v-row
            dense
            class="radio-parent"
            v-if="dataValidation(form, 'one_a')"
          >
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item">
                <label class="el-form-item__label">
                  <b>1a.</b>
                  Within the past two years, have you or a family member ever
                  had tuberculosis of the lungs or been in close contact with a
                  person with tuberculosis?
                </label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="one_a">
                <el-radio-group
                  @change="autoSaveSubmit(form)"
                  v-model="form.one_a"
                  class="mt-1"
                >
                  <el-radio :label="$t('global.yes')">
                    {{ $t('global.yes') }}
                  </el-radio>
                  <el-radio :label="$t('global.no')">
                    {{ $t('global.no') }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row
            dense
            class="radio-parent"
            v-if="dataValidation(form, 'one_b')"
          >
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item">
                <label class="el-form-item__label">
                  <b>1b.</b>
                  Do you have any physical or mental disorder that would require
                  social and/or health services, other than medication, during a
                  stay in Canada?
                </label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="one_b">
                <el-radio-group
                  @change="autoSaveSubmit(form)"
                  v-model="form.one_b"
                  class="mt-1"
                >
                  <el-radio :label="$t('global.yes')">
                    {{ $t('global.yes') }}
                  </el-radio>
                  <el-radio :label="$t('global.no')">
                    {{ $t('global.no') }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row
            dense
            class="radio-parent"
            v-if="dataValidation(form, 'one_c')"
          >
            <v-col cols="12">
              <div class="el-form-item">
                <label class="el-form-item__label">
                  <b>1c.</b>
                  If you answered 'YES' to question 1a) or 1b), please provide
                  details and the name of the family member (if applicable)
                </label>
                <div class="el-form-item__content">
                  <el-input
                    @change="autoSaveSubmit(form)"
                    :disabled="form.one_a == 'No' && form.one_b == 'No'"
                    v-model="form.one_c"
                    style="width: 100%"
                    type="textarea"
                    :rows="4"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
          <v-divider
            class="mb-3"
            v-if="dataValidation(form, 'two_a')"
          ></v-divider>
          <v-row
            dense
            class="radio-parent"
            v-if="dataValidation(form, 'two_a')"
          >
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item">
                <label class="el-form-item__label">
                  <b>2a.</b>
                  Have you ever remained beyond the validity of your status,
                  attended school without authorization or worked without
                  authorization in Canada?
                </label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="two_a">
                <el-radio-group
                  @change="autoSaveSubmit(form)"
                  v-model="form.two_a"
                  class="mt-1"
                >
                  <el-radio :label="$t('global.yes')">
                    {{ $t('global.yes') }}
                  </el-radio>
                  <el-radio :label="$t('global.no')">
                    {{ $t('global.no') }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row
            dense
            class="radio-parent"
            v-if="dataValidation(form, 'two_b')"
          >
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item">
                <label class="el-form-item__label">
                  <b>2b.</b>
                  Have you ever been refused a visa or permit, denied entry or
                  ordered to leave Canada or any other country
                </label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="two_b">
                <el-radio-group
                  @change="autoSaveSubmit(form)"
                  v-model="form.two_b"
                  class="mt-1"
                >
                  <el-radio :label="$t('global.yes')">
                    {{ $t('global.yes') }}
                  </el-radio>
                  <el-radio :label="$t('global.no')">
                    {{ $t('global.no') }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row
            dense
            class="radio-parent"
            v-if="dataValidation(form, 'two_c')"
          >
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item">
                <label class="el-form-item__label">
                  <b>2c.</b>
                  Have you previously applied to enter or remain in Canada?
                </label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="two_c">
                <el-radio-group
                  @change="autoSaveSubmit(form)"
                  v-model="form.two_c"
                  class="mt-1"
                >
                  <el-radio :label="$t('global.yes')">
                    {{ $t('global.yes') }}
                  </el-radio>
                  <el-radio :label="$t('global.no')">
                    {{ $t('global.no') }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row
            dense
            class="radio-parent"
            v-if="dataValidation(form, 'two_d')"
          >
            <v-col cols="12">
              <div class="el-form-item">
                <label class="el-form-item__label">
                  <b>2d.</b>
                  If you answered 'YES' to question 2a) or 2b) or 2c), please
                  provide details.
                </label>
                <div class="el-form-item__content">
                  <el-input
                    @change="autoSaveSubmit(form)"
                    :disabled="
                      form.two_a == 'No' &&
                      form.two_b == 'No' &&
                      form.two_c == 'No'
                    "
                    v-model="form.two_d"
                    style="width: 100%"
                    type="textarea"
                    :rows="4"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
          <v-divider
            class="mb-3"
            v-if="dataValidation(form, 'three_a')"
          ></v-divider>
          <v-row
            dense
            class="radio-parent"
            v-if="dataValidation(form, 'three_a')"
          >
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item">
                <label class="el-form-item__label">
                  <b>3a.</b>
                  Have you ever committed, been arrested for, or been charged
                  with or convicted of any criminal offence in any country?
                </label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="three_a">
                <el-radio-group
                  @change="autoSaveSubmit(form)"
                  v-model="form.three_a"
                  class="mt-1"
                >
                  <el-radio :label="$t('global.yes')">
                    {{ $t('global.yes') }}
                  </el-radio>
                  <el-radio :label="$t('global.no')">
                    {{ $t('global.no') }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row
            dense
            class="radio-parent"
            v-if="dataValidation(form, 'three_b')"
          >
            <v-col cols="12">
              <div class="el-form-item">
                <label class="el-form-item__label">
                  <b>3b.</b>
                  If you answered 'YES' to question 3a) above, please provide
                  details.
                </label>
                <div class="el-form-item__content">
                  <el-input
                    @change="autoSaveSubmit(form)"
                    :disabled="form.three_a == 'No'"
                    v-model="form.three_b"
                    style="width: 100%"
                    type="textarea"
                    :rows="4"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
          <v-divider
            class="mb-3"
            v-if="dataValidation(form, 'four_a')"
          ></v-divider>
          <v-row
            dense
            class="radio-parent"
            v-if="dataValidation(form, 'four_a')"
          >
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item">
                <label class="el-form-item__label">
                  <b>4a.</b>
                  Did you serve in any military, militia, or civil defence unit
                  or serve in security organization or police force (including
                  non obligatory national service, reserve or volunteer units)?
                </label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="four_a">
                <el-radio-group
                  @change="autoSaveSubmit(form)"
                  v-model="form.four_a"
                  class="mt-1"
                >
                  <el-radio :label="$t('global.yes')">
                    {{ $t('global.yes') }}
                  </el-radio>
                  <el-radio :label="$t('global.no')">
                    {{ $t('global.no') }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row
            dense
            class="radio-parent"
            v-if="dataValidation(form, 'four_b')"
          >
            <v-col cols="12">
              <div class="el-form-item">
                <label class="el-form-item__label">
                  <b>4b.</b>
                  If you answered 'YES' to question 4a), please provide dates of
                  service and countries where you served.
                </label>
                <div class="el-form-item__content">
                  <el-input
                    @change="autoSaveSubmit(form)"
                    :disabled="form.four_a == 'No'"
                    v-model="form.four_b"
                    style="width: 100%"
                    type="textarea"
                    :rows="4"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
          <v-divider
            class="mb-3"
            v-if="dataValidation(form, 'five')"
          ></v-divider>
          <v-row dense class="radio-parent" v-if="dataValidation(form, 'five')">
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item">
                <label class="el-form-item__label">
                  <b>5a.</b>
                  Are you, or have you even been a member or associated with any
                  political party, or other group or organization which has
                  engaged in or advocated violence as a means to achieving a
                  political or religious objective, or which has been associated
                  with criminal activity at any time?
                </label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="five">
                <el-radio-group
                  @change="autoSaveSubmit(form)"
                  v-model="form.five"
                  class="mt-1"
                >
                  <el-radio :label="$t('global.yes')">
                    {{ $t('global.yes') }}
                  </el-radio>
                  <el-radio :label="$t('global.no')">
                    {{ $t('global.no') }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
          <v-divider
            class="mb-3"
            v-if="dataValidation(form, 'six')"
          ></v-divider>
          <v-row dense class="radio-parent" v-if="dataValidation(form, 'six')">
            <v-col cols="7" sm="8" md="10">
              <div class="el-form-item">
                <label class="el-form-item__label">
                  <b>6a.</b>
                  Have you ever witnessed or participated in the ill treatment
                  of prisoners or civilians, looting or desecration of religious
                  buildings?
                </label>
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <el-form-item prop="six">
                <el-radio-group
                  @change="autoSaveSubmit(form)"
                  v-model="form.six"
                  class="mt-1"
                >
                  <el-radio :label="$t('global.yes')">
                    {{ $t('global.yes') }}
                  </el-radio>
                  <el-radio :label="$t('global.no')">
                    {{ $t('global.no') }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn
          :loading="autoSave"
          :disabled="autoSave"
          color="primary"
          @click.native="autoSaveSubmit(form)"
        >
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js';

export default {
  mixins: [datavalidation],
  props: {
    sectionLabel: {
      type: String,
    },
    sectionInfoBox: {
      type: String,
    },
    memberUID: {
      type: String,
      required: true,
    },
    memberID: {
      type: String,
      required: true,
    },
    layout: {
      type: String,
    },
    isDataValidation: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: +this.memberID,
        one_a: null,
        one_b: null,
        one_c: null,
        two_a: null,
        two_b: null,
        two_c: null,
        two_d: null,
        three_a: null,
        three_b: null,
        four_a: null,
        four_b: null,
        five: null,
        six: null,
        sec_org_id: parseInt(localStorage.getItem('org_id')),
      },
      url: 'imms_table/application_background_question',
      getUrl: `table/application_background_question?filter=member_uid=${this.memberUID}`,
    };
  },
  watch: {
    memberUID: function () {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$Progress.start();
      this.$store
        .dispatch('store/get', `${this.getUrl}`)
        .then((response) => {
          if (response.resource.length > 0) {
            this.form = response.resource[0];
            if (this.isDataValidation) {
              this.checkRow(response.resource[0]);
            }
          } else {
            if (this.isDataValidation) {
              this.checkRow(this.form);
            }
          }
          this.$Progress.finish();
        })
        .catch((err) => {
          this.$Progress.fail();
        });
    },
    checkRow(data) {
      if (data.one_a == 'No' && data.one_b == 'No') {
        delete this.form.one_c;
      }
      if (data.two_a == 'No' && data.two_b == 'No' && data.two_c == 'No') {
        delete this.form.two_d;
      }
      if (data.three_a == 'No') {
        delete this.form.three_b;
      }
      if (data.four_a == 'No') {
        delete this.form.four_b;
      }

      delete this.form.sec_org_id;

      this.checkItem(data, this.form);
    },
  },
};
</script>
