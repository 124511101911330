<template>
  <v-card class="mt-3" v-if="!isDataValidation">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <v-row dense class="medium-space">
        <v-col cols="12">
          <div class="body-1 mb-3">I am appling for one or more of the following:</div>
        </v-col>
        <v-col cols="12">
          <el-form-item prop="appling_for1" class="pl-2">
            <el-checkbox id="appling_for1" v-model="form.appling_for1" :disabled="form.appling_for2">{{ question1 }}</el-checkbox>
          </el-form-item>
        </v-col>
        <v-col cols="12">
          <el-form-item prop="appling_for2" class="pl-2">
            <el-checkbox id="appling_for2" v-model="form.appling_for2" :disabled="check">{{ question2 }}</el-checkbox>
          </el-form-item>
        </v-col>
        <v-col cols="12">
          <el-form-item prop="appling_for3" class="pl-2">
            <el-checkbox id="appling_for3" v-model="form.appling_for3" :disabled="form.appling_for2">{{ question3 }}</el-checkbox>
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  computed: {
    check: function() {
      return this.form.appling_for3 && this.form.appling_for1 ? true : false
    }
  },
  data() {
    return {
      question1: 'Extension of my temporary residence status',
      question2: 'Restoration of temporary residence status as a visitor',
      question3: 'Initial temporary residence permit or extension of temporary residence permit'
    }
  },
  mounted() {
    if (parseInt(this.$route.params.apptypeId) == 23) {
      this.question1 = 'Apply for a study permit for the first time or extend my study permit'
      this.question2 = 'Restore my status as a student'
      this.question3 = 'Get a new temporary resident permit (for inadmissible applicants only)'
    }
  }
}
</script>

<style>
  .el-checkbox__input{
    margin-right: 10px;
  }
</style>
