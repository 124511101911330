<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ $t('title.imm_0008_education_occupation') }}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <v-row dense class="medium-space">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-if="dataValidation(form, 'education_highest_level')"
        >
          <el-form-item
            :label="$t('imm_0008.education_highest_level')"
            prop="education_highest_level"
          >
            <el-select
              @change="saveIMM(form)"
              v-model="form.education_highest_level"
              placeholder="Select"
              style="width: 100%"
            >
              <el-option
                :value="item.name"
                v-for="(item, index) in educationLevel"
                v-bind:key="index"
              >
                {{ item.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-if="dataValidation(form, 'education_total_year')"
        >
          <el-form-item
            :label="$t('imm_0008.education_total_year')"
            prop="education_total_year"
          >
            <el-input
              @change="saveIMM(form)"
              type="number"
              v-model="form.education_total_year"
              style="width: 100%"
            />
          </el-form-item>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-if="dataValidation(form, 'occupation_current')"
        >
          <el-form-item
            :label="$t('imm_0008.occupation_current')"
            prop="occupation_current"
          >
            <el-input
              @change="saveIMM(form)"
              v-model="form.occupation_current"
              style="width: 100%"
            />
          </el-form-item>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-if="dataValidation(form, 'occupation_intended')"
        >
          <el-form-item
            :label="$t('imm_0008.occupation_intended')"
            prop="occupation_intended"
          >
            <el-input
              @change="saveIMM(form)"
              v-model="form.occupation_intended"
              style="width: 100%"
            />
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js';

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true,
    },
    sectionId: {
      type: Number,
    },
    sectionLabel: {
      type: String,
    },
    sectionInfoBox: {
      type: String,
    },
    isDataValidation: {
      type: Boolean,
    },
  },
  data() {
    return {
      educationLevel: [
        { name: 'Secondary or less' },
        { name: 'Trade/Apprenticeship Certificate/Diploma' },
        { name: 'Non-University Certificate/Diploma' },
        { name: 'Bachelor’s Degree' },
        { name: 'Post Graduate – No Degree' },
        { name: 'Post-Secondary – No Degree' },
        { name: 'Master’s Degree' },
        { name: 'Doctorate - PhD' },
        { name: 'None' },
      ],
    };
  },
  mounted() {
    if (this.isDataValidation) {
      let item = {
        education_highest_level: null,
        education_total_year: null,
        occupation_current: null,
        occupation_intended: null,
      };

      this.checkItem(this.form, item);
    }
  },
};
</script>
