<template>
  <el-form
    v-if="hideSection"
    :model="form"
    ref="formGeneric"
    :rules="rules"
    label-position="top">
    <v-card class="mt-3">
      <v-card-title>
        <span>{{ $t('title.imm_0008_current_marital_status') }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">{{sectionInfoBox}}</v-alert>
        <v-row dense class="medium-space">
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'current_maritial_status')">
            <el-form-item :label="$t('imm_0008.current_maritial_status')" prop="current_maritial_status">
              <el-select @change="saveIMM(form)" v-model="form.current_maritial_status" placeholder="Select" style="width: 100%;">
                <el-option :value="item.name" v-for="(item, index) in maritalStatus" v-bind:key="index">{{ item.name }}</el-option>
              </el-select>
            </el-form-item>
          </v-col>
          <template v-if="form.current_maritial_status == 'Married' || form.current_maritial_status == 'Common-Law'">
            <v-col cols="12" md="8" v-if="dataValidation(form, 'gen_datemarriage')">
              <el-form-item :label="$t('imm_0008.gen_datemarriage')" prop="gen_datemarriage">
                <el-date-picker @change="saveIMM(form)" :picker-options="datePickerOptions" value-format="yyyy-MM-dd" v-model="form.gen_datemarriage" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </v-col>
          </template>
        </v-row>
        <v-row dense class="medium-space" v-if="form.current_maritial_status == 'Married' || form.current_maritial_status == 'Common-Law'">
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'spouse_given_name')">
            <el-form-item :label="$t('imm_0008.spouse_given_name')" prop="spouse_given_name">
              <el-input @change="saveIMM(form)" v-model="form.spouse_given_name" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'spouse_middle_name')">
            <el-form-item :label="$t('imm_0008.spouse_middle_name')" prop="spouse_middle_name">
              <el-input @change="saveIMM(form)" v-model="form.spouse_middle_name" style="width: 100%;" />
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-if="dataValidation(form, 'spouse_family_name')">
            <el-form-item :label="$t('imm_0008.spouse_family_name')" prop="spouse_family_name">
              <el-input @change="saveIMM(form)" v-model="form.spouse_family_name" style="width: 100%;" />
            </el-form-item>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </el-form>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  data() {
    var checkCharacter = (rule, value, callback) => {
      var patt = new RegExp("[^ A-Za-z а-яА-ЯЁё]");
      var res = patt.test(value);

      if (res) {
        callback(new Error('Please only use alpha characters (abc)'));
      }
      else {
        callback();
      }
    };
    return {
      maritalStatus: [
        { name: 'Annulled Marriage' },
        { name: 'Common-Law' },
        { name: 'Divorced' },
        { name: 'Legally Separated' },
        { name: 'Married' },
        { name: 'Single' },
        { name: 'Unknown' },
        { name: 'Widowed' }
      ],
      rules: {
        spouse_given_name: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        spouse_middle_name: [
          { validator: checkCharacter, trigger: 'blur' }
        ],
        spouse_family_name: [
          { validator: checkCharacter, trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    if (this.isDataValidation) {
      var item = {
        current_maritial_status: null,
        gen_datemarriage: null,
        spouse_given_name: null,
        spouse_family_name: null
      }

      if (this.form.current_maritial_status !== 'Married' && this.form.current_maritial_status !== 'Common-Law') {
        delete item.gen_datemarriage
        delete item.spouse_given_name
        delete item.spouse_family_name
      }

      this.checkItem(this.form, item)
    }
  },
  methods: {
    saveIMM(formGeneric) {
      var that = this
      that.$refs.formGeneric.validate((valid) => {
        if (valid) {
          that.$store.dispatch('generic/edit', formGeneric)
        }
        else {
          return false
        }
      })
    }
  }
}
</script>
