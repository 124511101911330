<template>
  <v-card class="mt-3" v-if="hideSection">
    <v-card-title>
      <span>{{ $t('title.application_eduction_general_detail') }}</span>
    </v-card-title>
    <v-card-text>
      <v-alert type="info" border="left" dense class="mb-3" v-if="$t('title.application_eduction_general_detail') !== ''">
        {{ $t('title.application_eduction_general_detail') }}
      </v-alert>
      <el-form :model="form" ref="form" label-position="top">
        <v-row dense>
          <v-col cols="12">
            <div class="body-2 mb-3">{{ $t('global.application_eduction_general_detail') }}</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'elem_school')">
            <el-form-item prop="elem_school" class="small-input">
              <el-input @change="autoSaveSubmit(form)" v-model="form.elem_school" type="number" />
              <label class="el-form-item__label">{{ $t('application_eduction_general.elem_school') }}</label>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'secondary_school')">
            <el-form-item prop="secondary_school" class="small-input">
              <el-input @change="autoSaveSubmit(form)" v-model="form.secondary_school" type="number" />
              <label class="el-form-item__label">{{ $t('application_eduction_general.secondary_school') }}</label>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'college_year')">
            <el-form-item prop="college_year" class="small-input">
              <el-input @change="autoSaveSubmit(form)" v-model="form.college_year" type="number" />
              <label class="el-form-item__label">{{ $t('application_eduction_general.college_year') }}</label>
            </el-form-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'post_grad_year')">
            <el-form-item prop="post_grad_year" class="small-input mb-0">
              <el-input @change="autoSaveSubmit(form)" v-model="form.post_grad_year" :disabled="notApplicable" />
              <label class="el-form-item__label">{{ $t('application_eduction_general.post_grad_year') }}</label>
            </el-form-item>
            <el-checkbox v-model="notApplicable" @change="autoSaveSubmit(form);changeApplicable()">Not Applicable</el-checkbox>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="dataValidation(form, 'year_education')">
            <el-form-item prop="year_education" class="small-input">
              <el-input @change="autoSaveSubmit(form)" v-model="form.year_education" />
              <label class="el-form-item__label">Years of education</label>
            </el-form-item>
          </v-col>
        </v-row>
        <div class="mt-3">
          <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="autoSaveSubmit(form)">
            {{ $t('global.save') }}
          </v-btn>
        </div>
        <v-divider class="mt-3 mb-3"></v-divider>
      </el-form>
    </v-card-text>
  </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  mixins: [datavalidation],
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  data() {
    return {
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        elem_school: null,
        secondary_school: null,
        college_year: null,
        post_grad_year: null,
        year_education: null,
        sec_org_id: parseInt(localStorage.getItem('org_id'))
      },
      url: 'imms_table/application_eduction_general',
      getUrl: `table/application_eduction_general?filter=member_uid=${this.memberUID}&order=id DESC`,
      loading: false,
      notApplicable: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `${this.getUrl}`)
        .then(response => {
          if (response.resource.length > 0) {
            this.form = response.resource[0]

            if (this.form.post_grad_year == 'NA') {
              this.notApplicable = true
            }

            if (this.isDataValidation) {
              this.checkRow(response.resource[0])
            }
          }
          else {
            if (this.isDataValidation) {
              this.checkRow(this.form)
            }
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    checkRow(data) {
      var form = {
        elem_school: null,
        secondary_school: null,
        college_year: null,
        post_grad_year: null,
        year_education: null
      }

      this.checkItem(data, form)
    },
    changeApplicable() {
      if (this.notApplicable) {
        this.form.post_grad_year = 'NA'
      }
      else {
        this.form.post_grad_year = null
      }
    }
  }
}
</script>

<style>
  .small-input .el-input{
    width: 90px !important;
    float: left;
    margin-right: 15px;
    position: initial;
  }
  .small-input .el-form-item__content .el-form-item__label{
    line-height: 18px;
    display: block;
  }
</style>
