import moment from "moment";
import constants from "../constants.js"

export const getAmount = (amount) => {
  return amount > 0 ? amount / constants.CENT_TO_INT : 0
}

export const DefaultTransition = {
  functional: true,
  render(h, { children }) {
    const data = {
      attrs: { tag: "div", name: "toast", type: "transition" }
    };
    return h("transition-group", data, children);
  }
};

export const checkFields = (objArray, fields, sectionId) => {
  let checkItems = fields;
  let sectionData = [];

  checkItems.forEach(function(item) {
    if (parseFloat(item.section_id) == parseFloat(sectionId)) {
      sectionData.push(item);
    }
  });

  objArray.forEach(function(data) {
    let idx = sectionData.map((p) => p.col_name).indexOf(data);

    if (idx != -1) {
      addError(sectionData[idx], data);
    }
    else {
      removeField(data);
    }
  }, this);
};

function addError(field, item) {
  let errorMessage = field.error_message !== null ? field.error_message : "Error";
  let id = item;

  if ($("#" + id).is(".el-radio-group") || $("#" + id).is(".el-checkbox")) {
    $("#" + id)
      .parents(".el-form-item__content")
      .append('<div class="el-form-item__error">' + errorMessage + "</div>");
  }
  else {
    $("#" + id)
      .parentsUntil(".el-form-item__content")
      .append('<div class="el-form-item__error">' + errorMessage + "</div>");
  }
  $("#" + id).addClass("verror");
}

function removeField(item, sectionId) {
  if (sectionId != undefined) {
    if ($("#section" + sectionId + " #" + item).is(".el-radio-group")) {
      $("#section" + sectionId + " #" + item)
        .parents(".radio-parent")
        .remove();
    }
    else {
      $("#section" + sectionId + " #" + item)
        .parentsUntil(".layout.wrap")
        .remove();
    }
  }
  else {
    if ($("#" + item).is(".el-radio-group")) {
      $("#" + item)
        .parents(".radio-parent")
        .remove();
    }
    else {
      $("#" + item)
        .parentsUntil(".layout.wrap")
        .remove();
    }
  }
}

export const checkItemsFields = (objArray, fields, sectionId, refs) => {
  let checkItems = fields;
  let sectionData = [];

  checkItems.forEach(function(item) {
    if (parseFloat(item.section_id) == parseFloat(sectionId)) {
      sectionData.push(item);
    }
  });

  let newArray = checkRow(objArray, sectionData, sectionId, refs);
  let fCount = 0;
  newArray.forEach(function(chId) {
    let idx = sectionData.map((p) => p.data_column).indexOf(chId.data_column);
    if (idx == -1 || chId.hide) {
      if ($("#" + chId.data_column).is(".el-input__inner")) {
        let flex = $("#" + chId.data_column).parents(".flex");
        if (flex.length > 0) {
          flex.remove();
        }
      }
      else if ($("#" + chId.data_column).parents(".el-radio-group")) {
        let flex = $("#" + chId.data_column).parents(".flex");
        if (flex.length > 0) {
          flex.remove();
        }
      }
      else {
        let flex = $("#" + chId.data_column).parents(".radio-parent");
        if (flex.length > 0) {
          flex.remove();
        }
      }
      fCount++;
    }
    else {
      let errorMessage = sectionData[idx].error_message !== null ? sectionData[idx].error_message : "Error";
      let field = $("#" + chId.data_column).parents(".el-form-item__content");

      if (field.length > 0) {
        field.append('<div class="el-form-item__error">' + errorMessage + "</div>");
      }

      $("#" + chId.data_column).addClass("verror");
    }
  }, this);

  if (fCount == newArray.length) {
    if ($("#section" + sectionId)) {
      $("#section" + sectionId).remove();
    }
  }

  return "";
};

export const checkTableFields = (objArray, fields, sectionId) => {
  let checkArray = fields;
  let sectionData = [];

  checkArray.forEach(function(item) {
    if (parseFloat(item.section_id) == parseFloat(sectionId)) {
      sectionData.push(item);
    }
  });

  let newArray = checkRow(objArray, sectionData, sectionId);

  newArray.forEach(function(chId) {
    let idx = sectionData.map((p) => p.data_column).indexOf(chId.data_column);

    if (idx == -1 || chId.hide) {
      let field = $("#" + chId.data_column).parents("td div");
      if (field.length > 0) {
        field.remove();
      }
    }
    else {
      let errorMessage = sectionData[idx].error_message !== null ? sectionData[idx].error_message : "Error";
      let field = $("#" + chId.data_column).parents("td");
      let classField = $("#" + chId.data_column);
      if (field.length > 0 && classField.length > 0) {
        field.append('<div class="table__error">' + errorMessage + "</div>");
        classField.addClass("verror");
      }
    }
  }, this);

  return "";
};

function checkRow(objArray, array, sectionId, refs) {
  let tempArray = {
    Employment: ["status", "other"],
    Education: ["study_time", "study_period", "awarded", "years"],
    MembershipOrganization: ["name_of_org", "type_of_organization", "activities", "city"],
    MembershipOfOrganizations: ["details", "location"],
    Military: ["location"],
    МilitaryService: ["city", "branch", "places", "reason_for_end_service", "rank"],
    "197": ["any_children", "many_children"],
    "195": ["family_members", "funds", "check_relative_in_canada", "relative_in_canada_text"],
    AdditionalIEE: ["city_birth", "address", "dependant_type", "present_occupation", "place"],
    PermanentResident: ["dependant_type", "present_occupation", "place", "present_occupation", "country_residence", "city_residence"],
    AdditionalFamilyMembers: ["dependant_type", "address", "city_birth", "present_occupation", "country_residence", "city_residence"],
    AdditionalFamilyMemberEmailAddress: ["country_residence", "city_residence", "place", "present_occupation", "city_residence"],
    AdditionalFamilyMemberPresentOccupation: ["dependant_type", "city_residence", "city_residence", "place", "country_residence"],
    DependentFamilyMembers: ["relationship_to_spouse", "relationship_to_child", "relationship_to_you"],
    withinBC: ["province_id", "country_id"],
    outsideCanada: ["province_id", "other"],
    withinCanada: ["country_id"],
    OfficialLanguage: ["applicant_id", "report_number", "type_taken"]
  };
  let temp = [];

  let checkArray = [];

  if (array.length > 0) {
    if (tempArray[array[0].alias] != undefined) {
      checkArray = tempArray[array[0].alias];
    }
  }

  objArray.forEach(function(obj) {
    let idx = array.map((p) => parseFloat(p.data_id)).indexOf(parseFloat(obj.id));

    if (idx !== -1) {
      $("#row" + obj.id)
        .children("div")
        .addClass("error-row");
      let count = 0;
      let nullCount = typeof checkArray !== "undefined" ? checkArray.length : 0;

      for (let key in obj) {
        if (obj[key] == null || obj[key] == "") {
          if (checkArray.indexOf(key) !== -1) {
            count += 1;
          }
        }

        let data = {};
        data.name = key;
        data.data_column = key + "" + obj.id;
        data.data_id = obj.id;
        data.hide = false;

        if (obj[key] != null || obj[key] != "" || obj[key] == true || obj[key] == false || obj[key] == "Yes" || obj[key] == "No") {
          data.hide = true;
        }

        temp.push(data);
      }

      if (count == nullCount && count > 0 && nullCount > 0) {
        $("#row" + obj.id).remove();
      }
    }
    else {
      $("#row" + obj.id).remove();
    }
  });

  return temp;
}

export const totalAmount = (datas) => {
  if (datas.length === 0 && datas === undefined) {
    return false;
  }

  let total = {};
  total.amount_foreign = 0;
  total.amount_canadian = 0;

  datas.forEach(function(item) {
    let amount_foreign = 0;
    let amount_canadian = 0;

    if (item.amount_foreign !== null && item.amount_foreign !== "") {
      amount_foreign = item.amount_foreign;
    }
    if (item.amount_canadian !== null && item.amount_foreign !== "") {
      amount_canadian = item.amount_canadian;
    }

    total.amount_foreign += parseFloat(amount_foreign);
    total.amount_canadian += parseFloat(amount_canadian);
  });

  return total;
};

export const disableDate = (event) => {
  if (event.target) event.target.readOnly = true;
};

export const checkLength = (start, pr, from, to, check) => {
  let prDate = pr;
  let fromDate = from;
  let startDate = start;
  let item = {
    absent: 0,
    total: 0
  };

  if (startDate >= from) {
    fromDate = startDate;
  }

  let frDate = moment(fromDate, "YYYY-MM-DD");
  let toDate = moment(to, "YYYY-MM-DD");
  let length = toDate.diff(frDate, "days") + check;

  if (prDate > fromDate && prDate >= to) {
    length = length * 0.5;
    item.absent = length;
  }
  else if (prDate > fromDate && prDate < to) {
    let pr = moment(prDate, "YYYY-MM-DD");

    let prevDay = pr.diff(frDate, "days");
    let nextDay = toDate.diff(pr, "days");

    length = (prevDay + check) * 0.5 + nextDay;

    item.absent = (prevDay + check) * 0.5;
  }

  item.total = length;

  return item;
};

export const myGroupBy = (collection, property) => {
  var i = 0,
    val,
    index,
    values = [],
    result = [];
  for (; i < collection.length; i++) {
    val = collection[i][property];
    index = values.indexOf(val);
    if (index > -1) {
      var it = result[index];
      it.items.push(collection[i]);
      result[index] = it;
    }
    else {
      values.push(val);
      let rs = {
        group: val,
        show: false,
        items: [collection[i]]
      };
      result.push(rs);
    }
  }
  return result;
};

export const totalProcessingDays = (application) => {
  if (application == undefined || application == null) return null
  let total = 0
  if (application.days_in_purchased != null) {
    total += +application.days_in_purchased
  }

  if (application.days_in_rcic_onboarding != null) {
    total += +application.days_in_rcic_onboarding
  }
  if (application.days_in_forms_recruitment != null) {
    total += +application.days_in_forms_recruitment
  }

  if (application.days_in_review != null) {
    total += +application.days_in_review
  }
  if (application.days_in_waiting_for_final_payment != null) {
    total += +application.days_in_waiting_for_final_payment
  }
  if (application.days_in_ready_to_submit != null) {
    total += +application.days_in_ready_to_submit
  }
  if (application.days_in_submitted != null) {
    total += +application.days_in_submitted
  }
  if (application.days_in_follow_up != null) {
    total += +application.days_in_follow_up
  }
  if (application.days_in_completed != null) {
    total += +application.days_in_completed
  }
  if (application.days_in_archived != null) {
    total += +application.days_in_archived
  }
  return total
};

export const getDaysInStatus = (selectedStatus, application) => {
  const dayStatus = `days_in_${application.status}`
  const dateStatus = `date_at_${application.status}`
  let days = 0

  if (selectedStatus != null && selectedStatus === application.status && application[dateStatus]) {
    const now = moment().utc()
    let statusDate = moment(application[dateStatus]).utc()
    let diffDays = now.diff(statusDate, 'days')
    days = diffDays
  }
  else if (application[dayStatus]) {
    days = application[dayStatus]
  }

  return days
};

export const getCheckStatus = (selectedStatus, application, val) => {
  let days = 0
  if (selectedStatus != null && selectedStatus === application.status) {
    days = totalProcessingDays(application) > application.processing_days ? 0 : application.processing_days - totalProcessingDays(application)
  }
  else {
    days = val
  }

  return days
};

export const getProperProcessingDays = (application, selectedStatus) => {
  let days = 0
  if (selectedStatus != null && application.status == constants.APP_STATUS_CREATED) days = getCheckStatus(selectedStatus, application, application.days_in_purchased)
  else if (selectedStatus != null && application.status == constants.APP_STATUS_PURCHASED) days = getCheckStatus(selectedStatus, application, application.days_in_rcic_onboarding)
  else if (selectedStatus != null && application.status == constants.APP_STATUS_RCIC_ONBOARDING) days = getCheckStatus(selectedStatus, application, application.days_in_forms_recruitment)
  else if (selectedStatus != null && application.status == constants.APP_STATUS_FORMS_RECRUITMENT) days = getCheckStatus(selectedStatus, application, application.days_in_review)
  else if (selectedStatus != null && application.status == constants.APP_STATUS_REVIEW) days = getCheckStatus(selectedStatus, application, application.days_in_waiting_for_final_payment)
  else if (selectedStatus != null && application.status == constants.APP_STATUS_WAITING_FOR_PAYMENT) days = getCheckStatus(selectedStatus, application, application.days_in_ready_to_submit)
  else if (selectedStatus != null && application.status == constants.APP_STATUS_READY_TO_SUBMIT) days = getCheckStatus(selectedStatus, application, application.days_in_submitted)
  else if (selectedStatus != null && application.status == constants.APP_STATUS_SUBMITTED) days = getCheckStatus(selectedStatus, application, application.days_in_follow_up)
  else if (selectedStatus != null && application.status == constants.APP_STATUS_FOLLOW_UP) days = getCheckStatus(selectedStatus, application, application.days_in_completed)
  else if (selectedStatus != null && application.status == constants.APP_STATUS_COMPLETE) days = getCheckStatus(selectedStatus, application, application.days_in_archived)
  else if (selectedStatus != null && application.status == constants.APP_STATUS_ARCHIVED) days = getCheckStatus(selectedStatus, application, application.processing_days)
  else if (selectedStatus == null) days = application.processing_days

  return days
}

export const getApplicationStatusLabel = (statusKey) => {
  if (statusKey == constants.APP_STATUS_CREATED) return constants.APP_STATUS_CREATED_LABEL
  else if (statusKey == constants.APP_STATUS_PURCHASED) return constants.APP_STATUS_PURCHASED_LABEL
  else if (statusKey == constants.APP_STATUS_RCIC_ONBOARDING) return constants.APP_STATUS_RCIC_ONBOARDING_LABEL
  else if (statusKey == constants.APP_STATUS_FORMS_RECRUITMENT) return constants.APP_STATUS_FORMS_RECRUITMENT_LABEL
  else if (statusKey == constants.APP_STATUS_REVIEW) return constants.APP_STATUS_REVIEW_LABEL
  else if (statusKey == constants.APP_STATUS_WAITING_FOR_PAYMENT) return constants.APP_STATUS_WAITING_FOR_PAYMENT_LABEL
  else if (statusKey == constants.APP_STATUS_READY_TO_SUBMIT) return constants.APP_STATUS_READY_TO_SUBMIT_LABEL
  else if (statusKey == constants.APP_STATUS_SUBMITTED) return constants.APP_STATUS_SUBMITTED_LABEL
  else if (statusKey == constants.APP_STATUS_FOLLOW_UP) return constants.APP_STATUS_FOLLOW_UP_LABEL
  else if (statusKey == constants.APP_STATUS_COMPLETE) return constants.APP_STATUS_COMPLETE_LABEL
  else if (statusKey == constants.APP_STATUS_ARCHIVED) return constants.APP_STATUS_ARCHIVED_LABEL
};

export const getApplicationStatusLabelForClient = (statusKey) => {
  if (statusKey == constants.APP_STATUS_CREATED) return constants.APP_STATUS_CREATED_LABEL_FOR_CLIENT
  else if (statusKey == constants.APP_STATUS_PURCHASED) return constants.APP_STATUS_PURCHASED_LABEL_FOR_CLIENT
  else if (statusKey == constants.APP_STATUS_RCIC_ONBOARDING) return constants.APP_STATUS_RCIC_ONBOARDING_LABEL_FOR_CLIENT
  else if (statusKey == constants.APP_STATUS_FORMS_RECRUITMENT) return constants.APP_STATUS_FORMS_RECRUITMENT_LABEL_FOR_CLIENT
  else if (statusKey == constants.APP_STATUS_REVIEW) return constants.APP_STATUS_REVIEW_LABEL_FOR_CLIENT
  else if (statusKey == constants.APP_STATUS_WAITING_FOR_PAYMENT) return constants.APP_STATUS_WAITING_FOR_LABEL_PAYMENT_FOR_CLIENT
  else if (statusKey == constants.APP_STATUS_READY_TO_SUBMIT) return constants.APP_STATUS_READY_TO_SUBMIT_LABEL_FOR_CLIENT
  else if (statusKey == constants.APP_STATUS_SUBMITTED) return constants.APP_STATUS_SUBMITTED_LABEL_FOR_CLIENT
  else if (statusKey == constants.APP_STATUS_FOLLOW_UP) return constants.APP_STATUS_FOLLOW_UP_LABEL_FOR_CLIENT
  else if (statusKey == constants.APP_STATUS_COMPLETE) return constants.APP_STATUS_COMPLETE_LABEL_FOR_CLIENT
  else if (statusKey == constants.APP_STATUS_ARCHIVED) return constants.APP_STATUS_ARCHIVED_LABEL_FOR_CLIENT
};

export const getRemainDays = async(status, days, country) => {
  let currentDays = null
  var today = moment()

  if (status == null || days == null) return null

  for (let i = 0; i < days.length; i++) {
    if (days[i].imms_uuid == status.imms_uuid) {
      currentDays = days[i]
    }
  }
  
  var submittedDays = today.diff(currentDays.date_at_submitted, 'days')

  if (currentDays != null && currentDays.ApplicationType.SecApplicationCicProcessingDay[0] != null) {
    if (currentDays.ApplicationType.new_name == 'LMIA - Labour Market Impact Assessment ') {
      return (currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days["High-wage stream"] - submittedDays < 0 ? 0 : currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days["High-wage stream"] - submittedDays)
    }

    if (currentDays.ApplicationType.new_name == 'Permanent Resident Card Renewal ') {
      return (currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days["No, I am waiting for my first card"] - submittedDays < 0 ? 0 : currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days["No, I am waiting for my first card"] - submittedDays)
    }

    if (currentDays.ApplicationType.new_name == 'Canadian Experience Class') {
      return (currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days["I already applied"] - submittedDays < 0 ? 0 : currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days["I already applied"] - submittedDays)
    }

    if (currentDays.ApplicationType.new_name == 'Work Permit as a Caregiver') {
      return (currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days["Home child care provider pilot"] - submittedDays < 0 ? 0 : currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days["Home child care provider pilot"] - submittedDays)
    }

    if (currentDays.ApplicationType.new_name == 'Quebec Skilled Worker - Work Permit ') {
      return (currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days["I already applied"] - submittedDays < 0 ? 0 : currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days["I already applied"] - submittedDays)
    }

    if (currentDays.ApplicationType.new_name == 'Start-up visa') {
      return (currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days["I already applied"] - submittedDays < 0 ? 0 : currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days["I already applied"] - submittedDays)
    }

    if (currentDays.ApplicationType.new_name == 'Provincial Nominees') {
      return (currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days["No, I already applied"] - submittedDays < 0 ? 0 : currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days["No, I already applied"] - submittedDays)
    }
    
    if (currentDays.ApplicationType.new_name == 'Electronic Travel Authorization (eTA)') return 0

    if ('time' in currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days) {
      return (currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days.time - submittedDays < 0 ? 0 : currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days.time - submittedDays)
    }

    if (country in currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days) {
      return (currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days[country] - submittedDays < 0 ? 0 : currentDays.ApplicationType.SecApplicationCicProcessingDay[0].days[country] - submittedDays)
    }
  }

  return null
}

export const fileExtension = (name) => {
  name = name ? name.split('.').pop() : '';
  switch (name.toLowerCase()) {
    case "pdf":
      return "pdf";
    case "jpg":
    case "jpeg":
    case "png":
      return "image";
    case "docx":
    case "docm":
    case "doc":
    case "dotx":
    case "dotm":
    case "dot":
    case "xlsx":
    case "xls":
    case "csv":
      return "doc";
    default:
      return "";
  }
}