import api from "./../plugins/axios";

let url = "table/application_language_ability";

export const state = {
  languageAbility: {}
};

export const getters = {
  getOne(state) {
    return state.languageAbility;
  }
};

export const actions = {
  getById({ commit }, id) {
    return api.get(url + "?filter=member_uid=" + id).then(
      (response) => {
        commit("GET_DATA", response.data.resource[0] || {});
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  save({ commit }, data) {
    return api.post(url, { resource: data }).then(
      (response) => {
        data.id = response.data.resource[0].id;
        commit("GET_DATA", data);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATA", err);
        return Promise.reject(err);
      }
    );
  },
  edit({ commit }, data) {
    return api.put(url + "/" + data.id, data).then(
      (response) => {
        commit("GET_DATA", data);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATA", err);
        return Promise.reject(err);
      }
    );
  }
};

export const mutations = {
  GET_DATA(state, data) {
    state.languageAbility = data;
  },
  FAILURE_DATA(state) {
    state.languageAbility = {};
  }
};
