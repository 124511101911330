<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
        <v-btn color="primary" dark small @click.native="saveForm()">Add</v-btn>
      </v-card-title>
      <v-card-text class="p-n">
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <v-alert color="error" icon="warning" :value="isCheck" class="mb-3">Please check the gaps between dates</v-alert>
        <v-row dense class="customCollapseHeader">
          <v-col cols="6" sm="4">From Date</v-col>
          <v-col cols="6" sm="4">To Date</v-col>
          <v-col class="d-none d-sm-flex" sm="4">Company/Facility Name</v-col>
        </v-row>
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i">
            <template slot="title">
              <div v-on:click="getProvince(form)" class="customHead">
                <v-row dense>
                  <v-col cols="6" sm="4">
                    <el-tag type="primary" v-if="form.from_date !== null && form.from_date != undefined">{{ form.from_date | day }}</el-tag>
                  </v-col>
                  <v-col cols="6" sm="4">
                    <el-tag type="success" v-if="form.to_date !== null && form.to_date != undefined">{{ form.to_date | day }}</el-tag>
                  </v-col>
                  <v-col class="d-none d-sm-flex" sm="4"><div class="pl-3 pr-2">{{ form.name_of_employer }}</div></v-col>
                </v-row>
              </div>
            </template>
            <el-form :model="form" ref="form" label-position="top">
              <v-row dense>
                <v-col cols="12" sm="6" md="4">
                  <el-form-item label="From:" prop="from_date">
                    <el-date-picker :id="'from_date' + form.id" :picker-options="datePickerOptions" @change="handleAutoSave(i, form)" value-format="yyyy-MM-dd" v-model="form.from_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <el-form-item label="To:" prop="to_date">
                    <el-date-picker :id="'to_date' + form.id" :picker-options="datePickerOptions" @change="handleAutoSave(i, form)" value-format="yyyy-MM-dd" v-model="form.to_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <el-form-item label="Country" prop="country_id">
                    <div class="el-select native-select">
                      <div class="el-input">
                        <select :id="'country_id' + form.id" @change="handleAutoSave(i, form);getProvince(form)" v-model="form.country_id" placeholder="Select" class="el-input__inner">
                          <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                        </select>
                      </div>
                    </div>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-show="selectProvine">
                  <el-form-item label="Province" prop="province">
                    <div class="el-select native-select">
                      <div class="el-input">
                        <select :id="'province' + form.id" @change="handleAutoSave(i, form)" v-model="form.province" placeholder="Select" class="el-input__inner">
                          <option v-for="(item, index) in province" v-bind:key="index" :value="item.province_name" :label="item.province_name">{{ item.province_name }}</option>
                        </select>
                      </div>
                    </div>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <el-form-item label="City/Town" prop="city">
                    <el-input :id="'city' + form.id" @change="handleAutoSave(i, form)" v-model="form.city" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6">
                  <el-form-item label="Address" prop="address">
                    <el-input :id="'address' + form.id" @change="handleAutoSave(i, form)" v-model="form.address" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <el-form-item label="Company/Facility Name" prop="name_of_employer">
                    <el-input :id="'name_of_employer' + form.id" @change="handleAutoSave(i, form)" v-model="form.name_of_employer" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <el-form-item label="Occupation/Position" prop="position">
                    <el-input :id="'position' + form.id" @change="handleAutoSave(i, form)" v-model="form.position" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <el-form-item label="Monthly gross salary/income" prop="monthly_income">
                    <el-input :id="'monthly_income' + form.id" @change="handleAutoSave(i, form)" type="number" v-model="form.monthly_income">
                      <template slot="prepend">$</template>
                    </el-input>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <el-form-item label="Phone number" prop="phone">
                    <div v-if="phoneMasked" class="el-input">
                      <masked-input :id="'phone' + form.id" @change="handleAutoSave(i, form)" v-model="form.phone" :mask="phoneMask" class="el-input__inner" />
                    </div>
                    <el-input :id="'phone' + form.id" v-else @change="handleAutoSave(i, form)" type="number" v-model="form.phone" style="width: 100%;" />
                  </el-form-item>
                </v-col>
                <v-col cols="12">
                  <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="handleAutoSave(i,form)">
                    {{ $t('global.save') }}
                  </v-btn>
                  <v-btn color="error" small @click.native="deleteOpen(form,i)">Delete</v-btn>
                </v-col>
              </v-row>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import MaskedInput from 'vue-text-mask'
import autosave from '@/mixins/autosave.js'

export default {
  mixins: [autosave],
  props: {
    sectionId: {
      type: String
    },
    layout: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        country_id: '',
        position: null,
        city: '',
        name_of_employer: '',
        monthly_income: null,
        province: '',
        from_date: null,
        to_date: null,
        address: null,
        phone: null
      },
      items: [],
      activeNames: -1,
      selectProvine: false,
      phoneMasked: false,
      phoneMask: ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      url: 'imms_table/application_sponsorship_employment_history',
      loading: false
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      province: 'other/province'
    }),
    isCheck: function() {
      var from_date = ''
      var isCheck = []
      var alert = false
      this.items.forEach(function(item, index) {
        var a = moment(from_date)
        var b = moment(item.to_date)
        var date = a.diff(b, 'days')
        if (date > 1) {
          alert = true
          isCheck.push(item)
        }
        from_date = item.from_date
      })
      return alert
    }
  },
  components: {
    MaskedInput
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    getProvince(item) {
      if (42 == item.country_id || 239 == item.country_id) {
        this.selectProvine = true
        this.phoneMasked = true
        this.$store.dispatch('other/getProvince', item.country_id)
      }
      else {
        this.phoneMasked = false
        this.selectProvine = false
      }
    },
    fetchData() {
      this.$Progress.start()
      var url = `table/application_sponsorship_employment_history?filter=member_uid=${this.memberUID}&order=to_date DESC`
      this.$store.dispatch('store/get', url)
        .then(response => {
          this.items = response.resource
          if (this.datas !== undefined) {
            this.checkItemsFields(response.resource, this.datas, this.sectionId)
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        country_id: '',
        position: null,
        city: '',
        name_of_employer: '',
        monthly_income: null,
        province: '',
        from_date: null,
        to_date: null,
        address: null,
        phone: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>
