import { firebase } from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';
import '@firebase/storage';
const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyBRhzjZbPzuXAWA2rBnjOWYfAKTx5aWXxc',
  authDomain: 'cac-portal-ab6e7.firebaseapp.com',
  databaseURL: 'https://cac-portal-ab6e7.firebaseio.com',
  projectId: 'cac-portal-ab6e7',
  storageBucket: 'cac-portal-ab6e7.appspot.com',
  messagingSenderId: '529886763934',
  appId: '1:529886763934:web:c2b13fb5ffbe1522eb148f',
  timestampsInSnapshots: true,
});

export const fireDb = firebaseApp.firestore();
export const auth = firebaseApp.auth();
export const fireStorage = firebase.storage();
export default firebaseApp;
