<template>
  <div>
    <v-card class="mt-3 bquestion">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
      </v-card-title>
      <v-card-text class="label_with_bold">

        <el-form :model="form" ref="form" label-position="top" class="form">
            <v-row dense>
                <v-col cols="12">
                  <el-form-item label="Do you have a current job offer?">
                    <el-radio-group @change="setConditionalVal('have_job')" v-model="form.have_job">
                      <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                      <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </v-col>
                <v-col cols="12">

                  <div v-if="form.have_job == 'Yes'">
                    <el-form-item label="Do you have a SINP Job Approval Letter?">
                      <el-radio-group @change="setConditionalVal('have_sinp_job_approval')" v-model="form.have_sinp_job_approval">
                        <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                        <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="Are you working in Saskatchewan on a valid work permit?*" v-if="form.have_sinp_job_approval == 'Yes'">
                      <el-radio-group @change="setConditionalVal('are_you_working')" v-model="form.are_you_working">
                        <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                        <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="Are you an international student working on a valid work permit?*" v-if="form.are_you_working == 'Yes'">
                      <el-radio-group @change="setConditionalVal('are_you_international_student')" v-model="form.are_you_international_student">
                        <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                        <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <div v-if="form.are_you_international_student == 'Yes'">
                        <el-form-item label="Did you graduate:">
                          <el-radio-group @change="setConditionalVal('did_graduate')" v-model="form.did_graduate">
                            <el-radio label="In Saskatchewan">In Saskatchewan</el-radio>
                            <el-radio label="Outside Saskatchewan">Outside Saskatchewan</el-radio>
                          </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="form.did_graduate == 'In Saskatchewan'" label="Do you have six months of employment in Saskatchewan, and a job offer related to your field of study?*">
                          <el-radio-group @change="setConditionalVal('employment_job_offer')" v-model="form.employment_job_offer">
                            <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                            <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                          </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="form.did_graduate == 'Outside Saskatchewan'" label="Do you have six consecutive months of full-time employment in Saskatchewan and a job offer (both related to your field of study)?">
                          <el-radio-group @change="setConditionalVal('full_time_employment_job_offer')" v-model="form.full_time_employment_job_offer">
                            <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                            <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                          </el-radio-group>
                        </el-form-item>

                      </div>
                      <div v-if="form.are_you_international_student == 'No'">
                        <el-form-item label="Have you worked for the employer that has offered you full-time employment for at least 6 months?*" v-if="form.are_you_international_student == 'No'">
                          <el-radio-group @change="handleAutoSave(0, form)" v-model="form.full_time_employment">
                            <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                            <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                          </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="form.full_time_employment == 'Yes'" label="I am working:*">
                            <el-select @change="handleAutoSave(0, form)" v-model="form.am_working" style="width:100%">
                              <el-option-group
                                key="in-a-health-occupation"
                                label="In a Health occupation">
                                <el-option key="Physician" label=" - Physician" value="Physician"></el-option>
                                <el-option key="Nurse" label=" - Nurse" value="Nurse"></el-option>
                                <el-option key="OtherHealthProfessiona" label=" - Other health professiona" value="Other health professiona"></el-option>
                              </el-option-group>
                              <el-option key="am100" label="In a skilled occupation or a designated trade or in an agricultural occupation" value="In a skilled occupation or a designated trade or in an agricultural occupation"></el-option>
                              <el-option key="am101" label="In one of the following hospitality positions: Food/Beverage Server; Food Counter Attendant/Kitchen Helper or Housekeeping/Cleaning Staff" value="In one of the following hospitality positions: Food/Beverage Server; Food Counter Attendant/Kitchen Helper or Housekeeping/Cleaning Staff"></el-option>
                              <el-option key="am102" label="As a Long Haul Trucker" value="As a Long Haul Trucker"></el-option>
                            </el-select>
                        </el-form-item>

                      </div>
                  </div>
                  <div v-if="form.have_job == 'No'">
                    <el-form-item label="Have you been accepted into Immigration, Refugees and Citizenship Canada's Express Entry pool and have an Express Entry Profile Number?">
                      <el-radio-group @change="handleAutoSave(0, form)" v-model="form.have_accepted_profile">
                        <el-radio :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                        <el-radio :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <div v-if="form.have_accepted_profile == 'Yes'">
                      <el-form-item label="Express Entry Profile Number">
                        <el-input @change="handleAutoSave(0, form)" v-model="form.express_entry_profile_number" />
                      </el-form-item>
                      <el-form-item label="Job Seeker Validation Code">
                        <el-input @change="handleAutoSave(0, form)" v-model="form.job_seeker_validation_code" />
                      </el-form-item>
                      <el-form-item label="I have at least one year of work experience in">
                        <el-select v-model="form.work_experience" @change="handleAutoSave(0, form)" style="width:100%">
                          <el-option v-for="(item, index) in work_experiences" :key="index" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-if="form.have_accepted_profile == 'No'">
                      <el-form-item label="I have at least one year of work experience in">
                        <el-select v-model="form.work_experience" @change="handleAutoSave(0, form)" style="width:100%">
                          <el-option v-for="(item, index) in work_experiences" :key="index" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                </v-col>
              </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="handleAutoSave(0, form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/autosave.js'

export default {
  mixins: [autosave],
  props: {
    sectionId: {
      type: String
    },
    layout: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        have_job: null,
        have_sinp_job_approval: null,
        are_you_working: null,
        are_you_international_student: null,
        did_graduate: null,
        employment_job_offer: null,
        full_time_employment_job_offer: null,
        full_time_employment: null,
        am_working: null,
        have_accepted_profile: null,
        express_entry_profile_number: null,
        job_seeker_validation_code: null,
        work_experience: null
      },
      url: 'imms_table/application_sinp_eligibility_questions',
      loading: false,
      work_experiences: [
        {
          "value": "0423",
          "label": "0423 - Managers in social, community and correctional services"
        },
        {
          "value": "1311",
          "label": "1311 - Accounting technicians"
        },
        {
          "value": "2174",
          "label": "2174 - Computer programmers and interactive media developers"
        },
        {
          "value": "2251",
          "label": "2251 - Architectural technologists and technicians"
        },
        {
          "value": "3211",
          "label": "3211 - Medical laboratory technologists"
        },
        {
          "value": "3215",
          "label": "3215 - Medical radiation technologists"
        },
        {
          "value": "3216",
          "label": "3216 - Medical sonographers"
        },
        {
          "value": "3234",
          "label": "3234 - Paramedical occupations"
        },
        {
          "value": "4151",
          "label": "4151 - Psychologists"
        },
        {
          "value": "4212",
          "label": "4212 - Social and community service workers"
        },
        {
          "value": "4214",
          "label": "4214 - Early childhood educators and assistants"
        },
        {
          "value": "4215",
          "label": "4215 - Instructors of persons with disabilities"
        },
        {
          "value": "5254",
          "label": "5254 - Program leaders and instructors in recreation, sport and fitness"
        },
        {
          "value": "6331",
          "label": "6331 - Butchers, meat cutters and fishmongers - retail and wholesale"
        },
        {
          "value": "6332",
          "label": "6332 - Bakers"
        },
        {
          "value": "7292",
          "label": "7292 - Glaziers"
        },
        {
          "value": "7312",
          "label": "7312 - Heavy-duty equipment mechanics"
        },
        {
          "value": "7321",
          "label": "7321 - Automotive service technicians, truck and bus mechanics and mechanical repairers"
        },
        {
          "value": "7322",
          "label": "7322 - Motor vehicle body repairers"
        }

      ]
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    setConditionalVal(field) {
      if (field == 'have_job') {
        this.form.have_sinp_job_approval = null
        this.form.are_you_working = null
        this.form.are_you_international_student = null
        this.form.did_graduate = null
        this.form.employment_job_offer = null
        this.form.full_time_employment_job_offer = null
        this.form.full_time_employmen = null
        this.form.am_working = null
        this.form.have_accepted_profile = null
        this.form.express_entry_profile_number = null
        this.form.job_seeker_validation_code = null
        this.form.work_experience = null
      }
      else if (field == 'have_sinp_job_approval') {
        this.form.are_you_working = null
        this.form.are_you_international_student = null
        this.form.did_graduate = null
        this.form.employment_job_offer = null
        this.form.full_time_employment_job_offer = null
        this.form.full_time_employmen = null
        this.form.am_working = null
        this.form.have_accepted_profile = null
        this.form.express_entry_profile_number = null
        this.form.job_seeker_validation_code = null
        this.form.work_experience = null
      }
      else if (field == 'are_you_working') {
        this.form.did_graduate = null
        this.form.employment_job_offer = null
        this.form.full_time_employment_job_offer = null
        this.form.full_time_employmen = null
        this.form.am_working = null
        this.form.have_accepted_profile = null
        this.form.express_entry_profile_number = null
        this.form.job_seeker_validation_code = null
        this.form.work_experience = null
      }
      else if (field == 'are_you_international_student') {
        this.form.did_graduate = null
        this.form.employment_job_offer = null
        this.form.full_time_employment_job_offer = null
        this.form.full_time_employmen = null
        this.form.am_working = null
        this.form.have_accepted_profile = null
        this.form.express_entry_profile_number = null
        this.form.job_seeker_validation_code = null
        this.form.work_experience = null
      }
      else if (field == 'did_graduate') {
        this.form.employment_job_offer = null
        this.form.full_time_employmen = null
        this.form.am_working = null
        this.form.have_accepted_profile = null
        this.form.express_entry_profile_number = null
        this.form.job_seeker_validation_code = null
        this.form.work_experience = null
      }
      else if (field == 'employment_job_offer') {
        this.form.am_working = null
        this.form.have_accepted_profile = null
        this.form.express_entry_profile_number = null
        this.form.job_seeker_validation_code = null
        this.form.work_experience = null
      }
      else if (field == 'full_time_employment_job_offer') {
        this.form.am_working = null
        this.form.have_accepted_profile = null
        this.form.express_entry_profile_number = null
        this.form.job_seeker_validation_code = null
        this.form.work_experience = null
      }

      this.handleAutoSave(0, this.form)
    },
    fetchData() {
      this.$store.dispatch('store/get', `table/application_sinp_eligibility_questions?filter=member_uid=${this.memberUID}`)
        .then(response => {
          if (response.resource.length > 0) {
            this.form = response.resource[0]
          }
        })
    }
  }
}
</script>

<style>
  .label_with_bold .el-form-item__label{
    font-weight: bold;
  }
</style>
