<template>
  <div class="mt-3" v-if="hideSection">
    <v-card>
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text class="p-n">
        <table class="v-table" style="width:100%">
          <thead>
            <tr>
              <th>Employer</th>
              <th width="140">Start Date</th>
              <th width="140">End Date</th>
              <th>Employment Location </th>
              <th>Position</th>
              <th width="30">
                <v-btn color="green" small fab @click.native="add()">
                  <v-icon class="white--text">add</v-icon>
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in items" :key="i" :id="'row' + item.id">
              <td class="pa-1">
                <el-input v-if="dataValidationItems(item, 'name_of_employer')" v-model="item.name_of_employer" style="width: 100%" @change="handleAutoSaveItems(item)" />
              </td>
              <td class="pa-1">
                <el-date-picker v-if="dataValidationItems(item, 'from_date')" :picker-options="datePickerOptions" value-format="yyyy-MM-dd" @change="handleAutoSaveItems(item)" v-model="item.from_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </td>
              <td class="pa-1">
                <el-date-picker v-if="dataValidationItems(item, 'to_date')" :picker-options="datePickerOptions" value-format="yyyy-MM-dd" @change="handleAutoSaveItems(item)" v-model="item.to_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
              </td>
              <td class="pa-1">
                <el-input v-if="dataValidationItems(item, 'city')" v-model="item.city" style="width: 100%" @change="handleAutoSaveItems(item)" />
              </td>
              <td class="pa-1">
                <el-input v-if="dataValidationItems(item, 'job_title')" v-model="item.job_title" style="width: 100%" @change="handleAutoSaveItems(item)" />
              </td>
              <td class="pa-1 text-center">
                <v-btn color="pink" small fab @click.native="deleteItem(item, i)">
                  <v-icon class="white--text">clear</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="saveAll()">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  mixins: [autosave],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        from_date: null,
        to_date: null,
        name_of_employer: null,
        city: null,
        job_title: null
      },
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        from_date: null,
        to_date: null,
        name_of_employer: null,
        city: null,
        job_title: null
      },
      url: 'imms_table/imm_1294_employment_history',
      deleteUrl: 'imms/imm_1294_employment_history',
      getUrl: `/table/imm_1294_employment_view?filter=member_uid=${this.memberUID}&order=from_date DESC, to_date DESC`
    }
  },
  watch: {
    memberUID: function() {
      this.getData()
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.getUrl = `/table/imm_1294_employment_view?filter=member_uid=${this.memberUID}&order=from_date DESC, to_date DESC`
      this.fetch().then(data => {
        let vm = this;

        if (vm.isDataValidation) {
          let datas = []

          data.items.forEach(function(item) {
            var obj = vm.checkFormItems(item, vm.form);

            if (obj != 'empty') {
              datas.push(item)
            }
          })
          vm.items = datas
        }
        else {
          let items = []
          data.items.forEach(function(item) {
            items.push(item)
          })
          vm.items = items
        }
      })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        from_date: null,
        to_date: null,
        name_of_employer: null,
        city: null,
        job_title: null
      }
      this.edit = false
    }
  }
}
</script>
