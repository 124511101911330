<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
        <v-btn color="primary" dark small @click.native="saveForm()">Add</v-btn>
      </v-card-title>
      <v-card-text class="p-n">
        <v-alert type="info" border="left" dense class="mb-2" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <v-row dense class="customCollapseHeader">
          <v-col cols="6" sm="4">First name</v-col>
          <v-col cols="6" sm="4">Last name</v-col>
          <v-col class="d-none d-sm-flex" sm="4">Date of birth</v-col>
        </v-row>
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i">
            <template slot="title">
              <div class="customHead">
                <v-row dense>
                  <v-col cols="6" sm="4">{{ form.first_name }}</v-col>
                  <v-col cols="6" sm="4">{{ form.last_name }}</v-col>
                  <v-col class="d-none d-sm-flex" sm="4">
                    <el-tag type="primary" class="ml-3" v-if="form.date_of_birth !== null && form.date_of_birth != undefined">{{ form.date_of_birth }}</el-tag>
                  </v-col>
                </v-row>
              </div>
            </template>
            <el-form :model="form" ref="form" label-position="top">
              <v-row dense>
                <v-col cols="12" sm="6" md="4">
                  <el-form-item label="First Name" prop="first_name">
                    <el-input :id="'first_name' + form.id" @change="handleAutoSave(i, form)" v-model="form.first_name" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <el-form-item label="Last Name" prop="last_name">
                    <el-input :id="'last_name' + form.id" @change="handleAutoSave(i, form)" v-model="form.last_name" style="width: 100%" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <el-form-item label="Date of Birth" prop="date_of_birth">
                    <el-date-picker :id="'date_of_birth' + form.id" :picker-options="datePickerOptions" @change="handleAutoSave(i, form)" value-format="yyyy-MM-dd" v-model="form.date_of_birth" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <el-form-item prop="co_signer_or_sponsor">
                    <el-checkbox :id="'co_signer_or_sponsor' + form.id" @change="handleAutoSave(i, form)" v-model="form.co_signer_or_sponsor" class="mt-4">Co Signer/Sponsor</el-checkbox>
                  </el-form-item>
                </v-col>
                <v-col cols="12">
                  <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="handleAutoSave(i, form)">
                    {{ $t('global.save') }}
                  </v-btn>
                  <v-btn color="error" small @click.native="deleteOpen(form,i)">Delete</v-btn>
                </v-col>
              </v-row>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/autosave.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    layout: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [autosave],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        first_name: null,
        last_name: null,
        date_of_birth: null,
        co_signer_or_sponsor: null
      },
      activeNames: -1,
      loading: false,
      url: 'imms_table/application_sponsorship_co_signer',
      fields: ['first_name', 'last_name', 'date_of_birth', 'co_signer_or_sponsor'],
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/application_sponsorship_co_signer?filter=member_uid=${this.memberUID}&order=date_of_birth DESC`
      this.$store.dispatch('store/get', url)
        .then(response => {
          this.items = response.resource
          if (this.datas !== undefined) {
            this.checkItemsFields(response.resource, this.datas, this.sectionId)
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        first_name: null,
        last_name: null,
        date_of_birth: null,
        co_signer_or_sponsor: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>
