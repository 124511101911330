<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>
        <span><strong>F.</strong> {{ $t('title.application_liablities') }}</span>
      </v-card-title>
      <v-card-text class="p-n">
        <v-alert type="info" border="left" dense class="mb-3" v-if="$t('infobox.application_liablities') !== ''">
          {{ $t('infobox.application_liablities') }}
        </v-alert>
        <table class="table" style="width:100%">
          <thead>
            <tr>
              <th rowspan="2">{{ $t('global.complete_address') }}</th>
              <th colspan="2">{{ $t('global.current_balance') }}</th>
              <th rowspan="2" width="30">
                <v-btn color="green" small fab @click.native="addForm()">
                  <v-icon class="white--text">add</v-icon>
                </v-btn>
              </th>
            </tr>
            <tr>
              <th width="120">{{ $t('application_liablities.amount_foreign') }}</th>
              <th width="120">{{ $t('application_liablities.amount_canadian') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in items" :key="i" :id="'row' + item.id">
              <td class="pa-1">
                <el-input :id="'description' + item.id" v-model="item.description" style="width: 100%" />
              </td>
              <td class="pa-1">
                <el-input :id="'amount_foreign' + item.id" type="number" v-model="item.amount_foreign" class="text-right" style="width: 100%" />
              </td>
              <td class="pa-1">
                <el-input :id="'amount_canadian' + item.id" type="number" v-model="item.amount_canadian" class="text-right" style="width: 100%" />
              </td>
              <td class="pa-1 text-center">
                <v-btn color="pink" small fab @click.native="deleteOpen(item)"><v-icon class="white--text">clear</v-icon></v-btn>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td class="text-right">
                <b>{{ $t('global.total') }}</b>
              </td>
              <td class="border-bottom text-right"><b>{{ total.amount_foreign | currency('',0) }}</b></td>
              <td class="border-bottom text-right"><b>{{ total.amount_canadian | currency('',0) }}</b></td>
              <td class="border-bottom"></td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm()">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { totalAmount } from '@/utils/helpers'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        description: '',
        amount_foreign: null,
        amount_canadian: null
      },
      loading: false,
      url: 'imms_table/application_liablities'
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    total: function() {
      return totalAmount(this.items)
    },
    items: {
      get() {
        return this.$store.state.store.liability
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/getTotal', { 'url': `table/application_liablities?filter=member_uid=${this.memberUID}`, state: 'liability', stateTotal: 'liabilityTotal' })
        .then(response => {
          if (this.datas !== undefined) {
            this.checkTableFields(this.items, this.datas)
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.form = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        description: '',
        amount_foreign: null,
        amount_canadian: null
      }
    },
    addForm() {
      this.nodeClear()
      this.items.push(this.form)
    },
    submitForm() {
      if (this.items.length > 0) {
        this.loading = true
        this.items.forEach(function(form) {
          var actionMessage = 'Saved'
          var action = 'store/save'
          if (form.sec_org_id == undefined && form.sec_org_id == null) form.sec_org_id = this.$orgId

          if (form.id != undefined) {
            actionMessage = 'Edited'
            action = 'store/edit'
            form.id = parseFloat(form.id)
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': form }).then(response => {
            if (response.data.resource && response.data.resource.length > 0) {
              form.id = response.data.resource[0].id
              form.imms_uuid = response.data.resource[0].imms_uuid
            }
            this.$store.dispatch('toaster/add', { text: actionMessage, type: 'success' })
            this.$Progress.finish()
            this.loading = false
          })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }, this)
      }
      else {
        return false
      }
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>
