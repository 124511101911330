<template>
  <div>
    <v-card v-if="hideSection" class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="yukon-checkbox">
          <v-row dense>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'position_job_title')">
              <el-form-item prop="position_job_title" label="Job title">
                <el-input @change="handleAutoSave(form)" v-model="form.position_job_title" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'position_noc')">
              <el-form-item prop="position_noc" label="NOC">
                <el-input @change="handleAutoSave(form)" v-model="form.position_noc" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'position_hours')">
              <el-form-item prop="position_hours" label="Hours per week (35-40 hrs)">
                <el-input @change="handleAutoSave(form)" v-model="form.position_hours" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'position_job_description')">
              <el-form-item prop="position_job_description" label="Job description">
                <el-input @change="handleAutoSave(form)" v-model="form.position_job_description" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'position_wage_offered')">
              <el-form-item prop="position_wage_offered" label="Wage offered, rate per hour">
                <el-input @change="handleAutoSave(form)" v-model="form.position_wage_offered" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'position_wage_per_hour')">
              <el-form-item prop="position_wage" label="Median wage per hour as per ESDC">
                <el-input @change="handleAutoSave(form)" v-model="form.position_wage_per_hour" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'position_wage_annum')">
              <el-form-item prop="position_wage_annum" label="Salary per annum">
                <el-input @change="handleAutoSave(form)" v-model="form.position_wage_annum" />
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  mixins: [autosave],
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        position_job_title: null,
        position_noc: null,
        position_hours: null,
        position_job_description: null,
        position_wage_offered: null,
        position_wage_per_hour: null,
        position_wage_annum: null
      },
      url: 'imms_table/lmia_yukon_app',
      getUrl: 'imms_table/lmia_yukon_app?application_imms_uuid=' + this.$route.params.id
    }
  },
  mounted() {
    this.fetch().then(data => {
      if (data.items != undefined && data.items.length > 0) {
        if (this.isDataValidation) {
          this.checkRow(data.items[0])
        }
        this.form = data.items[0]
      }
      else {
        if (this.isDataValidation) {
          this.checkRow(this.form)
        }
      }
    })
  }
}
</script>
