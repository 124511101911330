<template>
  <div v-if="hideSection">
    <v-card class="mt-3 bquestion">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" icon="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="form">
          <v-row dense>
            <v-col cols="12">
              <el-form-item prop="province" label="What province are you located in?"> 
                <el-select @change="autoSaveSubmit(form)" v-model="form.province" filterable class="mt-1" style="width: 350px">
                  <el-option v-for="(item, index) in province" :key="'province' + index" :value="item.province_name" :label="item.province_name">{{ item.province_name }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a1" label="Have you legally obtained 1560 hours of work experience in the last 3 years?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a1" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="occupation_a" label="Are you on the list of any NOC A occupations listed below"> 
                <el-select @change="autoSaveSubmit(form)" v-model="form.occupation_a" filterable class="mt-1" style="width: 350px">
                  <el-option v-for="item in occupationsAItems" :key="item.noc_code" :value="`${item.noc_code} ${item.label}`" :label="`${item.noc_code} ${item.label}`">{{ item.noc_code }} {{ item.label }}</el-option>
                  <el-option :value="$t('global.not_applicable')">{{ $t('global.not_applicable') }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="occupation_b" label="Are you on the list of any NOC B occupations listed below"> 
                <el-select @change="autoSaveSubmit(form)" v-model="form.occupation_b" filterable class="mt-1" style="width: 350px">
                  <el-option v-for="item in occupationsBItems" :key="item.noc_code" :value="`${item.noc_code} ${item.label}`" :label="`${item.noc_code} ${item.label}`">{{ item.noc_code }} {{ item.label }}</el-option>
                  <el-option :value="$t('global.not_applicable')">{{ $t('global.not_applicable') }}</el-option>
                </el-select>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a2" label="Are you employed in the occupation currently?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a2" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a3" label="Have minimum CLB 4 in French or English?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a3" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a4" label="Test within the last 2 years?">
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a4" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a5" label="Reside in Canada currently?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a6" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a7" label="Intend to reside in a province after getting permanent residence?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a7" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a8" label="Are you interested to confirm you qualify?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a8" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a9" label="If you qualify would you be interested in an Immigration Consultant assisting you?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a9" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a10" label="Are you aware the program opens on May 6th and may fill up quickly?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a10" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a11" label="Do you have a spouse or kids outside of Canada that want to be included?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a11" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
            <v-col cols="12">
              <el-form-item prop="a12" label="Are you able to pay your application and right of landing fee at the time of applying?"> 
                <el-radio-group @change="autoSaveSubmit(form)" v-model="form.a12" class="mt-1">
                  <el-radio :value="$t('global.yes')" :label="$t('global.yes')">{{ $t('global.yes') }}</el-radio>
                  <el-radio :value="$t('global.no')" :label="$t('global.no')">{{ $t('global.no') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" @click.native="autoSaveSubmit(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'
var occupations = require('./occupations.json')
import { mapGetters } from 'vuex'

export default {
  mixins: [datavalidation],
  props: {
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    layout: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    }
  },
  data() {
    return {
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        province: null,
        occupation_a: null,
        occupation_b: null,
        a1: null,
        a2: null,
        a3: null,
        a4: null,
        a5: null,
        a6: null,
        a7: null,
        a8: null,
        a9: null,
        a10: null,
        a11: null,
        a12: null,
        sec_org_id: this.$orgId,
        type: 'work'
      },
      url: 'imms_table/application_assessment_questions',
      getUrl: `table/application_assessment_questions?filter=(member_uid=${this.memberUID})AND(type=work)`,
      occupationsAItems: occupations.occupationsA,
      occupationsBItems: occupations.occupationsB
    }
  },
  computed: {
    ...mapGetters({
      province: 'other/province'
    })
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `${this.getUrl}`)
        .then(response => {
          if (response.resource.length > 0) {
            this.form = response.resource[0]
            if (this.isDataValidation) {
              this.checkRow(response.resource[0])
            }
          }
          else {
            if (this.isDataValidation) {
              this.checkRow(this.form)
            }
          }
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    checkRow(data) {
      delete this.form.sec_org_id

      this.checkItem(data, this.form)
    }
  }
}
</script>
