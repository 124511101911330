<template>
  <v-card v-if="hideSection" class="mt-3">
    <v-card-title>
      <span>{{ $t('title.application_government_positions') }}</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()" :disabled="notApplicable">{{ $t('global.add') }}</v-btn>
    </v-card-title>
    <v-card-text class="p-n">
      <div class="mb-3 mt-1">
        <el-checkbox v-model="notApplicable" @change="checkApplicable()">{{ $t('global.not_applicable') }}</el-checkbox>
      </div>
      <v-alert type="info" border="left" dense class="mb-3" v-if="$t('infobox.application_government_positions') !== ''">
          {{ $t('infobox.application_government_positions') }}
      </v-alert>
      <v-row dense class="customCollapseHeader">
        <v-col cols="6" sm="3">From Date</v-col>
        <v-col cols="6" sm="3">To Date</v-col>
        <v-col class="d-none d-sm-flex" sm="6">Activity</v-col>
      </v-row>
      <el-collapse v-model="activeNames" accordion v-show="!notApplicable">
        <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i">
          <template slot="title">
            <div class="customHead" v-on:click="checkCounty(form)">
              <v-row dense>
                <v-col cols="6" sm="3">
                  <el-tag type="primary" v-if="form.from_date !== null && form.from_date != undefined">{{ form.from_date | day }}</el-tag>
                </v-col>
                <v-col cols="6" sm="3">
                  <el-tag type="success" v-if="form.to_date !== null && form.to_date != undefined">{{ form.to_date | day }}</el-tag>
                </v-col>
                <v-col class="d-none d-sm-flex" sm="6"><div class="pl-2 pr-2">{{ form.activities }}</div></v-col>
              </v-row>
            </div>
          </template>
          <el-form :model="form" ref="form" label-position="top">
            <v-row dense>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'from_date')">
                <el-form-item :label="$t('application_government_positions.from_date')" prop="from_date">
                  <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.from_date" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'to_date')">
                <el-form-item :label="$t('application_government_positions.to_date')" prop="to_date">
                  <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.to_date" @change="handleAutoSave(i, form)" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'country_id')">
                <el-form-item :label="$t('application_government_positions.country_id')" prop="country_id">
                  <div class="el-select native-select">
                    <div class="el-input">
                      <select :id="'country_id' + form.id" v-model="form.country_id" placeholder="Select" @change="changeCountry(form);handleAutoSave(i, form)" class="el-input__inner">
                        <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                      </select>
                    </div>
                  </div>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'city')">
                <el-form-item :label="$t('application_government_positions.city')" prop="city">
                  <el-input v-model="form.city" @change="handleAutoSave(i, form)" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'department_branch')">
                <el-form-item :label="$t('application_government_positions.department_branch')" prop="department_branch">
                  <el-input v-model="form.department_branch" @change="handleAutoSave(i, form)" style="width: 100%;" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'activities')">
                <el-form-item :label="$t('application_government_positions.activities')" prop="activities">
                  <el-input v-model="form.activities" @change="handleAutoSave(i, form)" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'org_level')">
                <el-form-item :label="$t('application_government_positions.org_level')" prop="org_level">
                  <el-select v-model="form.org_level" allow-create @change="handleAutoSave(i, form)" style="width: 100%;">
                    <el-option label="National" value="National"></el-option>
                    <el-option label="Regional" value="Regional"></el-option>
                    <el-option label="Municipal" value="Municipal"></el-option>
                  </el-select>
                </el-form-item>
              </v-col>
              <v-col cols="12">
                <v-btn :loading="autoSave" :disabled="autoSave" color="primary" dark small @click.native="handleAutoSave(i,form)">
                  {{ $t('global.save') }}
                </v-btn>
                <v-btn :loading="autoSave" :disabled="autoSave" color="error" small @click.native="deleteItem(form,i)">{{ $t('global.delete') }}</v-btn>
              </v-col>
            </v-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        org_level: null,
        department_branch: null,
        activities: null,
        from_date: null,
        to_date: null,
        city: null,
        country_id: null
      },
      activeNames: -1,
      url: 'imms_table/application_government_positions',
      deleteUrl: 'imms/application_government_positions',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      applicableId: 'applicable/id',
      country: 'other/country'
    }),
    notApplicable: {
      get() {
        return this.$store.state.applicable.notApplicable.government_positions
      },
      set(val) {
        this.$store.commit('applicable/set_government_positions', val)
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    checkCounty(form) {
      if (form.country_id && form.country_id !== null) form.country_id = parseFloat(form.country_id)
    },
    changeCountry(item) {
      let idx = this.country.map(p => p.id).indexOf(item.country_id)
      item.country_name = this.country[idx].country_name
    },
    fetchData() {
      this.$Progress.start()
      var url = `table/application_government_positions_view?filter=member_uid=${this.memberUID}&order=to_date DESC`
      this.$store.dispatch('store/get', url).then(response => {
        let vm = this

        if (vm.isDataValidation) {
          if (vm.notApplicable) {
            vm.hideSection = false
          }
          else {
            response.resource.forEach(function(item) {
              var form = {
                org_level: null,
                department_branch: null,
                activities: null,
                from_date: null,
                to_date: null,
                city: null,
                country_id: null
              }

              var obj = vm.checkFormItems(item, form)

              if (obj != 'empty') {
                vm.items.push(item)
              }
            })
            if (response.resource.length == 0) {
              vm.hideSection = true
            }
            else {
              vm.hideSection = vm.validationItems.length == 0 ? false : true
            }
          }
        }
        else {
          vm.items = response.resource
        }
        this.$Progress.finish()
      })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        org_level: null,
        department_branch: null,
        activities: null,
        from_date: null,
        to_date: null,
        city: null,
        country_id: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    checkApplicable() {
      var action = 'applicable/save'
      var that = this
      var data = {}
      data.government_positions = that.notApplicable
      data.application_id = this.$route.params.id
      data.member_id = parseFloat(this.memberID)
      data.member_uid = this.memberUID
      if (that.applicableId != undefined) {
        data.id = that.applicableId
        action = 'applicable/edit'
      }
      this.$Progress.start()
      this.$store.dispatch(action, data).then(response => {
        this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
        this.$Progress.finish()
      })
        .catch(err => {
          this.$store.dispatch('toaster/add', { text: 'Updated', type: 'success' })
          this.$Progress.fail()
        })
    }
  }
}
</script>
