<template>
    <v-card class="mt-3" v-if="hideSection">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
        <v-btn class="mb-3" color="primary" dark small @click.native="saveForm()">Add</v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-2" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i" :id="'row' + form.id">
            <template slot="title">
              <div class="relative">
                <el-tag type="primary" v-if="form.occupation != null">{{ form.occupation }}</el-tag>
                <span class="el-col-title">{{ form.noc_code }}</span>
              </div>
            </template>
            <el-form :model="form" ref="form" label-position="top">
              <v-row dense>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'noc_code')">
                  <el-form-item label="Primary occupation (4digit NOC Code)" prop="noc_code">
                    <el-input :maxlength="4" @change="handleAutoSave(i, form)" v-model="form.noc_code" style="width: 100%;" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" sm="6" v-if="dataValidationItems(form, 'occupation')">
                  <el-form-item label="Date you first became qualified to practice in this occupation?" prop="occupation">
                    <el-date-picker @change="handleAutoSave(i, form)" value-format="yyyy-MM" :picker-options="datePickerOptions" type="month" v-model="form.occupation" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(i,form)">
                    {{ $t('global.save') }}
                  </v-btn>
                  <v-btn color="error" small @click.native="deleteOpen(form,i)">Delete</v-btn>
                </v-col>
              </v-row>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </v-card-text>
    </v-card>
</template>

<script>
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        noc_code: null,
        occupation: null,
        sec_org_id: parseInt(localStorage.getItem('org_id'))
      },
      activeNames: -1,
      url: 'imms_table/application_occupation',
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/application_occupation?filter=member_uid=${this.memberUID}`
      this.$store.dispatch('store/get', url).then(response => {
        let vm = this

        if (vm.isDataValidation) {
          response.resource.forEach(function(item) {
            var form = {
              noc_code: null,
              occupation: null
            }

            var obj = vm.checkFormItems(item, form)

            if (obj != 'empty') {
              vm.items.push(item)
            }
          })
          vm.hideSection = vm.validationItems.length == 0 ? false : true
        }
        else {
          vm.items = response.resource
        }
        this.$Progress.finish()
      })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        noc_code: null,
        occupation: null,
        sec_org_id: parseInt(localStorage.getItem('org_id'))
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    resetForm() {
      this.$refs.formItem.resetFields()
      this.nodeClear()
    }
  }
}
</script>
