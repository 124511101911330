let dismissInterval = 10000;
let maxToastId = 0;

export const state = {
  messages: []
};

export const getters = {
  toastMessages: (state) => state.messages
};

export const actions = {
  add({ commit }, data) {
    const id = ++maxToastId;
    data.id = id;
    data.visible = true;
    commit("ADD", data);
    setTimeout(() => commit("REMOVE", id), dismissInterval);
  },
  remove({ commit }, id) {
    commit("REMOVE", id);
  }
};

export const mutations = {
  ADD(state, data) {
    state.messages.push(data);
  },
  REMOVE(state, id) {
    state.messages = state.messages.filter((m) => m.id !== id);
  }
};
