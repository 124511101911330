import api from "../plugins/axios";

let url = "/table/lmia_ui_tabs";

export const state = {
  tabs: [],
  selectedTabs: [],
  tab: {},
  ui_conf_id: undefined
};

export const getters = {
  all(state) {
    return state.tabs;
  },
  getOne(state) {
    return state.tab;
  },
  getUiConfId(state) {
    return state.ui_conf_id;
  },
  selectedTabs(state) {
    return state.selectedTabs;
  }
};

export const actions = {
  getTabs({ commit }, filter) {
    return api.get("/table/lmia_ui_tab" + filter).then(
      (response) => {
        commit("GET_DATAS", response.data.resource);
        return Promise.resolve(response);
      },
      (err) => {
        commit("FAILURE_DATAS", err);
        return Promise.reject(err);
      }
    );
  },
  getSections({ commit }, filter) {
    return api.get("/table/lmia_ui_section" + filter).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  saveUiTab({ commit }, data) {
    var item = {};
    item.order = data.order;
    item.label = data.label;
    item.alias = data.alias;
    item.infobox = data.infobox;

    if (data.id == undefined) {
      let requestObject = { resource: [item] };
      return api.post("/table/lmia_ui_tab", requestObject).then(
        (response) => {
          var item = {};
          item.order = data.order;
          item.label = data.label;
          item.alias = data.alias;
          item.infobox = data.infobox;
          item.sectionTmps = data.sectionTmps;
          item.id = response.data.resource[0].id || undefined;
          return Promise.resolve(response.data.resource[0] || undefined);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    }
    else {
      item.id = data.id;
      let requestObject = { resource: [item] };
      return api.put("/table/lmia_ui_tab", requestObject).then(
        (response) => {
          return Promise.resolve(response.data.resource[0] || undefined);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    }
  },
  saveSection({ commit }, data) {
    if (data.id == undefined) {
      let requestObject = { resource: [data] };
      return api.post("/table/lmia_ui_section", requestObject).then(
        (response) => {
          return Promise.resolve(response.data.resource[0] || undefined);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    }
    else {
      let requestObject = { resource: [data] };
      return api.put("/table/lmia_ui_section", requestObject).then(
        (response) => {
          return Promise.resolve(response.data.resource[0] || undefined);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    }
  },
  save({ commit }, data) {
    var requestObject = { resource: [data] };
    return api.post(url, requestObject).then(
      (response) => {
        var item = {};
        item.order = data.order;
        item.label = data.label;
        item.alias = data.alias;
        item.infobox = data.infobox;
        item.sectionTmps = data.sectionTmps;
        item.id = response.data.resource[0].id || undefined;
        commit("ADD_DATA", item);
        return Promise.resolve(response.data.resource[0] || undefined);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  saveUiConf({ commit }, data) {
    var requestObject = { resource: data };
    return api.post("table/lmia_ui_configuration", requestObject).then(
      (response) => {
        return Promise.resolve(response.data.resource[0] || undefined);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  delete({ commit }, data) {
    return api.delete(url + "/" + data.id).then(
      (response) => {
        commit("DELETE_DATA", data);
        return Promise.resolve(response);
      },
      (error) => {
        commit("FAILURE_DATAS", error);
        return Promise.reject(error);
      }
    );
  },
  deleteTab({ commit }, data) {
    return api.delete("table/lmia_ui_tab" + "?filter=id=" + data.id).then(
      (response) => {
        commit("DELETE_DATA", data);
        return Promise.resolve(response);
      },
      (error) => {
        commit("FAILURE_DATAS", error);
        return Promise.reject(error);
      }
    );
  },
  deleteSection({ commit }, filter) {
    return api.delete("table/lmia_ui_section" + filter).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  deleteUiConf({ commit }, filter) {
    return api.delete("table/lmia_ui_configuration" + filter).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
};

export const mutations = {
  GET_DATAS(state, datas) {
    datas.map(function(row) {
      row.sectionTmps = [];
      if (row.sections != null) {
        row.sectionTmps = JSON.parse(row.sections);
      }
    });
    state.tabs = datas;
  },
  GET_CONFIFS_WITH_SELECTED(state, datas) {
    datas.all.map(function(row) {
      row.sectionTmps = [];
      if (row.sections != null) {
        row.sectionTmps = JSON.parse(row.sections);
        row.sectionTmps.map(function(section) {
          section.checked = false;
          datas.selected.map(function(vRow) {
            if (vRow.sections != null) {
              var maps = JSON.parse(vRow.sections);
              maps.map(function(vSection) {
                if (vSection.sections != undefined) {
                  vSection.sections.map(function(vNode) {
                    if (vNode.keyVal === section.keyVal) {
                      row.alias = vSection.alias;
                      section.checked = true;
                      section.order = vNode.order;
                    }
                  });
                }
              });
            }
          });
        });
      }
    });
    state.tabs = datas.all;
  },
  GET_CONF(state, data) {
    state.ui_conf_id = data[0] != undefined ? data[0]["id"] : undefined;
  },
  ADD_DATA(state, data) {
    let idx = state.tabs.map((p) => p.id).indexOf(data.id);
    state.tabs.splice(idx, 1, data);
  },
  FAILURE_DATAS(state) {
    state.tabs = [];
  },
  FAILURE_DATA(state, data) {
    state.tab = {};
  },
  DELETE_DATA(state, data) {
    let idx = state.tabs.map((p) => p.id).indexOf(data.id);
    state.tabs.splice(idx, 1);
  },
  EMPTY(state, data) {
    state.tabs = [];
  }
};
