import api from './../plugins/axios';

export const state = {
  sidebar: true,
  isMobile: false,
  menu: [],
};

export const getters = {
  getMenu: (state) => state.menu,
  isMobile: (state) => state.isMobile,
};

export const mutations = {
  toggle(state, payload) {
    state.sidebar = payload;
  },
  isMobile(state, payload) {
    state.isMobile = payload;
  },
  GET_MENUS_OLD(state, item) {
    window.localStorage.setItem('menus_ircc', JSON.stringify(item));
    state.menu = item;
  },
  GET_MENUS(state, item) {
    window.localStorage.setItem('menus_ircc', JSON.stringify(item.parentMenu));
    window.localStorage.setItem('isImmigratePortal', item.isImmigratePortal);
    state.menu = item.parentMenu;
  },
  FAILURE_MENU(state) {
    state.menu = [];
  },
};

export const actions = {
  setSidebar({ commit }, payload) {
    commit('toggle', payload);
  },
  getMenu({ commit }, params) {
    let items = window.localStorage.getItem('menus_ircc');
    if (
      params != undefined &&
      params.refreshForce != undefined &&
      params.refreshForce
    ) {
      items = [];
    }
    if (items != undefined && items.length > 0) {
      commit('GET_MENUS_OLD', JSON.parse(items));
    } else {
      return api.get('/get_menu').then((response) => {
        commit('GET_MENUS', response.data);
        return Promise.resolve(response);
      });
    }
  },
};
