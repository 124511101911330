<template>
  <v-card class="mt-3">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()">Add</v-btn>
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{ sectionInfoBox }}
      </v-alert>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item :name="i+1" v-for="(item,i) in items" :key="i" :id="'row' + item.id">
          <template slot="title">
            <div v-on:click="getProvince(item)" class="relative">
              <span class="el-col-title">
                <el-tag type="primary">{{ item.est_date | day }}</el-tag>
                {{ item.reg_name }}
              </span>
            </div>
          </template>
          <el-form :model="item" ref="form" label-position="top" class="mt-3 form">
            <v-card>
              <v-card-title>
                <span>Yukton Express Entry (YEE)</span>
                <v-spacer />
              </v-card-title>
              <v-card-text class="p-n">
                <v-row dense class="medium-space">
                  <v-col cols="12" md="6">
                    <el-form-item label="Stream?" prop="stream">
                      <el-select :id="'stream' + item.id" v-model="item.stream" style="width: 100%">
                        <el-option value="Yukon EE Skilled Worker Program">Yukon EE Skilled Worker Program</el-option>
                        <el-option value="Yukon EE Skilled Trades Program">Yukon EE Canadian Experience Class</el-option>
                        <el-option value="Yukon EE Canadian Experience Class">Yukon EE Canadian Experience Class</el-option>
                      </el-select>
                    </el-form-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-3">
              <v-card-title>
                <span>Company Information</span>
                <v-spacer />
              </v-card-title>
              <v-card-text class="p-n">
                <v-row dense class="medium-space">
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Registered Business Name?" prop="reg_name">
                      <el-input :id="'reg_name' + item.id" v-model="item.reg_name" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Date Established" prop="est_date">
                      <el-date-picker :id="'est_date' + item.id" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="item.est_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Website" prop="website">
                      <el-input :id="'website' + item.id" v-model="item.website" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Type of Business" prop="bus_type">
                      <el-input :id="'bus_type' + item.id" v-model="item.bus_type" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Operating As (if applicable)" prop="operating">
                      <el-input :id="'operating' + item.id" v-model="item.operating" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Primary Business Language?" prop="primary_lang">
                      <el-select :id="'primary_lang' + item.id" v-model="item.primary_lang" style="width: 100%;">
                        <el-option value="English">English</el-option>
                        <el-option value="French">French</el-option>
                      </el-select>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Country" prop="country_id">
                      <div class="el-select native-select">
                        <div class="el-input">
                          <select :id="'cur_country_id' + item.id" v-model="item.cur_country_id" placeholder="Select" class="el-input__inner" @change="getProvince(item.country_id)">
                            <option v-for="(itemC, index) in country" v-bind:key="index" :value="itemC.id" :label="itemC.country_name">{{ itemC.country_name }}</option>
                          </select>
                        </div>
                      </div>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" v-show="selectProvine">
                    <el-form-item label="Province" prop="province">
                      <div class="el-select native-select">
                        <div class="el-input">
                          <select :id="'province' + item.id" v-model="item.province" placeholder="Select" class="el-input__inner" @change="getProvince(item.country_id)">
                            <option v-for="(itemP, index) in province" v-bind:key="index" :value="itemP.province_name" :label="itemP.province_name">{{ itemP.province_name }}</option>
                          </select>
                        </div>
                      </div>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="City" prop="city">
                      <el-input :id="'city' + item.id" v-model="item.city" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Postal Code" prop="post_code">
                      <el-input :id="'post_code' + item.id" v-model="item.post_code" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Address" prop="address">
                      <el-input :id="'address' + item.id" :autosize="{ minRows: 3, maxRows: 4}" type="textarea" v-model="item.address" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Phone" prop="phone">
                      <div class="el-input" v-if="phoneMasked">
                        <masked-input
                          v-model="item.phone"
                          :mask="phoneMask"
                          class="el-input__inner"
                          :id="'phone' + item.id"
                        />
                      </div>
                      <el-input v-else :id="'phone' + item.id" type="number" v-model="item.phone" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Fax" prop="fax">
                      <el-input :id="'fax' + item.id" type="number" v-model="item.fax" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Email" prop="email" :rules="[
                        { type: 'email', message: 'Please input correct email address', trigger: 'blur,change' }
                      ]">
                      <el-input :id="'email' + item.id" v-model="item.email" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Confidential Email" prop="confidential_email" :rules="[
                        { type: 'email', message: 'Please input correct email address', trigger: 'blur,change' }
                      ]">
                      <el-input :id="'confidential_email' + item.id" v-model="item.confidential_email" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-3">
              <v-card-title>
                <span>Position Information</span>
                <v-spacer />
              </v-card-title>
              <v-card-text class="p-n">
                <v-row dense class="medium-space">
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Job Title" prop="jobtitle">
                      <el-input :id="'jobtitle' + item.id" v-model="item.jobtitle" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Job Description" prop="job_description">
                      <el-input :id="'job_description' + item.id" v-model="item.job_description" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="NOC" prop="noc">
                      <el-input :id="'noc' + item.id" v-model="item.noc" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Hours Per Week (30-40hrs) " prop="hrsperweek">
                      <el-input :id="'hrsperweek' + item.id" v-model="item.hrsperweek" type="number" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Salary Per Annum" prop="sal_perannum">
                      <el-input :id="'sal_perannum' + item.id" v-model="item.sal_perannum" type="number" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <el-form-item label="Wage Rate $/hr for Yukon (as per ESDC’s median wage rate)" prop="wage">
                      <el-input :id="'wage' + item.id" type="number" v-model="item.wage" style="width: 100%;" />
                    </el-form-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-text>
                <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="submitForm(i,item)">
                  {{ $t('global.save') }}
                </v-btn>
                <v-btn color="error" small @click.native="deleteOpen(item,i)">Delete</v-btn>
              </v-card-text>
            </v-card>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import MaskedInput from 'vue-text-mask'
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      selectProvine: false,
      phoneMasked: false,
      phoneMask: ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      edit: false,
      form: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        stream: null,
        reg_name: null,
        est_date: null,
        website: null,
        bus_type: null,
        operating: null,
        primary_lang: null,
        country_id: null,
        province: null,
        city: null,
        post_code: null,
        address: null,
        phone: null,
        fax: null,
        email: null,
        confidential_email: null,
        jobtitle: null,
        job_description: null,
        noc: null,
        hrsperweek: null,
        sal_perannum: null,
        wage: null
      },
      activeNames: -1,
      url: 'imms_table/application_company_information',
      state: 'companyInformation',
      loading: false,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date()
        }
      }
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  components: {
    MaskedInput
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      province: 'other/province'
    }),
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    getProvince(item) {
      if (42 == item.country_id || 239 == item.country_id) {
        this.phoneMasked = true
        this.selectProvine = true
        this.$store.dispatch('other/getProvince', item.country_id)
      }
      else {
        this.phoneMasked = false
        this.selectProvine = false
      }
    },
    checkDate: function(event) {
      this.$emit('checkDate', event)
    },
    fetchData() {
      this.$Progress.start()
      var url = 'table/application_company_information?filter=member_uid=' + this.memberUID
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) this.checkItemsFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.form = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        stream: null,
        reg_name: null,
        est_date: null,
        website: null,
        bus_type: null,
        operating: null,
        primary_lang: null,
        country_id: null,
        province: null,
        city: null,
        post_code: null,
        address: null,
        phone: null,
        fax: null,
        email: null,
        confidential_email: null,
        jobtitle: null,
        job_description: null,
        noc: null,
        hrsperweek: null,
        sal_perannum: null,
        wage: null
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.form)
      var len = this.items.length
      this.activeNames = len
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true

          if (formObj.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.item.resetFields()
      this.nodeClear()
    }
  }
}
</script>
