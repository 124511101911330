<template>
  <div class="mt-3 addinional-family-member">
    <component
      v-if="urlView != null"
      :is="isView"
      :isShow="isShow"
      :items="items"
      :url="url"
      :urlView="urlView"
      :country="country"
      :maritalStatus="maritalStatus"
      :relationshipStatus="relationshipStatus"
      :sectionSlug="sectionSlug"
      :memberName="memberName"
      :memberUID="memberUID"
      :memberID="memberID"
      :sectionInfoBox="sectionInfoBox"
      :sectionLabel="sectionLabel"
      :applicantID="applicantID"
      :datas="datas"
      :sectionId="sectionId"
      :isDataValidation="isDataValidation"
      :isOINP="isOINP"
    >
    </component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    sectionSlug: {
      type: String
    },
    sectionAlias: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    },
    memberName: {
      type: String
    },
    isOINP: {
      type: Boolean
    },
    isDataValidation: {
      type: Boolean
    }
  },
  data() {
    return {
      familyMembers: [],
      url: '/table/application_family_members',
      urlView: null,
      items: [],
      isView: 'FamilyMemberBase',
      applicantID: null
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      maritalStatus: 'other/maritalStatus',
      relationshipStatus: 'other/relationships'
    }),
    isShow: function() {
      var id = +this.$route.params.apptypeId
      return id === 2 || id === 3 || id === 24 || id === 22 ? true : false
    }
  },
  created() {
    this.$store.dispatch('other/getCountry')
    this.$store.dispatch('other/getRelationShips', '?filter=name!=Principal&order=rel_order ASC,name ASC')
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('store/get', `application/imm_0008/${this.memberID}`).then(response => {
        let applicantID = response.applicant_id
        if (+response.relationship_to_principal === 1 && response.app_primary) {
          this.url = '/table/imm_0008'
          this.urlView = `/application/family_member_view?applicant_id=${applicantID}&order=rel_order ASC,birth_date ASC`
          this.isView = 'AdditionalFamilyMemberPrinciple'
        }
        else {
          this.url = '/table/application_family_members'
          this.urlView = `/application/application_family_member_view?member_uid=${this.memberUID}&order=rel_order ASC,birth_date ASC`
          this.isView = 'AdditionalFamilyMemberBase'
        }
        this.applicantID = +applicantID
      })
    }
  }
}
</script>

<style>
  .addinional-family-member .el-collapse-item__header{
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .addinional-family-member .el-collapse-item__arrow{
    line-height: 24px;
  }
</style>
