import apiNode from "./../plugins/axios.node";

export const state = {};

export const getters = {};

export const actions = {
  getHash({ commit }, data) {
    return apiNode.post("/ChasePayment/hash", data).then(
      (response) => {
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
};

export const mutations = {};
