<template>
  <v-card v-if="hideSection" class="mt-3">
    <v-card-title>
      <span v-if="sectionLabel == '5. Address history'">{{ sectionLabel }}</span>
      <span v-else>{{ $t('title.application_addresses') }}</span>
      <v-spacer />
      <v-btn color="primary" dark small @click.native="saveForm()">{{ $t('global.add') }}</v-btn>
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert color="info" border="left" dense class="mb-3" v-if="$t('infobox.application_addresses') !== '' && !isAlertHide">
          {{ $t('infobox.application_addresses') }}
      </v-alert>
      <v-alert color="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <el-alert
        v-if="isCheck"
        class="mb-3"
        title="Please check the gaps between dates"
        type="error">
      </el-alert>
      <v-row dense class="customCollapseHeader">
        <v-col cols="6" sm="2">From Date</v-col>
        <v-col cols="6" sm="2">To Date</v-col>
        <v-col class="d-none d-sm-flex" sm="4">Country</v-col>
        <v-col class="d-none d-sm-flex" sm="4">City</v-col>
      </v-row>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i" :id="'row' + form.id">
          <template slot="title">
            <div v-on:click="getProvince(form)" class="customHead">
              <v-row dense>
                <v-col cols="6" sm="2">
                  <el-tag type="primary" v-if="form.from_date !== null && form.from_date != undefined">{{ form.from_date | day }}</el-tag>
                </v-col>
                <v-col cols="6" sm="2">
                  <el-tag type="success" v-if="form.current">Current</el-tag>
                  <el-tag type="success" v-else-if="form.to_date !== null && form.to_date != undefined">{{ form.to_date | day }}</el-tag>
                </v-col>
                <v-col class="d-none d-sm-flex" sm="4"><div class="pl-2 pr-2">{{ form.country_name }}</div></v-col>
                <v-col class="d-none d-sm-flex" sm="4"><div class="pl-3 pr-2">{{ form.city }}</div></v-col>
              </v-row>
            </div>
          </template>
          <el-form :model="form" ref="form" label-position="top" :rules="formRules">
            <v-row dense>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'from_date')">
                <el-form-item :label="$t('application_addresses.from_date')" prop="from_date">
                  <el-date-picker value-format="yyyy-MM-dd" :picker-options="datePickerOptions" @change="handleAutoSave(i, form)" v-model="form.from_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'to_date')">
                <el-form-item :label="$t('application_addresses.to_date')" prop="to_date" class="current">
                  <el-checkbox v-model="form.current">Current</el-checkbox>
                  <el-date-picker v-if="!form.current" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" @change="handleAutoSave(i, form)" v-model="form.to_date" v-on:click.native="checkDate($event)" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'street')">
                <el-form-item :label="$t('application_addresses.street')" prop="street">
                  <el-input v-model="form.street" style="width: 100%" @change="handleAutoSave(i, form)" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'city')">
                <el-form-item :label="$t('application_addresses.city')" prop="city">
                  <el-input v-model="form.city" style="width: 100%" @change="handleAutoSave(i, form)" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'postal_code')">
                <el-form-item :label="$t('application_addresses.postal_code')" prop="postal_code">
                  <el-input v-model="form.postal_code" @change="handleAutoSave(i, form)" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'country_id')">
                <el-form-item :label="$t('application_addresses.country_id')" prop="country_id">
                  <div class="el-select native-select">
                    <div class="el-input">
                      <select @change="getProvince(form);handleAutoSave(i, form)" v-model="form.country_id" placeholder="Select" class="el-input__inner">
                        <option v-for="(item, index) in country" v-bind:key="index" :value="item.id" :label="item.country_name">{{ item.country_name }}</option>
                      </select>
                    </div>
                  </div>
                </el-form-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="dataValidationItems(form, 'province')">
                <el-form-item v-if="selectProvine" :label="$t('application_addresses.province')" prop="province">
                  <div class="el-select native-select">
                    <div class="el-input">
                      <select v-model="form.province" @change="handleAutoSave(i, form)" placeholder="Select" class="el-input__inner">
                        <option v-for="(item, index) in province" v-bind:key="index" :value="item.id" :label="item.province_name">{{ item.province_name }}</option>
                      </select>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item v-else :label="$t('application_addresses.province')" prop="province">
                  <el-input v-model="form.province" @change="handleAutoSave(i, form)" style="width: 100%" />
                </el-form-item>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(i,form)">{{ $t('global.save') }}</v-btn>
                <v-btn :loading="autoSave" :disabled="autoSave" color="error" small @click.native="deleteItem(form,i)">{{ $t('global.delete') }}</v-btn>
              </v-col>
            </v-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    isDataValidation: {
      type: Boolean
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        street: '',
        city: '',
        postal_code: '',
        country_id: null,
        province: '',
        from_date: null,
        to_date: null,
        current: false
      },
      selectProvine: false,
      activeNames: -1,
      url: 'imms_table/application_addresses',
      deleteUrl: 'imms/application_addresses',
      formRules: {
        country_id: [
          { message: 'Please select the country', required: true, trigger: 'blur' }
        ]
      },
      items: []
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: {
    ...mapGetters({
      country: 'other/country',
      province: 'other/province'
    }),
    isCheck: function() {
      var from_date = '';
      var isCheck = [];
      var alert = false;

      if (this.datas === undefined) {
        this.items.forEach(function(item, index) {
          var a = moment(from_date);
          var b = moment(item.to_date);
          var date = a.diff(b, 'days');

          if (date > 1) {
            alert = true
            isCheck.push(item)
          }

          from_date = item.from_date
        })
      }

      return alert
    },
    isAlertHide: function() {
      return this.$route.name == 'section_comparison' ? true : false
    }
  },
  methods: {
    getProvince(item) {
      if (item.country_id != null) {
        let idx = this.country.map(p => p.id).indexOf(item.country_id)
        item.country_name = this.country[idx].country_name

        if (42 == item.country_id || 239 == item.country_id) {
          this.selectProvine = true
          this.$store.dispatch('other/getProvince', item.country_id)
        }
        else {
          this.selectProvine = false
        }
      }
    },
    fetchData() {
      this.$Progress.start()
      var url = 'table/application_address_vew?filter=member_uid=' + this.memberUID + '&order=from_date DESC,to_date DESC'
      this.$store.dispatch('store/get', url).then(response => {
        let vm = this

        if (vm.isDataValidation) {
          response.resource.forEach(function(item) {
            var form = {
              from_date: null,
              to_date: null,
              province: '',
              country_id: '',
              postal_code: '',
              city: '',
              street: null
            }

            if (item.current) {
              delete form.to_date
            }

            var obj = vm.checkFormItems(item, form)

            if (obj != 'empty') {
              vm.items.push(item)
            }
          })
          vm.hideSection = vm.validationItems.length == 0 ? false : true
        }
        else {
          vm.items = response.resource
        }
        this.$Progress.finish()
      })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        street: '',
        city: '',
        postal_code: '',
        country_id: null,
        province: '',
        from_date: null,
        to_date: null,
        current: false
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>

<style>
  .current .el-checkbox{
    position: absolute;
    top: -40px;
    left: 40px;
  }
</style>
