<template>
  <div class="mt-3">
    <v-card>
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
        <v-btn v-if="layout === 'collapse'" color="primary" dark small @click.native="saveForm()">Add</v-btn>
      </v-card-title>
      <v-card-text class="p-n">
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <template v-if="layout === 'collapse'">
          <el-collapse v-model="activeNames" accordion>
            <el-collapse-item :name="i+1" v-for="(form,i) in items" :key="i" :id="'row' + form.id">
              <template slot="title">
                <span class="el-col-title">{{form.job_title}}</span>
              </template>
                <el-form :model="form" ref="form" label-position="top">
                  <v-row dense>
                    <v-col cols="12" sm="6" md="4">
                      <el-form-item label="First Name" prop="given_name">
                        <el-input :id="'given_name' + form.id" v-model="form.given_name" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <el-form-item label="Last Name" prop="family_name">
                        <el-input :id="'family_name' + form.id" v-model="form.family_name" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <el-form-item label="Job Title" prop="job_title">
                        <el-input :id="'job_title' + form.id" v-model="form.job_title" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <el-form-item label="Employee Duties" prop="employee_duties">
                        <el-input :id="'employee_duties' + form.id" v-model="form.employee_duties" style="width: 100%;" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <el-form-item label="Date Began" prop="date_began">
                        <el-date-picker :id="'date_began' + form.id" value-format="yyyy-MM-dd" v-model="form.date_began" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <el-form-item label="Hourly Wage" prop="hourly_wage">
                        <el-input :id="'hourly_wage' + form.id" type="number" v-model="form.hourly_wage" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <el-form-item label="Hourly Per Week" prop="hours_perweek">
                        <el-input :id="'hours_perweek' + form.id" type="number" v-model="form.hours_perweek" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <el-form-item label="Immigration Status" prop="immigration_status">
                        <el-input :id="'immigration_status' + form.id" v-model="form.immigration_status" style="width: 100%" />
                      </el-form-item>
                    </v-col>
                    <v-col cols="12">
                      <v-btn :loading="loading" :disabled="loading" color="primary" dark small @click.native="submitForm(i,form)">
                        {{ $t('global.save') }}

                      </v-btn>
                      <v-btn color="error" small @click.native="deleteOpen(form,i)">Delete</v-btn>
                    </v-col>
                  </v-row>
                </el-form>
              </el-collapse-item>
          </el-collapse>
        </template>
        <template v-if="layout === 'table'">
          <table class="table" style="width:100%">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Job Title</th>
                <th>Employee Duties</th>
                <th>Date Began</th>
                <th>Hourly Wage</th>
                <th>Hourly Per Wage</th>
                <th>Immigration Status</th>
                <th width="30">
                  <v-btn color="green" small fab @click.native="saveForm()">
                    <v-icon class="white--text">add</v-icon>
                  </v-btn>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(form,i) in items" :key="i" :id="'row' + form.id">
                <td class="pa-1">
                  <el-input :id="'given_name' + form.id" v-model="form.given_name" style="width: 100%" />
                </td>
                <td class="pa-1">
                  <el-input :id="'family_name' + form.id" v-model="form.family_name" style="width: 100%" />
                </td>
                <td class="pa-1">
                  <el-input :id="'job_title' + form.id" v-model="form.job_title" style="width: 100%" />
                </td>
                <td class="pa-1">
                  <el-input :id="'employee_duties' + form.id" v-model="form.employee_duties" style="width: 100%;" />
                </td>
                <td class="pa-1">
                  <el-date-picker :id="'date_began' + form.id" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="form.date_began" style="width: 100%;"></el-date-picker>
                </td>
                <td class="pa-1">
                  <el-input :id="'hourly_wage' + form.id" type="number" v-model="form.hourly_wage" style="width: 100%" />
                </td>
                <td class="pa-1">
                  <el-input :id="'hours_perweek' + form.id" type="number" v-model="form.hours_perweek" style="width: 100%;" />
                </td>
                <td class="pa-1">
                  <el-input :id="'immigration_status' + form.id" v-model="form.immigration_status" style="width: 100%" />
                </td>
                <td class="pa-1 text-center">
                  <v-btn color="pink" small fab @click.native="deleteOpen(form, i)"><v-icon class="white--text">clear</v-icon></v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </v-card-text>
      <v-card-actions v-if="layout === 'table'" class="pa-3">
        <v-spacer />
        <v-btn :loading="loading" :disabled="loading" color="primary" small @click.native="allSaveForm()">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import datavalidation from '@/mixins/datavalidation.js'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    layout: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  mixins: [datavalidation],
  data() {
    return {
      edit: false,
      formItem: {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        family_name: '',
        given_name: '',
        job_title: '',
        employee_duties: '',
        date_began: '',
        hourly_wage: '',
        hours_perweek: '',
        immigration_status: ''
      },
      activeNames: -1,
      url: 'imms_table/application_human_resources',
      state: 'humanResource',
      loading: false,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date()
        }
      }
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'sidebar/isMobile'
    }),
    items: {
      get() {
        return this.$store.state.all_stores[this.state]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      var url = `table/application_human_resources?filter=member_uid=${this.memberUID}`
      this.$store.dispatch('all_stores/getAll', { 'url': url, 'slug': this.state })
        .then(response => {
          if (this.datas !== undefined) this.checkTableFields(this.items, this.datas, this.sectionId)
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    nodeClear() {
      this.formItem = {
        application_id: this.$route.params.id,
        member_uid: this.memberUID,
        member_id: parseFloat(this.memberID),
        family_name: '',
        given_name: '',
        job_title: '',
        employee_duties: '',
        date_began: '',
        hourly_wage: '',
        hours_perweek: '',
        immigration_status: ''
      }
      this.edit = false
    },
    saveForm() {
      this.nodeClear()
      this.edit = false
      this.items.push(this.formItem)
      var len = this.items.length
      this.activeNames = len
    },
    allSaveForm() {
      if (this.items.length > 0) {
        this.loading = true
        this.items.forEach(function(form) {
          var action = 'all_stores/save'
          if (form.id != undefined) {
            action = 'all_stores/edit'
            form.id = parseFloat(form.id)
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': form, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                form.id = response.data.resource[0].id
                form.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }, this)
      }
      else {
        return false
      }
    },
    submitForm(index, formObj) {
      this.$refs.form[index].validate((valid) => {
        if (valid) {
          this.loading = true

          if (formObj.id != undefined) this.edit = true
          var action = 'all_stores/save'
          if (this.edit) {
            action = 'all_stores/edit'
          }
          this.$Progress.start()
          this.$store.dispatch(action, { 'url': this.url, 'data': formObj, 'slug': this.state })
            .then(response => {
              if (response.data.resource && response.data.resource.length > 0) {
                formObj.id = response.data.resource[0].id
                formObj.imms_uuid = response.data.resource[0].imms_uuid
              }
              this.$store.dispatch('toaster/add', { text: 'Save', type: 'success' })
              this.$Progress.finish()
              this.activeNames = -1
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.nodeClear()
    }
  }
}
</script>
