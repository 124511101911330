<template>
  <div>
    <v-card v-if="hideSection" class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="yukon-checkbox">
          <v-row dense>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'job_title')">
              <el-form-item prop="job_title" label="Position Title ">
                <el-input @change="handleAutoSave(form)" v-model="form.job_title" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'job_description')">
              <el-form-item prop="job_description" label="Job Description ">
                <el-input @change="handleAutoSave(form)" v-model="form.job_description" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'required_skills')">
              <el-form-item prop="required_skills" label="Required skills (as per the NOC)">
                <el-input @change="handleAutoSave(form)" v-model="form.required_skills" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'required_education')">
              <el-form-item prop="required_education" label="Required education (as per the NOC)">
                <el-input @change="handleAutoSave(form)" v-model="form.required_education" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'other')">
              <el-form-item prop="other" label="Other (please specify)">
                <el-input @change="handleAutoSave(form)" v-model="form.other" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'yukon_industry')">
              <el-form-item prop="yukon_industry" label="Canada/Yukon Industy or Association Standards required">
                <el-input @change="handleAutoSave(form)" v-model="form.yukon_industry" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'yukon_industry_other')">
              <el-form-item prop="yukon_industry_other" label="Other (please specify)">
                <el-input @change="handleAutoSave(form)" v-model="form.yukon_industry_other" />
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'ensured_verified')">
              <el-form-item prop="ensured_verified">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.ensured_verified"></el-checkbox>
                <label class="ml-4">Have you ensured and verified that the applicant has the necessary credentials to be eligible to work in the occupation?</label>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'intended_occupation')">
              <el-form-item prop="intended_occupation">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.intended_occupation"></el-checkbox>
                <label class="ml-4">Is the intended occupation consistent with the applicant's ability to perform the job based in the applicant's education, training, or expereince?</label>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'job_location')">
              <el-form-item prop="job_location" label="Job location ">
                <el-input @change="handleAutoSave(form)" v-model="form.job_location" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'job_location_city')">
              <el-form-item prop="job_location_city" label="Job location">
                <el-input @change="handleAutoSave(form)" v-model="form.job_location_city" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'other_community')">
              <el-form-item prop="other_community" label="Other community">
                <el-input @change="handleAutoSave(form)" v-model="form.other_community" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'noc')">
              <el-form-item prop="noc" label="NOC">
                <el-input @change="handleAutoSave(form)" v-model="form.noc" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'hours_per_week')">
              <el-form-item prop="hours_per_week" label="Hours per/week (35-40)">
                <el-input @change="handleAutoSave(form)" v-model="form.hours_per_week" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'wage_rate')">
              <el-form-item prop="wage_rate" label="Wage rate $/hr for Yukon (as per the EDSC's median wage rate)">
                <el-input @change="handleAutoSave(form)" v-model="form.wage_rate" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'salary_per_annum')">
              <el-form-item prop="salary_per_annum" label="Salary per annum">
                <el-input @change="handleAutoSave(form)" v-model="form.salary_per_annum" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'number_employees')">
              <el-form-item prop="number_employees" label="Number of employees currently employed by this business/company">
                <el-input @change="handleAutoSave(form)" v-model="form.number_employees" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'foreign_workers')">
              <el-form-item prop="foreign_workers" label="How many are foreign workers or nominess?">
                <el-input @change="handleAutoSave(form)" v-model="form.foreign_workers" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'comments')">
              <el-form-item prop="comments" label="Comments:">
                <el-input @change="handleAutoSave(form)" v-model="form.comments" />
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'released_pr')">
              <el-form-item prop="released_pr">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.released_pr"></el-checkbox>
                <label class="ml-4">Have you released and Canadian or permanent resident workers from this position in the last 12 months?</label>
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="form.released_pr">
              <el-form-item v-if="dataValidation(form, 'released_pr_yes')" prop="released_pr_yes" label="If yes, have you offered the position to the former worker/s before applying to this program?">
                <el-input @change="handleAutoSave(form)" v-model="form.released_pr_yes" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-else>
              <el-form-item v-if="dataValidation(form, 'released_pr_no')" prop="released_pr_no" label="If no, please explain why or why not.">
                <el-input @change="handleAutoSave(form)" v-model="form.released_pr_no" />
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'released_temporary')">
              <el-form-item prop="released_temporary">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.released_temporary"></el-checkbox>
                <label class="ml-4">Have you released any temporary foreign workers or nominees from this position in the last 12 months?</label>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="form.released_temporary">
              <el-form-item v-if="dataValidation(form, 'released_training_opportunity')" prop="released_training_opportunity" label="If yes, have you offered the position to the former worker/s before applying to this program?">
                <el-input @change="handleAutoSave(form)" v-model="form.released_training_opportunity" />
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'training_opportunities')">
              <el-form-item prop="training_opportunities">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.training_opportunities"></el-checkbox>
                <label class="ml-4">Have you considered training opportunities for Canadian workers?</label>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'recruit_workers')">
              <el-form-item prop="recruit_workers">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.recruit_workers"></el-checkbox>
                <label class="ml-4">Have you tried to recruit workers from local or provincial/territorial employment centres, service centres for Aboriginal youth, and people with disabilities?</label>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'offer_bursaries')">
              <el-form-item prop="offer_bursaries">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.offer_bursaries"></el-checkbox>
                <label class="ml-4">Have you tried to offer bursaries to attract students or youth, pursue online recruitment strategies, or undertake ongoing advertising and interviews in order to maintain a pre-screened applicant pool?</label>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'recruitment_proof')">
              <el-form-item prop="recruitment_proof">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.recruitment_proof"></el-checkbox>
                <label class="ml-4">Have submitted proof of all three forms of advertising listed above with my application?</label>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'recruitment_active')">
              <el-form-item prop="recruitment_active">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.recruitment_active"></el-checkbox>
                <label class="ml-4">Have you actively recruited in Yukon and Canada for this position?</label>
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  mixins: [autosave],
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        job_title: null,
        job_description: null,
        required_skills: null,
        required_education: null,
        other: null,
        yukon_industry: null,
        yukon_industry_other: null,
        ensured_verified: null,
        intended_occupation: null,
        job_location: null,
        job_location_city: null,
        other_community: null,
        noc: null,
        hours_per_week: null,
        wage_rate: null,
        salary_per_annum: null,
        number_employees: null,
        foreign_workers: null,
        comments: null,
        released_pr: null,
        released_pr_yes: null,
        released_pr_no: null,
        released_temporary: null,
        released_training_opportunity: null,
        training_opportunities: null,
        recruit_workers: null,
        offer_bursaries: null,
        recruitment_proof: null,
        recruitment_active: null
      },
      url: 'imms_table/lmia_yukon_position_information',
      getUrl: 'imms_table/lmia_yukon_position_information?application_imms_uuid=' + this.$route.params.id
    }
  },
  mounted() {
    this.fetch().then(data => {
      if (data.items != undefined && data.items.length > 0) {
        if (this.isDataValidation) {
          this.checkRow(data.items[0])
        }
        this.form = data.items[0]
      }
      else {
        if (this.isDataValidation) {
          this.checkRow(this.form)
        }
      }
    })
  }
}
</script>
