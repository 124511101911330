<template>
  <div>
    <el-form :model="form" ref="form" label-position="top" class="mt-3 form">
      <el-row :gutter="20">
        <el-col :sm="24" :md="24" :lg="24">
          <v-card>
            <v-card-title :class="color">
              <span>New Brunswick Provincial Nominee Program - Skilled Worker Information Form</span>
              <v-spacer />
            </v-card-title>
            <v-card-text class="p-n">
              <el-row :gutter="30">
                <el-col :sm="12" :md="8" :lg="6">
                  <el-form-item label="Language Ability" prop="lang_ability">
                    <el-select id="lang_ability" v-model="form.lang_ability">
                      <el-option :value="item.name" :label="item.name" v-for="(item, index) in abilities" v-bind:key="index">{{ item.name }}</el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </v-card-text>
          </v-card>
          <v-card class="mt-3 mt-3">
            <v-card-title :class="color">
              <span>Previous Immigration Applications to Canada</span>
              <v-spacer />
            </v-card-title>
            <v-card-text class="p-n">
              <el-row :gutter="30">
                <el-col :sm="12" :md="8" :lg="6">
                  <el-form-item prop="studypermityes">
                    <el-checkbox id="studypermityes" v-model="form.studypermityes">Study Permit</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :sm="12" :md="8" :lg="6">
                  <el-form-item prop="workpermityes">
                    <el-checkbox id="workpermityes" v-model="form.workpermityes">Work Permit</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :sm="12" :md="8" :lg="6">
                  <el-form-item prop="pnpyes">
                    <el-checkbox id="pnpyes" v-model="form.pnpyes">Provincial Nominee </el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :sm="12" :md="8" :lg="6">
                  <el-form-item prop="trvyes">
                    <el-checkbox id="trvyes" v-model="form.trvyes">Temporary resident visa</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :sm="12" :md="8" :lg="6">
                  <el-form-item prop="icyes">
                    <el-checkbox id="icyes" v-model="form.icyes">Immigrated Canada</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
            </v-card-text>
            <v-card-actions>
              <v-btn :loading="loading" :disabled="loading" color="primary" small class="ml-3" @click.native="submitForm()">
                {{ $t('global.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    sectionId: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      abilities: [
        { name: 'English - Partial competence in familiar situations (Basic)' },
        { name: 'French - Partial competence in familiar situations (Basic)' },
        { name: 'English - Operational command of the language with only casional inaccuracies (Advanced)' },
        { name: 'French - Operational command of the language with only casional inaccuracies (Advanced)' },
        { name: 'English -Effective command of the language despite some inaccuracies and misunderstandings (Intermediate)' },
        { name: 'French -Effective command of the language despite some inaccuracies and misunderstandings (Intermediate)' }
      ],
      loading: false
    }
  },
  mounted() {
    if (this.datas !== undefined) {
      this.checkFields(this.fields, this.datas, this.sectionId)
    }
  },
  watch: {
    memberUID: function() {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters({
      item: 'language_ability/getOne'
    }),
    form: function() {
      return this.item.id === undefined ? this.formNode : this.item
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$Progress.start()
      this.$store.dispatch('language_ability/getById', this.$route.params.id)
        .then(response => {
          this.$Progress.finish()
        })
        .catch(err => {
          this.$Progress.fail()
        })
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true

          this.$Progress.start()
          var action = 'edit'
          var actionMessage = 'Saved'
          if (this.form.id === undefined) {
            action = 'save'
            actionMessage = 'Save Successful'
          }
          this.$store.dispatch('language_ability/' + action, this.form)
            .then(response => {
              this.$store.dispatch('toaster/add', { text: actionMessage, type: 'success' })
              this.$Progress.finish()
              this.loading = false
            })
            .catch(err => {
              this.$store.dispatch('toaster/add', { text: 'Error', type: 'success' })
              this.$Progress.fail()
              this.loading = false
            })
        }
        else {
          return false
        }
      })
    }
  }
}
</script>
