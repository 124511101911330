<template>
  <div>
    <v-card v-if="hideSection" class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top">
          <v-row dense>
            <v-col cols="6" v-if="dataValidation(form, 'emp_company_name')">
              <el-form-item label="Name of the company">
                <el-input v-model="form.emp_company_name" @change="handleAutoSave(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'emp_physical_address')">
              <el-form-item label="Physical address">
                <el-input v-model="form.emp_physical_address" @change="handleAutoSave(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'emp_company_email')">
              <el-form-item label="Company Email address">
                <el-input v-model="form.emp_company_email" @change="handleAutoSave(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="6" v-if="dataValidation(form, 'emp_company_website')">
              <el-form-item label="Company Website">
                <el-input v-model="form.emp_company_website" @change="handleAutoSave(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="6" v-if="dataValidation(form, 'emp_contact_name')">
              <el-form-item label="Contact name">
                <el-input v-model="form.emp_contact_name" @change="handleAutoSave(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
            <v-col cols="6" v-if="dataValidation(form, 'emp_contact_phone')">
              <el-form-item label="Contact phone number">
                <el-input v-model="form.emp_contact_phone" @change="handleAutoSave(form)" style="width: 100%" />
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  mixins: [autosave],
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        emp_company_name: null,
        emp_physical_address: null,
        emp_company_email: null,
        emp_company_website: null,
        emp_contact_name: null,
        emp_contact_phone: null,
        sec_org_id: parseInt(localStorage.getItem('org_id'))
      },
      url: 'imms_table/lmia_yukon_app',
      getUrl: 'imms_table/lmia_yukon_app?application_imms_uuid=' + this.$route.params.id
    }
  },
  mounted() {
    this.fetch().then(data => {
      if (data.items != undefined && data.items.length > 0) {
        if (this.isDataValidation) {
          this.checkRow(data.items[0])
        }
        this.form = data.items[0]
      }
      else {
        if (this.isDataValidation) {
          this.checkRow(this.form)
        }
      }
    })
  }
}
</script>
