<template>
  <div>
    <v-card v-if="hideSection" class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top" class="yukon-checkbox">
          <v-row dense>
            <v-col cols="12" v-if="dataValidation(form, 'settle_provide_accommodation')">
              <el-form-item prop="settle_provide_accommodation">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.settle_provide_accommodation"></el-checkbox>
                <label class="ml-4">Are you providing accommodation for your Nominee(s)?</label>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense v-if="form.settle_provide_accommodation">
            <v-col cols="12" v-if="dataValidation(form, 'settle_accom_address')">
              <el-form-item prop="settle_accom_address" label="Address">
                <el-input @change="handleAutoSave(form)" v-model="form.settle_accom_address" />
              </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'settle_accom_city')">
              <el-form-item prop="settle_accom_city" label="City">
              <el-input @change="handleAutoSave(form)" v-model="form.settle_accom_city" />
            </el-form-item>
            </v-col>
            <v-col cols="12" sm="6" v-if="dataValidation(form, 'settle_accom_postal_code')">
              <el-form-item prop="settle_accom_postal_code" label="Postal Code">
              <el-input @change="handleAutoSave(form)" v-model="form.settle_accom_postal_code" />
            </el-form-item>
            </v-col>
          </v-row>
          <v-row dense v-if="!form.settle_provide_accommodation">
            <v-col cols="12" v-if="dataValidation(form, 'settle_assist_accom')">
              <el-form-item prop="settle_assist_accom" label="If you are not providing accomodation how will you assist your Nominee(s) in finding it?">
              <el-input @change="handleAutoSave(form)" v-model="form.settle_assist_accom" />
            </el-form-item>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" v-if="dataValidation(form, 'settle_safety_training')">
              <el-form-item prop="settle_safety_training">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.settle_safety_training"></el-checkbox>
                <label class="ml-4">What training is provided to employees in regards to workplace health and safety?</label>
              </el-form-item>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" v-if="dataValidation(form, 'settle_safety_clothing')">
              <el-form-item prop="settle_safety_clothing">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.settle_safety_clothing"></el-checkbox>
                <label class="ml-4">Is any special safety equipment or protective clothing necessary?</label>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'settle_safety_equipment')">
              <el-form-item prop="settle_safety_equipment">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.settle_safety_equipment"></el-checkbox>
                <label class="ml-4">If yes, is the safety equipment provided by the employer?</label>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'settle_safety_training_provided')">
              <el-form-item prop="settle_safety_training_provided">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.settle_safety_training_provided"></el-checkbox>
                <label class="ml-4">Is health and safety training provided in the workplace?</label>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'settle_rights_in_canada')">
              <el-form-item prop="settle_rights_in_canada">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.settle_rights_in_canada"></el-checkbox>
                <label class="ml-4">have you ensured that the nominees knows of his/her rights as a worker in Canada?</label>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'settle_guide_employment')">
              <el-form-item prop="settle_guide_employment">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.settle_guide_employment"></el-checkbox>
                <label class="ml-4">Foreign Workers' Guide to Employment in Yukon</label>
              </el-form-item>
            </v-col>
            <v-col cols="12" v-if="dataValidation(form, 'settle_work_program')">
              <el-form-item prop="settle_work_program">
                <el-checkbox @change="handleAutoSave(form)" v-model="form.settle_work_program"></el-checkbox>
                <label class="ml-4">Ready to Work Program</label>
              </el-form-item>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  mixins: [autosave],
  props: {
    isDataValidation: {
      type: Boolean
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        settle_provide_accommodation: null,
        settle_accom_address: null,
        settle_accom_city: null,
        settle_assist_accom: null,
        settle_work_program: null,
        settle_guide_employment: null,
        settle_rights_in_canada: null,
        settle_safety_training_provided: null,
        settle_safety_equipment: null,
        settle_safety_clothing: null,
        settle_safety_training: null
      },
      url: 'imms_table/lmia_yukon_employer_declarations',
      getUrl: 'imms_table/lmia_yukon_employer_declarations?application_imms_uuid=' + this.$route.params.id
    }
  },
  mounted() {
    this.fetch().then(data => {
      if (data.items != undefined && data.items.length > 0) {
        if (this.isDataValidation) {
          this.checkRow(data.items[0])
        }
        this.form = data.items[0]
      }
      else {
        if (this.isDataValidation) {
          this.checkRow(this.form)
        }
      }
    })
  }
}
</script>
