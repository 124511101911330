<template>
  <div>
    <v-card v-if="hideSection" class="mt-3">
      <v-card-title>
        <span>{{ sectionLabel }}</span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
          {{sectionInfoBox}}
        </v-alert>
        <el-form :model="form" ref="form" label-position="top">
          <v-row dense>
            <v-col cols="6" sm="5">
              <v-row dense>
                <v-col cols="12" v-if="dataValidation(form, 'job_bank_advertised')">
                  <el-form-item label="">
                    <el-checkbox v-model="form.job_bank_advertised" style="width: 100%" @change="handleAutoSave(form)">I have advertised on</el-checkbox>
                    <ul>
                      <li>the National Job Bank; and two or more recruitment methods among these:</li>
                      <li>local and regional newspapers, newsletters, employment centres.</li>
                      <li>recognized employment sites such as Monster, Workopolis etc., AND</li>
                    </ul>
                  </el-form-item>
                </v-col>
                <v-col cols="12" v-if="dataValidation(form, 'submitted_proof')">
                  <el-form-item label="">
                    <el-checkbox v-model="form.submitted_proof" style="width: 100%" @change="handleAutoSave(form)">I have submitted proof of all three forms of advertising listed above with my application.</el-checkbox>
                  </el-form-item>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" sm="7">
              <div class="display-1 mb-3">OR</div>
              <v-row dense>
                <v-col cols="12" v-if="dataValidation(form, 'lmia_submitted')">
                  <el-form-item prop="lmia_submitted">
                    <el-checkbox v-model="form.lmia_submitted" style="width: 100%" @change="handleAutoSave(form)">I have a valid Labour Market Impact Assessment (LMIA) and have submitted proof with my application.</el-checkbox>
                  </el-form-item>
                  <p>Do not submit resumes with your application, but do keep them on file for at least two years, in the event this documentation is requested by a Service Canada Officer.</p>
                </v-col>
                <v-col cols="12" v-if="dataValidation(form, 'how_many_pr')">
                  <el-form-item prop="how_many_pr" label="How many Canadian or permanent residents applied on this job?">
                    <el-input v-model="form.how_many_pr" type="number" style="width: 100%" @change="handleAutoSave(form)" />
                  </el-form-item>
                  <div>Reasons for not hiring EACH of them. You may be asked for additional detailed information if deemed neccessary by the assessment officer to asses your application.</div>
                </v-col>
                <v-col class="mt-2" cols="12" v-if="dataValidation(form, 'applicant_1')">
                  <el-form-item label="Applicant 1" class="leftpos">
                    <el-input v-model="form.applicant_1" @change="handleAutoSave(form)" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" v-if="dataValidation(form, 'applicant_2')">
                  <el-form-item label="Applicant 2" class="leftpos">
                    <el-input v-model="form.applicant_2" @change="handleAutoSave(form)" />
                  </el-form-item>
                </v-col>
                <v-col cols="12" v-if="dataValidation(form, 'applicant_3')">
                  <el-form-item label="Applicant 3" class="leftpos">
                    <el-input v-model="form.applicant_3" @change="handleAutoSave(form)" />
                  </el-form-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn :loading="autoSave" :disabled="autoSave" color="primary" small @click.native="handleAutoSave(form)">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import autosave from '@/mixins/lmia_autosave'

export default {
  mixins: [autosave],
  props: {
    isDataValidation: {
      type: Boolean
    },
    layout: {
      type: String
    },
    sectionLabel: {
      type: String
    },
    sectionInfoBox: {
      type: String
    },
    memberUID: {
      type: String,
      required: true
    },
    memberID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      form: {
        application_id: this.$route.params.id,
        sec_org_id: parseInt(localStorage.getItem('org_id')),
        job_bank_advertised: null,
        submitted_proof: null,
        lmia_submitted: null,
        how_many_pr: null,
        applicant_1: null,
        applicant_2: null,
        applicant_3: null
      },
      url: 'imms_table/lmia_ads_information_yukon',
      getUrl: 'imms_table/lmia_ads_information_yukon?application_imms_uuid=' + this.$route.params.id
    }
  },
  mounted() {
    this.fetch().then(data => {
      if (data.items != undefined && data.items.length > 0) {
        if (this.isDataValidation) {
          this.checkRow(data.items[0])
        }
        this.form = data.items[0]
      }
      else {
        if (this.isDataValidation) {
          this.checkRow(this.form)
        }
      }
    })
  }
}
</script>
<style>
  .el-form-item__label, .el-checkbox__label{
    white-space: normal;
  }
  .leftpos .el-form-item__label{ width: 120px; float: left; }
  .leftpos .el-form-item__content{ margin-left: 120px; }
</style>
