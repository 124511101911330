
<template>
  <v-card class="mt-3">
    <v-card-title>
      <span>{{ sectionLabel }}</span>
      <v-spacer />
    </v-card-title>
    <v-card-text class="p-n">
      <v-alert type="info" border="left" dense class="mb-3" v-if="sectionInfoBox">
        {{sectionInfoBox}}
      </v-alert>
      <v-row dense class="medium-space">
        <v-col cols="12" sm="6">
          <el-form-item :label="$t('imm_0008.many_family')" prop="many_family">
            <el-select id="many_family" v-model="form.many_family" style="width: 100%;">
              <el-option v-for="n in 15" :key="n" :label="n" :value="n"></el-option>
            </el-select>
          </el-form-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionId: {
      type: Number
    },
    sectionLabel: {
      type: String
    },
    isDataValidation: {
      type: Boolean
    },
    datas: {
      type: Array
    }
  },
  data() {
    return {
      fields: ['many_family']
    }
  },
  mounted() {
    if (this.datas !== undefined) {
      this.checkFields(this.fields, this.datas, this.sectionId)
    }
  },
  methods: {
    checkDate: function(event) {
      this.$emit('checkDate', event)
    }
  }
}
</script>
